import IonIcon from '@reacticons/ionicons';
import React from 'react';

interface RemoveGridButtonProps {
    onRemove: () => void;
}

const RemoveGridButton: React.FC<RemoveGridButtonProps> = ({ onRemove }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <IonIcon name="trash-outline" className="click-ion-icon" style={{ width: '20px', height: '20px', color: "red" }} onClick={onRemove} />
            <span
                style={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: '#111928',
                    fontSize: '12px',
                    fontWeight: 'bold',
                }}
            >
                Remove Pricing Grid
            </span>
        </div >
    );
};

export default RemoveGridButton;