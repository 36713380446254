import {
  Group,
  Stack,
  Title,
} from '@mantine/core'
import { FacilityParams } from 'app/models/facility-params'
import StatusPill from '../common/pill-status'


interface props {
  facility: FacilityParams
}

export default function LenderAllocationsHeader({ facility }: props): JSX.Element {
  return (
    <Stack className="detailsHeader">
      <Group noWrap>
        <Title className='detailsTitle'>Lender Allocations</Title>
        <StatusPill
          updated={true}
          state={facility?.status ?? 'Draft'}
          additionalState={facility?.amendmentType ?? ''}
        />
      </Group>
    </Stack>
  )
}
