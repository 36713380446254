import { Group } from '@mantine/core';
import IonIcon from '@reacticons/ionicons';
import React from 'react';

interface AddGridButtonProps {
    onClick: () => void;
    pricingGrid: number;
}
const AddGridButton: React.FC<AddGridButtonProps> = ({ onClick, pricingGrid }) => {
    const handleClick = () => {
        if (pricingGrid < 3) {
            onClick();
        }
    };

    return (
        <Group noWrap>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IonIcon name="add-circle-outline" className="click-ion-icon" onClick={handleClick} />
                <span style={{ marginLeft: '8px', fontFamily: 'Plus Jakarta Sans', fontWeight: 'bold', fontSize: '1em' }}>
                    Add Grid
                </span>
            </div>
        </Group>
    );
};


export default AddGridButton;