import { Dispatch } from 'redux'
import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { addPik, getPiks, approvePik, deletePik, submitPik, rejectPik, editPik, stopLoadingPik, startLoadingPik } from './actions'
import { PikParams } from 'app/models/pik-params'


export const loadApprovedPiks = async (loanId: string | undefined) => {
  const apiServerUrl = config.backendUrl
  if (loanId) {
    return await client
      .get(`${apiServerUrl}/api/approved-payment-in-kinds/loan?id=${loanId}`)
      .then(response => {
        return response.data
      }).catch(e => {
        console.log(e)
        return []
      })
  } else {
    return await client
      .get(`${apiServerUrl}/api/approved-payment-in-kinds`)
      .then(response => {
        return response.data
      }).catch(e => {
        console.log(e)
        return []
      })
  }
}
export const loadDraftPIK = async (loanId: string | undefined) => {
  const apiServerUrl = config.backendUrl
  if (loanId) {
    return await client
      .get(`${apiServerUrl}/api/draft-payment-in-kinds/loan?id=${loanId}`)
      .then(response => {
        return response.data
      }).catch(e => {
        console.log(e)
        return []
      })
  } else {
    return await client
      .get(`${apiServerUrl}/api/draft-payment-in-kinds`)
      .then(response => {
        return response.data
      }).catch(e => {
        console.log(e)
        return []
      })
  }
}

export const loadSubmittedPik = async (
  loanId: string | undefined
) => {
  const apiServerUrl = config.backendUrl
  if (loanId) {
    return await client
      .get(`${apiServerUrl}/api/submitted-payment-in-kinds/loan?id=${loanId}`)
      .then(response => {
        return response.data
      }).catch(e => {
        console.log(e)
        return []
      })
  } else {
    return await client
      .get(`${apiServerUrl}/api/submitted-payment-in-kinds`)
      .then(response => {
        return response.data
      }).catch(e => {
        console.log(e)
        return []
      })
  }
}

export const loadPaymentInKinds =
  (loanId?: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(startLoadingPik())
    let allPiks: any[] = []

    const draftPiks = await loadDraftPIK(loanId)
    const submittedPiks = await loadSubmittedPik(loanId)
    const piks = await loadApprovedPiks(loanId)

    const arr = submittedPiks.map(
      (a: { paymentInKind: PikParams }) => a.paymentInKind
    )
    const subPiks = arr.map((a: PikParams) => ({
      ...a,
      status: 'Submitted',
    }))

    allPiks = draftPiks
      .map((pik: PikParams) => ({
        ...pik,
        status: 'Draft',
      }))
      .concat(subPiks)
      .concat(
        piks.map((pik: PikParams) => ({
          ...pik,
          status: 'Approved',
        }))
      )

    return dispatch(getPiks(allPiks))
  }
  finally {
    dispatch(stopLoadingPik())
  }
}

export const savePik = (newPik: PikParams, oldPik?:PikParams) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  if(oldPik){

    return client.put(`${apiServerUrl}/api/draft-payment-in-kinds`, newPik)
    .then(pik => {
      dispatch(editPik({ ...pik.data }))
      return {success: true, payload: pik.data}
    }).catch(e => {
      return {success: false, payload: e.response.data.error}
    })

  }
  return client
    .post(`${apiServerUrl}/api/draft-payment-in-kinds`, newPik)
    .then(pik => {
      dispatch(addPik({ ...pik.data, status: 'Draft' }))
      return {success: true, payload: pik.data}
    }).catch(e => {
      return {success: false, payload: e.response.data.error}
    })
}
export const submitDraftPik = (id: string) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .post(`${apiServerUrl}/api/draft-payment-in-kinds/submit`, { id })
    .then(pik => {
      dispatch(submitPik(pik.data))
      return {success: true, payload: pik.data}
    }).catch(e => {
      return {success: false, payload: e.response.data.error}
    })
}
export const rejectSubmittedPik = (id: string) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .post(`${apiServerUrl}/api/submitted-payment-in-kinds/reject`, { id })
    .then(pik => {
      dispatch(rejectPik(pik.data))
      return {success: true, payload: pik.data}
    }).catch(e => {
      return {success: false, payload: e.response.data.error}
    })
}

export const approveSubmittedPik = (id: string) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .post(`${apiServerUrl}/api/submitted-payment-in-kinds/approve`, { id })
    .then(pik => {
      dispatch(approvePik(pik.data))
      return {success: true, payload: pik.data}
    }).catch(e => {
      return {success: false, payload: e.response.data.error}
    })
}

export const deleteSelectedPik = (pik: any) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .delete(`${apiServerUrl}/api/draft-payment-in-kinds?ids[]=${pik.id}`)
    .then(() => {
      dispatch(deletePik(pik))
      return {success: true, payload: pik}
    }).catch(e => {
      return {success: false, payload: e.response.data.error}
    })
}
