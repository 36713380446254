import { useEffect, useState } from 'react'
import { Stack } from '@mantine/core'
import KPI_Section from 'app/views/components/KPI/KPI-horizontal-section'
import { getLockedColumnDefs } from './locked-deal-table-columnDefs'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { DealParams } from 'app/models/deal-params'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { getLockedDeals, getDealLoader } from 'app/state/ducks/deals/selectors'
import { MRT_ColumnDef } from 'mantine-react-table'
import { getKPIData } from '../deals-kpi-data'
import { mapEntityIdsToNames } from 'app/utils/util-functions'
import cleanUUID from 'app/views/components/functions/cleanUUID'
import MainTable from 'app/views/components/Table/MainTable'

export default function LockedDealManagmentPage() {
  const allDeals = useSelector(getLockedDeals)
  const dealLoader = useSelector(getDealLoader)
  const [globalLoading, setGlobalLoading] = useState(false)
  const [deals, setDeals] = useState<DealParams[]>([])
  const [filteredDeals, setFilteredDeals] = useState<DealParams[]>(deals)
  const [columnDefs, setColumnDefs] = useState<MRT_ColumnDef<DealParams>[]>([])
  const entities = useSelector(getEntities)
  const navigate = useNavigate()
  const kpiData = getKPIData(deals, setFilteredDeals)


  useEffect(() => {
    setGlobalLoading(dealLoader)
  }, [dealLoader])

  useEffect(() => {
    setDeals(allDeals)
    setFilteredDeals(allDeals)
  }, [allDeals])

  useEffect(() => {
    if (!entities) {
      return
    }
    setColumnDefs(getLockedColumnDefs(entities))
  }, [entities])

  // Function to filter and update entity and borrowers names
  function cleanDealData(
    filteredDeals: DealParams[],
  ): DealParams[] {
    const entityIdToName = mapEntityIdsToNames(entities);

    return (
      filteredDeals.map((deals) => {
        const adminEntityName = deals.adminEntity?.id
          ? entityIdToName.get(cleanUUID(deals.adminEntity.id)) ?? ''
          : '';
        const borrowerNames = deals.borrowers.map((borrower: { id: string }) => {
          return entityIdToName.get(cleanUUID(borrower.id)) ?? '';
        });

        return {
          ...deals,
          adminEntityName,
          borrowerNames,
        };
      }).sort((a, b) => a.dealName.localeCompare(b.dealName)) ?? []
    );
  }

  return (
    <Stack>
      <KPI_Section
        kpiData={kpiData}
        isLoading={globalLoading}
      />
      {columnDefs.length != 0 ? (
        <MainTable
          tableName='Locked_Deals Table'
          isLoading={globalLoading}
          withPagination={true}
          csvExportEnabled={true}
          enableTopToolbar={false}
          headerBackgroundColor='#F0EEEE'
          columnDefs={columnDefs}
          data={cleanDealData(filteredDeals)}
          setRow={(e: any, cellName: string) => {
            if (cellName != 'id') {
              navigate('/dealmanagement/deal/' + e.id + '/' + e.status)
            }
          }}
        />
      ) : null}
    </Stack>
  )
}
