import { Accordion, Grid, Radio, Stack } from '@mantine/core'
import { LendersPosition } from 'app/models/lenders-position-params'
import { WireInstructionParams } from 'app/models/wire-instruction-params'
import { BaseText, BoldText } from 'app/views/components/Headers&Text/Text'
import cleanUUID from 'app/views/components/functions/cleanUUID'

interface Props {
  selectedWireInstructions: any
  currency: string
  setSelectedPaymentInstructions: any
  allWireInstructions: WireInstructionParams[]
  lenderPosition: LendersPosition | LendersPosition
}

export default function OneCurrency({
  selectedWireInstructions,
  currency,
  setSelectedPaymentInstructions,
  allWireInstructions,
}: Props) {


  const selectedWireInstruction = selectedWireInstructions[currency]
  function handleChange(currency: string, e: string): void {
    const wireInstruction = allWireInstructions.find(wi => wi.id == e)
    setSelectedPaymentInstructions(currency, wireInstruction)
  }

  return (
    <Accordion.Item  value={currency} key={currency}>
    <Accordion.Control fw={600} fz="14px" p="lg">
      {currency}
    </Accordion.Control>
    <Accordion.Panel>
        <Radio.Group
          value={selectedWireInstruction?.id ?? ''}
          onChange={e => handleChange(currency, e)}
        >
          {allWireInstructions
            .filter(wireInstruction => wireInstruction.currency === currency)
            .map((wireInstruction, index) => {
              return (
                <Grid key={index} style={{ paddingTop: 20, paddingBottom: 20 }}>
                  <Grid.Col
                    span={1}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Radio value={cleanUUID(wireInstruction.id ?? '')} />
                  </Grid.Col>
                  <Grid.Col span={5}>
                    <Stack spacing="sm">
                      <BoldText text={'Currency'} />
                      <BoldText text={'Bank ABA #'} />
                      <BoldText text={'Account with Institution Name'} />
                      <BoldText text={'Beneficiary Customer Name'} />
                      <BoldText text={'Beneficiary Account #'} />
                      <BoldText text={'Payment Reference'} />
                    </Stack>
                  </Grid.Col>
                  <Grid.Col span={5}>
                    <Stack spacing="sm">
                      <BaseText text={wireInstruction.currency} />
                      <BaseText
                        text={
                          wireInstruction.accountWithInstitutionBankAba &&
                          wireInstruction.accountWithInstitutionBankAba != ''
                            ? wireInstruction.accountWithInstitutionBankAba
                            : '-'
                        }
                      />
                      <BaseText
                        text={
                          wireInstruction.accountWithInstitutionName != null
                            ? wireInstruction.accountWithInstitutionName
                            : '-'
                        }
                      />
                      <BaseText
                        text={
                          wireInstruction.beneficiaryCustomerName != null
                            ? wireInstruction.beneficiaryCustomerName
                            : '-'
                        }
                      />
                      <BaseText
                        text={
                          wireInstruction.beneficiaryAccountNumber != null
                            ? wireInstruction.beneficiaryAccountNumber
                            : '-'
                        }
                      />
                      <BaseText
                        text={
                          wireInstruction.paymentReference &&
                          wireInstruction.paymentReference != ''
                            ? wireInstruction.paymentReference
                            : '-'
                        }
                      />
                    </Stack>
                  </Grid.Col>
                </Grid>
              )
            })}
        </Radio.Group>
      </Accordion.Panel>
    </Accordion.Item>
  )
}
