import React from 'react'

type ReactText = string | number
type ReactChild = React.ReactElement | ReactText
type ReactNode = ReactChild | React.ReactPortal | boolean | null | undefined

type FormWrapperProps = {
  title: string
  children: ReactNode
}

function FormWrapper({ title, children }: FormWrapperProps) {
  return (
    <div className="form-wrapper">
      <h4 className="form-wrapper-title">{title}</h4>
      <div className="form-wrapper-body">{children}</div>
    </div>
  )
}

export default FormWrapper
