export const GET_LOANS_BY_FACILITY = 'loans/GET_LOANS_BY_FACILITY'
export const GET_LOCKED_LOANS_BY_FACILITY = 'loans/GET_LOCKED_LOANS_BY_FACILITY'
export const GET_LOAN = 'loans/GET_LOAN'
export const ADD_LOAN = 'loans/ADD_LOAN'
export const EDIT_LOAN = 'loans/EDIT_LOAN'
export const SUBMIT_LOAN = 'loans/SUBMIT_LOAN'
export const APPROVE_LOAN = 'loans/APPROVE_LOAN'
export const REJECT_LOAN = 'loans/REJECT_LOAN'
export const GET_APPROVED_LOANS = 'loans/GET_APPROVED_LOANS'
export const GET_ALL_APPROVED_LOANS = 'loans/GET_ALL_APPROVED_LOANS'
export const DELETE_LOAN = 'loans/DELETE_LOAN'
export const GET_LOAN_HISTORY = 'loans/GET_HISTORY'
export const GET_LOCKED_LOANS = 'loans/GET_LOCKED_LOANS'
export const START_LOADING_FACILITY_LOANS = 'loans/START_LOADING_FACILITY_LOANS'
export const STOP_LOADING_FACILITY_LOANS = 'loans/STOP_LOADING_FACILITY_LOANS'
export const GET_ALL_LOANS = 'loans/GET_ALL_LOANS'
