import { Accordion, Stack } from "@mantine/core";
import { DealDetailsAccordion } from "./deal-details-accordion";
import { AttachmentsAccordion } from "app/views/components/Accordion/attachments-accordion/attachments-accordion";
import { NotesAccordion } from "app/views/components/Accordion/notes-accordion";
import { ContractType } from 'app/models/common-types'
import { DealParams } from "app/models/deal-params";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { loadNoteByContract } from "app/state/ducks/notes/thunks";
import { useDispatch } from "react-redux";

interface props {
  deal: DealParams
}

export default function DealDetailSidebar({ deal }: props) {
  const { dealId } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!deal?.id) {
      return
    }
    dispatch(loadNoteByContract(deal.id, ContractType.Deals))
  })

  return (
    <Stack className="accordions-header-part">
      <Stack h="100%" bg="#F9F8F8" w="100%" p="md">
        <Accordion
          multiple
          defaultValue={['DealDeatils', 'pdfs', 'notes']}
          variant="contained"
          bg="white"
          styles={{
            control: {
              fontFamily: 'Plus Jakarta Sans',
              '&[data-active]': {
                backgroundColor: '#A4CAFE',
              },
            },
          }}
        >
          <DealDetailsAccordion deal={deal} />
          <AttachmentsAccordion
            id={dealId ?? ''}
            type={ContractType.Deals}
          />
          <NotesAccordion
            contractId={deal?.id ?? ''}
            contractAdmin={deal?.accountManagementAdmin ?? ''}
            type={ContractType.Deals}
          />
        </Accordion>
      </Stack>
    </Stack>
  )
}
