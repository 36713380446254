import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'
import * as reducers from './ducks'
import { apiService } from './middlewares'
import { composeWithDevTools } from 'redux-devtools-extension'

export default function configureStore(initialState = {}) {
  const rootReducer = combineReducers(reducers)

  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(apiService, thunkMiddleware))
  )
}
