import { Group, Stack } from '@mantine/core'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'

export default function ReleaseQueueHeader() {
  return (
    <Stack>
      <Group position="apart" style={{ marginBottom: '15px' }}>
        <PageTitle>Release Queue</PageTitle>
      </Group>
    </Stack>
  )
}
