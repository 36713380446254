import React from 'react'
import { Modal } from '@mantine/core'
import DealsForm from './deal-details/deal-form'

type Props = {
  deal?: any
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  useStepper?: boolean
  amendment?: any
}

export const DealsModal: React.FC<Props> = ({
  open,
  setOpen,
  deal,
  useStepper,
  amendment,
}: Props) => {
  return (
    <Modal
      className="modal-body modal-body-template fit-content-modal"
      opened={open}
      onClose={() => setOpen(false)}
      centered={true}
      size="77%"
    >
      <DealsForm
        closeModal={() => setOpen(false)}
        amendment={amendment}
        deal={deal ?? {}}
        useStepper={useStepper}
      />
    </Modal>
  )
}
