import * as types from './types'

export const getPayments = (payload: any) => ({
  type: types.GET_PAYMENTS,
  payload,
})

export const submitPayment = (payload: any) => ({
  type: types.SUBMIT_PAYMENT,
  payload,
})

export const releasePayment = (payload: any) => ({
  type: types.RELEASE_PAYMENT,
  payload,
})

export const settlePayment = (payload: any) => ({
  type: types.SETTLE_PAYMENT,
  payload,
})
export const getReceivers = (payload: any) => ({
  type: types.GET_RECEIVERS,
  payload,
})
export const getSenders = (payload: any) => ({
  type: types.GET_SENDERS,
  payload,
})
export const getLockedPayments = (payload: any) => ({
  type: types.GET_LOCKED_PAYMENTS,
  payload,
})
export const changeFronting = (payload: any) => ({
  type: types.CHANGE_FRONTING,
  payload,
})
export const lockPayment = (payload: any) => ({
  type: types.CANCEL_PAYMENT,
  payload,
  })
export const changeReceivedPayments = (payload: any) =>({
    type: types.CHANGE_RECEIVED_PAYMENTS,
    payload,
  })
export const rejectReceiver = (payload: any) =>({
    type: types.REJECT_RECEIVER,
    payload,
  })

export const queuePayment = (payload: any) => ({
  type: types.QUEUE_PAYMENT,
  payload,
})

export const startLoadingActivePayments = () => ({
  type: types.START_LOADING_ACTIVE_PAYMENTS,
  payload: null,
})

export const stopLoadingActivePayments = () => ({
  type: types.STOP_LOADING_ACTIVE_PAYMENTS,
  payload: null,
})

export const startLoadingLockedPayments = () => ({
  type: types.START_LOADING_LOCKED_PAYMENTS,
  payload: null,
})

export const stopLoadingLockedPayments = () => ({
  type: types.STOP_LOADING_LOCKED_PAYMENTS,
  payload: null,
})
