import { Text, Accordion } from '@mantine/core'
import NestedAccordion from 'app/views/components/Accordion/nested-accordion'
import OneWireInstructionAccordion from './one-borrower'
import { filteredInstructions } from './borrowers-wireinstructions-params'
import { WireInstructionParams } from 'app/models/wire-instruction-params'

const AccordionList = ({
  initialState,
  setInitialState,
  instructions,
}: {
  initialState: boolean[]
  setInitialState: React.Dispatch<React.SetStateAction<boolean[]>>
  instructions: filteredInstructions[]
}) => {
  const toggleAccordion = (index: string | number) => {
    setInitialState((prevState: boolean[]) =>
      prevState.map((state, idx) => (idx === index ? !state : state))
    )
  }

  return (
    <>
      {instructions.map(
        (entity: filteredInstructions, index: string | number) => (
          <Accordion
            key={entity.id}
            w="100%"
            bg={initialState[index as number] ? '#32439A' : '#F9FAFB'}
            variant="contained"
            value={initialState[index as number] ? index.toString() : ''}
            onChange={() => toggleAccordion(index)}
            styles={{
              control: {
                fontFamily: 'Plus Jakarta Sans',
                '&[data-active]': {
                  backgroundColor: '#32439A',
                },
              },
              chevron: {
                color: initialState[index as number] ? 'white' : '32439A',
              },
            }}
          >
            <Accordion.Item value={index.toString()}>
              <Accordion.Control>
                <Text
                  c={initialState[index as number] ? 'white' : '#32439A'}
                  fw={600}
                  fz="lg"
                >
                  {entity.entityName} (
                  {Array.isArray(entity.instructions)
                    ? entity.instructions.length
                    : 0}{' '}
                  Wire Instructions)
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                <Accordion multiple chevronPosition="left">
                  <NestedAccordion
                    defaultExpanded={true}
                    items={
                      Array.isArray(entity.instructions)
                        ? entity.instructions
                        : [entity.instructions]
                    }
                  >
                    {(item: WireInstructionParams) => (
                      <OneWireInstructionAccordion item={item} />
                    )}
                  </NestedAccordion>
                </Accordion>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        )
      )}
    </>
  )
}

export default AccordionList
