import { FacilityParams } from 'app/models/facility-params'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import { formatDateToUTC } from 'app/utils/util-functions'
import { KpiProps } from 'app/views/components/KPI/KPI'
import { useSelector } from 'react-redux'
import { useKPIFilters } from 'app/views/components/KPI/KPI-filters'

export const getKPIData = (
  data: FacilityParams[],
  setData: React.Dispatch<React.SetStateAction<FacilityParams[]>>,
): KpiProps[] => {
  const businessDate = useSelector(getBusinessDate)

  const filterFunctions: Record<string, (facility: FacilityParams) => boolean> =
    {
      Submitted: (facility: FacilityParams) => facility.status === 'Submitted',
      Approved: (facility: FacilityParams) => facility.status === 'Approved',
      Draft: (facility: FacilityParams) => facility.status === 'Draft',
      Expired: (facility: FacilityParams) =>
        facility.maturity < formatDateToUTC(businessDate),
      Terminated: (facility: FacilityParams) =>
        facility.status === 'Terminated',
    }

  const { applyFilter, appliedFilters } = useKPIFilters({
    data,
    filterFunctions,
    setData,
  })

  if (!data) return []

  const submittedFacilities = data.filter(
    facility => facility.status === 'Submitted',
  )
  const approvedFacilities = data.filter(
    facility => facility.status === 'Approved',
  )
  const draftFacilities = data.filter(facility => facility.status === 'Draft')
  const expiredFacilities = data.filter(
    facility => facility.maturity < formatDateToUTC(businessDate),
  )
  const terminatedFacilities = data.filter(
    facility => facility.status === 'Terminated',
  )

  return [
    {
      title: 'Pending Facilities',
      amount: submittedFacilities.length,
      percentage: Math.round((submittedFacilities.length / data.length) * 100),
      activeFilter: appliedFilters.has('Submitted'),
      event: () => applyFilter('Submitted'),
    },
    {
      title: 'Expiring Facilities',
      amount: expiredFacilities.length,
      percentage: Math.round((expiredFacilities.length / data.length) * 100),
      activeFilter: appliedFilters.has('Expired'),
      event: () => applyFilter('Expired'),
    },
    {
      title: 'In Progress Facilities',
      amount: draftFacilities.length,
      percentage: Math.round((draftFacilities.length / data.length) * 100),
      activeFilter: appliedFilters.has('Draft'),
      event: () => applyFilter('Draft'),
    },
    {
      title: 'Active Facilities',
      amount: approvedFacilities.length,
      percentage: Math.round((approvedFacilities.length / data.length) * 100),
      activeFilter: appliedFilters.has('Approved'),
      event: () => applyFilter('Approved'),
    },
    {
      title: 'Terminated Facilities',
      amount: terminatedFacilities.length,
      percentage: Math.round((terminatedFacilities.length / data.length) * 100),
      activeFilter: appliedFilters.has('Terminated'),
      event: () => applyFilter('Terminated'),
    },
  ]
}
