import React from 'react'
import { PageLayout } from 'app/views/components/layout/page-layout'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'
import { TradeClosingDrillPage } from './trade-closing-drill-page'


export function TradeClosingLandingPage(tab: { tab: string }) {
  return (
    <PageLayout>
      <PageTitle pageTitle='Trade Closing' />
      <TradeClosingDrillPage tab={tab.tab} />
    </PageLayout>
  )
}
