import moment from 'moment';
import { resetInterest } from './reset-interest'; // Adjust import path as necessary
import { LoanParams } from 'app/models/loan-params';
import { Form } from './interfaces';
import { InterestRateParams } from 'app/models/interest-rate';
import { IroValues } from 'app/models/facility-params';
import { roundTo } from 'app/utils/util-functions';
import { IndexRateOptionParams } from 'app/models/index-rate-options-params';


export const calculateNonTermInterests = (
  form: Form,
  loan: LoanParams | undefined,
  dailyRates: InterestRateParams[],
  allIndexes: IndexRateOptionParams[],
  iro: IroValues | undefined,
  date: string,
): void => {
  resetInterest(form, loan);
  if (!iro) {
    return;
  }

  const indexRateSelected = allIndexes.find(index => index.id === iro.indexOption.id);
  const startDate = date;

  dailyRates.sort((a, b) => moment(a.effectiveDate).diff(moment(b.effectiveDate)));

  const iroSelected = [...dailyRates].reverse().find(rate => moment(rate.effectiveDate).isBefore(startDate));

  if (!iroSelected && indexRateSelected?.indexType !== 'FixedIndex') {
    form.setFieldError('indexOption', 'Not found Index rate option for given index option');
    return;
  }

  let interestBaseRate = ''
  if (indexRateSelected?.indexType === 'FixedIndex') {
    interestBaseRate = iro.fixedRate + ''
  }
  else if (iroSelected?.overnightRate) {
    interestBaseRate = iroSelected.overnightRate
  }
  else if (iroSelected?.floatingRate) {
    interestBaseRate = iroSelected.floatingRate
  }

  const interestWithRounding = roundTo(Number(interestBaseRate), iro.rounding ?? 0);

  form.setFieldValue('interestBaseRate', Number(interestBaseRate));
  form.setFieldValue('interestBaseRateWithRounding', Number(interestWithRounding));
  form.setFieldValue('allInRate', Number(interestWithRounding) + (Number(iro.margin) ?? 0));
};