import { Text, Stack, } from '@mantine/core'
import { useSelector } from 'react-redux'
import { DetailSection } from 'app/views/components/detailSections/detail-section'
import { getReceivers } from 'app/state/ducks/payments/selectors'


export default function PaymentWireInstructionsInfo() {
    const receivers = useSelector(getReceivers)

    const paymentWIObjectArray = receivers && receivers.length > 0 ? [
        {
            label: 'Currency',
            value: receivers[0].wireInstruction.currency,
        },
        {
            label: 'Bank ABA #',
            value: receivers[0].wireInstruction.accountWithInstitutionBankAba
        }, {
            label: 'Account with Institution Name',
            value: receivers[0].wireInstruction.accountWithInstitutionName,
        },
        {
            label: 'Beneficiary Customer Name',
            value: receivers[0].wireInstruction.beneficiaryCustomerName,
        },
        {
            label: 'Beneficiary Account #',
            value: receivers[0].wireInstruction.beneficiaryAccountNumber,
        },
        {
            label: 'Payment Reference',
            value: 'New Borrowing',
        }
    ] : []

    return (
        <Stack w='100%' className='sectionRemoveGap sectionBody'>
            <Stack w='100%' className='sectionDetailBodyDrawer'>
                <Text className='sectionDetailBlueHeader'>Wire Instructions</Text>
                <div style={{ paddingLeft: '30px' }}>
                    <DetailSection detailObjArr={paymentWIObjectArray} columnCount={3} />
                </div>
            </Stack>
        </Stack>
    )
}