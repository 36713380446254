import { LoanRateSettingParams } from 'app/models/loan-rate-setting-params'
import {
  submitForApproval,
  rejectSelectedLoanRateSetting,
  approveSelectedLoanRateSetting,
  deleteSelectedLoanRateSetting,
  loadAllLoanRateSettings,
} from 'app/state/ducks/loan-rate-settings/thunks'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'
import { Dispatch } from 'redux'

export async function submitLoanRateSetting(
  loanRateSetting: LoanRateSettingParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(submitForApproval(loanRateSetting))
  if (response.payload.error) {
    ErrorNotification({
      title: 'Submission Failed',
      message: response.payload.error.message,
    })
  } else {
    SuccessNotification({
      title: 'Successful Submission',
      message: 'Loan RateSetting has been Submitted for Approval',
    })
  }
  dispatch(loadAllLoanRateSettings())
  return
}

export async function rejectLoanRateSetting(
  loanRateSetting: LoanRateSettingParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(
    rejectSelectedLoanRateSetting(loanRateSetting)
  )
  if (response.success) {
    SuccessNotification({
      title: 'Successful Rejection',
      message: 'Loan RateSetting has been moved back to a Draft',
    })
  } else {
    ErrorNotification({
      title: 'Rejection Failed',
      message: response.payload.error.message,
    })
  }
  dispatch(loadAllLoanRateSettings())
  return
}

export async function approveLoanRateSetting(
  loanRateSetting: LoanRateSettingParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(
    approveSelectedLoanRateSetting(loanRateSetting)
  )
  if (response.success) {
    SuccessNotification({
      title: 'Successful Approval',
      message: 'Loan Rate Setting has been Approved',
    })
  } else {
    ErrorNotification({
      title: 'Approval Failed',
      message: response.payload.error.message,
    })
  }
  dispatch(loadAllLoanRateSettings())
  return
}

export async function deleteLoanRateSetting(
  loanRateSetting: LoanRateSettingParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(
    deleteSelectedLoanRateSetting(loanRateSetting)
  )
  if (response.payload.error) {
    ErrorNotification({
      title: 'Delete Failed',
      message: response.payload.error.message,
    })
  } else {
    SuccessNotification({
      title: 'Successful Deletion',
      message: 'Loan Rollover has been Deleted',
    })
  }
  dispatch(loadAllLoanRateSettings())
  return
}
