import { ContractType, StableKey } from './common-types'
import { DealDocumentTags, EntityDocumentTags } from './dropdown-options'

export type Document = {
  admin: string
  id: string
  tag: string | null
  externalId: string
  mimeType: string
  isPrivate: boolean
  name: string
  createDate: string
  effectiveDate: string
  contractId: StableKey
  contractType: ContractType
}

export function getDocumentTagOptions(contractType: ContractType) {
  switch (contractType) {
    case ContractType.Deals:
    case ContractType.Facilities:
      return DealDocumentTags
    case ContractType.Entities:
      return EntityDocumentTags
    case ContractType.Principals:
    case ContractType.Payments:
      return []
    default:
      return []
  }
}
