import { Menu } from '@mantine/core'
import PrimaryDropDown from 'app/views/components/DropDown/DropDown'
import { useNavigate } from 'react-router-dom'
import { Fees } from 'app/models/fees-params'
import { useDispatch } from 'react-redux'
import { saveFeePayment } from 'app/state/ducks/fee-payment/thunks'
import IonIcon from '@reacticons/ionicons'

interface actionParam {
    row: Fees
}

export function FeeFacilityServicingActions({ row }: actionParam) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    async function openFeeScheduler() {
        navigate(`/dealmanagement/facilities/${row.facilityId.id}/Approved/fees`)
    }

    async function createPayment() {
        dispatch(saveFeePayment({ facilityId: row.facilityId, effectiveDate: row.firstPaymentDate, feeId: { admin: row?.agencyAdmin ?? '', id: row?.id ?? '' } }))
    }

    return (
        <div>
            <Menu shadow="md" width={200} position="bottom" withinPortal>
                <Menu.Target>
                    <div style={{ width: 'fit-content' }}>
                        <PrimaryDropDown>Actions</PrimaryDropDown>
                    </div>
                </Menu.Target>

                <Menu.Dropdown>
                    <Menu.Item
                        icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
                        onClick={() => openFeeScheduler()}
                    >
                        Scheduler
                    </Menu.Item>
                    <Menu.Item
                        icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
                        onClick={() => createPayment()}
                    >
                        Create Payment
                    </Menu.Item>

                </Menu.Dropdown>
            </Menu>
        </div>
    )
}