import { PageLayout } from 'app/views/components/layout/page-layout'
import DealFeesContentPage from './deal-fees-content-page'

export function DealFeesPage() {

  return (
    <PageLayout>
      <DealFeesContentPage />
    </PageLayout>
  )
}
