import { Auth0Provider } from '@auth0/auth0-react'
import React, {
  PropsWithChildren,
  //  useEffect, useState
} from 'react'
import config from 'app/config/config'

type Auth0ProviderWithConfigProps = {
  children: React.ReactNode
}

export const Auth0ProviderWithConfig = ({
  children,
}: PropsWithChildren<Auth0ProviderWithConfigProps>): JSX.Element | null => {
  const domain = config.oauth.authority
  const clientId = config.oauth.clientId
  const connection = config.oauth.connection
  const audience = config.backendUrl + '/api'
  const redirect_uri = window.location.origin

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri,
        audience,
        connection,
        scope: "openid profile email offline_access"
      }}
    >
      {children}
    </Auth0Provider>
  )
}
