import { Table, Text } from '@mantine/core'
import { OneFundManager } from './one-fund-manager'
import { BoldText } from 'app/views/components/Headers&Text/Text'
import { WireInstructionParams } from 'app/models/wire-instruction-params'

export type LenderListParam = {
  fundManager: string
  creditEmail: string
  adminEmail: string
  lenderName: string
  mei?: string
  address?: string
  address2?: string | null
  city?: string
  state?: string | null
  zipCode?: string
  country?: string
  wireInstructions: (WireInstructionParams | undefined)[]
  amount: number
}

interface Params {
  data: Map<string, LenderListParam[]>
  facilityAmount: number
}

export function LenderListTabel({ data, facilityAmount }: Params) {
  if (data.size == 0) {
    return null
  }
  return (
    <Table>
      <thead>
        <tr style={{ backgroundColor: 'rgb(128 128 128 / 42%)' }}>
          <th style={{ width: '13%' }}>
            <Text>Fund Manager</Text>
          </th>
          <th style={{ width: '10%' }}>
            <Text>Lender Name</Text>
          </th>
          <th style={{ width: '5%' }}>
            <Text>MEI</Text>
          </th>
          <th style={{ width: '10%' }}>
            <Text>Admin Contact Email</Text>
          </th>
          <th style={{ width: '10%' }}>
            <Text>Credit Contact Email</Text>
          </th>
          <th style={{ width: '3%' }}>
            <Text>Curency</Text>
          </th>
          <th style={{ width: '10%' }}>
            <Text>Account</Text>
          </th>
          <th style={{ width: '10%' }}>
            <Text>ABA</Text>
          </th>
          <th style={{ width: '10%' }}>
            <Text>Bank Name</Text>
          </th>
          <th style={{ width: '20%' }}>
            <Text>Address</Text>
          </th>
          <th style={{ width: '8%' }}>
            <Text>Facility Amount</Text>
          </th>
        </tr>
      </thead>

      <tbody>
        {[...data.keys()].map(key => {
          const lenderListData = data.get(key)
          if (!lenderListData || lenderListData.length == 0) {
            return null
          }
          return <OneFundManager data={lenderListData} />
        })}
        <tr>
          <td>
            <BoldText text="Grand Total" />
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <Text>{'$' + facilityAmount}</Text>
          </td>
        </tr>
      </tbody>
    </Table>
  )
}
