import React from 'react'
import { PageLayout } from 'app/views/components/layout/page-layout'
import { EntityDrillPage } from './entity-drill-page'


export function EntityLandingPage(tab: { tab: string }) {
    return (
        <PageLayout>
            <EntityDrillPage tab={tab.tab} />
        </PageLayout>
    )
}