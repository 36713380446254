declare global {
  interface Window {
    env: any
  }
}

// change with your own variables
type EnvType = {
  REACT_APP_COMPANY: string
  REACT_APP_OAUTH_AUTHORITY: string
  REACT_APP_OAUTH_CLIENT_ID: string
  REACT_APP_OAUTH_CONNECTION: string
  REACT_APP_BACKEND_URL: string
  REACT_S3_BUCKET_URL: string
  REACT_APP_VERSION: string
  REACT_APP_RELEASE_DATE: string
  REACT_APP_NOTICES_EMAIL: string
}
export const env: EnvType = { ...process.env, ...window.env }
