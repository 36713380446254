import { Anchor, Breadcrumbs } from '@mantine/core'
import IonIcon from '@reacticons/ionicons'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'
import { PageLayout } from 'app/views/components/layout/page-layout'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { getWireInstructions } from 'app/state/ducks/wire-instructions/selectors'
import { loadWireInstructions } from 'app/state/ducks/wire-instructions/thunks'
import { WireInstructionParams } from 'app/models/wire-instruction-params'
import AccordionList from '../../common/accordion-list-component'
import {
  WireInstruction,
  filteredInstructions,
  Entity,
} from '../../common/borrowers-wireinstructions-params'
import { FacilityParams } from 'app/models/facility-params'
import { getFacilities } from 'app/state/ducks/facilities/selectors'

export default function BorrowersPage() {
  const navigate = useNavigate()
  const { facilityId } = useParams()
  const dispatch = useDispatch()
  const wireInstructions = useSelector(getWireInstructions)
  const [isInstructionsLoaded, setIsInstructionsLoaded] = useState(false)
  const facilities: FacilityParams[] = useSelector(getFacilities)
  const [facility, setFacility] = useState<FacilityParams>()

  useEffect(() => {
    dispatch(loadWireInstructions())
  }, [])

  useEffect(() => {
    setFacility(facilities?.find(f => f.id === facilityId))
  }, [facilities])

  const groupByEntity = wireInstructions.reduce(
    (result: Entity, wireInstruction: WireInstructionParams) => {
      const entityId = wireInstruction.entity.id
      if (!result[entityId]) {
        result[entityId] = {
          id: entityId.replace(/"/g, ''),
          entityName: wireInstruction.entityName,
          instructions: [] as WireInstructionParams[],
        }
      }
      result[entityId].instructions.push(wireInstruction)
      return result
    },
    {}
  )

  const groupedInstructions = Object.values(groupByEntity)

  const filteredInstructions: WireInstruction[] = groupedInstructions.filter(
    group =>
      facility?.borrowers.some(
        (borrower: { id: string }) => borrower.id === group.id
      )
  ) as WireInstruction[]

  const [initialState, setInitialState] = useState(
    filteredInstructions.map(() => false)
  )

  useEffect(() => {
    if (!isInstructionsLoaded && filteredInstructions.length > 0) {
      setInitialState(filteredInstructions.map(() => true))
      setIsInstructionsLoaded(true)
    }
  }, [filteredInstructions])

  const items = [
    { title: 'Deal Management', action: () => navigate('/dealmanagement') },
    { title: 'Facilities', action: () => navigate('/dealmanagement') },
    {
      title: facility?.name,
      action: () =>
        navigate(
          `/dealmanagement/facilities/${facilityId}/${facility?.status}`
        ),
    },
  ].map((item, index) => (
    <Anchor
      onClick={() => item.action()}
      key={index}
      color="#374151"
      fw={500}
      fz="14px"
    >
      {item.title}
    </Anchor>
  ))

  return (
    <PageLayout>
      <Breadcrumbs
        separator={<IonIcon name="chevron-forward-outline" />}
        mt="xs"
      >
        {items}
      </Breadcrumbs>
      <PageTitle pageTitle="Borrowers" />
      <AccordionList
        initialState={initialState}
        setInitialState={setInitialState}
        instructions={filteredInstructions as unknown as filteredInstructions[]}
      />
    </PageLayout>
  )
}
