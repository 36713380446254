export const GET_WIRE_INSTRUCTIONS = 'wireInstructions/getWireInstructions'
export const GET_WIRE_INSTRUCTION = 'wireInstructions/getWireInstruction'
export const ADD_WIRE_INSTRUCTIONS = 'wireInstruction/addWireInstructions'
export const EDIT_WIRE_INSTRUCTIONS = 'wireInstructions/editWireInstructions'
export const DELETE_WIRE_INSTRUCTIONS =
  'wireInstructions/deleteWireInstructions'
export const ACTIVE_WIRE_INSTRUCTION = 'wireInstructions/activeWireInstruction'
export const INACTIVE_WIRE_INSTRUCTION =
  'wireInstructions/inactiveWireInstructions'
export const START_LOADING_WIRE_INSTRUCTIONS =
  'wireInstructions/startLoadingWireInstructions'
export const STOP_LOADING_WIRE_INSTRUCTIONS =
  'wireInstructions/stopLoadingWireInstructions'
