//I LEFT A LOT OF STUFF COMMENTED OUT BECAUSE EVENTUALLY THIS WILL BE FILTERABLE!!


// import { getBusinessDate } from 'app/state/ducks/business-date/selectors';
// import { formatDateToUTC } from 'app/utils/util-functions';
import { KpiProps } from 'app/views/components/KPI/KPI';
// import { useSelector } from 'react-redux';
// import { useKPIFilters } from 'app/views/components/KPI/KPI-filters';
import { LendersPositionWithMapping } from 'app/models/lenders-position-params';

export const positionsKPIData = (
  data: LendersPositionWithMapping[],
  // setData: React.Dispatch<React.SetStateAction<LendersPosition[]>>
): KpiProps[] => {
  if (!data || data.length < 1) return [];

  // const businessDate = useSelector(getBusinessDate);

  // const filterFunctions: Record<string, (position: LendersPosition) => boolean> = {
  //   'Open Trades': (position: LendersPosition) => position.contractType === 'Facilities',
  //   'Pending Activity': (position: LendersPosition) => position.contractType === 'Draft',
  //   'Agent Discrepancy': (position: LendersPosition) => position.contractType === 'Submitted',
  //   'My Positions': (position: LendersPosition) => position.contractType === 'Submitted',
  // };

  // const { applyFilter, appliedFilters } = useKPIFilters({ data, filterFunctions, setData });


  // console.log('appliedFilters', filterFunctions);

  const allPositions = data;
  const openTrades = data.filter(position => position.currency === 'MEow');
  const pendingActivity = data.filter(position => position.currency === 'Draft');
  const agentDiscrepancy = data.filter(position => position.currency === 'Submitted');
  const myPositions = data.filter(position => position.currency === 'Submitted');

  return [
    {
      title: 'All Positions',
      amount: allPositions.length,
      activeFilter: false,
      // event: () => applyFilter('All Positions')
    },
    {
      title: 'Open Trades',
      amount: openTrades.length,
      activeFilter: false,
      // event: () => applyFilter('Open Trades'),
    },
    {
      title: 'Pending Activity',
      amount: pendingActivity.length,
      activeFilter: false,
      // appliedFilters.has('Draft'),
      // event: () => applyFilter('Draft'),
    },
    {
      title: 'Agent Discrepancy',
      amount: agentDiscrepancy.length,
      activeFilter: false,
      // appliedFilters.has('Approved'),
      // event: () => applyFilter('Approved'),
    },
    {
      title: 'My Positions',
      amount: myPositions.length,
      activeFilter: false,
      // appliedFilters.has('Amendment'),
      // event: () => applyFilter('Amendment'),
    },
  ];
};
