import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import {
  getWireInstructions,
  getWireInstruction,
  addWireInstruction,
  editWireInstruction,
  deleteWireInstructions,
  activeWireInstruction,
  inactiveWireInstruction,
  startLoadingWireInstructions,
  stopLoadingWireInstructions,
} from 'app/state/ducks/wire-instructions/actions'
import { WireInstructionParams } from 'app/models/wire-instruction-params'

export const saveWireInstruction =
  (
    savedWireInstruction: WireInstructionParams,
    wireInstruction?: WireInstructionParams,
  ) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    const statusLower = savedWireInstruction.status.toLowerCase() || 'inactive'

    const updated_savedWI: WireInstructionParams = {
      ...savedWireInstruction,
      hasError: false,
      hasSuccessfulEntitySave: false,
      ErrorMessage: '',
      customer_token: config.company.toUpperCase(),
    }

    if (wireInstruction) {
      return client
        .put(
          `${apiServerUrl}/api/${statusLower}-wire-instructions`,
          updated_savedWI,
        )
        .then(wireInstruction => {
          wireInstruction.data.status = savedWireInstruction.status
          const payload = dispatch(editWireInstruction(wireInstruction))
          return { success: true, payload: payload.payload }
        })
        .catch(e => {
          return { success: false, payload: e.response.data.error }
        })
    }
    delete savedWireInstruction.id
    return client
      .post(
        `${apiServerUrl}/api/${statusLower}-wire-instructions`,
        savedWireInstruction,
      )
      .then(wireInstruction => {
        wireInstruction.data.status = statusLower
        const payload = dispatch(addWireInstruction(wireInstruction))
        return { success: true, payload: payload.payload }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

const loadInactiveWireInstructions = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/inactive-wire-instructions`)
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

const loadActiveWireInstructions = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/active-wire-instructions`)
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

export const loadWireInstructions = () => async (dispatch: Dispatch) => {
  try {
    dispatch(startLoadingWireInstructions())
    const [inactiveWireInstructions, activeWireInstructions] =
      await Promise.all([
        loadInactiveWireInstructions(),
        loadActiveWireInstructions(),
      ])
    const allWireInstructions = inactiveWireInstructions
      .map((wireInstruction: WireInstructionParams) => ({
        ...wireInstruction,
        status: 'Inactive',
      }))
      .concat(
        activeWireInstructions.map(
          (wireInstruction: WireInstructionParams) => ({
            ...wireInstruction,
            status: 'Active',
          }),
        ),
      )
    return dispatch(getWireInstructions(allWireInstructions))
  } catch (e) {
    console.log(e)
    return []
  } finally {
    dispatch(stopLoadingWireInstructions())
  }
}

export const loadOneWireInstruction =
  (wireInstructionId: string, status: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    const statusLower = status.toLowerCase()
    return client
      .get(
        `${apiServerUrl}/api/${statusLower}-wire-instructions/one?id=${wireInstructionId}`,
      )
      .then(wireInstruction => {
        return dispatch(
          getWireInstruction({ ...wireInstruction.data, status: status }),
        )
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const removeWireInstructions =
  (wireInstruction: (string | undefined)[]) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .delete(
        `${apiServerUrl}/api/inactive-wire-instructions?ids[]=${wireInstruction}`,
      )
      .then(wireInstructions => {
        return dispatch(deleteWireInstructions(wireInstructions.data))
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const activateWireInstruction =
  (wireInstruction: WireInstructionParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/inactive-wire-instructions/activate`, {
        id: wireInstruction.id,
      })
      .then(response => {
        const payload = dispatch(activeWireInstruction(response.data))
        return { success: true, payload: payload.payload }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const deactivateWireInstruction =
  (wireInstruction: WireInstructionParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/active-wire-instructions/deactivate`, {
        id: wireInstruction.id,
      })
      .then(response => {
        const payload = dispatch(inactiveWireInstruction(response.data))
        return { success: true, payload: payload.payload }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }
