import { EntityParams } from 'app/models/entity-params'
import { EntitiesState } from './reducer'
export const getEntities = (state: {
  entities: { entities: EntityParams[] }
}) => {
  return state.entities.entities
}

export const getEntity = (state: { entities: { entity: EntityParams } }) => {
  return state.entities.entity
}

export const getEntitiesLoader = (state: { entities: EntitiesState }) => {
  return state.entities.isLoading
}
