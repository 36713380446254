import { withAuthenticationRequired } from '@auth0/auth0-react'
import React from 'react'
import { PageLoader } from 'app/views/components/layout/page-loader'

interface ProtectedRouteProps {
  component: React.ComponentType<any>; // Accepts any component with props
  [key: string]: any; // This allows for additional props like `tab`
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component,
  ...rest // Spread the rest of the props here
}) => {
  // Wrap the component with authentication
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="page-layout">
        <PageLoader />
      </div>
    ),
  });

  // Pass additional props (e.g., `tab`) to the wrapped component
  return <Component {...rest} />;
};