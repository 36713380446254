import { Modal } from '@mantine/core'
import { useState } from 'react'
import { UpdatedFacilitiesScreen } from '../../facilities/updated-facilities-screen'
import PrimaryInput from 'app/views/components/buttons/PrimaryInput'

function CreateFacilityButton() {
  const [openFacility, setOpenFacility] = useState(false)

  return (
    <>
      <PrimaryInput
        text="Create Facility"
        onClick={() => setOpenFacility(true)}
      />
      <Modal
        className="modal-body modal-body-template fit-content-modal"
        opened={openFacility}
        onClose={() => setOpenFacility(false)}
        centered={true}
        size="77%"
      >
        <UpdatedFacilitiesScreen
          deal={undefined}
          facility={undefined}
          onClose={() => setOpenFacility(false)}
        />
      </Modal>
    </>
  )
}

export default CreateFacilityButton
