import { MRT_ColumnDef } from 'mantine-react-table'
import { Text } from '@mantine/core'
import { ReactNode } from 'react'
import { ContactParams } from 'app/models/contact-params'
import Pill, { PillColor } from 'app/views/components/Table/Pill'
import ContactActions from './contact-actions'

enum StatusState {
  Active = 'Active',
  Unapproved = 'Unapproved',
  PendingKYC = 'Pending KYC',
  Inactive = 'Inactive',
}

const getPillColor = new Map<StatusState, PillColor>()
getPillColor.set(StatusState.Active, PillColor.LIGHT_BLUE)
getPillColor.set(StatusState.Unapproved, PillColor.PINK)
getPillColor.set(StatusState.PendingKYC, PillColor.ORANGE)
getPillColor.set(StatusState.Inactive, PillColor.GREY)


function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}

export const contactCols: MRT_ColumnDef<ContactParams>[] = [
  {
    size: 100,
    accessorKey: 'name',
    header: 'Name',
    Header: <TableHead header={'Name'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 100,
    accessorKey: 'contactType',
    header: 'Type',
    Header: <TableHead header={'Type'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 100,
    accessorKey: 'status',
    header: 'Status',
    Header: <TableHead header={'Status'} />,
    Cell: ({ cell }) => {
      const color: PillColor =
        getPillColor.get(cell.getValue<string>() as StatusState) ?? PillColor.GREY
      return <Pill text={(cell.getValue<string>() as string) ?? ''} color={color} />
    },
  },
  {
    size: 100,
    accessorKey: 'email',
    header: 'Email',
    Header: <TableHead header={'Email'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 100,
    accessorKey: 'phoneNumber',
    header: 'Phone',
    Header: <TableHead header={'Phone'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 100,
    accessorKey: 'faxNumber',
    header: 'Fax',
    Header: <TableHead header={'Fax'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 100,
    accessorKey: 'id',
    header: 'Actions',
    Header: <TableHead header={'Actions'} />,
    enableColumnActions: false,
    enableColumnFilter: false,
    Cell: cell => <ContactActions row={cell.row.original} />,
  },
]
