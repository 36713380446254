import { Button, Group, Modal, Stack, Title } from '@mantine/core'
import { PaymentParams } from 'app/models/payments-params'
import {
  submitPayments,
  releasePayments,
  settlePayments,
  cancelPayment,
  queuePayments,
} from 'app/state/ducks/payments/thunks'
import { formatNumberToCurrency } from 'app/utils/util-functions'
import { ErrorNotification, SuccessNotification } from 'app/views/components/notifications/notification'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

interface Props {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  payment: PaymentParams
  lenderAllocations?: any
  isCanceled?: boolean
}

export default function PaymentModal({
  open,
  setOpen,
  payment,
  isCanceled,
}: Props) {
  const dispatch = useDispatch()
  const [action, setAction] = useState<string>('')

  useEffect(() => {
    setAction(isCanceled
      ? 'Cancel'
      : payment.status == 'Rejected'
        ? 'Queue'
        : payment.status == 'Released'
          ? 'Settle'
          : payment.submitted
            ? 'Release'
            : 'Submit for Release')
  }, [isCanceled, payment])

  const handleProceedClick = async (payment: any) => {
    let response: any = null
    setOpen(false)
    if (isCanceled) {
      response = await dispatch(cancelPayment(payment))
    }
    if (!isCanceled && payment.status == 'Rejected') {
      response = await dispatch(queuePayments(payment))
    }

    if (!isCanceled && payment.status == 'Released') {
      response = await dispatch(settlePayments(payment))
    }
    if (!isCanceled && payment.status == 'Queued' && payment.submitted) {
      response = await dispatch(releasePayments(payment))
    }
    if (!isCanceled && payment.status == 'Queued' && !payment.submitted) {
      response = await dispatch(submitPayments(payment))
    }
    if (!response) {
      return
    }

    if (response.success === false) {
      ErrorNotification({
        title: 'Action Failed',
        message: response.payload ?? 'Failed to proceed action',
      })
      return
    }

    SuccessNotification({
      title: 'Successful proceed action',
      message: 'Payment action has been successfully processed',
    })
  }

  return (
    <Modal opened={open} onClose={() => setOpen(false)} size="lg">
      <Stack>
        <Title>{action} {formatNumberToCurrency(Number(payment.payment.amount), payment.payment.currency ?? 'USD')} Payment </Title>
        <Group position="right">
          <Button onClick={() => setOpen(false)}>Cancel</Button>

          <Button
            style={{ backgroundColor: '#32439A' }}
            onClick={() => handleProceedClick(payment)}
          >
            Proceed
          </Button>

        </Group>
      </Stack>
    </Modal>
  )
}
