import { Dispatch } from 'redux'
import {
    activateContact,
    removeContacts,
    deactivateContact,
} from 'app/state/ducks/contacts/thunks'
import { ContactParams } from 'app/models/contact-params'
import {
    ErrorNotification,
    SuccessNotification,
} from 'app/views/components/notifications/notification'

export async function setActiveContact(
    contact: ContactParams,
    dispatch: Dispatch<any>
) {
    const response: any = await dispatch(activateContact(contact))
    if (response.success) {
        SuccessNotification({
            title: 'Successful Activated',
            message: 'Contact has been Activated',
        })
    } else {
        ErrorNotification({
            title: 'Approval Failed',
            message: response.payload ?? 'Contact could not be activated',
        })
    }
    return
}

export async function makeInactiveContact(
    contact: ContactParams,
    dispatch: Dispatch<any>
) {
    const response: any = await dispatch(
        deactivateContact(contact)
    )
    if (response.success) {
        SuccessNotification({
            title: 'Successful Deactivation',
            message: 'Contact has been deactivated.',
        })
    } else {
        ErrorNotification({
            title: 'Deactivation Failed',
            message: response.payload ?? 'Contact could not be deactivated',
        })
    }
    return
}

export async function deleteContacts(
    contact: ContactParams,
    dispatch: Dispatch<any>
) {
    const response: any = await dispatch(
        removeContacts([contact.id])
    )
    if (response.payload.error) {
        ErrorNotification({
            title: 'Delete Failed',
            message: response.payload.error.message,
        })
    } else {
        SuccessNotification({
            title: 'Successful Deletion',
            message: 'Contact has been Deleted',
        })
    }
    return
}
