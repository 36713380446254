import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import {
  getJobs,
  getJob,
  addJob,
  editJob,
  deleteJobs,
} from 'app/state/ducks/jobs/actions'
import { JobParams } from 'app/models/job-params'


// for now only Edit Job will be used
export const saveJob =
  (savedJob: JobParams, job?: JobParams) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl

    if (job) {
      return client
        .put(`${apiServerUrl}/api/job-management`, savedJob)
        .then(job => {
           dispatch(editJob(job.data ))
           return { payload: job.data, success: true }
        }).catch(e => {
          return { payload: e.response.data.error, success: false }
        })
    } else {
      return client
        .post(`${apiServerUrl}/api/job-management`, savedJob)
        .then(job => {
           dispatch(addJob({...job.data, sucess:true}))
           return { payload: job.data, success: true }
        }).catch(e => {
          return { payload: e.response.data.error, success: false }
        })
    }
  }

  export const loadAllJobs = () => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl;
    try {
      const response = await client.get(`${apiServerUrl}/api/job-management`);
      const jobs = response.data;
      dispatch(getJobs(jobs));
    } catch (e) {
      console.log(e);
      dispatch(getJobs([]));
    }
  };

export const loadOneJob =
  (jobId: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .get(`${apiServerUrl}/api/job-management/one?id=${jobId}`)
      .then(job => {
        return dispatch(getJob({...job.data}))
      }).catch(e => {
        return { payload: e.response.data.error, success: false }
      })
  }

export const removeJobs = (job: any) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .delete(`${apiServerUrl}/api/job-management?ids[]=${job}`)
    .then(jobs => {
      return dispatch(deleteJobs(jobs.data))
    }).catch(e => {
      return { payload: e.response.data.error, success: false }
    })
}
