import * as types from './types'
import { createReducer } from 'app/state/utils'

const initialState: never[] = []

const accrualsTableReducer = createReducer(initialState)({
  [types.GET_ACCRUALS_TABLE]: (state: any, payload: any) => {
    return { ...state, accrualsTable: payload.payload }
  },
  [types.ADD_ACCRUALS_TABLE]: (state: any, payload: any) => {
    return {
      ...state,
      accrualsTable: [payload.payload, ...state.accrualsTable],
    }
  },
  [types.EDIT_ACCRUALS_TABLE]: (state: any, payload: any) => {
    const index = state.accrualsTable.findIndex(
      (accrualTable: { id: any }) => accrualTable.id === payload.payload.data.id
    )
    const newArray = [...state.accrualsTable]
    newArray[index] = payload.payload.data
    return {
      ...state,
      accrualsTable: newArray,
    }
  },
})

export default accrualsTableReducer
