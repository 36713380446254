import { Group, Stack } from '@mantine/core'
import { useEffect, useState } from 'react'
import { PaymentParams } from 'app/models/payments-params'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import PaymentModal from './payment-modal'
import { ContractType } from 'app/models/common-types'
import { useDispatch, useSelector } from 'react-redux'
import { getSenders } from 'app/state/ducks/payments/selectors'
import { changeFrontingOnPayment, loadReceivers, loadSenders } from 'app/state/ducks/payments/thunks'
import { ErrorNotification, SuccessNotification } from 'app/views/components/notifications/notification'
import { StatusPill } from 'app/views/components/pill/status-pill'
import { loadNoteByContract } from 'app/state/ducks/notes/thunks'
import PaymentDetailsInfo from './payment-details-info'
import NotesAndAttachment from 'app/views/components/notes-and-attachemnt/notes-and-attachment-full'
import PaymentWireInstructionsInfo from './payment-wire-instruction-info'
import { PaymentLenderAllocationTable } from './payment-lender-allocation-table'
import MainDrawer from 'app/views/components/Drawer/MainDrawer'
import SecondaryButton from 'app/views/components/buttons/SecondaryButton'

interface Props {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  row?: PaymentParams
}

export default function ReleaseQueueDrawer({ open, setOpen, row }: Props) {
  const dispatch = useDispatch()
  const senders = useSelector(getSenders)
  const [loading, setLoading] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [isCanceled, setIsCanceled] = useState<boolean>(false)

  useEffect(() => {
    if (!row) {
      return
    }
    dispatch(loadNoteByContract(row.id, ContractType.Payments))
    dispatch(loadSenders(row.id))
    dispatch(loadReceivers(row.id))
    if (row.id == "1") changeFronting(row.payment.fronting)
  }, [row])

  const changeFronting = async (value: boolean) => {
    setLoading(true)
    const response: any = await dispatch(changeFrontingOnPayment(row, value))
    setLoading(false)
    if (response.success === false) {
      ErrorNotification({
        title: 'Change Fronting status Failed',
        message: response.payload ?? 'Failed to change fronting',
      })
      return
    }

    SuccessNotification({
      title: 'Successful changed fronting status',
      message: 'Status is updated.',
    })
  }

  if (!row) {
    return <></>
  }
  return (
    <>
      <MainDrawer
        opened={open}
        setOpen={setOpen}
        titleText={row.payment.facilityName ?? ''}
        statusPill={<StatusPill status={row.status} payment={row} />}
        footer={
          ['Queued', 'Released', 'Rejected'].includes(row.status) ? (
            <Group position="right">
              <SecondaryButton
                variant="outline"
                onClick={() => {
                  setIsCanceled(true)
                  setOpen(false)
                  setOpenModal(true)
                }}
              >
                Cancel
              </SecondaryButton>

              <PrimaryButton
                disabled={
                  loading ||
                  (
                    row.status === 'Queued' &&
                    !row.submitted &&
                    !row.payment.fronting &&
                    senders.some((sender) => !sender.paymentReceived)
                  )}
                onClick={() => {
                  setIsCanceled(false)
                  setOpen(false)
                  setOpenModal(true)
                }}
              >
                {row.status === 'Rejected'
                  ? "Queue"
                  : row.status === 'Released'
                    ? "Settle"
                    : row.submitted ? "Release" : "Submit"}
              </PrimaryButton>
            </Group>) : null
        }
      >
        <Stack style={{ gap: "10px" }} >
          <PaymentDetailsInfo payment={row} />
          <PaymentLenderAllocationTable payment={row} closeDrawer={() => setOpen(false)} />
          {row.payment.transactionType === 'NewBorrowingTransaction' && <PaymentWireInstructionsInfo />}
          <NotesAndAttachment
            itemId={row?.id ?? ''}
            type={ContractType.Payments}
            itemAdmin={row?.payment.agencyAdmin ?? ''}
          />
        </Stack>
      </MainDrawer>

      <PaymentModal
        open={openModal}
        setOpen={setOpenModal}
        payment={row}
        isCanceled={isCanceled}
      />
    </>
  )
}
