import * as types from './types'

export const getPiks = (payload: any) => ({
  type: types.GET_PIKS,
  payload,
})

export const addPik = (payload: any) => ({
  type: types.ADD_PIK,
  payload,
})

export const approvePik = (payload: any) => ({
  type: types.APPROVE_PIK,
  payload,
})
export const deletePik = (payload: any) => ({
  type: types.DELETE_PIK,
  payload,
})
export const submitPik = (payload: any) => ({
  type: types.SUBMIT_PIK,
  payload,
})
export const rejectPik = (payload: any) => ({
  type: types.REJECT_PIK,
  payload,
})
export const editPik = (payload: any) => ({
  type: types.EDIT_PIK,
  payload,
})

export const startLoadingPik = () => ({
  type: types.START_LOADING_PIK,
  payload: null,
})

export const stopLoadingPik = () => ({
  type: types.STOP_LOADING_PIK,
  payload: null,
})
