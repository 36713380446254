import * as types from './types'
import { createReducer } from 'app/state/utils'

const initialState: never[] = []

const facilityTypesReducer = createReducer(initialState)({
  [types.GET_FACILITY_TYPES]: (state: any, payload: any) => {
    return { ...state, facilitytypes: payload.payload }
  },
  [types.ADD_FACILITY_TYPES]: (state: any, payload: any) => {
    if (typeof payload.payload === 'object') {
      return payload.payload
    } else {
      return {
        ...state,
        facilitytypes: [payload.payload, ...state.facilitytypes],
      }
    }
  },
})

export default facilityTypesReducer
