import * as types from './types'

export const setUser = (payload: any) => ({
  type: types.SET_USER,
  payload,
})

export const setRegisteredUser = (payload: any) => ({
  type: types.SET_REGISTERED_USER,
  payload,
})
