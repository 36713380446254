import { DealParams } from 'app/models/deal-params'
import { DealAmendmentState } from './reducer'

export const getDealAmendments = (state: {
  dealAmendments: { dealAmendments: DealParams[] }
}) => state.dealAmendments.dealAmendments

export const getDealAmendment = (state: {
  dealAmendments: { dealAmendment: DealParams }
}) => {
  return state.dealAmendments.dealAmendment
}

export const getDealAmendmentLoader = (state: { dealAmendments: DealAmendmentState }) => {
  return state.dealAmendments.isLoading
}
