import {
  Header,
  Group,
  Button,
  Box,
  Burger,
  Drawer,
  createStyles,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useAuth0 } from '@auth0/auth0-react'
import HeaderStyles from 'app/views/components/layout/Header/HeaderStyles'
// import { NotificationsDropdown } from './NotificationsDropdown'
import { UserHeader } from './UserHeader'
import { LogoHeader } from './LogoHeader'

type LayoutHeaderProps = {
  open: boolean;
};

export const LayoutHeader: React.FC<LayoutHeaderProps> = ({ open }) => {
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false)
  const { classes } = createStyles(theme => HeaderStyles(theme))()
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0()

  const handleLoginBtn = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: '/mywork',
      },
    })
  }

  const handleSignupBtn = () => {
    window.location.href = '/signup'
  }

  const handleLogoutBtn = () => {
    logout({ logoutParams: { returnTo: window.location.origin } })
  }

  return (
    <>
      <Box pb={120}>
        <Header
          height={90}
          p="10px 20px 10px 0px"
          style={{ zIndex: 200 }}
        >
          <Group position="apart" sx={{ height: '100%' }}>
            <LogoHeader open={open} />
            <UserHeader />

            <Burger
              opened={drawerOpened}
              onClick={toggleDrawer}
              className={classes.hiddenDesktop}
            />
          </Group>
        </Header>

        <Drawer
          opened={drawerOpened}
          onClose={closeDrawer}
          size="100%"
          padding="md"
          title="Company Entity"
          className={classes.hiddenDesktop}
          zIndex={1000000}
        >
          <Group position="center" grow pb="xl" px="md">
            {!isAuthenticated && (
              <Button variant="default" onClick={() => handleLoginBtn()}>
                Log in
              </Button>
            )}
            {!isAuthenticated && (
              <Button onClick={() => handleSignupBtn()}>Sign up</Button>
            )}
            {isAuthenticated && (
              <Button onClick={() => handleLogoutBtn()}>Log out</Button>
            )}
          </Group>
          {/* </ScrollArea> */}
        </Drawer>
      </Box >
    </>
  )
}
