import {
    Group,
    Stack,
} from '@mantine/core'
import SegmentControlUpdated from 'app/views/components/segments/segment-control-updated'
import CreateActivityButton from './create-activity-btn'
import { ServicingPageData } from './servicing-page-data'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { loadAllDeals } from 'app/state/ducks/deals/thunks'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import { loadAllFacilities } from 'app/state/ducks/facilities/thunks'
import { loadFeePayments } from 'app/state/ducks/fee-payment/thunks'
import { loadAllFees } from 'app/state/ducks/fees/thunks'
import { loadIndexRateOptions } from 'app/state/ducks/index-rate-options/thunks'
import { loadAllLoanConversions } from 'app/state/ducks/loan-conversions/thunks'
import { loadAllLoanIncreases } from 'app/state/ducks/loan-increases/thunks'
import { loadAllLoanRateSettings } from 'app/state/ducks/loan-rate-settings/thunks'
import { loadAllLoanRollovers } from 'app/state/ducks/loan-rollovers/thunks'
import { loadAllLoans } from 'app/state/ducks/loans/thunks'
import { loadPaymentInKinds } from 'app/state/ducks/payment-in-kind/thunks'
import { loadAllPrincipalPayments } from 'app/state/ducks/principal-payment/thunks'


interface props {
    tab: string
}


export function ServicingDrillPage({ tab }: props) {
    const dispatch = useDispatch()

    const tabs: { [key in props['tab']]: { route: string; label: string; active: boolean; pageRender: JSX.Element; createButton: JSX.Element; historyLink: string; } } = {
        all: { route: `/assetservicing`, label: 'ALL', active: tab === 'all' ? true : false, pageRender: <ServicingPageData />, createButton: <CreateActivityButton />, historyLink: '/assetservicing' },
        scheduled: { route: `/assetservicing/scheduled`, label: 'SCHEDULED', active: tab === 'scheduled' ? true : false, pageRender: <ServicingPageData tab={tab} />, createButton: <CreateActivityButton />, historyLink: '/assetservicing/scheduled' },
        unscheduled: { route: `/assetservicing/unscheduled`, label: 'UNSCHEDULED', active: tab === 'unscheduled' ? true : false, pageRender: <ServicingPageData tab={tab} />, createButton: <CreateActivityButton />, historyLink: '/assetservicing/unscheduled' },
    }

    useEffect(() => {
        dispatch(loadAllLoanRollovers())
        dispatch(loadAllLoanIncreases())
        dispatch(loadAllLoanRateSettings())
        dispatch(loadAllLoans())
        dispatch(loadAllFacilities())
        dispatch(loadAllDeals())
        dispatch(loadEntities())
        dispatch(loadAllPrincipalPayments())
        dispatch(loadIndexRateOptions())
        dispatch(loadPaymentInKinds())
        dispatch(loadFeePayments())
        dispatch(loadAllFees())
        dispatch(loadAllLoanConversions())
    }, [])

    return (
        <Stack w="100%">
            <Group w="100%" position="apart" className='tabsGapsActivity'>
                <Group position="right">
                    <SegmentControlUpdated tabs={tabs} />
                </Group>
                <Group noWrap>
                    {tabs[tab].createButton}
                </Group>
            </Group>
            {tabs[tab].pageRender}
        </Stack>
    )
}