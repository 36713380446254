import { Dispatch } from 'redux'
import {
  // uploadDocument,
  getDocumentByContract,
  editDocumentTag,
  removeDocument,
} from 'app/state/ducks/documents/actions'
import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { ContractType } from 'app/models/common-types'

// commented out because we have massive security concerns
// export const uploadDocumentToS3 =
//   (file: File, id: string, type: ContractType) =>
//   async (dispatch: Dispatch) => {
//     try {
//       const apiServerUrl = config.backendUrl
//       const formData = new FormData()

//       formData.append('file', file)

  //     const response = await client.post(
  //       `${apiServerUrl}/api/documents/upload?type=${type}${id?`&id=${id}`:''}`,
  //       formData
  //     )
  //     const data = response.data

  //     dispatch(uploadDocument(data))
  //     return {success: true, payload: data}
  //   } catch (error) {
  //     return {success: false, payload: error}
  //     console.error('Error uploading file to S3:', error)
  //   }
  // }

export const loadByContract =
  (id: string, type: ContractType) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    try {
      const data = await client.get(
        `${apiServerUrl}/api/documents/${type}/${id}`,
      )
      return dispatch(getDocumentByContract(data.data))
    } catch (error) {
      console.log('THUNK ERROR', error)
    }
  }

export const changeTag =
  (id: string, newTag: string | null) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    try {
      const data = await client.post(`${apiServerUrl}/api/documents/edit-tag`, {
        id,
        newTag,
      })
      return dispatch(editDocumentTag(data.data))
    } catch (error) {
      console.log('THUNK ERROR', error)
    }
  }

export const deleteDocument = (ids: string) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  try {
    const data = await client.delete(
      `${apiServerUrl}/api/documents?ids[]=${ids}`,
    )
    return dispatch(removeDocument(data))
  } catch (error) {
    console.log('THUNK ERROR', error)
  }
}

export const getDocumentFromS3 = async (id: string, mimeType: string) => {
  try {
    const apiServerUrl = config.backendUrl
    const response = await client.get<{ url: string }>(
      `${apiServerUrl}/api/documents/download/${id}/${mimeType}`,
    )
    return { url: response.data.url }
  } catch (error) {
    console.error('THUNK ERROR', error)
    return { url: '' }
  }
}
