import { MRT_ColumnDef } from 'mantine-react-table'
import { Text } from '@mantine/core'
import { ReactNode } from 'react'
import { formatNumberToCurrency } from 'app/utils/util-functions'

function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}

export const columnDefs: MRT_ColumnDef<any>[] = [
  {
    size: 50,
    accessorKey: 'deal',
    header: 'Deal Name',
    Header: <TableHead header={'Deal Name'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    accessorKey: 'facility',
    header: 'Facility Name',
    Header: <TableHead header={'Facility Name'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 50,
    accessorKey: 'amount',
    header: 'Allocation',
    Header: <TableHead header={'Allocation'} />,
    filterVariant: 'range',
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
]

export const colsBorrower: MRT_ColumnDef<any>[] = [
  {
    size: 50,
    accessorKey: 'deal.dealName',
    header: 'Deal Name',
    Header: <TableHead header={'Deal Name'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    accessorKey: 'facility.name',
    header: 'Facility Name',
    Header: <TableHead header={'Facility Name'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 50,
    accessorKey: 'facility.amount',
    header: 'Borrower Allocation',
    Header: <TableHead header={'Borrower Allocation'} />,
    filterVariant: 'range',
    Cell: ({ cell, row }: any) => (
      <TableRowText
        text={formatNumberToCurrency(Number(cell.getValue()), (row.original.facility.currency ?? 'USD'))}
      />
    ),
  },
]

export const colsLender: MRT_ColumnDef<any>[] = [
  {
    size: 50,
    accessorKey: 'deal.dealName',
    header: 'Deal Name',
    Header: <TableHead header={'Deal Name'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    accessorKey: 'facility.name',
    header: 'Facility Name',
    Header: <TableHead header={'Facility Name'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 50,
    accessorKey: 'lenderAllocation.amount',
    header: 'Lender Allocation',
    Header: <TableHead header={'Lender Allocation'} />,
    filterVariant: 'range',
    Cell: ({ cell, row }: any) => (
      <TableRowText
        text={formatNumberToCurrency(Number(cell.getValue()), (row.original.facility.currency ?? 'USD'))}
      />
    ),
  },
]