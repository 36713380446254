import * as types from './types'

export const getLoanConversions = (payload: any) => ({
  type: types.GET_LOAN_CONVERSIONS,
  payload,
})

export const addLoanConversion = (payload: any) => ({
  type: types.ADD_LOAN_CONVERSION,
  payload,
})

export const editLoanConversion = (payload: any) => ({
  type: types.EDIT_LOAN_CONVERSION,
  payload,
})

export const submitLoanConversion = (payload: any) => ({
  type: types.SUBMIT_LOAN_CONVERSION,
  payload,
})

export const approveLoanConversion = (payload: any) => ({
  type: types.APPROVE_LOAN_CONVERSION,
  payload,
})

export const rejectLoanConversion = (payload: any) => ({
  type: types.REJECT_LOAN_CONVERSION,
  payload,
})

export const deleteLoanConversion = (payload: any) => ({
  type: types.DELETE_LOAN_CONVERSION,
  payload,
})

export const startLoadingLoanConversion = () => ({
  type: types.START_LOADING_LOAN_CONVERSION,
  payload: null,
})

export const stopLoadingLoanConversion = () => ({
  type: types.STOP_LOADING_LOAN_CONVERSION,
  payload: null,
})
