import { ContactParams } from "app/models/contact-params"
import { DealParams } from "app/models/deal-params"
import { EntityParams } from "app/models/entity-params"
import { FacilityParams } from "app/models/facility-params"
import { LendersPosition, LendersPositionWithMapping } from "app/models/lenders-position-params"
import { formatNumberToCurrency } from "app/utils/util-functions"

export function createLendersPositionsWithMapping(positions: LendersPosition[], deals: DealParams[], entities: EntityParams[], facilities: FacilityParams[], contacts: ContactParams[]): LendersPositionWithMapping[] {
  return positions.map((position: LendersPosition) => {
    const facility = facilities.find((facility: FacilityParams) => facility.id === position?.contractId?.id)
    const entity = entities.find((ent: EntityParams) => ent.id === position?.lender?.id)
    const agentEntity = entities.find((ent: EntityParams) => ent.id === facility?.adminEntity?.id)
    const opsAdminEntity = contacts.find((contact: ContactParams) => contact?.id === facility?.opsAdmin?.id)
    const deal = deals.find((deal: DealParams) => deal.id === facility?.dealId?.id)
    const settledDateAmount = position?.proRatas ? position.proRatas[position.proRatas.length - 1].amount : ''
    const formattedPositions: LendersPositionWithMapping = {
      dealName: deal?.dealName ?? '',
      dealCusip: deal?.cusip ?? '',
      facilityName: facility?.name ?? '',
      facilityCusip: facility?.cusip ?? '',
      internalName: '',
      entityName: entity?.entityName ?? '',
      entityMei: entity?.mei,
      agentName: agentEntity?.entityName,
      agentMei: agentEntity?.mei,
      opsAdmin: opsAdminEntity?.name,
      currency: facility?.baseCurrency,
      settledDateAmount: formatNumberToCurrency(Number(settledDateAmount), facility?.baseCurrency ?? 'USD') ?? settledDateAmount,
      tdDate: '',
      id: position.lender.id ?? '',
    }
    return formattedPositions
  })
}
