import { LoanRolloverParams } from 'app/models/loan-rollover-params'
import { LoanRolloverState } from './reducer'

export const getLoanRollovers = (state: {
  loanRollovers: { loanRollovers: LoanRolloverParams[] }
}) => state.loanRollovers.loanRollovers

export const getApprovedLoanRollovers = (state: {
  loanRollovers: { approvedLoanRollovers: LoanRolloverParams[] }
}) => state.loanRollovers.approvedLoanRollovers

export const getLoanRolloversLoader = (state: { loanRollovers: LoanRolloverState }) => 
  state.loanRollovers.isLoading 