import {
    Group,
    Stack,
} from '@mantine/core'
import { ContractType } from 'app/models/common-types'
import NotesAndAttachment from 'app/views/components/notes-and-attachemnt/notes-and-attachment-full'
import SectionTitle from 'app/views/components/Headers&Text/SectionTitle'
import { FacilityParams } from 'app/models/facility-params'
import DealRelatedFacilitiesDetails from '../../deals/deal-details/deal-related-facilities-details'
import FacilityDetailsInfo from './facility-details-info'

interface props {
    facility: FacilityParams | undefined
}

export default function FacilityPage({ facility }: props) {

    return (
        <Stack w="100%">
            <Stack w="100%" className='sectionRemoveGap'>
                <SectionTitle>Facility Overview</SectionTitle>
                <Stack w='100%' className='sectionRemoveGap sectionBody'>
                    <FacilityDetailsInfo />
                </Stack>
            </Stack>
            <DealRelatedFacilitiesDetails />
            <Group w="100%" noWrap mt='30px'>
                <NotesAndAttachment
                    itemId={facility?.id ?? ''}
                    type={ContractType.Facilities}
                    itemAdmin={facility?.accountManagementAdmin ?? ''}
                />
            </Group>
        </Stack>
    )
}