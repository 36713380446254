import { Table, Text } from '@mantine/core'
import { InterestRateParams } from 'app/models/interest-rate'

interface Props {
  data: InterestRateParams[]
}

export function FloatingTable({ data }: Props) {
  return (
    <div
      style={{
        border: '1px solid #D1D5DB',
        backgroundColor: '#32439A',
        fontWeight: 'bold',
      }}
    >
      <Table>
        <thead>
          <tr>
            <th style={{ width: '10%' }}>
              <Text color="white">Effective Date</Text>
            </th>
            <th style={{ width: '10%' }}>
              <Text color="white">Rate value</Text>
            </th>
          </tr>
        </thead>
        <tbody style={{ backgroundColor: '#E5E7EB' }}>
          {data &&
            data.map((item, index) => {
              return (
                <tr
                  key={index}
                  style={{ border: '1px solid #D1D5DB', height: '3vw' }}
                >
                  <td>
                    <Text>{item.effectiveDate}</Text>
                  </td>
                  <td>
                    <Text>{item.floatingRate ?? ''}</Text>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </Table>
    </div>
  )
}
