import React from 'react';

interface AppVersionInfoProps {
    version: string;
    releaseDate: string;
    open: boolean;
}

const AppVersionInfo: React.FC<AppVersionInfoProps> = ({ version, releaseDate, open }) => {
    const formattedDate = open ? releaseDate : releaseDate.slice(2);

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 'auto',
                paddingTop: '1.5rem',
                paddingBottom: '1.5rem',
                fontSize: '0.875rem',
                color: '#6b7280',
            }}
        >
            {open
                ? `Version ${version} - Release Date: ${releaseDate}`
                : `v${version} ${formattedDate}`}
        </div>
    );
};

export default AppVersionInfo;
