import { StableKey } from './common-types'

export type TermRates = {
  overnight: string
  oneWeek: string
  twoWeeks: string
  threeWeeks: string
  oneMonth: string
  twoMonths: string
  threeMonths: string
  fourMonths: string
  fiveMonths: string
  sixMonths: string
  sevenMonths: string
  eightMonths: string
  nineMonths: string
  tenMonths: string
  elevenMonths: string
  oneYear: string
}

export type InterestRateParams = {
  id?: string
  effectiveDate: string
  indexId: StableKey
  termRates: TermRates
  floatingRate: string
  overnightRate: string
}

export const getInterestForPeriod = (term: TermRates, period: string) => {
  switch (period) {
    case 'Overnight':
      return term.overnight
    case 'OneWeek':
      return term.oneWeek
    case 'TwoWeeks':
      return term.twoWeeks
    case 'ThreeWeeks':
      return term.threeWeeks
    case 'OneMonth':
      return term.oneMonth
    case 'TwoMonths':
      return term.twoMonths
    case 'ThreeMonths':
      return term.threeMonths
    case 'FourMonths':
      return term.fourMonths
    case 'FiveMonths':
      return term.fiveMonths
    case 'SixMonths':
      return term.sixMonths
    case 'SevenMonths':
      return term.sevenMonths
    case 'EightMonths':
      return term.eightMonths
    case 'NineMonths':
      return term.nineMonths
    case 'TenMonths':
      return term.tenMonths
    case 'ElevenMonths':
      return term.elevenMonths
    case 'OneYear':
      return term.oneYear
  }
  return 0
}
