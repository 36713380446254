import { PageLayout } from 'app/views/components/layout/page-layout'
import ReleaseQueueHeader from './release-queue-header'
import { LockedReleaseQueueDrillPage } from './locked_release_queue_drill_page '

export function LockedReleaseQueueLandingPage(tab: { tab: string }) {

  return (
    <PageLayout>
      <ReleaseQueueHeader />
      <LockedReleaseQueueDrillPage tab={tab.tab} />
    </PageLayout>
  )
}
