import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import {
  getContacts,
  addContact,
  editContact,
  deleteContacts,
  activeContact,
  inactiveContact,
  getContact,
  startLoadingContacts,
  stopLoadingContacts,
} from 'app/state/ducks/contacts/actions'
import { ContactParams } from 'app/models/contact-params'

export const saveContact =
  (savedContact: ContactParams, contact?: ContactParams) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    const statusLower = savedContact.status.toLowerCase() || 'inactive'

    if (contact) {
      return client
        .put(`${apiServerUrl}/api/${statusLower}-contacts`, savedContact)
        .then(contact => {
          const payload = dispatch(editContact(contact))
          return { success: true, payload: payload.payload }
        })
        .catch(e => {
          return { success: false, payload: e.response.data.error }
        })
    }
    delete savedContact.id
    return client
      .post(`${apiServerUrl}/api/${statusLower}-contacts`, savedContact)
      .then(contact => {
        contact.data.status = statusLower
        const payload = dispatch(addContact(contact))
        return { success: true, payload: payload.payload }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

const loadInactiveContacts = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/inactive-contacts`)
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

const loadActiveContacts = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/active-contacts`)
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

export const loadContacts = () => async (dispatch: Dispatch) => {
  try {
    dispatch(startLoadingContacts())
    const [inactiveContacts, activeContacts] = await Promise.all([
      loadInactiveContacts(),
      loadActiveContacts(),
    ])
    const allContacts = inactiveContacts
      .map((contact: any) => ({ ...contact, status: 'Inactive' }))
      .concat(
        activeContacts.map((contact: any) => ({
          ...contact,
          status: 'Active',
        })),
      )
    return dispatch(getContacts(allContacts))
  } catch (e) {
    console.log(e)
    return []
  } finally {
    dispatch(stopLoadingContacts())
  }
}

export const loadOneContact =
  (contactId: string, status: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    const statusLower = status.toLowerCase()
    return client
      .get(`${apiServerUrl}/api/${statusLower}-contacts/one?id=${contactId}`)
      .then(contact => {
        return dispatch(getContact({ ...contact.data, status: status }))
      })
      .catch(e => {
        return { payload: e.response.data.error, success: false }
      })
  }

export const removeContacts = (contact: any) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .delete(`${apiServerUrl}/api/inactive-contacts?ids[]=${contact}`)
    .then(contacts => {
      return dispatch(deleteContacts(contacts.data))
    })
    .catch(e => {
      return { payload: e.response.data.error, success: false }
    })
}

export const activateContact =
  (contact: ContactParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/inactive-contacts/activate`, {
        id: contact.id,
      })
      .then(response => {
        dispatch(activeContact(response.data))
        return { success: true }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const deactivateContact =
  (contact: ContactParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/active-contacts/deactivate`, {
        id: contact.id,
      })
      .then(response => {
        dispatch(inactiveContact(response.data))
        return { success: true }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }
