import { Button } from '@mantine/core'
import IonIcon from '@reacticons/ionicons'
import { ReactNode, forwardRef } from 'react'

interface Props {
  children?: ReactNode
  isCreateNew?: boolean
}

const PrimaryDropDown = forwardRef<HTMLButtonElement, Props>(
  ({ children, isCreateNew, ...others }: Props, ref) => (
    <Button
      p={isCreateNew ? '6px 30px 6px 30px' : undefined}
      ref={ref}
      style={{
        fontFamily: 'Plus Jakarta Sans',
        color: '#fff',
        backgroundColor: '#32439A',
        borderRadius: 4,
        height: 34,
        fontSize: 14,
      }}
      {...others}
    >
      {children}
      <IonIcon name='chevron-down-outline' style={{ marginLeft: '10px' }} />
    </Button>
  )
)

export default PrimaryDropDown
