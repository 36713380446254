import { Menu } from '@mantine/core'
import PrimaryDropDown from 'app/views/components/DropDown/DropDown'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { WireInstructionParams } from 'app/models/wire-instruction-params'
import IonIcon from '@reacticons/ionicons'
import { WireInstructionsModal } from './modal-wire-instructions'
import { setActiveWireInstruction, makeInactiveWireInstruction, deleteWireInstructions } from './wire-instructions-daml-actions'

type Props = {
  row: WireInstructionParams
}

export default function WireInstructionActions({ row }: Props) {
  const dispatch = useDispatch()
  const [addWireInstructions, setAddWireInstructions] = useState<boolean>(false)

  function editWireInstruction() {
    setAddWireInstructions(true)
  }

  return (
    <div className='wireinstructionActions'>
      <WireInstructionsModal
        setOpen={setAddWireInstructions}
        open={addWireInstructions}
        paymentInstruction={row}
      />
      <Menu shadow="md" width={200} position="bottom" withinPortal>
        <Menu.Target>
          <div style={{ width: 'fit-content' }}>
            <PrimaryDropDown>Actions</PrimaryDropDown>
          </div>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
            onClick={() => editWireInstruction()}
          >
            Edit
          </Menu.Item>

          {row?.status?.toLowerCase() == 'inactive' ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => {
                setActiveWireInstruction(row, dispatch)
              }}
            >
              Activate
            </Menu.Item>
          ) : (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => {
                makeInactiveWireInstruction(row, dispatch)
              }}
            >
              Deactivate
            </Menu.Item>
          )}

          {row?.status?.toLowerCase() == 'inactive' ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name='trash-outline' />}
              onClick={() => {
                deleteWireInstructions(row, dispatch)
              }}
            >
              Delete
            </Menu.Item>
          ) : null}
        </Menu.Dropdown>
      </Menu>
    </div>
  )
}
