import { Menu, Modal } from '@mantine/core'
import PrimaryDropDown from 'app/views/components/DropDown/DropDown'
import { useDispatch, useSelector } from 'react-redux'
import { LoanIncreaseParams } from 'app/models/loan-increase-params'
import {
  approveLoanIncrease,
  deleteLoanIncrease,
  rejectLoanIncrease,
  submitLoanIncrease,
} from './increase-daml-actions'
import { useEffect, useState } from 'react'
import { loadOneLoan } from 'app/state/ducks/loans/thunks'
import { getAllApprovedLoans } from 'app/state/ducks/loans/selectors'
import IncreaseFormLogic from './increase-form-logic'
import IonIcon from '@reacticons/ionicons'
import { LoanParams } from 'app/models/loan-params'

type Props = {
  row: LoanIncreaseParams
}

export default function LoanIncreaseActions({ row }: Props) {
  const allLoans = useSelector(getAllApprovedLoans)
  const [openNewIncrease, setOpenNewIncrease] = useState(false)
  const [loan, setLoan] = useState<LoanParams>()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadOneLoan(row.loanId.id ?? '', 'Approved'))
  }, [])

  function openIncrease() {
    setLoan(allLoans.find(l => l.id === row.loanId.id))
    setOpenNewIncrease(true)
  }


  return (
    <div>
      <Modal
        size="50%"
        className="modal-body modal-body-template fit-content-modal form-no-stepper"
        opened={openNewIncrease}
        onClose={() => setOpenNewIncrease(false)}
      >
        {loan ? <IncreaseFormLogic loan={loan} close={() => setOpenNewIncrease(false)} /> : null}
      </Modal>
      <Menu shadow="md" width={200} position="bottom" withinPortal>
        <Menu.Target>
          <div style={{ width: 'fit-content' }}>
            <PrimaryDropDown>Actions</PrimaryDropDown>
          </div>
        </Menu.Target>

        <Menu.Dropdown>
          {row?.status?.toLowerCase() == 'draft' ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => openIncrease()}
            >
              Edit
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'draft' ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => {
                submitLoanIncrease(row, dispatch)
              }}
            >
              Submit For Approval
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'submitted' ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => {
                approveLoanIncrease(row, dispatch)
              }}
            >
              Approve
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'approved' ? (
            <Menu.Item icon={<IonIcon className="action-ion-icon" name='open-outline' />}>Amendment</Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() != 'approved' ? (
            <>
              <Menu.Divider />
              <Menu.Label>Danger zone</Menu.Label>
            </>
          ) : null}

          {row?.status?.toLowerCase() == 'draft' ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name='trash-outline' />}
              onClick={() => {
                deleteLoanIncrease(row, dispatch)
              }}
            >
              Delete
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'submitted' ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => {
                rejectLoanIncrease(row, dispatch)
              }}
            >
              Reject
            </Menu.Item>
          ) : null}
        </Menu.Dropdown>
      </Menu>
    </div>
  )
}
