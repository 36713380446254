export type StableKey = {
  id: string
  admin: string
}

export type Tuple<T1,T2> = {
  _1: T1,
  _2: T2
}

export type OptionalBankInfo = {
  bankName: string
  bankAba: string | null | undefined
  bankBic: string | null | undefined
}

export enum ContractType {
  Deals = 'Deals',
  Facilities = 'Facilities',
  Entities = 'Entities',
  Principals = 'Principals',
  Payments = 'Payments',
  Fees = 'Fees',
}


export enum NoteStateTypes {
  Deals = 'dealNote',
  Facilities = 'facilityNote',
  Entities = 'entityNote',
  Principals = 'principalNote',
  Payments = 'paymentNote',
  Fees = 'feeNote',
}


export enum DocumentStateTypes {
  Deals = 'dealDocuments',
  Facilities = 'facilityDocuments',
  Entities = 'entityDocuments',
  Principals = 'principalDocuments',
  Payments = 'paymentDocuments',
  Fees = 'feeDocuments',
}
