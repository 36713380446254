import { Group, Stack, Text } from "@mantine/core";

interface Props {
    detailObjArr: { label: string | JSX.Element; value: string | null | undefined | JSX.Element; }[];
    columnCount?: number;
}

export function DetailSection({ detailObjArr, columnCount = 1 }: Props) {
    // Create an array of columns where each column is an array of items
    const columns: { label: string | JSX.Element; value: string | JSX.Element | null | undefined; }[][] = Array.from({ length: columnCount }, () => []);

    // Distribute items into columns in a round-robin fashion
    detailObjArr.forEach((item, index) => {
        columns[index % columnCount].push(item);
    });

    // Build the columns
    const builtWithColumns = columns.map((columnItems, columnIndex) => (
        <Stack key={columnIndex} w={`${100 / columnCount}%`}>
            {columnItems.map((obj, itemIndex) => (
                <Stack key={itemIndex} className='sectionObject'>
                    <Text className="sectionItemTitle">{obj.label}</Text>
                    <Text className="sectionItem">{obj.value ?? <>&nbsp;</>}</Text>
                </Stack>
            ))}
        </Stack>
    ));

    return (
        <Group w='100%' noWrap align="top">
            {builtWithColumns}
        </Group>
    );
}
