export type ServicingParams = {
  id: string
  facilityName: string
  stage: string
  transaction: string
  status: string
  amount: number
  currency: string
  serviceTime: string | number | Date
  admin: string
  notices: string
  effectiveDate: string | Date
  scheduled: string
  type: ServicingTypes
  original?: null | any
}

export enum ServicingTypes {
  loanConversion = 'loanConversion',
  amortizationSchedule = 'amortizationSchedule',
  maturingLoan = 'maturingLoan',
  maturingDeal = 'maturingDeal',
  maturingFacility = 'maturingFacility',
  loanRollover = 'loanrollover',
  loanIncrease = 'loanincrease',
  loanRateSetting = 'loanratesetting',
  principalPayment = 'principalPayment',
  base = 'base',
  loanAddRates = 'loanAddRates',
  newBorrowing = 'newBorrowing',
  pik = 'PIK',
  feePayment = 'feepayment',
  fee = 'fee',
}
