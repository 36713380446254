import { LoanConversionParams } from 'app/models/loan-conversion-params'
import { approveSelectedLoanConversion, deleteSelectedLoanConversion, loadAllLoanConversions, rejectSelectedLoanConversion, submitForApproval } from 'app/state/ducks/loan-conversions/thunks'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'
import { Dispatch } from 'redux'

export async function submitLoanConversion(
  loanConversion: LoanConversionParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(submitForApproval(loanConversion))
  if (response.success) {
    SuccessNotification({
      title: 'Successful Submission',
      message: 'Loan Conversion has been Submitted for Approval',
    })
  } else {
    ErrorNotification({
      title: 'Submission Failed',
      message: response.payload ?? 'Could not submit Loan Conversion',
    })
  }
  dispatch(loadAllLoanConversions())
  return
}

export async function rejectLoanConversion(
  loanConversion: LoanConversionParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(rejectSelectedLoanConversion(loanConversion))
  if (response.success) {
    SuccessNotification({
      title: 'Successful Rejection',
      message: 'Loan Conversion has been moved back to a Draft',
    })
  } else {
    ErrorNotification({
      title: 'Rejection Failed',
      message: response.payload ?? 'Could not reject Loan Conversion',
    })
  }
  dispatch(loadAllLoanConversions())
  return
}

export async function approveLoanConversion(
  loanConversion: LoanConversionParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(
    approveSelectedLoanConversion(loanConversion)
  )
  if (response.success) {
    SuccessNotification({
      title: 'Successful Approval',
      message: 'Loan Conversion has been Approved',
    })
  } else {
    ErrorNotification({
      title: 'Approval Failed',
      message: response.payload ?? 'Could not approve Loan Conversion',
    })
  }
  dispatch(loadAllLoanConversions())
  return
}

export async function deleteLoanConversion(
  loanConversion: LoanConversionParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(deleteSelectedLoanConversion(loanConversion))
  if (response.payload.error) {
    ErrorNotification({
      title: 'Delete Failed',
      message: response.payload.error.message,
    })
  } else {
    SuccessNotification({
      title: 'Successful Deletion',
      message: 'Loan Conversion has been Deleted',
    })
  }
  dispatch(loadAllLoanConversions())
  return
}
