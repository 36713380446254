import { Text } from '@mantine/core'

interface Props {
  text?: string | number
}

export function BoldText({ text }: Props) {
  return (
    <Text
      color={'#6B7280'}
      fz="12px"
      fw={600}
      sx={{
        fontFamily: 'Plus Jakarta Sans',
      }}
    >
      {text}
    </Text>
  )
}

export function BaseText({ text }: Props) {
  return (
    <Text
      color="#111928"
      fz="14px"
      fw={400}
      sx={{
        fontFamily: 'Plus Jakarta Sans',
      }}
    >
      {text}
    </Text>
  )
}

export function ClickText({ text }: Props) {
  return (
    <Text
      color="#3b38f2"
      fz="14px"
      fw={400}
      sx={{
        fontFamily: 'Plus Jakarta Sans',
      }}
    >
      {text}
    </Text>
  )
}
