/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllActiveNotices, getAllLockedNotices, getActiveNoticeLoader, getLockedNoticeLoader } from 'app/state/ducks/notices/selectors'
import KPI_Section from 'app/views/components/KPI/KPI-horizontal-section'
import { cols } from './notices-columnDefs'
import { loadActiveNotices, loadLockedNotices } from 'app/state/ducks/notices/thunks'
import { NoticeParams } from 'app/models/notices-params'
import { Stack } from '@mantine/core'
import { getKPIData } from './notices-kpi-data'
import MainTable from 'app/views/components/Table/MainTable'


interface props {
    tab?: string | null | undefined
}

export function NoticesPage({ tab }: props) {
    const [row, setRow] = useState<NoticeParams>()
    const [open, setOpen] = useState<boolean>(false)
    const activeNotices: NoticeParams[] = useSelector(getAllActiveNotices)
    const lockedNotices: NoticeParams[] = useSelector(getAllLockedNotices)
    const activeNoticeLoader = useSelector(getActiveNoticeLoader)
    const lockedNoticeLoader = useSelector(getLockedNoticeLoader)
    const [globalLoading, setGlobalLoading] = useState(false)
    const [filteredNotices, setFilteredNotices] = useState<NoticeParams[]>(
        tab === 'archived' ? lockedNotices : activeNotices
    )

    const kpiData = getKPIData(tab === "archived" ? lockedNotices : activeNotices, setFilteredNotices)
    const dispatch = useDispatch()

    function handleRowClicked(e: any) {
        setRow(e)
        setOpen(true)
    }

    useEffect(() => {
        setGlobalLoading(activeNoticeLoader || lockedNoticeLoader)
    }, [activeNoticeLoader, lockedNoticeLoader])

    useEffect(() => {
        if (tab === 'archived') {
            dispatch(loadLockedNotices())
        } else {
            dispatch(loadActiveNotices())
        }
    }, [])

    useEffect(() => {
        if (tab === 'archived') {
            setFilteredNotices(lockedNotices)
        } else {
            setFilteredNotices(activeNotices)
        }
    }, [tab, activeNotices, lockedNotices])

    return (
        <Stack>
            <KPI_Section
                kpiData={kpiData}
                isLoading={globalLoading}
            />
            <MainTable
                tableName="Notices Table"
                columnDefs={cols()}
                data={filteredNotices.sort((a, b) => a.effectiveDate.localeCompare(b.effectiveDate))}
                setRow={handleRowClicked}
                isLoading={globalLoading}
                headerBackgroundColor='#F0EEEE'
                enableTopToolbar={false}
                withPagination={true}
                csvExportEnabled={true}
            />
        </Stack>
    )
}
