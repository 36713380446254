import { MRT_ColumnDef } from 'mantine-react-table'
import { Text } from '@mantine/core'
import { ReactNode } from 'react'

export type SenderTableParams = {
    lender: string,
    currency: any,
    amountExpected: string,
    received: any,
}

function TableHead({ header }: { header: string }) {
    return (
        <Text
            style={{
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '1.5em',
            }}
        >
            {header}
        </Text>
    )
}

function TableRowText({ text }: { text: ReactNode }) {
    return (
        <Text
            style={{
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '1.5em',
            }}
        >
            {text}
        </Text>
    )
}
export const columnDefs: MRT_ColumnDef<SenderTableParams>[] = [
    {
        size: 60,
        accessorKey: 'lender',
        header: 'lender',
        Header: <TableHead header={'Lender'} />,
        Cell: ({ cell }) => {
            return <TableRowText text={cell.getValue() as string} />
        },
    },
    {
        size: 30,
        accessorKey: 'currency',
        header: 'Currency',
        Header: <TableHead header={'Currency'} />,
        Cell: ({ cell }) => {
            return <TableRowText text={cell.getValue() as string} />

        },
    },
    {
        size: 60,
        accessorKey: 'amountExpected',
        header: 'amountExpected',
        Header: <TableHead header={'Amount Expected'} />,
        filterVariant: 'range',
        Cell: ({ cell }: any) => {
            return <TableRowText text={cell.getValue() as string} />

        },
    },
    {
        size: 40,
        accessorKey: 'received',
        header: 'Received',
        Header: <TableHead header={'Received'} />,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ cell }: any) => {
            return <TableRowText text={cell.getValue() as string} />
        },
    },
]
