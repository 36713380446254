import { PrincipalPaymentParams } from "app/models/principal-payments-params"
import { savePrincipalPayment, submitForApproval } from "app/state/ducks/principal-payment/thunks"
import { ErrorNotification, SuccessNotification } from "app/views/components/notifications/notification"
import axios from "axios"

export const createDraftPrincipalPayment = async (form: any, dispatch:any, payment?:PrincipalPaymentParams, ) => {
    try {
      const loanPrincipalPayment = form.getTransformedValues()

      const response: any = await dispatch(
        savePrincipalPayment(loanPrincipalPayment, payment)
      )
      if (response.success) {
        SuccessNotification({
          title: 'Successfully Saved Draft Loan Payment',
          message: 'You created or edited a Loan Payment',
        })

        return { ...response.payload.data, activeLenders: form.values.activeLenders }
      } else {
        ErrorNotification({
          title: 'Failed to Create Payment',
          message: response.payload,
        })
        return null
      }
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response?.status == 400) {
        const data: any = error.response.data
        ErrorNotification({
          title: 'Failed to Create Payment',
          message: data?.error ?? 'Please check inputs',
        })
      } else {
        ErrorNotification({
          title: 'Failed to Create Payment',
          message: error.toString() ?? 'Please check inputs',
        })
      }
      return null
    }
  }

  export const saveAndSubmit = async (form:any, dispatch:any, payment?:PrincipalPaymentParams) => {
      const response: any = await createDraftPrincipalPayment(form, dispatch, payment)
      try {
        if (!response) {
          return
        }

        const result: any = await dispatch(
          submitForApproval(response)
        )
        if (!result.payload.error) {
          SuccessNotification({
            title: 'Successful Payment',
            message: 'loan payment submitted successfully',
          })
        } else {
          ErrorNotification({
            title: 'Payment created but not submitted.',
            message: result.payload.error.message,
          })
        }
      } finally {
        close()
      }
    }
