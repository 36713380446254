import { Note } from 'app/models/note-params'
import * as types from './types'
import { createReducer } from 'app/state/utils'
import { ContractType } from 'app/models/common-types'

export interface NoteState {
  dealNote?: Note | null
  facilityNote?: Note | null
  entityNote?: Note | null
  principalNote?: Note | null
  paymentNote?: Note | null
  feeNote?: Note | null
}

const initialState: NoteState = {}

const notesReducer = createReducer(initialState)({
  [types.GET_NOTE_BY_CONTRACT]: (state: any, payload: any) => {
    return {
      dealNote: payload.payload.contractType === ContractType.Deals ? payload.payload : state.dealNote,
      facilityNote: payload.payload.contractType === ContractType.Facilities ? payload.payload : state.facilityNote,
      entityNote: payload.payload.contractType === ContractType.Entities ? payload.payload : state.entityNote,
      principalNote: payload.payload.contractType === ContractType.Principals ? payload.payload : state.principalNote,
      paymentNote: payload.payload.contractType === ContractType.Payments ? payload.payload : state.paymentNote,
      feeNote: payload.payload.contractType === ContractType.Fees ? payload.payload : state.feeNote,
    }
  },
  [types.ADD_NOTE]: (state: any, payload: any) => {
    return {
      dealNote: payload.payload.contractType === ContractType.Deals ? payload.payload : state.dealNote,
      facilityNote: payload.payload.contractType === ContractType.Facilities ? payload.payload : state.facilityNote,
      entityNote: payload.payload.contractType === ContractType.Entities ? payload.payload : state.entityNote,
      principalNote: payload.payload.contractType === ContractType.Principals ? payload.payload : state.principalNote,
      paymentNote: payload.payload.contractType === ContractType.Payments ? payload.payload : state.paymentNote,
      feeNote: payload.payload.contractType === ContractType.Fees ? payload.payload : state.feeNote,
    }
  },
  [types.EDIT_NOTE]: (state: any, payload: any) => {
    return {
      dealNote: payload.payload.contractType === ContractType.Deals ? payload.payload : state.dealNote,
      facilityNote: payload.payload.contractType === ContractType.Facilities ? payload.payload : state.facilityNote,
      entityNote: payload.payload.contractType === ContractType.Entities ? payload.payload : state.entityNote,
      principalNote: payload.payload.contractType === ContractType.Principals ? payload.payload : state.principalNote,
      paymentNote: payload.payload.contractType === ContractType.Payments ? payload.payload : state.paymentNote,
      feeNote: payload.payload.contractType === ContractType.Fees ? payload.payload : state.feeNote,
    }
  },
  [types.SET_NULL]: (state: any, payload: any) => {
    return {
      dealNote: payload.payload.contractType === ContractType.Deals ? null : state.dealNote,
      facilityNote: payload.payload.contractType === ContractType.Facilities ? null : state.facilityNote,
      entityNote: payload.payload.contractType === ContractType.Entities ? null : state.entityNote,
      principalNote: payload.payload.contractType === ContractType.Principals ? null : state.principalNote,
      paymentNote: payload.payload.contractType === ContractType.Payments ? null : state.paymentNote,
      feeNote: payload.payload.contractType === ContractType.Fees ? null : state.feeNote,
    }
  }
})

export default notesReducer
