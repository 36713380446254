import * as types from './types'

export const getPrincipalPayments = (payload: any) => ({
  type: types.GET_PRINCIPAL_PAYMENTS,
  payload,
})

export const addPrincipalPayment = (payload: any) => ({
  type: types.ADD_PRINCIPAL_PAYMENT,
  payload,
})

export const editPrincipalPayment = (payload: any) => ({
  type: types.EDIT_PRINCIPAL_PAYMENT,
  payload,
})

export const submitPrincipalPayment = (payload: any) => ({
  type: types.SUBMIT_PRINCIPAL_PAYMENT,
  payload,
})

export const approvePrincipalPayment = (payload: any) => ({
  type: types.APPROVE_PRINCIPAL_PAYMENT,
  payload,
})

export const rejectPrincipalPayment = (payload: any) => ({
  type: types.REJECT_PRINCIPAL_PAYMENT,
  payload,
})

export const deletePrincipalPayment = (payload: any) => ({
  type: types.DELETE_PRINCIPAL_PAYMENT,
  payload,
})

export const startLoadingPrincipalPayment = () => ({
  type: types.START_LOADING_PRINCIPAL_PAYMENT,
  payload: null,
})

export const stopLoadingPrincipalPayment = () => ({
  type: types.STOP_LOADING_PRINCIPAL_PAYMENT,
  payload: null,
})
