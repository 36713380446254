import React, { useState } from 'react';

interface KPIDataProps<T> {
  data: T[];
  filterFunctions: Record<string, (item: T) => boolean>;
  setData: React.Dispatch<React.SetStateAction<T[]>>;
}

export const useKPIFilters = <T,>({ data, filterFunctions, setData }: KPIDataProps<T>) => {
  const [appliedFilters, setAppliedFilters] = useState<Set<string>>(new Set());

  const applyFilter = (filter: string) => {
    setAppliedFilters(prevFilters => {
      const newFilters = new Set(prevFilters);

      if (filter.startsWith('All') || filter.startsWith('Parent')) {
        newFilters.clear();
      } else {
        if (newFilters.has(filter)) {
          newFilters.delete(filter);
        } else {
          newFilters.add(filter);
        }
      }

      if (newFilters.size === 0) {
        setData(data);
      } else {
        const filtered = data.filter(item =>
          Array.from(newFilters).some(filter => filterFunctions[filter](item))
        );
        setData(filtered);
      }

      return newFilters;
    });
  };

  return { applyFilter, appliedFilters };
};
