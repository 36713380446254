import { LoanParams } from 'app/models/loan-params'
import {
  submitForApproval,
  deleteSelectedLoan,
  approveSelectedLoan,
  rejectSelectedLoan,
} from 'app/state/ducks/loans/thunks'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'
import { Dispatch } from 'redux'

export async function submitLoan(loan: LoanParams, dispatch: Dispatch<any>) {
  const response: any = await dispatch(submitForApproval(loan))
  if (response.success) {
    SuccessNotification({
      title: 'Successful Submission',
      message: 'Loan has been Submitted for Approval',
    })
  } else {
    ErrorNotification({
      title: 'Submission Failed',
      message: response.payload ?? 'Loan submission failed',
    })
  }
  return
}

export async function rejectLoan(loan: LoanParams, dispatch: Dispatch<any>) {
  const response: any = await dispatch(rejectSelectedLoan(loan))
  if (response.success) {
    SuccessNotification({
      title: 'Successful Rejection',
      message: 'Loan has been moved back to a Draft',
    })
  } else {
    ErrorNotification({
      title: 'Rejection Failed',
      message: response.payload ?? 'Loan rejection failed',
    })
  }
  return
}

export async function approveLoan(loan: LoanParams, dispatch: Dispatch<any>) {
  const response: any = await dispatch(approveSelectedLoan(loan))
  if (response.success) {
    SuccessNotification({
      title: 'Successful Approval',
      message: 'Loan has been Approved',
    })
  } else {
    ErrorNotification({
      title: 'Approval Failed',
      message: response.payload,
    })
  }
  return
}

export async function deleteLoan(loan: LoanParams, dispatch: Dispatch<any>) {
  const response: any = await dispatch(deleteSelectedLoan(loan))
  if (response.payload.error) {
    ErrorNotification({
      title: 'Delete Failed',
      message: response.payload.error.message,
    })
  } else {
    SuccessNotification({
      title: 'Successful Deletion',
      message: 'Loan has been Deleted',
    })
  }
  return
}
