import { Dispatch } from 'redux'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'
import { HolidayCalendarParams } from 'app/models/holiday-calendar-params'
import { activateCalendar, deactivateCalendar, loadCalendars, removeCalendars } from 'app/state/ducks/holiday-calendars/thunks'

export async function activeHolidayCalendar(
  calendar: HolidayCalendarParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(activateCalendar(calendar))
  if (response.success) {
    SuccessNotification({
      title: 'Successful Activated',
      message: 'Holiday Calendar has been Activated',
    })
    return false
  } else {
    ErrorNotification({
      title: 'Activation Failed',
      message: response.payload,
    })
  }
  return true
}

export async function makeInactiveHolidayCalendar(
  calendar: HolidayCalendarParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(deactivateCalendar(calendar))
  if (response.success) {
    SuccessNotification({
      title: 'Successful Deactivation',
      message: 'Holiday Calendar has been deactivated.',
    })
  } else {
    ErrorNotification({
      title: 'Deactivation Failed',
      message: response.payload,
    })
    return false
  }

  dispatch(loadCalendars())
  return true
}

export async function deleteHolidayCalendar(
  calendar: HolidayCalendarParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(removeCalendars([calendar.id]))
  if (response.payload.error) {
    ErrorNotification({
      title: 'Delete Failed',
      message: response.payload.error.message,
    })
  } else {
    SuccessNotification({
      title: 'Successful Deletion',
      message: 'Holiday Calendar has been Deleted',
    })
  }
  dispatch(loadCalendars())
  return
}
