import { RegisterParams } from 'app/models/register-params'
import { setRegisteredUser } from 'app/state/ducks/signup/actions'
import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'

export const registerUser =
  (signupUser: RegisterParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    const updated_signupUser: RegisterParams = {
      email: signupUser.email,
      username: signupUser.username,
      password: signupUser.password,
      hasError: false,
      hasSuccessfulRegistration: false,
      ErrorMessage: '',
      customer_token: config.company.toUpperCase(),
    }
    //TODO: move out into a util; create a BaseAPIClient
    return client
      .post(`${apiServerUrl}/signup/register`, updated_signupUser)
      .then(user => {
        return dispatch(setRegisteredUser(user))
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const updateUser = (customUserObj: any) => {
  const apiServerUrl = config.backendUrl
  return client
    .post(`${apiServerUrl}/signup/updateUser`, customUserObj)
    .then(user => {
      return user
    })
    .catch(e => {
      return { success: false, payload: e.response.data.error }
    })
}
