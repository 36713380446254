import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MRT_ColumnDef } from 'mantine-react-table'
import { getPayments, getActivePaymentLoader } from 'app/state/ducks/payments/selectors'
import { loadActivePayments } from 'app/state/ducks/payments/thunks'
import ReleaseQueueDrawer from '../release-queue/drawer/drawer'
import { paymentCols } from '../release-queue/payments/payments-columnDefs'
import { PaymentParams } from 'app/models/payments-params'
import { Stack, Group } from '@mantine/core'
import { calcTotalAmount } from './mywork-util/my-work-utils'
import { formatNumberToCurrency } from 'app/utils/util-functions'
import { myworkKPIData } from './mywork-kpi-data'
import KPI_Section from 'app/views/components/KPI/KPI-horizontal-section'
import { stringToDate } from '../../../utils/util-functions'
import MainTable from 'app/views/components/Table/MainTable'


interface props {
  date: Date
}

export function PaymentsPage({ date }: props) {
  const [row, setRow] = useState<PaymentParams>()
  const [open, setOpen] = useState<boolean>(false)
  const [columnDefs, setColumnDefs] = useState<MRT_ColumnDef<PaymentParams>[]>(
    []
  )
  const [payments, setPayments] = useState<PaymentParams[]>([])
  const [sortedPayments, setSortedPayments] = useState<PaymentParams[]>(payments)
  const activePayments: PaymentParams[] = useSelector(getPayments)
  const activePaymentLoader = useSelector(getActivePaymentLoader)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadActivePayments())
  }, [])

  useEffect(() => {
    setPayments(activePayments)
  }, [activePayments])

  useEffect(() => {
    setColumnDefs(paymentCols())
  }, [])

  function compare(a: PaymentParams, b: PaymentParams) {
    const weight = new Map([
      ['Prepayment', 1],
      ['Repayment', 2],
      ['Notice', 3],
      ['PrincipalPaymentTransaction', 4],
      ['PrincipalWithInterestPaymentTransaction', 5],
      ['InterestPaymentTransaction', 6],
      ['NewBorrowingTransaction', 7],
      ['Facility Fees', 8],
    ])

    const aWeight = weight.get(a.payment.transactionType) ?? 0
    const bWeight = weight.get(b.payment.transactionType) ?? 0
    if (aWeight > bWeight) {
      return 1
    }
    if (aWeight < bWeight) {
      return -1
    }
    return 0
  }

  useEffect(() => {
    const pay = payments || []
    setSortedPayments(pay.sort((a, b) => compare(a, b)))
  }, [payments])

  function handleRowClicked(e: any) {
    setRow(e)
    setOpen(true)
  }


  const filteredPayments = sortedPayments.filter(a => {
    const effectiveDate = stringToDate(a.payment.effectiveDate);
    return (
      date.getFullYear() === effectiveDate.getFullYear() &&
      date.getMonth() === effectiveDate.getMonth() &&
      date.getDate() === effectiveDate.getDate()
    );
  });

  const activityCount = filteredPayments.length.toString()
  const percentageComplete = 'N/A'

  const deadline = stringToDate(filteredPayments[0]?.payment.effectiveDate)?.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  }) ?? 'N/A'
  const totalAmount = formatNumberToCurrency(calcTotalAmount(filteredPayments?.map(item => Number(item?.payment.amount) ?? 0)) ?? 0, 'USD')?.toString() ?? 'N/A'
  const kpiData = myworkKPIData({ activityCount: Number(activityCount), percentageComplete: percentageComplete, deadlineApproaching: deadline, totalActivityAmount: totalAmount })

  return (
    <Stack>
      <KPI_Section
        kpiData={kpiData}
        isLoading={activePaymentLoader}
      />
      <Group position="right">
      </Group>
      {columnDefs.length != 0 ? (
        <MainTable
          tableName="My Work Payments Table"
          columnDefs={columnDefs}
          data={filteredPayments.sort((a, b) => a.payment.effectiveDate.localeCompare(b.payment.effectiveDate))}
          setRow={handleRowClicked}
          isLoading={activePaymentLoader}
          headerBackgroundColor='#F0EEEE'
          enableTopToolbar={false}
          withPagination={true}
        />
      ) : null}
      <ReleaseQueueDrawer open={open} setOpen={setOpen} row={row} />
    </Stack>
  )
}
