import { useState } from 'react';
import { Menu } from '@mantine/core';
import { JobParams } from 'app/models/job-params';
import { changeSchedule } from './job-daml-actions';
import { useDispatch } from 'react-redux';
import JobButton from './job-button';

type Props = {
    row: JobParams
}

export default function JobManagementStatusAction({ row }: Props) {
    const dispatch = useDispatch();
    const [selectedStatus, setSelectedStatus] = useState(row.jobStatus);

    const handleStatusChange = (newStatus: boolean) => {
        if (newStatus !== row.jobStatus) {
            changeSchedule({ ...row, jobStatus: newStatus }, dispatch);
            setSelectedStatus(newStatus);
        }
    };

    return (
        <div>
            <Menu shadow="md" width={200} position="bottom" withinPortal>
                <Menu.Target>
                    <div style={{ minWidth: 120 }}>
                        <JobButton minWidth={120}>{selectedStatus ? 'Enabled' : 'Disabled'}</JobButton>
                    </div>
                </Menu.Target>
                <Menu.Dropdown>
                    {[
                        { label: 'Enabled', value: true },
                        { label: 'Disabled', value: false },
                    ].map(({ label, value }) => (
                        <Menu.Item
                            key={label}
                            onClick={() => handleStatusChange(value)}
                            style={{
                                backgroundColor: selectedStatus === value ? '#32439A' : 'transparent',
                                color: selectedStatus === value ? '#fff' : 'inherit',
                            }}
                        >
                            {label}
                        </Menu.Item>
                    ))}
                </Menu.Dropdown>
            </Menu>
        </div>
    );
}