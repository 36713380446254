import { Group, Stack } from '@mantine/core'
import SegmentControlUpdated from 'app/views/components/segments/segment-control-updated'
import HistoryToggleButton from 'app/views/components/buttons/HistoryToggleButton'
import { PaymentsPage } from '../payments/payments-page'
import { NoticesPage } from '../notices/notices-page'
interface props {
  tab: string
}
export function LockedReleaseQueueDrillPage({ tab }: props) {

  const tabs: { [key in props['tab']]: { route: string; label: string; active: boolean; pageRender: JSX.Element; historyLink: string; } } = {
    lockedReleaseQueuePayments: { route: `/releasequeue/archived`, label: 'PAYMENTS', active: tab === 'lockedReleaseQueuePayments' ? true : false, pageRender: <PaymentsPage tab='archived' />, historyLink: '/releasequeue' },
    lockedReleaseQueueNotices: { route: `/releasequeue/notices/archived`, label: 'NOTICES', active: tab === 'lockedReleaseQueueNotices' ? true : false, pageRender: <NoticesPage tab='archived' />, historyLink: '/releasequeue/notices' },
  }

  return (
    <Stack w="100%">
      <Group w="100%" position="apart" className='tabsGapsActivity'>
        <Group position="right">
          <SegmentControlUpdated tabs={tabs} />
        </Group>
        <Group noWrap>
          <HistoryToggleButton navLink={tabs[tab].historyLink} active={true} />
        </Group>
      </Group>
      {tabs[tab].pageRender}
    </Stack>
  )
}
