import { ErrorNotification } from 'app/views/components/notifications/notification'
import { Assignment } from './params'
import { LendersPosition } from 'app/models/lenders-position-params'

export const showErrorMessage = (title: string, message: string) => {
  ErrorNotification({
    title,
    message,
  })
}

export const findNegativeAmountLenderEdit = (
  updatedLendersPositions: LendersPosition[],
  assignmentsList: Assignment[],
  selectedRow: number | null,
  assignment: Assignment
) => {
  return updatedLendersPositions.find(lender => {
    if (lender.lender.id === assignmentsList[selectedRow ?? 0].to) {
      let differenceAmount =
        Number(lender.amount) - assignmentsList[selectedRow ?? 0].amount
      if (assignmentsList[selectedRow ?? 0].to === assignment.to) {
        differenceAmount += assignment.amount
      }
      if (differenceAmount < 0) {
        return true
      }
    }
  })
}

export const hasNegativeAmountAfterDeletion = (
  lendersPositions: LendersPosition[],
  selectedAssignment: Assignment,
  assignmentAmount: number
) => {
  return lendersPositions.some(lender => {
    if (lender.lender.id === selectedAssignment.to) {
      const differenceAmount = Number(lender.amount) - assignmentAmount
      return differenceAmount < 0
    }
    return false
  })
}
