import { MRT_ColumnDef } from 'mantine-react-table'
import { Text } from '@mantine/core'
import { ReactNode } from 'react'
import { SubLimitsTypes } from 'app/models/dropdown-options'
import { EntityParams } from 'app/models/entity-params'
import { SubLimit } from './sublimits'
import { formatNumberToCurrency } from 'app/utils/util-functions'

function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}

const subLimitsTypesLabel = (value: string) => {
  const subLimitType = SubLimitsTypes.find(type => type.value === value)

  if (subLimitType) {
    return subLimitType.label
  }

  return ''
}

export function getColumnDefs(
  entities: EntityParams[]
): MRT_ColumnDef<SubLimit>[] {
  return [
    {
      size: 40,
      accessorKey: 'subLimitsType',
      header: 'Fee Type',
      Header: <TableHead header={'SubLimits Type'} />,
      Cell: ({ cell }) => (
        <TableRowText text={subLimitsTypesLabel(cell.getValue<string>())} />
      ),
    },
    {
      size: 40,
      accessorKey: 'amount',
      header: 'Amount',
      Header: <TableHead header={'Amount'} />,
      Cell: (cell: any) => {
        const { subLimitsType, lenders, amount } = cell.row.original;
        const totalAmount = subLimitsType === 'Borrower'
          ? lenders.reduce((acc: number, { amount }: { amount: number }) => acc + Number(amount), 0)
          : Number(amount) || 0;
        return (
          <TableRowText
            text={formatNumberToCurrency(totalAmount, (cell.row.original.currency &&
              cell.row.original.currency.length > 0
              ? cell.row.original.currency
              : 'USD'))}
          />
        )
      },
    },
    {
      size: 40,
      accessorKey: 'currency',
      header: 'Currency',
      Header: <TableHead header={'Currency'} />,
      Cell: ({ cell }) => {
        return <Text>{cell.getValue<string>()}</Text>
      },
    },
    {
      size: 40,
      accessorKey: 'lenders',
      header: 'Allocations',
      Header: <TableHead header={'Allocations'} />,
      filterVariant: 'range',
      Cell: ({ cell }) => {
        let entityNamesString = ''
        if (Array.isArray(cell.getValue<string[]>())) {
          const entitiesWithEntityNames = cell.getValue<any[]>().map(
            (obj: { lender: { id: string } }) => {
              const matchingEntity = entities.find(
                entity => entity.id === obj.lender.id
              )

              if (matchingEntity) {
                return {
                  entityName: matchingEntity.entityName,
                }
              }

              return {
                id: obj.lender.id,
                entityName: '', // Provide a default value when entityName is not found
              }
            }
          )

          const entityNames = entitiesWithEntityNames.map(
            entity => entity.entityName
          )
          entityNamesString =
            entityNames.length > 0 ? entityNames.join(', ') : ''
        } else {
          return ''
        }
        return <Text>{entityNamesString}</Text>
      },
    },
  ]
}
