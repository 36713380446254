import React from 'react'
import { PageLayout } from 'app/views/components/layout/page-layout'
import { ServicingDrillPage } from './servicing-drill-page'
import ServicingHeader from './servicing-header'


export function ServicingLandingPage(tab: { tab: string }) {

  return (
    <PageLayout>
      <ServicingHeader />
      <ServicingDrillPage tab={tab.tab} />
    </PageLayout>
  )
}