import { DealParams } from 'app/models/deal-params'
import {
  submitForApprovalAmendment,
  rejectSubmittedDealAmendment,
  approveSubmittedDealAmendment,
  deleteDraftDealAmendment,
  loadAllDealAmendments,
  saveDealTermination,
} from 'app/state/ducks/deal-amendments/thunks'
import { Dispatch } from 'redux'
import { loadAllDeals } from 'app/state/ducks/deals/thunks'
import { TerminationParams } from 'app/models/termination-params'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'

export async function submitDealAmendment(
  deal: DealParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(submitForApprovalAmendment(deal))
  if (response.success === false) {
    ErrorNotification({
      title: 'Submission Failed',
      message: response.payload ?? 'Failed to Approve Deal Amendment',
    })
    return false
  }
  SuccessNotification({
    title: 'Successful Submission',
    message: 'Deal has been Submitted for Approval',
  })
  return true
}

export async function rejectDealAmendment(
  deal: DealParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(rejectSubmittedDealAmendment(deal))
  if (response.success === false) {
    ErrorNotification({
      title: 'Rejection Failed',
      message: response.payload ?? 'Failed to Reject Deal Amendment',
    })
    return false
  }
  SuccessNotification({
    title: 'Successful Rejection',
    message: 'Deal has been moved back to a Draft',
  })
  return true
}

export async function approveDealAmendment(
  deal: DealParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(approveSubmittedDealAmendment(deal))
  if (response.success === false) {
    ErrorNotification({
      title: 'Approval Failed',
      message: response.payload ?? 'Failed to Approve Deal Amendment',
    })
    return false
  }
  dispatch(loadAllDeals())
  SuccessNotification({
    title: 'Successful Approval',
    message: 'Deal has been Approved',
  })
  return true
}

export async function deleteDealAmendment(
  deal: DealParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(deleteDraftDealAmendment([deal.id]))
  if (response.payload.error) {
    ErrorNotification({
      title: 'Delete Failed',
      message: response.payload.error.message,
    })
    return false
  }
  SuccessNotification({
    title: 'Successful Deletion',
    message: 'Deal has been Deleted',
  })
  return true
}

export async function createDraftTermination(
  terminationForm: TerminationParams,
  dispatch: Dispatch<any>,
  deal?: DealParams
) {
  const response: any = await dispatch(
    saveDealTermination(terminationForm, deal)
  )
  if (!response?.success) {
    ErrorNotification({
      title: 'Termination request creation failed',
      message: response.payload,
    })
    return
  }
  SuccessNotification({
    title: 'Successful Termination request created',
    message: 'Draft termination has created',
  })
  dispatch(loadAllDealAmendments())
  return response.payload
}
