import {
  Group,
  Drawer as MDrawer,
  Space,
  Stack,
  Text,
} from '@mantine/core'
import { ReactNode } from 'react'
import IonIcon from '@reacticons/ionicons'

type menuOption = {
  label: string
  onClick?: () => void
}
interface DrawerProps {
  opened: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  children?: ReactNode
  footer?: ReactNode
  titleText?: string
  statusPill?: ReactNode
  menuOptions?: menuOption[]
}

export default function MainDrawer({
  opened,
  setOpen,
  children,
  footer,
  titleText,
  statusPill,
}: DrawerProps) {

  return (
    <MDrawer
      opened={opened}
      onClose={() => setOpen(false)}
      withCloseButton={false}
      position="right"
      size='50%'
      transitionProps={{ duration: 200, timingFunction: 'linear' }}
    >
      <Stack mx='20px' my="lg" spacing={0} h="100%" >
        <Group w='100%' align='center' position='apart' mb='20px'>
          <Group noWrap>
            <Text className='drawerTitle'>{titleText}</Text>
            {statusPill}
          </Group>
          <IonIcon className='drawerCloseButton' name='close-circle' onClick={() => setOpen(false)} />
        </Group>
        {children}
        {footer && (
          <>
            <Space h="25vh" />
            {footer}
          </>
        )}

      </Stack>
    </MDrawer>
  )
}

