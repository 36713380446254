import React from 'react'
import { Grid, Col, Select } from '@mantine/core'
import CustomDatePicker from 'app/views/components/date-picker/date-picker-logic' // Ensure this import path is correct
import { useSelector } from 'react-redux'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'

interface DealFacilityDateSelectBoxProps {
  form: {
    values: {
      dealId: string
      facilityId: string
      effectiveDate: Date
    }
    setFieldValue: (field: string, value: any) => void
  }
  dealsOptions: Array<{ label: string; value: string }>
  facilitiesOptions: Array<{ label: string; value: string; dealid: string }>
  onDealChange: (dealId: string) => void
  onFacilityChange: (facilityId: string) => void
  onDateChange: (date: Date) => void
  disabled?: boolean
}

const DealFacilityDateSelectBox: React.FC<DealFacilityDateSelectBoxProps> = ({
  form,
  dealsOptions,
  facilitiesOptions,
  onDealChange,
  onFacilityChange,
  onDateChange,
  disabled,
}) => {
  const businessDate = useSelector(getBusinessDate)

  const labelStyles = {
    fontSize: '1vw',
  }
  const handleDealChange = (value: string) => {
    onDealChange(value)
  }

  const handleFacilityChange = (value: string) => {
    onFacilityChange(value)
  }

  const handleDateChange = (value: any) => {
    onDateChange(value)
  }

  return (
    <Grid gutter="lg">
      <Col span={4}>
        <Select
          w="100%"
          label="Deal"
          placeholder="Select a Deal"
          searchable={true}
          data={dealsOptions}
          value={form.values.dealId}
          onChange={handleDealChange}
          styles={{
            label: labelStyles,
          }}
          disabled={disabled}
        />
      </Col>
      <Col span={4}>
        <Select
          w="100%"
          searchable
          label="Facility"
          placeholder="Select a Facility"
          data={
            facilitiesOptions.filter(
              option => option.dealid === form.values.dealId
            ) ?? []
          }
          value={form.values.facilityId}
          onChange={handleFacilityChange}
          styles={{
            label: labelStyles,
          }}
          disabled={disabled}
        />
      </Col>
      <Col span={4}>
        <CustomDatePicker
          label={'Effective Date'}
          required={false}
          date={
            form.values.effectiveDate
              ? form.values.effectiveDate
              : businessDate
          }
          setDate={handleDateChange}
          holidayCalendars={[]}
          disabled={disabled}
          styles={{ labelStyles } as any}
        />
      </Col>
    </Grid>
  )
}

export default DealFacilityDateSelectBox
