import { ScrollArea, Table, Text } from '@mantine/core'
import { InterestRateParams } from 'app/models/interest-rate'

interface Props {
  data: InterestRateParams[]
}

export function TermTable({ data }: Props) {
  return (
    <ScrollArea>
      <div
        style={{
          border: '1px solid #D1D5DB',
          backgroundColor: '#32439A',
          fontWeight: 'bold',
        }}
      >
        <Table>
          <thead>
            <tr>
              <th style={{ width: '10%' }}>
                <Text color="white">Effective Date</Text>
              </th>
              <th style={{ width: '10%' }}>
                <Text color="white">Overnight</Text>
              </th>
              <th style={{ width: '13%' }}>
                <Text color="white">One Week</Text>
              </th>
              <th style={{ width: '13%' }}>
                <Text color="white">Two Weeks</Text>
              </th>
              <th style={{ width: '13%' }}>
                <Text color="white">Three Weeks</Text>
              </th>
              <th style={{ width: '13%' }}>
                <Text color="white">One Month</Text>
              </th>
              <th style={{ width: '13%' }}>
                <Text color="white">Two Months</Text>
              </th>
              <th style={{ width: '13%' }}>
                <Text color="white">Three Months</Text>
              </th>
              <th style={{ width: '13%' }}>
                <Text color="white">Four Months</Text>
              </th>
              <th style={{ width: '13%' }}>
                <Text color="white">Five Months</Text>
              </th>
              <th style={{ width: '13%' }}>
                <Text color="white">Six Months</Text>
              </th>
              <th style={{ width: '13%' }}>
                <Text color="white">Seven Months</Text>
              </th>
              <th style={{ width: '13%' }}>
                <Text color="white">Eight Months</Text>
              </th>
              <th style={{ width: '13%' }}>
                <Text color="white">Nine Months</Text>
              </th>
              <th style={{ width: '13%' }}>
                <Text color="white">Ten Months</Text>
              </th>
              <th style={{ width: '13%' }}>
                <Text color="white">Eleven Months</Text>
              </th>
              <th style={{ width: '13%' }}>
                <Text color="white">One Year</Text>
              </th>
            </tr>
          </thead>
          <tbody style={{ backgroundColor: '#E5E7EB' }}>
            {data &&
              data.map((item, index) => {
                return (
                  <tr
                    key={index}
                    style={{ border: '1px solid #D1D5DB', height: '3vw' }}
                  >
                    <td>
                      <Text>{item.effectiveDate}</Text>
                    </td>
                    <td>
                      <Text>{item.termRates?.overnight ?? ''}</Text>
                    </td>
                    <td>
                      <Text>{item.termRates?.oneWeek ?? ''}</Text>
                    </td>
                    <td>
                      <Text>{item.termRates?.twoWeeks ?? ''}</Text>
                    </td>
                    <td>
                      <Text>{item.termRates?.threeWeeks ?? ''}</Text>
                    </td>
                    <td>
                      <Text>{item.termRates?.oneMonth ?? ''}</Text>
                    </td>
                    <td>
                      <Text>{item.termRates?.twoMonths ?? ''}</Text>
                    </td>
                    <td>
                      <Text>{item.termRates?.threeMonths ?? ''}</Text>
                    </td>
                    <td>
                      <Text>{item.termRates?.fourMonths ?? ''}</Text>
                    </td>
                    <td>
                      <Text>{item.termRates?.fiveMonths ?? ''}</Text>
                    </td>
                    <td>
                      <Text>{item.termRates?.sixMonths ?? ''}</Text>
                    </td>
                    <td>
                      <Text>{item.termRates?.sevenMonths ?? ''}</Text>
                    </td>
                    <td>
                      <Text>{item.termRates?.eightMonths ?? ''}</Text>
                    </td>
                    <td>
                      <Text>{item.termRates?.nineMonths ?? ''}</Text>
                    </td>
                    <td>
                      <Text>{item.termRates?.tenMonths ?? ''}</Text>
                    </td>
                    <td>
                      <Text>{item.termRates?.elevenMonths ?? ''}</Text>
                    </td>
                    <td>
                      <Text>{item.termRates?.oneYear ?? ''}</Text>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
      </div>
    </ScrollArea>
  )
}
