import { isNotEmpty, useForm } from '@mantine/form'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { Group, Select, Stack } from '@mantine/core'
import CustomDatePicker from 'app/views/components/date-picker/date-picker-logic'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import { formatDateToUTC, stringToDate } from 'app/utils/util-functions'
import FormWrapper from 'app/views/components/Form/FormWrapper'
import {
  createDraftTermination,
  submitDealAmendment,
} from '../deal-amendment-details/deal-amendment-daml-actions'
import { DealParams } from 'app/models/deal-params'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'

type Props = {
  close: any
  deal: DealParams
}

export default function DealTerminationForm({ close, deal }: Props) {
  const businessDate = useSelector(getBusinessDate)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const form: any = useForm({
    initialValues: {
      id: deal.id,
      amendmentDate: deal?.amendment
        ? stringToDate(deal.amendmentDate ?? '')
        : stringToDate(deal?.maturity) ?? businessDate,
    },

    transformValues: values => {
      return {
        id: { id: deal.id, admin: deal.accountManagementAdmin },
        amendmentDate: formatDateToUTC(values.amendmentDate),
      }
    },

    validate: {
      id: isNotEmpty('Deal Id'),
      amendmentDate: isNotEmpty('Amendment Date'),
    },
  })

  const onSubmit = async () => {
    setIsLoading(true)
    const result = await createDraftTermination(
      form.getTransformedValues(),
      dispatch,
      deal?.amendment ? deal : undefined
    )
    setIsLoading(false)
    if (result) {
      close()
    }
  }

  const saveAndSubmit = async () => {
    setIsLoading(true)
    const response: any = await createDraftTermination(
      form.getTransformedValues(),
      dispatch,
      deal?.amendment ? deal : undefined
    )
    if (!response) {
      setIsLoading(false)
      return
    }
    await submitDealAmendment(response, dispatch)

    close()
    setIsLoading(false)
  }

  return (
    <FormWrapper title="Terminate">
      <div className="content">
        <form onSubmit={form.onSubmit(() => onSubmit())}>
          <div className="create-new">
            <Stack spacing="xl">
              <Group noWrap>
                <Select
                  searchable
                  readOnly
                  withAsterisk
                  label="Deal"
                  placeholder="Select Deal"
                  w="100%"
                  data={[{ label: deal?.dealName, value: deal?.id }]}
                  {...form.getInputProps('id')}
                />
                <CustomDatePicker
                  required
                  label={'Termination Date'}
                  date={form.values.amendmentDate}
                  setDate={(value: any) =>
                    form.setFieldValue('amendmentDate', value)
                  }
                  holidayCalendars={deal?.holidayCalendar ?? []}
                />
              </Group>
              <Group noWrap>
                <PrimaryButton
                  loading={isLoading}
                  type="submit"
                  disabled={!form.isValid()}
                  text="Save"
                  w="100%"
                />
                <PrimaryButton
                  loading={isLoading}
                  onClick={() => saveAndSubmit()}
                  disabled={!form.isValid()}
                  text="Create and Submit"
                  w="100%"
                />
              </Group>
            </Stack>
          </div>
        </form>
      </div>
    </FormWrapper>
  )
}
