import {
    Group,
    Stack,
} from '@mantine/core'
import { ContractType } from 'app/models/common-types'
import NotesAndAttachment from 'app/views/components/notes-and-attachemnt/notes-and-attachment-full'
import SectionTitle from 'app/views/components/Headers&Text/SectionTitle'
import { DealParams } from 'app/models/deal-params'
import DealDetailsInfo from './deal-details-info'
import DealRelatedFacilitiesDetails from './deal-related-facilities-details'

interface props {
    deal: DealParams | undefined
}

export default function DealPage({ deal }: props) {

    return (
        <Stack w="100%">
            <Stack w="100%" className='sectionRemoveGap'>
                <SectionTitle>Deal Overview</SectionTitle>
                <Stack w='100%' className='sectionRemoveGap sectionBody'>
                    <DealDetailsInfo />
                </Stack>
            </Stack>
            <DealRelatedFacilitiesDetails />
            <Group w="100%" noWrap mt='30px'>
                <NotesAndAttachment
                    itemId={deal?.id ?? ''}
                    type={ContractType.Deals}
                    itemAdmin={deal?.accountManagementAdmin ?? ''}
                />
            </Group>
        </Stack>
    )
}