import React from 'react'
import PositionsHeader from './positions-header'
import { PositionsDrillPage } from './position-drill-page'
import { PageLayout } from 'app/views/components/layout/page-layout'


export function PositionsLandingPage(tab: { tab: string }) {
  return (
    <PageLayout>
      <PositionsHeader />
      <PositionsDrillPage tab={tab.tab} />
    </PageLayout>
  )
}
