import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import { getSchedulerAmortizationByFacility, editSchedulerAmortization, changeSchedulerAmortizationForFacility, changeAmortizationRowStatus } from './actions'
import { AmortizationScheduleParams, ChangeAmortizationSchedulerData, EditAmortizationRowRequest } from 'app/models/amortitation-schedule'
import { StableKey } from 'app/models/common-types'

export const updateSchedulerAmortization =
  (savedSchedulerAmortization: AmortizationScheduleParams, schedulerAmortization: boolean) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl

    if (!schedulerAmortization) {
      return null
    }
    return client
      .put(`${apiServerUrl}/api/amortization-schedule`, savedSchedulerAmortization)
      .then(response => {
        return dispatch(editSchedulerAmortization({ ...response.data }))
      })
      .catch(error => {
        console.log(error)
      })
  }

  export const changeAllSchedulerAmortizationForFacility =
  (amortizationScheduler: ChangeAmortizationSchedulerData, facilityId: StableKey) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/amortization-schedule/facility`, {
        amortizationSchedulerData: amortizationScheduler,
        facilityId: facilityId,
      })
      .then(async (response: any) => {
       await dispatch(
          changeSchedulerAmortizationForFacility(response.data)
        )
        return { payload: response.data, success: true }
      })
      .catch(e => {
        return { payload: e.response.data.error, success: false }
      })
  }


export const loadSchedulerAmortizationByFacility =
  (id: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .get(`${apiServerUrl}/api/amortization-schedule/facility?id=${id}`)
      .then(schedulerAmortization => {
        return dispatch(
          getSchedulerAmortizationByFacility(
            schedulerAmortization.data
          )
        )
      }).catch(e => {
        console.log(e)
        return []
      })
  }

export const recalculateAmortizationSchedule = (facilityId: StableKey) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
    return client
      .put(`${apiServerUrl}/api/amortization-schedule/recalculate`, {
        facilityId: facilityId,
      })
      .then((response: any) => {
         dispatch(
          changeSchedulerAmortizationForFacility(response.data)
        )
        return { payload: response.data, success: true }
      })
      .catch(e => {
        return { payload: e.response.data.error, success: false }
      })
}

export const changeStatusForAmortizationRow = (facilityId: StableKey, effectiveDate: string, status: string) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/amortization-schedule/row/change-status`, {
        facilityId: facilityId,
        status: status,
        effectiveDate: effectiveDate,
      })
      .then((response: any) => {
         dispatch(
          changeAmortizationRowStatus(response.data)
        )
        return { payload: response.data, success: true }
      })
      .catch(e => {
        return { payload: e.response.data.error, success: false }
      })
}


export const removeAmortizationRow = (facilityId: StableKey, effectiveDate: string) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/amortization-schedule/row/delete`, {
        facilityId: facilityId,
        effectiveDate: effectiveDate,
      })
      .then((response: any) => {
         dispatch(
          changeSchedulerAmortizationForFacility(response.data)
        )
        return { payload: response.data, success: true }
      })
      .catch(e => {
        return { payload: e.response.data.error, success: false }
      })
}

export const editRowInAmortization = (body: EditAmortizationRowRequest ) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
    return client
      .put(`${apiServerUrl}/api/amortization-schedule/row`, 
      body,)
      .then((response: any) => {
        dispatch(
          changeSchedulerAmortizationForFacility(response.data)
        )
        return { payload: response.data, success: true }
      })
      .catch(e => {
        return { payload: e.response.data.error, success: false }
      })
}