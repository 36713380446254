import {
    Group,
    Container,
    createStyles,
} from '@mantine/core'
import { useAuth0 } from '@auth0/auth0-react'
import Logo from '../../navigation/Logo'
import CollapsedLogo from '../../navigation/CollapsedLogo'
import HeaderStyles from 'app/views/components/layout/Header/HeaderStyles'
import { CreateNewButton } from './CreateNewButton'

type LayoutHeaderProps = {
    open: boolean;
};

export const LogoHeader: React.FC<LayoutHeaderProps> = ({ open }) => {
    const { classes } = createStyles(theme => HeaderStyles(theme))()
    const { isAuthenticated, } = useAuth0()

    return (
        <Group
            sx={{ height: '100%' }}
            spacing='10px'
            className={classes.hiddenMobile}
            noWrap={true}
        >
            <Container mr={open ? "0px" : "6px"} ml={open ? "40px" : '0px'} mb={0} style={{ pointerEvents: 'none' }}>
                {open ? <Logo /> : <CollapsedLogo />}
            </Container>
            {isAuthenticated && <CreateNewButton />}
        </Group>

    )
}
