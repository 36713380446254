import { MRT_ColumnDef } from 'mantine-react-table'
import { Text } from '@mantine/core'
import { ReactNode } from 'react'

function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}

export const usersCols: MRT_ColumnDef<any>[] = [
  {
    size: 50,
    accessorKey: 'firstName',
    header: 'First Name',
    Header: <TableHead header={'First Name'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 100,
    accessorKey: 'email',
    header: 'Email',
    Header: <TableHead header={'Email'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 100,
    accessorKey: 'status',
    header: 'Status',
    Header: <TableHead header={'Status'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 100,
    accessorKey: 'createdAt',
    header: 'Created At',
    Header: <TableHead header={'Created At'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
]
