import { Group } from '@mantine/core';
import IonIcon from '@reacticons/ionicons';
import React from 'react';

interface AddGridButtonProps {
    onClick: () => void;
    disabled: boolean; // Controls the disabled state of the button
}

const AddGridButton: React.FC<AddGridButtonProps> = ({ onClick, disabled }) => {
    return (
        <Group>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IonIcon size="large" name="add-circle-outline" style={{ cursor: disabled ? "not-allowed" : "pointer" }} onClick={onClick} />
                <span style={{ marginLeft: '8px', fontFamily: 'Plus Jakarta Sans', fontWeight: 'bold', fontSize: '1em' }}>
                    Add Pricing Grid
                </span>
            </div>
        </Group>
    );
};

export default AddGridButton;