import { Dispatch } from 'redux'
import {
  activateWireInstruction,
  removeWireInstructions,
  deactivateWireInstruction,
} from 'app/state/ducks/wire-instructions/thunks'
import { WireInstructionParams } from 'app/models/wire-instruction-params'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'

export async function setActiveWireInstruction(
  wireInstruction: WireInstructionParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(activateWireInstruction(wireInstruction))
  if (response.success) {
    SuccessNotification({
      title: 'Successful Activated',
      message: 'Wire Instruction has been Activated',
    })
  } else {
    ErrorNotification({
      title: 'Approval Failed',
      message: response.payload ?? 'Wire Instruction could not be activated',
    })
  }  
  return
}

export async function makeInactiveWireInstruction(
  wireInstruction: WireInstructionParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(
    deactivateWireInstruction(wireInstruction)
  )
  if (response.success) {
    SuccessNotification({
      title: 'Successful Deactivation',
      message: 'Wire Instruction has been deactivated.',
    })
  } else {
    ErrorNotification({
      title: 'Deactivation Failed',
      message: response.payload ?? 'Wire Instruction could not be deactivated',
    })
  }
  return
}

export async function deleteWireInstructions(
  wireInstruction: WireInstructionParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(
    removeWireInstructions([wireInstruction.id])
  )
  if (response.payload.error) {
    ErrorNotification({
      title: 'Delete Failed',
      message: response.payload.error.message,
    })
  } else {
    SuccessNotification({
      title: 'Successful Deletion',
      message: 'Wire Instruction has been Deleted',
    })
  }
  return
}
