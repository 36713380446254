import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MainTable from 'app/views/components/Table/MainTable'
import getColumnDefs from './trade-closing-columnDefs'
import { loadAllAssignments } from 'app/state/ducks/assignments/thunks'
import { getAssignmentLoader, getAssignments } from 'app/state/ducks/assignments/selectors'
import { AssignmentParams } from 'app/models/assignment-params'
import { getFacilities } from 'app/state/ducks/facilities/selectors'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import { loadAllFacilities } from 'app/state/ducks/facilities/thunks'
import { MRT_ColumnDef } from 'mantine-react-table'
import { StableKey } from 'app/models/common-types'
import { useListState } from '@mantine/hooks'
import { useNavigate } from 'react-router-dom'
import KPI_Section from 'app/views/components/KPI/KPI-horizontal-section'
import { getKPIData } from './trade-closing-kpi-data'
import { Group, Stack } from '@mantine/core'
import SegmentControlUpdated from 'app/views/components/segments/segment-control-updated'
import PrimaryInput from 'app/views/components/buttons/PrimaryInput'


export type TradeClosingPageData = {
  assignment: AssignmentParams
  facilityId: StableKey
  from: StableKey[]
  to: StableKey[]
  amount: number
  effectiveDate: string
  id: string
  status: string
}

interface props {
  tab: string
}

export function TradeClosingDrillPage({ tab }: props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  function navigateToTradeClosingNew() {
    navigate('/tradeclosing/new-assignment')
  }


  const assignments: AssignmentParams[] | undefined =
    useSelector(getAssignments)
  const [tableData, tableDataHander] = useListState<TradeClosingPageData>()
  const [columnDefs, setColumnDefs] = useState<
    MRT_ColumnDef<TradeClosingPageData>[]
  >([])

  const [filteredTableData, setFilteredTableData] = useState<TradeClosingPageData[]>(tableData)
  const kpiData = getKPIData(tableData, setFilteredTableData)

  const facilities = useSelector(getFacilities)
  const entities = useSelector(getEntities)
  const assignmentLoader = useSelector(getAssignmentLoader)
  const [globalLoading, setGlobalLoading] = useState(false)

  useEffect(() => {
    setGlobalLoading(assignmentLoader)
  }, [assignmentLoader])

  useEffect(() => {
    const loadData = async () => Promise.all([
      dispatch(loadEntities()),
      dispatch(loadAllFacilities()),
      dispatch(loadAllAssignments()),
    ])
    loadData()
  }, [dispatch])

  useEffect(() => {
    if (!facilities || !entities || facilities.length === 0 || entities.length === 0) {
      return
    }
    setColumnDefs(getColumnDefs(entities, facilities))
  }, [entities, facilities])

  useEffect(() => {
    if (!assignments) {
      return
    }
    const assingmentMap = assignments.map(assignment => ({
      facilityId: assignment.facilityId ?? '',
      effectiveDate: assignment.effectiveDate,
      id: assignment.id,
      status: assignment.status ?? '',
      from: assignment.trades.map(trade => trade.from),
      to: assignment.trades.map(trade => trade.to),
      amount: assignment.trades.reduce(
        (acc, trade) => acc + Number(trade.amount),
        0
      ),
      assignment: assignment,
      trades: assignment.trades,
    }))
    tableDataHander.setState(assingmentMap)
    setFilteredTableData(assingmentMap)
  }, [assignments])

  function handleRowClicked(e: AssignmentParams) {
    navigate(`/tradeclosing/assignment/` + e.status + `/` + e.id + ``)
  }

  const tabs: { [key in props['tab']]: { route: string; label: string; active: boolean; } } = {
    tradeClosing: {
      route: '/tradeclosing', label: 'ALL TRADES', active: tab === 'tradeClosing' ? true : false
    },
    tradeClosingParent: {
      route: '/tradeclosing/parent', label: 'PARENT', active: tab === 'tradeClosingParent' ? true : false,
    },
    tradeClosingSubTrade: {
      route: '/tradeclosing/subtrade', label: 'SUB TRADE', active: tab === 'tradeClosingSubTrade' ? true : false,
    },
  }

  return (
    <Stack>
      <Group w="100%" position="apart" className='tabsGapsActivity'>
        <Group position="right">
          <SegmentControlUpdated tabs={tabs} />
        </Group>
        <Group noWrap>
          <PrimaryInput onClick={navigateToTradeClosingNew} text="Create Assignment" />
        </Group>
      </Group>
      <KPI_Section
        kpiData={kpiData}
        isLoading={globalLoading}
      />
      {columnDefs.length != 0 ? (
        <MainTable
          tableName='Trade_Closing Table'
          withPagination={true}
          csvExportEnabled={true}
          enableTopToolbar={false}
          headerBackgroundColor='#F0EEEE'
          overflow="auto"
          minHeight="0px"
          isLoading={globalLoading}
          columnDefs={columnDefs}
          data={filteredTableData}
          setRow={(e: TradeClosingPageData, cellName: string) => {
            if (cellName != 'id') {
              handleRowClicked(e.assignment)
            }
          }}
        />
      ) : null}
    </Stack>
  )
}
