import { MRT_ColumnDef } from 'mantine-react-table'
import { Text } from '@mantine/core'
import { ReactNode } from 'react'

export type CleanCurrentLoanSummaryParams = {
    'Start Date': string,
    'End Date': string,
    'Loan Amount': string,
    'Base Rate': string,
    'Margin': string,
    'All-In Rate': string,
    'Days': number,
    'Interest Due': string
}

function TableHead({ header }: { header: string }) {
    return (
        <Text
            style={{
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '1.5em',
            }}
        >
            {header}
        </Text>
    )
}

function TableRowText({ text }: { text: ReactNode }) {
    return (
        <Text
            style={{
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '1.5em',
            }}
        >
            {text}
        </Text>
    )
}

export function getColumnDefs(): MRT_ColumnDef<CleanCurrentLoanSummaryParams>[] {

    return [
        {
            size: 50,
            accessorKey: 'Start Date',
            header: 'Start Date',
            Header: <TableHead header={'Start Date'} />,
            mantineFilterTextInputProps: {
                placeholder: 'Date',
            },
            filterFn: 'equals',
            filterVariant: 'date',
            sortingFn: 'datetime',
            Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,

        },

        {
            size: 50,
            accessorKey: 'End Date',
            header: 'End Date',
            Header: <TableHead header={'End Date'} />,
            mantineFilterTextInputProps: {
                placeholder: 'Date',
            },
            filterFn: 'equals',
            filterVariant: 'date',
            sortingFn: 'datetime',
            Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,

        },
        {
            size: 50,
            accessorKey: 'Loan Amount',
            header: 'Loan Amount',
            Header: <TableHead header={'Loan Amount'} />,
            filterVariant: 'range',
            Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
        },
        {
            size: 50,
            accessorKey: 'Base Rate',
            header: 'Base Rate',
            Header: <TableHead header={'Base Rate'} />,
            filterVariant: 'range',
            Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
        },
        {
            size: 50,
            accessorKey: 'Margin',
            header: 'Margin',
            Header: <TableHead header={'Margin'} />,
            filterVariant: 'range',
            Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
        },
        {
            size: 50,
            accessorKey: 'All-In Rate',
            header: 'All-In Rate',
            Header: <TableHead header={'All-In Rate'} />,
            filterVariant: 'range',
            Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
        },
        {
            size: 50,
            accessorKey: 'Days',
            header: 'Days',
            Header: <TableHead header={'Days'} />,
            filterVariant: 'range',
            Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
        },
        {
            size: 50,
            accessorKey: 'Interest Due',
            header: 'Interest Due',
            Header: <TableHead header={'Interest Due'} />,
            filterVariant: 'range',
            Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
        },
    ]
}