import { Menu, Modal } from '@mantine/core'
import PrimaryDropDown from 'app/views/components/DropDown/DropDown'
import { useDispatch, useSelector } from 'react-redux'
import { LoanParams } from 'app/models/loan-params'
import {
  approveLoan,
  submitLoan,
  deleteLoan,
  rejectLoan,
} from './loan-daml-actions'
import { useState } from 'react'
import HistoryForm from './history/history'
import RateSettingForm from '../rate-settings/rate-setting-form'
import PrincipalPaymentModal from '../principal-payment/principal-payment-modal'
import { useNavigate } from 'react-router-dom'
import { UpdatedLoanScreen } from './loan-stepper'
import { loadOneFacility } from 'app/state/ducks/facilities/thunks'
import { getFacility } from 'app/state/ducks/facilities/selectors'
import { getIndexRateOptions } from 'app/state/ducks/index-rate-options/selectors'
import RolloverFormLogic from '../rollovers/rollover-form-logic'
import IncreaseFormLogic from '../increases/increase-form-logic'
import ProcessPIKForm from '../payment-in-kind/pik-form'
import { LoanRolloverParams } from 'app/models/loan-rollover-params'
import { LoanIncreaseParams } from 'app/models/loan-increase-params'
import IonIcon from '@reacticons/ionicons'
import ConversionFormLogic from '../conversion/conversion-form-logic'
import { LoanConversionParams } from 'app/models/loan-conversion-params'
import { PrincipalPaymentParams } from 'app/models/principal-payments-params'

type Props = {
  item: LoanParams
  setOpenEdit?: React.Dispatch<React.SetStateAction<boolean>>
  setLoan?: React.Dispatch<React.SetStateAction<any>>
  fromOutside: boolean
  rollover?: LoanRolloverParams | undefined
  increase?: LoanIncreaseParams | undefined
  conversion?: LoanConversionParams
  payment?: PrincipalPaymentParams
}

export default function LoanActions({ item, setOpenEdit, setLoan, rollover, increase, fromOutside, conversion, payment }: Props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [openEditLocal, setOpenEditLocal] = useState(false)
  const [openNewRollover, setOpenNewRollover] = useState(false)
  const [openNewIncrease, setOpenNewIncrease] = useState(false)
  const [openNewConversion, setOpenNewConversion] = useState(false)
  const [openHistory, setOpenHistory] = useState(false)
  const [openNewPayment, setOpenNewPayment] = useState(false)
  const [loanLocal, setLoanLocal] = useState<LoanParams>()
  const [openNewRateSetting, setOpenNewRateSetting] = useState(false)
  const [openProcessPik, setOpenPIK] = useState(false)
  const facility = useSelector(getFacility)
  const allIndexes = useSelector(getIndexRateOptions)

  const isTermIndex = allIndexes.find(index => index.id === item.indexOption?.id)?.indexType === "TermIndex";

  function editLoan() {
    dispatch(loadOneFacility(item.facilityId.id, 'Approved'))
    if (fromOutside && setLoan && setOpenEdit) {
      setLoan(item)
      setOpenEdit(true)
    } else {
      setLoanLocal(item)
      setOpenEditLocal(true)
    }
  }
  function openRollover() {
    setLoanLocal(item)
    setOpenNewRollover(true)
  }
  function openIncrease() {
    setLoanLocal(item)
    setOpenNewIncrease(true)
  }
  function openConversion() {
    setLoanLocal(item)
    setOpenNewConversion(true)
  }
  function openPayment() {
    setLoanLocal(item)
    setOpenNewPayment(true)
  }

  function openRateSetting() {
    setLoanLocal(item)
    setOpenNewRateSetting(true)
  }

  function displayHistory() {
    setLoanLocal(item)
    setOpenHistory(true)
  }

  function openPIK(): void {
    setLoanLocal(item)
    setOpenPIK(true)
  }

  return (
    <>
      <Menu shadow="md" width={200} position="bottom" withinPortal>
        <Menu.Target>
          <div style={{ width: 'fit-content' }}>
            <PrimaryDropDown>Actions</PrimaryDropDown>
          </div>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Label>Loan</Menu.Label>
          {item?.status?.toLowerCase() == 'locked' ? null : <Menu.Item
            onClick={() =>
              navigate(
                `/dealmanagement/loans/${item.id}/${item.status}/lenders`
              )
            }
            icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
          >
            Lenders Position
          </Menu.Item>}
          {item?.status?.toLowerCase() == 'draft' ? (
            <>
              <Menu.Item
                icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
                onClick={() => editLoan()}
              >
                Edit
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  submitLoan(item, dispatch)
                }}
                icon={<IonIcon className="action-ion-icon" name='open-outline' />}
              >
                Submit For Approval
              </Menu.Item>
            </>
          ) : null}
          {item?.status?.toLowerCase() == 'submitted' ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => {
                approveLoan(item, dispatch)
              }}
            >
              Approve
            </Menu.Item>
          ) : null}
          {item?.status?.toLowerCase() == 'locked' ? (
            <Menu.Item
              onClick={() => displayHistory()}
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
            >
              History
            </Menu.Item>
          ) : null}
          {item?.status?.toLowerCase() == 'approved' ? (
            <>
              {item?.interestBaseRate == null ||
                item?.interestBaseRate == 0.0 ? (
                <Menu.Item
                  onClick={() => openRateSetting()}
                  icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
                >
                  Add Rates
                </Menu.Item>
              ) : null}
              {facility?.pikOption !== 'No_PIK' ? (
                <Menu.Item
                  onClick={() => openPIK()}
                  icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
                >
                  Process PIK
                </Menu.Item>
              ) : null}
              {isTermIndex && (
                <Menu.Item
                  onClick={() => openRollover()}
                  icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
                >
                  {rollover ? "Edit Rollover" : "Process Rollover"}
                </Menu.Item>

              )}
              {!isTermIndex &&
                (
                  <Menu.Item
                    onClick={() => openIncrease()}
                    icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
                  >
                    {increase ? "Edit Increase" : "Process Increase"}
                  </Menu.Item>
                )
              }
              {!isTermIndex &&
                (
                  <Menu.Item
                    onClick={() => openConversion()}
                    icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
                  >
                    {conversion ? "Edit Conversion" : "Process Conversion"}
                  </Menu.Item>
                )
              }
              <Menu.Item
                onClick={() => openPayment()}
                icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              >
                {payment ? "Edit Payment" : "Process Payment"}
              </Menu.Item>
              <Menu.Item
                onClick={() => displayHistory()}
                icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              >
                History
              </Menu.Item>
            </>
          ) : null}

          {item?.status?.toLowerCase() != 'approved' ? (
            <>
              <Menu.Divider />
              <Menu.Label>Danger zone</Menu.Label>
            </>
          ) : null}

          {item?.status?.toLowerCase() == 'draft' ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name='trash-outline' />}
              onClick={() => {
                deleteLoan(item, dispatch)
              }}
            >
              Delete
            </Menu.Item>
          ) : null}

          {item?.status?.toLowerCase() == 'submitted' ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => {
                rejectLoan(item, dispatch)
              }}
            >
              Reject
            </Menu.Item>
          ) : null}
        </Menu.Dropdown>
      </Menu>



      <Modal
        className="modal-body modal-body-template fit-content-modal"
        opened={openEditLocal}
        onClose={() => setOpenEditLocal(false)}
        centered={true}
        size="77%"
      >
        <UpdatedLoanScreen
          facility={facility}
          loan={loanLocal}
          step={0}
          onClose={() => setOpenEditLocal(false)}
        />
      </Modal>

      <Modal
        size="50%"
        className="modal-body modal-body-template fit-content-modal"
        opened={openNewRollover}
        onClose={() => setOpenNewRollover(false)}
      >
        {loanLocal && (
          <RolloverFormLogic loan={loanLocal} close={() => setOpenNewRollover(false)} />
        )}
      </Modal>


      <Modal
        size="50%"
        className="modal-body modal-body-template fit-content-modal"
        opened={openNewIncrease}
        onClose={() => setOpenNewIncrease(false)}
      >
        {loanLocal && (
          <IncreaseFormLogic loan={loanLocal} close={() => setOpenNewIncrease(false)} />
        )}
      </Modal>

      <Modal
        size="50%"
        className="modal-body modal-body-template fit-content-modal"
        opened={openNewConversion}
        onClose={() => setOpenNewConversion(false)}
      >
        {loanLocal && (
          <ConversionFormLogic loan={loanLocal} close={() => setOpenNewConversion(false)} />
        )}
      </Modal>

      <Modal
        size="50%"
        className="modal-body modal-body-template fit-content-modal"
        opened={openProcessPik}
        onClose={() => setOpenPIK(false)}
      >
        {loanLocal && (
          <ProcessPIKForm loan={loanLocal} close={() => setOpenPIK(false)} />
        )}
      </Modal>

      {loanLocal && (
        <PrincipalPaymentModal
          loan={loanLocal}
          setOpen={setOpenNewPayment}
          open={openNewPayment}
        />
      )}

      <Modal
        size="50%"
        className="modal-body modal-body-template fit-content-modal"
        opened={openNewRateSetting}
        onClose={() => setOpenNewRateSetting(false)}
      >
        {loanLocal && (
          <RateSettingForm
            loan={loanLocal}
            close={() => setOpenNewRateSetting(false)}
          />
        )}
      </Modal>
      <Modal
        size="90%"
        opened={openHistory}
        onClose={() => setOpenHistory(false)}
      >
        {loanLocal && (
          <HistoryForm
            loanId={loanLocal.id ?? ''}
            close={() => setOpenHistory(false)}
          />
        )}
      </Modal>
    </>
  )
}
