import { LoanIncreaseParams } from 'app/models/loan-increase-params'
import {
  submitForApproval,
  rejectSelectedLoanIncrease,
  approveSelectedLoanIncrease,
  deleteSelectedLoanIncrease,
  loadAllLoanIncreases,
} from 'app/state/ducks/loan-increases/thunks'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'
import { Dispatch } from 'redux'

export async function submitLoanIncrease(
  loanIncrease: LoanIncreaseParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(submitForApproval(loanIncrease))
  if (response.success) {
    SuccessNotification({
      title: 'Successful Submission',
      message: 'Loan Increase has been Submitted for Approval',
    })
  } else {
    ErrorNotification({
      title: 'Submission Failed',
      message: response.payload ?? 'Could not submit Loan Increase',
    })
  }
  dispatch(loadAllLoanIncreases())
  return
}

export async function rejectLoanIncrease(
  loanIncrease: LoanIncreaseParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(rejectSelectedLoanIncrease(loanIncrease))
  if (response.success) {
    SuccessNotification({
      title: 'Successful Rejection',
      message: 'Loan Increase has been moved back to a Draft',
    })
  } else {
    ErrorNotification({
      title: 'Rejection Failed',
      message: response.payload ?? 'Could not reject Loan Increase',
    })
  }
  dispatch(loadAllLoanIncreases())
  return
}

export async function approveLoanIncrease(
  loanIncrease: LoanIncreaseParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(
    approveSelectedLoanIncrease(loanIncrease)
  )
  if (response.success) {
    SuccessNotification({
      title: 'Successful Approval',
      message: 'Loan Increase has been Approved',
    })
  } else {
    ErrorNotification({
      title: 'Approval Failed',
      message: response.payload ?? 'Could not approve Loan Increase',
    })
  }
  dispatch(loadAllLoanIncreases())
  return
}

export async function deleteLoanIncrease(
  loanIncrease: LoanIncreaseParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(deleteSelectedLoanIncrease(loanIncrease))
  if (response.payload.error) {
    ErrorNotification({
      title: 'Delete Failed',
      message: response.payload.error.message ?? 'Could not delete loan',
    })
  } else {
    SuccessNotification({
      title: 'Successful Deletion',
      message: 'Loan Increase has been Deleted',
    })
  }
  dispatch(loadAllLoanIncreases())
  return
}
