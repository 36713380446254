import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import {
  getLoanRateSettings,
  addLoanRateSetting,
  editLoanRateSetting,
  submitLoanRateSetting,
  approveLoanRateSetting,
  rejectLoanRateSetting,
  getApprovedLoanRateSettings,
  deleteLoanRateSetting,
  stopLoadingLoanRateSetting,
  startLoadingLoanRateSetting,
} from './actions'
import { LoanRateSettingParams } from 'app/models/loan-rate-setting-params'

export const saveLoanRateSetting =
  (savedLoanRateSetting: LoanRateSettingParams, loanRateSetting?: any) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    if (savedLoanRateSetting.allInRate == '')
      savedLoanRateSetting.allInRate = 0.0
    if (savedLoanRateSetting.margin == '') savedLoanRateSetting.margin = 0.0
    if (savedLoanRateSetting.interestBaseRate == '')
      savedLoanRateSetting.interestBaseRate = 0.0
    if (savedLoanRateSetting.roundedBaseRate == '')
      savedLoanRateSetting.roundedBaseRate = 0.0
    const updated_savedLoanRateSetting: LoanRateSettingParams = {
      ...savedLoanRateSetting,
    }
    if (loanRateSetting) {
      return client
        .put(
          `${apiServerUrl}/api/draft-loan-rate-settings`,
          updated_savedLoanRateSetting
        )
        .then(response => {
          const payload =  dispatch(editLoanRateSetting(response))
          return {success: true, payload: payload.payload}
        }).catch(e =>{
          return {success: false, payload: e.response.data.error}
        })
    }

    return client
      .post(
        `${apiServerUrl}/api/draft-loan-rate-settings`,
        updated_savedLoanRateSetting
      )
      .then(response => {
        const payload = dispatch(addLoanRateSetting(response))
        return {success: true, payload: payload.payload}
      }).catch(e =>{
        return {success: false, payload: e.response.data.error}
      })
  }

export const loadLoanRateSettingId =
  (loanRateSettingId: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .get(
        `${apiServerUrl}/api/approved-loan-rate-settings/one?id=${loanRateSettingId}`
      )
      .then(response => {
        return dispatch(getApprovedLoanRateSettings(response.data))
      }).catch(e => {
        return {success: false, payload: e.response.data.error}
      })
  }

export const loadLoanRateSettings =
  (loanId: string | undefined) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .get(`${apiServerUrl}/api/approved-loan-rate-settings/loan?id=${loanId}`)
      .then(response => {
        return dispatch(getApprovedLoanRateSettings(response.data))
      }).catch(e => {
        console.log(e)
        return []
      })
  }
export const loadApprovedLoanRateSettings = async (
  loanId: string | undefined
) => {
  const apiServerUrl = config.backendUrl
  if (loanId) {
    return await client
      .get(`${apiServerUrl}/api/approved-loan-rate-settings/loan?id=${loanId}`)
      .then(response => {
        return response.data
      }).catch(e => {
        console.log(e)
        return []
      })
  } else {
    return await client
      .get(`${apiServerUrl}/api/approved-loan-rate-settings`)
      .then(response => {
        return response.data
      }).catch(e => {
        console.log(e)
        return []
      })
  }
}

export const loadDraftLoanRateSettings = async (loanId: string | undefined) => {
  const apiServerUrl = config.backendUrl
  if (loanId) {
    return await client
      .get(`${apiServerUrl}/api/draft-loan-rate-settings/loan?id=${loanId}`)
      .then(response => {
        return response.data
      }).catch(e => {
        console.log(e)
        return []
      })
  } else {
    return await client
      .get(`${apiServerUrl}/api/draft-loan-rate-settings`)
      .then(response => {
        return response.data
      }).catch(e => {
        console.log(e)
        return []
      })
  }
}

export const loadSubmittedLoanRateSettings = async (
  loanId: string | undefined
) => {
  const apiServerUrl = config.backendUrl
  if (loanId) {
    return await client
      .get(`${apiServerUrl}/api/submitted-loan-rate-settings/loan?id=${loanId}`)
      .then(response => {
        return response.data
      }).catch(e => {
        console.log(e)
        return []
      })
  } else {
    return await client
      .get(`${apiServerUrl}/api/submitted-loan-rate-settings`)
      .then(response => {
        return response.data
      }).catch(e => {
        console.log(e)
        return []
      })
  }
}

export const submitForApproval =
  (loanRateSetting: LoanRateSettingParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/draft-loan-rate-settings/submit`, {
        id: loanRateSetting.id,
      })
      .then(response => {
        const payload =  dispatch(submitLoanRateSetting(response.data))
        return {success: true, payload: payload.payload}
      }).catch(e =>{
        return {success: false, payload: e.response.data.error}
      })
  }

export const approveSelectedLoanRateSetting =
  (loanRateSetting: LoanRateSettingParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/submitted-loan-rate-settings/approve`, {
        id: loanRateSetting.id,
      })
      .then(response => {
        const payload = dispatch(approveLoanRateSetting(response.data))
        return {success: true, payload: payload.payload}
      }).catch(e =>{
        return {success: false, payload: e.response.data.error}
      })
  }

export const rejectSelectedLoanRateSetting =
  (loanRateSetting: LoanRateSettingParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/submitted-loan-rate-settings/reject`, {
        id: loanRateSetting.id,
      })
      .then(response => {
        const payload = dispatch(rejectLoanRateSetting(response.data))
        return {success: true, payload: payload.payload}
      }).catch(e =>{
        return {success: false, payload: e.response.data.error}
      })
  }

export const deleteSelectedLoanRateSetting =
  (loanRateSetting: LoanRateSettingParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .delete(
        `${apiServerUrl}/api/draft-loan-rate-settings?ids[]=${loanRateSetting.id}`
      )
      .then(() => {
        return dispatch(deleteLoanRateSetting(loanRateSetting))
      }).catch(e => {
        return {success: false, payload: e.response.data.error}
      })
  }

export const loadAllLoanRateSettings =
  (loanId?: string | undefined) => async (dispatch: Dispatch) => {
    try {
    dispatch(startLoadingLoanRateSetting())
    let all_loan_rate_settings: any[] = []

    const draftLoanRateSettings = await loadDraftLoanRateSettings(loanId)
    const submittedLoanRateSettings = await loadSubmittedLoanRateSettings(
      loanId
    )

    const arr = submittedLoanRateSettings.map(
      (a: { loanRateSetting: LoanRateSettingParams }) => a.loanRateSetting
    )
    const sub_loan_rate_settings = arr.map((a: LoanRateSettingParams) => ({
      ...a,
      status: 'Submitted',
    }))

    all_loan_rate_settings = draftLoanRateSettings
      .map((loanRateSetting: LoanRateSettingParams) => ({
        ...loanRateSetting,
        status: 'Draft',
      }))
      .concat(sub_loan_rate_settings)

    return dispatch(getLoanRateSettings(all_loan_rate_settings))
  } finally {
    dispatch(stopLoadingLoanRateSetting())
  }
  }
