import { isNotEmpty, useForm } from '@mantine/form'
import LetterOfCreditFormPresentation from './letter-of-credit-form-presentation'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getFacility } from 'app/state/ducks/facilities/selectors'
import { useEffect } from 'react'
import { loadWireInstructions } from 'app/state/ducks/wire-instructions/thunks'
import cleanUUID from 'app/views/components/functions/cleanUUID'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { LetterOfCreditParams } from 'app/models/letter-of-credit'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import { formatDateToUTC, stringToDate } from 'app/utils/util-functions'

type Props = {
  close: any
  letterOfCredit?: LetterOfCreditParams
}

export default function LetterOfCreditForm({ close, letterOfCredit }: Props) {
  const { facilityId } = useParams()
  const dispatch = useDispatch()
  const facility = useSelector(getFacility)
  const entities = useSelector(getEntities)
  const businessDate = useSelector(getBusinessDate)

  useEffect(() => {
    dispatch(loadWireInstructions())
  }, [])

  const form: any = useForm({
    initialValues: {
      id: letterOfCredit?.id ?? undefined,
      facilityId,
      amount: Number(letterOfCredit?.amount) ?? 0,
      currency:
        letterOfCredit?.currency ??
        facility?.baseCurrency ??
        facility?.baseCurrency,
      effectiveDate: letterOfCredit?.effectiveDate
        ? stringToDate(letterOfCredit.effectiveDate)
        : businessDate,
      initialExpirationDate: letterOfCredit?.initialExpirationDate
        ? stringToDate(letterOfCredit.initialExpirationDate)
        : null,
      adjustedExpirationDate: letterOfCredit?.adjustedExpirationDate
        ? stringToDate(letterOfCredit.adjustedExpirationDate)
        : null,
      fxRate: Number(letterOfCredit?.fxRate) ?? null,
      issuingBanks:
        letterOfCredit?.issuingBanks.map(x => cleanUUID(x?.id ?? '')) ?? [],
      issuingFeeRate: Number(letterOfCredit?.issuingFeeRate) ?? null,
      letterOfCreditType: letterOfCredit?.letterOfCreditType ?? '',
      letterOfCreditStandbyType:
        letterOfCredit?.letterOfCreditStandbyType ?? null,
      autoExtendNotificationPeriod:
        letterOfCredit?.autoExtendNotificationPeriod ?? null,
      borrowers:
        letterOfCredit?.borrowers.map(x => cleanUUID(x?.id ?? '')) ?? [],
      beneficiaries:
        letterOfCredit?.beneficiaries.map(x => cleanUUID(x?.id ?? '')) ?? [],
      autoExtend: letterOfCredit?.autoExtend ?? false,
      autoExtendPeriod: Number(letterOfCredit?.autoExtendPeriod) ?? null,
      hasError: false,
      ErrorMessage: '',
      hasSuccessfulEntitySave: false,
      customer_token: '',
    },

    transformValues: values => {
      const issuingBanks = entities
        .filter((entity: any) => values.issuingBanks.includes(entity.id))
        .map(entity => ({ id: entity.id, admin: entity.maker }))
      const borrowers = entities
        .filter((entity: any) => values.borrowers.includes(entity.id))
        .map(entity => ({ id: entity.id, admin: entity.maker }))
      const beneficiaries = entities
        .filter((entity: any) => values.beneficiaries.includes(entity.id))
        .map(entity => ({ id: entity.id, admin: entity.maker }))

      return {
        ...values,
        issuingBanks: issuingBanks,
        borrowers: borrowers,
        beneficiaries: beneficiaries,
        effectiveDate: formatDateToUTC(values.effectiveDate),
        initialExpirationDate: values.initialExpirationDate ? formatDateToUTC(
          values.initialExpirationDate
        ) : null,
        facilityId: {
          id: facility?.id,
          admin: facility?.accountManagementAdmin,
        },
        letterOfCreditStandbyType:
          values.letterOfCreditType !== 'Standby' ||
            values.letterOfCreditStandbyType === ''
            ? null
            : values.letterOfCreditStandbyType,
        autoExtendNotificationPeriod:
          values.autoExtend === false ||
            values.autoExtendNotificationPeriod === ''
            ? null
            : values.autoExtendNotificationPeriod,
        autoExtendPeriod:
          values.autoExtend === false || values.autoExtendPeriod === 0
            ? null
            : values.autoExtendPeriod,
        adjustedExpirationDate:
          values.autoExtend === false || !values.adjustedExpirationDate
            ? null
            : formatDateToUTC(values.adjustedExpirationDate),
        fxRate:
          values.currency === facility?.baseCurrency || values.fxRate === 0.0
            ? null
            : values.fxRate,
      }
    },

    validate: {
      currency: isNotEmpty('Currency cannot be empty'),
      facilityId: isNotEmpty('Facility cannot be empty'),
      amount: (value: number) => {
        if (!value) {
          return 'Invalid amount'
        }
        if (value <= 0.0) {
          return 'Invalid amount'
        }
        if (!facility) {
          return null
        }
        if (value > Number(facility?.amount)) {
          return 'Amount cannot be greater than facility amount'
        }
        return null
      },
      letterOfCreditType: value => {
        if (!value) {
          return 'Must Select LC Type'
        }
        return null
      },
      effectiveDate: value => {
        if (!value) {
          return null
        }
        if (
          facility?.startDate &&
          value < stringToDate(facility?.startDate)
        ) {
          return 'Effective Date must be after facility start date'
        }
        return null
      },
      initialExpirationDate: value => {
        if (!value) {
          return null
        }
        if (
          facility?.maturity &&
          value > stringToDate(facility?.maturity)
        ) {
          return 'Initial Expiration Date must be before facility maturity'
        }
        return null
      },
      adjustedExpirationDate: (value, allValues) => {
        if (!value) {
          return null
        }
        if (
          facility?.maturity &&
          value > stringToDate(facility?.maturity)
        ) {
          return 'Final Expiration Date must be after facility maturity'
        }
        if (
          allValues?.initialExpirationDate &&
          value < allValues?.initialExpirationDate
        ) {
          return 'Final Expiration Date must be after initial expiration date'
        }
        return null
      },
    },
    validateInputOnBlur: true,
  })

  return (
    <LetterOfCreditFormPresentation
      form={form}
      letterOfCredit={letterOfCredit}
      facility={facility}
      close={close}
    />
  )
}
