// ** Hooks
import { formatDateToUTC } from 'app/utils/util-functions'
import { NumberInput } from '@mantine/core'
import CustomDatePicker from 'app/views/components/date-picker/date-picker-logic'
import { IndexRateOptionParams } from 'app/models/index-rate-options-params'
import { InterestRateParams } from 'app/models/interest-rate'

type Props = {
  form: any
  index: IndexRateOptionParams
  allRates: InterestRateParams[]
  alreadyExists: boolean
  setAlreadyExists: (value: boolean) => void
}

const NonTermRateForm: React.FC<Props> = ({
  form,
  index,
  allRates,
  alreadyExists,
  setAlreadyExists,
}) => {
  const handleDataChange = (date: any) => {
    const rate = allRates.find(
      rate => rate.effectiveDate === formatDateToUTC(date)
    )
    if (rate) {
      form.setValues({
        ...form.values,
        effectiveDate: date,
        floatingRate: Number(rate.floatingRate),
        overnightRate: Number(rate.overnightRate),
      })
      setAlreadyExists(true)
    } else {
      form.setValues({
        ...form.values,
        effectiveDate: date,
        floatingRate: index.indexType !== 'FloatingIndex' ? null : 0.0,
        overnightRate: index.indexType !== 'OvernightIndex' ? null : 0.0,
      })
      setAlreadyExists(false)
    }
  }

  return (
    <>
      <CustomDatePicker
        label={'Date'}
        date={form.values.effectiveDate}
        setDate={handleDataChange}
        mantineFormProps={{ ...form.getInputProps('effectiveDate') }}
        holidayCalendars={[]}
      />
      <NumberInput
        readOnly={alreadyExists}
        precision={5}
        label={index.indexType === 'OvernightIndex' ? 'Overnight' : 'Floating'}
        name="single rate"
        id="single rate"
        {...form.getInputProps(
          index.indexType === 'OvernightIndex'
            ? 'overnightRate'
            : 'floatingRate'
        )}
      />
    </>
  )
}

export default NonTermRateForm
