import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import {
  getLoanConversions,
  addLoanConversion,
  editLoanConversion,
  submitLoanConversion,
  approveLoanConversion,
  rejectLoanConversion,
  deleteLoanConversion,
  startLoadingLoanConversion,
  stopLoadingLoanConversion,
} from './actions'
import { LoanConversionParams } from 'app/models/loan-conversion-params'

export const saveLoanConversion =
  (savedLoanConversion: LoanConversionParams, loanConversion?: any) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
   
    if (loanConversion) {
      return client
        .put(`${apiServerUrl}/api/draft-loan-conversions`, savedLoanConversion)
        .then(response => {
          const payload = dispatch(editLoanConversion(response))
          return {success: true, payload: payload.payload}
        }).catch(e => {
          return {success: false, payload: e.response.data.error}
        })
    }

    return client
      .post(`${apiServerUrl}/api/draft-loan-conversions`, savedLoanConversion)
      .then(response => {
        const payload = dispatch(addLoanConversion(response))
        return {success: true, payload: payload.payload}
      }).catch(e => {
        return {success: false, payload: e.response.data.error}
      })
  }

export const loadLoanConversionId =
  (loanConversionId: string) => async () => {
    const apiServerUrl = config.backendUrl
    return client
      .get(
        `${apiServerUrl}/api/approved-loan-conversions/one?id=${loanConversionId}`,
      )
      .then((response) => {
        return {success: true, payload: response.data }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }
export const loadApprovedLoanConversions = async (loanId: string | undefined) => {
  const apiServerUrl = config.backendUrl
  if (loanId) {
    return await client
      .get(`${apiServerUrl}/api/approved-loan-conversions/loan?id=${loanId}`)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.log(e)
        return []
      })
  } else {
    return await client
      .get(`${apiServerUrl}/api/approved-loan-conversions`)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.log(e)
        return []
      })
  }
}

export const loadDraftLoanConversions = async (loanId: string | undefined) => {
  const apiServerUrl = config.backendUrl
  if (loanId) {
    return await client
      .get(`${apiServerUrl}/api/draft-loan-conversions/loan?id=${loanId}`)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.log(e)
        return []
      })
  } else {
    return await client
      .get(`${apiServerUrl}/api/draft-loan-conversions`)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.log(e)
        return []
      })
  }
}

export const loadSubmittedLoanConversions = async (
  loanId: string | undefined,
) => {
  const apiServerUrl = config.backendUrl
  if (loanId) {
    return await client
      .get(`${apiServerUrl}/api/submitted-loan-conversions/loan?id=${loanId}`)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.log(e)
        return []
      })
  } else {
    return await client
      .get(`${apiServerUrl}/api/submitted-loan-conversions`)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.log(e)
        return []
      })
  }
}

export const submitForApproval =
  (loanConversion: LoanConversionParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/draft-loan-conversions/submit`, {
        id: loanConversion.id,
      })
      .then(response => {
        const payload = dispatch(submitLoanConversion(response.data))
        return { success: true, payload: payload.payload }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const approveSelectedLoanConversion =
  (loanConversion: LoanConversionParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/submitted-loan-conversions/approve`, {
        id: loanConversion.id,
      })
      .then(response => {
        const payload = dispatch(approveLoanConversion(response.data))
        return { success: true, payload: payload.payload }
      })
      .catch(e => {
        console.log(e)
        return { success: false, payload: e.response.data.error }
      })
  }

export const rejectSelectedLoanConversion =
  (loanConversion: LoanConversionParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/submitted-loan-conversions/reject`, {
        id: loanConversion.id,
      })
      .then(response => {
        const payload = dispatch(rejectLoanConversion(response.data))
        return { success: true, payload: payload.payload }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const deleteSelectedLoanConversion =
  (loanConversion: LoanConversionParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .delete(
        `${apiServerUrl}/api/draft-loan-conversions?ids[]=${loanConversion.id}`,
      )
      .then(() => {
        return dispatch(deleteLoanConversion(loanConversion))
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const loadAllLoanConversions =
  (loanId?: string | undefined) => async (dispatch: Dispatch) => {
    try {
      dispatch(startLoadingLoanConversion())
    let all_loan_conversions: any[] = []

    const draftLoanConversions = await loadDraftLoanConversions(loanId)
    const submittedLoanConversions = await loadSubmittedLoanConversions(loanId)

    const arr = submittedLoanConversions.map(
      (a: { loanConversion: LoanConversionParams }) => a.loanConversion,
    )
    const sub_loan_conversions = arr.map((a: LoanConversionParams) => ({
      ...a,
      status: 'Submitted',
    }))

    all_loan_conversions = draftLoanConversions
      .map((loanConversion: LoanConversionParams) => ({
        ...loanConversion,
        status: 'Draft',
      }))
      .concat(sub_loan_conversions)

    return dispatch(getLoanConversions(all_loan_conversions))
  } finally {
    dispatch(stopLoadingLoanConversion())
  }
}
