import { MRT_ColumnDef } from 'mantine-react-table'
import { Text } from '@mantine/core'
import { ReactNode } from 'react'
import { HolidayCalendarParams } from 'app/models/holiday-calendar-params'
import HolidayCalendarActions from './holiday-calendar-actions'
import Pill, { PillColor } from 'app/views/components/Table/Pill'
enum StatusState {
  Active = 'Active',
  Inactive = 'Inactive',
  Scraped = 'Scraped',
}

const getPillColor = new Map<StatusState, PillColor>()
getPillColor.set(StatusState.Active, PillColor.LIGHT_BLUE)
getPillColor.set(StatusState.Inactive, PillColor.GREY)
getPillColor.set(StatusState.Scraped, PillColor.PINK)


function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}
export function getColumnDefs(
  setOpenHolidayCalendar: React.Dispatch<React.SetStateAction<boolean>>,
): MRT_ColumnDef<HolidayCalendarParams>[] {
  return [
    {
      size: 40,
      accessorKey: 'name',
      header: 'Calendar',
      Header: <TableHead header={'Calendar'} />,
      Cell: ({ cell }) => (
        <TableRowText text={cell.getValue<string>()} />
      ),
    },
    {
      size: 100,
      accessorKey: 'status',
      header: 'Status',
      Header: <TableHead header={'Status'} />,
      Cell: ({ cell }) => {
        const color: PillColor =
          getPillColor.get(cell.getValue<string>() as StatusState) ?? PillColor.GREY
        return <Pill text={(cell.getValue<string>() as string) ?? ''} color={color} />
      },
    },
    {
      size: 40,
      accessorKey: 'year',
      header: 'Year',
      Header: <TableHead header={'Year'} />,
      filterVariant: 'range',
      Cell: ({ cell }) => (
        <TableRowText text={cell.getValue<string>()} />
      ),
    },
    {
      size: 40,
      accessorKey: 'id',
      header: 'Actions',
      Header: <TableHead header={'Actions'} />,
      enableColumnActions: false,
      enableColumnFilter: false,
      Cell: cell => (
        <HolidayCalendarActions
          row={cell.row.original}
          setOpenHolidayCalendar={setOpenHolidayCalendar}
        />
      ),
    },
  ]
}
