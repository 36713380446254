import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import configureStore from 'app/state/redux-store'
import MainSwitch from './app'
import * as serviceWorker from 'serviceWorker/'
import React from 'react'
//import styles
import 'app/styles/styles.css'
import 'app/styles/tailwind.css'
import 'react-dropzone-uploader/dist/styles.css'
import 'react-datepicker/dist/react-datepicker.css'

import { Auth0ProviderWithConfig } from './auth0-provider-with-history'

//create store
const rpStore = configureStore()

const container = document.getElementById('root')

if (!container) {
  throw new Error('Root container not found');
}

const root = createRoot(container)

function AppController() {
  function render() {
    root.render(
      <React.StrictMode>
        <Provider store={rpStore}>
          <Auth0ProviderWithConfig>
            <MainSwitch />
          </Auth0ProviderWithConfig>
        </Provider>
      </React.StrictMode>
    )
  }
  render()
}

export default AppController

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
