import { Group } from '@mantine/core'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'

export default function PositionsHeader() {

  return (
    <Group position="apart">
      <PageTitle pageTitle='Positions' />
    </Group>
  )
}
