import { ToDoParams } from "app/models/todo-params"
import { ToDoState } from "app/state/ducks/todos/reducer"

export const getTodos = (state: {
  todos: { todos: ToDoParams[] }
}) => {
  return state.todos.todos
}

export const getTodo = (state: { todos: { todo: ToDoParams } }) => {
  return state.todos.todo
}

export const getTodoLoader = (state: { todos: ToDoState } ) => {
  return state.todos.isLoading
}
