import {
  currencyOptions,
  strBooleanOptions,
  IndexType,
} from 'app/models/dropdown-options'
import TextInput from 'app/views/components/inputs/TextInput'
import { Group, Select, Stack, Checkbox } from '@mantine/core'
import FormWrapper from 'app/views/components/Form/FormWrapper'
import { useForm } from '@mantine/form'
import { IndexRateOptionParams } from 'app/models/index-rate-options-params'
import React, { useEffect, useState } from 'react'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import { saveIndexRateOption } from 'app/state/ducks/index-rate-options/thunks'
import { useDispatch } from 'react-redux'
import config from 'app/config/config'
import { rateTypeOptionsNewIndex } from 'app/models/dropdown-options'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'

type indexRateOptionsFormProps = {
  indexRateOption?: IndexRateOptionParams
  closeModal: () => void
  onHandleSubmit?: (data: any) => void
}

export default function IndexRateOptionsForm({
  indexRateOption,
  closeModal,
  onHandleSubmit,
}: indexRateOptionsFormProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])

  const dispatch = useDispatch()
  const form = useForm({
    initialValues: {
      id: indexRateOption?.id ?? '',
      indexOption: indexRateOption?.indexOption ?? '',
      currency: indexRateOption?.currency ?? '',
      riskFreeRate: indexRateOption?.riskFreeRate == true ? 'true' : 'false',
      indexType: indexRateOption?.indexType ?? '',
      contractPeriods: indexRateOption?.contractPeriods ?? [],
    },
    validate: {
      indexOption: value => (value === '' ? 'Required' : null),
      currency: value => (value === '' ? 'Required' : null),
      riskFreeRate: value => (value === '' ? 'Required' : null),
      indexType: value => (value === '' ? 'Required' : null),
    },
    validateInputOnBlur: true,
  })

  useEffect(() => {
    if (indexRateOption?.contractPeriods) {
      setSelectedOptions(indexRateOption.contractPeriods)
    }
  }, [indexRateOption])

  async function onSubmit(values: IndexRateOptionParams) {
    if (selectedOptions.length === 0 && values.indexType === 'TermIndex') {
      ErrorNotification({
        title: 'Index Rate Option save failed',
        message: 'Please select at least one contract period',
      })
      return
    }
    try {
      setIsLoading(true)

      const indexRateOptionparams: IndexRateOptionParams = {
        ...values,
        status: indexRateOption?.status ?? '',
        riskFreeRate: values?.riskFreeRate == 'false' ? false : true,
        contractPeriods: selectedOptions ?? [],
        hasError: false,
        hasSuccessfulIndexRateOptionSave: false,
        ErrorMessage: '',
        customer_token: config.company.toUpperCase(),
      }
      const response: any = await dispatch(
        saveIndexRateOption(indexRateOptionparams, indexRateOption)
      )

      if (response?.success) {
        SuccessNotification({
          title: 'Successful Save Index Rate Option',
          message: 'You created or edited an indexRateOption',
        })
        closeModal()
        form.reset()
        return response.payload.data
      } else {
        ErrorNotification({
          title: 'Index Rate Option save failed',
          message: response?.payload || 'Check data and try again',
        })
      }
      return undefined
    } catch (error) {
      console.error(error)
      ErrorNotification({
        title: 'Index Rate Option save failed',
        message: 'Check data and try again',
      })
    } finally {
      setIsLoading(false)
    }
  }

  async function submitForm(event: any) {
    event.preventDefault()
    try {
      const indexRateOption = await onSubmit(
        form.values as IndexRateOptionParams
      )
      if (typeof onHandleSubmit === 'function') {
        onHandleSubmit(indexRateOption ?? form.values)
      }
    } catch (err) {
      console.error('Error in submitForm:', err)
    }
  }

  const handleCheckboxChange = (option: string) => {
    setSelectedOptions(prevState =>
      prevState.includes(option)
        ? prevState.filter(item => item !== option)
        : [...prevState, option]
    )
  }

  const handleIndexTypeChange = (value: string) => {
    form.setFieldValue('indexType', value)
    if (value !== 'TermIndex') {
      setSelectedOptions([])
    }
  }

  return (
    <FormWrapper
      title={`${indexRateOption ? 'Edit' : 'Add a New'} Index Rate Option`}
    >
      <div className="content">
        <form onSubmit={submitForm}>
          <div className="create-new" style={{ paddingTop: '15px' }}>
            <Stack w="100%">
              <Group noWrap w="100%">
                <TextInput
                  w="100%"
                  label="IRO Name"
                  name="indexOption"
                  id="indexOption"
                  placeholder="Enter full name"
                  {...form.getInputProps('indexOption')}
                />
                <Select
                  w="100%"
                  label="Currency"
                  searchable
                  placeholder="Select Currency"
                  data={currencyOptions}
                  {...form.getInputProps('currency')}
                />
              </Group>

              <Group noWrap w="100%">
                <Select
                  error
                  w="100%"
                  label="Risk Free Rate"
                  placeholder="Select Yes or No"
                  data={strBooleanOptions}
                  {...form.getInputProps('riskFreeRate')}
                />
                <Select
                  w="100%"
                  label="Type"
                  placeholder="Select Type"
                  searchable={true}
                  data={IndexType}
                  value={form.values.indexType}
                  onChange={handleIndexTypeChange}
                />
              </Group>

              {form.values.indexType === 'TermIndex' && (
                <div style={{ marginTop: '10px' }}>
                  <div>Available Term Rate</div>
                  {Array.from({ length: 5 }).map((_, rowIndex) => (
                    <div className="checkbox-row" key={rowIndex}>
                      {rateTypeOptionsNewIndex
                        .slice(rowIndex * 5, rowIndex * 5 + 5)
                        .map((option, index) => (
                          <Checkbox
                            key={index}
                            label={option.label}
                            checked={selectedOptions.includes(option.value)}
                            onChange={() => handleCheckboxChange(option.value)}
                          />
                        ))}
                    </div>
                  ))}
                </div>
              )}

              <div
                style={{
                  marginTop: '1rem',
                  display: 'flex',
                  gap: '.5rem',
                  justifyContent: 'flex-end',
                }}
              >
                <PrimaryButton
                  disabled={!form.isValid()}
                  className="form-button"
                  loading={isLoading}
                  type="submit"
                  w="100%"
                >
                  Submit
                </PrimaryButton>
              </div>
            </Stack>
          </div>
        </form>
      </div>
    </FormWrapper>
  )
}
