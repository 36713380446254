import * as types from './types'
import { HolidayCalendarParams } from 'app/models/holiday-calendar-params'
import { createReducer } from 'app/state/utils'

export interface HolidayCalendarState {
  calendars: HolidayCalendarParams[]
  calendar?: HolidayCalendarParams
  isLoading: boolean
}

const initialState: HolidayCalendarState = {
    calendars: [],
    calendar: undefined,
    isLoading: false,
}


const calendarsReducer = createReducer(initialState)({
  [types.GET_CALENDARS]: (state: any, payload: any) => {
    return { ...state, calendars: payload.payload }
  },
  [types.GET_CALENDAR]: (state: any, payload: any) => {
    return { ...state, calendar: payload.payload }
  },
  [types.ADD_CALENDARS]: (state: any, payload: any) => {
    return {
      ...state,
      calendars: [payload.payload.data, ...state.calendars],
    }
  },
  [types.EDIT_CALENDARS]: (state: any, payload: any) => {
    const index = state.calendars.findIndex(
      (calendar: { id: any }) => calendar.id === payload.payload.data.id
    )
    const newArray = [...state.calendars]
    newArray[index] = { ...payload.payload.data, status: 'Inactive' }
    return {
      ...state,
      calendars: newArray,
      calendar: { ...payload.payload.data, status: 'Inactive' },
    }
  },
  [types.ACTIVE_CALENDAR]: (state: any, payload: any) => {
    const index = state.calendars.findIndex(
      (calendar: { id: any }) => calendar.id === payload.payload.id
    )
    const newArray = [...state.calendars]
    newArray[index] = { ...newArray[index], status: 'Active' }
    if (payload?.payload?.id === state?.calendar?.id) {
      return {
        ...state,
        calendar: { ...state.calendar, status: 'Active' },
        calendars: newArray,
      }
    }
    return {
      ...state,
      calendars: newArray,
    }
  },
  [types.INACTIVE_CALENDAR]: (state: any, payload: any) => {
    const index = state.calendars.findIndex(
      (calendar: { id: any }) => calendar.id === payload.payload.id
    )
    const newArray = [...state.calendars]
    newArray[index] = { ...newArray[index], status: 'Inactive' }
    if (payload?.payload?.id === state?.calendar?.id) {
      return {
        ...state,
        calendar: { ...state.calendar, status: 'Inactive' },
        calendars: newArray,
      }
    }
    return {
      ...state,
      calendars: newArray,
    }
  },
  [types.DELETE_CALENDARS]: (state: any, payload: any) => {
    const filteredCalendars = state.calendars.filter((calendar: any) => {
      return calendar.id !== payload.payload[0].id
    })

    return {
      ...state,
      calendars: filteredCalendars,
    }
  },
  [types.START_LOADING_CALENDARS]: (state: HolidayCalendarState) => {
    return { ...state, isLoading: true }
  },
  [types.STOP_LOADING_CALENDARS]: (state: HolidayCalendarState) => {
    return { ...state, isLoading: false }
  },
})

export default calendarsReducer
