import { Button, ButtonProps, Text } from '@mantine/core'
import { useNavigate } from 'react-router'

type Props = ButtonProps & {
  navLink?: string
  active?: boolean
}

function HistoryToggleButton({ navLink, active, ...props }: Props) {
  const navigate = useNavigate()

  const onClick = () => {
    if (navLink) {
      navigate(navLink)
    }
  }

  return (
    <Button
      bg={active ? "#3D3D3D" : "#FFFFFF"}
      w="109px"
      h="33px"
      pt="6px"
      pb="6px"
      pl="30px"
      pr="30px"
      style={{ borderRadius: '5px', border: '1px solid #B1B1B1' }}
      onClick={onClick}
      styles={{
        root: {
          '&:hover': {
            background: active ? "#3D3D3D" : "#FFFFFF",
          },
        },
      }}
      {...props}
    >
      <Text
        color={active ? "#FFFFFF" : "#3D3D3D"}
        fz="14px"
        fw={600}
        style={{
          fontFamily: 'Plus Jakarta Sans',
          color: active ? "#FFFFFF" : "#3D3D3D",
          lineHeight: '21px'
        }}
      >
        History
      </Text>
    </Button>
  )
}
export default HistoryToggleButton