import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import {
  getPrincipalPayments,
  addPrincipalPayment,
  editPrincipalPayment,
  submitPrincipalPayment,
  approvePrincipalPayment,
  rejectPrincipalPayment,
  deletePrincipalPayment,
  startLoadingPrincipalPayment,
  stopLoadingPrincipalPayment,
} from 'app/state/ducks/principal-payment/actions'
import { DraftPrincipalPaymentParams } from 'app/models/principal-payments-params'

export const savePrincipalPayment =
  (
    savedPrincipalPayment: DraftPrincipalPaymentParams,
    principalPayment?: any
  ) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    const updated_savedPrincipalPayment = {
      paymentType: savedPrincipalPayment.paymentType,
      id: principalPayment?.id ?? null,
      loanId: savedPrincipalPayment.loanId,
      amount: savedPrincipalPayment.amount,
      acceptedLenders: savedPrincipalPayment.acceptedLenders,
      interestPaid: savedPrincipalPayment.interestPaid,
      interestAmount: savedPrincipalPayment.interestAmount,
      paymentDate: savedPrincipalPayment.paymentDate,
      noteId: principalPayment?undefined:savedPrincipalPayment.noteId,
      documentIds: principalPayment?[]:savedPrincipalPayment.documentIds,
      hasError: false,
      ErrorMessage: '',
      customer_token: config.company.toUpperCase(),
    }
    if (principalPayment) {
      return client
        .put(
          `${apiServerUrl}/api/draft-principal-payments`,
          updated_savedPrincipalPayment
        )
        .then(response => {
          const payload = dispatch(editPrincipalPayment(response))
          return {success: true, payload: payload.payload}
        }).catch(e => {
          return {success: false, payload: e.response.data.error}
        })
    } else {
      return client
        .post(
          `${apiServerUrl}/api/draft-principal-payments`,
          updated_savedPrincipalPayment
        )
        .then(response => {
          const payload = dispatch(addPrincipalPayment(response))
          return {success: true, payload: payload.payload}
        }).catch(e => {
          return {success: false, payload: e.response.data.error}
        })
    }
  }

export const loadApprovedPrincipalPayments = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/approved-principal-payments`)
    .then(response => {
      return response.data
    }).catch(e => {
      console.log(e)
      return []
    })
}

export const loadDraftPrincipalPayments = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/draft-principal-payments`)
    .then(response => {
      return response.data
    }).catch(e => {
      console.log(e)
      return []
    })
}

export const loadSubmittedPrincipalPayments = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/submitted-principal-payments`)
    .then(response => {
      return response.data
    }).catch(e => {
      console.log(e)
      return []
    })
}

export const submitForApproval =
  (principalPayment: any) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/draft-principal-payments/submit`, {
        id: principalPayment.id,
      })
      .then(response => {
        const payload = dispatch(submitPrincipalPayment(response.data))
        return {success: true, payload: payload.payload}
      }).catch(e => {
        return {success: false, payload: e.response.data.error}
      })
  }

export const approveSelectedPrincipalPayment =
  (principalPayment: any) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/submitted-principal-payments/approve`, {
        id: principalPayment.id,
      })
      .then(response => {
        const payload = dispatch(approvePrincipalPayment(response.data))
        return {success: true, payload: payload.payload}
      }).catch(e => {
        return {success: false, payload: e.response.data.error}
      })
  }

export const rejectSelectedPrincipalPayment =
  (principalPayment: any) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/submitted-principal-payments/reject`, {
        id: principalPayment.id,
      })
      .then(response => {
        const payload = dispatch(rejectPrincipalPayment(response.data))
        return {success: true, payload: payload.payload}
      }).catch(e => {
        return {success: false, payload: e.response.data.error}
      })
  }

export const deleteSelectedPrincipalPayment =
  (principalPayment: any) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .delete(
        `${apiServerUrl}/api/draft-principal-payments?ids[]=${principalPayment.id}`
      )
      .then(() => {
        return dispatch(deletePrincipalPayment(principalPayment))
      }).catch(e => {
        return {success: false, payload: e.response.data.error}
      })
  }

export const loadAllPrincipalPayments = () => async (dispatch: Dispatch) => {
  try {
  dispatch(startLoadingPrincipalPayment())
  const draftPrincipalPayments = await loadDraftPrincipalPayments()
  const submittedPrincipalPayments = await loadSubmittedPrincipalPayments()

  const arr = submittedPrincipalPayments.map(
    (a: { principalPayment: any }) => a.principalPayment
  )
  const sub_principal_payment = arr.map((a: any) => ({
    ...a,
    status: 'Submitted',
  }))

  const all_principalPayments = draftPrincipalPayments
    .map((principalPayment: any) => ({ ...principalPayment, status: 'Draft' }))
    .concat(sub_principal_payment)

  return dispatch(getPrincipalPayments(all_principalPayments))
} finally {
  dispatch(stopLoadingPrincipalPayment())
}
}


export const getAmountWithInterest = (body:any) => {
  const apiServerUrl = config.backendUrl
  body = {
    ...body,
    hasError: false,
    ErrorMessage: "",
    customer_token: "RIGHTPEDAL",
  }
  return client
    .post(
      `${apiServerUrl}/api/draft-principal-payments/precalculate`,
      body
    )
    .then(response => {
      return {success: true, payload: response.data}
    }).catch(e => {
      console.log(e, 'error')
      return {success: false, payload: e.response.data.error}
    })

}

export const getPaymentLenderBreakdown = (loanId: string, paymentId:string) => {
  const apiServerUrl = config.backendUrl
  
  return client
    .get(
      `${apiServerUrl}/api/approved-loans/payment-breakdown?loanId=${loanId}&paymentId=${paymentId}`
    )
    .then(response => {
      return {success: true, payload: response.data}
    }).catch(e => {
      console.log(e, 'error')
      return {success: false, payload: e.response.data.error}
    })

}
