import * as types from './types'
import { createReducer } from 'app/state/utils'

const initialState: never[] = []

const signupReducer = createReducer(initialState)({
  [types.SET_USER]: (state: any, payload: any) => {
    return { ...state, users: payload }
  },
  [types.SET_REGISTERED_USER]: (state: any, payload: any) => {
    return { ...state, registered_user: payload }
  },
})

export default signupReducer
