import { Group, Accordion, Text } from '@mantine/core'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import { ReactNode } from 'react'
import { useSelector } from 'react-redux'

interface Row {
  label: string
  value: string | number | Date | ReactNode
}

interface Props {
  title: string
  data?: Row[]
}

export function ListAccordion({ title, data }: Props) {
  const businessDate = useSelector(getBusinessDate)
  return (
    <Accordion.Item value={title}>
      <Accordion.Control fw={600} fz="14px" p="lg">
        {title}
      </Accordion.Control>
      <Accordion.Panel>
        {data?.map(({ label, value }: Row, index) => {
          let valueDisplay: ReactNode

          if (value instanceof Date) {
            value = new Date(value ?? businessDate).toLocaleDateString('en-US')
          }
          if (typeof value === 'object') {
            valueDisplay = value
          }
          if (typeof value === 'string' || typeof value === 'number') {
            valueDisplay = (
              <Text
                fz="14px"
                fw={400}
                sx={{
                  fontFamily: 'Plus Jakarta Sans',
                }}
              >
                {value}
              </Text>
            )
          }
          return (
            <Group position="left" key={index}>
              <Text
                ml="5%"
                fz="14px"
                fw={800}
                w="45%"
                sx={{
                  fontFamily: 'Plus Jakarta Sans',
                }}
              >
                {label}
              </Text>

              {valueDisplay}
            </Group>
          )
        })}
      </Accordion.Panel>
    </Accordion.Item>
  )
}
