import { MRT_ColumnDef } from 'mantine-react-table'
import { Text } from '@mantine/core'
import { ReactNode } from 'react'

function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}


export function getColumnDefs(
): MRT_ColumnDef<{ pikDate: string | Date }>[] {
  return [
    {
      size: 40,
      accessorKey: 'pikDate',
      header: 'PIK Date',
      Header: <TableHead header={'PIK Date'} />,
      Cell: ({ cell }) => (
        <TableRowText text={cell.getValue<string>()} />
      ),
    }
  ]
}
