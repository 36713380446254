import config from 'app/config/config'
import { InterestRateParams } from 'app/models/interest-rate'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import { addRate, getRates, simulateAPIForIndex } from './actions'
import { IndexRateOptionParams } from 'app/models/index-rate-options-params'

export const saveRate =
  (savedRate: InterestRateParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/interest-rates`, savedRate)
      .then(rate => {
        dispatch(addRate(rate))
        return { payload: rate.data, success: true }
      })
      .catch(e => {
        return { payload: e.response.data.error, success: false }
      })
  }

export const simulateApi = () => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client.post(`${apiServerUrl}/api/rate/simulateAPI`).then(rates => {
    return dispatch(simulateAPIForIndex(rates.data))
  }).catch(e => {
    return {success: false, payload: e.response.data.error}
  })
}

export const loadInterestByIndex =
  (index: IndexRateOptionParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .get(`${apiServerUrl}/api/interest-rates/index?id=${index.id}`)
      .then(rates => {
        return dispatch(getRates(rates.data))
      }).catch(e => {
        return {success: false, payload: e.response.data.error}
      })
  }
