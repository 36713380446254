import { MRT_ColumnDef } from 'mantine-react-table'
import { Text } from '@mantine/core'
import { ReactNode } from 'react'
import Pill, { PillColor } from 'app/views/components/Table/Pill'
import { EntityParams } from 'app/models/entity-params'
import EntityActions from './entity-actions'
import { kycStatusOptions } from 'app/models/dropdown-options'

enum StatusState {
  Active = 'Active',
  Unapproved = 'Unapproved',
  PendingKYC = 'Pending KYC',
  Inactive = 'Inactive',
}

const getPillColor = new Map<StatusState, PillColor>()
getPillColor.set(StatusState.Active, PillColor.GREEN)
getPillColor.set(StatusState.Unapproved, PillColor.PINK)
getPillColor.set(StatusState.PendingKYC, PillColor.ORANGE)
getPillColor.set(StatusState.Inactive, PillColor.GREY)

function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}

export const entityCols: MRT_ColumnDef<EntityParams>[] = [
  {
    accessorKey: 'entityName',
    header: 'Entity Name',
    Header: <TableHead header={'Entity Name'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 80,
    accessorKey: 'internalId',
    header: 'ID',
    Header: <TableHead header={'ID'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 100,
    accessorKey: 'status',
    header: 'Status',
    Header: <TableHead header={'Status'} />,
    Cell: ({ cell }) => {
      const color: PillColor =
        getPillColor.get(cell.getValue<string>() as StatusState) ?? PillColor.GREY
      return <Pill text={(cell.getValue<string>() as string) ?? ''} color={color} />
    },
  },
  {
    accessorKey: 'mei',
    header: 'MEI',
    Header: <TableHead header={'MEI'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 90,
    accessorKey: 'kycType',
    header: 'KYC Type',
    Header: <TableHead header={'KYC Type'} />,
    Cell: ({ cell }) => {
      let value = cell.getValue()
      if (value === null || value === '') {
        value = 'N/A'
      }
      return <TableRowText text={value as string} />
    },
  },
  {
    accessorKey: 'entityType',
    header: 'Type',
    Header: <TableHead header={'Type'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    accessorKey: 'entityProfile',
    header: 'Profile',
    Header: <TableHead header={'Profile'} />,
    Cell: ({ cell }) => {
      const value = cell.getValue<string[]>()
      const result =
        !value || value.length == 0
          ? ''
          : value.length > 1
            ? 'Multiple'
            : value[0]

      return <TableRowText text={result} />
    },
  },
  {
    accessorFn: row => row.fundManager ?? row.parent,//set the ability to filter by fund manager or parent
    accessorKey: 'parent',
    header: 'Fund Manager / Parent',
    Header: <TableHead header={'Fund Manager / Parent'} />,
    Cell: ({ cell, row }) => {
      return (
        <TableRowText text={cell.getValue<string>() ?? row.original.parent ?? ''} />
      )
    },
  },
  {
    accessorKey: 'taxFormStatus',
    header: 'Tax Form Status',
    Header: <TableHead header={'Tax Form Status'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    accessorKey: 'kycStatus',
    header: 'KYC Status',
    Header: <TableHead header={'KYC Status'} />,
    Cell: ({ cell }) => <TableRowText text={kycStatusOptions?.find(
      option => option.value === cell.getValue<string>()
    )?.label ?? 'Pending'} />,
  },
  {
    accessorKey: 'id',
    header: 'Actions',
    Header: <TableHead header={'Actions'} />,
    enableColumnActions: false,
    enableColumnFilter: false,
    Cell: cell => <EntityActions row={cell.row.original} />,
  },
]
