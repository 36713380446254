import { Menu } from '@mantine/core'
import PrimaryDropDown from 'app/views/components/DropDown/DropDown'
import { useDispatch } from 'react-redux'
import {
  approveServicing,
  deleteServicing,
  rejectServicing,
  submitServicing,
} from './servicing-daml-actions'
import { ServicingParams } from 'app/models/servicing-params'
import IonIcon from '@reacticons/ionicons'

interface actionParam {
  row: ServicingParams
}

export default function ServicingActions({ row }: actionParam) {
  const dispatch = useDispatch()

  function editServicing(servicingId: string) {
    console.log('servicingId', servicingId)
  }

  return (
    <div>
      <Menu shadow="md" width={200} position="bottom" withinPortal>
        <Menu.Target>
          <div style={{ width: 'fit-content' }}>
            <PrimaryDropDown>Actions</PrimaryDropDown>
          </div>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
            onClick={() => editServicing(row.id)}
          >
            Edit
          </Menu.Item>

          <Menu.Item
            icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
            onClick={() => {
              submitServicing(row, dispatch)
            }}
          >
            Submit For Approval
          </Menu.Item>

          <Menu.Item
            icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
            onClick={() => {
              approveServicing(row, dispatch)
            }}
          >
            Approve
          </Menu.Item>

          <Menu.Item icon={<IonIcon className="action-ion-icon" name='open-outline' />}>Amendment</Menu.Item>

          <>
            <Menu.Divider />
            <Menu.Label>Danger zone</Menu.Label>
          </>

          <Menu.Item
            color="red"
            icon={<IonIcon className="action-ion-icon" name='trash-outline' />}
            onClick={() => {
              deleteServicing(row, dispatch)
            }}
          >
            Delete
          </Menu.Item>

          <Menu.Item
            color="red"
            icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
            onClick={() => {
              rejectServicing(row, dispatch)
            }}
          >
            Reject
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  )
}