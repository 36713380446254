import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import {
  getDealAmendments,
  addDealAmendment,
  editDealAmendment,
  getDealAmendment,
  submitDealAmendment,
  rejectDealAmendment,
  approveDealAmendment,
  deleteDealAmendment,
} from './actions'
import { DraftDealParams, DealParams } from 'app/models/deal-params'
import { TerminationParams } from 'app/models/termination-params'
import { getBusinessDateFromState } from '../business-date/thunks'
import { formatDateToUTC } from 'app/utils/util-functions'

export const saveDealAmendment =
  (savedDeal: DraftDealParams, deal?: any) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    savedDeal.fees.map((fee: any) => {
      if (fee.dayBasis === '') fee.dayBasis = null
    })
    const businessDate = getBusinessDateFromState()
    const updated_savedDeal: DraftDealParams = {
      ...savedDeal,
      amendmentType: savedDeal.amendmentType ?? 'Amendments',
      amendmentDate: savedDeal.amendmentDate ?? formatDateToUTC(businessDate),
      hasError: false,
      hasSuccessfulEntitySave: false,
      ErrorMessage: '',
      customer_token: config.company.toUpperCase(),
    }
    if (deal) {
      return client
        .put(`${apiServerUrl}/api/amendment-draft-deals`, updated_savedDeal)
        .then(() => {
          dispatch(editDealAmendment(updated_savedDeal))
          return { success: true, payload: updated_savedDeal }
        })
        .catch(e => {
          return { success: false, payload: e.response.data.error }
        })
    } else {
      return client
        .post(`${apiServerUrl}/api/approved-deals/amendment`, updated_savedDeal)
        .then(() => {
          dispatch(addDealAmendment(updated_savedDeal))
          return { success: true, payload: updated_savedDeal }
        })
        .catch(e => {
          return { success: false, payload: e.response.data.error }
        })
    }
  }

const loadDraftDealAmendments = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/amendment-draft-deals`)
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

export const saveDealTermination =
  (terminationData: TerminationParams, dealAmendment?: DealParams) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    if (dealAmendment) {
      dealAmendment.amendmentDate = terminationData.amendmentDate
      return client
        .put(`${apiServerUrl}/api/amendment-draft-deals`, dealAmendment)
        .then(data => {
          dispatch(editDealAmendment(dealAmendment))
          return { payload: data.data, success: true }
        })
        .catch(e => {
          return { success: false, payload: e.response.data.error }
        })
    } else {
      return client
        .post(`${apiServerUrl}/api/approved-deals/terminate`, terminationData)
        .then(data => {
          dispatch(addDealAmendment(data))
          return { payload: data.data, success: true }
        })
        .catch(e => {
          return { success: false, payload: e.response.data.error }
        })
    }
  }

const loadSubmittedDealAmendments = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/amendment-submitted-deals`)
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

export const loadAllDealAmendments = () => async (dispatch: Dispatch) => {
  try {
    const [draftDeals, submittedDeals] = await Promise.all([
      loadDraftDealAmendments(),
      loadSubmittedDealAmendments(),
    ])
    
    const subDeals = submittedDeals.map(
      (a: { deal: any; amendmentType: string; amendmentDate: string }) => ({
        ...a.deal,
        amendmentType: a.amendmentType,
        amendmentDate: a.amendmentDate,
        status: 'Submitted',
      }),
    )
    const allDeals = draftDeals
      .map((deal: any) => ({ ...deal, status: 'Draft' }))
      .concat(subDeals)
    return dispatch(getDealAmendments(allDeals))
  } catch (error) {
    console.log('THUNK ERROR', error)
  } 
}

export const loadOneDealAmendment =
  (dealId: string, status: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    const statusLower = status.toLowerCase()
    return client
      .get(
        `${apiServerUrl}/api/amendment-${statusLower}-deals/one?id=${dealId}`,
      )
      .then(deals => {
        if (statusLower === 'submitted') {
          return dispatch(
            getDealAmendment({
              ...deals.data.deal,
              amendmentType: deals.data.amendemntType,
              amendmentDate: deals.data.amendemntDate,
              status: status,
            }),
          )
        }
        return dispatch(getDealAmendment({ ...deals.data, status: status }))
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const submitForApprovalAmendment =
  (deal: DealParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/amendment-draft-deals/submit`, { id: deal.id })
      .then(response => {
        dispatch(submitDealAmendment(response.data))
        return { success: true }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const rejectSubmittedDealAmendment =
  (deal: DealParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/amendment-submitted-deals/reject`, {
        id: deal.id,
      })
      .then(response => {
        dispatch(rejectDealAmendment(response.data))
        return { success: true }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const approveSubmittedDealAmendment =
  (deal: DealParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/amendment-submitted-deals/approve`, {
        id: deal.id,
      })
      .then(response => {
        dispatch(approveDealAmendment(response.data))
        return { success: true }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const deleteDraftDealAmendment =
  (ids: string[]) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .delete(`${apiServerUrl}/api/amendment-draft-deals?ids=${ids}`)
      .then(response => {
        return dispatch(deleteDealAmendment(response.data))
      })
      .catch(e => {
        console.log('THUNK ERROR', e)
      })
  }
