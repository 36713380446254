import React from 'react'
import { Modal } from '@mantine/core'
import WireInstructionsForm from './wire-instructions-form'

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
  paymentInstruction?: any
  entityId?: string
}
export function WireInstructionsModal({
  setOpen,
  open,
  paymentInstruction,
  entityId,
}: Props) {
  return (
    <Modal
      className="modal-body create-new-form fit-content-modal"
      opened={open}
      onClose={() => setOpen(false)}
      centered={true}
      size="45%"
    >
      <WireInstructionsForm
        closeModal={() => setOpen(false)}
        paymentInstruction={paymentInstruction ?? undefined}
        entityId={entityId}
        onHandleSubmit={() => undefined}
      />
    </Modal>
  )
}
