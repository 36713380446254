import { LetterOfCreditParams, LocHistoryData } from 'app/models/letter-of-credit'
import * as types from './types'
import { createReducer } from 'app/state/utils'

export interface LetterOfCreditState {
  approvedLetterOfCredits: LetterOfCreditParams[]
  letterOfCredits: LetterOfCreditParams[]
  letterOfCredit?: LetterOfCreditParams
  locHistory: LocHistoryData[]
  isLoading: boolean 
}

const initialState: LetterOfCreditState = {
  approvedLetterOfCredits: [],
  letterOfCredits: [],
  letterOfCredit: undefined,
  locHistory: [],
  isLoading: false,
}

const letterOfCreditsReducer = createReducer(initialState)({
  [types.GET_LETTER_OF_CREDITS]: (state: LetterOfCreditState, payload: any) => {
    return { ...state, letterOfCredits: payload.payload }
  },

  [types.GET_LETTER_OF_CREDIT]: (state: LetterOfCreditState, payload: any) => {
    return { ...state, letterOfCredit: payload.payload }
  },
  [types.GET_LETTER_OF_CREDIT_HISTORY]: (state: LetterOfCreditState, payload: any) => {
    return { ...state, locHistory: payload.payload }
  },

  [types.GET_APPROVED_LETTER_OF_CREDITS]: (
    state: LetterOfCreditState,
    payload: any
  ) => {
    return { ...state, approvedLetterOfCredits: payload.payload }
  },

  [types.GET_ALL_APPROVED_LETTER_OF_CREDITS]: (
    state: LetterOfCreditState,
    payload: any
  ) => {
    return {
      ...state,
      approvedLetterOfCredits: payload.payload.map((letterOfCredit: any) => ({
        ...letterOfCredit,
        status: 'Approved',
      })),
    }
  },

  [types.EDIT_LETTER_OF_CREDIT]: (state: LetterOfCreditState, payload: any) => {
    const index = state.letterOfCredits.findIndex(
      letterOfCredit => letterOfCredit.id === payload.payload.data.id
    )
    const newArray = [...state.letterOfCredits]
    newArray[index] = { ...payload.payload.data, status: 'Draft' }
    return {
      ...state,
      letterOfCredits: newArray,
    }
  },

  [types.ADD_LETTER_OF_CREDIT]: (state: LetterOfCreditState, payload: any) => {
    return {
      ...state,
      letterOfCredits: [
        { ...payload.payload.data, status: 'Draft' },
        ...state.letterOfCredits,
      ],
    }
  },

  [types.SUBMIT_LETTER_OF_CREDIT]: (
    state: LetterOfCreditState,
    payload: any
  ) => {
    if (payload.payload.error) {
      return {
        ...state,
      }
    }
    const obj = state.letterOfCredits.find(
      letterOfCredit => letterOfCredit.id == payload.payload.id
    )
    if (!obj) {
      return state
    }
    obj.status = 'Submitted'
    return {
      ...state,
      letterOfCredits: [...state.letterOfCredits],
    }
  },

  [types.APPROVE_LETTER_OF_CREDIT]: (
    state: LetterOfCreditState,
    payload: any
  ) => {
    const obj = state.letterOfCredits.find(
      letterOfCredit => letterOfCredit.id == payload.payload.id
    )
    if (!obj) {
      return state
    }
    obj.status = 'Approved'

    return {
      ...state,
      approvedLetterOfCredits: [...state.approvedLetterOfCredits, obj],
      letterOfCredits: [...state.letterOfCredits],
    }
  },

  [types.REJECT_LETTER_OF_CREDIT]: (
    state: LetterOfCreditState,
    payload: any
  ) => {
    const obj = state.letterOfCredits.find(
      letterOfCredit => letterOfCredit.id == payload.payload.id
    )
    if (!obj) {
      return state
    }
    obj.status = 'Draft'
    return {
      ...state,
      letterOfCredits: [...state.letterOfCredits],
    }
  },

  [types.DELETE_LETTER_OF_CREDIT]: (
    state: LetterOfCreditState,
    payload: any
  ) => {
    const index = state.letterOfCredits.findIndex(
      letterOfCredit => letterOfCredit.id == payload.payload.id
    )
    if (index >= 0) {
      state.letterOfCredits.splice(index, 1)
    }
    return {
      ...state,
      letterOfCredits: [...state.letterOfCredits],
    }
  },
  [types.START_LOADING_FACILITY_LETTERS_OF_CREDIT]: (state: LetterOfCreditState) => {
    return { ...state, isLoading: true } 
  },
  [types.STOP_LOADING_FACILITY_LETTERS_OF_CREDIT]: (state: LetterOfCreditState) => {
    return { ...state, isLoading: false }
  },
})

export default letterOfCreditsReducer
