import React from 'react';
import { TextInput, Radio, Group } from '@mantine/core';
import NumberInput from '../inputs/NumericalInput'
import { DraftDealParams } from 'app/models/deal-params';
import { FacilityLevelData, FacilityPricingGrid } from 'app/models/facility-params';
import { UseFormReturnType } from '@mantine/form';

interface LevelDetailsProps {
    form: UseFormReturnType<{ pricingGrid: FacilityPricingGrid[] }>;
    pricingGridIndex: number;
    idx: number;
    deal: DraftDealParams | undefined;
    level: FacilityLevelData;
    handleLevelChange: (idx: number) => void;
}

const LevelDetails: React.FC<LevelDetailsProps> = ({
    form,
    pricingGridIndex,
    idx,
    deal,
    level,
    handleLevelChange
}) => {
    return (
        <>
            <TextInput
                readOnly
                w="8.3%"
                label="Level"
                placeholder="Level"
                value={idx + 1}
            />

            <TextInput
                readOnly
                w="41.5%"
                label="Ratios"
                placeholder="Ratios"
                value={`${level.level}`}
            />

            <NumberInput
                w="16.6%"
                disabled={deal?.pricingGrid.find((grid: { pricingGridType: string; }) => grid.pricingGridType === form.values.pricingGrid[pricingGridIndex].pricingGridType)?.marginOrFee === 'FeePricingGrid'}
                precision={5}
                defaultValue={0.0}
                label="Margin Rate"
                placeholder="Please insert margin"
                parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : ''}
                {...form.getInputProps(`pricingGrid.${pricingGridIndex}.levels.${idx}.margin`)}
            />

            <NumberInput
                w="16.6%"
                disabled={deal?.pricingGrid.find((grid: { pricingGridType: string; }) => grid.pricingGridType === form.values.pricingGrid[pricingGridIndex].pricingGridType)?.marginOrFee === 'MarginPricingGrid'}
                precision={5}
                defaultValue={0.0}
                label="Fee Rate"
                placeholder="Please insert fee"
                parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : ''}
                {...form.getInputProps(`pricingGrid.${pricingGridIndex}.levels.${idx}.fee`)}
            />

            <Radio.Group
                w="16.6%"
                label="Current Level"
                styles={{
                    label: { fontSize: '14px' } // Set font size to 14px for the label
                }}
                value={level.currentLevel ? idx.toString() : ''}
                onChange={() => handleLevelChange(idx)}
            >
                <Group style={{ justifyContent: 'center', width: '80%', marginTop: '15px' }}>
                    <Radio value={idx.toString()} checked={level.currentLevel} />
                </Group>
            </Radio.Group>
        </>
    );
};

export default LevelDetails;