import KPI from 'app/views/components/KPI/KPI'
import { Group, ScrollArea, Stack } from '@mantine/core'

type Props = {
  isLoading?: boolean,
  kpiData: {
    title: string,
    amount: number | JSX.Element | string,
    color?: number | undefined,
    activeFilter: boolean,
    event?: (() => void) | undefined,
  }[]
}

function KPI_Section({ kpiData, isLoading }: Props) {
  return (
    <Stack w="100%">
      <ScrollArea
        style={{
          width: '100%',
          height: '10%',
        }}
        type="always"
        offsetScrollbars
        styles={() => ({
          scrollbar: {
            '&[data-orientation="horizontal"]': {
              backgroundColor: 'transparent',
              display: 'hidden',
            },
          },
        })}
      >
        <Group w="100%" noWrap position='left' style={{ gap: 0 }}>
          {kpiData.map((f: any, key: any) => (
            <KPI {...f} key={key} isLoading={isLoading} />
          ))}
        </Group>
      </ScrollArea>
    </Stack>
  )
}

export default KPI_Section
