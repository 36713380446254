import { Accordion, Anchor, Grid, SimpleGrid, Text } from '@mantine/core'
import { LoanParams } from 'app/models/loan-params'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { BaseText, BoldText, ClickText } from 'app/views/components/Headers&Text/Text'
import Pill, { PillColor } from 'app/views/components/Table/Pill'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import LoanActions from './loan-actions'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import { getIndexRateOptions } from 'app/state/ducks/index-rate-options/selectors'
import { useNavigate } from 'react-router-dom'
import { getFacility } from 'app/state/ducks/facilities/selectors'
import { getAmountWithInterest } from 'app/state/ducks/principal-payment/thunks'
import { formatDateToUTC, formatNumberToCurrency } from 'app/utils/util-functions'
import { getLoanRollovers } from 'app/state/ducks/loan-rollovers/selectors'
import { getLoanIncreases } from 'app/state/ducks/loan-increases/selectors'
import { getLoanConversions } from 'app/state/ducks/loan-conversions/selectors'
import { getPrincipalPayments } from 'app/state/ducks/principal-payment/selectors'

type Props = {
  item: LoanParams
  setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>
  setLoan: React.Dispatch<React.SetStateAction<any>>
}

enum pillState {
  'Draft' = 'Draft',
  'Submitted' = 'Submitted',
  'Approved' = 'Approved',
  'Locked' = 'Locked',
}

export default function OneLoanAccordion({ item, setOpenEdit, setLoan }: Props) {
  const navigate = useNavigate()
  const businessDate = useSelector(getBusinessDate)
  const entities = useSelector(getEntities)
  const allIndexes = useSelector(getIndexRateOptions)
  const allPayments = useSelector(getPrincipalPayments)
  const facility = useSelector(getFacility)
  const [currentAmountWithInterest, setCurrentAmountWithInterest] = useState<{ amount: number, interest: number }>()
  const [open, setOpen] = useState(false)
  const loanRollover = useSelector(getLoanRollovers)
  const rollover = loanRollover.find(lr => lr?.loanId?.id === item?.id) ?? undefined
  const loanIncrease = useSelector(getLoanIncreases)
  const loanConversions = useSelector(getLoanConversions)
  const conversion = loanConversions.find(lc => lc?.loanId?.id === item?.id) ?? undefined
  const increase = loanIncrease.find(li => li?.loanId?.id === item?.id) ?? undefined
  const payment = allPayments.find(p => p.loanId.id === item.id)

  async function precalculate() {
    const currentDate = await getAmountWithInterest({
      amount: 0,
      paymentDate: formatDateToUTC(businessDate),
      interestPaid: true,
      acceptedLenders: null,
      loanId: { id: item.id, admin: item.agencyAdmin },
    })
    setCurrentAmountWithInterest(currentDate.payload)
  }

  function PillStatus({ ...props }) {
    const state = item.status
    let collor = PillColor.GREY
    if (state === pillState.Submitted) {
      collor = PillColor.ORANGE
    }
    if (state === pillState.Approved) {
      collor = PillColor.LIGHT_BLUE
    }
    if (state === pillState.Draft) {
      collor = PillColor.GREY
    }
    if (state === pillState.Locked) {
      collor = PillColor.GREY
    }

    return <Pill text={item.status === "Locked" ? "INACTIVE" : state ?? '-'} color={collor} {...props} />
  }

  // Utility to compute the difference in days between two dates.
  function daysDifference(
    start: string | undefined,
    end: string | undefined
  ): number {
    if (!start || !end) return 0
    return moment(end).diff(moment(start), 'days')
  }

  // Utility to compute the accrual interest for a given day difference.
  function computeAccrualInterest(
    interest: number | undefined,
    daysDiff: number
  ): number {
    if (!interest || daysDiff === 0) return 0
    return interest / daysDiff
  }

  function generateDatesAndAmounts(
    startDate: string,
    endDate: string,
    accrualRatePerDay: number
  ): Array<{ date: string; amount: number }> {
    const start = moment(startDate)
    const end = moment(endDate)
    const results: Array<{ date: string; amount: number }> = []

    let currentDate = start.clone()
    let currentAmount = 0 // This is set to 0 as the amount for the start date is 0.

    while (currentDate.isSameOrBefore(end)) {
      results.push({
        date: currentDate.format('YYYY-MM-DD'),
        amount: currentAmount,
      })

      currentDate = currentDate.add(1, 'day')
      currentAmount += accrualRatePerDay
    }

    return results
  }

  const startDateEndDateDiff = daysDifference(item?.startDate, item?.endDate)

  const accrualRatePerDay = computeAccrualInterest(
    item?.accrualInterestMaturityDate,
    startDateEndDateDiff
  )

  generateDatesAndAmounts(item?.startDate, item?.endDate, accrualRatePerDay)
  const iro = facility?.iroValues?.find(iro => iro?.indexOption?.id === item?.indexOption?.id)
  return (
    <>
      <Accordion.Control
        className="loan-accordion-button"
        pt={0}
        pb={0}
        onClick={() => {
          if (!open) {
            precalculate()
          }
          setOpen(!open)
        }
        }
      >
        <Grid columns={31} pb="0px" pt="0px">
          <Grid.Col
            span={4}
            style={{
              color: open ? 'rgb(0,0,0,0.34)' : 'rgb(0,0,0)',
              textAlign: 'center',
            }}
          >
            <Text>{allIndexes.find(index => index?.id === item?.indexOption?.id)?.indexOption ?? ''}</Text>
          </Grid.Col>
          <Grid.Col
            span={4}
            style={{
              color: open ? 'rgb(0,0,0,0.34)' : 'rgb(0,0,0)',
              textAlign: 'center',
            }}
          >
            <Text>{item?.startDate}</Text>
          </Grid.Col>
          <Grid.Col
            span={4}
            style={{
              color: open ? 'rgb(0,0,0,0.34)' : 'rgb(0,0,0)',
              textAlign: 'center',
            }}
          >
            <Text>{item?.endDate}</Text>
          </Grid.Col>
          <Grid.Col
            span={6}
            style={{
              color: open ? 'rgb(0,0,0,0.34)' : 'rgb(0,0,0)',
              textAlign: 'center',
            }}
          >
            <Text>
              {formatNumberToCurrency(Number(item?.amount) ?? 0, item?.currency ?? 'USD')}
            </Text>
          </Grid.Col>
          <Grid.Col
            span={3}
            style={{
              color: open ? 'rgb(0,0,0,0.34)' : 'rgb(0,0,0)',
              textAlign: 'center',
            }}
          >
            <Text>{item?.allInRate}</Text>
          </Grid.Col>

          <Grid.Col
            span={5}
            style={{
              color: open ? 'rgb(0,0,0,0.34)' : 'rgb(0,0,0)',
              textAlign: 'center',
            }}
          >
            <PillStatus w="inherit" />
          </Grid.Col>
          <Grid.Col
            span={5}
            style={{ textAlign: 'center' }}
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <LoanActions
              item={item}
              increase={increase}
              rollover={rollover}
              conversion={conversion}
              payment={payment}
              setOpenEdit={setOpenEdit}
              setLoan={setLoan}
              fromOutside={true}
            />
          </Grid.Col>
        </Grid>
      </Accordion.Control>
      <Accordion.Panel>
        <SimpleGrid cols={2} pt="15px">
          <BoldText text="Borrower" />
          <BaseText
            text={
              item.borrower?.id
                ? entities.find(entity => entity.id === item.borrower.id)
                  ?.entityName ?? '-'
                : '-'
            }
          />

          <BoldText text="Amount" />
          <BaseText
            text={formatNumberToCurrency(Number(item?.amount) ?? 0, item?.currency ?? 'USD')}
          />

          <BoldText text="Start Date" />
          <BaseText text={item?.startDate} />

          <BoldText text="Maturity Date" />
          <BaseText text={item?.endDate} />

          {allIndexes.find(index => index?.id === item?.indexOption?.id)?.indexType !== 'TermIndex' && <>  <Anchor
            onClick={() =>
              navigate(
                `/dealmanagement/facilities/${facility?.id}/${facility?.status}/${item?.id}/${item?.status}/interest-payment-schedule`
              )
            }
          >
            {' '}
            <ClickText text="Interest Payment Schedule" />
          </Anchor>
            <BaseText text={item?.nextInterestPaymentDate ?? ''} />
          </>}
          {item?.nextPIKDate && <>
            <Anchor
              onClick={() =>
                navigate(
                  `/dealmanagement/facilities/${facility?.id}/${facility?.status}/${item?.id}/${item?.status}/pik-schedule`
                )
              }
            >
              {' '}
              <ClickText text="PIK Scheduler" />
            </Anchor>
            <BaseText text={item?.nextPIKDate ?? ''} />
          </>}
          <BoldText text="Index Rate Option" />
          <BaseText text={allIndexes.find(index => index?.id === item?.indexOption?.id)?.indexOption ?? ''} />

          <BoldText text="Status" />
          <PillStatus w="fit-content" />

          <BoldText text="Interest Base Rate" />
          <BaseText text={item?.interestBaseRate ?? ''} />

          <BoldText text="Interest Base Rate with Rounding" />
          <BaseText text={item?.interestBaseRateWithRounding ?? ''} />

          <BoldText text="Margin" />
          <BaseText text={item?.margin} />

          <BoldText text="CAS" />
          <BaseText text={item?.casValue ?? 'N/A'} />
          {/* facility?.iroValues?.find(iro => iro?.indexOption?.id === item?.indexOption?.id */}


          <BoldText text={`All-In-Rate ${iro?.iroCap === item.allInRate ? '(Cap)' : iro?.iroFloor === item.allInRate ? '(Floor)' : ''}`} />
          <BaseText text={item?.allInRate ?? ''} />

          <BoldText text="Day Basis" />
          <BaseText text={item?.dayBasis} />

          <BoldText text="Borrower Wire Instructions" />
          <BaseText text={item?.borrowerPaymentInstructions?.id} />

          <BoldText text="Interest Due at Maturity" />
          <BaseText
            text={formatNumberToCurrency(Number(item?.accrualInterestMaturityDate) ?? 0, item?.currency ?? 'USD')}
          />
          <BoldText text="Interest Due at Current Date" />
          <BaseText
            text={formatNumberToCurrency(currentAmountWithInterest?.interest ?? 0, item?.currency ?? 'USD')}
          />

          <BoldText text="Amount Due" />
          <BaseText
            text={formatNumberToCurrency(Number(item?.amountDue) ?? 0, item?.currency ?? 'USD')}
          />
        </SimpleGrid>
      </Accordion.Panel>
    </>
  )
}
