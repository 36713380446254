import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import {
  getIndexRateOptions,
  getIndexRateOption,
  addIndexRateOption,
  editIndexRateOption,
  deleteIndexRateOptions,
  activeIndexRateOption,
  inactiveIndexRateOption,
  startLoadingIndexRateOptions,
  stopLoadingIndexRateOptions,
} from 'app/state/ducks/index-rate-options/actions'
import { IndexRateOptionParams } from 'app/models/index-rate-options-params'

export const saveIndexRateOption =
  (
    savedIndexRateOption: IndexRateOptionParams,
    indexRateOption?: IndexRateOptionParams,
  ) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    const statusLower = savedIndexRateOption.status.toLowerCase() || 'inactive'
    if (indexRateOption) {
      return client
        .put(
          `${apiServerUrl}/api/${statusLower}-index-rate-options`,
          savedIndexRateOption,
        )
        .then(indexRateOption => {
          dispatch(editIndexRateOption(indexRateOption))
          return { payload: indexRateOption.data, success: true }
        })
        .catch(e => {
          return { payload: e.response.data.error, success: false }
        })
    }
    delete savedIndexRateOption.id
    return client
      .post(
        `${apiServerUrl}/api/${statusLower}-index-rate-options`,
        savedIndexRateOption,
      )
      .then(indexRateOption => {
        indexRateOption.data.status = statusLower
        dispatch(addIndexRateOption(indexRateOption))
        return { payload: indexRateOption.data, success: true }
      })
      .catch(e => {
        return { payload: e.response.data.error, success: false }
      })
  }

const loadInactiveIndexRateOptions = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/inactive-index-rate-options`)
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

const loadActiveIndexRateOptions = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/active-index-rate-options`)
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

export const loadIndexRateOptions = () => async (dispatch: Dispatch) => {
  try {
    dispatch(startLoadingIndexRateOptions())
    const [inactiveIndexRateOptions, activeIndexRateOptions] = await Promise.all([
      loadInactiveIndexRateOptions(),
      loadActiveIndexRateOptions(),
    ])  
    const allIndexRateOptions = inactiveIndexRateOptions
      .map((indexRateOption: any) => ({
        ...indexRateOption,
        status: 'Inactive',
      }))
      .concat(
        activeIndexRateOptions.map((indexRateOption: any) => ({
          ...indexRateOption,
          status: 'Active',
        })),
      )
    return dispatch(getIndexRateOptions(allIndexRateOptions))
  } catch (e) {
    console.log(e)
    return []
  } finally {
    dispatch(stopLoadingIndexRateOptions())
  }
}

export const loadOneIndexRateOption =
  (indexRateOptionId: string, status: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    const statusLower = status.toLowerCase()
    return client
      .get(
        `${apiServerUrl}/api/${statusLower}-index-rate-options/one?id=${indexRateOptionId}`,
      )
      .then(indexRateOption => {
        return dispatch(
          getIndexRateOption({ ...indexRateOption.data, status: status }),
        )
      })
      .catch(e => {
        return { payload: e.response.data.error, success: false }
      })
  }

export const removeIndexRateOptions =
  (indexRateOption: any) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .delete(
        `${apiServerUrl}/api/inactive-index-rate-options?ids[]=${indexRateOption}`,
      )
      .then(indexRateOptions => {
        return dispatch(deleteIndexRateOptions(indexRateOptions.data))
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const activateIndexRateOption =
  (indexRateOption: IndexRateOptionParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/inactive-index-rate-options/activate`, {
        id: indexRateOption.id,
      })
      .then(response => {
        dispatch(activeIndexRateOption(response.data))
        return { payload: response.data, success: true }
      })
      .catch(e => {
        return { payload: e.response.data.error, success: false }
      })
  }

export const deactivateIndexRateOption =
  (indexRateOption: IndexRateOptionParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/active-index-rate-options/deactivate`, {
        id: indexRateOption.id,
      })
      .then(response => {
        dispatch(inactiveIndexRateOption(response.data))
        return { payload: response.data, success: true }
      })
      .catch(e => {
        return { payload: e.response.data.error, success: false }
      })
  }
