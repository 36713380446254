import { useAuth0 } from '@auth0/auth0-react'
import React, { useState } from 'react'
import { CodeSnippet } from 'app/views/components/page-specific/code-snippet'
import { PageLayout } from 'app/views/components/layout/page-layout'
import { Button, Drawer, Group, Text } from '@mantine/core'
import { DatePicker } from '@mantine/dates'

export default function ProfilePage() {
  const { user } = useAuth0()
  const [opened, setOpened] = useState(false)

  if (!user) {
    return null
  }

  return (
    <PageLayout>
      <Drawer
        position="right"
        opened={opened}
        onClose={() => setOpened(false)}
        title="Register"
        padding="xl"
        size="xl"
      >
        <Text fz="l" c="blue" fw={700}>
          Howdy
        </Text>
        <DatePicker />
      </Drawer>
      <div className="content-layout">
        <div className="content__body">
          <p id="page-description">
            <span>
              You can use the <strong>ID Token</strong> to get the profile
              information of an authenticated user.
            </span>

            <Group position="center">
              <Button onClick={() => setOpened(true)} className="bg-blue-500">
                Open Drawer
              </Button>
            </Group>
            <span>
              <strong>Only authenticated users can access this page.</strong>
            </span>
          </p>
          <div className="profile-grid">
            <div className="profile__header">
              <img
                src={user.picture}
                alt="Profile"
                className="profile__avatar"
              />
              <div className="profile__headline">
                <h2 className="profile__title">{user.name}</h2>
                <span className="profile__description">{user.email}</span>
              </div>
            </div>
            <div className="profile__details">
              <CodeSnippet
                title="Decoded ID Token"
                code={JSON.stringify(user, null, 2)}
              />
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}
