import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import {
  getTodos,
  getTodo,
  addTodo,
  editTodo,
  deleteTodos,
  startLoadingTodos,
  stopLoadingTodos,
} from './actions'
import { ToDoParams } from 'app/models/todo-params'

export const saveTodo =
  (savedTodo: ToDoParams, todo?: ToDoParams) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    if (todo) {
      savedTodo.id = todo.id
      return client
        .put(`${apiServerUrl}/api/todo-lists`, savedTodo)
        .then(todo => {
          dispatch(editTodo(todo))
          return { success: true, payload: todo.data}
        })
        .catch(error => {
          return { success: false, payload: error.response.data.error }
        })
    }
    delete savedTodo.id
    return client
      .post(`${apiServerUrl}/api/todo-lists`, savedTodo)
      .then(todo => {
        dispatch(addTodo(todo))
        return { success: true, payload: todo.data}
      })
      .catch(error => {
        return { success: false, payload: error.response.data.error }
      })
  }

export const loadTodos = () => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  dispatch(startLoadingTodos())
  return await client
    .get(`${apiServerUrl}/api/todo-lists`)
    .then(response => {
        const todos = response.data
        const allTodos = todos.map((todo: ToDoParams) => ({ ...todo}))
        return dispatch(getTodos(allTodos))
    }).catch(e => {
      console.log(e)
      return []
    }).finally(() => {
      dispatch(stopLoadingTodos())
    })
}

export const loadOneTodo =
  (todoId: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .get(`${apiServerUrl}/api/todo-lists/one?id=${todoId}`)
      .then(todo => {
        return dispatch(getTodo({ ...todo.data}))
      }).catch(e => {
        return {success: false, payload: e.response.data.error}
      })
  }

export const changeTodoStatus = (todo: ToDoParams, status: string) => async (dispatch: Dispatch) => {
    todo.status = status
    const apiServerUrl = config.backendUrl
    return client
      .put(`${apiServerUrl}/api/todo-lists`, todo)
      .then(todo => {
        dispatch(editTodo(todo))
        return { success: true, payload: todo.data}
      })
      .catch(error => {
        return { success: false, payload: error.response.data.error }
      })
  }

export const removeTodos = (todo: string) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .delete(`${apiServerUrl}/api/todo-lists?ids[]=${todo}`)
    .then(todos => {
      dispatch(deleteTodos(todos.data))
      return {success: true, payload: todos.data}
    }).catch(e => {
      return {success: false, payload: e.response.data.error}
    })
}