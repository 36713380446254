import { Anchor, Avatar, Group, Stack, Text } from '@mantine/core'
import { ContactParams } from 'app/models/contact-params'
import { EntityParams, entityTypeWithoutFundManager } from 'app/models/entity-params'
import { PaymentInstructionParams } from 'app/models/payment-instructions-params'
import cleanUUID from 'app/views/components/functions/cleanUUID'
import {
  EntityProfileOptions,
  EntityTypeOptions,
  kycStatusOptions,
} from 'app/models/dropdown-options'

export function getEntityOverviewData(entity?: EntityParams) {
  if (!entity) {
    return []
  }
  const isFundManager = !entityTypeWithoutFundManager.includes(entity?.entityType);

  return [
    {
      label: 'Entity Type',
      value:
        EntityTypeOptions.find(option => option.value === entity.entityType)
          ?.label || '',
    },
    {
      label: 'Entity Profile',
      value: entity.entityProfile
        .map(
          profile =>
            EntityProfileOptions.find(option => option.value === profile)
              ?.label || ''
        )
        .filter(Boolean)
        .join(', '),
    },
    {
      label: 'MEI',
      value: entity.mei,
    },
    {
      label: 'Address',
      value: entity.address,
    },
    {
      label: 'Address 2',
      value: entity.address2,
    },
    {
      label: 'City',
      value: entity.city,
    },
    {
      label: 'State',
      value: entity.state,
    },
    {
      label: 'ZIP/Postal Code',
      value: entity.zipCode,
    },
    {
      label: 'Country/Region',
      value: entity.country,
    },
    {
      label: isFundManager
        ? 'Fund Manager'
        : 'Parent',
      value: isFundManager
        ? entity.fundManager
        : entity.parent
    },
    {
      label: isFundManager
        ? 'Fund Manager MEI'
        : 'Parent MEI',
      value: isFundManager
        ? entity.fundManagerMei
        : entity.parentMei
    },
    {
      label: 'GIIN',
      value: entity.giin,
    },
  ]
}

export function getEntityDetailsData(entity: EntityParams) {
  return [
    {
      label: 'NAIC Code',
      value: 'XXXXXXX',
    },
    {
      label: 'CRN #',
      value: entity.crn,
    },
    {
      label: 'Country of Incorporation',
      value: entity.countryOfIncorporation,
    },
    {
      label: 'Country of Tax Residence',
      value: entity.countryOfTaxResidence,
    },
    {
      label: 'EIN',
      value: entity.ein,
    },
    {
      label: 'US Tax Form Type',
      value: entity.usTaxFormType,
    },
    {
      label: 'KYC Status',
      value: kycStatusOptions.find(
        option => option.value === entity?.kycStatus
      )?.label || 'Pending',
    },
    {
      label: 'UK Treaty Passport #',
      value: entity.ukTreatyPassportNumber,
    },
    {
      label: 'UK Treaty Passport Exp Date',
      value: entity.ukTreatyPassportExpiryDate,
    },
  ]
}

export function getContactData(contacts: ContactParams[], entityId: string) {
  return contacts
    ?.filter(c => cleanUUID(c.entity.id) === entityId)
    .map(contact => {
      return {
        label: contact?.contactType,
        value: (
          <Stack>
            <Group noWrap>
              <Avatar radius="xl" color="blue">
                {contact?.name
                  .split(' ')
                  .map(n => n.at(0))
                  .join('')
                  .toUpperCase()}
              </Avatar>
              <Text>{contact?.name}</Text>
            </Group>

            <Anchor>{contact?.email}</Anchor>
            <Text>{contact?.phoneNumber}</Text>
          </Stack>
        ),
      }
    })
}

export function getWireInstructionData(
  wireInstructions: PaymentInstructionParams[],
  entityId: string
) {
  const instructions = wireInstructions.find(
    WI => cleanUUID(WI?.entity?.id) === entityId
  )
  return [
    {
      label: 'Currency',
      value: instructions?.currency,
    },
    {
      label: 'Bank ABA #',
      value: instructions?.bankAba,
    },
    {
      label: 'Acct with Institution Name',
      value: instructions?.accountWithInstitutionName,
    },
    {
      label: 'Beneficiary CUstomer Name',
      value: instructions?.beneficiaryCustomerName,
    },
    {
      label: 'Beneficiary Account #',
      value: instructions?.beneficiaryAccountNumber,
    },
    {
      label: 'Payment Reference',
      value: instructions?.paymentReference,
    },
    {
      label: 'Special Instructions',
      value: instructions?.specialInstructions,
    },
  ]
}
