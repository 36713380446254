import { FacilityParams } from 'app/models/facility-params'
import { FacilityAmendmentState } from './reducer'

export const getFacilityAmendments = (state: {
  facilityAmendments: { facilityAmendments: FacilityParams[] }
}) => state.facilityAmendments.facilityAmendments

export const getFacilityAmendment = (state: {
  facilityAmendments: { facilityAmendment: FacilityParams }
}) => {
  return state.facilityAmendments.facilityAmendment
}

export const getFacilityAmendmentLoader = (state: { facilityAmendments: FacilityAmendmentState }) => {
  return state.facilityAmendments.isLoading
}
