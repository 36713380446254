import React from 'react'
import MyWorkHeader from './mywork-header'
import { PageLayout } from 'app/views/components/layout/page-layout'
import { MyWorkDrillPage } from './mywork-drill-page'


export function MyWorkLandingPage(tab: { tab: string }) {
  return (
    <PageLayout>
      <MyWorkHeader />
      <MyWorkDrillPage tab={tab.tab} />
    </PageLayout>
  )
}
