import { MRT_ColumnDef } from 'mantine-react-table'
import { Text } from '@mantine/core'
import { ReactNode } from 'react'

export type ReceiverTableParams = {
    lender: string,
    wireInstruction: any,
    amount: string,
    alert: any,
}

function TableHead({ header }: { header: string }) {
    return (
        <Text
            style={{
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '1.5em',
            }}
        >
            {header}
        </Text>
    )
}

function TableRowText({ text }: { text: ReactNode }) {
    return (
        <Text
            style={{
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '1.5em',
            }}
        >
            {text}
        </Text>
    )
}
export const columnDefs: MRT_ColumnDef<ReceiverTableParams>[] = [
    {
        size: 70,
        accessorKey: 'lender',
        header: 'lender',
        Header: <TableHead header={'Lender'} />,
        Cell: ({ cell }) => {
            return <TableRowText text={cell.getValue() as string} />
        },
    },
    {
        size: 30,
        accessorKey: 'wireInstruction',
        header: 'wireInstruction',
        Header: <TableHead header={'Wire Instruction'} />,
        Cell: ({ renderedCellValue }) => {
            return renderedCellValue
        },
    },
    {
        size: 50,
        accessorKey: 'amount',
        header: 'Amount',
        Header: <TableHead header={'Amount'} />,
        filterVariant: 'range',
        Cell: ({ cell }: any) => {
            return (
                <TableRowText
                    text={cell.getValue()}
                />
            )
        },
    },
    {
        size: 10,
        accessorKey: 'alert',
        header: 'Alert',
        Header: <TableHead header={'Alert'} />,
        filterVariant: 'range',
        Cell: ({ renderedCellValue }: any) => (
            renderedCellValue

        ),
    },
    {
        size: 40,
        accessorKey: 'actions',
        header: 'Actions',
        Header: <TableHead header={'Actions'} />,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue }) => renderedCellValue,
    },
]
