import { DealParams } from 'app/models/deal-params'
import {
  submitForApproval,
  rejectSubmittedDeal,
  approveSubmittedDeal,
  deleteDraftDeal,
} from 'app/state/ducks/deals/thunks'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'
import { Dispatch } from 'redux'

export async function submitDeal(deal: DealParams, dispatch: Dispatch<any>) {
  const response: any = await dispatch(submitForApproval(deal))
  if (response.success === false) {
    ErrorNotification({
      title: 'Submission Failed',
      message: response.payload ?? 'Deal Failed To Submit',
    })
    return false
  }

  SuccessNotification({
    title: 'Successful Submission',
    message: 'Deal has been Submitted for Approval',
  })
  return true
}

export async function rejectDeal(deal: DealParams, dispatch: Dispatch<any>) {
  const response: any = await dispatch(rejectSubmittedDeal(deal))
  if (response.success === false) {
    ErrorNotification({
      title: 'Rejection Failed',
      message: response.payload ?? 'Deal Failed To Reject',
    })
    return false
  }
  SuccessNotification({
    title: 'Successful Rejection',
    message: 'Deal has been moved back to a Draft',
  })

  return true
}

export async function approveDeal(deal: DealParams, dispatch: Dispatch<any>) {
  const response: any = await dispatch(approveSubmittedDeal(deal))
  if (response.success === false) {
    ErrorNotification({
      title: 'Approval Failed',
      message: response.payload ?? 'Deal Failed To Approve',
    })
    return false
  }

  SuccessNotification({
    title: 'Successful Approval',
    message: 'Deal has been Approved',
  })

  return true
}

export async function deleteDeal(deal: DealParams, dispatch: Dispatch<any>) {
  const response: any = await dispatch(deleteDraftDeal([deal.id]))
  if (response.success === false) {
    ErrorNotification({
      title: 'Delete Failed',
      message: response.payload ?? 'Deal Failed To Delete',
    })
  } else {
    SuccessNotification({
      title: 'Successful Deletion',
      message: 'Deal has been Deleted',
    })
  }
}
