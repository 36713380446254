import { useState } from 'react';
import { JobParams } from 'app/models/job-params';
import { Modal, Button, Text } from '@mantine/core';
import JobButton from './job-button';

type Props = {
  row: JobParams;
};

export default function JobManagementRunAction({ row }: Props) {
  const [isModalOpen, setModalOpen] = useState(false);

  const handlePrimaryDropDownClick = () => {
    setModalOpen(true);
  };

  const handleConfirm = () => {
    console.log(row, 'confirmed row');
    setModalOpen(false);
    // Add logic here for what happens after confirmation
  };

  return (
    <div>
      <JobButton img={false} minWidth={75} onClick={handlePrimaryDropDownClick}>Go</JobButton>
      <Modal
        opened={isModalOpen}
        onClose={() => setModalOpen(false)}
        title={<Text style={{ fontSize: '24px', fontWeight: 500 }}>Confirmation</Text>}
        centered
      >
        <Text size="md" style={{ marginBottom: '20px' }}>
          Are you sure you want to run <b>{row.name}</b> now?
        </Text>
        <div style={{ display: 'flex', justifyContent: 'right', gap: '10px' }}>
          <Button variant="default" onClick={() => setModalOpen(false)}>
            No
          </Button>
          <Button
            variant="filled"
            onClick={handleConfirm}
            style={{
              backgroundColor: '#32439A',
              color: '#fff',
            }}
          >
            Yes
          </Button>
        </div>
      </Modal>
    </div>
  );
}