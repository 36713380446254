import { LenderListParam } from './lender-list-table'
import { Flex, Text } from '@mantine/core'

interface Props {
  data: LenderListParam[]
}
export function OneFundManager({ data }: Props) {
  return (
    <>
      {data.map((row, index) => (
        <tr key={index}>
          {index == 0 ? (
            <td>
              <Text>{row.fundManager !== '' ? row.fundManager : '-'}</Text>
            </td>
          ) : (
            <td></td>
          )}
          <td>
            <Text>{row.lenderName !== '' ? row.lenderName : '-'}</Text>
          </td>
          <td>
            <Text>{row.mei !== '' ? row.mei : '-'}</Text>
          </td>
          <td>
            <Text>{row.adminEmail !== '' ? row.adminEmail : '-'}</Text>
          </td>
          <td>
            <Text>{row.creditEmail !== '' ? row.creditEmail : '-'}</Text>
          </td>
          <td>
            <Flex direction="column">
              {row.wireInstructions.map(wireInstruction => (
                <Text key={wireInstruction?.id ?? ''}>
                  {wireInstruction?.currency}
                </Text>
              ))}
            </Flex>
          </td>
          <td>
            <Flex direction="column">
              {row.wireInstructions.map(wireInstruction => (
                <Text key={wireInstruction?.id ?? ''}>
                  {wireInstruction?.accountWithInstitutionName !== ''
                    ? wireInstruction?.accountWithInstitutionName
                    : '-'}
                </Text>
              ))}
            </Flex>
          </td>
          <td>
            <Flex direction="column">
              {row.wireInstructions.map(wireInstruction => (
                <Text key={wireInstruction?.id ?? ''}>
                  {wireInstruction?.accountWithInstitutionBankAba !== ''
                    ? wireInstruction?.accountWithInstitutionBankAba
                    : '-'}
                </Text>
              ))}
            </Flex>
          </td>
          <td>
            <Flex direction="column">
              {row.wireInstructions.map(wireInstruction => (
                <Text key={wireInstruction?.id ?? ''}>
                  {wireInstruction?.paymentInstructionType == 'Intermediary'
                    ? wireInstruction?.intermediaryBank?.bankName !== ''
                      ? wireInstruction?.intermediaryBank?.bankName
                      : '-'
                    : wireInstruction?.correspondentBank?.bankName !== ''
                      ? wireInstruction?.correspondentBank?.bankName
                      : '-'}
                </Text>
              ))}
            </Flex>
          </td>
          <td>
            <Text>{row.address == '' ? row.address : '-'}</Text>
          </td>
          <td>
            <Text>{'$' + row.amount}</Text>
          </td>
        </tr>
      ))}
      <tr style={{ backgroundColor: 'rgb(128 128 128 / 42%)' }}>
        <td>
          <Text>{data[0].fundManager + ' Total'}</Text>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>
          <Text>
            {'$' +
              data
                .map(data => data.amount)
                .reduce((sum, amount) => sum + amount, 0)}
          </Text>
        </td>
      </tr>
    </>
  )
}
