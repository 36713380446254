import { FeePaymentState } from "./reducer"

export const getFeePayments = (state: { 
  feePayments: FeePaymentState
}) => state.feePayments.feePayment

export const getFeePaymentsLoader = (state: {
  feePayments: { isLoading: boolean }
}) => {
  return state.feePayments.isLoading
}
