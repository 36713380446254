import { DocumentState } from './reducer'
import { ContractType, DocumentStateTypes } from 'app/models/common-types'

export const getDocuments = (state: { documents: DocumentState }) =>
  state.documents


export const getDocumentsForType = (type: ContractType) => (state: { documents: DocumentState }) => {
  const documentStateType = DocumentStateTypes[type]
  return state.documents[documentStateType]
}