import * as types from './types'
import { createReducer } from 'app/state/utils'

export interface LoanConversionsState {
  loanConversions: any[]
  isLoading: boolean
}

const initialState: LoanConversionsState = { loanConversions: [], isLoading: false }

const loanConversionsReducer = createReducer(initialState)({
  [types.GET_LOAN_CONVERSIONS]: (state: any, payload: any) => {
    return { ...state, loanConversions: payload.payload }
  },

  [types.EDIT_LOAN_CONVERSION]: (state: any, payload: any) => {
    const index = state.loanConversions.findIndex(
      (loanConversion: { id: any }) => loanConversion.id === payload.payload.data.id
    )
    const newArray = [...state.loanConversions]
    newArray[index] = { ...payload.payload.data, status: 'Draft' }
    return {
      ...state,
      loanConversions: newArray,
    }
  },

  [types.ADD_LOAN_CONVERSION]: (state: any, payload: any) => {
    return {
      ...state,
      loanConversions: [
        { ...payload.payload.data, status: 'Draft' },
        ...state.loanConversions,
      ],
    }
  },

  [types.SUBMIT_LOAN_CONVERSION]: (state: any, payload: any) => {
    const obj = state.loanConversions.find(
      (loanConversion: { id: any }) => loanConversion.id == payload.payload.id
    )
    obj.status = 'Submitted'
    return {
      ...state,
      loanConversions: [...state.loanConversions],
    }
  },

  [types.APPROVE_LOAN_CONVERSION]: (state: any, payload: any) => {
    const index = state.loanConversions.findIndex(
      (loanConversion: { id: any }) => loanConversion.id == payload.payload.id
    )
    if (index >= 0) {
      state.loanConversions.splice(index, 1)
    }
    return {
      ...state,
      loanConversions: [...state.loanConversions],
    }
  },

  [types.REJECT_LOAN_CONVERSION]: (state: any, payload: any) => {
    const obj = state.loanConversions.find(
      (loanConversion: { id: any }) => loanConversion.id == payload.payload.id
    )
    obj.status = 'Draft'
    return {
      ...state,
      loanConversions: [...state.loanConversions],
    }
  },

  [types.DELETE_LOAN_CONVERSION]: (state: any, payload: any) => {
    const index = state.loanConversions.findIndex(
      (loanConversion: { id: any }) => loanConversion.id == payload.payload.id
    )
    if (index >= 0) {
      state.loanConversions.splice(index, 1)
    }
    return {
      ...state,
      loanConversions: [...state.loanConversions],
    }
  },
  [types.START_LOADING_LOAN_CONVERSION]: (state: any) => {
    return { ...state, isLoading: true }
  },
  [types.STOP_LOADING_LOAN_CONVERSION]: (state: any) => {
    return { ...state, isLoading: false }
  },
})

export default loanConversionsReducer
