import React, { useState } from 'react';
import { Checkbox } from '@mantine/core';

interface CheckboxGroupProps {
    options: string[];
    onCheckedChange: (checkedState: Record<string, boolean>) => void;
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({ options, onCheckedChange }) => {
    const [checkedState, setCheckedState] = useState<Record<string, boolean>>(
        options.reduce((acc: Record<string, boolean>, option) => {
            acc[option] = false;
            return acc;
        }, {})
    );

    const handleCheckboxChange = (option: string) => {
        const newState = { ...checkedState, [option]: !checkedState[option] };
        setCheckedState(newState);
        onCheckedChange(newState);
    };

    return (
        <div className="checkbox-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px' }}>
            {options.map((option, index) => (
                <Checkbox
                    key={index}
                    label={option}
                    checked={checkedState[option]}
                    size="lg"
                    onChange={() => handleCheckboxChange(option)}
                    style={{ fontSize: '20px' }}
                />
            ))}
        </div>
    );
};

export default CheckboxGroup;