export const GET_DEALS = 'deals/GET_DEALS'
export const GET_DEAL = 'deals/GET_DEAL'
export const ADD_DEALS = 'deals/ADD_DEALS'
export const EDIT_DEALS = 'deals/EDIT_DEALS'
export const SUBMIT_DEAL = 'deals/SUBMIT_DEAL'
export const REJECT_DEAL = 'deals/REJECT_DEAL'
export const APPROVE_DEAL = 'deals/APPROVE_DEAL'
export const DELETE_DEAL = 'deals/DELETE_DEAL'
export const GET_DEAL_HISTORY = 'deals/GET_HISTORY'
export const GET_LOCKED_DEALS = 'deals/GET_LOCKED_DEALS'
export const START_LOADING_DEALS = 'deals/START_LOADING_DEALS'
export const STOP_LOADING_DEALS = 'deals/STOP_LOADING_DEALS'
export const GET_DEAL_SNAPSHOT = 'deals/GET_DEAL_SNAPSHOT'
