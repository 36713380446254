import { Button, Group, Menu, Modal, Title, Stack } from '@mantine/core'
import IonIcon from '@reacticons/ionicons'
import { PaymentParams, ReceiverParams } from 'app/models/payments-params'
import { rejectPayment } from 'app/state/ducks/payments/thunks'
import { formatNumberToCurrency } from 'app/utils/util-functions'
import PrimaryDropDown from 'app/views/components/DropDown/DropDown'
import { ErrorNotification, SuccessNotification } from 'app/views/components/notifications/notification'
import React from 'react'
import { useDispatch } from 'react-redux'

interface Props {
    lender: ReceiverParams
    closeDrawer: () => void
    payment: PaymentParams
}


export const ReceiversActions = ({ lender, closeDrawer, payment }: Props) => {
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false)
    const [selectedLender, setSelectedLender] = React.useState<ReceiverParams | undefined>()

    function rejectReceiver(l: ReceiverParams): void {
        setOpen(true)
        setSelectedLender(l)
    }


    async function handleProceedClick() {
        if (!selectedLender) return
        const result: any = await dispatch(rejectPayment(selectedLender))
        if (!result.success) {
            ErrorNotification({
                title: 'Reject Payment Failed',
                message: result.payload ?? 'Failed to reject payment',
            })
            return
        }

        SuccessNotification({
            title: 'Successful Rejected Payment',
            message: 'Receiver has been removed from this payment',
        })
        closeDrawer()
    }

    return (
        <>
            <Menu shadow="md" width={200}>
                <Menu.Target>
                    <div style={{ width: 'fit-content' }}>
                        <PrimaryDropDown>Actions</PrimaryDropDown>
                    </div>
                </Menu.Target>
                <Menu.Dropdown>
                    {['Released', 'Queued'].includes(payment.status) &&
                        <Menu.Item
                            onClick={() => rejectReceiver(lender)}
                            icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
                        >
                            Reject
                        </Menu.Item>
                    }
                </Menu.Dropdown>
            </Menu>


            {open && selectedLender ? (<Modal opened={open} onClose={() => setOpen(false)} size="lg">
                <Stack>
                    <Title>Reject {formatNumberToCurrency(Number(selectedLender?.amount ?? 0), payment.payment.currency ?? 'USD')} Payment </Title>
                    <Group position="right">
                        <Button onClick={() => setOpen(false)}>Cancel</Button>
                        <Button
                            style={{ backgroundColor: '#32439A' }}
                            onClick={() => handleProceedClick()}
                        >
                            Proceed
                        </Button>
                    </Group>
                </Stack>
            </Modal>) : null}
        </>
    )
}
