import { LoanConversionParams } from 'app/models/loan-conversion-params'
import { LoanConversionsState } from './reducer'

export const getLoanConversions = (state: {
  loanConversions: { loanConversions: LoanConversionParams[] }
}) => state.loanConversions.loanConversions

export const getLoanConversionsLoader = (state: { loanConversions: LoanConversionsState }) => 
  state.loanConversions.isLoading 


