import { MRT_ColumnDef } from 'mantine-react-table'
import { Avatar, Text, Tooltip } from '@mantine/core'
import { DateInput } from '@mantine/dates'
import { ReactNode } from 'react'
import { DealParams } from 'app/models/deal-params'
import cleanUUID from 'app/views/components/functions/cleanUUID'
import getInitials from 'app/views/components/functions/getInitials'
import { EntityParams } from 'app/models/entity-params'
import DealActions from '../deal-details/deal-actions'
import { formatDateToUTC, formatNumberToCurrency, stringToDate } from 'app/utils/util-functions'

function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}
export function getLockedColumnDefs(
  entities: EntityParams[]
): MRT_ColumnDef<DealParams>[] {
  const entityIdToName = new Map<string, string>()
  if (Array.isArray(entities)) {
    for (const entity of entities) {
      if (entity.id) {
        entityIdToName.set(entity.id, entity.entityName)
      }
    }
  }

  return [
    {
      size: 40,
      accessorKey: 'dealName',
      header: 'Deal Name',
      Header: <TableHead header={'Deal Name'} />,
      Cell: ({ cell }) => (
        <TableRowText text={cell.getValue<string>()} />
      ),
    },
    {
      size: 40,
      accessorKey: 'amendmentDate',
      header: 'Termination Date',
      Header: <TableHead header={'Termination Date'} />,
      Filter: ({ column }) => (
        <DateInput
          placeholder="Filter by Start Date"
          onChange={(newValue: Date) => {
            column.setFilterValue(newValue)
          }}
          value={column.getFilterValue() as Date}
        />
      ),
      Cell: ({ cell }) => (
        <Text fz="1.5em">{formatDateToUTC(cell.getValue<Date>())}</Text>
      ),
    },
    {
      size: 50,
      accessorKey: 'amount',
      header: 'Amount',
      Header: <TableHead header={'Amount'} />,
      filterVariant: 'range',
      Cell: ({ cell, row }: any) => (
        <TableRowText
          text={formatNumberToCurrency(
            cell.getValue(),
            row.original.currency ?? 'USD'
          )}
        />
      ),
    },
    {
      size: 80,
      accessorKey: 'startDate',
      header: 'Start Date',
      Header: <TableHead header={'Start Date'} />,
      accessorFn: row => {
        return stringToDate(row.startDate);
      },
      mantineFilterTextInputProps: {
        placeholder: 'Date',
      },
      filterFn: 'lessThanOrEqualTo',
      filterVariant: 'date',
      sortingFn: 'datetime',
      Cell: ({ cell }) => (
        <Text fz="1.5em">{cell.getValue<Date>()?.toLocaleDateString()}</Text>
      ), //render Date as a string
    },
    {
      accessorKey: 'maturity',
      header: 'Maturity Date',
      Header: <TableHead header={'Maturity Date'} />,
      accessorFn: row => {
        return stringToDate(row.maturity);
      },
      mantineFilterTextInputProps: {
        placeholder: 'Date',
      },
      filterFn: 'lessThanOrEqualTo',
      filterVariant: 'date',
      sortingFn: 'datetime',
      Cell: ({ cell }) => (
        <Text fz="1.5em">{cell.getValue<Date>()?.toLocaleDateString()}</Text>
      ), //render Date as a string
    },
    {
      size: 50,
      accessorFn: row => {
        const entityName = entityIdToName.get(row.borrowers?.at(0)?.id ?? '')
        return entityName;
      },
      accessorKey: 'borrowers',
      header: 'Borrowers',
      Header: <TableHead header={'Primary Borrower'} />,
      Cell: ({ cell }) => {
        const entityName = cell.getValue<string>() ?? '';
        return (
          <Tooltip label={entityName}>
            <Avatar radius="xl" color="cyan" size="lg">
              {getInitials(entityName)}
            </Avatar>
          </Tooltip>
        )
      },
    },
    {
      size: 50,
      accessorFn: row => {
        const entityName = row?.adminEntity?.id
          ? entityIdToName.get(cleanUUID(row.adminEntity.id))
          : null
        return entityName;
      },
      accessorKey: 'adminEntity',
      header: 'Admin',
      Header: <TableHead header={'Admin'} />,
      Cell: ({ cell }) => {
        const entityName = cell.getValue<string>() ?? ''
        return (
          <Tooltip label={entityName}>
            <Avatar radius="xl" color="cyan" size="lg">
              {entityName ? getInitials(entityName) : ''}
            </Avatar>
          </Tooltip>
        )
      },
    },
    {
      size: 40,
      accessorKey: 'id',
      header: 'Actions',
      Header: <TableHead header={'Actions'} />,
      enableColumnActions: false,
      enableColumnFilter: false,
      Cell: cell => <DealActions row={cell.row.original} />,
    },
  ]
}
