import { PageLayout } from 'app/views/components/layout/page-layout'
import ReleaseQueueHeader from './release-queue-header'
import { ReleaseQueueDrillPage } from './release_queue_drill_page'

export function ReleaseQueueLandingPage(tab: { tab: string }) {

  return (
    <PageLayout>
      <ReleaseQueueHeader />
      <ReleaseQueueDrillPage tab={tab.tab} />
    </PageLayout>
  )
}
