import React from 'react'
import { PageLayout } from 'app/views/components/layout/page-layout'
import PricingGridContentPage from './princing-grid-content-page'

export function PricingGridPage() {

  return (
    <PageLayout>
      <PricingGridContentPage />
    </PageLayout>
  )
}
