import { ErrorNotification, SuccessNotification } from "app/views/components/notifications/notification";

export const simulate = (steps: any) => async () => {
  for (let i = 0; i < steps.length; i++) {
    const step = steps[i];
    await new Promise(resolve => setTimeout(resolve, step.delay));
    try{
      console.log('step', step)
      let methodBody = step.body
      if(step.reference !== ''){
        if(step.methodName === 'saveFacility'){
          const previousBody = steps[step.reference].payload
          step.body.dealId = {
            admin: previousBody.accountManagementAdmin,
            id: previousBody.id
          }
        }else{
          methodBody = steps[step.reference].payload
        }
      }
      const response = await step.method(methodBody)
      step.payload = response.payload
      if(response.success){
        SuccessNotification({
          title: 'Successful ' + step.name,
          message: 'Sim ' + i + ' was executed successfully',
        })
      }else{
        ErrorNotification({
          title: 'Failed ' + step.name,
          message: 'Sim ' + i + ' failed'
        })
      }
    }catch(e){
      console.log('sim error', e, step)
      ErrorNotification({
        title: 'Failed ' + step.name,
        message: 'Sim ' + i + ' failed'
      })
    }
  }

}