import CreateNewComponent from 'app/views/components/create-new-modals/modal-new-modal'
import DealsFormWithoutStepper from '../deal-screen-without-stepper'

type Props = {
  closeModal: () => void
  deal?: any
  dealAmendment?: any
  amendment?: string | null
  useStepper?: boolean
}

const DealsForm: React.FC<Props> = ({
  closeModal,
  deal,
  useStepper,
  amendment,
  dealAmendment,
}) => {

  return (
    <form>
      {!useStepper ? (
        <DealsFormWithoutStepper
          deal={deal}
          onClose={closeModal}
          amendment={amendment}
          dealAmendment={dealAmendment}
        />
      ) : (
        <CreateNewComponent onClose={closeModal} />
      )}
    </form>
  )
}

export default DealsForm
