import { useForm } from '@mantine/form'
import { useDispatch, useSelector } from 'react-redux'
import { getFacility } from 'app/state/ducks/facilities/selectors'
import { useEffect, useState } from 'react'
import { LoanParams } from 'app/models/loan-params'
import {
  Group,
  NumberInput,
  Select,
  SelectItem,
  Stack,
  Text,
} from '@mantine/core'
import CustomDatePicker from 'app/views/components/date-picker/date-picker-logic'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import {
  currencyOptions,
  dayBasisOptions,
  rateTypeOptions,
  roundingOptions,
  Option,
} from 'app/models/dropdown-options'
import {
  formatDateToUTC,
  roundTo, stringToDate,
} from 'app/utils/util-functions'
import {
  loadAllLoanRateSettings,
  saveLoanRateSetting,
  submitForApproval,
} from 'app/state/ducks/loan-rate-settings/thunks'
import { getLoanRateSettings } from 'app/state/ducks/loan-rate-settings/selectors'
import { LoanRateSettingParams } from 'app/models/loan-rate-setting-params'
import config from 'app/config/config'
import { loadOneFacility } from 'app/state/ducks/facilities/thunks'
import FormWrapper from 'app/views/components/Form/FormWrapper'
import { ContractPeriod, IroValues } from 'app/models/facility-params'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'
import { getIndexRateOptions } from 'app/state/ducks/index-rate-options/selectors'
import { getInterestRatess } from 'app/state/ducks/interest-rates/selectors'
import { loadInterestByIndex } from 'app/state/ducks/interest-rates/thunks'
import { getInterestForPeriod } from 'app/models/interest-rate'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'

type Props = {
  close: any
  loan: LoanParams
}

export default function RateSettingForm({ close, loan }: Props) {
  const dispatch = useDispatch()

  const facility = useSelector(getFacility)
  const loanRateSetting = useSelector(getLoanRateSettings)
  const allIndexes = useSelector(getIndexRateOptions)
  const dailyRates = useSelector(getInterestRatess)
  const businessDate = useSelector(getBusinessDate)

  const [isLoading, setIsLoading] = useState(false)
  const [facilityContractPeriodOptions, setFacilityContractPeriodOptions] =
    useState<SelectItem[]>([])
  const [facilityindexOptions, setFacilityindexOptions] = useState<Option[]>([])
  const rateSetting = loanRateSetting[0]

  useEffect(() => {
    if (rateSetting) {
      const formRateSetting = {
        ...rateSetting,
        amount: Number(loan.amount),
        allInRate: Number(rateSetting.allInRate),
        interestBaseRate: Number(rateSetting.interestBaseRate),
        margin: Number(rateSetting.margin),
        roundedBaseRate: Number(rateSetting.roundedBaseRate),
      }
      form.setValues(formRateSetting)
    } else {
      const cleanForm = {
        id: undefined,
        loanId: { admin: loan.agencyAdmin, id: loan.id },
        contractPeriod: loan.contractPeriod,
        currency: loan.currency,
        amount: Number(loan.amount),
        startDate: stringToDate(loan.startDate),
        maturity: stringToDate(loan.endDate),
        interestRateOption: loan.indexOption.id,
        rounding: loan.rounding,
        dayBasis: loan.dayBasis,
        interestBaseRate: '',
        roundedBaseRate: '',
        margin: Number(loan.margin),
        allInRate: '',
        status: 'Draft',
      }
      form.setValues(cleanForm)
    }
    if (loan.contractPeriod && dailyRates && dailyRates.length > 0) {
      changeContractPeriod(loan.contractPeriod)
    }
  }, [rateSetting])

  // set interest on edit
  useEffect(() => {
    if (!dailyRates) {
      return
    }
    const iro = facility?.iroValues.find(
      iro => iro.indexOption.id == loan.indexOption.id
    )
    if (iro) {
      calculateInterests(iro, loan.contractPeriod, form.values.startDate)
    }
  }, [dailyRates, facility, loan])

  useEffect(() => {
    if (!allIndexes || allIndexes.length == 0 || !facility) {
      return
    }
    const facilityIndexes = [...new Set(facility?.iroValues.map(iro => { return allIndexes.find(option => option.id === iro.indexOption.id) }))]

    setFacilityindexOptions(
      facilityIndexes.map(indexOption => ({
        value: indexOption?.id ?? '',
        label: indexOption?.indexOption ?? '',
      })) ?? []
    )
    const selectedIndex = allIndexes.find(
      index => index.id == form.values.interestRateOption
    )
    if (selectedIndex) {
      dispatch(loadInterestByIndex(selectedIndex))
    }
  }, [allIndexes, facility])

  useEffect(() => {
    dispatch(loadOneFacility(loan.facilityId.id, 'Approved'))
  }, [])

  useEffect(() => {
    if (!loan) {
      return
    }
    dispatch(loadAllLoanRateSettings(loan?.id))
    const filteredIros = facility?.iroValues.find(
      iro =>
        iro.indexOption.id == form.values.interestRateOption &&
        form.values.currency === iro.currency
    )
    if (!filteredIros) {
      return
    }
    const contractPeriods = filteredIros.contractPeriod.map(
      cp => cp.contractPeriod
    )
    const filteredContractPeriodOptions = rateTypeOptions.filter(option =>
      contractPeriods.includes(option.value)
    )
    setFacilityContractPeriodOptions(filteredContractPeriodOptions)
  }, [facility, loan])

  useEffect(() => {
    if (!dailyRates || dailyRates.length < 1 || !loan) {
      return
    }

    if (loan.contractPeriod) {
      changeContractPeriod(loan.contractPeriod)
    }
  }, [dailyRates, loan])

  const form: any = useForm({
    initialValues: {
      id: rateSetting?.id ?? undefined,
      loanId: { admin: loan.agencyAdmin, id: loan.id },
      contractPeriod: loan?.contractPeriod,
      currency: loan.currency,
      amount: Number(loan?.amount),
      startDate: stringToDate(loan?.startDate),
      maturity: stringToDate(loan?.endDate),
      interestRateOption: loan?.indexOption.id,
      rounding: loan?.rounding,
      dayBasis: loan?.dayBasis,
      interestBaseRate:
        Number(rateSetting?.interestBaseRate) ?? Number(loan?.interestBaseRate),
      roundedBaseRate:
        Number(rateSetting?.roundedBaseRate) ??
        Number(loan?.interestBaseRateWithRounding),
      margin: Number(rateSetting?.margin) ?? Number(loan?.margin),
      allInRate: Number(rateSetting?.allInRate) ?? Number(loan?.allInRate),
      status: rateSetting?.status ?? 'Draft',
      hasError: false,
      ErrorMessage: '',
      approveRate: false,
      hasSuccessfulEntitySave: false,
      customer_token: '',
    },

    transformValues: values => {
      return {
        ...values,
        facilityId: {
          id: facility?.id,
          admin: facility?.accountManagementAdmin,
        },
      }
    },

    validate: {
      amount: (value: number) => (value > 0 ? null : 'Invalid amount'),
      status: (value: string) =>
        value === 'Draft' ? null : 'Has already been submitted',
      contractPeriod: (value: string) =>
        value === null ? 'This needs something' : null,
      maturity: (value: any) =>
        value > form.values.startDate
          ? null
          : 'Maturity has to be after Start Date',
      startDate: (value: any) =>
        form.values.maturity > value
          ? null
          : 'Maturity has to be after Start Date',
      interestBaseRate: (value: number | null | string) =>
        value != '' ? null : 'interestBaseRate needs to be available',
      margin: (value: number | null | string) =>
        value != '' ? null : 'margin needs to be available',
      allInRate: (value: number | null | string) =>
        value != '' ? null : 'allInRate needs to be available',
      roundedBaseRate: (value: number | null | string) =>
        value != '' ? null : 'roundedBaseRate needs to be available',
    },
  })

  const resetInterest = () => {
    form.setFieldValue('interestBaseRate', '')
    form.setFieldValue('roundedBaseRate', '')
    form.setFieldValue('allInRate', '')
    form.clearFieldError('contractPeriod')
    form.clearFieldError('startDate')
  }

  const changeIndexOption = (event: string | null) => {
    if (!event) {
      return
    }

    const selectedIndex = allIndexes.find(index => index.indexOption == event)
    if (selectedIndex) {
      dispatch(loadInterestByIndex(selectedIndex))
    }

    form.setFieldValue('interestRateOption', event)
    form.setFieldValue('casValue', 0)
    form.setFieldValue('contractPeriod', '')

    const filteredIros = facility?.iroValues.find(
      iro => iro.indexOption.id == event && form.values.currency === iro.currency
    )
    if (!filteredIros) {
      form.setFieldValue('rounding', '')
      form.setFieldValue('margin', 0)
      form.setFieldValue('dayBasis', '')
      resetInterest()
      return
    }
    form.setFieldValue('rounding', filteredIros.rounding)
    form.setFieldValue('margin', Number(filteredIros.margin))
    form.setFieldValue('dayBasis', filteredIros.dayBasis)

    const contractPeriods = filteredIros.contractPeriod.map(
      cp => cp.contractPeriod
    )
    const filteredContractPeriodOptions = rateTypeOptions.filter(option =>
      contractPeriods.includes(option.value)
    )
    setFacilityContractPeriodOptions(filteredContractPeriodOptions)

    resetInterest()
  }

  const changeContractPeriod = (event: string | null) => {
    if (!event) {
      return
    }
    form.setFieldValue('contractPeriod', event)
    const iro = facility?.iroValues.find(
      iro =>
        iro.indexOption.id == form.values.interestRateOption &&
        form.values.currency === iro.currency
    )
    if (!iro) {
      return
    }

    const contractWitCass = iro.contractPeriod.find(
      cp => cp.contractPeriod == event
    )
    if (!contractWitCass) {
      return
    }

    if (!iro) {
      form.setFieldError(
        'contractPeriod',
        'Not found Index rate option for given contract period'
      )
      return
    }
    form.setFieldValue('casValue', Number(contractWitCass.cas))
    calculateInterests(iro, contractWitCass, form.values.startDate)
  }

  const calculateInterests = (
    iro: IroValues,
    contractWitCass: ContractPeriod | undefined,
    date: string
  ) => {
    resetInterest()
    if (!iro || !contractWitCass) {
      return
    }
    const startDate = date
    const iroSelected = dailyRates.find(
      index => index.effectiveDate === startDate
    )

    if (!iroSelected) {
      form.setFieldError(
        'startDate',
        'Not found Index rate option for given start date'
      )
      return
    }
    const interestBaseRate = iroSelected.overnightRate
      ? iroSelected.overnightRate
      : iroSelected.floatingRate
        ? iroSelected.floatingRate
        : iroSelected.termRates
          ? getInterestForPeriod(
            iroSelected.termRates,
            contractWitCass.contractPeriod
          )
          : 0.0
    if (!interestBaseRate) {
      form.setFieldError(
        'contractPeriod',
        'Not found interest for given contract period'
      )
      form.setFieldError(
        'startDate',
        'Not found Index rate option for given start date'
      )
      return
    }
    const interestWithRounding = Number(
      roundTo(Number(interestBaseRate), iro.rounding)
    )
    form.setFieldValue('interestBaseRate', Number(interestBaseRate))
    form.setFieldValue('roundedBaseRate', interestWithRounding)
    form.setFieldValue(
      'allInRate',
      interestWithRounding + Number(iro.margin) + Number(contractWitCass.cas)
    )
  }

  const createDraftRateSetting = async () => {
    try {
      const loanRateSettingVals: LoanRateSettingParams = {
        ...form.values,
        maturity: formatDateToUTC(form.values.maturity),
        startDate: formatDateToUTC(form.values.startDate),
        hasError: false,
        hasSuccessfulEntitySave: false,
        ErrorMessage: '',
        customer_token: config.company.toUpperCase(),
      }
      const response: any = await dispatch(
        saveLoanRateSetting(loanRateSettingVals, rateSetting)
      )
      if (response.success) {
        SuccessNotification({
          title: 'Successfully Saved Draft Loan Rate Setting',
          message: 'You created or edited a Loan Rate Setting',
        })
        return response
      } else {
        ErrorNotification({
          title: 'Failed to Create Rate Setting',
          message: response.payload ?? 'Please check inputs',
        })
        return null
      }
    } catch (error: any) {
      const data: any = error.response.data
      ErrorNotification({
        title: 'Failed to Create Rate Setting',
        message: data?.error ?? 'Please check inputs',
      })
    }
  }

  const onSubmit = async () => {
    setIsLoading(true)
    const result = await createDraftRateSetting()
    setIsLoading(false)
    if (result) {
      close()
    }
  }

  const saveAndSubmit = async () => {
    setIsLoading(true)
    const response: any = await createDraftRateSetting()
    try {
      if (!response) {
        return
      }
      const result: any = await dispatch(
        submitForApproval(response.payload.data)
      )
      if (result.success) {
        SuccessNotification({
          title: 'Successful Rate Settings Submitted',
          message: 'loan rate settings subbmitted succesfuly',
        })
      } else {
        ErrorNotification({
          title: 'Rate Settings created but not submitted.',
          message: result.payload ?? 'Loan rate setting not submitted.',
        })
      }
    } finally {
      close()
      setIsLoading(false)
    }
  }

  return (
    <FormWrapper title={(rateSetting ? 'Edit' : 'Create') + ' Rates'}>
      <div className="content">
        <form onSubmit={form.onSubmit(() => onSubmit())}>
          <div className="create-new">
            <Stack spacing="xl">
              {rateSetting && rateSetting?.status !== 'Draft' ? (
                <Text className="topFormErrorText">
                  Rates have already been submitted
                </Text>
              ) : null}
              <Group noWrap>
                <Select
                  searchable
                  readOnly
                  withAsterisk
                  label="Index Rate"
                  placeholder="Select Index Rate"
                  w="100%"
                  data={[...facilityindexOptions]}
                  {...form.getInputProps('interestRateOption')}
                  onChange={e => {
                    changeIndexOption(e)
                  }}
                />
                <Select
                  searchable
                  withAsterisk
                  readOnly
                  data={currencyOptions}
                  label="Select Currency"
                  placeholder="Currency"
                  w="100%"
                  {...form.getInputProps('currency')}
                />
                <NumberInput
                  withAsterisk
                  readOnly
                  w="100%"
                  label="Amount"
                  id="amount"
                  placeholder="Enter Amount"
                  parser={value =>
                    value ? value.replace(/\$\s?|(,*)/g, '') : ''
                  }
                  formatter={value =>
                    !Number.isNaN(parseFloat(value ?? ''))
                      ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      : ''
                  }
                  {...form.getInputProps('amount')}
                />
              </Group>
              <Stack>
                <Group noWrap>
                  <Select
                    searchable
                    withAsterisk
                    readOnly
                    label="Contract Period"
                    placeholder="Select Contract Period"
                    w="100%"
                    data={facilityContractPeriodOptions}
                    {...form.getInputProps('contractPeriod')}
                    onChange={e => {
                      changeContractPeriod(e)
                    }}
                  />
                  <CustomDatePicker
                    required
                    disabled
                    label={'Start Date'}
                    date={
                      form.values.startDate
                        ? form.values.startDate
                        : businessDate
                    }
                    setDate={(value: any) => {
                      form.setFieldValue('startDate', value)
                      calculateInterests(
                        form.values.iro,
                        form.values.contractPeriod,
                        value
                      )
                    }}
                    holidayCalendars={facility?.holidayCalendar ?? []}
                  />

                  <CustomDatePicker
                    required
                    disabled
                    label={'Maturity Date'}
                    date={
                      form.values.maturity
                        ? form.values.maturity
                        : businessDate
                    }
                    setDate={(value: any) =>
                      form.setFieldValue('maturity', value)
                    }
                    holidayCalendars={facility?.holidayCalendar ?? []}
                  />
                </Group>
                {(form.isTouched('startDate') || form.isTouched('maturity')) &&
                  form.values.startDate >=
                  form.values.maturity ? (
                  <div className="topFormErrorText">
                    Start Date Must Be Before Maturity Date
                  </div>
                ) : null}
              </Stack>

              <Group noWrap>
                <Select
                  searchable
                  withAsterisk
                  readOnly
                  data={roundingOptions}
                  label="Interest Rate Rounding"
                  w="100%"
                  {...form.getInputProps('rounding')}
                />
                <Select
                  searchable
                  withAsterisk
                  readOnly
                  data={dayBasisOptions}
                  label="Day Basis"
                  w="100%"
                  {...form.getInputProps('dayBasis')}
                />

                <NumberInput
                  withAsterisk
                  w="100%"
                  precision={5}
                  label="Margin"
                  parser={value =>
                    value ? value.replace(/%\s?|\$\s?|(,*)/g, '') : ''
                  }
                  formatter={value =>
                    !Number.isNaN(parseFloat(value ?? '')) ? value + ' %' : '%'
                  }
                  {...form.getInputProps('margin')}
                />
              </Group>

              <Group noWrap>
                <NumberInput
                  withAsterisk
                  w="100%"
                  precision={5}
                  label="Interest Rate"
                  parser={value =>
                    value ? value.replace(/%\s?|\$\s?|(,*)/g, '') : ''
                  }
                  formatter={value =>
                    !Number.isNaN(parseFloat(value ?? '')) ? value + ' %' : '%'
                  }
                  {...form.getInputProps('interestBaseRate')}
                />
                <NumberInput
                  withAsterisk
                  w="100%"
                  precision={5}
                  label="Interest Rate with Rounding"
                  parser={value =>
                    value ? value.replace(/%\s?|\$\s?|(,*)/g, '') : ''
                  }
                  formatter={value =>
                    !Number.isNaN(parseFloat(value ?? '')) ? value + ' %' : '%'
                  }
                  {...form.getInputProps('roundedBaseRate')}
                />
                <NumberInput
                  withAsterisk
                  w="100%"
                  precision={5}
                  label="All In Rate"
                  parser={value =>
                    value ? value.replace(/%\s?|\$\s?|(,*)/g, '') : ''
                  }
                  formatter={value =>
                    !Number.isNaN(parseFloat(value ?? '')) ? roundTo(Number(value ?? ''), form?.values?.rounding ?? 'NoRounding') + ' %' : '%'
                  }
                  {...form.getInputProps('allInRate')}
                />
              </Group>
              <Group noWrap>
                <PrimaryButton
                  loading={isLoading}
                  type="submit"
                  disabled={!form.isValid()}
                  text="Save"
                  w="100%"
                />
                <PrimaryButton
                  loading={isLoading}
                  onClick={() => saveAndSubmit()}
                  disabled={!form.isValid()}
                  text="Create and Submit"
                  w="100%"
                />
              </Group>
            </Stack>
          </div>
        </form>
      </div>
    </FormWrapper>
  )
}
