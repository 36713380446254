import React from 'react'
import { Box, Grid, Col, Group } from '@mantine/core'
import IonIcon from '@reacticons/ionicons'

interface ActionButtonsProps {
  createAssignment: () => void
  editAssignment: () => void
  deleteAssignment: () => void
  disabled: boolean
  disabledEditDelete: boolean
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  createAssignment,
  editAssignment,
  deleteAssignment,
  disabled,
  disabledEditDelete,
}) => {
  return (
    <Box style={{ marginTop: '15px' }}>
      <Grid>
        <Col span={3}>
          <Group>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IonIcon size='large' name='add-circle-outline' aria-disabled={disabled}
                style={{
                  backgroundColor: disabled ? 'white' : '',
                  borderColor: disabled ? 'white' : '',
                }}
                onClick={createAssignment} />
              <span
                style={{
                  marginLeft: '8px',
                  fontFamily: 'Plus Jakarta Sans',
                  fontWeight: 'bold',
                  fontSize: '1em',
                }}
              >
                Add Assignment
              </span>
            </div>
          </Group>
        </Col>
        <Col span={3}>
          <Group>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IonIcon size='large' name='create-outline' aria-disabled={disabled || disabledEditDelete}
                style={{
                  backgroundColor:
                    disabled || disabledEditDelete ? 'white' : '',
                  borderColor: disabled || disabledEditDelete ? 'white' : '',
                }}
                onClick={editAssignment} />
              <span
                style={{
                  marginLeft: '8px',
                  fontFamily: 'Plus Jakarta Sans',
                  fontWeight: 'bold',
                  fontSize: '1em',
                }}
              >
                Edit Assignment
              </span>
            </div>
          </Group>
        </Col>
        <Col span={3}>
          <Group>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IonIcon size='large' name='trash-outline' aria-disabled={disabled || disabledEditDelete}
                style={{
                  backgroundColor:
                    disabled || disabledEditDelete ? 'white' : '',
                  borderColor: disabled || disabledEditDelete ? 'white' : '',
                }}
                onClick={deleteAssignment} />
              <span
                style={{
                  marginLeft: '8px',
                  fontFamily: 'Plus Jakarta Sans',
                  fontWeight: 'bold',
                  fontSize: '1em',
                }}
              >
                Delete Assignment
              </span>
            </div>
          </Group>
        </Col>
      </Grid>
    </Box>
  )
}

export default ActionButtons
