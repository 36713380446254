import { Badge, BadgeProps } from '@mantine/core'

export enum PillColor {
  ORANGE = '#FB5607',
  PINK = '#E8006E',
  DARK_BLUE = '#32439A',
  LIGHT_BLUE = '#96D2FA',
  PURPLE = '#8338EC',
  RED = '#FF0000',
  GREY = '#9CA3AF',
  GREEN = '#287A26',
}

type PillProps = BadgeProps & {
  color: PillColor
  text: string
}

export default function Pill({ text, color, ...props }: PillProps) {
  return (
    <Badge
      className='pillStyles'
      styles={{
        root: {
          color: color === PillColor.LIGHT_BLUE ? '#32439A' : 'white',
          backgroundColor: color,
        },
      }}
      {...props}
    >
      <div className={text.length > 10 ? 'scrollBox' : ''}>
        <span className={text.length > 10 ? 'hoverScroll' : ''}>{text}</span>
      </div>
    </Badge>
  )
}