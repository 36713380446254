import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFacility } from 'app/state/ducks/facilities/selectors'
import TextInput from 'app/views/components/inputs/TextInput'
import { Group, Stack, Text, Grid, Table, NumberInput } from '@mantine/core'
import { amortizationsStatus, frequencyOptions } from 'app/models/dropdown-options'
import { setOneFacility } from 'app/state/ducks/facilities/thunks'
import { getFacilitySchedulerAmortization } from 'app/state/ducks/amortization/selectors'
import {
  AmortizationScheduleParams,
  SchedulerList
} from 'app/models/amortitation-schedule'
import AmortizationRowActions from './amortization-row-actions'
import { getFacilityAmendments } from 'app/state/ducks/facility-amendments/selectors'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import { formatNumberToCurrency, stringToDate } from 'app/utils/util-functions'

enum Tab {
  current = 'Current',
  history = 'History'
}
interface Props {
  facilityId?: string
  isCalculationInSync?: boolean
  setIsCalculationInSync?: any
  tab: string
}
export default function AmortizationScheduleDetails({
  facilityId,
  isCalculationInSync,
  setIsCalculationInSync,
  tab
}: Props) {
  const dispatch = useDispatch()
  const facilityAmendments = useSelector(getFacilityAmendments)
  const facility = useSelector(getFacility)
  const businessDate = useSelector(getBusinessDate)
  const facilityAmortizationScheduler: AmortizationScheduleParams = useSelector(
    getFacilitySchedulerAmortization
  )

  const [amortizationSchedule, setAmortizationSchedule] =
    useState<AmortizationScheduleParams>()
  const [currentRows, setCurrentRows] = useState<any>([])
  const [historyRows, setHistoryRows] = useState<any>([])

  useEffect(() => {
    if (facilityAmendments && facilityAmendments.length > 0) {
      const amendment = facilityAmendments.find(
        (amendment: any) => amendment.id === facilityId
      )
      if (amendment) {
        dispatch(setOneFacility(amendment))
      }
    }
  }, [facilityAmendments, facilityId])

  useEffect(() => {
    checkAvailablePaymentSum()
  }, [facility, amortizationSchedule])

  useEffect(() => {
    if (facility && facility.amendmentAmortization) {
      setAmortizationSchedule(facility.amendmentAmortization)
    } else {
      setAmortizationSchedule(facilityAmortizationScheduler)
    }
  }, [facilityAmortizationScheduler, facility])

  useEffect(() => {
    setCurrentRows(
      amortizationSchedule?.scheduler
        ?.filter((scheduler: SchedulerList) => scheduler.status !== 'Paid')
        .map((scheduler: SchedulerList, index) => {
          return (
            <tr key={index}>
              <td colSpan={1}>{scheduler?.paymentDate}</td>
              <td colSpan={1}>
                {formatNumberToCurrency((Number(scheduler?.amountDue) ?? 0), (facility?.baseCurrency ?? 'USD'))}
              </td>
              <td colSpan={1}>
                {formatNumberToCurrency((Number(scheduler?.paymentAmount) ?? 0), (facility?.baseCurrency ?? 'USD'))}
              </td>
              <td colSpan={1}>
                {' '}
                {formatNumberToCurrency((Number(scheduler?.balance) ?? 0), (facility?.baseCurrency ?? 'USD'))}
              </td>
              <td colSpan={1}>{amortizationsStatus.find(({ value }) => value == scheduler?.status)?.label ?? ''}</td>
              <td colSpan={1}>
                <AmortizationRowActions
                  row={scheduler}
                  amortization={amortizationSchedule}
                  canBePaid={
                    index === 0 &&
                    (facility?.status == 'Approved' ||
                      (facility?.amendmentType !== undefined &&
                        facility?.status == 'Draft'))
                  }
                  canBePastDue={
                    stringToDate(scheduler.paymentDate) < businessDate &&
                    (facility?.status == 'Approved' ||
                      (facility?.amendmentType !== undefined &&
                        facility?.status == 'Draft'))
                  }
                  facility={facility}
                />
              </td>
            </tr>
          )
        })
    )

    setHistoryRows(
      amortizationSchedule?.scheduler
        ?.filter((scheduler: SchedulerList) => scheduler.status === 'Paid')
        .map((scheduler: SchedulerList, index) => {
          return (
            <tr key={index}>
              <td colSpan={1}>{scheduler?.paymentDate}</td>
              <td colSpan={1}>
                {formatNumberToCurrency((Number(scheduler?.amountDue) ?? 0), (facility?.baseCurrency ?? 'USD'))}
              </td>

              <td colSpan={1}>
                {formatNumberToCurrency((Number(scheduler?.paymentAmount) ?? 0), (facility?.baseCurrency ?? 'USD'))}
              </td>
              <td colSpan={1}>
                {' '}
                {formatNumberToCurrency((Number(scheduler?.balance) ?? 0), (facility?.baseCurrency ?? 'USD'))}
              </td>
            </tr>
          )
        })
    )
  }, [amortizationSchedule])

  const checkAvailablePaymentSum = () => {
    const availablePaymentSum = amortizationSchedule?.scheduler
      ?.filter(scheduler => scheduler?.status !== 'Paid')
      .reduce((sum, scheduler) => sum + Number(scheduler?.paymentAmount), 0)
    setIsCalculationInSync(
      Number(facility?.amount ?? 0) === availablePaymentSum
    )
  }

  return (
    <Stack className="data-header-part">
      <div style={{ fontSize: 'large', fontWeight: '500' }}>
        Amortization Schedule Details
      </div>{' '}
      <>
        <Group noWrap w="100%">
          <Grid grow>
            {/* Frequency */}
            <Grid.Col span={3}>
              <TextInput
                w="100%"
                label="Frequency"
                placeholder="Empty"
                value={
                  frequencyOptions.find(
                    option => option.value === amortizationSchedule?.frequency
                  )?.label ?? ''
                }
                disabled
              />
            </Grid.Col>

            {/* Payment Amount */}
            <Grid.Col span={3}>
              <NumberInput
                w="100%"
                label="Payment Amount"
                placeholder="Empty"
                formatter={(value: any) =>
                  !Number.isNaN(parseFloat(value ?? ''))
                    ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : ''
                }
                value={Number(amortizationSchedule?.amount ?? 0.0)}
                disabled
              />
            </Grid.Col>

            {/* Percentage */}
            <Grid.Col span={3}>
              <NumberInput
                precision={5}
                w="100%"
                label="Percentage"
                placeholder="Empty"
                value={Number(amortizationSchedule?.percentage ?? 0.0)}
                style={{ color: "6B7280 !important" }}
                formatter={value =>
                  !Number.isNaN(parseFloat(value ?? '')) ? value + ' %' : '%'
                }
                disabled
              />
            </Grid.Col>

            {/* First Payment Date */}
            <Grid.Col span={3}>
              <TextInput
                w="100%"
                label="First Payment Date"
                placeholder="Empty"
                value={amortizationSchedule?.firstPaymentDate}
                disabled
              />
            </Grid.Col>
          </Grid>
        </Group>
        <Group noWrap w="100%" style={{ fontSize: 'large', fontWeight: '500' }}>
          {tab}
          {tab === Tab.current && !isCalculationInSync && (
            <Text className="topFormErrorText">
              The schedule is not in sync with facility amount
            </Text>
          )}
        </Group>
      </>
      <div style={{ overflowX: 'auto', width: '100%' }}>
        <Table highlightOnHover fontSize="xl">
          <thead>
            <tr>
              <th>Payment Due Date</th>
              <th>Facility Amount</th>
              <th>Payment Amount</th>
              <th>Balance</th>
              {tab === Tab.history ? null : (
                <>
                  <th>Status</th>
                  <th>Actions</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>{tab === Tab.current ? currentRows : historyRows}</tbody>
        </Table>
      </div>
    </Stack>
  )
}
