import React, { useEffect, useState } from 'react'
import { Group, Modal, Select, Stack, TextInput } from '@mantine/core'
import FormWrapper from 'app/views/components/Form/FormWrapper'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import { useForm } from '@mantine/form'
import { TimeInput } from '@mantine/dates';
import { formatDateToUTC, stringToDate } from 'app/utils/util-functions'
import { ToDoParams } from 'app/models/todo-params'
import CustomDatePicker from 'app/views/components/date-picker/date-picker-logic'
import { todoPriority, todoTaskType } from 'app/models/dropdown-options'
import { saveTodo } from 'app/state/ducks/todos/thunks'
import { ErrorNotification, SuccessNotification } from 'app/views/components/notifications/notification'
import { useDispatch } from 'react-redux'
import moment from 'moment'

type ToDoModalProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
  todo?: ToDoParams | any //remove this any when cleaning up it's for the myworkdata
}

export const ToDoModal: React.FC<ToDoModalProps> = ({
  setOpen,
  open,
  todo,
}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const formVals = {
    dueDate: stringToDate(todo?.dueDate) ?? '',
    timeDue: todo?.timeDue && todo?.timeDue.toString().includes('T') ? todo?.timeDue?.toString()?.split('T')[1]?.slice(0, -4) : '',
    taskType: todo?.taskType ?? '',
    priority: todo?.priority ?? '',
    notes: todo?.notes ?? '',
    status: todo?.status ?? 'NewTask',
  }

  const form: any = useForm({
    initialValues: formVals,
    validate: {
      dueDate: value => (value === null ? 'Required' : null),
      timeDue: value => (value === null ? 'Required' : null),
      taskType: value => (value === '' ? 'Required' : null),
      priority: value => (value === '' ? 'Required' : null),
      notes: value => (value === '' ? 'Required' : null),
    },
    transformValues: values => {
      const [hours, minutes] = values.timeDue.split(':')
      const momentDate = moment(values.dueDate);

      // Set the time (hours and minutes) to the moment date
      momentDate.hours(hours);
      momentDate.minutes(minutes);
      momentDate.seconds(0);  // Set seconds to 0
      momentDate.milliseconds(0);  // Set milliseconds to 0

      // Convert the date to UTC and format it as "YYYY-MM-DDTHH:mm:SS.000"
      const utcString = momentDate.utc().format('YYYY-MM-DDTHH:mm:ss.000Z');

      return {
        ...values,
        dueDate: formatDateToUTC(values.dueDate),
        timeDue: utcString,
      }
    },
    validateInputOnBlur: true,
  })

  useEffect(() => {
    const newVals = formVals
    if (todo) {
      form.setValues(newVals)
    } else {
      form.reset()
    }
  }, [todo, open])

  const submitTodo = async (todo: ToDoParams, oldTodo?: ToDoParams) => {
    setIsLoading(true)
    const response: any = await dispatch(saveTodo(todo, oldTodo))
    if (response?.success) {
      SuccessNotification({
        title: 'Saved To Do',
      })
    } else {
      ErrorNotification({
        title: 'To Do Failed to Save',
        message: response.payload ?? 'Check data and try again',
      })
    }
    setIsLoading(false)
    return response
  }

  async function submitForm(event: { preventDefault: () => void }) {
    event.preventDefault()
    try {
      await submitTodo(form.getTransformedValues(), todo)
      setIsLoading(false)
      form.reset()
      setOpen(false)
    } catch {
      ErrorNotification({
        title: 'To Do Failed to Save',
        message: 'Check data and try again',
      })
      setIsLoading(false)
    }
  }

  return (
    <Modal
      className="modal-body create-new-form fit-content-modal"
      opened={open}
      onClose={() => setOpen(!open)}
      centered={true}
      size="60%"
    >
      <FormWrapper
        title={`${todo ? 'Edit' : 'Add'} To Do`}>
        <div className="content">
          <form onSubmit={submitForm}>
            <div className="create-new" style={{ paddingTop: '15px' }}>
              <Group noWrap w="100%">
                <Stack w="100%">
                  <Group noWrap w="100%">
                    <Select
                      withAsterisk
                      searchable
                      w="100%"
                      label="Task Type"
                      placeholder="Enter Task Type"
                      data={todoTaskType}
                      {...form.getInputProps('taskType')}
                    />
                    <Select
                      withAsterisk
                      searchable
                      w="100%"
                      label="Priority"
                      placeholder="Enter Priority"
                      data={todoPriority}
                      {...form.getInputProps('priority')}
                    />
                  </Group>
                  <Group noWrap w="100%">
                    <CustomDatePicker
                      withAsterisk
                      w={'100%'}
                      label={'Due Date'}
                      date={form.values.dueDate}
                      setDate={(value: any) => form.setFieldValue('dueDate', value)}
                      holidayCalendars={[]}
                    />
                    <TimeInput
                      withAsterisk
                      w={'100%'}
                      radius="lg"
                      label="Time Due"
                      placeholder="Enter Time Due"
                      {...form.getInputProps('timeDue')}
                    />
                  </Group>
                  <Group noWrap w="100%">
                    <TextInput
                      withAsterisk
                      w={'100%'}
                      label="Notes"
                      name="notes"
                      id="todo-notes-ta"
                      placeholder="Write a description here or post an update"
                      {...form.getInputProps('notes')}
                    />
                  </Group>
                  <div
                    style={{
                      display: 'flex',
                      gap: '.5rem',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <PrimaryButton
                      disabled={!form.isValid()}
                      className="form-button"
                      loading={isLoading}
                      type="submit"
                      w="100%"
                    >
                      Save
                    </PrimaryButton>
                  </div>
                </Stack>
              </Group>
            </div>
          </form>
        </div>
      </FormWrapper>
    </Modal >
  )
}
