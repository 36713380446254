import { HoverCard, Text, Stack, Button } from '@mantine/core';
import { EntityParams } from 'app/models/entity-params';
import IonIcon from '@reacticons/ionicons';
import { kycStatusOptions } from 'app/models/dropdown-options'


interface Props {
    entities: EntityParams[];
    lenderId: string;
}

export default function AlertHoverCard({ entities, lenderId }: Props) {
    const entity = entities.find(entity => entity.id === lenderId && entity?.kycStatus !== 'ValidatedStatus');

    if (!entity) {
        return null;
    }

    return (
        <HoverCard width={580} shadow="md" arrowPosition='side' position='left' arrowOffset={10} arrowSize={20} withinPortal withArrow>
            <HoverCard.Target>
                <div>
                    <IonIcon size="large" name="alert-circle-sharp" style={{ color: "red" }} />
                </div>
            </HoverCard.Target>
            <HoverCard.Dropdown >
                <div>
                    <div
                        style={{
                            backgroundColor: '#95A4FD',
                            width: '100%',
                            padding: '10px',
                            color: 'white',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            borderRadius: '8px 8px 0px 0px',
                        }}
                    >
                        Alert
                    </div>
                    <Stack align="flex-start" style={{ padding: '15px', flexDirection: 'column' }}>
                        <Text
                            fz="14px"
                            fw={500}
                            sx={{
                                fontFamily: 'Plus Jakarta Sans',
                                color: '#2D3748',
                            }}
                        >
                            KYC Status needs to be Validated for payment release
                        </Text>
                        <Button
                            variant="filled"
                            color="indigo"
                            style={{
                                marginTop: '10px',
                                backgroundColor: '#32439A',
                                color: 'white',
                                borderRadius: '5px',
                            }}
                        >
                            {kycStatusOptions.find(option => option.value === entity.kycStatus)?.label ?? ''}
                        </Button>
                    </Stack>
                </div>
            </HoverCard.Dropdown>
        </HoverCard>
    );
}