import { Group, Stack } from '@mantine/core'
import { ReactNode } from 'react'

interface Props {
  children?: ReactNode
}

export default function SectionTitle({ children }: Props) {
  return (
    <Stack w="100%">
      <Group noWrap w="100%" className='sectionTitle'>
        {children ?? ''}
      </Group>
    </Stack>
  )

}