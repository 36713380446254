import { Dispatch } from 'redux'
import {
  getNoteByContract,
  editNote,
  addNote,
  setNull,
} from 'app/state/ducks/notes/actions'
import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { ContractType } from 'app/models/common-types'
import { Note } from 'app/models/note-params'

export const saveNote = (note: Note) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  const endpoint = `${apiServerUrl}/api/notes`

  try {
    const response = await client.post(endpoint, note)
      dispatch(addNote(response.data))
      return {success: true, payload: response.data}
    
  } catch (error) {
    console.error('THUNK ERROR', error)
    return {success: false, payload: error}
  }
}


export const readNoteByContract =
async (id: string, type: ContractType) =>  {
    const apiServerUrl = config.backendUrl
    try {
      const data = await client.get(`${apiServerUrl}/api/notes/${type}/${id}`)
      return data.data
    } catch (error) {
      console.log('THUNK ERROR', error)
    }
  }

  export const uploadNote = async (note: Note, oldNote?: Note) => {
    const apiServerUrl = config.backendUrl
    const endpoint = `${apiServerUrl}/api/notes`
  
    try {
      if(oldNote){
        const response = await client.put(`${apiServerUrl}/api/notes`, {
          id: oldNote.id,
          newText: note.text,
        })
        return {success: true, payload: response.data}
      }
      
      const response = await client.post(endpoint, note)
      return {success: true, payload: response.data}

    } catch (error) {
      console.error('THUNK ERROR', error)
      return {success: false, payload: error}
    }
  }


export const loadNoteByContract =
  (id: string, type: ContractType) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    try {
      const data = await client.get(`${apiServerUrl}/api/notes/${type}/${id}`)
      if(!data.data){
        return dispatch(setNull({contractType: type}))
      }
      return dispatch(getNoteByContract(data.data))
    } catch (error) {
      console.log('THUNK ERROR', error)
    }
  }

export const editNoteText =
  (id: string, newText: string | null) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    try {
      const data = await client.put(`${apiServerUrl}/api/notes`, {
        id,
        newText,
      })
      dispatch(editNote(data.data))
      return {success: true, payload: data.data}

    } catch (error) {
      console.log('THUNK ERROR', error)
      return {success: false, payload: error}

    }
  }
