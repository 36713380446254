import React from 'react'
import { lenderEligibilityCriteriaOptions } from 'app/models/dropdown-options'
import { AssignmentRowProps } from '../params'
import { formatNumberToCurrency } from 'app/utils/util-functions'

function AssignmentRow({
  index,
  item,
  selectedRow,
  onRowClick,
  entities,
  currency,
}: AssignmentRowProps) {
  const { from, to, amount, eligibility, id } = item

  // Add logic to get lender details and eligibility name
  const fromLender = getDetails(from, entities)
  const toEntity = getDetails(to, entities)
  const eligibilityName = getEligibilityName(eligibility)

  return (
    <tr
      key={index}
      onClick={() => onRowClick(index)}
      style={{
        cursor: 'pointer',
        backgroundColor: selectedRow === index ? 'lightblue' : 'white',
      }}
    >
      <td>{index + 1}</td>
      <td>{fromLender.name}</td>
      <td>{fromLender.mei}</td>
      <td>{toEntity.name}</td>
      <td>{toEntity.mei}</td>
      <td>{formatCurrency(amount, currency)}</td>
      <td>{id}</td>
      <td>{eligibilityName}</td>
      <td></td>
    </tr>
  )
}

function getDetails(entityId: string, entities: any) {
  const entity = entities?.find(
    (entity: { id: string }) => entity.id === entityId
  )
  const entityName = entity?.entityName || ''
  const entityMei = entity?.mei || ''
  return { name: entityName, mei: entityMei }
}

function getEligibilityName(eligibilityValue: string) {
  return (
    lenderEligibilityCriteriaOptions.find(
      option => option.value === eligibilityValue
    )?.label || ''
  )
}

function formatCurrency(value: number, currency: string) {
  return formatNumberToCurrency(value, currency)
}

export default AssignmentRow
