import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import { setBusinessDate } from './actions'
import { BusinessDateParams } from 'app/models/business-date-params'

let businessDate = new Date()

export const loadBusinessDate  = () => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/business-dates/`)
    .then(response => {
      const lastResponse = response.data[response.data.length - 1]
      businessDate = lastResponse.currentDate
      dispatch(setBusinessDate(lastResponse))
      return {payload: businessDate, success: true, id: lastResponse.id}
    }).catch(error => {
      console.log('Business Date Error. Reverted Back to new Date(): ', error)
      businessDate = new Date()
      return
    })
}

export const nextBusinessDate =
  (businessDateInfo: BusinessDateParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/business-dates/next`, { id: businessDateInfo.id })
      .then(response => {
        const fullResponse: any = response.data
        businessDate = fullResponse.currentDate
        dispatch(setBusinessDate(fullResponse))
        return {payload: businessDate, success: true, id: fullResponse.id}
      }).catch(error => {
        console.log('Business Date Error. Reverted Back to new Date(): ', error)
        businessDate = new Date()
        return
      })
  }

export const getBusinessDateFromState = () => {
  return businessDate
}