import React, { useState, useEffect, useImperativeHandle } from 'react'
import { Divider, NumberInput, TextInput, Stack, Group, Switch } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { isNotEmpty, useForm } from '@mantine/form'
import Select from '../inputs/Select'
import {
  currencyOptions,
  dayBasisOptions,
  rateSettingInstructions,
  rateTypeOptions,
  roundingOptions,
  Option,
  frequencyOptions,
} from 'app/models/dropdown-options'
import { saveFacility } from 'app/state/ducks/facilities/thunks'
import { saveFacilityAmendment } from 'app/state/ducks/facility-amendments/thunks'
import { FacilityParams } from 'app/models/facility-params'
import { getFacilityAmendment } from 'app/state/ducks/facility-amendments/selectors'
import {
  ErrorNotification,
  SuccessNotification,
} from '../notifications/notification'
import { getIndexRateOptions } from 'app/state/ducks/index-rate-options/selectors'
import CustomDatePicker from '../date-picker/date-picker-logic'
import { formatDateToUTC, stringToDate } from 'app/utils/util-functions'
import { IndexType } from 'app/models/dropdown-options'
import FormWrapper from '../Form/FormWrapper'
import IonIcon from '@reacticons/ionicons'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'

type Props = {
  facility: any
  setFacility: any
  amendment?: string | null | undefined
}

export interface ChildManageIroRef {
  handleClick: () => void
}

const getIndexTypeLabel = (value: string | undefined) => {
  const indexType = IndexType.find(option => option.value === value);
  return indexType ? indexType.label : value;
}

const ManageIro: React.ForwardRefRenderFunction<ChildManageIroRef, Props> = (
  { facility, setFacility, amendment },
  ref
) => {
  const facilityAmendment: FacilityParams = useSelector(getFacilityAmendment)
  const allIndexes = useSelector(getIndexRateOptions)
  const businessDate = useSelector(getBusinessDate)
  const dispatch = useDispatch()
  const [iroState, setIroState] = useState([
    {
      selectedOption: '',
      contractPeriodOptions: rateTypeOptions,
    },
  ])

  const handleCapFloorChange = (event: { currentTarget: { checked: boolean | ((prevState: boolean) => boolean) } }, iroIndex: number) => {
    form.setFieldValue(`iroValues.${iroIndex}.capFloorFlag`, event.currentTarget.checked);
    if (!event.currentTarget.checked) {
      form.setFieldValue(`iroValues.${iroIndex}.iroCap`, '');
      form.setFieldValue(`iroValues.${iroIndex}.iroFloor`, '');
    }
  };



  const [indexOptions, setIndexOptions] = useState<Option[]>([])
  // Remove the last item
  const updatedFrequencyOptions = frequencyOptions.slice(0, -1);

  const handleClick = async () => {
    if (form.validate().hasErrors === true) {
      throw Error
    } else {
      let response: any
      if (amendment) {
        response = await dispatch(
          saveFacilityAmendment(
            {
              ...facility,
              amendmentDate: facility.amendmentDate ?? formatDateToUTC(businessDate),
              amendmentAmortization: facility.amendmentAmortization ?? null,
              amendmentFees: facility.amendmentFees ?? [],
              amendmentLenders: facility.amendmentLenders ?? [],
              iroValues: form.getTransformedValues().iroValues,
            },
            facilityAmendment
          )
        )
      } else {
        response = await dispatch(
          saveFacility(
            {
              ...facility,
              iroValues: form.getTransformedValues().iroValues,
            },
            facility.id
          )
        )
      }
      if (response?.success === false) {
        ErrorNotification({
          title: ' Facility Action Failed',
          message:
            response?.payload || 'Check Lender Allocations and try again',
        })
        return
      }
      if (
        Object.keys(response?.payload).length !== 0 ||
        response?.success === true
      ) {
        setFacility({
          ...facility,
          iroValues: form.getTransformedValues().iroValues,
        })
        SuccessNotification({
          title: 'Successful Facility Creation',
          message:
            'You can add Lender Allocations, Interest Rate Options, Fees and Amortization Schedules',
        })
      }
    }
  }

  useImperativeHandle(ref, () => ({
    handleClick,
  }))

  useEffect(() => {
    if (!allIndexes) {
      return
    }
    setIndexOptions(
      allIndexes.map((index: any) => ({
        value: index.id,
        label: index.indexOption,
      }))
    )
  }, [allIndexes])


  const form = useForm({
    initialValues: {
      iroValues:
        facility?.iroValues?.length > 0
          ? facility.iroValues.map((iro: any) => {
            const indexOption = allIndexes.find(
              option => option.id === iro.indexOption.id
            )
            return {
              ...iro,
              indexOption: iro.indexOption.id,
              indexType: getIndexTypeLabel(indexOption?.indexType),
              margin: Number(iro.margin),
              interestPaymentFrequency: iro.interestPaymentFrequency,
              interestFirstPaymentDate: stringToDate(iro.interestFirstPaymentDate),
              capFloorFlag: iro.capFloorFlag,
              iroFloor: Number(iro.iroFloor) ?? null,
              iroCap: Number(iro.iroCap) ?? null,
              contractPeriod: iro.contractPeriod.map((period: any) => ({
                contractPeriod: period.contractPeriod,
                cas: Number(period.cas),
              })),
              fixedRate: Number(iro.fixedRate),
            }
          })
          : [
            {
              indexType: '',
              currency: '',
              margin: '',
              indexOption: '',
              rounding: '',
              dayBasis: '',
              interestPaymentFrequency: null,
              interestFirstPaymentDate: null,
              capFloorFlag: false,
              iroCap: '',
              iroFloor: '',
              contractPeriod: [],
              rateInstruction: '',
              fixedRate: '',
            },
          ],
    },
    validate: {
      iroValues: {
        margin: value => (Number(value) > 0 ? null : 'Invalid margin'),
        indexOption: isNotEmpty('Index cannot be empty'),
        currency: isNotEmpty('Currency cannot be empty'),
        rounding: isNotEmpty('Currency cannot be empty'),
        dayBasis: isNotEmpty('Day Basis cannot be empty'),
        iroCap: (_value, allValues, row) => {
          const currentRow = row.split('.')[1]
          return allValues.iroValues[currentRow].capFloorFlag && allValues.iroValues[currentRow].iroCap < allValues.iroValues[currentRow].iroFloor ? 'Cap must be greater than or equal to Floor' : null
        },
        iroFloor: (_value, allValues, row) => {
          const currentRow = row.split('.')[1]
          return allValues.iroValues[currentRow].capFloorFlag && allValues.iroValues[currentRow].iroCap < allValues.iroValues[currentRow].iroFloor ? 'Floor must be lower than or equal to Cap' : null
        }
      },
    },
    transformValues(values) {
      return {
        iroValues: values.iroValues.map((iro: any) => {
          const indexOption = allIndexes.find(
            option => option.id === iro.indexOption
          )
          const firstInterestPaymentDate = iro.interestFirstPaymentDate ? formatDateToUTC(iro.interestFirstPaymentDate) : null
          const contractPeriod = iro.contractPeriod.length === 1 && iro.contractPeriod[0].contractPeriod === '' ? [] : iro.contractPeriod
          const fixedRate = iro.fixedRate === '' ? null : iro.fixedRate
          return {
            ...iro,
            contractPeriod: contractPeriod,
            interestFirstPaymentDate: firstInterestPaymentDate,
            fixedRate: fixedRate,
            iroCap: iro.iroCap === '' ? null : iro.iroCap,
            iroFloor: iro.iroFloor === '' ? null : iro.iroFloor,
            indexOption: {
              id: indexOption?.id ?? '',
              admin: indexOption?.public ?? '',
            },
            indexType: getIndexTypeLabel(indexOption?.indexType)
          }
        }),
      }
    },
  })

  const contractPeriodFields = (iroIndex: number, cpIndex: number) => {

    const handleRemoveContractPeriod = () => {
      form.values.iroValues[iroIndex].contractPeriod.splice(cpIndex, 1)
      form.setValues({
        ...form.values,
        iroValues: [...form.values.iroValues],
      })
    }

    const handleContractPeriodChange = (newValue: string | null) => {
      form.values.iroValues[iroIndex].contractPeriod[cpIndex].contractPeriod =
        newValue
      form.setValues({
        ...form.values,
        iroValues: [...form.values.iroValues],
      })
    }

    const contractPeriodValue =
      form.values.iroValues[iroIndex].contractPeriod[cpIndex].contractPeriod
    const contractPeriodOptions = iroState[iroIndex]
      ? iroState[iroIndex].contractPeriodOptions
      : rateTypeOptions

    const selectedContractPeriods = form.values.iroValues[iroIndex]?.contractPeriod?.map((period: any) => period.contractPeriod)

    const updatedContractPeriodOptions = contractPeriodOptions.map(option => {
      return {
        ...option,
        disabled:
          selectedContractPeriods.includes(option.value)
      }
    })



    return (
      <Stack w="100%" key={cpIndex}>
        <Group noWrap w="100%">
          <Group noWrap w="100%">
            <Select
              w="100%"
              label="Contract Period"
              placeholder="i.e. Overnight"
              data={updatedContractPeriodOptions}
              withAsterisk
              searchable
              onChange={event => {
                handleContractPeriodChange(event)
              }}
              value={contractPeriodValue}
            />
          </Group>


          {(allIndexes.find(index => index.id === form.values.iroValues[iroIndex].indexOption)?.riskFreeRate === true) && (
            <Group noWrap w="100%">
              <NumberInput
                w="100%"
                withAsterisk
                label="CAS"
                placeholder="i.e. 0.00%"
                defaultValue={0.0}
                precision={5}
                min={0.0}
                step={0.1}
                {...form.getInputProps(
                  `iroValues.${iroIndex}.contractPeriod.${cpIndex}.cas`
                )}
                sx={{
                  label: {
                    fontFamily: 'Plus Jakarta Sans',
                    color: '#111928',
                    fontSize: '1em',
                  },
                  input: {
                    backgroundColor: '#F9FAFB',
                    height: '3em',
                    borderRadius: '0.7em',
                    color: '#6B7280',
                    borderWidth: '1px',
                    borderColor: '#D1D5DB',
                    fontSize: '1em',
                    fontFamily: 'Plus Jakarta Sans',
                  },
                }}
              />
            </Group>
          )}
          {
            cpIndex !== 0 && (
              <Group noWrap w="100%" mt="20px" position='center'>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <IonIcon name="trash-outline" className="click-ion-icon" style={{ width: '20px', height: '20px', color: "red" }}
                    onClick={handleRemoveContractPeriod} />
                  <span
                    style={{
                      fontFamily: 'Plus Jakarta Sans',
                      color: '#111928',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    Remove contact period
                  </span>
                </div>
              </Group>
            )}
        </Group>
      </Stack >
    )
  }

  const initializeContractPeriods = (iroIndex: number) => {
    if (form.values.iroValues[iroIndex].contractPeriod.length === 0) {
      form.values.iroValues[iroIndex].contractPeriod.push({
        contractPeriod: '',
        cas: Number(''),
      })
      form.setValues({
        ...form.values,
        iroValues: [...form.values.iroValues],
      })
    }
  }

  const handleAddContractPeriod = (iroIndex: number) => {
    form.values.iroValues[iroIndex].contractPeriod.push({
      contractPeriod: '',
      cas: Number(''),
    })

    if (iroIndex >= iroState.length) {
      const updatedIroState = [...iroState]
      for (let i = iroState.length; i <= iroIndex; i++) {
        updatedIroState.push({
          selectedOption: '',
          contractPeriodOptions: rateTypeOptions,
        })
      }
      setIroState(updatedIroState)
    }

    form.setValues({
      ...form.values,
      iroValues: [...form.values.iroValues],
    })
  }

  const handleIndexRateOptionChange = (
    selectedOption: string,
    iroIndex: number
  ) => {
    if (selectedOption) {
      const indexRateOption = allIndexes.find(
        option => option.id === selectedOption
      )
      if (!indexRateOption) {
        return
      }
      let contractPeriodOptions = rateTypeOptions
      let selectedContractPeriods =
        form.values.iroValues[iroIndex].contractPeriod
      if (indexRateOption.indexType === 'TermIndex') {
        contractPeriodOptions = rateTypeOptions.filter((option: any) =>
          indexRateOption.contractPeriods.includes(option.value)
        )
        const termsContractPeriods = selectedContractPeriods.filter(
          (option: any) =>
            indexRateOption.contractPeriods.includes(option.contractPeriod)
        )
        selectedContractPeriods =
          termsContractPeriods.length > 0
            ? termsContractPeriods
            : [
              {
                contractPeriod: '',
                cas: Number(''),
              },
            ]
      }

      if (indexRateOption.indexType !== 'TermIndex') {
        selectedContractPeriods = []
      }
      const updatedIroState = [...iroState]
      updatedIroState[iroIndex] = {
        selectedOption: selectedOption,
        contractPeriodOptions,
      }
      setIroState(updatedIroState)

      form.setValues(values => {
        const updatedValues = { ...values }
        updatedValues.iroValues[iroIndex].indexOption = selectedOption
        updatedValues.iroValues[iroIndex].indexType = getIndexTypeLabel(indexRateOption.indexType);
        updatedValues.iroValues[iroIndex].currency = indexRateOption.currency
        updatedValues.iroValues[iroIndex].contractPeriod = selectedContractPeriods
        updatedValues.iroValues[iroIndex].interestPaymentFrequency = indexRateOption.indexType === 'TermIndex' ? null : updatedValues.iroValues[iroIndex].interestPaymentFrequency
        updatedValues.iroValues[iroIndex].interestFirstPaymentDate = indexRateOption.indexType === 'TermIndex' ? null : updatedValues.iroValues[iroIndex].interestFirstPaymentDate
        updatedValues.iroValues[iroIndex].rateInstruction = indexRateOption.indexType === 'FixedIndex' ? '' : updatedValues.iroValues[iroIndex].rateInstruction
        updatedValues.iroValues[iroIndex].fixedRate = indexRateOption.indexType === 'FixedIndex' ? updatedValues.iroValues[iroIndex].fixedRate : ''
        return updatedValues
      })
    } else {
      form.setValues(values => {
        const updatedValues = { ...values }
        updatedValues.iroValues[iroIndex].indexOption = ''
        updatedValues.iroValues[iroIndex].currency = ''
        updatedValues.iroValues[iroIndex].contractPeriod = [{ contractPeriod: '', cas: Number('') }]
        updatedValues.iroValues[iroIndex].rateInstruction = ''
        updatedValues.iroValues[iroIndex].interestPaymentFrequency = null
        updatedValues.iroValues[iroIndex].interestFirstPaymentDate = null

        updatedValues.iroValues[iroIndex].capFloorFlag = false
        updatedValues.iroValues[iroIndex].iroCap = ''
        updatedValues.iroValues[iroIndex].iroFloor = ''

        updatedValues.iroValues[iroIndex].margin = ''
        updatedValues.iroValues[iroIndex].dayBasis = ''
        updatedValues.iroValues[iroIndex].rounding = ''
        updatedValues.iroValues[iroIndex].fixedRate = ''
        return updatedValues
      })
    }
  }


  const fields = form.values.iroValues?.map((iro: any, iroIndex: number) => {
    initializeContractPeriods(iroIndex)

    return (
      <FormWrapper title={''}>
        <Stack w="100%" key={iroIndex}>
          <Group noWrap w="100%">
            <Select
              w="100%"
              label="Index Rate Option"
              placeholder="Select Index Rate Option"
              data={indexOptions.filter(
                i =>
                  form.values.iroValues[iroIndex].indexOption === i.value ||
                  !form.values.iroValues
                    .map((iroValue: any) => iroValue.indexOption)
                    .includes(i.value)
              )}
              withAsterisk
              searchable
              {...form.getInputProps(`iroValues.${iroIndex}.indexOption`)}
              value={
                form.values.iroValues[iroIndex].indexOption ||
                (iroState[iroIndex] ? iroState[iroIndex].selectedOption : '')
              }
              onChange={(selectedOption: string) => {
                handleIndexRateOptionChange(selectedOption, iroIndex)
              }}
            />
            <TextInput
              w="100%"
              readOnly
              label="Index Rate Type"
              placeholder="Type is Auto-Generated"
              {...form.getInputProps(`iroValues.${iroIndex}.indexType`)}
            />
          </Group>
          {allIndexes.find(index => index.id === form.values.iroValues[iroIndex].indexOption)?.indexType !== 'FixedIndex' ?
            <Group noWrap w="100%">
              <Select
                w="100%"
                label="Rate Setting Instructions"
                placeholder="i.e. 1 Same Date"
                data={rateSettingInstructions}
                withAsterisk
                searchable
                {...form.getInputProps(`iroValues.${iroIndex}.rateInstruction`)}
              />
            </Group>
            :
            <Group noWrap w="100%">
              <NumberInput
                w="100%"
                withAsterisk
                label="Fixed Rate"
                placeholder="i.e. 0.00%"
                defaultValue={0.0}
                precision={5}
                min={0.0}
                step={0.1}
                {...form.getInputProps(`iroValues.${iroIndex}.fixedRate`)}
                sx={{
                  label: {
                    fontFamily: 'Plus Jakarta Sans',
                    color: '#111928',
                    fontSize: '1em',
                  },
                  input: {
                    backgroundColor: '#F9FAFB',
                    height: '3em',
                    borderRadius: '0.7em',
                    color: '#6B7280',
                    borderWidth: '1px',
                    borderColor: '#D1D5DB',
                    fontSize: '1em',
                    fontFamily: 'Plus Jakarta Sans',
                  },
                }}
              />
            </Group>}
          <Group noWrap w="100%">
            <Group w="50%">
              <Switch
                label="Cap & Floor (Yes/No)"
                checked={form.values.iroValues[iroIndex].capFloorFlag}
                {...form.getInputProps(`iroValues.${iroIndex}.capFloorFlag`)}
                onChange={value => handleCapFloorChange(value, iroIndex)}
                styles={{
                  label: {
                    fontSize: '1.5em',
                  },
                }}
              />
            </Group>
            <Group noWrap w="100%">
              <NumberInput
                w="100%"
                label="Cap"
                placeholder="Enter Cap"
                withAsterisk
                disabled={!form.values.iroValues[iroIndex].capFloorFlag}
                {...form.getInputProps(`iroValues.${iroIndex}.iroCap`)}
              />
              <NumberInput
                w="100%"
                label="Floor"
                placeholder="Enter Floor"
                withAsterisk
                disabled={!form.values.iroValues[iroIndex].capFloorFlag}
                {...form.getInputProps(`iroValues.${iroIndex}.iroFloor`)}
              />
            </Group>
          </Group>
          <Group noWrap w="100%">
            <Select
              w="100%"
              readOnly
              label="Currency"
              placeholder="Select Currency"
              data={currencyOptions}
              withAsterisk
              searchable
              {...form.getInputProps(`iroValues.${iroIndex}.currency`)}
            />
            <NumberInput
              w="100%"
              withAsterisk
              label="Margin"
              placeholder="i.e. 0.00%"
              defaultValue={0.0}
              precision={5}
              min={0.0}
              step={0.1}
              {...form.getInputProps(`iroValues.${iroIndex}.margin`)}
              sx={{
                label: {
                  fontFamily: 'Plus Jakarta Sans',
                  color: '#111928',
                  fontSize: '1em',
                },
                input: {
                  backgroundColor: '#F9FAFB',
                  height: '3em',
                  borderRadius: '0.7em',
                  color: '#6B7280',
                  borderWidth: '1px',
                  borderColor: '#D1D5DB',
                  fontSize: '1em',
                  fontFamily: 'Plus Jakarta Sans',
                },
              }}
            />
          </Group>
          <Group noWrap w="100%">
            <Select
              searchable
              w="100%"
              label="Rounding"
              placeholder="i.e. Decimal Places"
              data={roundingOptions}
              withAsterisk
              {...form.getInputProps(`iroValues.${iroIndex}.rounding`)}
            />
            <Select
              w="100%"
              label="Day Basis"
              placeholder="i.e. Actual/360"
              data={dayBasisOptions}
              withAsterisk
              searchable
              {...form.getInputProps(`iroValues.${iroIndex}.dayBasis`)}
            />
          </Group>
          {allIndexes.find(index => index.id === form.values.iroValues[iroIndex].indexOption)?.indexType === 'TermIndex' ?
            <>
              <Stack w="100%">
                {iro.contractPeriod.map((_: any, cpIndex: number) =>
                  contractPeriodFields(iroIndex, cpIndex)
                )}
              </Stack>
              <Group noWrap w="100%">
                <Group>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IonIcon name="add-circle-outline" className="click-ion-icon" onClick={() => handleAddContractPeriod(iroIndex)} />
                    <span
                      style={{
                        marginLeft: '8px',
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: 'bold',
                        fontSize: '1em',
                      }}
                    >
                      Add contract period
                    </span>
                  </div>
                </Group>
              </Group> </> : <>
              <Stack>
                <Group noWrap w="100%"><div style={{ fontSize: '1.1vw', marginTop: '10px', marginBottom: '10px' }}>Interest Payment Schedule</div></Group>
              </Stack>
              <Group noWrap w="100%">
                <Select
                  w="100%"
                  label="Frequency"
                  placeholder="Select Frequency"
                  data={updatedFrequencyOptions}
                  searchable
                  {...form.getInputProps(
                    `iroValues.${iroIndex}.interestPaymentFrequency`
                  )}
                />
                <CustomDatePicker
                  w="100%"
                  label={'First Payment Date'}
                  date={
                    form.values.iroValues[iroIndex].interestFirstPaymentDate
                  }
                  setDate={(value: any) =>
                    form.setFieldValue(
                      `iroValues.${iroIndex}.interestFirstPaymentDate`,
                      value
                    )
                  }
                  holidayCalendars={facility?.holidayCalendar ?? []}
                />
              </Group>
            </>}
          {iroIndex !== 0 && (
            <>
              <Group noWrap w="100%" position='center'>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <IonIcon className="click-ion-icon" style={{ width: '20px', height: '20px', color: "red" }} name="trash-outline"
                    onClick={() => form.removeListItem('iroValues', iroIndex)} />
                  <span
                    style={{
                      fontFamily: 'Plus Jakarta Sans',
                      color: '#111928',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    Remove index rate option
                  </span>
                </div>
              </Group>
            </>
          )}
          <Divider my="sm" />
        </Stack>
      </FormWrapper>
    )
  })

  return (
    <Stack>
      <Stack>
        {fields}
      </Stack>
      <Group noWrap w="100%">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IonIcon name="add-circle-outline" className="click-ion-icon"
            onClick={() =>
              form.insertListItem('iroValues', {
                currency: '',
                margin: '',
                capFloorFlag: false,
                iroCap: '',
                iroFloor: '',
                indexOption: '',
                rounding: '',
                dayBasis: '',
                interestPaymentFrequency: null,
                interestFirstPaymentDate: null,
                contractPeriod: [],
              })
            }
          />
          <span
            style={{
              marginLeft: '8px',
              fontFamily: 'Plus Jakarta Sans',
              fontWeight: 'bold',
              fontSize: '1em',
            }}
          >
            Add index rate option
          </span>
        </div>
      </Group>
    </Stack>
  )
}
export default React.forwardRef(ManageIro)
