import { LoanParams } from "app/models/loan-params";
import { Form } from "./interfaces";

export const resetInterest = (form: Form, loan?: LoanParams): void => {
    form.setFieldValue('interestBaseRate', Number(loan?.interestBaseRate) || 0);
    form.setFieldValue('interestBaseRateWithRounding', Number(loan?.interestBaseRateWithRounding) || 0);
    form.setFieldValue(
        'allInRate',Number(loan?.allInRate) || (
        Number(loan?.interestBaseRate ?? form.values.interestBaseRate) +
        Number(form.values.margin))
      )
    form.clearFieldError('contractPeriod');
    form.clearFieldError('startDate');
};