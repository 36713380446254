import { MRT_ColumnDef } from 'mantine-react-table'
import { Text } from '@mantine/core'
import { ReactNode } from 'react'
import JobManagementScheduleAction from './job-management-schedule-action'
import { JobParams } from 'app/models/job-params'
import JobManagementRunAction from './job-management-run-action'
import JobManagementStatusAction from './job-management-status-action'

export type TableData = JobParams & {
  setJobLogs: any
}

function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}

export const manageJobsCols: MRT_ColumnDef<TableData>[] = [
  {
    accessorKey: 'name',
    header: 'Name',
    Header: <TableHead header={'Name'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    accessorKey: 'jobStatus',
    header: 'Job Status',
    Header: <TableHead header={'Job Status'} />,
    Cell: cell => <JobManagementStatusAction row={cell.row.original} />,
  },
  {
    accessorKey: 'schedule',
    header: 'Schedule',
    Header: <TableHead header={'Schedule'} />,
    enableColumnActions: true,
    Cell: cell => <JobManagementScheduleAction row={cell.row.original} />,
  },
  {
    accessorKey: 'id',
    header: 'Job Log',
    Header: <TableHead header={'Job Log'} />,
    Cell: cell => {
      const onClickAction = cell.row.original.setJobLogs
      const value = cell.row.original
      return (
        <Text onClick={() => onClickAction(value)}
          style={{
            color: '#A4CAFE',
            cursor: 'pointer'
          }}
        >
          Logs
        </Text>
      )
    },
  },
  {
    accessorKey: 'Test',
    header: 'Run Now',
    Header: <TableHead header={'Run Now'} />,
    Cell: cell => <JobManagementRunAction row={cell.row.original} />,
  },
  {
    accessorKey: 'description',
    header: 'Description',
    Header: <TableHead header={'Description'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
]
