import { LoanState } from './reducer'

export const getAllLoans = (state: { loans: LoanState }) => state.loans.allLoans

export const getLoansByFacility = (state: { loans: LoanState }) =>
  state.loans.facilityLoans

export const getLoan = (state: { loans: LoanState }) => state.loans.loan

export const getApprovedLoans = (state: { loans: LoanState }) =>
  state.loans.approvedLoans

export const getLoanHistory = (state: { loans: LoanState }) =>
  state.loans.loanHistory

export const getAllApprovedLoans = (state: { loans: LoanState }) =>
  state.loans.approvedLoans

export const getLockedLoansByFacility = (state: { loans: LoanState }) =>
  state.loans.facilityLockedLoans

export const getLoanLoader = (state: { loans: LoanState }) => state.loans.facilityLoans.loading || state.loans.facilityLockedLoans.loading
