import { useState } from 'react'
import { DealsModal } from 'app/views/pages/deals-management/deals/deals-modal'
import PrimaryInput from 'app/views/components/buttons/PrimaryInput'

function CreateDealButton() {
  const [open, setOpen] = useState(false)

  return (
    <>
      <PrimaryInput onClick={() => setOpen(true)} text="Create Deal" />
      <DealsModal open={open} setOpen={setOpen} />
    </>
  )
}

export default CreateDealButton
