import { useAuth0 } from '@auth0/auth0-react';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import {
  AppShell,
  Header,
  MediaQuery,
  Burger,
  useMantineTheme,
  Stack,
} from '@mantine/core';
import { LayoutNavBar } from 'app/views/components/navigation/LayoutNavBar';
import { LayoutHeader } from 'app/views/components/layout/Header/LayoutHeader';

interface PageLayoutProps {
  children?: React.ReactNode;
}

export const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const theme = useMantineTheme();

  // made like this for refresh page with locale storage
  const [opened, setOpened] = useState<boolean>(() => {
    const savedOpened = localStorage.getItem('sidebarOpen');
    return savedOpened ? JSON.parse(savedOpened) : true;
  });

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        if (isAuthenticated) {
          const token = await getAccessTokenSilently();
          Cookies.set('access_token', token, {
            expires: 1,
          });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };

    getAccessToken();
  }, [getAccessTokenSilently, isAuthenticated]);

  // Update localStorage whenever `opened` changes
  useEffect(() => {
    localStorage.setItem('sidebarOpen', JSON.stringify(opened));
  }, [opened]);

  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === 'dark' ? theme.colors.dark[8] : 'white',
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={<LayoutNavBar open={opened} setOpen={setOpened} />}
      header={
        <Header
          height={{ base: 90, md: 90, lg: 90 }}
          p="10px 20px 10px 0px"
          style={{ borderBottom: 'none', zIndex: 200 }}
        >
          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((prev) => !prev)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>
            <LayoutHeader open={opened} />
          </div>
        </Header>
      }
    >
      <Stack className='pageLayoutContent'>
        {children}
      </Stack>
    </AppShell>
  );
};