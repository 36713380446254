import { Stack } from '@mantine/core'
import { LendersPosition } from 'app/models/lenders-position-params'
import { LoanParams } from 'app/models/loan-params'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { ECFDecision } from './ecf-decision'
import MandatoryPaymentSummary from './mandatory-payment-summary'
import InterestPaymentSummary from './interest-payment-summary'
import { StableKey } from 'app/models/common-types'
import { getAmountWithInterest, getPaymentLenderBreakdown } from 'app/state/ducks/principal-payment/thunks'
import { LenderPaymentBreakdown, PrincipalPaymentParams } from 'app/models/principal-payments-params'
import { useForm } from '@mantine/form'
import { formatDateToUTC, stringToDate } from 'app/utils/util-functions'
import config from 'app/config/config'
import { useDispatch, useSelector } from 'react-redux'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import { useListState } from '@mantine/hooks'
import PrepaymentSummary from './prepayment-summary'
import FormWrapper from 'app/views/components/Form/FormWrapper'
import { LenderBreakdown } from './lender-breakdown'
import { createDraftPrincipalPayment, saveAndSubmit } from '../principal-payment-utils'

interface Props {
  payment?: PrincipalPaymentParams
  loan: LoanParams,
  lenderPositions: LendersPosition[],
  setPayment: (payment: PrincipalPaymentParams) => void
}

export interface ChildLenderSummaryRef {
  handleClick: () => void
  handleSubmit: () => void
}

const LenderSummary: React.ForwardRefRenderFunction<ChildLenderSummaryRef, Props> = ({
  payment,
  loan,
  lenderPositions,
  setPayment,
}, ref) => {
  const dispatch = useDispatch()
  const [amountWithInterest, setAmountWithInterest] = useState<{ amount: number, interest: number }>()
  const [lenderBreakdown, lenderBreakdownHandler] = useListState<LenderPaymentBreakdown>([])
  const [isPrecalculated, setIsPrecalculated] = useState(true)
  const businessDate = useSelector(getBusinessDate)
  const [activeLenders, activeLendersHandler] = useListState<LendersPosition>([])

  const form: any = useForm({
    initialValues: {
      id: payment ? payment.id : undefined,
      loanId: { admin: loan.agencyAdmin, id: loan.id },
      interestPaid: payment ? payment.interestPaid.toString() : null,
      acceptedLenders: payment ? payment.acceptedLenders : [],
      acceptedAmount: payment ? payment.acceptedAmount : 0,
      amount: payment ? Number(payment.amount) : 0,
      interestAmount: payment ? Number(payment.interestAmount) : 0,
      paymentDate: payment ? stringToDate(payment.paymentDate) : stringToDate(loan.startDate) > businessDate ? stringToDate(loan.startDate) : businessDate,
      paymentType: payment ? payment.paymentType : null,
      hasError: false,
      ErrorMessage: '',
      customer_token: '',
    },
    validate: {
      amount: (value: number) => (value >= 0 ? null : 'Invalid amount'),
      paymentType: (value: string | null | undefined) =>
        !value ? 'This needs something' : null,
      interestPaid: (value: number | null | string) => (value != null ? null : 'Interest paid is required'),
      acceptedLenders: (value: StableKey[] | null) => (value !== null && value.length >= 0 ? null : 'There must be at lest one accepted lender'),
      paymentDate: (value: Date) => (value != null ? null : 'Payment date is required'),
      interestAmount: (value: number, allVals) => ((value > 0 || allVals.amount > 0) ? null : 'Invalid interest amount'),
    },
    transformValues: (values) => ({
      ...values,
      paymentDate: formatDateToUTC(values.paymentDate),
      interestPaid: values.interestPaid == 'true',
      acceptedLenders: values.acceptedLenders,
      customer_token: config.company.toUpperCase(),
    })
  })

  const handleClick = async () => {
    const result = await createDraftPrincipalPayment(form, dispatch, payment)
    setPayment(result)
  }

  const handleSubmit = async () => {
    await saveAndSubmit(form, dispatch, payment)
  }

  useImperativeHandle(ref, () => ({
    handleClick,
    handleSubmit,
  }))

  useEffect(() => {
    if (!payment) {
      return
    }
    precalculate()
    getLenderBreakdown()
  }, [payment])

  useEffect(() => {
    if (!lenderPositions) {
      return
    }
    activeLendersHandler.setState(lenderPositions.filter(lp => lp.proRatas && lp.proRatas.length > 0 && stringToDate(lp.proRatas[0].effectiveDate) <= form.values.paymentDate))
  }, [lenderPositions])

  function changeAcceptedLenders(lender: LendersPosition, decision: boolean): void {
    const acceptedLenders = form.values.acceptedLenders
    if (acceptedLenders) {
      if (decision) {
        acceptedLenders.push(lender.lender)

      } else {
        const index = acceptedLenders.findIndex((lenderId: StableKey) => lenderId.id === lender.lender.id)
        acceptedLenders.splice(index, 1)
      }
      form.setFieldValue('acceptedLenders', acceptedLenders)
    } else {
      form.setFieldValue('acceptedLenders', [lender])
    }
    form.validate();
    precalculate()
  }

  async function getLenderBreakdown() {
    if (!loan || !payment) return
    const response = await getPaymentLenderBreakdown(loan.id ?? '', payment.id ?? '')
    if (response.success) {
      lenderBreakdownHandler.setState(response.payload)
    }
  }

  async function precalculate() {
    const transformedForm = form.getTransformedValues()
    const response = await getAmountWithInterest({
      ...transformedForm,
      amount: transformedForm.paymentType === 'InterestPayment' ? loan.amount : transformedForm.amount,
      loanId: { id: loan.id, admin: loan.agencyAdmin },
    })

    form.values.interestAmount = response.payload.interest
    form.validate();
    setAmountWithInterest(response.payload)
    setIsPrecalculated(true)
  }

  return (
    <FormWrapper title="Payment Summary">
      <Stack spacing="xl">
        {isPrecalculated ?
          form.values.paymentType == "Mandatory"
            ? <ECFDecision form={form} loan={loan} changeAcceptedLenders={changeAcceptedLenders} activeLenders={activeLenders} lenderBreakdown={lenderBreakdown} />
            : <LenderBreakdown form={form} loan={loan} activeLenders={activeLenders} lenderBreakdown={lenderBreakdown} />
          : null}

        {isPrecalculated &&
          form.values.paymentType == "Mandatory" &&
          <MandatoryPaymentSummary
            totalAmount={form.values.amount}
            acceptedAmount={amountWithInterest?.amount ?? 0}
            interestDue={amountWithInterest?.interest ?? 0}
            totalLender={lenderPositions.length}
            acceptedLender={form.values.acceptedLenders.filter((lp: any) => activeLenders?.some((lender: any) => lender.lender.id === lp.id)).length}
            currency={loan?.currency} />
        }

        {isPrecalculated && form.values.paymentType == "InterestPayment" &&
          <InterestPaymentSummary
            interestDue={amountWithInterest?.interest ?? form.values.interestAmount}
            paymentDate={form.values.paymentDate}
            loan={loan}
            currency={loan?.currency} />
        }
        {isPrecalculated &&
          ["FullPaymentPrincipal", "PrepaymentPrincipal"].includes(form.values.paymentType) &&
          <PrepaymentSummary
            interestDue={amountWithInterest?.interest ?? 0}
            paymentDate={form.values.paymentDate}
            paymentAmount={amountWithInterest?.amount ?? 0}
            loan={loan}
            currency={loan?.currency} />
        }
      </Stack>
    </FormWrapper>

  )
}

export default forwardRef(LenderSummary)

