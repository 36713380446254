import { MRT_ColumnDef } from 'mantine-react-table'
import { Text } from '@mantine/core'
import { ReactNode } from 'react'

export type CleanMandatoryPaymentSummaryParams = {
  'Accepted Amount': string,
  'Total Amount': string,
  'Total Lenders': number,
  'Accepted Lenders': number,
  'Interest Amount': string
}

function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}

export function getColumnDefs(): MRT_ColumnDef<CleanMandatoryPaymentSummaryParams>[] {

  return [
    {
      size: 50,
      accessorKey: 'Total Amount',
      header: 'Total Amount',
      Header: <TableHead header={'Total Amount'} />,
      filterVariant: 'range',
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },
    {
      size: 50,
      accessorKey: 'Accepted Amount',
      header: 'Accepted Amount',
      Header: <TableHead header={'Accepted Amount'} />,
      filterVariant: 'range',
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },
    {
      size: 50,
      accessorKey: 'Interest Amount',
      header: 'Interest Amount',
      Header: <TableHead header={'Interest Amount'} />,
      filterVariant: 'range',
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },


    {
      size: 50,
      accessorKey: 'Total Lenders',
      header: 'Total Lenders',
      Header: <TableHead header={'Total Lenders'} />,
      filterVariant: 'range',
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },

    {
      size: 50,
      accessorKey: 'Accepted Lenders',
      header: 'Accepted Lenders',
      Header: <TableHead header={'Accepted Lenders'} />,
      filterVariant: 'range',
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },
  ]
}