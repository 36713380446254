import { MRT_ColumnDef } from 'mantine-react-table'
import { Text } from '@mantine/core'
import { ReactNode } from 'react'
import { CleanedBorrowersData } from './borrowers-wireinstructions-params'

function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}

export function getColumnDefs(): MRT_ColumnDef<CleanedBorrowersData>[] {

  return [
    {
      size: 50,
      accessorKey: 'Currency',
      header: 'Currency',
      Header: <TableHead header={'Currency'} />,
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },
    {
      size: 50,
      accessorKey: 'Bank Aba#',
      header: 'Bank Aba#',
      Header: <TableHead header={'Bank Aba#'} />,
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },
    {
      size: 50,
      accessorKey: 'AcctInstitutionName',
      header: 'AcctInstitutionName',
      Header: <TableHead header={'Acct Institution Name'} />,
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },
    {
      size: 50,
      accessorKey: 'Customer Name',
      header: 'Customer Name',
      Header: <TableHead header={'Customer Name'} />,
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },
    {
      size: 50,
      accessorKey: 'Acct#',
      header: 'Acct#',
      Header: <TableHead header={'Acct.#'} />,
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },
    {
      size: 50,
      accessorKey: 'Payment Reference',
      header: 'Payment Reference',
      Header: <TableHead header={'Payment Reference'} />,
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },
    {
      size: 50,
      accessorKey: 'Special Instructions',
      header: 'Special Instructions',
      Header: <TableHead header={'Special Instructions'} />,
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },
  ]
}