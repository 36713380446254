import React from 'react'
import { PageLayout } from 'app/views/components/layout/page-layout'
import { DealDrillPage } from './deal-drill-page'


export function DealLandingPage(tab: { tab: string }) {
    return (
        <PageLayout>
            <DealDrillPage tab={tab.tab} />
        </PageLayout>
    )
}