import React from 'react'
import { Modal as SimpleModal } from 'app/views/components/modals/modal-small'

type Props = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  recommendedDate: Date
  reason: string
}

export const DateSuggestionModalConfirmation: React.FC<Props> = ({
  open,
  setOpen,
  recommendedDate,
  reason,
}) => {
  return (
    <SimpleModal.Frame
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <SimpleModal.Head>Holiday Selection Suggestion</SimpleModal.Head>

      <SimpleModal.Body>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            fontSize: '12px',
          }}
        >
          Financial Markets maybe closed being:
          <strong>{reason}</strong> The recommended date is:
          <strong>{recommendedDate.toLocaleDateString()}</strong>
        </div>
      </SimpleModal.Body>
    </SimpleModal.Frame>
  )
}
