import React from 'react'
import { ReactComponent as LOGO } from '../../../styles/LENDOS-Logo.svg'

const Logo: React.FC = () => {
  return (
    <LOGO
      style={{
        height: '70px',
        width: '160px',
        aspectRatio: '2 / 1',
      }}
    />
  )
}

export default Logo
