import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import Cookies from 'js-cookie'
import { UserManager, UserManagerSettings } from 'oidc-client-ts'
import config, { authToken, Config, setTokens } from 'app/config/config'

export enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
}

export interface TokenRequestBody {
  client_id: string
  grant_type: string
  refresh_token?: string
  client_secret?: string
  audience?: string
}

function headers(): Readonly<Record<string, string | boolean>> {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Credentials': true,
    'X-Requested-With': 'XMLHttpRequest',
  }
}

export abstract class Http {
  private instance: Promise<AxiosInstance> | null = null

  public get http(): Promise<AxiosInstance> {
    return this.instance || (this.instance = this.initHttp())
  }

  private async addToken(
    axiosConfig?: AxiosRequestConfig,
  ): Promise<AxiosRequestConfig> {
    return {
      ...axiosConfig,
      headers: {
        ...axiosConfig?.headers,
        Authorization: `Bearer ${Cookies.get('access_token')}`,
      },
    }
  }

  private async retryRequest(config: Config, error: any) {
    const originalRequest = error.config
    originalRequest.headers['Authorization'] = `Bearer ${Cookies.get(
      'access_token',
    )}`

    return (await this.http)(originalRequest).catch(retryError => {
      console.error('Retry failed:', retryError)
    })
  }

  async request<T = any, R = AxiosResponse<T>>(
    config: AxiosRequestConfig,
  ): Promise<R> {
    const configWithToken = await this.addToken(config)
    return axios.request<T, R>(configWithToken)
  }

  async get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    const configWithToken = await this.addToken(config)
    return await axios.get<T, R>(url, configWithToken)
  }

  async post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    const configWithToken = await this.addToken(config)
    return await axios.post<T, R>(url, data, configWithToken)
  }

  async put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    const configWithToken = await this.addToken(config)
    return await axios.put<T, R>(url, data, configWithToken)
  }

  async delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    const configWithToken = await this.addToken(config)
    return axios.delete<T, R>(url, configWithToken)
  }

  private async refreshAccessTokens() {
    const url: string = config.oauth.authority + '/oauth/token'

    const requestBody: TokenRequestBody = {
      client_id: config.oauth.clientId,
      grant_type: 'refresh_token',
      refresh_token: authToken(config)?.refresh_token,
    }

    const requestHeaders = {
      headers: {
        'content-type': 'application/json',
      },
    }

    return await axios.post(url, requestBody, requestHeaders).then(response => {
      setTokens(
        config,
        response.data.access_token,
        response.data.id_token,
        response.data.refresh_token,
      )
      Cookies.set('access_token', response.data.access_token)
      return response.data.access_token
    })
  }

  private async refreshTokens() {
    const url: string = config.oauth.authority + 'oauth/token'

    const requestBody: TokenRequestBody = {
      client_id: config.oauth.clientId,
      grant_type: 'refresh_token',
      refresh_token: authToken(config)?.refresh_token,
    }

    const requestHeaders = {
      headers: {
        'content-type': 'application/json',
      },
    }

    return await axios
      .post(url, requestBody, requestHeaders)
      .then(response => {
        setTokens(
          config,
          response.data.access_token,
          response.data.id_token,
          response.data.refresh_token,
        )
      })
      .catch(refreshError => {
        console.error('Token refresh failed:', refreshError)
        return false
      })
      .finally(() => {
        return true
      })
  }

  private async initHttp() {
    const http = axios.create({
      baseURL: config.backendUrl.replace('/api', ''),
      headers: headers(),
    })

    http.interceptors.response.use(
      response => response,
      error => {
        const { response } = error
        this.handleError(response)
      },
    )

    return http
  }

  protected async handleError(error: any) {
    const status = error?.status

    const settings: UserManagerSettings = {
      authority: config.oauth.authority,
      client_id: config.oauth.clientId,
      redirect_uri: window.location.origin,
      scope: 'openid email profile offline_access',
      loadUserInfo: true,
    }

    const manager = new UserManager(settings)

    switch (status) {
      case StatusCode.InternalServerError: {
        if (error.data === 'jwt expired' || error.data === 'jwt malformed') {
          const refreshed = await this.refreshTokens()
          if (refreshed) {
            return this.retryRequest(config, error)
          } else {
            const redirect = `${window.location.origin}`
            manager.signinRedirect({
              state: {
                url: redirect,
              },
              extraQueryParams: {
                max_age: 0,
              },
            })
          }
        }
        break
      }
      case StatusCode.Forbidden: {
        break
      }
      case StatusCode.Unauthorized: {
        const refreshed = await this.refreshTokens()
        if (refreshed) {
          return this.retryRequest(config, error)
        }
        break
      }
      case StatusCode.TooManyRequests: {
        break
      }
    }

    return Promise.reject(error)
  }
}
