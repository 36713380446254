import { DealHistoryData, DealParams } from 'app/models/deal-params'
import * as types from './types'
import { createReducer } from 'app/state/utils'
import { DealDataSnapshotParams } from 'app/models/snapshot-params'

export interface DealState {
  deals: DealParams[]
  deal?: DealParams
  dealHistory: DealHistoryData[]
  lockedDeals: DealParams[]
  isLoading: boolean
  dealSnapshot?: DealDataSnapshotParams
}

const initialState: DealState = {
  deals: [],
  deal: undefined,
  dealHistory: [],
  lockedDeals: [],
  isLoading: false,
  dealSnapshot: undefined,
}

const dealsReducer = createReducer(initialState)({
  [types.GET_DEALS]: (state: any, payload: any) => {
    return { ...state, deals: payload.payload }
  },
  [types.GET_DEAL]: (state: any, payload: any) => {
    return { ...state, deal: payload.payload }
  },
  [types.GET_DEAL_SNAPSHOT]: (state: any, payload: any) => {
    return { ...state, dealSnapshot: payload.payload }
  },
  [types.ADD_DEALS]: (state: any, payload: any) => {
    return {
      ...state,
      deals: [{ ...payload.payload, status: 'Draft' }, ...state.deals],
    }
  },
  [types.EDIT_DEALS]: (state: any, payload: any) => {
    const index = state.deals.findIndex(
      (deal: { id: any }) => deal.id === payload.payload.id
    )
    const newArray = [...state.deals]
    newArray[index] = { ...payload.payload, status: 'Draft' }
    if (state?.deal?.id === payload?.payload?.id) {
      return {
        ...state,
        deals: newArray,
        deal: { ...payload.payload, status: 'Draft' },
      }
    }
    return {
      ...state,
      deals: newArray,
    }
  },
  [types.GET_DEAL_HISTORY]: (state: any, payload: any) => {
    return { ...state, dealHistory: payload.payload }
  },
  [types.GET_LOCKED_DEALS]: (state: any, payload: any) => {
    return { ...state, lockedDeals: payload.payload }
  },
  [types.SUBMIT_DEAL]: (state: any, payload: any) => {
    if (payload.payload.error) {
      return state
    }
    const index = state.deals.findIndex(
      (deal: { id: any }) => deal.id === payload.payload.id
    )
    const newArray = [...state.deals]
    newArray[index] = { ...newArray[index], status: 'Submitted' }
    if (state?.deal?.id === payload?.payload?.id) {
      return {
        ...state,
        deal: { ...state.deal, status: 'Submitted' },
        deals: newArray,
      }
    }
    return {
      ...state,
      deals: newArray,
    }
  },
  [types.REJECT_DEAL]: (state: any, payload: any) => {
    if (payload.payload.error) {
      return state
    }
    const index = state.deals.findIndex(
      (deal: { id: any }) => deal.id === payload.payload.id
    )
    const newArray = [...state.deals]
    newArray[index] = { ...newArray[index], status: 'Draft' }
    if (state?.deal?.id === payload?.payload?.id) {
      return {
        ...state,
        deal: { ...state.deal, status: 'Draft' },
        deals: newArray,
      }
    }
    return {
      ...state,
      deals: newArray,
    }
  },
  [types.APPROVE_DEAL]: (state: any, payload: any) => {
    if (payload.payload.error) {
      return state
    }
    const index = state.deals.findIndex(
      (deal: { id: any }) => deal.id === payload.payload.id
    )
    const newArray = [...state.deals]
    newArray[index] = { ...newArray[index], status: 'Approved' }
    if (state?.deal?.id === payload?.payload?.id) {
      return {
        ...state,
        deal: { ...state.deal, status: 'Approved' },
        deals: newArray,
      }
    }
    return {
      ...state,
      deals: newArray,
    }
  },
  [types.DELETE_DEAL]: (state: any, payload: any) => {
    if (!payload.payload || payload.payload.length === 0) {
      return state
    }
    const deletedIds = payload.payload
      .filter((payload: any) => !payload.error)
      .map((payload: any) => payload.id)
    return {
      ...state,
      deals: state.deals.filter((deal: any) => !deletedIds.includes(deal.id)),
      deal:
        state.deal && deletedIds.includes(state.deal.id) ? null : state.deal,
    }
  },
  [types.START_LOADING_DEALS]: (state: DealState) => {
    return { ...state, isLoading: true }
  },
  [types.STOP_LOADING_DEALS]: (state: DealState) => {
    return { ...state, isLoading: false }
  },
})

export default dealsReducer
