import { ToDoParams } from 'app/models/todo-params'
import { changeTodoStatus, removeTodos } from 'app/state/ducks/todos/thunks'
import { ErrorNotification, SuccessNotification } from 'app/views/components/notifications/notification'
import { Dispatch } from 'redux'

export async function updateTodoStatus(
  todo: ToDoParams,
  status: string,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(changeTodoStatus(todo, status))
  if (response.success) {
    SuccessNotification({
      title: 'To Do Status Updated',
    })
    return false
  } else {
    ErrorNotification({
      title: 'To Do Status Update Failed',
      message: response.payload,
    })
  }
  return
}
export async function deleteTodo(
  id: string,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(removeTodos(id))
  if (response.success) {
    SuccessNotification({
      title: 'To Do Deleted',
    })
    return false
  } else {
    ErrorNotification({
      title: 'To Do Delete Failed',
      message: response.payload,
    })
  }
  return
}