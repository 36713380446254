import {
    Group,
    Stack,
    Title,
} from '@mantine/core'
import { DealParams } from 'app/models/deal-params'
import StatusPill from '../../common/pill-status'


interface props {
    deal: DealParams
}

export default function DealHeader({ deal }: props) {
    return (
        <Stack className="detailsHeader">
            <Group noWrap>
                <Title className='detailsTitle'>{deal?.dealName ?? ''}</Title>
                <StatusPill
                    updated={true}
                    state={deal?.status ?? 'Draft'}
                    additionalState={deal?.amendmentType ?? ''}
                />
            </Group>
        </Stack>
    )
}