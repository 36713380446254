import { InputBase } from '@mantine/core'
import { IMaskInput } from 'react-imask'

export default function PhoneInput({ ...props }) {
  return (
    <InputBase
      label="Your phone"
      sx={{
        label: {
          fontFamily: 'Plus Jakarta Sans',
          color: '#111928',
          fontSize: '1em',
        },
        input: {
          backgroundColor: '#F9FAFB',
          height: '3em',
          borderRadius: '0.7em',
          color: '#6B7280',
          borderWidth: '1px',
          borderColor: '#D1D5DB',
          fontSize: '1.25em',
          fontFamily: 'Plus Jakarta Sans',
        },
      }}
      {...props}
      component={IMaskInput}
      mask="+0 (000) 000-0000"
    />
  )
}
