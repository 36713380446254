import React from 'react';
import { Flex, Text } from '@mantine/core';
import { useSelector } from 'react-redux';
import { getBusinessDate } from 'app/state/ducks/business-date/selectors';

const CurrentDate = () => {
    const businessDate = useSelector(getBusinessDate)
    const formatDate = () => {
        const now = businessDate
        const dayOfWeek = now.toLocaleDateString('en-US', { weekday: 'long' }); // Gets the day of the week, e.g., "Friday"
        // Combines the month, day, and year into one string for the second row
        const monthDayYear = now.toLocaleDateString('en-US', { month: 'long', day: 'numeric' }) + ', ' + now.getFullYear();
        return { dayOfWeek, monthDayYear };
    };

    const { dayOfWeek, monthDayYear } = formatDate();

    return (
        <Flex direction="column" align="end" gap={5}>
            <Text
                align="right"
                style={{
                    height: '22px',
                    fontFamily: 'Plus Jakarta Sans',
                    fontWeight: 300,
                    fontSize: '22px',
                    lineHeight: '27.72px',
                    color: '#4B4B4D',
                    textAlign: 'right'
                }}
            >
                {dayOfWeek}
            </Text>
            <Text
                align="right"
                style={{
                    height: '16px',
                    fontFamily: 'Plus Jakarta Sans',
                    fontWeight: 300,
                    fontSize: '13px',
                    lineHeight: '16.38px',
                    color: '#323234',
                    textAlign: 'right'
                }}
            >
                {monthDayYear}
            </Text>
        </Flex>
    );
};

export default CurrentDate;