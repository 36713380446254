import { PaymentParams, ReceiverParams, SenderParams } from 'app/models/payments-params'
import * as types from './types'
import { createReducer } from 'app/state/utils'

export interface PaymentState {
  payments: PaymentParams[]
  lockedPayments: PaymentParams[]
  senders: SenderParams[]
  receivers: ReceiverParams[],
  isLoadingActivePayments: boolean,
  isLoadingLockedPayments: boolean,
}
const initialState: PaymentState = {
  payments: [],
  lockedPayments: [],
  senders: [],
  receivers: [],
  isLoadingActivePayments: false,
  isLoadingLockedPayments: false,
}
const paymentsReducer = createReducer(initialState)({
  [types.GET_PAYMENTS]: (state: any, payload: any) => {
    return { ...state, payments: payload.payload }
  },
  [types.GET_LOCKED_PAYMENTS]: (state: any, payload: any) => {
    return { ...state, lockedPayments: payload.payload }
  },
  [types.GET_RECEIVERS]: (state: any, payload: any) => {
    return { ...state, receivers: payload.payload }
  },
  [types.GET_SENDERS]: (state: any, payload: any) => {
    return { ...state, senders: payload.payload }
  },
  [types.SUBMIT_PAYMENT]: (state: any, payload: any) => {
    const index = state.payments.findIndex(
      (payment: { id: any }) => payment.id === payload.payload.id
    )

    const newArray = [...state.payments]
    newArray[index] = { ...newArray[index], status: 'Queued', submitted: true }
    return {
      ...state,
      payments: newArray,
    }
  },
  [types.RELEASE_PAYMENT]: (state: any, payload: any) => {
    const index = state.payments.findIndex(
      (payment: { id: any }) => payment.id === payload.payload.id
    )
    const newArray = [...state.payments]
    newArray[index] = { ...newArray[index], status: 'Released' }
    return {
      ...state,
      payments: newArray,
    }
  },
  [types.QUEUE_PAYMENT]: (state: any, payload: any) => {
    const index = state.payments.findIndex(
      (payment: { id: any }) => payment.id === payload.payload.id
    )
    const newArray = [...state.payments]
    newArray[index] = { ...newArray[index], status: 'Queued', submitted: false }
    return {
      ...state,
      payments: newArray,
    }
  },
  [types.SETTLE_PAYMENT]: (state: any, payload: any) => {
    const index = state.payments.findIndex(
      (payment: { id: any }) => payment.id === payload.payload.id
    )
    if (index === -1) {
      return state
    }
    const item = state.payments[index]
    const lockedArray = [...state.lockedPayments, {...item, status: 'SettledPayment' }]
    const newArray = [...state.payments]
    newArray.splice(index, 1)
    return {
      ...state,
      payments: newArray,
      lockedPayments: lockedArray,
    }
  },
  [types.CHANGE_FRONTING]: (state: any, payload: any) => {
    const index = state.payments.findIndex(
      (payment: { id: any }) => payment.id === payload.payload.id
    )
    if(index === -1) {
      return state
    }

    const newArray = [...state.payments]
    newArray[index].fronting = !newArray[index].fronting
    return {
      ...state,
      payments: newArray,
    }
  },
  [types.CANCEL_PAYMENT]: (state: PaymentState, payload: any) => {
    const index = state.payments.findIndex(
      (payment: { id: any }) => payment.id === payload.payload.id
    )
    if (index === -1) {
      return state
    }
    const item = state.payments[index]
    const lockedArray = [...state.lockedPayments, {...item, status: 'CanceledPayment'}]
    const newArray = [...state.payments]
    newArray.splice(index, 1)
    return {
      ...state,
      payments: newArray,
      lockedPayments: lockedArray,
    }
  },
  [types.REJECT_RECEIVER]: (state: PaymentState, payload: any) => {
    const index = state.payments.findIndex(
      (payment: { id: any }) => payment.id === payload.payload.editedId.id
    )
    if (index === -1) {
      return state
    }
    const item = state.payments[index]
    const newArray = [...state.payments]
    if(payload.payload.rejected.id === payload.payload.editedId.id){
      newArray[index] = { ...newArray[index], status: 'Rejected' }
      return {
        ...state,
        payments: newArray,
      }
    }
    newArray[index] = {
      ...newArray[index], 
      payment:{
        ...item.payment, 
        amount: ''+(Number(item.payment.amount) - Number(payload.payload.rejected.payment.amount)),
        receiverIds: item.payment.receiverIds.filter((id:any)=>id._2.id !== payload.payload.rejected.payment.receiverIds[0]._2.id)
      }
    }    
    return {
      ...state,
      payments: [...newArray, {...payload.payload.rejected, status: 'Rejected'}],
    }
  },
  [types.CHANGE_RECEIVED_PAYMENTS]: (state: PaymentState, payload: any) => {
    const senders = state.senders.map((sender:any)=>({
      ...sender,
      paymentReceived: payload.payload.paymentReceived.find((p:any)=>p.lender.id === sender.lenderId.id)?.paymentReceived ?? sender.paymentReceived
    }))
    return {
      ...state,
      senders,
    }
  },
  [types.START_LOADING_ACTIVE_PAYMENTS]: (state: PaymentState) => {
    return { ...state, isLoadingActivePayments: true }
  },
  [types.STOP_LOADING_ACTIVE_PAYMENTS]: (state: PaymentState) => {
    return { ...state, isLoadingActivePayments: false }
  },
  [types.START_LOADING_LOCKED_PAYMENTS]: (state: PaymentState) => {
    return { ...state, isLoadingLockedPayments: true }
  },
  [types.STOP_LOADING_LOCKED_PAYMENTS]: (state: PaymentState) => {
    return { ...state, isLoadingLockedPayments: false }
  },
})

export default paymentsReducer
