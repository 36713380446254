import { WireInstructionParams } from 'app/models/wire-instruction-params'
import * as types from './types'
import { createReducer } from 'app/state/utils'

export interface WireInstructionState {
  wireInstructions: WireInstructionParams[]
  isLoading: boolean
}

const initialState: WireInstructionState = {
  wireInstructions: [],
  isLoading: false,
}

const wireInstructionsReducer = createReducer(initialState)({
  [types.GET_WIRE_INSTRUCTIONS]: (state: any, payload: any) => {
    return { ...state, wireInstructions: payload.payload }
  },
  [types.GET_WIRE_INSTRUCTION]: (state: any, payload: any) => {
    return { ...state, wireInstruction: payload.payload }
  },
  [types.ADD_WIRE_INSTRUCTIONS]: (state: any, payload: any) => {
    return {
      ...state,
      wireInstructions: [payload.payload.data, ...state.wireInstructions],
    }
  },
  [types.EDIT_WIRE_INSTRUCTIONS]: (state: any, payload: any) => {
    const index = state.wireInstructions.findIndex(
      (wireInstruction: { id: any }) =>
        wireInstruction.id === payload.payload.data.id
    )
    const newArray = [...state.wireInstructions]
    newArray[index] = payload.payload.data
    return {
      ...state,
      wireInstructions: newArray,
    }
  },
  [types.DELETE_WIRE_INSTRUCTIONS]: (state: any, payload: any) => {
    const filteredWireInstructions = state.wireInstructions.filter(
      (wireInstruction: any) => {
        return wireInstruction.id !== payload.payload[0].id
      }
    )

    return {
      ...state,
      entities: filteredWireInstructions,
    }
  },
  [types.ACTIVE_WIRE_INSTRUCTION]: (state: any, payload: any) => {
    const index = state.wireInstructions.findIndex(
      (wireInstruction: { id: any }) => wireInstruction.id === payload.payload.id
    )
    const newArray = [...state.wireInstructions]
    newArray[index] = { ...newArray[index], status: 'Active' }
    if (payload.payload.id === state.wireInstruction?.id) {
      return {
        ...state,
        wireInstruction: { ...state.contact, status: 'Active' },
        wireInstructions: newArray,
      }
    }
    return {
      ...state,
      wireInstructions: newArray,
    }
  },
  [types.INACTIVE_WIRE_INSTRUCTION]: (state: any, payload: any) => {
    const index = state.wireInstructions.findIndex(
      (wireInstruction: { id: any }) => wireInstruction.id === payload.payload.id
    )
    const newArray = [...state.wireInstructions]
    newArray[index] = { ...newArray[index], status: 'Inactive' }
    if (payload.payload.id === state.wireInstruction?.id) {
      return {
        ...state,
        wireInstruction: { ...state.wireInstruction, status: 'Inactive' },
        wireInstructions: newArray,
      }
    }
    return {
      ...state,
      wireInstructions: newArray,
    }
  },
  [types.START_LOADING_WIRE_INSTRUCTIONS]: (state: WireInstructionState) => {
    return { ...state, isLoading: true }
  },
  [types.STOP_LOADING_WIRE_INSTRUCTIONS]: (state: WireInstructionState) => {
    return { ...state, isLoading: false }
  },
})

export default wireInstructionsReducer
