import * as types from './types'
import { createReducer } from 'app/state/utils'
import { Document } from 'app/models/document-params'
import { ContractType, DocumentStateTypes } from 'app/models/common-types'

export interface DocumentState {
  dealDocuments?: Document[] | null
  facilityDocuments?: Document[] | null
  entityDocuments?: Document[] | null
  principalDocuments?: Document[] | null
  paymentDocuments?: Document[] | null
  feeDocuments?: Document[] | null
}

const initialState: DocumentState = {}

const documentsReducer = createReducer(initialState)({
  [types.GET_DOCUMENT_BY_CONTRACT]: (state: any, payload: any) => {
    if(payload.payload.length === 0) {
    return state
    }
    return {
      ...state,
      dealDocuments: payload.payload[0].contractType === ContractType.Deals ? payload.payload : state.dealDocuments,
      facilityDocuments: payload.payload[0].contractType === ContractType.Facilities ? payload.payload : state.facilityDocuments,
      entityDocuments: payload.payload[0].contractType === ContractType.Entities ? payload.payload : state.entityDocuments,
      principalDocuments: payload.payload[0].contractType === ContractType.Principals ? payload.payload : state.principalDocuments,
      paymentDocuments: payload.payload[0].contractType === ContractType.Payments ? payload.payload : state.paymentDocuments,
      feeDocuments: payload.payload[0].contractType === ContractType.Fees ? payload.payload : state.feeDocuments,
    }  
  },
  [types.UPLOAD_DOCUMENT]: (state: any, payload: any) => {
    return {
      ...state,
      dealDocuments: payload.payload.contractType === ContractType.Deals ? [...state.dealDocuments, payload.payload] : state.dealDocument,
      facilityDocuments: payload.payload.contractType === ContractType.Facilities ? [...state.facilityDocuments, payload.payload] : state.facilityDocument,
      entityDocuments: payload.payload.contractType === ContractType.Entities ? [...state.entityDocuments, payload.payload] : state.entityDocument,
      principalDocuments: payload.payload.contractType === ContractType.Principals ? [...state.principalDocuments, payload.payload] : state.principalDocument,
      paymentDocuments: payload.payload.contractType === ContractType.Payments ? [...state.paymentDocuments, payload.payload] : state.paymentDocument,
      feeDocuments: payload.payload.contractType === ContractType.Fees ? [...state.feeDocuments, payload.payload] : state.feeDocument,
    } 
  },
  [types.EDIT_DOCUMENT_TAG]: (state: any, payload: any) => {
    const documentStateType = DocumentStateTypes[(payload.payload.contractType) as ContractType]
    const documents = state[documentStateType]
    const index = documents.findIndex(
      (document: any) => document.id === payload.payload.id
    )
    const newArray = [...state.documents]
    newArray[index] = payload.payload
    return {
      ...state,
      [documentStateType]: newArray
    }
  },
  [types.REMOVE_DOCUMENT]: (state: any, payload: any) => {
    if(payload.payload.data.length === 0) {
      return state
    }
    payload.payload.data.forEach((data: { id: any , contractType: ContractType}) => {
      Object.values(DocumentStateTypes).forEach((documentStateType: any) => {
        const documents = state[documentStateType]
        const index = (documents ?? []).findIndex(
          (document: { id: any }) => document.id === data.id
        )
        if (index >= 0) {
          documents.splice(index, 1)
        }
      })
    })
    return {
      ...state,
    }
  }
})

export default documentsReducer
