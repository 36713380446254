import { Flex, Grid, Group, Select } from '@mantine/core'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import CustomDatePicker from 'app/views/components/date-picker/date-picker-logic'
import { loadAllFacilities } from 'app/state/ducks/facilities/thunks'
import { loadAllDeals } from 'app/state/ducks/deals/thunks'
import { getDeals } from 'app/state/ducks/deals/selectors'
import { getFacilities } from 'app/state/ducks/facilities/selectors'
import { useForm } from '@mantine/form'
import { FacilityParams } from 'app/models/facility-params'
import { loadContacts } from 'app/state/ducks/contacts/thunks'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import { getContacts } from 'app/state/ducks/contacts/selectors'
import { getWireInstructions } from 'app/state/ducks/wire-instructions/selectors'
import { LenderListParam, LenderListTabel } from './lender-list-table'
import { loadWireInstructions } from 'app/state/ducks/wire-instructions/thunks'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { formatDateToUTC, stringToDate } from 'app/utils/util-functions'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import KPI_Section from 'app/views/components/KPI/KPI-horizontal-section'
import { getKPIData } from './reporting-kpi-data'

export default function ReportHeader() {
  const deals = useSelector(getDeals)
  const facilities = useSelector(getFacilities)
  const entities = useSelector(getEntities)
  const contacts = useSelector(getContacts)
  const wireInstructions = useSelector(getWireInstructions)
  const businessDate = useSelector(getBusinessDate)

  const [dealsOptions, setDealsOptions] = useState<
    { label: string; value: string }[]
  >([])
  const [facilitiesOptions, setFacilitiesOptions] = useState<
    { label: string; value: string }[]
  >([])
  const [isLoading, setIsLoading] = useState(false)
  const [openLenderListForm, setOpenLenderListForm] = useState(false)
  const [lenderListData, setLenderListData] = useState(new Map())
  const [facilityAmount, setFacilityAmount] = useState(0)
  const dispatch = useDispatch()

  const kpiData = getKPIData([])


  useEffect(() => {
    dispatch(loadAllFacilities())
    dispatch(loadAllDeals())
    dispatch(loadContacts())
    dispatch(loadWireInstructions())
    dispatch(loadEntities())
  }, [])

  const form = useForm({
    initialValues: {
      startDate: businessDate,
      deal: '',
      facility: '',
    },
  })

  useEffect(() => {
    if (!deals || form.values.deal !== '') {
      setDealsOptions(
        deals
          .filter(
            deal =>
              deal.status == 'Approved' &&
              formatDateToUTC(stringToDate(deal.startDate)) ===
              formatDateToUTC(form.values.startDate)
          )
          .map(deal => ({ label: deal.dealName, value: deal.id ?? '' }))
      )
    }
  }, [deals])
  const generateReports = (event: any) => {
    setIsLoading(true)

    try {
      const fundManagerMap = new Map<string, LenderListParam[]>()
      const facility = facilities.find(
        facility => facility.id === event.facility
      )
      if (!facility) {
        return
      }

      facility.lendersPosition.forEach(lp => {
        const adminContact = contacts.find(
          contact => contact.id === lp.adminContactId?.id
        )
        const creditContact = contacts.find(
          contact => contact.id === lp.creditContactId?.id
        )
        const entity = entities.find(entity => entity.id === lp.lender.id)

        if (!entity) {
          return
        }
        const fmParent = entity.fundManager ?? entity.parent ?? ''
        const lenderWireInstructions = lp.wireInstructions.map(lpw =>
          wireInstructions.find(wireInstruction => lpw.id == wireInstruction.id)
        )

        if (!fundManagerMap.get(fmParent)) {
          fundManagerMap.set(fmParent, [])
        }

        const array = fundManagerMap.get(fmParent) ?? []
        array.push({
          fundManager: fmParent,
          adminEmail: adminContact?.email ?? '',
          creditEmail: creditContact?.email ?? '',
          lenderName: entity.entityName,
          mei: entity.mei,
          wireInstructions: lenderWireInstructions.filter(
            result => result !== undefined
          ),
          amount: Number(lp.amount),
          address: entity.address,
          address2: entity.address2,
          city: entity.city,
          state: entity.state,
          zipCode: entity.zipCode,
          country: entity.country,
        })
      })

      setFacilityAmount(facility.amount)
      setLenderListData(fundManagerMap)
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleDealChange = (e: string | null) => {
    form.setFieldValue('facility', '')
    form.setFieldValue('deal', e ? e : '')
    setFacilitiesOptions(
      facilities
        .filter(
          (facility: FacilityParams) =>
            facility.status == 'Approved' && facility.dealId.id === e
        )
        .map((facility: FacilityParams) => ({
          label: facility.name,
          value: facility.id,
        }))
    )
  }

  const handleDateChange = (e: Date) => {
    form.setFieldValue('startDate', e)
    form.setFieldValue('facility', '')
    form.setFieldValue('deal', '')
    setDealsOptions(
      deals
        .filter(
          deal =>
            deal.status == 'Approved' &&
            formatDateToUTC(stringToDate(deal.startDate)) === formatDateToUTC(e)
        )
        .map(deal => ({ label: deal.dealName, value: deal.id ?? '' }))
    )
  }

  const handleOpenLenderListForm = () => {
    setOpenLenderListForm(true)
  }

  return (
    <>
      <Group position="apart">
        <PageTitle>Report</PageTitle>
      </Group>

      {/* This KPI is only for looks, if you are going to implement it, please look at other *.kpi.data to see the structure */}
      <KPI_Section kpiData={kpiData} />

      <Flex gap="10px">
        <PrimaryButton miw="189px">Deal Summary</PrimaryButton>
        <PrimaryButton miw="189px">Facility Summary</PrimaryButton>
        <PrimaryButton miw="189px">Entity Summary</PrimaryButton>
        <PrimaryButton miw="189px" onClick={() => handleOpenLenderListForm()}>
          Lender List
        </PrimaryButton>
        <PrimaryButton miw="189px">Lender Exposure</PrimaryButton>
        <PrimaryButton miw="189px">Open Trade</PrimaryButton>
      </Flex>
      {openLenderListForm ? (
        <>
          <form onSubmit={form.onSubmit(value => generateReports(value))}>
            <Grid grow className="modal-body modal-body-template fit-content-modal">
              <Grid.Col span={3}>
                <CustomDatePicker
                  name="startDate"
                  id="startDate"
                  label="Start Date"
                  date={form.values.startDate}
                  setDate={(value: any) => handleDateChange(value)}
                  holidayCalendars={[]}
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <Select
                  searchable
                  withAsterisk
                  label="Deal"
                  placeholder="Select a Deal"
                  data={dealsOptions ?? []}
                  onChange={e => handleDealChange(e)}
                  value={form.values.deal}
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <Select
                  searchable
                  withAsterisk
                  label="Facility"
                  placeholder="Select a Facility"
                  data={facilitiesOptions ?? []}
                  {...form.getInputProps('facility')}
                />
              </Grid.Col>
              <Grid.Col span={3} style={{ paddingTop: '27px' }}>
                <PrimaryButton
                  className="form-button"
                  disabled={!form.isValid()}
                  loading={isLoading}
                  type="submit"
                  text="Generate"
                />
              </Grid.Col>
            </Grid>
          </form>
          <LenderListTabel
            data={lenderListData}
            facilityAmount={facilityAmount}
          />
        </>
      ) : null}
    </>
  )
}
