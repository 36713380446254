import { LetterOfCreditState } from './reducer'

export const getLetterOfCredits = (state: {
  letterOfCredits: LetterOfCreditState
}) => state.letterOfCredits.letterOfCredits

export const getLetterOfCredit = (state: {
  letterOfCredits: LetterOfCreditState
}) => state.letterOfCredits.letterOfCredit

export const getApprovedLetterOfCredits = (state: {
  letterOfCredits: LetterOfCreditState
}) => state.letterOfCredits.approvedLetterOfCredits

export const getAllApprovedLetterOfCredits = (state: {
  letterOfCredits: LetterOfCreditState
}) => state.letterOfCredits.approvedLetterOfCredits

export const getLocHistory = (state: { letterOfCredits: LetterOfCreditState }) =>
  state.letterOfCredits.locHistory

export const getLetterofCreditsLoader = (state: { letterOfCredits: LetterOfCreditState }) => {
  return state.letterOfCredits.isLoading
}