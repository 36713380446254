import React, { useEffect } from 'react'
import { PageLayout } from 'app/views/components/layout/page-layout'
import { useDispatch } from 'react-redux'
import { loadAllJobs } from 'app/state/ducks/jobs/thunks'
import { ManageJobs } from 'app/views/pages/job-management/manage-jobs'

export default function JobManagementPage() {
  const dispatch = useDispatch()


  useEffect(() => {
    dispatch(loadAllJobs())
  }, [])

  return (
    <PageLayout>
      <ManageJobs />
    </PageLayout>
  )
}
