import React, { useEffect } from 'react'
import { PageLayout } from 'app/views/components/layout/page-layout'
import { useDispatch } from 'react-redux'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'
import { EntitiesDrillPage } from './entities-management-drill-page'

export default function EntitiesManagementPage(tab: { tab: string }) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadEntities())
  }, [])

  return (
    <PageLayout>
      <PageTitle pageTitle='Entity Management' />
      <EntitiesDrillPage tab={tab.tab} />
    </PageLayout>
  )
}
