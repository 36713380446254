import { Anchor, Stack, Text } from '@mantine/core'
import { useEffect, useState } from 'react'
import { FacilityParams } from 'app/models/facility-params'
import { useDispatch, useSelector } from 'react-redux'
import { loadAllFacilities } from 'app/state/ducks/facilities/thunks'
import { getFacilities } from 'app/state/ducks/facilities/selectors'
import { useNavigate, useParams } from 'react-router-dom'
import { DetailSection } from 'app/views/components/detailSections/detail-section'

export default function DealRelatedFacilitiesDetails() {
  const dispatch = useDispatch()
  const { facilityId } = useParams()
  const { dealId } = useParams()

  const facilities: FacilityParams[] = useSelector(getFacilities)
  const [relatedFacilities, setRealatedFacilities] = useState<FacilityParams[]>(
    []
  )
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(loadAllFacilities())
  }, [])

  useEffect(() => {
    if (Array.isArray(facilities)) {
      if (facilityId) {
        const facility = facilities?.find(f => f.id === facilityId)
        setRealatedFacilities(
          facilities?.filter(
            f => f.dealId.id === facility?.dealId.id && f.id !== facilityId
          )
        )
      }
      if (dealId) {
        setRealatedFacilities(facilities?.filter(f => f.dealId.id === dealId))
      }
    }
  }, [facilities, facilityId, dealId])


  const relatedFacilityLinks = relatedFacilities?.map((f, index) => {
    return {
      label: (
        <Anchor
          key={'related-facilities-accordion-' + index}
          onClick={() =>
            navigate(
              '/dealmanagement/facilities/' + f.id + '/' + f.status
            )
          }
        >
          <Text className='sectionItemTitle'>
            {index + 1}. {f.name ?? f.id}
          </Text>
        </Anchor>
      ),
      value: null
    }
  })

  return (
    <Stack w="100%" className='sectionRemoveGap'>
      <Stack w='100%' className='sectionDetailBody'>
        <Text className='sectionDetailHeader'>Related Facilities</Text>
        <DetailSection detailObjArr={relatedFacilityLinks} columnCount={2} />
      </Stack>
    </Stack>
  )
}
