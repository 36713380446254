import React from 'react'
import { Title } from '@mantine/core'
import { ReactComponent as COLLAPSEDLOGO } from '../../../styles/LENDOS-CollapsedLogo.svg'

const CollapsedLogo: React.FC = () => {
  return (
    <Title align="center" color="gray.9" style={{ marginLeft: -14 }} my="lg">
      <COLLAPSEDLOGO
        style={{
          width: '66px',
          aspectRatio: '2 / 1',
        }}
      />
    </Title>
  )
}

export default CollapsedLogo
