import { MRT_ColumnDef } from 'mantine-react-table'
import { Text } from '@mantine/core'
import { ReactNode } from 'react'
import { CleanedLoanHistoryData } from 'app/models/loan-params'

function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}

export function getColumnDefs(): MRT_ColumnDef<CleanedLoanHistoryData>[] {

  return [
    {
      size: 50,
      accessorKey: 'Effective Date',
      header: 'Effective Date',
      Header: <TableHead header={'Effective Date'} />,
      mantineFilterTextInputProps: {
        placeholder: 'Date',
      },
      filterFn: 'equals',
      filterVariant: 'date',
      sortingFn: 'datetime',
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,

    },
    {
      size: 50,
      accessorKey: 'Transaction Type',
      header: 'Transaction Type',
      Header: <TableHead header={'Transaction Type'} />,
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },
    {
      size: 50,
      accessorKey: 'Transaction Amount',
      header: 'Transaction Amount',
      Header: <TableHead header={'Transaction Amount'} />,
      filterVariant: 'range',
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },
    {
      size: 50,
      accessorKey: 'New Balance',
      header: 'New Balance',
      Header: <TableHead header={'New Balance'} />,
      filterVariant: 'range',
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },
    {
      size: 30,
      accessorKey: 'Base Rate',
      header: 'Base Rate',
      Header: <TableHead header={'Base Rate'} />,
      filterVariant: 'range',
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },
    {
      size: 30,
      accessorKey: 'Margin',
      header: 'Margin',
      Header: <TableHead header={'Margin'} />,
      filterVariant: 'range',
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },

    {
      size: 30,
      accessorKey: 'All-In Rate',
      header: 'All-In Rate',
      Header: <TableHead header={'All-In Rate'} />,
      filterVariant: 'range',
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },
  ]
}