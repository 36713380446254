import { Group, Stack, TextInput } from '@mantine/core'
import { useSelector } from 'react-redux'
import CustomDatePicker from 'app/views/components/date-picker/date-picker-logic'
import { useEffect, useState } from 'react'
import { getAccruedAndPaidInterest } from 'app/state/ducks/lenders-positions/thunks'
import { formatDateToUTC, formatNumberToCurrency } from 'app/utils/util-functions'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import FormWrapper from 'app/views/components/Form/FormWrapper'


interface Props {
    lendersPosition: any
    currency: string
}


export default function LenderInterestComponent({
    lendersPosition,
    currency,
}: Props) {
    const businessDate = useSelector(getBusinessDate);
    const [effectiveDate, setEffectiveDate] = useState<Date | null>(businessDate);
    const [interestEarned, setInterestEarned] = useState<string>("");
    const [interestPaid, setInterestPaid] = useState<string>("");

    const setInterests = async (date: Date | null) => {
        if (!date) {
            return
        }
        const result = await getAccruedAndPaidInterest(lendersPosition.contractId.id, lendersPosition.lender.id, formatDateToUTC(date));
        const interestEarned = formatNumberToCurrency(result.payload.interestEarned, currency ?? 'USD')
        const interestPaid = formatNumberToCurrency(result.payload.interestPaid, currency ?? 'USD')
        setInterestEarned(interestEarned);
        setInterestPaid(interestPaid);
    }

    useEffect(() => {
        setInterests(effectiveDate);
    }, [lendersPosition, businessDate])

    return (
        <Stack w="100%" className='modal-body'>
            <FormWrapper title="Interest Accrual" >
                <div className="content">
                    <div className="create-new">
                        <Group noWrap w="100%">
                            <CustomDatePicker
                                w="100%"
                                required
                                label={'As of Date'}
                                date={effectiveDate ?? new Date()}
                                setDate={(value: any) => {
                                    setEffectiveDate(value);
                                    setInterests(value);
                                }}
                                holidayCalendars={
                                    []
                                }
                            />
                            <TextInput
                                disabled
                                w="100%"
                                label="Interest Earned"
                                placeholder="Empty"
                                value={interestEarned}
                                readOnly
                            />
                            <TextInput
                                disabled
                                w="100%"
                                label="Interest Paid"
                                placeholder="Empty"
                                value={interestPaid}
                                readOnly
                            />
                        </Group>
                    </div>
                </div>
            </FormWrapper>
        </Stack >
    )
}