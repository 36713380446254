import { useForm } from '@mantine/form'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import {
  Group,
  NumberInput,
  Stack,
} from '@mantine/core'
import CustomDatePicker from 'app/views/components/date-picker/date-picker-logic'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import {
  formatDateToUTC, stringToDate,
} from 'app/utils/util-functions'

import FormWrapper from 'app/views/components/Form/FormWrapper'
import { FacilityParams, } from 'app/models/facility-params'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import { SchedulerList } from 'app/models/amortitation-schedule'
import { editRowInAmortization } from 'app/state/ducks/amortization/thunks'
import { ErrorNotification, SuccessNotification } from '../notifications/notification'
import { loadAllFacilityAmendments } from 'app/state/ducks/facility-amendments/thunks'

type Props = {
  close: () => void
  row?: SchedulerList
  facility: FacilityParams
}

export default function EditAmortizationRowForm({ close, row, facility }: Props) {

  const dispatch = useDispatch()

  const businessDate = useSelector(getBusinessDate)

  const [isLoading, setIsLoading] = useState(false)

  const form: any = useForm({
    initialValues: {
      newAmount: Number(row?.paymentAmount ?? 0.0),
      newPaymentDate: row?.paymentDate ? stringToDate(row?.paymentDate) : businessDate,
      oldPaymentDate: row?.paymentDate ? stringToDate(row?.paymentDate) : null,
    },
    transformValues: values => {
      return {
        ...values,
        oldPaymentDate: values.oldPaymentDate ? formatDateToUTC(values.oldPaymentDate) : null,
        newPaymentDate: formatDateToUTC(values.newPaymentDate),
        facilityId: {
          id: facility?.id,
          admin: facility?.accountManagementAdmin,
        },
      }
    },
    validate: {
      newAmount: (value: number) => (value <= facility.amount ? null : 'Invalid amount'),
      newPaymentDate: (value: Date) => {
        const startDate = stringToDate(facility.startDate)
        const maturityDate = stringToDate(facility.maturity)
        return value >= startDate && value <= maturityDate
          ? null
          : 'Invalid date'
      },
    },
  })

  const onSubmit = async () => {
    setIsLoading(true)
    const result: any = await dispatch(editRowInAmortization(form.getTransformedValues()))
    setIsLoading(false)
    if (!result || !result.success) {
      ErrorNotification({
        title: 'Edit Row Failed',
        message: result.payload || 'Action Failed',
      })
      return
    }
    SuccessNotification({
      title: 'Successful Row Edited',
      message: 'Row has been Edited',
    })
    dispatch(loadAllFacilityAmendments())
    close()
  }

  return (
    <FormWrapper title='Edit Amortization Row'>
      <div className="content">
        <form onSubmit={form.onSubmit(() => onSubmit())}>
          <div className="create-new">
            <Stack spacing="xl">
              <Group noWrap>
                <CustomDatePicker
                  required
                  label={'Payment Date'}
                  date={form.values.newPaymentDate}
                  setDate={(value: any) => form.setFieldValue('newPaymentDate', value)}
                  holidayCalendars={
                    facility?.holidayCalendar ?? []
                  }
                />
                <NumberInput
                  withAsterisk
                  w="100%"
                  label="Amount"
                  id="amount"
                  placeholder="Enter Amount"
                  parser={value =>
                    value ? value.replace(/\$\s?|(,*)/g, '') : ''
                  }
                  formatter={value =>
                    !Number.isNaN(parseFloat(value ?? ''))
                      ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      : ''
                  }
                  {...form.getInputProps('newAmount')}
                />
              </Group>
              <Group noWrap>
                <PrimaryButton
                  loading={isLoading}
                  type="submit"
                  disabled={!form.isValid()}
                  text="Save"
                  w="100%"
                />
                <PrimaryButton
                  loading={isLoading}
                  text="Cancel"
                  w="100%"
                  onClick={() => close()}
                />
              </Group>
            </Stack>
          </div>
        </form>
      </div>
    </FormWrapper>
  )
}
