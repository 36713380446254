import { Table, Text, Radio } from '@mantine/core'
import { ContactParams } from 'app/models/contact-params'

interface Props {
  contacts: ContactParams[]
}

export default function ContactTable({ contacts }: Props) {
  return (
    <Table>
      <thead>
        <tr>
          <th style={{ width: '2%' }}></th>
          <th style={{ width: '19%' }}>
            {' '}
            <Text>Name</Text>
          </th>
          <th style={{ width: '19%' }}>
            <Text>Email</Text>
          </th>
          <th style={{ width: '19%' }}>
            <Text>Phone</Text>
          </th>
          <th style={{ width: '19%' }}>
            <Text>Fax</Text>
          </th>
        </tr>
      </thead>
      <tbody>
        {contacts.map((contact, index) => {
          return (
            <tr key={index}>
              <td>
                <Radio value={contact.id} />
              </td>
              <td>
                <Text>{contact.name}</Text>
              </td>
              <td>
                <Text>{contact.email}</Text>
              </td>
              <td>
                <Text>{contact.phoneNumber}</Text>
              </td>
              <td>
                <Text>{contact.faxNumber}</Text>
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}
