import React from 'react'
import ContactsForm from './contacts-form'
import { Modal } from '@mantine/core'

type Props = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  contact?: any
  entityId?: string
}
export const ContactsModal: React.FC<Props> = ({
  open,
  setOpen,
  contact,
  entityId,
}) => {
  return (
    <Modal
      className="modal-body create-new-form fit-content-modal"
      opened={open}
      onClose={() => setOpen(false)}
      centered={true}
      size="40%"
    >
      <ContactsForm
        closeModal={() => setOpen(false)}
        contact={contact ?? null}
        entityId={entityId}
        onHandleSubmit={() => null}
      />
    </Modal>
  )
}
