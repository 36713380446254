import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { PageLayout } from 'app/views/components/layout/page-layout'
import DealManagementHeaderSection from './deals-management-header'
import { loadAllDeals, loadLockedDeals } from 'app/state/ducks/deals/thunks'
import { LockedDealDrillPage } from './locked-deals-management-drill-page'
import { loadLockedFacilities } from 'app/state/ducks/facilities/thunks'
export function LockedDealManagmentPage(tab: { tab: string }) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadLockedFacilities())
    dispatch(loadLockedDeals())
    dispatch(loadAllDeals())
  }, [])

  return (
    <PageLayout>
      <DealManagementHeaderSection />
      <LockedDealDrillPage tab={tab.tab} />
    </PageLayout>
  )
}
