import * as types from './types'
import { createReducer } from 'app/state/utils'

const initialState = { facilitySchedulerAmortization: undefined}

const schedulerAmortizationReducer = createReducer(initialState)({
  [types.GET_SCHEDULER_AMORTIZATION_BY_FACILITY]: (state: any, payload: any) => {
    return { ...state, facilitySchedulerAmortization: payload.payload }
  },
  [types.CHANGE_SCHEDULER_AMORTIZATION_FOR_FACILITY]: (state: any, payload: any) => {
    return {
      ...state,
      facilitySchedulerAmortization: payload.payload,
    }
  },
  [types.EDIT_SCHEDULER_AMORTIZATION]: (state: any, payload: any) => {
    const facilityIndex = state.facilityScheduler.findIndex(
      (schedulerAmortization: { id: any }) => schedulerAmortization.id === payload.payload.id
    )
    if (facilityIndex !== -1) {
      const newArray = [...state.facilityScheduler]
      newArray[facilityIndex] = payload.payload
      return {
        ...state,
        facilitySchedulerAmortization: newArray,
      }
    }
  },
  [types.CHANGE_AMORTIZATION_ROW_STATUS]: (state: any, payload: any) => {
    return {
      ...state,
      facilitySchedulerAmortization: payload.payload,
    }
  },
  [types.RESET_FACILITY_SCHEDULER_AMORTIZATION]: (state: any) => {
    return { ...state, facilitySchedulerAmortization: undefined }
  },
})

export default schedulerAmortizationReducer
