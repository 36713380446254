import * as types from './types'
import { createReducer } from 'app/state/utils'

const initialState: never[] = []

const lendersShareReducer = createReducer(initialState)({
  [types.GET_LENDERS_SHARE]: (state: any, payload: any) => {
    return { ...state, lenders_share: payload.payload }
  },
})

export default lendersShareReducer
