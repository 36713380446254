import { isNotEmpty, useForm } from '@mantine/form'
import { useDispatch, useSelector } from 'react-redux'
import { getFacility } from 'app/state/ducks/facilities/selectors'
import { useEffect, useState } from 'react'
import { LoanParams } from 'app/models/loan-params'
import {
  SelectItem,
} from '@mantine/core'
import {
  rateTypeOptions,
  Option,
} from 'app/models/dropdown-options'
import {
  formatDateToUTC,
  roundTo, stringToDate,
} from 'app/utils/util-functions'
import {
  saveLoanConversion,
} from 'app/state/ducks/loan-conversions/thunks'
import { getLoanConversions } from 'app/state/ducks/loan-conversions/selectors'
import { LoanConversionParams } from 'app/models/loan-conversion-params'
import { loadOneFacility } from 'app/state/ducks/facilities/thunks'
import { ContractPeriod, IroValues } from 'app/models/facility-params'
import axios from 'axios'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'
import { getIndexRateOptions } from 'app/state/ducks/index-rate-options/selectors'
import { getInterestRatess } from 'app/state/ducks/interest-rates/selectors'
import { loadInterestByIndex } from 'app/state/ducks/interest-rates/thunks'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import ConversionFormPresentation from './conversion-form-presentation'
import { calculateNonTermInterests } from '../loans/utilities/calculate-non-term-interest'
import { changeContractPeriod } from '../loans/utilities/change-contract-period'
import { calculateTermInterests } from '../loans/utilities/calculate-term-interest'

type Props = {
  close: any
  loan: LoanParams
}

export default function ConversionFormLogic({ close, loan }: Props) {
  const dispatch = useDispatch()

  const facility = useSelector(getFacility)
  const loanConversion = useSelector(getLoanConversions)
  const allIndexes = useSelector(getIndexRateOptions)
  const dailyRates = useSelector(getInterestRatess)
  const businessDate = useSelector(getBusinessDate)

  const [facilityindexOptions, setFacilityIndexOptions] = useState<Option[]>([])
  const [iro, setIro] = useState<IroValues | undefined>(undefined)
  const [contractPeriod, setContractPeriod] = useState<ContractPeriod>()
  const [disableEndDate, setDisableEndDate] = useState(false)
  const [facilityContractPeriodOptions, setFacilityContractPeriodOptions] =
    useState<SelectItem[]>([])

  const conversion = loanConversion.find(lr => lr?.loanId?.id === loan?.id) ?? undefined

  useEffect(() => {
    if (conversion) {
      const formConversion = {
        ...conversion.newLoan,
        status: conversion?.status,
        amount: Number(conversion?.newLoan.amount) ?? Number(loan.amount),
        interestBaseRate: Number(conversion?.newLoan.interestBaseRate ?? 0.0),
        indexOption: conversion?.newLoan.indexOption.id ?? '',
        interestBaseRateWithRounding:
          Number(conversion?.newLoan.interestBaseRateWithRounding ?? 0.0),
        endDate: conversion?.newLoan.endDate ? stringToDate(conversion.newLoan.endDate) : stringToDate(loan.endDate),
        startDate: conversion?.newLoan.startDate
          ? stringToDate(conversion.newLoan.startDate)
          : businessDate,
        margin: Number(conversion?.newLoan.margin ?? 0.0),
        allInRate: Number(conversion?.newLoan.allInRate ?? 0.0),
        casValue: Number(conversion?.newLoan.casValue ?? 0.0),
        hasError: false,
        ErrorMessage: '',
        approveRate: false,
        hasSuccessfulEntitySave: false,
        customer_token: '',
        readyForApproval: false,
        frontingProhibited: true,
      }
      form.setValues(formConversion)
    } else {
      const cleanForm = {
        id: undefined,
        status: undefined,
        facilityId: loan.facilityId,
        amount: Number(loan.amount),
        currency: loan.currency,
        dayBasis: "",
        interestBaseRate: 0.0,
        indexOption: "",
        interestBaseRateWithRounding: 0.0,
        rounding: "",
        endDate: stringToDate(loan.endDate),
        startDate: businessDate,
        isSwingLine: loan.isSwingLine,
        isNonProRata: loan.isNonProRata,
        margin: 0.0,
        allInRate: 0.0,
        amountDue: null,
        accrualInterestMaturityDate: null,
        casValue: 0.0,
        contractPeriod: '',
        borrower: loan.borrower,
        borrowerPaymentInstructions: loan.borrowerPaymentInstructions,
        hasError: false,
        ErrorMessage: '',
        approveRate: false,
        hasSuccessfulEntitySave: false,
        customer_token: '',
        nextInterestPaymentDate: null,
        nextPIKDate: null
      }
      form.setValues(cleanForm)
    }
  }, [conversion])


  useEffect(() => {
    if (!facility) {
      return
    }
    const indexRateOptions = facility.iroValues.find(
      iro => iro.indexOption.id == form.values.indexOption
    )
    setIro(indexRateOptions)
    if (!indexRateOptions) {
      return
    }
    if (form.values.rounding === '' || form.values.rounding === null) {
      form.setFieldValue('rounding', indexRateOptions.rounding)
    }
    if (
      form.values.margin === '' ||
      form.values.margin === 0 ||
      form.values.margin === null
    ) {
      form.setFieldValue('margin', Number(indexRateOptions.margin))
    }
    if (form.values.dayBasis === '' || form.values.dayBasis === null) {
      form.setFieldValue('dayBasis', indexRateOptions.dayBasis)
    }
    const selectedContractPeriod = indexRateOptions.contractPeriod.find(
      cp => cp.contractPeriod == form.values.contractPeriod
    )
    setContractPeriod(selectedContractPeriod)
  }, [facility, conversion])


  useEffect(() => {
    dispatch(loadOneFacility(loan.facilityId.id, 'Approved'))
  }, [])

  // set interest on edit
  useEffect(() => {
    if (!dailyRates) {
      return
    }
    if (allIndexes.find(index => index.id === form.values.indexOption)?.indexType !== 'TermIndex') {
      calculateNonTermInterests(form, conversion?.newLoan, dailyRates, allIndexes, iro, form.values?.startDate);
    }
  }, [dailyRates])

  useEffect(() => {
    if (!allIndexes || allIndexes.length == 0 || !facility) {
      return
    }
    const facilityIndexes = [...new Set(facility?.iroValues.map(iro => {
      return allIndexes.find(option => option.id === iro.indexOption.id)
    }))]


    setFacilityIndexOptions(
      facilityIndexes.map(indexOption => ({
        value: indexOption?.id ?? '',
        label: indexOption?.indexOption ?? '',
      })) ?? []
    )


    const selectedIndex = allIndexes.find(
      index => index.id == form.values.indexOption
    )
    if (selectedIndex) {
      dispatch(loadInterestByIndex(selectedIndex))
    }
  }, [allIndexes, facility])


  useEffect(() => {
    if (!loan) {
      return
    }
    const filteredIros = facility?.iroValues.find(
      iro =>
        iro.indexOption.id == form.values.indexOption &&
        form.values.currency === iro.currency
    )
    if (!filteredIros) {
      return
    }
    const contractPeriods = filteredIros.contractPeriod.map(
      iro => iro.contractPeriod
    )
    const filteredContractPeriodOptions = rateTypeOptions.filter(option =>
      contractPeriods.includes(option.value)
    )
    setFacilityContractPeriodOptions(filteredContractPeriodOptions)
  }, [facility, loan])

  const form: any = useForm({
    initialValues: {
      status: conversion?.status,
      id: conversion?.newLoan.id ?? undefined,
      facilityId: loan.facilityId,
      amount: Number(conversion?.newLoan.amount ?? loan.amount),
      currency:
        conversion?.newLoan.currency ?? loan.currency,
      dayBasis: conversion?.newLoan.dayBasis ?? "",
      interestBaseRate: Number(conversion?.newLoan.interestBaseRate ?? 0.0),
      indexOption: conversion?.newLoan.indexOption.id ?? "",
      interestBaseRateWithRounding:
        Number(conversion?.newLoan.interestBaseRateWithRounding ?? 0.0),
      rounding: conversion?.newLoan.rounding ?? "",
      endDate: conversion?.newLoan.endDate ? stringToDate(conversion.newLoan.endDate) : stringToDate(loan.endDate),
      startDate: conversion?.newLoan.startDate
        ? stringToDate(conversion.newLoan.startDate)
        : businessDate,
      isSwingLine: loan.isSwingLine,
      isNonProRata: loan.isNonProRata,
      margin: Number(conversion?.newLoan.margin ?? 0.0),
      allInRate: Number(conversion?.newLoan.allInRate ?? 0.0),
      amountDue: null,
      accrualInterestMaturityDate: null,
      casValue: Number(conversion?.newLoan.casValue ?? 0.0),
      contractPeriod: conversion?.newLoan.contractPeriod ?? '',
      borrower: loan.borrower,
      borrowerPaymentInstructions:
        loan.borrowerPaymentInstructions,
      hasError: false,
      ErrorMessage: '',
      approveRate: false,
      hasSuccessfulEntitySave: false,
      customer_token: '',
      readyForApproval: false,
      frontingProhibited: true,
      nextInterestPaymentDate: conversion?.newLoan.nextInterestPaymentDate ?? null,
      nextPIKDate: conversion?.newLoan.nextPIKDate ?? null
    },
    transformValues: values => {
      const indexOption = allIndexes.find(
        option => option.id === values.indexOption
      )
      return {
        id: values.id,
        status: values.status,
        loanId: { admin: loan.agencyAdmin, id: loan.id },
        newLoan: {
          ...values,
          startDate: formatDateToUTC(values.startDate),
          endDate: formatDateToUTC(values.endDate as Date),
          rounding: values.rounding != '' ? values.rounding : null,
          dayBasis: values.dayBasis != '' ? values.dayBasis : null,
          indexOption: {
            id: indexOption?.id ?? '',
            admin: indexOption?.public ?? '',
          },
          contractPeriod:
            values.contractPeriod != '' ? values.contractPeriod : null,
          allInRate: values.allInRate !== 0.0 ? values.allInRate : null,
          interestBaseRate:
            values.interestBaseRate !== 0.0 ? values.interestBaseRate : null,
          interestBaseRateWithRounding:
            values.interestBaseRateWithRounding !== 0.0
              ? Number(values.interestBaseRateWithRounding)
              : null,
        }
      }
    },
    validate: {
      amount: (value: number) => (value > 0 ? null : 'Invalid amount'),
      status: (value?: string) =>
        !value || value === 'Draft' ? null : 'Has already been submitted',
      endDate: (value: any, values) =>
        isNotEmpty(value)
          ? value > values.startDate
            ? null
            : 'Maturity has to be after Start Date'
          : 'Maturity is required'

      ,
      startDate: (value: any) => (!loan.startDate ||
        stringToDate(loan.startDate) <= value)
        ? null
        : 'Start Date is before loan start date'


    },
  })


  useEffect(() => {
    if (!form.values.indexOption) {
      return
    }
    const selectedIndex = allIndexes.find(index => index.id === form.values.indexOption)
    if (!selectedIndex) {
      return
    }
    setDisableEndDate(selectedIndex?.indexType !== 'TermIndex')
  }, [form.values.indexOption, allIndexes])

  const resetInterest = () => {
    form.setFieldValue('interestBaseRate', 0)
    form.setFieldValue('interestBaseRateWithRounding', 0)
    form.setFieldValue('allInRate', 0)
    form.clearFieldError('contractPeriod')
    form.clearFieldError('startDate')
  }

  const changeIndexOption = (event: string | null) => {
    if (!event) {
      return
    }
    const selectedIndex = allIndexes.find(index => index.id === event)
    if (selectedIndex?.indexType === 'TermIndex') {
      setDisableEndDate(false)
    } else {
      setDisableEndDate(true)
    }

    if (selectedIndex) {
      dispatch(loadInterestByIndex(selectedIndex))
    }
    form.setFieldValue('indexOption', event)
    form.setFieldValue('casValue', 0)
    form.setFieldValue('allInRate', 0)
    form.setFieldValue('contractPeriod', '')
    setContractPeriod(undefined)
    const filteredIros =
      facility?.iroValues.find(
        iro => iro.indexOption.id === event && form.values.currency === iro.currency
      ) ?? undefined
    if (!filteredIros) {
      form.setFieldValue('rounding', '')
      form.setFieldValue('margin', 0)
      form.setFieldValue('dayBasis', '')
      resetInterest()
      return
    }
    form.setFieldValue('rounding', filteredIros.rounding)
    form.setFieldValue('margin', Number(filteredIros.margin))
    form.setFieldValue('dayBasis', filteredIros.dayBasis)
    setIro(filteredIros)

    const contractPeriods = filteredIros.contractPeriod.map(
      cp => cp.contractPeriod
    )
    const filteredContractPeriodOptions = rateTypeOptions.filter(option =>
      contractPeriods.includes(option.value)
    )
    if (selectedIndex?.indexType !== 'TermIndex') {
      form.setFieldValue('contractPeriod', null)
      form.setFieldValue('endDate', stringToDate((facility?.maturity ?? '')))
    } else {
      resetInterest()
      setFacilityContractPeriodOptions(filteredContractPeriodOptions)
    }
  }

  const changeContractPeriodInForm = (event: string | null) => {
    if (!event) {
      return
    }
    changeContractPeriod(form, iro, conversion?.newLoan, dailyRates, event, setContractPeriod, form.values?.startDate);
  }

  const createDraftConversion = async () => {
    try {

      const loanConversionVals: LoanConversionParams = form.getTransformedValues()
      const response: any = await dispatch(
        saveLoanConversion(loanConversionVals, conversion)
      )

      if (response.success) {
        SuccessNotification({
          title: 'Successfully Saved Draft Loan Conversion',
          message: 'You created or edited a Loan Conversion',
        })
      } else {
        ErrorNotification({
          title: 'Failed to Create Conversion',
          message: response.payload ?? 'Please check inputs',
        })
      }
      return response
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response?.status == 400) {
        const data: any = error.response.data
        ErrorNotification({
          title: 'Failed to Create Conversion',
          message: data?.error ?? 'Please check inputs',
        })
        return null
      } else {
        ErrorNotification({
          title: 'Failed to Create Conversion',
          message: error,
        })
        return null
      }
    } finally {
      form.reset()
    }
  }

  function changeStartDate(value: any) {
    if (allIndexes.find(index => index.id === form.values.indexOption)?.indexType !== 'TermIndex') {
      calculateNonTermInterests(form, conversion?.newLoan, dailyRates, allIndexes, iro, value);
    } else {
      calculateTermInterests(form, conversion?.newLoan, dailyRates, iro, contractPeriod, value);
    }
  }

  function onInterestBaseRateChange(value: number | ''): void {
    form.setFieldValue('interestBaseRate', value)
    if (form.values.rounding) {
      const interestWithRounding = Number(
        roundTo(Number(value), form.values.rounding)
      )
      form.setFieldValue('interestBaseRateWithRounding', interestWithRounding)
    }
    form.setFieldValue(
      'allInRate',
      Number(value) +
      Number(form.values.margin ?? 0) +
      Number(form.values.casValue ?? 0)
    )
  }

  return (
    <ConversionFormPresentation close={close} form={form} facility={facility} loan={loan} conversion={conversion} facilityindexOptions={facilityindexOptions} disableEndDate={disableEndDate} facilityContractPeriodOptions={facilityContractPeriodOptions} changeIndexOption={changeIndexOption} changeStartDate={changeStartDate} changeContractPeriod={changeContractPeriodInForm} onInterestBaseRateChange={onInterestBaseRateChange} createDraftConversion={createDraftConversion} />
  )
}
