import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { setUsers } from 'app/state/ducks/users/actions'
import { Dispatch } from 'redux'

export const loadUsers = () => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client.get(`${apiServerUrl}/api/access/v1/users`).then(users => {
    return dispatch(setUsers(users))
  }).catch(e => {
    console.log(e)
    return []
  })
}
