import { stringToDate } from 'app/utils/util-functions'
import * as types from './types'
import { createReducer } from 'app/state/utils'

const initialState: never[] = []

const businessDateReducer = createReducer(initialState)({
  [types.SET_BUSINESS_DATE]: (state: any, payload: any) => {
    return { ...state, businessDateInfo: payload.payload, curBusinessDate: stringToDate(payload.payload.currentDate) }
  },
})

export default businessDateReducer
