import { useSelector } from 'react-redux'
import {
  IndexType,
  Option,
  currencyOptions,
  isRiskFreeRateOptions,
} from 'app/models/dropdown-options'

// ** Hooks
import { useEffect, useState } from 'react'
import { formatDateToUTC } from 'app/utils/util-functions'
import { Grid, Group, Text, Select, NumberInput } from '@mantine/core'
import CustomDatePicker from 'app/views/components/date-picker/date-picker-logic'
import { IndexRateOptionParams } from 'app/models/index-rate-options-params'
import { getIndexRateOptions } from 'app/state/ducks/index-rate-options/selectors'
import { InterestRateParams } from 'app/models/interest-rate'

type Props = {
  form: any
  index: IndexRateOptionParams
  allRates: InterestRateParams[]
  alreadyExists: boolean
  setAlreadyExists: (value: boolean) => void
}

const TermInterestRateForm: React.FC<Props> = ({
  form,
  index,
  allRates,
  alreadyExists,
  setAlreadyExists,
}) => {
  const [indexOptions, setIndexOptions] = useState<Option[]>([])

  const indexes = useSelector(getIndexRateOptions)

  useEffect(() => {
    setIndexOptions(
      indexes.map(item => ({ value: item.id ?? '', label: item.indexOption }))
    )
  }, [indexes])

  const handleDateChange = (date: any) => {
    const rate = allRates.find(
      rate => rate.effectiveDate === formatDateToUTC(date)
    )
    if (rate) {
      form.setValues({
        ...form.values,
        effectiveDate: date,
        termRates: {
          overnight:
            index.contractPeriods.includes('Overnight') ?
              rate.termRates.overnight
                ? Number(rate.termRates.overnight)
                : 0.0
              : null,
          oneWeek:
            index.contractPeriods.includes('OneWeek') ?
              rate.termRates.oneWeek
                ? Number(rate.termRates.oneWeek)
                : 0.0
              : null,
          twoWeeks:
            index.contractPeriods.includes('TwoWeeks') ?
              rate.termRates.twoWeeks
                ? Number(rate.termRates.twoWeeks)
                : 0.0
              : null,
          threeWeeks:
            index.contractPeriods.includes('ThreeWeeks') ?
              rate.termRates.threeWeeks
                ? Number(rate.termRates.threeWeeks)
                : 0.0
              : null,
          oneMonth:
            index.contractPeriods.includes('OneMonth') ?
              rate.termRates.oneMonth
                ? Number(rate.termRates.oneMonth)
                : 0.0
              : null,
          twoMonths:
            index.contractPeriods.includes('TwoMonths') ?
              rate.termRates.twoMonths
                ? Number(rate.termRates.twoMonths)
                : 0.0
              : null,
          threeMonths:
            index.contractPeriods.includes('ThreeMonths') ?
              rate.termRates.threeMonths
                ? Number(rate.termRates.threeMonths)
                : 0.0
              : null,
          fourMonths:
            index.contractPeriods.includes('FourMonths') ?
              rate.termRates.fourMonths
                ? Number(rate.termRates.fourMonths)
                : 0.0
              : null,
          fiveMonths:
            index.contractPeriods.includes('FiveMonths') ?
              rate.termRates.fiveMonths
                ? Number(rate.termRates.fiveMonths)
                : 0.0
              : null,
          sixMonths:
            index.contractPeriods.includes('SixMonths') ?
              rate.termRates.sixMonths
                ? Number(rate.termRates.sixMonths)
                : 0.0
              : null,
          sevenMonths:
            index.contractPeriods.includes('SevenMonths') ?
              rate.termRates.sevenMonths
                ? Number(rate.termRates.sevenMonths)
                : 0.0
              : null,
          eightMonths:
            index.contractPeriods.includes('EightMonths') ?
              rate.termRates.eightMonths
                ? Number(rate.termRates.eightMonths)
                : 0.0
              : null,
          nineMonths:
            index.contractPeriods.includes('NineMonths') ?
              rate.termRates.nineMonths
                ? Number(rate.termRates.nineMonths)
                : 0.0
              : null,
          tenMonths:
            index.contractPeriods.includes('TenMonths') ?
              rate.termRates.tenMonths
                ? Number(rate.termRates.tenMonths)
                : 0.0
              : null,
          elevenMonths:
            index.contractPeriods.includes('ElevenMonths') ?
              rate.termRates.elevenMonths
                ? Number(rate.termRates.elevenMonths)
                : 0.0
              : null,
          oneYear:
            index.contractPeriods.includes('OneYear') ?
              rate.termRates.oneYear
                ? Number(rate.termRates.oneYear)
                : 0.0
              : null,
        },
      })
      setAlreadyExists(true)
    } else {
      form.setValues({
        ...form.values,
        effectiveDate: date,
        termRates: {
          overnight: index.contractPeriods.includes('Overnight') ? 0.0 : null,
          oneWeek: index.contractPeriods.includes('OneWeek') ? 0.0 : null,
          twoWeeks: index.contractPeriods.includes('TwoWeeks') ? 0.0 : null,
          threeWeeks: index.contractPeriods.includes('ThreeWeeks') ? 0.0 : null,
          oneMonth: index.contractPeriods.includes('OneMonth') ? 0.0 : null,
          twoMonths: index.contractPeriods.includes('TwoMonths') ? 0.0 : null,
          threeMonths: index.contractPeriods.includes('ThreeMonths')
            ? 0.0
            : null,
          fourMonths: index.contractPeriods.includes('FourMonths') ? 0.0 : null,
          fiveMonths: index.contractPeriods.includes('FiveMonths') ? 0.0 : null,
          sixMonths: index.contractPeriods.includes('SixMonths') ? 0.0 : null,
          sevenMonths: index.contractPeriods.includes('SevenMonths')
            ? 0.0
            : null,
          eightMonths: index.contractPeriods.includes('EightMonths')
            ? 0.0
            : null,
          nineMonths: index.contractPeriods.includes('NineMonths') ? 0.0 : null,
          tenMonths: index.contractPeriods.includes('TenMonths') ? 0.0 : null,
          elevenMonths: index.contractPeriods.includes('ElevenMonths')
            ? 0.0
            : null,
          oneYear: index.contractPeriods.includes('OneYear') ? 0.0 : null,
        },
      })
      setAlreadyExists(false)
    }
  }

  return (
    <>
      <Group noWrap w="100%">
        <CustomDatePicker
          label={'Date'}
          date={form.values.effectiveDate}
          setDate={handleDateChange}
          mantineFormProps={{ ...form.getInputProps('effectiveDate') }}
          holidayCalendars={[]}
        />
        <Select
          readOnly
          w="100%"
          label="Index"
          placeholder="Index"
          data={indexOptions}
          searchable={true}
          withAsterisk
          {...form.getInputProps('indexId')}
        />
        <Select
          readOnly
          w="100%"
          label="Currency"
          placeholder="Currency"
          data={currencyOptions}
          withAsterisk
          searchable={true}
          {...form.getInputProps('currency')}
        />
        <Select
          readOnly
          searchable
          w="100%"
          label="Is RFR?"
          placeholder="Is RFR?"
          data={isRiskFreeRateOptions}
          withAsterisk
          {...form.getInputProps('riskFreeRate')}
        />
        <Select
          data={IndexType}
          readOnly
          w="100%"
          label="Type"
          placeholder="Select Type"
          searchable={true}
          {...form.getInputProps('type')}
        />
      </Group>
      <Text>Available Term Rate</Text>
      <Grid columns={5} style={{ height: "auto" }}>
        {index.contractPeriods.includes('overnight') && (
          <Grid.Col span={1}>
            <NumberInput
              readOnly={alreadyExists}
              precision={5}
              w="100%"
              label="Overnight"
              name="overnight"
              id="overnight"
              placeholder="Overnight Rate"
              className="iroFormFilled"
              {...form.getInputProps('termRates.overnight')}
            />
          </Grid.Col>
        )}
        {index.contractPeriods.includes('OneWeek') && (
          <Grid.Col span={1}>
            <NumberInput
              readOnly={alreadyExists}
              precision={5}
              w="100%"
              label="One Week"
              name="oneWeek"
              id="oneWeek"
              placeholder="One Week Rate"
              className="iroFormFilled"
              {...form.getInputProps('termRates.oneWeek')}
            />
          </Grid.Col>
        )}
        {index.contractPeriods.includes('TwoWeeks') && (
          <Grid.Col span={1}>
            <NumberInput
              readOnly={alreadyExists}
              precision={5}
              w="100%"
              label="Two Weeks"
              name="twoWeeks"
              id="twoWeeks"
              placeholder="Two Weeks Rate"
              className="iroFormFilled"
              {...form.getInputProps('termRates.twoWeeks')}
            />
          </Grid.Col>
        )}
        {index.contractPeriods.includes('ThreeWeeks') && (
          <Grid.Col span={1}>
            <NumberInput
              readOnly={alreadyExists}
              precision={5}
              w="100%"
              label="Three Weeks"
              name="threeWeeks"
              id="threeWeeks"
              placeholder="Three Weeks Rate"
              className="iroFormFilled"
              {...form.getInputProps('termRates.threeWeeks')}
            />
          </Grid.Col>
        )}
        {index.contractPeriods.includes('OneMonth') && (
          <Grid.Col span={1}>
            <NumberInput
              readOnly={alreadyExists}
              precision={5}
              w="100%"
              label="One Month"
              name="oneMonth"
              id="oneMonth"
              placeholder="One Month Rate"
              className="iroFormFilled"
              {...form.getInputProps('termRates.oneMonth')}
            />
          </Grid.Col>
        )}
        {index.contractPeriods.includes('TwoMonths') && (
          <Grid.Col span={1}>
            <NumberInput
              readOnly={alreadyExists}
              precision={5}
              w="100%"
              label="Two Months"
              name="twoMonths"
              id="twoMonths"
              placeholder="Two Months Rate"
              className="iroFormFilled"
              {...form.getInputProps('termRates.twoMonths')}
            />
          </Grid.Col>
        )}
        {index.contractPeriods.includes('ThreeMonths') && (
          <Grid.Col span={1}>
            <NumberInput
              readOnly={alreadyExists}
              precision={5}
              w="100%"
              label="Three Months"
              name="threeMonths"
              id="threeMonths"
              placeholder="Three Months Rate"
              className="iroFormFilled"
              {...form.getInputProps('termRates.threeMonths')}
            />
          </Grid.Col>
        )}
        {index.contractPeriods.includes('FourMonths') && (
          <Grid.Col span={1}>
            <NumberInput
              readOnly={alreadyExists}
              precision={5}
              w="100%"
              label="Four Months"
              name="fourMonths"
              id="fourMonths"
              placeholder="Four Months Rate"
              className="iroFormFilled"
              {...form.getInputProps('termRates.fourMonths')}
            />
          </Grid.Col>
        )}
        {index.contractPeriods.includes('FiveMonths') && (
          <Grid.Col span={1}>
            <NumberInput
              readOnly={alreadyExists}
              precision={5}
              w="100%"
              label="Five Months"
              name="fiveMonths"
              id="fiveMonths"
              placeholder="Five Months Rate"
              className="iroFormFilled"
              {...form.getInputProps('termRates.fiveMonths')}
            />
          </Grid.Col>
        )}
        {index.contractPeriods.includes('SixMonths') && (
          <Grid.Col span={1}>
            <NumberInput
              readOnly={alreadyExists}
              precision={5}
              w="100%"
              label="Six Months"
              name="sixMonths"
              id="sixMonths"
              placeholder="Six Months Rate"
              className="iroFormFilled"
              {...form.getInputProps('termRates.sixMonths')}
            />
          </Grid.Col>
        )}
        {index.contractPeriods.includes('SevenMonths') && (
          <Grid.Col span={1}>
            <NumberInput
              readOnly={alreadyExists}
              precision={5}
              w="100%"
              label="Seven Months"
              name="sevenMonths"
              id="sevenMonths"
              placeholder="Seven Months Rate"
              className="iroFormFilled"
              {...form.getInputProps('termRates.sevenMonths')}
            />
          </Grid.Col>
        )}
        {index.contractPeriods.includes('EightMonths') && (
          <Grid.Col span={1}>
            <NumberInput
              readOnly={alreadyExists}
              precision={5}
              w="100%"
              label="Eight Months"
              name="eightMonths"
              id="eightMonths"
              placeholder="Eight Months Rate"
              className="iroFormFilled"
              {...form.getInputProps('termRates.eightMonths')}
            />
          </Grid.Col>
        )}
        {index.contractPeriods.includes('NineMonths') && (
          <Grid.Col span={1}>
            <NumberInput
              readOnly={alreadyExists}
              precision={5}
              w="100%"
              label="Nine Months"
              name="nineMonths"
              id="nineMonths"
              placeholder="Nine Months Rate"
              className="iroFormFilled"
              {...form.getInputProps('termRates.nineMonths')}
            />
          </Grid.Col>
        )}
        {index.contractPeriods.includes('TenMonths') && (
          <Grid.Col span={1}>
            <NumberInput
              readOnly={alreadyExists}
              precision={5}
              w="100%"
              label="Ten Months"
              name="tenMonths"
              id="tenMonths"
              placeholder="Ten Months Rate"
              className="iroFormFilled"
              {...form.getInputProps('termRates.tenMonths')}
            />
          </Grid.Col>
        )}
        {index.contractPeriods.includes('ElevenMonths') && (
          <Grid.Col span={1}>
            <NumberInput
              readOnly={alreadyExists}
              precision={5}
              w="100%"
              label="Eleven Months"
              name="elevenMonths"
              id="elevenMonths"
              placeholder="Eleven Months Rate"
              className="iroFormFilled"
              {...form.getInputProps('termRates.elevenMonths')}
            />
          </Grid.Col>
        )}
        {index.contractPeriods.includes('OneYear') && (
          <Grid.Col span={1}>
            <NumberInput
              readOnly={alreadyExists}
              precision={5}
              w="100%"
              label="One Year"
              name="oneYear"
              id="oneYear"
              placeholder="One Year Rate"
              className="iroFormFilled"
              {...form.getInputProps('termRates.oneYear')}
            />
          </Grid.Col>
        )}
      </Grid>

      {/* <Group noWrap>
                                <PrimaryButton
                                    w="100%"
                                    disabled={!form.isValid()}
                                    loading={isLoading}
                                    type="submit"
                                    text="Submit"
                                />

                                <PrimaryButton
                                    w="100%"
                                    onClick={() => form.reset()}
                                    text="Clear"
                                />
                            </Group> */}
    </>
  )
}

export default TermInterestRateForm
