import { ContactParams, ContactFormValues } from 'app/models/contact-params'
import { useDispatch, useSelector } from 'react-redux'
import { saveContact } from 'app/state/ducks/contacts/thunks'
import {
  contactTypeBorrowerOptions,
  contactTypeOptions,
  preferredContactMethodOptions,
} from 'app/models/dropdown-options'
import { SetStateAction, useEffect, useState, FormEvent } from 'react'
import TextInput from 'app/views/components/inputs/TextInput'
import Select from 'app/views/components/inputs/Select'
import { getEntities } from 'app/state/ducks/entities/selectors'
import config from 'app/config/config'
import { useForm } from '@mantine/form'
import { Group, Stack, Switch, Radio, Flex } from '@mantine/core'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import PhoneInput from 'app/views/components/inputs/PhoneInput'
import { EntityParams } from 'app/models/entity-params'
import { ErrorNotification, SuccessNotification } from 'app/views/components/notifications/notification'
import FormWrapper from 'app/views/components/Form/FormWrapper'
import IonIcon from '@reacticons/ionicons'
import cleanUUID from 'app/views/components/functions/cleanUUID'

type ContactsFormProps = {
  contact?: ContactParams
  entityId?: string
  isStepper?: boolean
  onHandleSubmit?: (contacts: ContactFormValues) => void
  multipleContacts?: ContactParams[]
  closeModal?: (shouldClose: boolean) => void
}

const ContactsForm: React.FC<ContactsFormProps> = ({
  contact,
  entityId,
  isStepper,
  onHandleSubmit,
  multipleContacts,
  closeModal
}) => {

  const entities: EntityParams[] = useSelector(getEntities)
  const entity =
    entities?.find(
      e => e.id === entityId || e.id === cleanUUID(contact?.entity.id)
    ) ?? null
  const [contactDropDownOption, setContactDropDownOption] = useState(contactTypeOptions)

  const form = useForm({
    initialValues: {
      contacts: contact ? [{
        id: contact?.id ?? '',
        contactType: contact?.contactType ?? '',
        name: contact?.name ?? '',
        email: contact?.email ?? '',
        faxNumber: contact?.faxNumber ?? '',
        phoneNumber: contact?.phoneNumber ?? '',
        preferredContactMethod: contact?.preferredContactMethod ?? '',
        dataRoomAccess: contact?.dataRoomAccess ?? false,
        isIndividualOrGroup: contact?.isIndividualOrGroup ?? null,
        entity: { admin: entity?.maker, id: entity?.id },
        status: contact?.status ?? '',
      }] : [{
        id: '',
        contactType: '',
        name: '',
        email: '',
        faxNumber: '',
        phoneNumber: '',
        preferredContactMethod: '',
        dataRoomAccess: false,
        isIndividualOrGroup: null,
        entity: { admin: entity?.maker, id: entity?.id },
        status: '',
      }]
    },
    validate: {
      contacts: {
        contactType: value => (value === '' ? 'Invalid Contact Type' : null),
        name: value => (value === '' ? 'Invalid Name' : null),
        email: value => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
        faxNumber: value => (value !== '' ? null : 'Invalid Fax Number'),
        phoneNumber: value => (value !== '' ? null : 'Invalid Phone Number'),
        preferredContactMethod: value =>
          value === '' ? 'Invalid Contact Method' : null,
      }
    },
  })

  useEffect(() => {
    if (multipleContacts && multipleContacts.length > 0) {
      const multiContactForm: SetStateAction<Partial<ContactFormValues> | ContactFormValues[]> = []
      multipleContacts.map((contact) => {
        multiContactForm.push(
          {
            id: contact?.id ?? '',
            contactType: contact?.contactType ?? '',
            name: contact?.name ?? '',
            email: contact?.email ?? '',
            faxNumber: contact?.faxNumber ?? '',
            phoneNumber: contact?.phoneNumber ?? '',
            preferredContactMethod: contact?.preferredContactMethod ?? '',
            dataRoomAccess: contact?.dataRoomAccess ?? false,
            isIndividualOrGroup: contact.isIndividualOrGroup ?? null,
            entity: { admin: entity?.maker, id: entity?.id },
            status: contact?.status ?? '',
          }
        )
      })
      form.setValues({ contacts: multiContactForm });
    }
  }, [multipleContacts])

  useEffect(() => {
    if (entityId && entities) {
      const foundEntity = entities.find((entity: EntityParams) => entity?.id === entityId);
      form?.values?.contacts?.map((c) => c.entity = { admin: entity?.maker, id: entity?.id })
      let borrowerOrGaurentor = false
      foundEntity?.entityProfile.forEach((profile: string) => {
        if (profile.toLowerCase().includes('borrower') || profile.toLowerCase().includes('guarantor')) {
          borrowerOrGaurentor = true;
        }
      })
      if (borrowerOrGaurentor) {
        setContactDropDownOption(contactTypeOptions.concat(contactTypeBorrowerOptions))
      }
    }
  }, [entityId, entities])

  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  async function onSubmit(values: ContactFormValues) {
    setIsLoading(true)
    const multiContactUpdate = multipleContacts?.find(
      p => cleanUUID(p?.id) === values?.id
    )
    const contactparams: ContactParams = {
      ...values,
      isIndividualOrGroup: values.isIndividualOrGroup,
      hasError: false,
      hasSuccessfulEntitySave: false,
      ErrorMessage: '',
      customer_token: config.company.toUpperCase(),
    }
    const response: any = await dispatch(saveContact(contactparams, contact ?? multiContactUpdate))

    setIsLoading(false)
    if (response?.success) {
      SuccessNotification({
        title: 'Successfully Saved Contact'
      })
      form.reset()
      if (closeModal) {
        closeModal(true)
      }
    } else {
      ErrorNotification({
        title: 'Failed to Save Contact',
        message: response?.payload ?? 'Check contact fields and try again'
      })
    }
  }

  const submitForm = (event: FormEvent) => {
    event.preventDefault()
    form.values.contacts.map((c) => {
      onSubmit(c)
    })
    onHandleSubmit?.(form.values.contacts[0])
  }

  const fields = form.values?.contacts?.map((c, c_i) => {
    return (
      <div key={'contactsForm' + c_i}>
        <form>
          <Stack>
            <Group noWrap w="100%">
              <TextInput
                w="100%"
                withAsterisk
                label="Contact Name"
                name="name"
                id="name"
                placeholder="Enter Contact Name"
                {...form.getInputProps(`contacts.${c_i}.name`)}
              />

              <Select
                searchable
                w="100%"
                label="Contact Type"
                placeholder="Contact Type"
                data={contactDropDownOption}
                {...form.getInputProps(`contacts.${c_i}.contactType`)}
              />
            </Group>

            <Group noWrap>
              <TextInput
                w="50%"
                label="Email"
                name="email"
                id="email"
                placeholder="Enter Email"
                {...form.getInputProps(`contacts.${c_i}.email`)}
              />

              <Radio.Group
                w="50%"
                name="favoriteFramework"
                description="Is contact an individual or belongs to a group?"
                {...form.getInputProps(`contacts.${c_i}.isIndividualOrGroup`)}
              >
                <Group mt="xs">
                  <Radio value="Individual" label="Individual" />
                  <Radio value="Group" label="Group" />
                </Group>
              </Radio.Group>
            </Group>
            <Group noWrap w="100%">
              <PhoneInput
                style={{ fontSize: '14px', fontFamily: 'Plus Jakarta Sans' }}
                w="100%"
                label="Phone Number"
                name="phoneNumber"
                id="phoneNumber"
                placeholder="Phone Number"
                {...form.getInputProps(`contacts.${c_i}.phoneNumber`)}
              />
              <PhoneInput
                w="100%"
                label="Fax Number"
                name="faxNumber"
                id="faxNumber"
                placeholder="Fax Number"
                {...form.getInputProps(`contacts.${c_i}.faxNumber`)}
              />
            </Group>

            <Group noWrap w="100%">
              <Select
                searchable
                w="100%"
                label="Preferred Method of Communication"
                placeholder="Select choice"
                data={preferredContactMethodOptions}
                {...form.getInputProps(`contacts.${c_i}.preferredContactMethod`)}
              />

              <div style={{ width: '100%' }}>
                <Stack style={{ marginTop: '15px' }}>
                  <Group>
                    <Switch
                      size="lg"
                      checked={c.dataRoomAccess}
                      {...form.getInputProps(`contacts.${c_i}.dataRoomAccess`)}
                    />
                    <Flex direction="column">
                      <label className="switchLabel">Has Data Room Access</label>
                      <label className="form-subtitle">
                        {' '}
                        Applicable to Credit Contacts
                      </label>
                    </Flex>
                  </Group>
                </Stack>
              </div>
            </Group>
          </Stack>

          {(!contact && c_i > 0) &&
            <Stack style={{ marginTop: '10px' }}>
              <Group noWrap>
                <Group noWrap w={'75%'}></Group>
                <Group noWrap w={'100%'}>
                  <IonIcon name="trash-outline" className="click-ion-icon" style={{ color: "red" }} onClick={() => form.removeListItem('contacts', c_i)} />
                  <span
                    style={{
                      fontFamily: 'Plus Jakarta Sans',
                      color: '#111928',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    Remove
                  </span>
                </Group>
              </Group>
            </Stack>
          }
        </form>
        {(!contact && form.values.contacts.length > 1) &&
          <Group noWrap
            style={{
              backgroundColor: '#D1D5DB',
              color: '#D1D5DB',
              height: '5px',
              marginTop: '15px',
              marginBottom: '15px',
            }}
          ></Group>
        }
      </div>
    )
  })


  return (
    <FormWrapper
      title={`${contact ? 'Edit Contact' : 'Add Contacts'} ${contact ? 'from' : 'to'} Entity`}>
      <div className="content">
        <>{fields}</>

        {(!contact) &&
          <Stack style={{ marginTop: form.values.contacts.length > 0 ? '15px' : '0px', }}>
            <Group noWrap>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IonIcon name="add-circle-outline" className="click-ion-icon"
                  onClick={() =>
                    form.insertListItem('contacts', {
                      id: '',
                      contactType: '',
                      name: '',
                      email: '',
                      faxNumber: '',
                      phoneNumber: '',
                      preferredContactMethod: '',
                      dataRoomAccess: false,
                      entity: { admin: entity?.maker, id: entity?.id },
                      status: '',
                    })
                  } />
                <span
                  style={{
                    marginLeft: '8px',
                    fontFamily: 'Plus Jakarta Sans',
                    fontWeight: 'bold',
                    fontSize: '1em',
                  }}
                >
                  Add Contacts
                </span>
              </div>
            </Group>
          </Stack>
        }

        <Stack>
          <div
            style={{
              marginTop: '1rem',
              display: 'flex',
              gap: '.5rem',
              justifyContent: 'flex-end',
            }}
          >
            {isStepper ? (
              <>
                <PrimaryButton
                  className="form-button"
                  // disabled={!form.isValid()}  -> check validations
                  onClick={submitForm}
                  loading={isLoading}
                  type="submit"
                  w="100%"
                >
                  Save and Proceed
                </PrimaryButton>
              </>
            ) : (
              <>
                <PrimaryButton
                  className="form-button"
                  w="100%"
                  disabled={!form.isValid()}
                  onClick={submitForm}
                  loading={isLoading}
                  type="submit"
                  text="Save"
                />
                <PrimaryButton
                  className="form-button"
                  w="100%"
                  onClick={() => form.reset()}
                  text="Clear"
                />
              </>
            )}
          </div>
        </Stack>
      </div>
    </FormWrapper>
  )
}

export default ContactsForm