import { FacilityHistoryData, FacilityParams } from 'app/models/facility-params'
import * as types from './types'
import { createReducer } from 'app/state/utils'
import { FacilitySnapshotParams } from 'app/models/snapshot-params'

export interface FacilityState {
  facilities: FacilityParams[]
  facility?: FacilityParams
  facilityHistory: FacilityHistoryData[]
  lockedFacilities: FacilityParams[]
  isLoading: boolean
  facilitySnapshot?: FacilitySnapshotParams
}

const initialState: FacilityState = {
  facilities: [],
  facility: undefined,
  facilityHistory: [],
  lockedFacilities: [],
  isLoading: false,
}
const facilitiesReducer = createReducer(initialState)({
  [types.GET_FACILITIES]: (state: any, payload: any) => {
    return { ...state, facilities: payload.payload }
  },
  [types.GET_FACILITY]: (state: any, payload: any) => {
    return { ...state, facility: payload.payload }
  },
  [types.GET_FACILITY_SNAPSHOT]: (state: any, payload: any) => {
    return { ...state, facilitySnapshot: payload.payload }
  },
  [types.ADD_FACILITIES]: (state: any, payload: any) => {
    return {
      ...state,
      facilities: [
        { ...payload.payload, status: 'Draft' },
        ...state.facilities,
      ],
    }
  },
  [types.EDIT_FACILITIES]: (state: any, payload: any) => {
    const index = state.facilities.findIndex(
      (facility: { id: any }) => facility.id === payload.payload.id
    )
    const newArray = [...state.facilities]
    newArray[index] = { ...payload.payload, status: 'Draft' }
    return {
      ...state,
      facilities: newArray,
      facility: { ...payload.payload, status: 'Draft' },
    }
  },
  [types.GET_FACILITY_HISTORY]: (state: any, payload: any) => {
    return { ...state, facilityHistory: payload.payload }
  },

  [types.GET_LOCKED_FACILITIES]: (state: any, payload: any) => {
    return { ...state, lockedFacilities: payload.payload }
  },
  [types.SUBMIT_FACILITY]: (state: any, payload: any) => {
    if (payload.payload.error) {
      return state
    }
    const index = state.facilities.findIndex(
      (facility: { id: any }) => facility.id === payload.payload.id
    )
    const newArray = [...state.facilities]
    newArray[index] = { ...newArray[index], status: 'Submitted' }
    if (state?.facility?.id === payload.payload.id) {
      return {
        ...state,
        facility: { ...state.facility, status: 'Submitted' },
        facilities: newArray,
      }
    }
    return {
      ...state,
      facilities: newArray,
    }
  },
  [types.REJECT_FACILITY]: (state: any, payload: any) => {
    if (payload.payload.error) {
      return state
    }
    const index = state.facilities.findIndex(
      (facility: { id: any }) => facility.id === payload.payload.id
    )
    const newArray = [...state.facilities]
    newArray[index] = { ...newArray[index], status: 'Draft' }
    if (state?.facility?.id === payload.payload.id) {
      return {
        ...state,
        facility: { ...state.facility, status: 'Draft' },
        facilities: newArray,
      }
    }
    return {
      ...state,
      facilities: newArray,
    }
  },
  [types.APPROVE_FACILITY]: (state: any, payload: any) => {
    if (payload.payload.error) {
      return state
    }
    const index = state.facilities.findIndex(
      (facility: { id: any }) => facility.id === payload.payload.id
    )
    const newArray = [...state.facilities]
    newArray[index] = { ...newArray[index], status: 'Approved' }
    if (state?.facility?.id === payload.payload.id) {
      return {
        ...state,
        facility: { ...state.facility, status: 'Approved' },
        facilities: newArray,
      }
    }
    return {
      ...state,
      facilities: newArray,
    }
  },
  [types.DELETE_FACILITY]: (state: any, payload: any) => {
    if (!payload.payload || payload.payload.length === 0) {
      return state
    }
    const deletedIds = payload.payload
      .filter((payload: any) => !payload.error)
      .map((payload: any) => payload.id)
    return {
      ...state,
      facilities: state.facilities.filter(
        (facility: any) => !deletedIds.includes(facility.id)
      ),
      facility:
        state.facility && deletedIds.includes(state.facility.id)
          ? null
          : state.facility,
    }
  },
  [types.START_LOADING_FACILITIES]: (state: FacilityState) => {
    return { ...state, isLoading: true }
  },
  [types.STOP_LOADING_FACILITIES]: (state: FacilityState) => {
    return { ...state, isLoading: false }
  },
})

export default facilitiesReducer
