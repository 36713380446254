import React, { useEffect, useState } from 'react'
import { PageLayout } from 'app/views/components/layout/page-layout'
import { Group } from '@mantine/core'
import { useDispatch } from 'react-redux'
import { loadIndexRateOptions } from 'app/state/ducks/index-rate-options/thunks'
import { ManageIndexRateOptions } from 'app/views/pages/index-rate-options-management/manage-index-rate-options'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'
import { IndexRateOptionsModal } from './index-rate-options-modal'

export default function IndexRateOptionManagementPage() {
  const [addIndexRateOption, setAddIndexRateOption] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadIndexRateOptions())
  }, [])

  return (
    <PageLayout>
      <Group position="apart" style={{ paddingBottom: '10px' }}>
        <PageTitle>Index Rate Options</PageTitle>
        <Group style={{ paddingRight: '15px' }}>
          <PrimaryButton onClick={() => setAddIndexRateOption(true)}>
            Create IRO
          </PrimaryButton>
        </Group>
      </Group>
      <ManageIndexRateOptions />
      <IndexRateOptionsModal
        open={addIndexRateOption}
        setOpen={setAddIndexRateOption}
      />
    </PageLayout>
  )
}
