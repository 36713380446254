import { CloseButton, Group, Stack } from '@mantine/core'
import { IndexRateOptionParams } from 'app/models/index-rate-options-params'
import { InterestRateParams } from 'app/models/interest-rate'
import { getInterestRatess } from 'app/state/ducks/interest-rates/selectors'
import { loadInterestByIndex } from 'app/state/ducks/interest-rates/thunks'
import CustomDatePicker from 'app/views/components/date-picker/date-picker-logic'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TermTable } from './term-table'
import { FloatingTable } from './floating-table'
import { OvernightTable } from './overnight-table'
import { stringToDate } from 'app/utils/util-functions'

interface Props {
  index?: IndexRateOptionParams
  closeDailyRates: () => void
}

export function DailyRates({ index, closeDailyRates }: Props) {
  const dispatch = useDispatch()
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [tableData, setTableData] = useState<InterestRateParams[]>([])
  const dailyRates: InterestRateParams[] = useSelector(getInterestRatess)

  useEffect(() => {
    if (!index) {
      return
    }
    dispatch(loadInterestByIndex(index))
  }, [index])

  useEffect(() => {
    if (!dailyRates) return
    setTableData(
      dailyRates
        .filter(
          item =>
            (startDate ? stringToDate(item.effectiveDate) >= startDate : true) &&
            (endDate ? stringToDate(item.effectiveDate) <= endDate : true)
        )
        .sort((a, b) => (a.effectiveDate > b.effectiveDate ? 1 : -1))
    )
  }, [dailyRates, startDate, endDate])

  return (
    <Stack>
      <Group grow spacing="40%">
        <h2>{index?.indexOption ?? 'CAOO'}</h2>
        <CloseButton onClick={closeDailyRates} size="lg" />
      </Group>
      <Group noWrap>
        <CustomDatePicker
          label={'Start Date'}
          date={startDate as any}
          setDate={setStartDate}
          holidayCalendars={[]}
          clearable
        />
        <CustomDatePicker
          label={'End Date'}
          date={endDate as any}
          setDate={setEndDate}
          holidayCalendars={[]}
          clearable
        />
      </Group>
      {index?.indexType === 'TermIndex' && <TermTable data={tableData} />}
      {index?.indexType === 'FloatingIndex' && (
        <FloatingTable data={tableData} />
      )}
      {index?.indexType === 'OvernightIndex' && (
        <OvernightTable data={tableData} />
      )}
    </Stack>
  )
}
