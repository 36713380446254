import React from 'react'
import { PageLayout } from 'app/views/components/layout/page-layout'
import ReportHeader from './report-header'

export function ReportingPage() {
  return (
    <PageLayout>
      <ReportHeader />
    </PageLayout>
  )
}
