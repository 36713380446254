import { Select } from '@mantine/core';
import { DraftDealParams } from 'app/models/deal-params';
import { pricingGridTypeOptions } from 'app/models/dropdown-options';
import { UseFormReturnType } from '@mantine/form';
import { FacilityPricingGrid } from 'app/models/facility-params';

interface PricingGridTypeSelectorProps {
    form: UseFormReturnType<{ pricingGrid: FacilityPricingGrid[] }>;
    pricingGridIndex: number;
    deal: DraftDealParams | undefined;
    changeGridType: (value: string, index: number) => void;
}

const PricingGridTypeSelector: React.FC<PricingGridTypeSelectorProps> = ({
    form,
    pricingGridIndex,
    deal,
    changeGridType
}) => {
    const pricingGrid = form.values.pricingGrid[pricingGridIndex];
    const selectedOptions = form.values.pricingGrid.map(
        (item: { pricingGridType: string; }) => item.pricingGridType
    );

    const updatedPricingGridTypeOptions = pricingGridTypeOptions.filter(option =>
        deal?.pricingGrid.some((grid) => grid.pricingGridType === option.value)
    );

    const updatedOptions = updatedPricingGridTypeOptions.map(option => ({
        ...option,
        disabled: selectedOptions.includes(option.value) && option.value !== pricingGrid.pricingGridType,
    }));

    return (
        <Select
            w="100%"
            label="Pricing Grid Type"
            {...form.getInputProps(`pricingGrid.${pricingGridIndex}.pricingGridType`)}
            data={updatedOptions}
            onChange={(value: string) => changeGridType(value, pricingGridIndex)}
        />
    );
};

export default PricingGridTypeSelector;