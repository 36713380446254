import React from 'react'

import { Group, Text } from '@mantine/core'
import { formatNumberToCurrency } from 'app/utils/util-functions'

interface TotalAmountProps {
  total: number
  currency: string
}

const TotalAmount: React.FC<TotalAmountProps> = ({ total, currency }) => (
  <tr>
    <td colSpan={4}>
      <Group bg="#001565" position="apart" p="md" sx={{ borderRadius: '8px' }}>
        <Text color="white" size="lg" fw={600}>
          Total
        </Text>
        <Text color="white" size="lg" fw={600}>
          {formatNumberToCurrency(total, currency)}
        </Text>
      </Group>
    </td>
  </tr>
)

export default TotalAmount
