import { PageLayout } from 'app/views/components/layout/page-layout'
import SimulationsHeader from './simulations-header'

export default function SimulationsPage() {
    return (
        <PageLayout>
            <SimulationsHeader />
        </PageLayout>
    )
}
