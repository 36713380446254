import { NoticeState } from './reducer'

export const getAllActiveNotices = (state: {
  notices: NoticeState
}) => state.notices.activeNotices

export const getAllLockedNotices = (state: {
  notices: NoticeState
}) => state.notices.lockedNotices

export const getActiveNoticeLoader = (state: {
  notices: NoticeState
}) => state.notices.isLoadingActiveNotices

export const getLockedNoticeLoader = (state: {
  notices: NoticeState
}) => state.notices.isLoadingLockedNotices