import React from 'react'

const AssignmentTableHeader: React.FC = () => (
  <thead style={{ backgroundColor: '#001565', minHeight: '40px' }}>
    <tr>
      <th style={{ textAlign: 'center', color: 'white' }}>No.</th>
      <th style={{ textAlign: 'center', color: 'white' }}>From</th>
      <th style={{ textAlign: 'center', color: 'white' }}>Seller MEI</th>
      <th style={{ textAlign: 'center', color: 'white' }}>To</th>
      <th style={{ textAlign: 'center', color: 'white' }}>Buyer MEI</th>
      <th style={{ textAlign: 'center', color: 'white' }}>Amount</th>
      <th style={{ textAlign: 'center', color: 'white' }}>Trade ID</th>
      <th style={{ textAlign: 'center', color: 'white' }}>
        Assignee Eligibility
      </th>
      <th style={{ textAlign: 'center', color: 'white' }}>Attachments</th>
    </tr>
  </thead>
)

export default AssignmentTableHeader
