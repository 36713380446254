import { Dispatch } from 'redux'
import { loadAllJobs, saveJob } from 'app/state/ducks/jobs/thunks'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'
import { JobParams } from 'app/models/job-params'

export async function changeSchedule(
  job: JobParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(
    saveJob(job, job)
  )

  if (response.success) {
    SuccessNotification({
      title: 'Successful Edit',
      message: 'Job has been edited.',
    })
  } else {
    ErrorNotification({
      title: 'Edit Failed',
      message: response.payload ?? 'edit Failed',
    })
    return false
  }
  dispatch(loadAllJobs())
  return true
}
