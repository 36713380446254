import * as types from './types'

export const getRates = (payload: any) => ({
  type: types.GET_RATES,
  payload,
})

export const addRate = (payload: any) => ({
  type: types.ADD_RATES,
  payload,
})

export const editRate = (payload: any) => ({
  type: types.EDIT_RATES,
  payload,
})

export const simulateAPIForIndex = (payload: any) => ({
  type: types.SIMULATE_API_FOR_INDEX,
  payload,
})
