import { FacilityState } from './reducer'

export const getFacilities = (state: { facilities: FacilityState }) =>
  state.facilities.facilities

export const getFacility = (state: { facilities: FacilityState }) => {
  return state.facilities.facility
}

export const getFacilityHistory = (state: { facilities: FacilityState }) =>
  state.facilities.facilityHistory

export const getLockedFacilities = (state: { facilities: FacilityState }) => {
  return state.facilities.lockedFacilities
}

export const getFacilityLoader = (state: { facilities: FacilityState }) => {
  return state.facilities.isLoading
}

export const getFacilitySnapshot = (state: { facilities: FacilityState }) => {
  return state.facilities.facilitySnapshot
}
