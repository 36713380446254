import { LoanRateSettingParams } from 'app/models/loan-rate-setting-params'
import * as types from './types'
import { createReducer } from 'app/state/utils'

export interface LoanRateSettingsState {
  approvedLoanRateSettings: LoanRateSettingParams[]
  loanRateSettings: LoanRateSettingParams[]
  isLoading: boolean
}


const initialState: LoanRateSettingsState ={
  approvedLoanRateSettings: [],
  loanRateSettings: [],
  isLoading: false,
}

const loanRateSettingsReducer = createReducer(initialState)({
  [types.GET_LOAN_RATE_SETTINGS]: (state: any, payload: any) => {
    return { ...state, loanRateSettings: payload.payload }
  },

  [types.GET_APPROVED_LOAN_RATE_SETTINGS]: (state: any, payload: any) => {
    return { ...state, approvedLoanRateSettings: payload.payload }
  },

  [types.EDIT_LOAN_RATE_SETTING]: (state: any, payload: any) => {
    const index = state.loanRateSettings.findIndex(
      (loanRateSetting: { id: any }) =>
        loanRateSetting.id === payload.payload.data.id
    )
    const newArray = [...state.loanRateSettings]
    newArray[index] = { ...payload.payload.data, status: 'Draft' }
    return {
      ...state,
      loans: newArray,
    }
  },

  [types.ADD_LOAN_RATE_SETTING]: (state: any, payload: any) => {
    return {
      ...state,
      loanRateSettings: [
        { ...payload.payload.data, status: 'Draft' },
        ...state.loanRateSettings,
      ],
    }
  },

  [types.SUBMIT_LOAN_RATE_SETTING]: (state: any, payload: any) => {
    const obj = state.loanRateSettings.find(
      (loanRateSetting: { id: any }) => loanRateSetting.id == payload.payload.id
    )
    obj.status = 'Submitted'
    return {
      ...state,
      loanRateSettings: [...state.loanRateSettings],
    }
  },

  [types.APPROVE_LOAN_RATE_SETTING]: (state: any, payload: any) => {
    const obj = state.loanRateSettings.find(
      (loanRateSetting: { id: any }) => loanRateSetting.id == payload.payload.id
    )
    obj.status = 'Approved'

    return {
      ...state,
      approvedLoanRateSettings: [...state.approvedLoanRateSettings, obj],
      loanRateSettings: [...state.loanRateSettings],
    }
  },

  [types.REJECT_LOAN_RATE_SETTING]: (state: any, payload: any) => {
    const obj = state.loanRateSettings.find(
      (loanRateSetting: { id: any }) => loanRateSetting.id == payload.payload.id
    )
    obj.status = 'Draft'
    return {
      ...state,
      loanRateSettings: [...state.loanRateSettings],
    }
  },

  [types.DELETE_LOAN_RATE_SETTING]: (state: any, payload: any) => {
    const index = state.loanRateSettings.findIndex(
      (loanRateSetting: { id: any }) => loanRateSetting.id == payload.payload.id
    )
    if (index >= 0) {
      state.loanRateSettings.splice(index, 1)
    }
    return {
      ...state,
      loanRateSettings: [...state.loanRateSettings],
    }
  },
  [types.START_LOADING_LOAN_RATE_SETTING]: (state: any) => {
    return { ...state, isLoading: true }
  },
  [types.STOP_LOADING_LOAN_RATE_SETTING]: (state: any) => {
    return { ...state, isLoading: false }
  },
})

export default loanRateSettingsReducer
