import { ServicingParams, ServicingTypes } from 'app/models/servicing-params'
import { ToDoParams } from 'app/models/todo-params'

const randomDT = new Date(new Date().getTime() + 6000000)
const randomEDT = new Date(new Date().getTime() + 600000000)

export const unscheduledFakeTable: ServicingParams[] = [
  {
    id: 'unschedule1',
    facilityName: 'Facility 1',
    stage: 'Pending Review',
    transaction: 'New Borrowing',
    status: 'Instructed',
    amount: 1000000,
    currency: 'USD',
    serviceTime: randomDT,
    admin: 'Craig',
    notices: 'Pending',
    effectiveDate: randomEDT,
    scheduled: 'scheduled',
    type: ServicingTypes.base,
    original: {},
  },
  {
    id: 'unschedule2',
    facilityName: 'Facility 2',
    stage: 'Pending Review',
    transaction: 'New Borrowing',
    status: 'Instructed',
    amount: 5500000,
    currency: 'USD',
    serviceTime: randomDT,
    admin: 'Bono',
    notices: 'Pending',
    effectiveDate: randomEDT,
    scheduled: 'unscheduled',
    type: ServicingTypes.base,
    original: {},
  },
]

export const paymentsFakeTable = [
  {
    id: 'payments1',
    borrower: 'Tesla Inc.',
    taskType: 'Interest Payment',
    amount: 3000000,
    currency: 'USD',
    effectiveDate: new Date(new Date().getTime() + 40000000),
    serviceTime: new Date(new Date().getTime() + 40000000),
    stage: 'Pending Review',
    admin: 'Simon',
  },
  {
    id: 'payments2',
    borrower: 'Tesla Inc.',
    taskType: 'Principal Payment',
    amount: 27000000,
    currency: 'USD',
    effectiveDate: new Date(new Date().getTime() + 50000000),
    serviceTime: new Date(new Date().getTime() + 50000000),
    stage: 'Pending Submit',
    admin: 'Alex',
  },
  {
    id: 'payments3',
    borrower: 'Tesla Inc.',
    taskType: 'Facility Fee',
    amount: 4400000,
    currency: 'USD',
    effectiveDate: new Date(new Date().getTime() + 300000000),
    serviceTime: new Date(new Date().getTime() + 300000000),
    stage: 'Pending Review',
    admin: 'Emily',
  },
  {
    id: 'payments4',
    borrower: 'Tesla Inc.',
    taskType: 'Principal Payment',
    amount: 7500000,
    currency: 'USD',
    effectiveDate: new Date(new Date().getTime() + 500000000),
    serviceTime: new Date(new Date().getTime() + 500000000),
    stage: 'Pending Approval',
    admin: 'Vinny',
  },
  {
    id: 'payments5',
    borrower: 'Tesla Inc.',
    taskType: 'Commitment Fee',
    amount: 7500000,
    currency: 'USD',
    effectiveDate: new Date(new Date().getTime() + 900000000),
    serviceTime: new Date(new Date().getTime() + 900000000),
    stage: 'Pending Approval',
    admin: 'Adam',
  },
]

export const scheduledFakeTable: ServicingParams[] = [
  {
    id: 'schedule2',
    facilityName: 'Facility 2',
    stage: 'Pending Submit',
    transaction: 'Rate Setting',
    status: 'Pending',
    amount: 500000,
    currency: 'USD',
    serviceTime: randomDT,
    admin: 'Bryan',
    notices: 'Released',
    effectiveDate: randomEDT,
    scheduled: 'scheduled',
    type: ServicingTypes.base,
    original: {},
  },
]

export const todoFakeTable: ToDoParams[] = [
  {
    id: 'todo1',
    taskType: 'Borrower Query',
    dueDate: new Date(new Date().getTime() + 150000000),
    timeDue: new Date(new Date().getTime() + 150000000),
    status: 'NewTask',
    priority: 'High',
    notes: 'Sent borrower last 6 months of accrues',
  },
  {
    id: 'todo2',
    taskType: 'Lender Query',
    dueDate: new Date(new Date().getTime() + 110000000),
    timeDue: new Date(new Date().getTime() + 110000000),
    status: 'InProgress',
    priority: 'High',
    notes: 'Lender inquery on position issue',
  },
  {
    id: 'todo3',
    taskType: 'Initial Query',
    dueDate: new Date(new Date().getTime() + 150000000),
    timeDue: new Date(new Date().getTime() + 150000000),
    status: 'Completed',
    priority: 'Low',
    notes: 'Review credit agreement for Deal Closing next week',
  },
  {
    id: 'todo4',
    taskType: 'Deliverable',
    dueDate: new Date(new Date().getTime() + 1000000000),
    timeDue: new Date(new Date().getTime() + 1000000000),
    status: 'Completed',
    priority: 'Low',
    notes: 'Complete payment review for Bill',
  },
]
