import React from 'react';
import { Flex, Grid, TextInput } from '@mantine/core';
import IonIcon from '@reacticons/ionicons';

interface LevelFieldProps {
    cpIndex: number;
    levelValue: string;
    handleLevelChange: (newValue: string | null) => void;
    handleRemoveLevel: () => void;
}

export const LevelField: React.FC<LevelFieldProps> = ({
    cpIndex,
    levelValue,
    handleLevelChange,
    handleRemoveLevel,
}) => {
    return (
        <div key={cpIndex}>
            <Flex direction={'column'}>
                <Grid
                    grow
                    style={{
                        width: '101%',
                        height: '25%',
                        paddingBottom: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '0%',
                    }}
                >
                    <Grid.Col span={5}>
                        <TextInput
                            w="100%"
                            label={`Level ${cpIndex + 1}`}
                            placeholder="Enter Rating Information"
                            withAsterisk
                            onChange={(event) => {
                                handleLevelChange(event.target.value);
                            }}
                            value={levelValue}
                        />
                    </Grid.Col>
                    {cpIndex !== 0 && (
                        <Grid.Col span={4} style={{ paddingRight: '5%', marginTop: '1%' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <IonIcon name="trash-outline" className="click-ion-icon" style={{ width: '20px', height: '20px', color: "red" }}
                                    onClick={handleRemoveLevel} />
                                <span
                                    style={{
                                        fontFamily: 'Plus Jakarta Sans',
                                        color: '#111928',
                                        fontSize: '12px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Remove Level
                                </span>
                            </div>
                        </Grid.Col>
                    )}
                </Grid>
            </Flex>
        </div>
    );
};