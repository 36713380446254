import {
  Group,
  Select,
  Stack,
  Table,
} from '@mantine/core'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'
import React, { useState } from 'react'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import { useDispatch, useSelector } from 'react-redux'
import { simulate } from 'app/state/ducks/simulations/thunks'
import simInitSteps from './sim-init-steps'
import { simDeal } from './sim-deal-data'
import { simFacility } from './sim-facility-data'
import { saveDeal, submitForApproval as dealSubmission, approveSubmittedDeal as dealApproval } from 'app/state/ducks/deals/thunks'
import { saveFacility, submitForApproval as facilitySubmission, approveSubmittedFacility as facilityApproval } from 'app/state/ducks/facilities/thunks'
import { getBusinessDateInfo } from 'app/state/ducks/business-date/selectors'
import { nextBusinessDate } from 'app/state/ducks/business-date/thunks'


export default function SimulationsHeader(): JSX.Element {
  const dispatch = useDispatch()
  const [simSteps, setSimSteps] = useState(simInitSteps())
  const businessDateInfo = useSelector(getBusinessDateInfo)

  const simDataRef: any = {
    deal: simDeal,
    facility: simFacility
  }

  const simMethodRef: any = {
    saveDeal: (param: any) => dispatch(saveDeal(param)),
    dealSubmission: (param: any) => dispatch(dealSubmission(param)),
    dealApproval: (param: any) => dispatch(dealApproval(param)),
    saveFacility: (param: any) => dispatch(saveFacility(param)),
    facilitySubmission: (param: any) => dispatch(facilitySubmission(param)),
    facilityApproval: (param: any) => dispatch(facilityApproval(param))
  }

  function nextBD(): void {
    dispatch(nextBusinessDate(businessDateInfo))
  }

  function submitSimulate(): void {
    const simUpdated = simSteps.map(step => {
      return {
        name: step.name,
        body: simDataRef[step.body],
        methodName: step.method,
        method: simMethodRef[step.method],
        delay: step.delay,
        payload: null,
        reference: step.reference
      }
    })
    dispatch(simulate(simUpdated))
  }

  const methodDropdown = [
    { value: 'saveDeal', label: 'Create Deal' },
    { value: 'dealSubmission', label: 'Submit Deal' },
    { value: 'dealApproval', label: 'Approve Deal' },
    { value: 'saveFacility', label: 'Create Facility' },
    { value: 'facilitySubmission', label: 'Submit Facility' },
    { value: 'facilityApproval', label: 'Approve Facility' },
  ]

  const handleInputChange = (
    stepIndex: number,
    field: string,
    value: string
  ) => {
    const updatedSteps: any = [...simSteps]
    updatedSteps[stepIndex][field] = value
    setSimSteps(updatedSteps)
  }

  const updateBody = (value: string, stepIndex: number, field: string) => {
    const updatedSteps: any = [...simSteps]
    updatedSteps[stepIndex][field] = value
    setSimSteps(updatedSteps)
  }

  const deleteRow = (index: number) => {
    const updatedSteps = [...simSteps];
    updatedSteps.splice(index, 1);
    setSimSteps(updatedSteps);
  }

  const addRow = (index: number) => {
    const newStep = {
      name: 'New Step',
      body: '',
      method: '',
      delay: '0',
      payload: null,
      reference: '',
    };

    const updatedSteps = [...simSteps];
    updatedSteps.splice(index + 1, 0, newStep);
    setSimSteps(updatedSteps);
  }

  const selectSimBody = (val: string, stepIndex: number) => {
    return (
      <Select
        size="xs"
        clearable
        placeholder="Data Source"
        data={
          [
            { value: 'deal', label: 'Deal' },
            { value: 'facility', label: 'Facility' }
          ]
        }
        value={val}
        onChange={value => updateBody(value ?? '', stepIndex, 'body')}
      />
    )
  }

  const selectSimMethod = (val: string, stepIndex: number) => {
    return (
      <Select
        size="xs"
        clearable
        placeholder="Data Source"
        data={methodDropdown}
        value={val}
        onChange={value => updateBody(value ?? '', stepIndex, 'method')}
      />
    )
  }

  const rows = simSteps.map((step, step_i) => (
    <tr key={'sim_step_' + step_i}>
      <td style={{ width: '5%' }}>{step_i}</td>
      <td style={{ width: '17%' }}>
        <input
          value={step.name}
          onChange={e => handleInputChange(step_i, 'name', e.target.value)}
        />
      </td>
      <td style={{ width: '17%' }}>{selectSimMethod(step.method, step_i)}</td>
      <td style={{ width: '17%' }}>{selectSimBody(step.body, step_i)}</td>
      <td style={{ width: '17%' }}>
        <input
          disabled={step_i === 0}
          value={step.reference}
          onChange={e => handleInputChange(step_i, 'reference', e.target.value)}
        />
      </td>
      <td style={{ width: '17%' }}>
        <input
          value={step.delay}
          onChange={e => handleInputChange(step_i, 'delay', e.target.value)}
        />
      </td>
      <td style={{ width: '5%' }}>
        <button onClick={() => addRow(step_i)}>Add Row</button>
      </td>
      <td style={{ width: '5%' }}>
        <button onClick={() => deleteRow(step_i)}>Delete</button>
      </td>
    </tr>
  ))

  return (
    <>
      <Stack>
        <Group position="apart" align="center">
          <PageTitle pageTitle={'Simulations'} />
        </Group>
      </Stack>

      <Stack>
        <Table>
          <thead>
            <tr>
              <th>I</th>
              <th>Name</th>
              <th>Method</th>
              <th>Body</th>
              <th>Reference</th>
              <th>Delay</th>
              <th>Add Row</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </Stack>
      <PrimaryButton onClick={submitSimulate}>Simulate</PrimaryButton>
      <PrimaryButton onClick={nextBD}>Next BD</PrimaryButton>
    </>
  )
}
