import * as types from './types'
import { ToDoParams } from 'app/models/todo-params'
import { createReducer } from 'app/state/utils'


export interface ToDoState {
  todos: ToDoParams[]
  todo?: ToDoParams
  isLoading: boolean
}

const initialState: ToDoState = {
  todos: [],
  todo: undefined,
  isLoading: false,
}


const todosReducer = createReducer(initialState)({
  [types.GET_TODOS]: (state: any, payload: any) => {
    return { ...state, todos: payload.payload }
  },
  [types.GET_TODO]: (state: any, payload: any) => {
    return { ...state, todo: payload.payload }
  },
  [types.ADD_TODOS]: (state: any, payload: any) => {
    return {
      ...state,
      todos: [payload.payload.data, ...state.todos],
    }
  },
  [types.EDIT_TODOS]: (state: any, payload: any) => {
    const index = state.todos.findIndex(
      (todo: { id: any }) => todo.id === payload.payload.data.id
    )
    const newArray = [...state.todos]
    newArray[index] = { ...payload.payload.data }
    return {
      ...state,
      todos: [...newArray],
      todo: { ...payload.payload.data },
    }
  },
  [types.DELETE_TODOS]: (state: any, payload: any) => {
    const filteredTodos = state.todos.filter((todo: any) => {
      return todo.id !== payload.payload[0].id
    })

    return {
      ...state,
      todos: filteredTodos,
    }
  },
  [types.START_LOADING_TODOS]: (state: ToDoState) => {
    return { ...state, isLoading: true }
  },
  [types.STOP_LOADING_TODOS]: (state: ToDoState) => {
    return { ...state, isLoading: false }
  },
})

export default todosReducer
