import { Group, Stack, Title } from '@mantine/core'
import { ReactNode } from 'react'

interface Props {
  pageTitle?: string
  children?: ReactNode
}

export default function PageTitle({ pageTitle, children }: Props) {
  return (
    <>
      {pageTitle ?
        <Stack w="100%">
          <Group noWrap w="100%" position='apart'>
            <Title
              order={1}
              fw={600}
              className='pageTitleUpdated'
            >
              {pageTitle ?? ''}
            </Title>
            {children ?? ''}
          </Group>
          <Group noWrap w="100%" className='pageTitleGap'>
          </Group>
        </Stack>
        :
        <Title
          order={1}
          fw={600}
          style={{
            fontSize: '3rem',
            fontFamily: 'Plus Jakarta Sans',
            color: '#001565',
          }}
        >
          {pageTitle ?? children ?? ''}
        </Title>
      }

    </>
  )

}