import { InterestRateParams } from 'app/models/interest-rate'
import * as types from './types'
import { createReducer } from 'app/state/utils'

export interface InterestRateState {
  interestRates: InterestRateParams[]
}
const initialState: InterestRateState = { interestRates: [] }

const interestRatessReducer = createReducer(initialState)({
  [types.GET_RATES]: (state: any, payload: any) => {
    return { ...state, interestRates: payload.payload }
  },
  [types.ADD_RATES]: (state: any, payload: any) => {
    return {
      ...state,
      interestRates: [payload.payload.data, ...state.interestRates],
    }
  },
  [types.SIMULATE_API_FOR_INDEX]: (state: any, payload: any) => {
    return {
      ...state,
      interestRates: [...payload.payload, ...state.interestRates],
    }
  },
  [types.EDIT_RATES]: (state: any, payload: any) => {
    const index = state.interestRatess.findIndex(
      (interestRates: { id: any }) =>
        interestRates.id === payload.payload.data.id
    )
    const newArray = [...state.interestRatess]
    newArray[index] = payload.payload.data
    return {
      ...state,
      interestRatess: newArray,
    }
  },
})

export default interestRatessReducer
