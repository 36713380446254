import React, { useState, useEffect } from 'react'
import SegmentControlUpdated from 'app/views/components/segments/segment-control-updated'
import { Group, Stack } from '@mantine/core'
import { ToDoModal } from './todo/todo-modal'
import { useSelector } from 'react-redux'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import CustomDatePicker from 'app/views/components/date-picker-updated/date-picker-logic'
import PrimaryInput from 'app/views/components/buttons/PrimaryInput'
import { ToDoPage } from './todo-page'
import { PaymentsPage } from './payments-page'
import { UnscheduledPage } from './unscheduled-page'
import { ScheduledPage } from './scheduled-page'
import { useDispatch } from 'react-redux'
import { loadBusinessDate } from 'app/state/ducks/business-date/thunks'


interface props {
  tab: string
}

//This keeps the date set when navigating between pages
let setDate: Date;

export function MyWorkDrillPage({ tab }: props) {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const businessDate = useSelector(getBusinessDate)
  const [dateFilter, setDateFilter] = useState<Date | null>(setDate ?? businessDate)

  useEffect(() => {
    dispatch(loadBusinessDate())
  }, [dispatch])

  useEffect(() => {
    setDateFilter(setDate ?? businessDate);
  }, [businessDate]);

  const tabs: { [key in props['tab']]: { route: string; label: string; active: boolean; pageRender: JSX.Element } } = {
    todo: { route: '/mywork/todo', label: 'TO DO LIST', active: tab === 'todo' ? true : false, pageRender: <ToDoPage date={businessDate} /> },
    payments: { route: '/mywork/payments', label: 'PAYMENTS', active: tab === 'payments' ? true : false, pageRender: <PaymentsPage date={dateFilter || businessDate} /> },
    unscheduled: {
      route: '/mywork/unscheduled',
      label: 'UNSCHEDULED',
      active: tab === 'unscheduled' ? true : false,
      pageRender: <UnscheduledPage date={dateFilter || businessDate} />
    },
    scheduled: { route: '/mywork/scheduled', label: 'SCHEDULED', active: tab === 'scheduled' ? true : false, pageRender: <ScheduledPage date={dateFilter || businessDate} /> },
  }

  return (
    <>
      <Stack w="100%">
        {/* this group is for segment control, gap, and calendar & Activity */}
        <Group w="100%" position="apart" className='tabsGapsActivity'>
          <Group position="right">
            <SegmentControlUpdated tabs={tabs} />
          </Group>
          <Group noWrap>
            {
              tab !== "todo" && <CustomDatePicker
                w="175px"
                label={''}
                date={dateFilter ?? businessDate}
                setDate={(value: any) => {
                  setDate = value
                  setDateFilter(value)
                }}
                holidayCalendars={[]}
              />
            }
            <PrimaryInput className='activityButton' onClick={() => setOpen(true)}>Create To Do</PrimaryInput>
          </Group>
        </Group>
        {tabs[tab].pageRender}
      </Stack>
      <ToDoModal open={open} setOpen={setOpen} />
    </>
  )
}