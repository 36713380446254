import { LoanRolloverParams } from 'app/models/loan-rollover-params';
import * as types from './types'
import { createReducer } from 'app/state/utils'

export interface LoanRolloverState {
  approvedLoanRollovers: LoanRolloverParams[]
  loanRollovers: LoanRolloverParams[]
  isLoading: boolean
}

const initialState: LoanRolloverState = {
  approvedLoanRollovers: [],
  loanRollovers: [],
  isLoading: false,
}

const loanRolloversReducer = createReducer(initialState)({
  [types.GET_LOAN_ROLLOVERS]: (state: any, payload: any) => {
    return { ...state, loanRollovers: payload.payload }
  },

  [types.GET_APPROVED_LOAN_ROLLOVERS]: (state: any, payload: any) => {
    return { ...state, approvedLoanRollovers: payload.payload }
  },

  [types.EDIT_LOAN_ROLLOVER]: (state: any, payload: any) => {
    const index = state.loanRollovers.findIndex(
      (loanRollover: { id: any }) => loanRollover.id === payload.payload.data.id
    )
    const newArray = [...state.loanRollovers]
    newArray[index] = { ...payload.payload.data, status: 'Draft' }
    return {
      ...state,
      loanRollovers: newArray,
    }
  },

  [types.ADD_LOAN_ROLLOVER]: (state: any, payload: any) => {
    return {
      ...state,
      loanRollovers: [
        { ...payload.payload.data, status: 'Draft' },
        ...state.loanRollovers,
      ],
    }
  },

  [types.SUBMIT_LOAN_ROLLOVER]: (state: any, payload: any) => {
    const obj = state.loanRollovers.find(
      (loanRollover: { id: any }) => loanRollover.id == payload.payload.id
    )
    obj.status = 'Submitted'
    return {
      ...state,
      loanRollovers: [...state.loanRollovers],
    }
  },

  [types.APPROVE_LOAN_ROLLOVER]: (state: any, payload: any) => {
    const obj = state.loanRollovers.find(
      (loanRollover: { id: any }) => loanRollover.id == payload.payload.id
    )
    obj.status = 'Approved'

    return {
      ...state,
      approvedLoanRollovers: [...state.approvedLoanRollovers, obj],
      loanRollovers: [...state.loanRollovers],
    }
  },

  [types.REJECT_LOAN_ROLLOVER]: (state: any, payload: any) => {
    const obj = state.loanRollovers.find(
      (loanRollover: { id: any }) => loanRollover.id == payload.payload.id
    )
    obj.status = 'Draft'
    return {
      ...state,
      loanRollovers: [...state.loanRollovers],
    }
  },

  [types.DELETE_LOAN_ROLLOVER]: (state: any, payload: any) => {
    const index = state.loanRollovers.findIndex(
      (loanRollover: { id: any }) => loanRollover.id == payload.payload.id
    )
    if (index >= 0) {
      state.loanRollovers.splice(index, 1)
    }
    return {
      ...state,
      loanRollovers: [...state.loanRollovers],
    }
  },
  [types.START_LOADING_LOAN_ROLLOVER]: (state: any) => {
    return { ...state, isLoading: true }
  },
  [types.STOP_LOADING_LOAN_ROLLOVER]: (state: any) => {
    return { ...state, isLoading: false }
  },
})

export default loanRolloversReducer
