import { isNotEmpty, useForm } from '@mantine/form'
import { useDispatch, useSelector } from 'react-redux'
import { getFacility } from 'app/state/ducks/facilities/selectors'
import { useEffect, useState } from 'react'
import { LoanParams } from 'app/models/loan-params'
import {
  SelectItem,
} from '@mantine/core'
import moment from 'moment';
import {
  rateTypeOptions,
  Option,
} from 'app/models/dropdown-options'
import {
  formatDateToUTC,
  roundTo, stringToDate,
} from 'app/utils/util-functions'
import {
  saveLoanRollover,
} from 'app/state/ducks/loan-rollovers/thunks'
import { getLoanRollovers } from 'app/state/ducks/loan-rollovers/selectors'
import { LoanRolloverParams } from 'app/models/loan-rollover-params'
import config from 'app/config/config'
import { loadOneFacility } from 'app/state/ducks/facilities/thunks'
import { ContractPeriod, IroValues } from 'app/models/facility-params'
import axios from 'axios'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'
import { getIndexRateOptions } from 'app/state/ducks/index-rate-options/selectors'
import { getInterestRatess } from 'app/state/ducks/interest-rates/selectors'
import { loadInterestByIndex } from 'app/state/ducks/interest-rates/thunks'
import { getInterestForPeriod } from 'app/models/interest-rate'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import RolloverFormPresentation from './rollover-form-presentation'

type Props = {
  close: any
  loan: LoanParams
}

export default function RolloverFormLogic({ close, loan }: Props) {
  const dispatch = useDispatch()

  const facility = useSelector(getFacility)
  const loanRollover = useSelector(getLoanRollovers)
  const allIndexes = useSelector(getIndexRateOptions)
  const dailyRates = useSelector(getInterestRatess)
  const businessDate = useSelector(getBusinessDate)

  const [facilityindexOptions, setFacilityIndexOptions] = useState<Option[]>([])
  const [iro, setIro] = useState<IroValues | undefined>(undefined)
  const [contractPeriod, setContractPeriod] = useState<ContractPeriod>()
  const [isContractPeriodDisabled, setIsContractPeriodDisabled] = useState<boolean>(false)
  const [facilityContractPeriodOptions, setFacilityContractPeriodOptions] =
    useState<SelectItem[]>([])

  const rollover = loanRollover.find(lr => lr?.loanId?.id === loan?.id) ?? undefined

  useEffect(() => {
    if (rollover) {
      const formRollover = {
        ...rollover,
        interestRateOption: rollover.interestRateOption.id,
        allInRate: Number(rollover.allInRate),
        amount: Number(rollover.amount),
        pikPercentage: rollover.pikPercentage ? Number(rollover.pikPercentage) : 0.00000,
        interestBaseRate: Number(rollover.interestBaseRate),
        margin: Number(rollover.margin),
        roundedBaseRate: Number(rollover.roundedBaseRate),
        startDate: stringToDate(rollover.startDate),
        maturity: rollover.maturity
          ? stringToDate(rollover.maturity)
          : businessDate,
      }
      if (rollover.pikPercentage) {
        const pikAmount = loan?.accrualInterestMaturityDate * (formRollover.pikPercentage / 100)
        const principlePaymentAmount = loan?.accrualInterestMaturityDate - pikAmount
        form.setFieldValue('pikAmount', pikAmount)
        form.setFieldValue('principlePaymentAmount', principlePaymentAmount)
        const amount = Number(loan.amount) + pikAmount
        form.setFieldValue('amount', amount)
      }
      form.setValues(formRollover)
    } else {
      const cleanForm = {
        id: undefined,
        loanId: { admin: loan.agencyAdmin, id: loan.id },
        contractPeriod: null,
        currency: loan.currency,
        pikPercentage: 0.00000,
        amount:
          facility?.pikOption === 'PIK_All'
            ? Number(loan.amount) + Number(loan.accrualInterestMaturityDate)
            : 0.0,
        startDate: stringToDate(loan.endDate),
        maturity: null,
        interestRateOption: loan.indexOption.id,
        rounding: null,
        dayBasis: null,
        interestBaseRate: '',
        roundedBaseRate: '',
        margin: '',
        allInRate: '',
        status: 'Draft',
      }
      form.setValues(cleanForm)
    }
  }, [rollover])


  useEffect(() => {
    if (!facility) {
      return
    }
    const indexRateOptions = facility.iroValues.find(
      iro => iro.indexOption.id == form.values.interestRateOption
    )
    setIro(indexRateOptions)
    if (!indexRateOptions) {
      return
    }
    if (form.values.rounding === '' || form.values.rounding === null) {
      form.setFieldValue('rounding', indexRateOptions.rounding)
    }
    if (
      form.values.margin === '' ||
      form.values.margin === 0 ||
      form.values.margin === null
    ) {
      form.setFieldValue('margin', Number(indexRateOptions.margin))
    }
    if (form.values.dayBasis === '' || form.values.dayBasis === null) {
      form.setFieldValue('dayBasis', indexRateOptions.dayBasis)
    }
    const selectedContractPeriod = indexRateOptions.contractPeriod.find(
      cp => cp.contractPeriod == form.values.contractPeriod
    )
    setContractPeriod(selectedContractPeriod)
    if (!rollover) {
      calculateInterests(indexRateOptions, selectedContractPeriod, form.values.startDate)
    }
  }, [facility, rollover])

  useEffect(() => {
    dispatch(loadOneFacility(loan.facilityId.id, 'Approved'))
  }, [])

  // set interest on edit
  useEffect(() => {
    if (!dailyRates || form.values.interestBaseRate > 0.0) {
      return
    }

    calculateInterests(iro, contractPeriod, form.values.startDate)
  }, [dailyRates, rollover])

  useEffect(() => {
    if (!allIndexes || allIndexes.length == 0 || !facility) {
      return
    }
    const facilityIndexes = [...new Set(facility?.iroValues.map(iro => {
      return allIndexes.find(option => option.id === iro.indexOption.id)
    }))]


    setFacilityIndexOptions(
      facilityIndexes.map(indexOption => ({
        value: indexOption?.id ?? '',
        label: indexOption?.indexOption ?? '',
      })) ?? []
    )


    const selectedIndex = allIndexes.find(
      index => index.id == form.values.interestRateOption
    )
    if (selectedIndex) {
      dispatch(loadInterestByIndex(selectedIndex))
      setIsContractPeriodDisabled(selectedIndex.indexType !== 'TermIndex')
    }
  }, [allIndexes, facility])

  useEffect(() => {
    if (!loan) {
      return
    }
    const filteredIros = facility?.iroValues.find(
      iro =>
        iro.indexOption.id == form.values.interestRateOption &&
        form.values.currency === iro.currency
    )
    if (!filteredIros) {
      return
    }
    const contractPeriods = filteredIros.contractPeriod.map(
      iro => iro.contractPeriod
    )
    const filteredContractPeriodOptions = rateTypeOptions.filter(option =>
      contractPeriods.includes(option.value)
    )
    setFacilityContractPeriodOptions(filteredContractPeriodOptions)
  }, [facility, loan])

  const form: any = useForm({
    initialValues: {
      id: rollover?.id ?? undefined,
      loanId: { admin: loan.agencyAdmin, id: loan.id },
      contractPeriod: rollover?.contractPeriod ?? null,
      currency: rollover?.currency ?? loan.currency,
      amount:
        Number(rollover?.amount) ?? facility?.pikOption === 'PIK_All'
          ? Number(loan.amount) + Number(loan.accrualInterestMaturityDate)
          : 0.0,
      startDate: rollover?.startDate
        ? stringToDate(rollover?.startDate)
        : stringToDate(loan.endDate),
      maturity: rollover?.maturity ? stringToDate(rollover?.maturity) : null,
      interestRateOption: rollover?.interestRateOption?.id ?? loan.indexOption?.id,
      rounding: rollover?.rounding ?? null,
      dayBasis: rollover?.dayBasis ?? null,
      interestBaseRate: Number(rollover?.interestBaseRate) ?? null,
      roundedBaseRate: Number(rollover?.roundedBaseRate) ?? null,
      margin: Number(rollover?.margin) ?? null,
      allInRate: Number(rollover?.allInRate) ?? null,
      status: rollover?.status ?? 'Draft',
      withPik: rollover?.withPik ?? facility?.pikOption === 'PIK_All',
      pikPercentage: rollover?.pikPercentage ? Number(rollover.pikPercentage) : 0.00000,
      hasError: false,
      ErrorMessage: '',
      approveRate: false,
      hasSuccessfulEntitySave: false,
      customer_token: '',
    },
    transformValues: values => {
      const indexOption = allIndexes.find(
        option => option.id === values.interestRateOption
      )
      return {
        ...values,
        facilityId: {
          id: facility?.id,
          admin: facility?.accountManagementAdmin,
        },
        rounding: values.rounding != '' ? values.rounding : null,
        dayBasis: values.dayBasis != '' ? values.dayBasis : null,
        interestRateOption: {
          id: indexOption?.id ?? '',
          admin: indexOption?.public ?? ''
        },
        contractPeriod:
          values.contractPeriod != '' ? values.contractPeriod : null,
      }
    },
    validate: {
      amount: (value: number) => (value > 0 ? null : 'Invalid amount'),
      status: (value: string) =>
        value === 'Draft' ? null : 'Has already been submitted',
      maturity: (value: any) =>
        isNotEmpty(value)
          ? value > form.values.startDate
            ? null
            : 'Maturity has to be after Start Date'
          : 'Maturity is required',
      startDate: (value: any) =>
        form.values.maturity > value
          ? null
          : 'Maturity has to be after Start Date',
    },
  })

  const resetInterest = () => {
    form.setFieldValue('interestBaseRate', '')
    form.setFieldValue('roundedBaseRate', '')
    form.setFieldValue('allInRate', '')
    form.clearFieldError('contractPeriod')
    form.clearFieldError('startDate')
  }

  const handleWithPikChange = (event: any) => {
    if (facility?.pikOption === 'PIK_All') {
      return
    }
    form.setFieldValue('withPik', event.target.checked)
    form.setFieldValue('pikPercentage', 0)
    form.setFieldValue('pikAmount', 0)
    form.setFieldValue('principlePaymentAmount', 0)
    form.setFieldValue(
      'amount',
      event.target.checked
        ? Number(loan.amount) + Number(form.values.pikAmount)
        : 0.0
    )
  }

  const changeIndexOption = (event: string | null) => {
    if (!event) {
      return
    }

    const selectedIndex = allIndexes.find(index => index.id == event)
    if (selectedIndex) {
      dispatch(loadInterestByIndex(selectedIndex))
      setIsContractPeriodDisabled(selectedIndex.indexType !== 'TermIndex')
    }
    form.setFieldValue('interestRateOption', event)
    form.setFieldValue('casValue', 0)
    form.setFieldValue('contractPeriod', '')
    setContractPeriod(undefined)

    const filteredIros = facility?.iroValues.find(
      iro => iro.indexOption.id == event && form.values.currency === iro.currency
    )
    if (!filteredIros) {
      form.setFieldValue('rounding', '')
      form.setFieldValue('margin', 0)
      form.setFieldValue('dayBasis', '')
      resetInterest()
      return
    }
    form.setFieldValue('rounding', filteredIros.rounding)
    form.setFieldValue('margin', Number(filteredIros.margin))
    form.setFieldValue('dayBasis', filteredIros.dayBasis)
    setIro(filteredIros)

    const contractPeriods = filteredIros.contractPeriod.map(
      cp => cp.contractPeriod
    )
    const filteredContractPeriodOptions = rateTypeOptions.filter(option =>
      contractPeriods.includes(option.value)
    )
    if (selectedIndex?.indexType !== 'TermIndex') {
      form.setFieldValue('contractPeriod', null)
      form.setFieldValue('endDate', stringToDate((facility?.maturity ?? '')))
      calculateNonTermInterests(filteredIros, form.values.startDate)
    } else {
      setFacilityContractPeriodOptions(filteredContractPeriodOptions)
      resetInterest()
    }
  }

  const changeContractPeriod = (event: string | null) => {
    if (!event) {
      return
    }
    form.setFieldValue('contractPeriod', event)
    if (!iro) {
      return
    }

    const contractWitCass = iro.contractPeriod.find(
      cp => cp.contractPeriod == event
    )
    if (!contractWitCass) {
      return
    }

    setContractPeriod(contractWitCass)
    if (!iro) {
      form.setFieldError(
        'contractPeriod',
        'Not found Index rate option for given contract period'
      )
      return
    }
    form.setFieldValue('casValue', Number(contractWitCass.cas))
    calculateTermInterests(iro, contractWitCass, form.values.startDate)
  }

  const calculateInterests = (
    iro: IroValues | undefined,
    contractPeriodWitCas: ContractPeriod | undefined,
    date: any
  ) => {
    const selectedIndex = allIndexes.find(index => index.id == iro?.indexOption.id)
    if (selectedIndex?.indexType === 'TermIndex') {
      calculateTermInterests(iro, contractPeriodWitCas, date)
      return
    }
    calculateNonTermInterests(iro, date)
  }

  const calculateTermInterests = (
    iro: IroValues | undefined,
    contractPeriodWitCas: ContractPeriod | undefined,
    date: any
  ) => {
    resetInterest()
    if (!iro || !contractPeriodWitCas) {
      return
    }

    const iroSelected = dailyRates.find(
      index => index.effectiveDate === formatDateToUTC(date)
    )

    if (!iroSelected) {
      form.setFieldError(
        'startDate',
        'Not found Index rate option for given start date'
      )
      return
    }

    const interestBaseRate = iroSelected.termRates
      ? getInterestForPeriod(
        iroSelected.termRates,
        contractPeriodWitCas.contractPeriod
      )
      : 0.0
    if (!interestBaseRate) {
      form.setFieldError(
        'contractPeriod',
        'Not found interest for given contract period'
      )
      form.setFieldError(
        'startDate',
        'Not found Index rate option for given start date'
      )
      return
    }
    const interestWithRounding = Number(
      roundTo(Number(interestBaseRate), iro.rounding) ?? 0.0
    )
    form.setFieldValue('interestBaseRate', Number(interestBaseRate))
    form.setFieldValue('roundedBaseRate', interestWithRounding)
    form.setFieldValue(
      'allInRate',
      interestWithRounding +
      Number(iro.margin) +
      Number(contractPeriodWitCas.cas)
    )
  }

  const createDraftRollover = async () => {
    try {
      const indexOption = allIndexes.find(
        option => option.id === form.values.interestRateOption
      )
      const loanRolloverVals: LoanRolloverParams = {
        ...form.values,
        interestRateOption: {
          id: indexOption?.id ?? '',
          admin: indexOption?.public ?? ''
        },
        maturity: formatDateToUTC(form.values.maturity),
        startDate: formatDateToUTC(form.values.startDate),
        hasError: false,
        hasSuccessfulEntitySave: false,
        ErrorMessage: '',
        customer_token: config.company.toUpperCase(),
      }
      const response: any = await dispatch(
        saveLoanRollover(loanRolloverVals, rollover)
      )
      if (response.success) {
        SuccessNotification({
          title: 'Successfully Saved Draft Loan Rollover',
          message: 'You created or edited a Loan Rollover',
        })
      } else {
        ErrorNotification({
          title: 'Failed to Create Rollover',
          message: response.payload ?? 'Please check inputs',
        })
      }
      return response
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response?.status == 400) {
        const data: any = error.response.data
        ErrorNotification({
          title: 'Failed to Create Rollover',
          message: data?.error ?? 'Please check inputs',
        })
        return null
      } else {
        ErrorNotification({
          title: 'Failed to Create Rollover',
          message: error,
        })
        return null
      }
    } finally {
      form.reset()
    }
  }


  function calculateNonTermInterests(
    iro: IroValues | undefined,
    date: string,
  ) {
    resetInterest();
    if (!iro) {
      return;
    }

    const indexRateSelected = allIndexes.find(index => index.id === iro.indexOption.id);
    const startDate = date;
    dailyRates.sort((a, b) => moment(a.effectiveDate).diff(moment(b.effectiveDate)));

    const iroSelected = [...dailyRates].reverse().find(rate => moment(rate.effectiveDate).isBefore(startDate));

    if (!iroSelected && indexRateSelected?.indexType !== 'FixedIndex') {
      form.setFieldError('indexOption', 'Not found Index rate option for given index option');
      return;
    }

    let interestBaseRate = ''
    if (indexRateSelected?.indexType === 'FixedIndex') {
      interestBaseRate = iro.fixedRate + ''
    }
    else if (iroSelected?.overnightRate) {
      interestBaseRate = iroSelected.overnightRate
    }
    else if (iroSelected?.floatingRate) {
      interestBaseRate = iroSelected.floatingRate
    }

    const interestWithRounding = roundTo(Number(interestBaseRate), iro.rounding ?? 0) ?? 0

    form.setFieldValue('interestBaseRate', Number(interestBaseRate));
    form.setFieldValue('roundedBaseRate', Number(interestWithRounding));
    form.setFieldValue('allInRate', Number(interestWithRounding) + (Number(iro.margin) ?? 0))
  };

  function onInterestBaseRateChange(value: number | ''): void {
    form.setFieldValue('interestBaseRate', value)
    if (form.values.rounding) {
      const interestWithRounding = Number(
        roundTo(Number(value), form.values.rounding)
      )
      form.setFieldValue('roundedBaseRate', interestWithRounding)
    }
    form.setFieldValue(
      'allInRate',
      Number(value) +
      Number(form.values.margin ?? 0) +
      Number(form.values.casValue ?? 0)
    )
  }

  function onMarginChange(value: number | ''): void {
    form.setFieldValue('margin', value)
    form.setFieldValue(
      'allInRate',
      Number(form.values.interestBaseRate ?? 0) +
      Number(value ?? 0) +
      Number(form.values.casValue ?? 0)
    )
  }

  return (
    <RolloverFormPresentation close={close} form={form} facility={facility} loan={loan} rollover={rollover} iro={iro} contractPeriod={contractPeriod} calculateInterests={calculateInterests} facilityindexOptions={facilityindexOptions} facilityContractPeriodOptions={facilityContractPeriodOptions} changeIndexOption={changeIndexOption} handleWithPikChange={handleWithPikChange} changeContractPeriod={changeContractPeriod} onInterestBaseRateChange={onInterestBaseRateChange} createDraftRollover={createDraftRollover} onMarginChange={onMarginChange} isContractPeriodDisabled={isContractPeriodDisabled} />
  )
}