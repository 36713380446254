import { ContractPeriod, IroValues } from 'app/models/facility-params';
import { formatDateToUTC, roundTo } from 'app/utils/util-functions'
import { resetInterest } from './reset-interest';
import { InterestRateParams, getInterestForPeriod } from 'app/models/interest-rate'
import { LoanParams } from 'app/models/loan-params';
import { Form } from './interfaces';

export const calculateTermInterests = (
  form: Form,
  loan: LoanParams | undefined,
  dailyRates: InterestRateParams[],
  iro: IroValues | undefined,
  contractPeriodWitCas: ContractPeriod | undefined,
  startDate: Date
): void => {
  resetInterest(form, loan);
  if (!iro || !contractPeriodWitCas) {
    return;
  }
  const iroSelected = dailyRates.find(index => index.effectiveDate === formatDateToUTC(startDate));
  if (!iroSelected) {
    form.setFieldError('contractPeriod', 'Not found Index rate option for given contract period');
    form.setFieldError('startDate', 'Not found Index rate option for given start date');
    return;
  }
  const interestBaseRate = getInterestForPeriod(iroSelected.termRates, contractPeriodWitCas.contractPeriod) ?? 0.0;

  if (!interestBaseRate) {
    form.setFieldError('contractPeriod', 'Not found interest for given contract period');
    form.setFieldError('startDate', 'Not found Index rate option for given start date');
    return;
  }
  const interestWithRounding = roundTo(Number(interestBaseRate), iro.rounding);
  form.setFieldValue('interestBaseRate', Number(interestBaseRate));
  form.setFieldValue('interestBaseRateWithRounding', Number(interestWithRounding));
  form.setFieldValue('allInRate', Number(interestWithRounding) + Number(iro.margin) + Number(contractPeriodWitCas.cas));
};