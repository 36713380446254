import { Stack } from '@mantine/core'
import { LoanParams } from 'app/models/loan-params'
import { formatDateToUTC, formatNumberToCurrency, numberOfDaysBetweenTwoDates } from 'app/utils/util-functions'
import MainTable from 'app/views/components/Table/MainTable'
import { getColumnDefs } from './table-columnDefs-interest-payment-summary'

type Props = {
  interestDue: number
  currency: string
  loan: LoanParams
  paymentDate: Date
}

export default function InterestPaymentSummary({ interestDue, currency, loan, paymentDate }: Props) {

  return (
    <Stack w="100%" spacing={0}>
      <div
        style={{
          backgroundColor: '#A4CAFE',
          width: '100%',
          padding: '10px',
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold',
        }}
      >
        Interest Summary
      </div>
      <MainTable
        tableName='Loan History'
        csvExportEnabled={true}
        columnDefs={getColumnDefs()}
        data={[{
          'Start Date': loan.startDate,
          'End Date': formatDateToUTC(paymentDate),
          'Loan Amount': formatNumberToCurrency(Number(loan.amount), currency),
          'All-In Rate': loan.allInRate + "%",
          'Days': Math.round(numberOfDaysBetweenTwoDates(new Date(loan.startDate), new Date(paymentDate))),
          'Interest Amount': formatNumberToCurrency(interestDue, currency)
        }]}
        enableTopToolbar={false}
        headerBackgroundColor='#F0EEEE'
        setRow={() => {
        }}
        withPagination={false}
        minHeight={'100px'}
      />
    </Stack>

  )
}
