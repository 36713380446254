import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import {
  getLetterOfCredits,
  addLetterOfCredit,
  editLetterOfCredit,
  submitLetterOfCredit,
  approveLetterOfCredit,
  rejectLetterOfCredit,
  getApprovedLetterOfCredits,
  getAllApprovedLetterOfCredits,
  deleteLetterOfCredit,
  getLetterOfCredit,
  getLocHistory,
  startLoadingFacilityLettersOfCredit,
  stopLoadingFacilityLettersOfCredit,
} from './actions'
import { DraftLetterOfCreditParams } from 'app/models/letter-of-credit'

export const saveLetterOfCredit =
  (savedLetterOfCredit: DraftLetterOfCreditParams, letterOfCredit?: any) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    const updated_savedLetterOfCredit: DraftLetterOfCreditParams = {
      ...savedLetterOfCredit,
    }
    if (letterOfCredit) {
      return client
        .put(
          `${apiServerUrl}/api/draft-letter-of-credits`,
          updated_savedLetterOfCredit,
        )
        .then(response => {
          const payload = dispatch(editLetterOfCredit(response))
          return { success: true, payload: payload.payload }
        })
        .catch(e => {
          return { success: false, payload: e.response.data.error }
        })
    }

    return client
      .post(
        `${apiServerUrl}/api/draft-letter-of-credits`,
        updated_savedLetterOfCredit,
      )
      .then(response => {
        const payload = dispatch(addLetterOfCredit(response))
        return { success: true, payload: payload.payload }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const loadOneLetterOfCredit =
  (letterOfCreditId: string, status: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    const statusLower = status.toLowerCase()
    return client
      .get(
        `${apiServerUrl}/api/${statusLower}-letter-of-credits/one?id=${letterOfCreditId}`,
      )
      .then(letterOfCredit => {
        if (statusLower === 'submitted') {
          return dispatch(
            getLetterOfCredit({
              ...letterOfCredit.data.letterOfCredit,
              status: status,
            }),
          )
        }
        return dispatch(
          getLetterOfCredit({ ...letterOfCredit.data, status: status }),
        )
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const loadLetterOfCreditId =
  (letterOfCreditId: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .get(
        `${apiServerUrl}/api/approved-letter-of-credits/one?id=${letterOfCreditId}`,
      )
      .then(response => {
        return dispatch(getApprovedLetterOfCredits(response.data))
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const loadLetterOfCredits =
  (facilityId: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .get(
        `${apiServerUrl}/api/approved-letter-of-credits/facility?id=${facilityId}`,
      )
      .then(response => {
        return dispatch(getApprovedLetterOfCredits(response.data))
      })
      .catch(e => {
        console.log(e)
        return []
      })
  }
export const loadApprovedLetterOfCredits = async (facilityId?: string) => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(
      `${apiServerUrl}/api/approved-letter-of-credits${
        facilityId ? `/facility?id=${facilityId}` : ''
      }`,
    )
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

export const loadAllApprovedLetterOfCredits =
  () => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return await client
      .get(`${apiServerUrl}/api/approved-letter-of-credits`)
      .then(response => {
        return dispatch(getAllApprovedLetterOfCredits(response.data))
      })
      .catch(e => {
        console.log(e)
        return []
      })
  }

export const loadDraftLetterOfCredits = async (facilityId?: string) => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(
      `${apiServerUrl}/api/draft-letter-of-credits${
        facilityId ? `/facility?id=${facilityId}` : ''
      }`,
    )
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

export const loadSubmittedLetterOfCredits = async (facilityId?: string) => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(
      `${apiServerUrl}/api/submitted-letter-of-credits${
        facilityId ? `/facility?id=${facilityId}` : ''
      }`,
    )
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

export const submitForApproval =
  (letterOfCredit: any) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/draft-letter-of-credits/submit`, {
        id: letterOfCredit.id,
      })
      .then(response => {
        const payload = dispatch(submitLetterOfCredit(response.data))
        return { success: true, payload: payload.payload }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const approveSelectedLetterOfCredit =
  (letterOfCredit: any) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/submitted-letter-of-credits/approve`, {
        id: letterOfCredit.id,
        facilityId: letterOfCredit.facilityId,
      })
      .then(response => {
        dispatch(approveLetterOfCredit(response.data))
        return { success: true }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const rejectSelectedLetterOfCredit =
  (letterOfCredit: any) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/submitted-letter-of-credits/reject`, {
        id: letterOfCredit.id,
      })
      .then(response => {
        dispatch(rejectLetterOfCredit(response.data))
        return { success: true }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const deleteSelectedLetterOfCredit =
  (letterOfCredit: any) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .delete(
        `${apiServerUrl}/api/draft-letter-of-credits?ids[]=${letterOfCredit.id}`,
      )
      .then(() => {
        return dispatch(deleteLetterOfCredit(letterOfCredit))
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const loadAllLetterOfCredits =
  (facilityId?: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(startLoadingFacilityLettersOfCredit())
      let all_letterOfCredits: any[] = []
      const [draftLetterOfCredits,  submittedLetterOfCredits, letterOfCredits] = await Promise.all([
        loadDraftLetterOfCredits(facilityId),
        loadSubmittedLetterOfCredits(facilityId),
        loadApprovedLetterOfCredits(facilityId)
      ])

      const arr = submittedLetterOfCredits.map(
        (a: { letterOfCredit: any }) => a.letterOfCredit,
      )
      const sub_letter_of_credits = arr.map((a: any) => ({
        ...a,
        status: 'Submitted',
      }))

      all_letterOfCredits = draftLetterOfCredits
        .map((letterOfCredit: any) => ({ ...letterOfCredit, status: 'Draft' }))
        .concat(sub_letter_of_credits)
        .concat(
          letterOfCredits.map((letterOfCredit: any) => ({
            ...letterOfCredit,
            status: 'Approved',
          })),
        )

      return dispatch(getLetterOfCredits(all_letterOfCredits))
    } catch (error) {
      console.log('THUNK ERROR: ', error)
    } finally {
      dispatch(stopLoadingFacilityLettersOfCredit())
    }
  }

export const loadLocHistory = (locId: string) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .get(`${apiServerUrl}/api/approved-letter-of-credits/history?id=${locId}`)
    .then(response => {
      return dispatch(getLocHistory(response.data))
    })
    .catch(error => {
      console.log('THUNK ERROR: ', error)
    })
}
