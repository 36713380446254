export default function simInitSteps() {
    return ([
        {
            name: 'Deal Creation',
            body: 'deal',
            method: 'saveDeal',
            delay: '0',
            payload: null,
            reference: '',
        },
        {
            name: 'Deal Submission',
            body: '',
            method: 'dealSubmission',
            delay: '2000',
            payload: null,
            reference: '0',
        },
        {
            name: 'Deal Approval',
            body: '',
            method: 'dealApproval',
            delay: '3000',
            payload: null,
            reference: '1',
        },
        {
            name: 'Facility Creation',
            body: 'facility',
            method: 'saveFacility',
            delay: '2000',
            payload: null,
            reference: '2',
        },
        {
            name: 'Facility Submission',
            body: '',
            method: 'facilitySubmission',
            delay: '2000',
            payload: null,
            reference: '3',
        },
        {
            name: 'Facility Approval',
            body: '',
            method: 'facilityApproval',
            delay: '2000',
            payload: null,
            reference: '4',
        }
    ])
}
