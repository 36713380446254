import { Group } from '@mantine/core'
import React from 'react'

type ReactText = string | number
type ReactChild = React.ReactElement | ReactText
type ReactNode = ReactChild | React.ReactPortal | boolean | null | undefined

type FormWrapperProps = {
  title: string
  subtitle: string
  children: ReactNode
}

function FormWrapperWithSubtitle({ title, children, subtitle }: FormWrapperProps) {
  return (
    <div className="form-wrapper">
      <Group position="apart">
        <h4 className="form-wrapper-title">{title}</h4>
        <label className="form-subtitle">{subtitle}</label>
      </Group>
      <div className="form-wrapper-body">{children}</div>
    </div>
  )
}

export default FormWrapperWithSubtitle