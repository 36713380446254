export const GET_INDEX_RATE_OPTIONS = 'indexRateOptions/GET_INDEX_RATE_OPTIONS'
export const GET_INDEX_RATE_OPTION = 'indexRateOptions/GET_INDEX_RATE_OPTION'
export const ADD_INDEX_RATE_OPTIONS = 'indexRateOptions/ADD_INDEX_RATE_OPTIONS'
export const EDIT_INDEX_RATE_OPTIONS =
  'indexRateOptions/EDIT_INDEX_RATE_OPTIONS'
export const DELETE_INDEX_RATE_OPTIONS =
  'indexRateOptions/DELETE_INDEX_RATE_OPTIONS'
export const ACTIVE_INDEX_RATE_OPTION =
  'indexRateOptions/ACTIVE_INDEX_RATE_OPTION'
export const INACTIVE_INDEX_RATE_OPTION =
  'indexRateOptions/INACTIVE_INDEX_RATE_OPTION'
export const START_LOADING_INDEX_RATE_OPTIONS = 'indexRateOptions/START_LOADING_INDEX_RATE_OPTIONS'
export const STOP_LOADING_INDEX_RATE_OPTIONS = 'indexRateOptions/STOP_LOADING_INDEX_RATE_OPTIONS'
