import React from 'react'
import { PageLayout } from 'app/views/components/layout/page-layout'
import { FacilityDrillPage } from './facility-drill-page'


export function FacilityLandingPage(tab: { tab: string }) {
    return (
        <PageLayout>
            <FacilityDrillPage tab={tab.tab} />
        </PageLayout>
    )
}