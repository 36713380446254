import { useDispatch, useSelector } from 'react-redux'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { LoanParams } from 'app/models/loan-params'
import { Stack } from '@mantine/core'
import FormWrapper from 'app/views/components/Form/FormWrapper'
import {
  PrincipalPaymentParams,
} from 'app/models/principal-payments-params'
import { formatDateToUTC, stringToDate } from 'app/utils/util-functions'
import { getLoanLendersPositionsWithLoading } from 'app/state/ducks/lenders-positions/selectors'
import { FacilityParams } from 'app/models/facility-params'
import { useForm } from '@mantine/form'
import { ContractType, StableKey } from 'app/models/common-types'
import config from 'app/config/config'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import { useListState } from '@mantine/hooks'
import { LendersPosition } from 'app/models/lenders-position-params'
import { createDraftPrincipalPayment } from './principal-payment-utils'
import CurrentLoanSummary from 'app/views/components/current-loan-summary/current-loan-summary'
import NotesAndAttachment from 'app/views/components/notes-and-attachemnt/notes-and-attachment'
import { UpdatePrincipalPaymentForm } from './principal-payment-form'
import { getNoteForType } from 'app/state/ducks/notes/selectors'

interface Props {
  loan: LoanParams;
  payment: undefined | PrincipalPaymentParams;
  setPayment: any;
  setSaveButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  facility?: FacilityParams;
}

interface ChildLoanRef {
  handleClick: () => void
}

const UpdatePrincipalPaymentStep: React.ForwardRefRenderFunction<ChildLoanRef, Props> = ({
  loan,
  payment,
  setPayment,
  facility,
  setSaveButtonDisabled,
}, ref) => {

  const dispatch = useDispatch()
  const { data: lendersPositions, isLoading: isLoadingLenders } = useSelector(getLoanLendersPositionsWithLoading)
  const [activeLenders, activeLendersHandler] = useListState<LendersPosition>([])

  useEffect(() => {
    if (!lendersPositions || isLoadingLenders) {
      return
    }
    activeLendersHandler.setState(lendersPositions.filter(lp => lp.proRatas && lp.proRatas.length > 0 && stringToDate(lp.proRatas[0].effectiveDate) <= form.values.paymentDate))
  }, [lendersPositions, isLoadingLenders])

  const [paymentId, setPaymentId] = useState<string>(payment?.id ?? '')
  const [paymentAdmin, setPaymentAdmin] = useState<string>(payment?.agencyAdmin ?? '')
  // Prevent running validation on first render
  const [initialValidationDone, setInitialValidationDone] = useState(false);

  const businessDate = useSelector(getBusinessDate)
  const note = useSelector(getNoteForType(ContractType.Principals))
  // const documents = useSelector(getDocumentsForType(ContractType.Principals))


  const form: any = useForm({
    initialValues: {
      id: payment ? payment.id : undefined,
      loanId: { admin: loan.agencyAdmin, id: loan.id },
      interestPaid: payment ? payment.interestPaid.toString() : null,
      acceptedLenders: payment ? payment.acceptedLenders : [],
      acceptedAmount: payment ? payment.acceptedAmount : 0,
      amount: payment ? Number(payment.amount) : 0,
      interestAmount: payment ? Number(payment.interestAmount) : 0,
      paymentDate: payment ? stringToDate(payment.paymentDate) : stringToDate(loan.startDate) > businessDate ? stringToDate(loan.startDate) : businessDate,
      paymentType: payment ? payment.paymentType : null,
      documentIds: [],
      hasError: false,
      ErrorMessage: '',
      customer_token: '',
    },
    validate: {
      //due to how interestAmount is working, 
      // amount: (value: number) => (value >= 0 ? null : 'Invalid amount'),
      amount: (value: number, allVals) => {
        if (allVals.paymentType === 'InterestPayment') {
          return null
        } else if (allVals.interestPaid === 'false' && value >= 0) {
          return null
        } else if (allVals.interestPaid === 'true' && value <= 0) {
          return 'Amount must be greater than 0';
        } else if (value >= 0) {
          return null
        }
        return 'Select if interest should be paid'
      },
      paymentType: (value: string | null | undefined) =>
        !value ? 'Select Payment Type' : null,
      interestPaid: (value: number | null | string, allVals) => {
        if (allVals.paymentType === 'InterestPayment') {
          return null
        } else if (value === 'false' && allVals.amount >= 0) {
          return null
        } else if (value != null) {
          return null
        }
        return 'Select if interest should be paid'
      },
      acceptedLenders: (value: StableKey[] | null) => (value !== null && value.length >= 0 ? null : 'There must be at lest one accepted lender'),
      paymentDate: (value: Date, allVals) => {
        if (value == null) {
          return 'Payment date is required';
        }
        // Additional condition for paymentType === "PrepaymentPrincipal"
        if (allVals.paymentType === 'PrepaymentPrincipal') {
          if (value < new Date(loan.startDate) || value > new Date(loan.endDate)) {
            return `Date must be within ${loan.startDate} and ${loan.endDate}`;
          }
        }

        return null;
      },
      interestAmount: (value: number, allVals) => {
        if (form.values.interestPaid === 'false') {
          return null;
        }
        //this always returns 0 on interest payment, not sure if that's intended, 
        //but I added this to allow it to be submitted
        if (allVals.paymentType === 'InterestPayment') {
          return null;
        }
        if ((value > 0 || allVals.amount > 0)) {
          return null
        }
        return 'Invalid interest amount'
      },
    },
    validateInputOnChange: true,
    validateInputOnBlur: true,
    transformValues: (values) => ({
      ...values,
      paymentDate: formatDateToUTC(values.paymentDate),
      interestPaid: values.interestPaid == 'true',
      acceptedLenders: values.acceptedLenders
        ? values.acceptedLenders.filter((lp: any) => activeLenders?.some((lender: any) => lender.lender.id === lp.id))
        : lendersPositions?.map(lender => lender.lender),
      customer_token: config.company.toUpperCase(),
      noteId: !note || note.contractId ? null : note.id,
      // documentIds: !documents || documents.length == 0 || documents[0].contractId ? [] : documents.map(document => document.id), // uncomment this line after fixing security issue with documents and test it
    })
  })

  useEffect(() => {
    // Run validation only after the initial render
    setSaveButtonDisabled(true);
    if (initialValidationDone && form.values.paymentType) {
      form.validate();
      setSaveButtonDisabled(!form.isValid());
    } else {
      setInitialValidationDone(true);
    }
  }, [form.values]);

  const handleClick = async () => {
    await onSubmit()
  }

  useImperativeHandle(ref, () => ({
    handleClick,
  }))

  const onSubmit = async () => {
    setSaveButtonDisabled(true)
    const result = await createDraftPrincipalPayment(form, dispatch, payment)
    if (result) {
      setPayment(result)
    }
    setSaveButtonDisabled(false)
    return result
  }


  useEffect(() => {
    if (!payment) {
      return
    }
    setPaymentId(payment.id ?? '')
    setPaymentAdmin(payment.agencyAdmin ?? '')
  }, [payment])

  return (
    <FormWrapper title={(payment ? 'Edit' : 'Create') + ' Payment'}>
      <div className="content">
        <form onSubmit={form.onSubmit(() => onSubmit())}>
          <div className="create-new">
            <Stack spacing="md">
              <UpdatePrincipalPaymentForm
                facility={facility}
                loan={loan}
                payment={payment}
                form={form}
                lendersPositions={lendersPositions}
              />
              <CurrentLoanSummary loan={loan} />
              <NotesAndAttachment
                itemId={paymentId}
                type={ContractType.Principals}
                itemAdmin={paymentAdmin}
              />
            </Stack>
          </div>
        </form>
      </div>
    </FormWrapper>
  )
}
export default forwardRef(UpdatePrincipalPaymentStep)

