import { MRT_ColumnDef } from 'mantine-react-table'
import { Avatar, Text, Tooltip } from '@mantine/core'
import { ReactNode } from 'react'
import { FacilityParams } from 'app/models/facility-params'
import getInitials from 'app/views/components/functions/getInitials'
import FacilityActions from './facility-actions'
import StatusPill from '../../common/pill-status'
import { formatNumberToCurrency, stringToDate } from 'app/utils/util-functions'

function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}

export default function getColumnDefs(): MRT_ColumnDef<FacilityParams>[] {
  return [
    {
      size: 40,
      accessorFn: row => row.dealName,
      accessorKey: 'dealName',
      header: 'Deal Name',
      Header: <TableHead header={'Deal Name'} />,
      Cell: ({ cell }) => (
        <TableRowText text={cell.getValue<string>()} />
      ),
    },
    {
      size: 40,
      accessorKey: 'name',
      header: 'Name',
      Header: <TableHead header={'Name'} />,
      Cell: ({ cell }) => (
        <TableRowText text={cell.getValue<string>()} />
      ),
    },
    {
      size: 40,
      accessorKey: 'facilityType',
      header: 'Facility Type',
      Header: <TableHead header={'Facility Type'} />,
      Cell: ({ cell }) => (
        <TableRowText text={cell.getValue<string>()} />
      ),
    },
    {
      size: 40,
      accessorKey: 'status',
      header: 'Status',
      Header: <TableHead header={'Status'} />,
      Cell: cell => {
        const aditionalStatus = cell.row.original.amendment
          ? cell.row.original.amendmentType ?? 'Amendments'
          : ''
        return (
          <StatusPill
            state={cell.row.original.status ?? 'Draft'}
            additionalState={aditionalStatus}
          />
        )
      },
    },
    {
      size: 50,
      accessorKey: 'amount',
      header: 'Amount',
      Header: <TableHead header={'Amount'} />,
      filterVariant: 'range',
      Cell: ({ cell, row }: any) => (
        <TableRowText
          text={formatNumberToCurrency(cell.getValue(), row.original.baseCurrency ?? 'USD')}
        />
      ),
    }, {
      size: 50,
      accessorKey: 'utilizedAmount',
      header: 'Utilized Amount',
      Header: <TableHead header={'Utilized Amount'} />,
      filterVariant: 'range',
      Cell: ({ cell, row }: any) => (
        <TableRowText
          text={formatNumberToCurrency(cell.getValue(), row.original.baseCurrency ?? 'USD')}
        />
      ),
    },
    {
      size: 50,
      accessorKey: 'startDate',
      header: 'Start Date',
      Header: <TableHead header={'Start Date'} />,
      accessorFn: row => {
        return stringToDate(row.startDate);
      },
      mantineFilterTextInputProps: {
        placeholder: 'Date',
      },
      filterFn: 'lessThanOrEqualTo',
      filterVariant: 'date',
      sortingFn: 'datetime',
      Cell: ({ cell }) => (
        <Text fz="1.5em">{cell.getValue<Date>()?.toLocaleDateString()}</Text>
      ), //render Date as a string
    },
    {
      size: 50,
      accessorKey: 'maturity',
      header: 'Maturity Date',
      Header: <TableHead header={'Maturity Date'} />,
      accessorFn: row => {
        return stringToDate(row.maturity);
      },
      mantineFilterTextInputProps: {
        placeholder: 'Date',
      },
      filterFn: 'lessThanOrEqualTo',
      filterVariant: 'date',
      sortingFn: 'datetime',
      Cell: ({ cell }) => (
        <Text fz="1.5em">{cell.getValue<Date>()?.toLocaleDateString()}</Text>
      ), //render Date as a string
    },
    {
      size: 50,
      accessorFn: row => row.adminEntityName,
      accessorKey: 'adminEntityName',
      header: 'Admin',
      Header: <TableHead header={'Admin'} />,
      Cell: ({ cell }) => {
        return (
          <Tooltip label={cell.getValue<string>()}>
            <Avatar radius="xl" color="cyan" size="lg">
              {getInitials(cell.getValue<string>() as string)}
            </Avatar>
          </Tooltip>
        )
      },
    },
    {
      size: 40,
      accessorKey: 'id',
      header: 'Actions',
      Header: <TableHead header={'Actions'} />,
      enableColumnActions: false,
      enableColumnFilter: false,
      Cell: cell => <FacilityActions row={cell.row.original} />,
    },
  ]
}
