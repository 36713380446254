import { Group } from '@mantine/core'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'

export default function MyWorkHeader() {

  return (
    <Group position="apart">
      <PageTitle pageTitle='My Work' />
    </Group>
  )
}
