import React from 'react'
import { PageLayout } from 'app/views/components/layout/page-layout'
import { Group } from '@mantine/core'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import { userManagementCols } from './user-management-table.columnDefs'
import SecondaryButton from 'app/views/components/buttons/SecondaryButton'
import { updateUser } from 'app/state/ducks/signup/thunks'
import MainTable from 'app/views/components/Table/MainTable'

export default function UserManagementPage() {
  const users: any = [
    {
      fullName: 'Adam Black',
      username: 'adamblack',
      userProfile: 'Deal Closing Admin',
      teamName: 'Admin Agent',
      userType: 'Internal',
      email: 'adam.black@gmail.com',
      activityDetails: { activityCount: 10, activityLast: '3 years ago' },
    },
    {
      fullName: 'Adrianna Tomas',
      username: 'adriannatomas',
      userProfile: 'Assignments Admin',
      teamName: 'Admin Agent',
      userType: 'Internal',
      email: 'adri.tomas@enterprise.com',
      activityDetails: { activityCount: 1, activityLast: '2 min ago' },
    },
    {
      fullName: 'Cat Walker',
      username: 'catwalker',
      userProfile: 'CFO',
      teamName: 'Borrower',
      userType: 'Internal',
      email: 'cat.walker@enterprise.com',
      activityDetails: {
        activityCount: 5,
        activityLast: new Date('2024-03-18T13:30:10.000Z')
          .toString()
          .slice(0, 15),
      },
    },
    {
      fullName: 'Bruce Wayne',
      username: 'brucewayne',
      userProfile: 'Senior Manager',
      teamName: 'Admin Agent',
      userType: 'Internal',
      email: 'bruce.wayne@gmail.com',
      activityDetails: { activityCount: 3, activityLast: '20 min ago' },
    },
    {
      fullName: 'Drac Groff',
      username: 'dracgroff',
      userProfile: 'LC Admin',
      teamName: 'Letter of Credit Beneficiary',
      userType: 'Internal',
      email: 'drac.groff@gmail.com',
      activityDetails: { activityCount: 55, activityLast: '2 hours ago' },
    },
  ]

  function viewUserInfo(e: any) {
    console.log(e)
    // setUser(e)
    // setOpen(true)
  }

  function createNewUser() {
    // setUser({})
    // setOpen(true)
  }

  async function updateUserMethod() {
    const customUserObj = {
      email: 'steven.inouye91@gmail.com',
      participantNodeName: 'RIGHTPEDAL',
      method: 'grant',
      actAs: ['agencyAdmin'],
      readAs: ['agencyAdmin'],
    }
    const updatedUser = await updateUser(customUserObj)
    return updatedUser
  }

  if (!users) {
    return <PageLayout />
  }

  return (
    <PageLayout>
      <Group position="apart" mb="5%">
        <PageTitle>User Management</PageTitle>
        <Group>
          <SecondaryButton onClick={() => createNewUser()}>
            Invite User
          </SecondaryButton>
          <PrimaryButton onClick={() => createNewUser()}>
            Create User
          </PrimaryButton>
          <PrimaryButton onClick={() => updateUserMethod()}>
            Update User
          </PrimaryButton>
        </Group>
      </Group>

      <MainTable
        tableName='User Management Table'
        data={users}
        columnDefs={userManagementCols}
        setRow={(e: any) => viewUserInfo(e)}
        headerBackgroundColor='#F0EEEE'
        enableTopToolbar={false}
        withPagination={true}
      />

      {/* <UsersModal open={open} setOpen={setOpen} user={user} /> */}
    </PageLayout>
  )
}
