export enum KycStatus {
  PendingKycStatus = 'PendingKycStatus',
  InReviewKycStatus = 'InReviewKycStatus',
  ValidatedStatus = 'ValidatedStatus',
}

export type EntityParams = {
  id?: string
  maker?: string
  checker?: string
  entityName: string
  entityType: string
  entityProfile: string[]
  entityReference?: string | null
  entityParty: string | null | undefined
  fundManager?: string | null
  institutionType: string | null
  fundManagerMei?: string | null
  parent?: string | null
  parentMei?: string | null
  address?: string
  address2?: string | null
  city?: string
  state?: string | null
  zipCode?: string
  country?: string
  giin?: string
  crn?: string
  naicCode?: string
  internalId?: string
  legalEntityId?: string
  mei?: string
  countryOfIncorporation?: string
  countryOfTaxResidence?: string
  ein?: string
  taxFormStatus?: string | null
  usTaxFormType?: string | null
  ukTreatyPassportNumber?: string | null
  ukTreatyPassportExpiryDate?: string | null
  kycType: string | null
  kycStatus: KycStatus | null
  cei?: string
  hasError: boolean
  ErrorMessage: string
  hasSuccessfulEntitySave: boolean
  customer_token: string
  status: string
}

export const entityTypeWithoutFundManager = [
  'BorrowerPartnership',
  'BorrowerIndividual',
  'BorrowerLLC',
  'BorrowerCorporate',
  'AssetManager',
  'FundManager',
  'BankFIBrokerDealer',
]
