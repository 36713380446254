import { MRT_ColumnDef } from 'mantine-react-table'
import { Text } from '@mantine/core'
import { ReactNode } from 'react'
import { WireInstructionParams } from 'app/models/wire-instruction-params'
import Pill, { PillColor } from 'app/views/components/Table/Pill'
import WireInstructionActions from './wire-instruction-actions'

enum StatusState {
  Active = 'Active',
  Unapproved = 'Unapproved',
  PendingKYC = 'Pending KYC',
  Inactive = 'Inactive',
}

const getPillColor = new Map<StatusState, PillColor>()
getPillColor.set(StatusState.Active, PillColor.LIGHT_BLUE)
getPillColor.set(StatusState.Unapproved, PillColor.PINK)
getPillColor.set(StatusState.PendingKYC, PillColor.ORANGE)
getPillColor.set(StatusState.Inactive, PillColor.GREY)

function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}

export const wireInstructionCols: MRT_ColumnDef<WireInstructionParams>[] = [
  {
    size: 50,
    accessorKey: 'currency',
    header: 'Currency',
    Header: <TableHead header={'Currency'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    accessorKey: 'entityName',
    header: 'Entity',
    Header: <TableHead header={'Entity'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 100,
    accessorKey: 'status',
    header: 'Status',
    Header: <TableHead header={'Status'} />,
    Cell: ({ cell }) => {
      const color: PillColor =
        getPillColor.get(cell.getValue<string>() as StatusState) ?? PillColor.GREY
      return <Pill text={(cell.getValue<string>() as string) ?? ''} color={color} />
    },
  },
  {
    size: 100,
    accessorKey: 'paymentInstructionType',
    header: 'Instruction Type',
    Header: <TableHead header={'Instruction Type'} />,
    filterVariant: 'range',
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 100,
    accessorKey: 'accountWithInstitutionBankAba',
    header: 'Bank Aba',
    Header: <TableHead header={'Bank Aba'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    accessorKey: 'paymentReference',
    header: 'Payment Reference',
    Header: <TableHead header={'Payment Reference'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 180,
    accessorKey: 'id',
    header: 'Actions',
    Header: <TableHead header={'Actions'} />,
    enableColumnActions: false,
    enableColumnFilter: false,
    Cell: cell => <WireInstructionActions row={cell.row.original} />,
  },
]
