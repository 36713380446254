import { MRT_ColumnDef } from 'mantine-react-table'
import { Text } from '@mantine/core'
import { ReactNode } from 'react'
import Pill, { PillColor } from 'app/views/components/Table/Pill'
import { IndexRateOptionParams } from 'app/models/index-rate-options-params'
import IndexRateOptionActions from './index-rate-option-actions'
import IonIcon from '@reacticons/ionicons';

enum StatusState {
  Active = 'Active',
  Inactive = 'Inactive',
}

export type TableData = IndexRateOptionParams & {
  setDailyRates: any
}
const getPillColor = new Map<StatusState, PillColor>()
getPillColor.set(StatusState.Active, PillColor.LIGHT_BLUE)
getPillColor.set(StatusState.Inactive, PillColor.GREY)

function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}

export const iroCols: MRT_ColumnDef<TableData>[] = [
  {
    accessorKey: 'indexOption',
    header: 'Index Rate Name',
    Header: <TableHead header={'Index Rate Name'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 32,
    accessorKey: 'currency',
    header: 'Currency',
    Header: <TableHead header={'Currency'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 25,
    accessorKey: 'riskFreeRate',
    header: 'RFR',
    Header: <TableHead header={'RFR'} />,
    Cell: ({ cell }) => (
      <TableRowText text={cell.getValue<string>() ? 'Yes' : 'No'} />
    ),
    filterFn: (row, columnId, filterValue) => {
      const formattedValue = row.getValue(columnId) ? 'Yes' : 'No';
      return formattedValue.toLowerCase().includes(filterValue.toLowerCase());
    }
  },
  {
    accessorKey: 'indexType',
    header: 'Type',
    Header: <TableHead header={'Type'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 80,
    accessorKey: 'status',
    header: 'Status',
    Header: <TableHead header={'Status'} />,
    Cell: ({ cell }) => {
      const color: PillColor =
        getPillColor.get(cell.getValue<string>() as StatusState) ?? PillColor.GREY
      return <Pill text={(cell.getValue<string>() as string) ?? ''} color={color} />
    },
  },
  {
    size: 25,
    accessorKey: 'setDailyRates',
    header: 'Daily Rates',
    Header: <TableHead header={'Daily Rates'} />,
    enableColumnActions: false,
    enableColumnFilter: false,
    enableSorting: false,
    Cell: cell => {
      const onClickAction = cell.row.original.setDailyRates
      const value = cell.row.original

      return (
        <IonIcon name="bar-chart-outline" size='large' onClick={() => onClickAction(value)} />
      )
    },
  },
  {
    size: 130,
    accessorKey: 'id',
    header: 'Actions',
    Header: <TableHead header={'Actions'} />,
    enableColumnActions: false,
    enableColumnFilter: false,
    enableSorting: false,
    Cell: cell => <IndexRateOptionActions row={cell.row.original} />,
  },
]
