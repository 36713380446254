import React, { useEffect } from 'react'
import { PageLayout } from 'app/views/components/layout/page-layout'
import { useDispatch, useSelector } from 'react-redux'
import { getUsers } from 'app/state/ducks/users/selectors'
import { loadUsers } from 'app/state/ducks/users/thunks'
import MainTable from 'app/views/components/Table/MainTable'
import { usersCols } from './users-columnDefs'

export default function () {
  const users = useSelector(getUsers)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadUsers())
  }, [])

  if (users) {
    return (
      <PageLayout>
        <div className="content-layout">
          <div className="content__body ">
            <div
              className="ag-theme-alpine code-snippet"
              style={{ height: '400px' }}
            >
              {users ? (
                <MainTable
                  tableName='Users Table'
                  data={users.payload.data}
                  enableTopToolbar={false}
                  headerBackgroundColor='#F0EEEE'
                  columnDefs={usersCols}
                  setRow={() => {
                    return
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
      </PageLayout>
    )
  } else {
    return <></>
  }
}
