import * as types from './types'

export const getAssignments = (payload: any) => ({
  type: types.GET_ASSIGNMENTS,
  payload,
})

export const getAssignment = (payload: any) => ({
  type: types.GET_ASSIGNMENT,
  payload,
})

export const addAssignment = (payload: any) => ({
  type: types.ADD_ASSIGNMENTS,
  payload,
})

export const editAssignment = (payload: any) => ({
  type: types.EDIT_ASSIGNMENTS,
  payload,
})

export const submitAssignment = (payload: any) => ({
  type: types.SUBMIT_ASSIGNMENT,
  payload,
})

export const rejectAssignment = (payload: any) => ({
  type: types.REJECT_ASSIGNMENT,
  payload,
})

export const approveAssignment = (payload: any) => ({
  type: types.APPROVE_ASSIGNMENT,
  payload,
})

export const deleteAssignment = (payload: any) => ({
  type: types.DELETE_ASSIGNMENT,
  payload,
})

export const startLoadingAssignments = () => ({
  type: types.START_LOADING_ASSIGNMENTS,
  payload: null,
})

export const stopLoadingAssignments = () => ({
  type: types.STOP_LOADING_ASSIGNMENTS,
  payload: null,
})
