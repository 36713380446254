import { PrincipalPaymentParams } from 'app/models/principal-payments-params'
import {
  submitForApproval,
  rejectSelectedPrincipalPayment,
  approveSelectedPrincipalPayment,
  deleteSelectedPrincipalPayment,
  loadAllPrincipalPayments,
} from 'app/state/ducks/principal-payment/thunks'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'
import { Dispatch } from 'redux'

export async function submitPrincipalPayment(
  principalPayment: PrincipalPaymentParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(submitForApproval(principalPayment))
  const paymentType = principalPayment?.paymentType === 'InterestPayment' ? 'Interest Payment' : 'Principal Payment'
  if (response.success) {
    SuccessNotification({
      title: 'Successful Submission',
      message: paymentType + ' has been Submitted for Approval',
    })
  } else {
    ErrorNotification({
      title: 'Submission Failed',
      message: response.payload ?? paymentType + ' Submission Failed',
    })
  }
  dispatch(loadAllPrincipalPayments())
  return
}

export async function rejectPrincipalPayment(
  principalPayment: PrincipalPaymentParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(
    rejectSelectedPrincipalPayment(principalPayment)
  )
  const paymentType = principalPayment?.paymentType === 'InterestPayment' ? 'Interest Payment' : 'Principal Payment'
  if (response.success) {
    SuccessNotification({
      title: 'Successful Rejection',
      message: paymentType + ' has been moved back to a Draft',
    })
  } else {
    ErrorNotification({
      title: 'Rejection Failed',
      message: response.payload ?? paymentType + ' Rejection Failed',
    })
  }
  dispatch(loadAllPrincipalPayments())
  return
}

export async function approvePrincipalPayment(
  principalPayment: PrincipalPaymentParams,
  dispatch: Dispatch<any>
) {
  const paymentType = principalPayment?.paymentType === 'InterestPayment' ? 'Interest Payment' : 'Principal Payment'
  const response: any = await dispatch(
    approveSelectedPrincipalPayment(principalPayment)
  )
  if (response.success) {
    SuccessNotification({
      title: 'Successful Approval',
      message: paymentType + ' has been Approved',
    })
  } else {
    ErrorNotification({
      title: 'Approval Failed',
      message: response.payload ?? paymentType + ' Approval Failed',
    })
  }
  dispatch(loadAllPrincipalPayments())
  return
}

export async function deletePrincipalPayment(
  principalPayment: PrincipalPaymentParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(
    deleteSelectedPrincipalPayment(principalPayment)
  )
  if (response.payload.error) {
    ErrorNotification({
      title: 'Delete Failed',
      message: response.payload.error.message,
    })
  } else {
    SuccessNotification({
      title: 'Successful Deletion',
      message: 'Principal Payment has been Deleted',
    })
  }
  dispatch(loadAllPrincipalPayments())
  return
}
