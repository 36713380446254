import React from 'react'
import { Group } from '@mantine/core'
import PrimaryInput from 'app/views/components/buttons/PrimaryInput'

export default function CreateNoticeButton() {
    return (
        <>
            <Group>
                <PrimaryInput
                    text='Create Notice'
                    onClick={() => console.log(true)}
                />
            </Group>
        </>
    )
}