import { Select as MSelect, SelectProps } from '@mantine/core'

export default function Select({ ...props }: SelectProps) {
  return (
    <MSelect
      clearable
      {...props}
      sx={{
        label: {
          fontFamily: 'Plus Jakarta Sans',
          color: '#111928',
          fontSize: '1em',
        },
        input: {
          backgroundColor: '#F9FAFB',
          height: '3em',
          borderRadius: '0.7em',
          color: '#6B7280',
          borderWidth: '1px',
          borderColor: '#D1D5DB',
          fontSize: '1em',
          fontFamily: 'Plus Jakarta Sans',
        },
        rightSection: {
          marginRight: '10px',
        },
      }}
      styles={{
        error: {
          fontSize: '1em',
        },
      }}
    />
  )
}
