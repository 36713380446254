import { Accordion, Grid, SimpleGrid, Text } from '@mantine/core'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { BaseText, BoldText } from 'app/views/components/Headers&Text/Text'
import Pill, { PillColor } from 'app/views/components/Table/Pill'
import { useState } from 'react'
import { formatDateToUTC, formatNumberToCurrency, stringToDate } from 'app/utils/util-functions'
import LetterOfCreditActions from './letter-of-credit-actions'
import { LetterOfCreditParams } from 'app/models/letter-of-credit'
import { useSelector } from 'react-redux'

type Props = {
  item: LetterOfCreditParams
}

enum pillState {
  'Draft' = 'Draft',
  'Submitted' = 'Submitted',
  'Approved' = 'Approved',
}

export default function OneLetterOfCreditAccordion({ item }: Props) {
  const entities = useSelector(getEntities)

  const [open, setOpen] = useState(false)

  function PillStatus({ ...props }) {
    const state = item.status
    let collor = PillColor.GREY
    if (state === pillState.Submitted) {
      collor = PillColor.ORANGE
    }
    if (state === pillState.Approved) {
      collor = PillColor.LIGHT_BLUE
    }
    if (state === pillState.Draft) {
      collor = PillColor.GREY
    }

    return <Pill text={state ?? '-'} color={collor} {...props} />
  }

  // Utility to compute the difference in days between two dates.
  return (
    <>
      <Accordion.Control
        className="loan-accordion-button"
        pt={0}
        pb={0}
        onClick={() => setOpen(!open)}
      >
        <Grid columns={31} pb="0px" pt="0px">
          <Grid.Col
            span={6}
            style={{
              color: open ? 'rgb(0,0,0,0.34)' : 'rgb(0,0,0)',
              textAlign: 'center',
            }}
          >
            <Text>{item?.effectiveDate}</Text>
          </Grid.Col>
          <Grid.Col
            span={6}
            style={{
              color: open ? 'rgb(0,0,0,0.34)' : 'rgb(0,0,0)',
              textAlign: 'center',
            }}
          >
            <Text>{item?.initialExpirationDate}</Text>
          </Grid.Col>
          <Grid.Col
            span={6}
            style={{
              color: open ? 'rgb(0,0,0,0.34)' : 'rgb(0,0,0)',
              textAlign: 'center',
            }}
          >
            <Text>
              {formatNumberToCurrency(Number(item?.amount) ?? 0, item?.currency ?? 'USD')}  
            </Text>
          </Grid.Col>
          <Grid.Col
            span={5}
            style={{
              color: open ? 'rgb(0,0,0,0.34)' : 'rgb(0,0,0)',
              textAlign: 'center',
            }}
          >
            <PillStatus w="inherit" />
          </Grid.Col>
          <Grid.Col
            span={5}
            style={{ textAlign: 'center' }}
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <LetterOfCreditActions item={item} />
          </Grid.Col>
        </Grid>
      </Accordion.Control>
      <Accordion.Panel>
        <SimpleGrid cols={2} pt="15px">
          <BoldText text="LC Type" />
          <BaseText text={item?.letterOfCreditType ?? ''} />

          <BoldText text="LC Sub Type" />
          <BaseText text={item?.letterOfCreditStandbyType ?? ''} />

          <BoldText text="Borrowers" />
          <BaseText
            text={item.borrowers
              ?.map(borrower =>
                borrower.id
                  ? entities.find(entity => entity.id === borrower.id)
                    ?.entityName ?? '-'
                  : '-'
              )
              .toString()}
          />

          <BoldText text="Issuing Banks" />
          <BaseText
            text={item.issuingBanks
              ?.map(bank =>
                bank.id
                  ? entities.find(entity => entity.id === bank.id)
                    ?.entityName ?? '-'
                  : '-'
              )
              .toString()}
          />

          <BoldText text="Beneficiaries" />
          <BaseText
            text={item.beneficiaries
              ?.map(bene =>
                bene.id
                  ? entities.find(entity => entity.id === bene.id)
                    ?.entityName ?? '-'
                  : '-'
              )
              .toString()}
          />

          <BoldText text="Currency" />
          <BaseText text={item?.currency} />

          <BoldText text="Amount" />
          <BaseText
            text={formatNumberToCurrency(Number(item?.amount) ?? 0, item?.currency ?? 'USD')}
          />

          <BoldText text="Issuance Fee Rate" />
          <BaseText text={item?.issuingFeeRate} />

          <BoldText text="Effective Date" />
          <BaseText text={formatDateToUTC(stringToDate(item?.effectiveDate))} />

          <BoldText text="Expiration Date" />
          <BaseText text={formatDateToUTC(stringToDate(item?.initialExpirationDate))} />

          <BoldText text="Final Expiration Date" />
          <BaseText
            text={
              item?.adjustedExpirationDate
                ? formatDateToUTC(stringToDate(item?.adjustedExpirationDate))
                : ''
            }
          />

          <BoldText text="FX Rate" />
          <BaseText text={item?.fxRate ?? ''} />

          <BoldText text="Notification Period" />
          <BaseText text={item?.autoExtendNotificationPeriod ?? ''} />

          <BoldText text="Auto Extend Period" />
          <BaseText text={item?.autoExtendPeriod ?? ''} />

          <BoldText text="Status" />
          <PillStatus w="fit-content" />
        </SimpleGrid>
      </Accordion.Panel>
    </>
  )
}
