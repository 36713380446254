import { LoanParams } from 'app/models/loan-params'
import { Modal } from '@mantine/core'
import { useSelector } from 'react-redux'
import { getPrincipalPayments } from 'app/state/ducks/principal-payment/selectors'
import { useEffect, useState } from 'react'
import { PrincipalPaymentScreen } from './principal-payment-stepper'
import { getFacilities } from 'app/state/ducks/facilities/selectors'
import { FacilityParams } from 'app/models/facility-params'
import { PrincipalPaymentParams } from 'app/models/principal-payments-params'

type Props = {
  open: boolean
  setOpen: any
  loan: LoanParams
}

export default function PrincipalPaymentModal({ loan, open, setOpen }: Props) {

  const principalPayments = useSelector(getPrincipalPayments)
  const facilities = useSelector(getFacilities)

  const [facility, setFacility] = useState<FacilityParams>()
  const [payment, setPayment] = useState<PrincipalPaymentParams>()

  useEffect(() => {
    const newPayment = principalPayments
      ? principalPayments.find(
        payment =>
          payment.loanId.id == loan.id && payment.status != 'Approved'
      )
      : undefined

    if (newPayment != payment)
      setPayment(newPayment)
  }, [principalPayments])

  useEffect(() => {

    if (!facilities) {
      return
    }
    setFacility(facilities.find(facility => facility.id == loan.facilityId.id))
  }, [facilities])

  return (
    <Modal
      size="70%"
      className="modal-body modal-body-template fit-content-modal"
      opened={open}
      onClose={() => setOpen(false)}
    >
      <PrincipalPaymentScreen loan={loan} payment={payment} onClose={() => setOpen(false)} facility={facility} />
    </Modal>
  )
}
