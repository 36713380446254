import React from 'react';
import { Flex, Grid, Group } from '@mantine/core';
import Select from '../inputs/Select';
import { LevelField } from './level-field';
import { UseFormReturnType } from '@mantine/form';
import { DealPricingGrid } from 'app/models/deal-params';
import { pricingGridTypeOptions, pricingGridMarginOrFeeTypeOptions } from 'app/models/dropdown-options';
import IonIcon from '@reacticons/ionicons';

interface PricingGridEntryProps {
    index: number;
    form: UseFormReturnType<{ pricingGrid: DealPricingGrid[] }>;
    selectedOptions: string[];
}

export const PricingGridEntry: React.FC<PricingGridEntryProps> = ({ index, form, selectedOptions }) => {
    // Functions to handle adding a new level and removing the entire grid entry
    const handleAddLevel = () => {
        const newLevels = [...form.values.pricingGrid[index].levels, ''];
        form.setFieldValue(`pricingGrid.${index}.levels`, newLevels);
    };

    const handleRemoveGrid = () => {
        form.removeListItem('pricingGrid', index);
    };

    // Updated options with disabled already selected ones
    const updatedPricingGridTypeOptions = pricingGridTypeOptions.map(option => ({
        ...option,
        disabled: selectedOptions.includes(option.value) && option.value !== form.values.pricingGrid[index].pricingGridType,
    }));

    return (
        <Group grow>
            <Flex direction={'column'}>
                <Grid grow style={{ paddingBottom: '10px', height: 'auto' }}>
                    <Grid.Col span={6}>
                        <Select
                            label="Pricing Grid Type"
                            {...form.getInputProps(`pricingGrid.${index}.pricingGridType`)}
                            data={updatedPricingGridTypeOptions}
                        />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Select
                            label="Margin and/or Fee"
                            {...form.getInputProps(`pricingGrid.${index}.marginOrFee`)}
                            data={pricingGridMarginOrFeeTypeOptions}
                        />
                    </Grid.Col>
                </Grid>
                {form.values.pricingGrid[index].levels.map((level, cpIndex) => (
                    <LevelField
                        key={cpIndex}
                        cpIndex={cpIndex}
                        levelValue={level}
                        handleLevelChange={(newValue) => {
                            const updatedLevels = [...form.values.pricingGrid[index].levels];
                            updatedLevels[cpIndex] = newValue ?? '';
                            form.setFieldValue(`pricingGrid.${index}.levels`, updatedLevels);
                        }}
                        handleRemoveLevel={() => {
                            const updatedLevels = form.values.pricingGrid[index].levels.filter((_, i) => i !== cpIndex);
                            form.setFieldValue(`pricingGrid.${index}.levels`, updatedLevels);
                        }}
                    />
                ))}
                <Group>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IonIcon name="add-circle-outline" className="click-ion-icon" onClick={handleAddLevel} />
                        <span
                            style={{
                                marginLeft: '8px',
                                fontFamily: 'Plus Jakarta Sans',
                                fontWeight: 'bold',
                                fontSize: '1em',
                            }}
                        >
                            Add Level
                        </span>
                    </div>
                </Group>
                {/* <Group> */}

                <Grid.Col span={12} style={{ marginTop: '-3px' }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <IonIcon name='trash-outline' className="click-ion-icon" style={{ color: "red", width: '20px', height: '20px' }} onClick={handleRemoveGrid} />
                        <span
                            style={{
                                fontFamily: 'Plus Jakarta Sans',
                                color: '#111928',
                                fontSize: '12px',
                                fontWeight: 'bold',
                            }}
                        >
                            Remove index rate option
                        </span>
                    </div>
                </Grid.Col>
            </Flex>
        </Group>
    );
};