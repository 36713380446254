import { Key, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFacility } from 'app/state/ducks/facilities/selectors'
import TextInput from 'app/views/components/inputs/TextInput'
import { Group, Stack, Grid, Table } from '@mantine/core'
import { amortizationsStatus, frequencyOptions } from 'app/models/dropdown-options'
import { setOneFacility } from 'app/state/ducks/facilities/thunks'
import {
  SchedulerList
} from 'app/models/amortitation-schedule'
import { getFacilityAmendments } from 'app/state/ducks/facility-amendments/selectors'
import { getLoan } from 'app/state/ducks/loans/selectors'
import { calculateInterestPaymentScheduler } from 'app/utils/util-functions'
import moment from 'moment'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'


enum Tab {
  current = 'Current',
  history = 'History'
}
interface Props {
  facilityId?: string
  tab: string
}
export default function InterestPaymentScheduleDetails({
  facilityId,
  tab
}: Props) {
  const dispatch = useDispatch()
  const facilityAmendments = useSelector(getFacilityAmendments)
  const facility = useSelector(getFacility)
  const loan = useSelector(getLoan)
  const bussinessDate = useSelector(getBusinessDate)
  const [currentRows, setCurrentRows] = useState<any>([])
  const [historyRows, setHistoryRows] = useState<any>([])
  const [interestPaymentScheduler, setInterestPaymentScheduler] = useState<SchedulerList[]>([])

  useEffect(() => {
    if (facilityAmendments && facilityAmendments.length > 0) {
      const amendment = facilityAmendments.find(
        (amendment: any) => amendment.id === facilityId
      )
      if (amendment) {
        dispatch(setOneFacility(amendment))
      }
    }
  }, [facilityAmendments, facilityId])


  const facilityFrequency = facility?.iroValues?.find((iroValues: { indexOption: { id: string } }) => iroValues.indexOption?.id === loan?.indexOption?.id)?.interestPaymentFrequency;
  const facilityFirstPaymentDate = facility?.iroValues?.find((iroValues: { indexOption: { id: string } }) => iroValues.indexOption?.id === loan?.indexOption?.id)?.interestFirstPaymentDate;


  useEffect(() => {
    const interestPayment = calculateInterestPaymentScheduler(
      facilityFrequency,
      loan,
      bussinessDate
    );
    setInterestPaymentScheduler(interestPayment as SchedulerList[]);
  }, [loan]);


  useEffect(() => {
    setCurrentRows(
      interestPaymentScheduler
        ?.filter((scheduler: SchedulerList) => scheduler.status !== 'Paid')
        .map((scheduler: SchedulerList, index: Key | null | undefined) => {
          return (
            <tr key={index}>
              <td colSpan={1}>{moment(scheduler?.paymentDate).format('YYYY-MM-DD')}</td>
              <td colSpan={1}>{amortizationsStatus.find(({ value }) => value == scheduler?.status)?.label ?? ''}</td>
              <td colSpan={1}>
              </td>
            </tr>
          )
        })
    )

    setHistoryRows(
      interestPaymentScheduler
        ?.filter((scheduler: SchedulerList) => scheduler.status === 'Paid')
        .map((scheduler: SchedulerList, index: Key | null | undefined) => {
          return (
            <tr key={index}>
              <td colSpan={1}>{scheduler?.paymentDate}</td>
            </tr>
          )
        })
    )
  }, [interestPaymentScheduler])



  return (
    <Stack className="data-header-part">
      <div style={{ fontSize: 'large', fontWeight: '500' }}>
        Ineterst Payment Details
      </div>{' '}
      <>
        <Group noWrap w="100%">
          <Grid grow>
            {/* Frequency */}
            <Grid.Col span={3}>
              <TextInput
                w="100%"
                label="Frequency"
                placeholder="Empty"
                value={
                  frequencyOptions.find(
                    option => option.value === facilityFrequency
                  )?.label ?? ''
                }
                disabled
              />
            </Grid.Col>



            {/* First Payment Date */}
            <Grid.Col span={3}>
              <TextInput
                w="100%"
                label="First Payment Date"
                placeholder="Empty"
                value={facilityFirstPaymentDate as string}
                disabled
              />
            </Grid.Col>
          </Grid>
        </Group>
      </>
      <div style={{ overflowX: 'auto', width: '100%' }}>
        <Table highlightOnHover fontSize="xl">
          <thead>
            <tr>
              <th>Payment Date</th>

              {tab !== Tab.history ? <th>Status</th> : (
                <>
                  <th>Payment Amount</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>{tab === Tab.current ? currentRows : historyRows}</tbody>
        </Table>
      </div>
    </Stack>
  )
}