import { SchedulerList } from 'app/models/amortitation-schedule'
import { MRT_ColumnDef } from 'mantine-react-table'
import { Text } from '@mantine/core'
import { ReactNode } from 'react'
import { formatNumberToCurrency } from 'app/utils/util-functions'

function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}


export function getColumnDefs(
  currency: string
): MRT_ColumnDef<SchedulerList>[] {
  return [
    {
      size: 40,
      accessorKey: 'paymentDate',
      header: 'Payment Due Date',
      Header: <TableHead header={'Payment Due Date'} />,
      Cell: ({ cell }) => (
        <TableRowText text={cell.getValue<string>()} />
      ),
    },
    {
      size: 40,
      accessorKey: 'amountDue',
      header: 'Loan Amount',
      Header: <TableHead header={'Loan Amount'} />,
      Cell: ({ cell }) => (
        <TableRowText
          text={formatNumberToCurrency(Number(cell.getValue<string>()), currency)}
        />
      ),
    },
    {
      size: 40,
      accessorKey: 'paymentAmount',
      header: 'Payment Amount',
      Header: <TableHead header={'Payment Amount'} />,
      filterVariant: 'range',
      Cell: ({ cell }) => (
        <TableRowText
          text={formatNumberToCurrency(Number(cell.getValue<string>()), currency)}
        />
      ),
    },
    {
      size: 40,
      accessorKey: 'balance',
      header: 'Balance',
      Header: <TableHead header={'Balance'} />,
      Cell: ({ cell }) => (
        <TableRowText
          text={formatNumberToCurrency(Number(cell.getValue<string>()), currency)}
        />
      ),
    },
    {
      size: 40,
      accessorKey: 'status',
      header: 'Status',
      Header: <TableHead header={'Status'} />,
      Cell: ({ cell }) => (
        <TableRowText text={cell.getValue<string>()} />
      ),
    },
  ]
}
