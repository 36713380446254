import { Dispatch } from 'redux'
import { IndexRateOptionParams } from 'app/models/index-rate-options-params'
import {
  activateIndexRateOption,
  loadIndexRateOptions,
  removeIndexRateOptions,
  deactivateIndexRateOption,
} from 'app/state/ducks/index-rate-options/thunks'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'

export async function activeIndexRateOption(
  indexRateOption: IndexRateOptionParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(activateIndexRateOption(indexRateOption))
  if (response.success) {
    SuccessNotification({
      title: 'Successful Activated',
      message: 'Index Rate Option has been Activated',
    })
  } else {
    ErrorNotification({
      title: 'Approval Failed',
      message: response.payload ?? 'Approval Failed',
    })
    return false
  }
  return true
}

export async function makeInactiveIndexRateOption(
  indexRateOption: IndexRateOptionParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(
    deactivateIndexRateOption(indexRateOption)
  )
  if (response.success) {
    SuccessNotification({
      title: 'Successful Deactivation',
      message: 'Index Rate Option has been deactivated.',
    })
  } else {
    ErrorNotification({
      title: 'Deactivation Failed',
      message: response.payload ?? 'Deactivation Failed',
    })
    return false
  }
  dispatch(loadIndexRateOptions())
  return true
}

export async function deleteIndexRateOption(
  indexRateOption: IndexRateOptionParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(
    removeIndexRateOptions([indexRateOption.id])
  )
  if (response.payload.error) {
    ErrorNotification({
      title: 'Delete Failed',
      message: response.payload.error.message,
    })
  } else {
    SuccessNotification({
      title: 'Successful Deletion',
      message: 'Index Rate Option has been Deleted',
    })
  }
  dispatch(loadIndexRateOptions())
  return
}
