export const GET_ALL_DRAFT_NOTICES = 'notices/GET_ALL_DRAFT_NOTICES'
export const GET_ALL_SUBMITTED_NOTICES = 'notices/GET_ALL_SUBMITTED_NOTICES'
export const GET_ALL_ACTIVE_NOTICES = 'notices/GET_ALL_ACTIVE_NOTICES'
export const GET_ALL_LOCKED_NOTICES = 'notices/GET_ALL_LOCKED_NOTICES'
export const GET_NOTICE = 'notices/GET_NOTICE'
export const SUBMIT_NOTICE = 'notices/SUBMIT_NOTICE'
export const APPROVE_NOTICE = 'notices/APPROVE_NOTICE'
export const REJECT_NOTICE = 'notices/REJECT_NOTICE'
export const PREVIEW_NOTICE = 'notices/PREVIEW_NOTICE'
export const START_LOADING_ACTIVE_NOTICES = 'notices/START_LOADING_ACTIVE_NOTICES'
export const STOP_LOADING_ACTIVE_NOTICES = 'notices/STOP_LOADING_ACTIVE_NOTICES'
export const START_LOADING_LOCKED_NOTICES = 'notices/START_LOADING_LOCKED_NOTICES'
export const STOP_LOADING_LOCKED_NOTICES = 'notices/STOP_LOADING_LOCKED_NOTICES'
