import * as types from './types'

export const getFacilityAmendments = (payload: any) => ({
  type: types.GET_FACILITY_AMENDMENTS,
  payload,
})

export const getFacilityAmendment = (payload: any) => ({
  type: types.GET_FACILITY_AMENDMENT,
  payload,
})

export const nullFacilityAmendment = (payload: any) => ({
  type: types.NULL_FACILITY_AMENDMENT,
  payload,
})

export const addFacilityAmendment = (payload: any) => ({
  type: types.ADD_FACILITY_AMENDMENTS,
  payload,
})

export const editFacilityAmendment = (payload: any) => ({
  type: types.EDIT_FACILITY_AMENDMENTS,
  payload,
})

export const submitFacilityAmendment = (payload: any) => ({
  type: types.SUBMIT_FACILITY_AMENDMENT,
  payload,
})

export const rejectFacilityAmendment = (payload: any) => ({
  type: types.REJECT_FACILITY_AMENDMENT,
  payload,
})

export const approveFacilityAmendment = (payload: any) => ({
  type: types.APPROVE_FACILITY_AMENDMENT,
  payload,
})

export const deleteFacilityAmendment = (payload: any) => ({
  type: types.DELETE_FACILITY_AMENDMENT,
  payload,
})

export const changeFacilityFees = (payload: any) => ({
  type: types.CHANGE_FACILITY_FEES_AMENDMENT,
  payload,
})

export const changeFacilityAmortization = (payload: any) => ({
  type: types.CHANGE_FACILITY_AMORTIZATION_AMENDMENT,
  payload,
})

export const startLoadingFacilityAmendments = () => ({  
  type: types.START_LOADING_FACILITY_AMENDMENTS,
  payload: null,
})

export const stopLoadingFacilityAmendments = () => ({
  type: types.STOP_LOADING_FACILITY_AMENDMENTS,
  payload: null,
})

