import { Button, ButtonProps, Text } from '@mantine/core'
import { ReactNode } from 'react'

type Props = ButtonProps & {
  text?: string
  onClick?: (e?: any) => void
  children?: ReactNode
}

function PrimaryButton({ text, onClick, children, ...props }: Props) {
  return (
    <Button
      bg="#32439A"
      w="120px"
      h="4em"
      style={{ borderRadius: '4px' }}
      loading={props.loading}
      onClick={onClick}
      styles={{
        root: {
          '&:hover': {
            background: '#02136a',
          },
        },
      }}
      {...props}
    >
      <Text
        color="#262626"
        fz="1.3em"
        fw={600}
        style={{
          fontFamily: 'Plus Jakarta Sans',
          color: 'white',
        }}
      >
        {text ?? children}
      </Text>
    </Button>
  )
}

export default PrimaryButton
