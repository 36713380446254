import React from 'react'
import { PageLayout } from 'app/views/components/layout/page-layout'
import { LenderAllocationsDrillPage } from './lender-allocations-drill-page'


export function LenderAllocationsLandingPage(tab: { tab: string }) {
    return (
        <PageLayout>
            <LenderAllocationsDrillPage tab={tab.tab} />
        </PageLayout>
    )
}