import { Stack } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getFacilityHistory } from 'app/state/ducks/facilities/selectors'
import { loadFacilityHistory } from 'app/state/ducks/facilities/thunks'
import moment from 'moment'
import { formatNumberToCurrency } from 'app/utils/util-functions'
import MainTable from 'app/views/components/Table/MainTable'
import { CleanedFacilityHistoryData, FacilityHistoryData } from 'app/models/facility-params'
import { getColumnDefs } from './table-columnDefs-facility-history'

type Props = {
  close: any
  facilityId: string
}

export default function HistoryForm({ facilityId }: Props) {
  const facilityHistory = useSelector(getFacilityHistory)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadFacilityHistory(facilityId))
  }, [facilityId])


  function cleanFacilityHistory(
    facilityHistory: FacilityHistoryData[],
  ): CleanedFacilityHistoryData[] {
    return facilityHistory.map((item): CleanedFacilityHistoryData => {
      return {
        'Type': item.actionType,
        'Start Date': item.facility.startDate,
        'End Date': item.facility.maturity,
        'Amount': formatNumberToCurrency(Number(item.facility.amount), item.facility?.baseCurrency ?? 'USD'),
        'Days': moment(item.facility.maturity).diff(moment(item.facility.startDate), 'days'),
        'History Date': item.actionDate.toString(),
        'History Time': moment(item.actionTime).format('hh:mm A'),
      };
    });
  }


  return (
    <Stack w="100%">
      <div
        style={{
          backgroundColor: '#A4CAFE',
          width: '100%',
          padding: '10px',
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold',
        }}
      >
        Facility History
      </div>
      <MainTable
        tableName='Loan History'
        csvExportEnabled={true}
        columnDefs={getColumnDefs()}
        enableTopToolbar={false}
        headerBackgroundColor='#F0EEEE'
        data={cleanFacilityHistory(facilityHistory)}
        setRow={() => {
        }}
      />
    </Stack>
  )
}