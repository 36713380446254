import * as types from './types'

export const getSchedulerAmortizationByFacility = (payload: any) => ({
  type: types.GET_SCHEDULER_AMORTIZATION_BY_FACILITY,
  payload,
})

export const changeSchedulerAmortizationForFacility = (payload: any) => ({
  type: types.CHANGE_SCHEDULER_AMORTIZATION_FOR_FACILITY,
  payload,
})

export const editSchedulerAmortization = (payload: any) => ({
  type: types.EDIT_SCHEDULER_AMORTIZATION,
  payload,
})

export const deleteSchedulerAmortization = (payload: any) => ({
  type: types.DELETE_SCHEDULER_AMORTIZATION,
  payload,
})

export const resetFacilitySchedulerAmortization = () => ({
  type: types.RESET_FACILITY_SCHEDULER_AMORTIZATION,
  payload: null,
})

export const changeAmortizationRowStatus = (payload: any) => ({
  type: types.CHANGE_AMORTIZATION_ROW_STATUS,
  payload,
})

