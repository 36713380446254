import { User } from 'oidc-client-ts'
import { env } from './env'

const config = {
  company: env[`REACT_APP_COMPANY`],
  oauth: {
    authority: env[`REACT_APP_OAUTH_AUTHORITY`],
    clientId: env[`REACT_APP_OAUTH_CLIENT_ID`],
    connection: env[`REACT_APP_OAUTH_CONNECTION`],
  },
  backendUrl: env[`REACT_APP_BACKEND_URL`],
  bucketUrl: env[`REACT_S3_BUCKET_URL`],
  appVersion: env['REACT_APP_VERSION'],
  releaseDate: env['REACT_APP_RELEASE_DATE'],
  noticesEmail: env['REACT_APP_NOTICES_EMAIL'],
}

export type Config = typeof config

export default config

// export const getGlobalConfig = async () => config

export const authToken = (config: Config) => {
  const storageString = sessionStorage.getItem(
    `oidc.user:${config.oauth.authority}:${config.oauth.clientId}`
  )
  return storageString ? User.fromStorageString(storageString) : null
}

export const setTokens = (
  config: Config,
  accessToken: string,
  idToken: string,
  refreshToken: string
) => {
  const storageString = sessionStorage.getItem(
    `oidc.user:${config.oauth.authority}:${config.oauth.clientId}`
  )

  if (storageString != undefined) {
    const user = JSON.parse(storageString)

    user.access_token = accessToken
    user.id_token = idToken
    user.refresh_token = refreshToken

    sessionStorage.setItem(
      `oidc.user:${config.oauth.authority}:${config.oauth.clientId}`,
      JSON.stringify(user)
    )
  }
}
