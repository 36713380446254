import { LoanRateSettingParams } from 'app/models/loan-rate-setting-params'
import { LoanRateSettingsState } from './reducer'

export const getLoanRateSettings = (state: {
  loanRateSettings: { loanRateSettings: LoanRateSettingParams[] }
}) => state.loanRateSettings.loanRateSettings

export const getApprovedLoanRateSettings = (state: {
  loanRateSettings: { approvedLoanRateSettings: LoanRateSettingParams[] }
}) => state.loanRateSettings.approvedLoanRateSettings

export const getLoanRateSettingsLoader = (state: { loanRateSettings: LoanRateSettingsState }) => 
  state.loanRateSettings.isLoading 

