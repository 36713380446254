import { Accordion, Grid } from '@mantine/core'
import { NotesAccordion } from '../Accordion/notes-accordion'
import { ContractType } from 'app/models/common-types'
import { AttachmentsAccordion } from '../Accordion/attachments-accordion/attachments-accordion'

type Props = {
  itemId: string,
  type: ContractType
  itemAdmin: string
}

export default function NotesAndAttachment({ itemId, itemAdmin, type }: Props) {
  return (
    <Accordion
      defaultValue={['pdfs', 'notes']}
      multiple
      variant="contained"
      bg="white"
      styles={{
        control: {
          fontFamily: 'Plus Jakarta Sans',
          '&[data-active]': {
            backgroundColor: '#A4CAFE',
          },
        },
      }}
    >
      <Grid justify={'space-between'}>
        <Grid.Col span={6}>
          <NotesAccordion contractId={itemId} contractAdmin={itemAdmin} type={type} fullSize={true} />
        </Grid.Col>
        <Grid.Col span={6}>
          <AttachmentsAccordion id={itemId} type={type} />
        </Grid.Col>
      </Grid>
    </Accordion>
  )
}
