import { Stack } from '@mantine/core'
import { StableKey } from 'app/models/common-types'
import { LendersPosition } from 'app/models/lenders-position-params'
import { LoanParams } from 'app/models/loan-params'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { getPercentage } from 'app/state/ducks/lenders-positions/thunks'
import React from 'react'
import { useSelector } from 'react-redux'
import { formatNumberToCurrency } from 'app/utils/util-functions'
import MainTable from 'app/views/components/Table/MainTable'
import { getColumnDefs } from './table-columnDefs-ecf-decision'
import { LenderPaymentBreakdown } from 'app/models/principal-payments-params'

interface Props {
  form: any
  loan: LoanParams
  changeAcceptedLenders: (lender: LendersPosition, decision: boolean) => void
  activeLenders: LendersPosition[]
  lenderBreakdown: LenderPaymentBreakdown[]
}
export const ECFDecision = ({ form, loan, changeAcceptedLenders, activeLenders, lenderBreakdown }: Props) => {
  const entities = useSelector(getEntities)

  function cleanLenderBreakdown(lenders: LendersPosition[]) {
    return lenders.map((lender: LendersPosition) => {
      const paymentEffectiveDate = form.values.paymentDate
      const percentage = getPercentage(lender, paymentEffectiveDate)
      const lenderWithAmount = lenderBreakdown.find(l => l.lenderId.id === lender.lender.id)
      const finalAmount = form.values.interestPaid === 'true' ? Number(lenderWithAmount?.amount ?? 0.0) + Number(lenderWithAmount?.interest ?? 0.0) : Number(lenderWithAmount?.amount ?? 0.0)
      const decision = form.values.acceptedLenders?.find((lenderId: StableKey) => lenderId.id === lender.lender.id)
      const entity = entities?.find(entity => entity.id === lender.lender.id)


      return {
        'Lender': entity?.entityName ?? '',
        'Payment Amount': formatNumberToCurrency(Number(finalAmount), loan.currency),
        'Percentage': `${percentage}%`,
        'Expected Amount': formatNumberToCurrency(Number(decision ? finalAmount : 0), loan.currency),
        'Decision': decision,
        'Action': () => changeAcceptedLenders(lender, !decision)
      }
    })
  }

  return (
    <Stack w="100%" spacing={0}>
      <div
        style={{
          backgroundColor: '#A4CAFE',
          width: '100%',
          padding: '10px',
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold',
        }}
      >
        ECF Decision
      </div>
      <MainTable
        tableName='Loan History'
        csvExportEnabled={true}
        columnDefs={getColumnDefs()}
        data={cleanLenderBreakdown(activeLenders)}
        enableTopToolbar={false}
        headerBackgroundColor='#F0EEEE'
        setRow={() => {
        }}
        withPagination={false}
        minHeight={'100px'}
      />
    </Stack>

  )
}
