import * as types from './types'

export const getJobs = (payload: any) => ({
  type: types.GET_JOBS,
  payload,
})

export const getJob = (payload: any) => ({
  type: types.GET_JOB,
  payload,
})

export const addJob = (payload: any) => ({
  type: types.ADD_JOBS,
  payload,
})

export const editJob = (payload: any) => ({
  type: types.EDIT_JOBS,
  payload,
})

export const deleteJobs = (payload: any) => ({
  type: types.DELETE_JOBS,
  payload,
})