import { MRT_ColumnDef } from 'mantine-react-table'
import { Text } from '@mantine/core'
import { ReactNode } from 'react'
import { CleanedFundManagerData } from 'app/models/lenders-position-params'

function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}
export function getColumnDefs(getTotalAmount: () => string): MRT_ColumnDef<CleanedFundManagerData>[] {

  return [

    {
      size: 50,
      accessorKey: 'Fund Manager / Parent',
      header: 'Fund Manager / Parent',
      Header: <TableHead header={'Fund Manager / Parent'} />,
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
      Footer: () => (<div style={{ fontSize: '1.7vh', fontWeight: 'bold', color: 'black' }}>Total</div>),
    },

    {
      size: 50,
      accessorKey: 'Allocation',
      header: 'Allocation',
      Header: <TableHead header={'Allocation'} />,
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
      Footer: () => <div style={{ fontSize: '1.7vh', fontWeight: 'bold', color: 'black' }}>{getTotalAmount()}</div>
    },

    {
      size: 50,
      accessorKey: 'MEI',
      header: 'MEI',
      Header: <TableHead header={'MEI'} />,
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },

    {
      size: 50,
      accessorKey: 'Percentage',
      header: 'Percentage',
      Header: <TableHead header={'Pro-rata Share'} />,
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },
  ]
}
