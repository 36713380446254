import { Group, Avatar, Tooltip, Accordion, Text } from '@mantine/core'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import cleanUUID from 'app/views/components/functions/cleanUUID'
import getInitials from 'app/views/components/functions/getInitials'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { DealParams } from 'app/models/deal-params'
import { formatDateToUTC, formatNumberToCurrency, stringToDate } from 'app/utils/util-functions'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'

interface Props {
  deal?: DealParams
}

export function DealDetailsAccordion({ deal }: Props) {
  const dispatch = useDispatch()
  const entities = useSelector(getEntities)
  const businessDate = useSelector(getBusinessDate)
  const entityIdToName = new Map<string, string>()

  useEffect(() => {
    dispatch(loadEntities())
  }, [])

  if (Array.isArray(entities)) {
    for (const entity of entities) {
      if (entity.id) {
        entityIdToName.set(entity.id, entity.entityName)
      }
    }
  }

  return (
    <Accordion.Item value="DealDeatils">
      <Accordion.Control fw={600} fz="14px" p="lg">
        Deal Details
      </Accordion.Control>
      <Accordion.Panel>
        <Group position="apart">
          <Text
            fz="14px"
            fw={800}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            Deal Assignee
          </Text>
          <Tooltip
            label={entityIdToName.get(cleanUUID(deal?.adminEntity?.id ?? ''))}
          >
            <Avatar radius="xl" color="blue">
              {getInitials(
                entityIdToName.get(cleanUUID(deal?.adminEntity?.id ?? ''))
              )}
            </Avatar>
          </Tooltip>
        </Group>
        <Group position="apart">
          <Text
            fz="14px"
            fw={800}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            Borrowers
          </Text>
          <Group>
            {deal?.borrowers?.map((e: { id: string }, index: number) => {
              return (
                <Tooltip
                  label={entityIdToName.get(cleanUUID(e.id))}
                  key={'dealtooltip-' + index}
                >
                  <Avatar radius="xl" color="blue">
                    {getInitials(entityIdToName.get(cleanUUID(e.id)))}
                  </Avatar>
                </Tooltip>
              )
            })}
          </Group>
        </Group>
        <Group position="apart">
          <Text
            fz="14px"
            fw={800}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            Total Amount
          </Text>
          <Text
            fz="14px"
            fw={400}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            {formatNumberToCurrency(Number(deal?.amount), deal?.currency ?? 'USD')}
          </Text>
        </Group>
        <Group position="apart">
          <Text
            fz="14px"
            fw={800}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            Currency
          </Text>
          <Text
            fz="14px"
            fw={400}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            {deal?.currency}
          </Text>
        </Group>
        <Group position="apart">
          <Text
            fz="14px"
            fw={800}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            Start Date
          </Text>
          <Text
            fz="14px"
            fw={400}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            {formatDateToUTC(deal?.startDate ? stringToDate(deal?.startDate) : businessDate)}
          </Text>
        </Group>
        <Group position="apart">
          <Text
            fz="14px"
            fw={800}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            End Date
          </Text>
          <Text
            fz="14px"
            fw={400}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            {formatDateToUTC(deal?.maturity ? stringToDate(deal?.maturity) : businessDate)}
          </Text>
        </Group>
        <Group position="apart">
          <Text
            fz="14px"
            fw={800}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            Deal Ref ID
          </Text>
        </Group>
      </Accordion.Panel>
    </Accordion.Item>
  )
}
