import { Menu } from '@mantine/core'
import PrimaryDropDown from 'app/views/components/DropDown/DropDown'
import { ServicingParams } from 'app/models/servicing-params'
import { useNavigate } from 'react-router-dom'
import IonIcon from '@reacticons/ionicons'

interface actionParam {
  row: ServicingParams
}

export function FacilityAmortizationServicingActions({ row }: actionParam) {
  const navigate = useNavigate()
  async function openAmortizationSchedule() {
    navigate(`/dealmanagement/facilities/${row.id}/${row.status}/amortization-schedule`)
  }
  async function openLoans() {
    navigate(
      '/dealmanagement/facilities/' +
      row.id +
      '/' +
      row.status +
      '?tab=loans'
    )
  }
  return (
    <div>
      <Menu shadow="md" width={200} position="bottom" withinPortal>
        <Menu.Target>
          <div style={{ width: 'fit-content' }}>
            <PrimaryDropDown>Actions</PrimaryDropDown>
          </div>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
            onClick={() => openAmortizationSchedule()}
          >
            Scheduler
          </Menu.Item>
          <Menu.Item
            icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
            onClick={() => openLoans()}
          >
            Payment
          </Menu.Item>

        </Menu.Dropdown>
      </Menu>
    </div>
  )
}