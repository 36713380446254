import { JobParams } from 'app/models/job-params'

export const getJobs = (state: {
  jobs: { jobs: JobParams[] }
}) => {
  return state.jobs.jobs
}

export const getJob = (state: { jobs: { job: JobParams } }) => {
  return state.jobs.job
}
