import { useCallback } from 'react'
import { Assignment } from '../params'
import { LendersPosition } from 'app/models/lenders-position-params'
import { UseListStateHandlers } from '@mantine/hooks'

type UseDeleteAssignmentAdjustProps = {
  updatedLendersPositions: LendersPosition[]
  updatedLendersPositionsHandler: UseListStateHandlers<LendersPosition>
  assignmentsList: Assignment[]
  selectedRow: number | null
  findEntityName: (id: string) => string
  findContactId: (id: string) => string
  findWireInstructionId: (id: string) => string
  createNewLenderPosition: (trade: any, id: string) => LendersPosition
}

const useDeleteAssignmentAdjust = ({
  updatedLendersPositions,
  updatedLendersPositionsHandler,
  assignmentsList,
  selectedRow,
  findEntityName,
  findContactId,
  findWireInstructionId,
  createNewLenderPosition,
}: UseDeleteAssignmentAdjustProps) => {
  const deleteAssignmentAdjust = useCallback(
    (assignment: Assignment) => {
      let findFrom = false

      const newLendersPositions = updatedLendersPositions.map(lender => {
        if (lender.lender.id === assignment.from) {
          lender.amount = (
            Number(lender.amount) + Number(assignment.amount)
          ).toString()
        }
        if (lender.lender.id === assignment.to) {
          lender.amount = (
            Number(lender.amount) - Number(assignment.amount)
          ).toString()
        }

        if (lender.lender.id === assignment.to) {
          return {
            ...lender,
            amount: Number(lender.amount) > 0 ? lender.amount : '0',
          }
        }

        if (lender.lender.id === assignmentsList[selectedRow ?? 0].from) {
          findFrom = true
        }

        return lender
      })

      if (!findFrom) {
        const newLender = createNewLenderPosition(
          assignmentsList[selectedRow ?? 0],
          assignmentsList[selectedRow ?? 0].from
        )
        newLendersPositions.push(newLender)
      }

      updatedLendersPositionsHandler.setState(newLendersPositions)
    },
    [
      updatedLendersPositions,
      updatedLendersPositionsHandler,
      assignmentsList,
      selectedRow,
      findEntityName,
      findContactId,
      findWireInstructionId,
    ]
  )

  return deleteAssignmentAdjust
}

export default useDeleteAssignmentAdjust
