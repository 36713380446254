import {
  Grid,
  MultiSelect,
  NumberInput,
  Select,
  SelectItem,
  TextInput,
} from '@mantine/core'
import { useForm, isNotEmpty } from '@mantine/form'
import cleanUUID from 'app/views/components/functions/cleanUUID'
import config from 'app/config/config'
import { useDispatch, useSelector } from 'react-redux'
import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { DraftDealParams } from 'app/models/deal-params'
import { saveDeal } from 'app/state/ducks/deals/thunks'
import { EntityParams } from 'app/models/entity-params'
import CustomDatePicker from 'app/views/components/date-picker/date-picker-logic'
import {
  currencyOptions, strBooleanOptions,
} from 'app/models/dropdown-options'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { getContacts } from 'app/state/ducks/contacts/selectors'
import moment from 'moment'
import { saveDealAmendment } from 'app/state/ducks/deal-amendments/thunks'
import { ErrorNotification, SuccessNotification } from 'app/views/components/notifications/notification'
import { getCalendars } from 'app/state/ducks/holiday-calendars/selectors'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import { formatDateToUTC, formatToIsoCurrencyFormat, stringToDate } from 'app/utils/util-functions'
import FormWrapper from 'app/views/components/Form/FormWrapper'

export interface Props {
  deal?: DraftDealParams
  dealAmendment?: DraftDealParams
  amendment?: string | null
  setSaveButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>
  setDisabledSteps: React.Dispatch<React.SetStateAction<number[]>>
  setDeal: React.Dispatch<React.SetStateAction<any>>
  setAmendment: React.Dispatch<React.SetStateAction<any>>
}

export interface ChildDealRef {
  handleClick: () => void
}

const UpdatedDealsModal: React.ForwardRefRenderFunction<ChildDealRef, Props> = (
  {
    deal,
    amendment,
    dealAmendment,
    setSaveButtonDisabled,
    setDisabledSteps,
    setDeal,
    setAmendment,
  },
  ref
) => {
  const entities = useSelector(getEntities)
  const contacts = useSelector(getContacts)
  const holidayCalendars = useSelector(getCalendars)
  const businessDate = useSelector(getBusinessDate)
  const dispatch = useDispatch()

  const holidayCalendarsOptions = holidayCalendars?.map(calendar => ({ label: calendar.name, value: calendar.id ?? '' }))
  const [loading, setLoading] = useState<boolean>(false)
  deal = dealAmendment ? dealAmendment : deal

  const form = useForm({
    initialValues: {
      dynamicNameObj: {
        borrower:
          entities && deal && deal.borrowers
            ? entities.find(
              entity => entity.id === cleanUUID(deal?.borrowers[0]?.id)
            )?.entityName
            : '',
        amount: deal?.amount ?? '',
        date: deal?.startDate ? stringToDate(deal?.startDate) : businessDate,
        currency: deal?.currency ? deal?.currency : 'USD',
      },
      id: deal?.id ?? undefined,
      dealName: deal?.dealName ?? '',
      amount: Number(deal?.amount) ?? 0,
      utilizedAmount: deal?.utilizedAmount ?? 0,
      currency: deal?.currency ?? '',
      approve: deal?.approve ?? false,
      isPricingGrid: deal?.isPricingGrid == true ? 'true' : 'false',
      holidayCalendar: deal?.holidayCalendar?.map((b: any) => {
        return b.id
      }) ?? [],
      cusip: deal?.cusip,
      isin: deal?.isin,
      lxId: deal?.lxId ?? '',
      bbgId: deal?.bbgId ?? '',
      borrowers:
        deal?.borrowers?.map((b: any) => {
          return b.id
        }) ?? [],
      guarantors:
        deal?.guarantors?.map((b: any) => {
          return b.id
        }) ?? [],
      adminEntity: cleanUUID(deal?.adminEntity?.id ?? ''),
      startDate: deal?.startDate ? stringToDate(deal.startDate) : businessDate,
      maturity: deal?.maturity ? stringToDate(deal.maturity) : businessDate,
      precedentDate: deal?.precedentDate
        ? stringToDate(deal.precedentDate)
        : businessDate,
      closing: deal?.closing ? stringToDate(deal.closing) : businessDate,
      opsAdmin: cleanUUID(deal?.opsAdmin?.id ?? ''),
      amendmentDate: amendment
        ? deal?.amendmentDate ? stringToDate(deal.amendmentDate) : businessDate
        : undefined,
      fees: deal?.fees ?? [],
      pricingGrid: deal?.pricingGrid ?? [],
      hasError: false,
      hasSuccessfulEntitySave: false,
      ErrorMessage: '',
      customer_token: config.company.toUpperCase() ?? '',
    },
    transformValues: values => {
      return {
        ...values,
        isPricingGrid: values.isPricingGrid == 'false' ? false : true,
      }
    },

    validate: {
      dealName: isNotEmpty('Deal Name cannot be empty'),
      amount: value => (value > 0 ? null : 'Invalid amount'),
      borrowers: value =>
        value && value.length > 0 ? null : 'Borrowers list cannot be empty',
      lxId: value =>
        value == '' || value.length === 8 ? null : 'Invalid amount',
      bbgId: value =>
        value == '' || value.length === 9 ? null : 'Invalid amount',
    },
  })

  React.useEffect(() => {
    setSaveButtonDisabled(!form.isValid())
  }, [form.isValid, setSaveButtonDisabled])


  const onSubmit = async (dealValues: any) => {
    setLoading(true)
    // here we are adding admin into borrwers that is array
    const entityMap = new Map(
      entities.map(entity => [entity.id, entity.maker])
    )

    dealValues.borrowers = dealValues.borrowers.map(
      (id: string) => ({ id, admin: entityMap.get(id) })
    )
    dealValues.guarantors = dealValues.guarantors.map(
      (id: string) => ({ id, admin: entityMap.get(id) })
    )
    dealValues.holidayCalendar = dealValues.holidayCalendar.map((id: string) => {
      const curCal = holidayCalendars.find(({ id }) => id === id)
      return ({ id, admin: curCal?.public })
    })

    const adminEntity = dealValues.adminEntity
      ? {
        id: dealValues.adminEntity,
        admin: entityMap.get(dealValues.adminEntity),
      }
      : dealValues.adminEntity

    // here we are adding admin into borrwers that is array
    const opsAdmin =
      contacts.find(contact => contact.id === dealValues.opsAdmin) ?? null
    try {
      let response: any = null
      const isPricingGrid: boolean = dealValues.isPricingGrid == 'true' ? true : false
      const submissionValues = {
        ...dealValues,
        amendmentDate: amendment
          ? formatDateToUTC(dealValues.amendmentDate)
          : undefined,
        startDate: formatDateToUTC(dealValues.startDate),
        maturity: formatDateToUTC(dealValues.maturity),
        closing: formatDateToUTC(dealValues.closing),
        precedentDate: formatDateToUTC(dealValues.startDate),
        opsAdmin: opsAdmin ? { id: opsAdmin.id, admin: opsAdmin.maker } : null,
        isPricingGrid: isPricingGrid,
        pricingGrid: isPricingGrid ? dealValues.pricingGrid : [],
        adminEntity: adminEntity
          ? { id: adminEntity.id, admin: adminEntity.admin }
          : null,
      }
      // if(!submissionValues.isPricingGrid){

      // }
      if (amendment) {
        let dealAmendmentId = null
        dealAmendmentId = dealAmendment?.id ? dealAmendment?.id : null
        response = await dispatch(
          saveDealAmendment(submissionValues, dealAmendmentId)
        )
        setAmendment(response.payload)
      } else {
        response = await dispatch(saveDeal(submissionValues, !!dealValues.id))
        setDeal(response.payload)
      }

      setLoading(false)

      if (submissionValues.isPricingGrid) {
        setDisabledSteps([3, 4, 5, 6, 7, 8, 9, 10])
      } else {
        setDisabledSteps([2, 3, 4, 5, 6, 7, 8, 9, 10])
      }
      if (response.success) {

        SuccessNotification({
          title: 'Successful Deal Creation',
          message:
            'You can add a facility to your deal by clicking on edit deal.',
        })
      } else {
        ErrorNotification({
          title: 'Failed Deal Creation',
          message:
            response.payload ?? 'Please check all fields and resubmit.',
        })
      }
    } catch {
      setLoading(false)
    }
  }

  const handleClick = async () => {
    await onSubmit(form.values)
  }

  // Expose the handleClick method to the parent component
  useImperativeHandle(ref, () => ({
    handleClick,
  }))

  const borrowers: SelectItem[] = entities
    ?.filter(entity => entity.entityProfile.includes('BorrowerLending') || entity.entityProfile.includes('BorrowerTrading'))
    .map(entity => ({ label: entity.entityName, value: entity.id ?? '' }));

  const guarantors: SelectItem[] = entities
    ?.filter(entity => entity.entityProfile.includes('Guarantor'))
    .map(entity => ({ label: entity.entityName, value: entity.id ?? '' }));

  const adminAgents: SelectItem[] = entities
    ?.filter(entity => entity.entityProfile.includes('AdminAgentWeOperate') || entity.entityProfile.includes('AdminAgent'))
    .map((entity: EntityParams) => {
      return { label: entity.entityName + ' - ' + entity.mei, value: entity.id ?? '' }
    })

  const filterOpsAdminContact = (selected: string) => {
    form.setFieldValue('opsAdmin', '')
    form.setFieldValue('adminEntity', selected)
  }

  const updateDynamicName = (
    key: keyof typeof form.values.dynamicNameObj,
    value: string | any
  ) => {
    const dynaobj = form.values.dynamicNameObj
    if (key == 'borrower') {
      const entityName =
        entities.find(entity => entity.id === cleanUUID(value))?.entityName ??
        ''
      dynaobj['borrower'] = entityName
    } else {
      dynaobj[key] = value
    }
    let formattedAmount = ''
    if (Number(dynaobj.amount) > 0) {
      formattedAmount = formatToIsoCurrencyFormat(
        Number(dynaobj.amount),
        form.values.currency ?? 'USD',
        1,
        1,
        'compact'
      )
    }
    const exportName =
      dynaobj.borrower +
      ' ' +
      formattedAmount +
      ' ' +
      moment(dynaobj.date).format('YYYY/MM/DD').toString()
    form.setFieldValue('dynamicNameObj', dynaobj)
    form.setFieldValue('dealName', exportName)
  }

  const handleCurrencyChange = (e: string) => {
    form.setFieldValue('currency', e)
    updateDynamicName('currency', e)
  }

  const handleAmountChange = (e: number) => {
    form.setFieldValue('amount', e)
    updateDynamicName('amount', e)
  }

  const handleBorrowersChange = (e: string[]) => {
    if (e.length <= 0) {
      form.setFieldValue('borrowers', [])
      form.setFieldValue('borrowerPaymentInstructions', [])
      updateDynamicName('borrower', '')
    } else {
      form.setFieldValue('borrowers', e)
      updateDynamicName('borrower', e[0])
    }
  }

  return (
    <FormWrapper title={`${deal ? 'Edit' : 'Create'} Deal`}>
      <Grid grow>
        {amendment && (
          <Grid.Col span={12} style={{ paddingTop: '0px' }}>
            <CustomDatePicker
              label={'Amendment Date'}
              date={form.values.amendmentDate ?? businessDate}
              setDate={(value: any) => {
                form.setFieldValue('amendmentDate', value)
              }}
              holidayCalendars={
                deal?.holidayCalendar ?? form.values.holidayCalendar ?? []
              }
            />
          </Grid.Col>
        )}
        <Grid.Col span={12} style={{ paddingTop: '0px' }}>
          <TextInput
            disabled
            name="dealName"
            id="dealName"
            label="Deal Name"
            placeholder="Deal Name is Auto-Generated"
            {...form.getInputProps('dealName')}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <Select
            searchable
            label="Currency"
            placeholder="Select a Currency"
            data={currencyOptions ?? []}
            onChange={(e: string) => {
              handleCurrencyChange(e)
            }}
            value={form.values.currency}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <TextInput
            label="Deal CUSIP"
            name="cusip"
            id="cusip"
            placeholder="Enter CUSIP"
            minLength={9}
            maxLength={9}
            disabled={amendment != null ? true : false}
            {...form.getInputProps('cusip')}
            onChange={(e) => form.setFieldValue('cusip', e.target.value.toUpperCase())}
            error={
              !form.values.cusip || form.values.cusip.length == 9
                ? null
                : 'Cusip Should Be 9 Characters'
            }
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <TextInput
            label="Deal ISIN"
            name="isin"
            id="isin"
            placeholder="Enter ISIN"
            minLength={12}
            maxLength={12}
            disabled={amendment != null ? true : false}
            {...form.getInputProps('isin')}
            onChange={(e) => form.setFieldValue('isin', e.target.value.toUpperCase())}
            error={
              !form.values.isin || form.values.isin.length == 12
                ? null
                : 'ISIN Should Be 12 Characters'
            }
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <TextInput
            label="Deal LXID"
            name="lxId"
            id="lxId"
            placeholder="Enter Deal LXID"
            minLength={8}
            maxLength={8}
            disabled={amendment != null ? true : false}
            {...form.getInputProps('lxId')}
            onChange={(e) => form.setFieldValue('lxId', e.target.value.toUpperCase())}
            error={
              !form.values.lxId || form.values.lxId.length == 8
                ? null
                : 'LXID Should Be 8 Characters'
            }
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            label="Deal BBG ID"
            name="bbgId"
            id="bbgId"
            placeholder="Enter Deal Bloomberg Id"
            minLength={9}
            maxLength={9}
            disabled={amendment != null ? true : false}
            {...form.getInputProps('bbgId')}
            onChange={(e) => form.setFieldValue('bbgId', e.target.value.toUpperCase())}
            error={
              !form.values.bbgId || form.values.bbgId.length == 9
                ? null
                : 'BBG ID Should Be 9 Characters'
            }
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Select
            searchable
            placeholder="Select agent"
            label="Administrative Agent"
            data={adminAgents ?? []}
            {...form.getInputProps('adminEntity')}
            onChange={filterOpsAdminContact}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <NumberInput
            withAsterisk
            pb={5}
            label="Amount"
            id="amount"
            placeholder="Enter Amount"
            parser={value => (value ? value.replace(/\$\s?|(,*)/g, '') : '')}
            formatter={value =>
              !Number.isNaN(parseFloat(value ?? ''))
                ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                : ''
            }
            onChange={(e: number) => {
              handleAmountChange(e)
            }}
            value={form.values.amount}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <CustomDatePicker
            label={'Start Date'}
            date={form.values.startDate}
            setDate={(value: any) => {
              form.setFieldValue('startDate', value)
              updateDynamicName('date', value)
            }}
            holidayCalendars={
              deal?.holidayCalendar ?? form.values.holidayCalendar ?? []
            }
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <CustomDatePicker
            label={'Maturity Date'}
            date={form.values.maturity}
            setDate={(value: any) => form.setFieldValue('maturity', value)}
            holidayCalendars={
              deal?.holidayCalendar ?? form.values.holidayCalendar ?? []
            }
          />
        </Grid.Col>

        <Grid.Col span={6}>
          <MultiSelect
            searchable
            withAsterisk
            placeholder="Borrowers"
            label="Borrowers"
            name="borrower"
            data={borrowers ?? []}
            onChange={e => handleBorrowersChange(e)}
            value={form.values.borrowers}
          />
        </Grid.Col>

        <Grid.Col span={12}>
          <MultiSelect
            searchable
            placeholder="Guarantors"
            label="Guarantors"
            data={guarantors ?? []}
            {...form.getInputProps('guarantors')}
          />
        </Grid.Col>

        <Grid.Col span={6}>
          <Select
            clearable
            searchable
            w="100%"
            placeholder="Select yes or no"
            label="Pricing Grid"
            data={strBooleanOptions}
            {...form.getInputProps('isPricingGrid')}
          />
        </Grid.Col>

        <Grid.Col span={6}>
          <MultiSelect
            searchable
            label="Holiday Calendar"
            placeholder="Select Holiday Calendars"
            data={holidayCalendarsOptions ?? []}
            {...form.getInputProps('holidayCalendar')}
          />
        </Grid.Col>



        <Grid.Col span={6}>
          <CustomDatePicker
            label={'Date Conditions Precedent Met'}
            date={form.values.precedentDate}
            setDate={(value: any) => form.setFieldValue('precedentDate', value)}
            holidayCalendars={
              deal?.holidayCalendar ?? form.values.holidayCalendar ?? []
            }
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Select
            placeholder="Select Ops Admin"
            searchable
            label="Ops Admin"
            data={
              contacts && contacts.length > 0
                ? contacts
                  .filter(
                    contact =>
                      contact.entity &&
                      contact.entity.id.replace(/['"]/g, '') ===
                      form.values.adminEntity
                  )
                  .map(contact => ({
                    value: contact.id ?? '',
                    label: contact.name,
                  }))
                : []
            }
            {...form.getInputProps('opsAdmin')}
          />
        </Grid.Col>

        <Grid.Col
          span={12}
          style={{ marginTop: 20, marginBottom: 20, display: 'none' }}
        >
          <PrimaryButton
            onClick={handleClick}
            className="form-button"
            loading={loading}
            w="100%"
          >
            Save and Proceed
          </PrimaryButton>
        </Grid.Col>
      </Grid>
    </FormWrapper>
  )
}

export default forwardRef(UpdatedDealsModal)
