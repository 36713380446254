import { Menu } from '@mantine/core'
import PrimaryDropDown from 'app/views/components/DropDown/DropDown'
import { useDispatch } from 'react-redux'
import {
  deleteHolidayCalendar,
  activeHolidayCalendar,
  makeInactiveHolidayCalendar,
} from './holiday-calendar-daml-actions'
import { HolidayCalendarParams } from 'app/models/holiday-calendar-params'
import IonIcon from '@reacticons/ionicons'

type Props = {
  row: HolidayCalendarParams
  setOpenHolidayCalendar: React.Dispatch<React.SetStateAction<boolean>>
  withRedirection?: boolean
  extended?: boolean
}

export default function HolidayCalendarActions({
  row,
  setOpenHolidayCalendar,
}: Props) {
  const dispatch = useDispatch()

  async function activate(): Promise<void> {
    await activeHolidayCalendar(row, dispatch)
  }
  async function deactivate(): Promise<void> {
    await makeInactiveHolidayCalendar(row, dispatch)
  }

  function editCalendar() {
    setOpenHolidayCalendar(true)
  }

  return (
    <div>

      <Menu shadow="md" width={200} position="bottom" withinPortal>
        <Menu.Target>
          <div style={{ width: 'fit-content' }}>
            <PrimaryDropDown>Actions</PrimaryDropDown>
          </div>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
            onClick={() => editCalendar()}
          >
            Edit
          </Menu.Item>

          {row?.status?.toLowerCase() == 'inactive' ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => activate()}
            >
              Activate
            </Menu.Item>
          ) : (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => deactivate()}
            >
              Deactivate
            </Menu.Item>
          )}

          {row?.status?.toLowerCase() == 'inactive' ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name='trash-outline' />}
              onClick={() => {
                deleteHolidayCalendar(row, dispatch)
              }}
            >
              Delete
            </Menu.Item>
          ) : null}
        </Menu.Dropdown>
      </Menu>
    </div>
  )
}
