import { LoanParams } from 'app/models/loan-params'
import {
    Group,
    NumberInput,
    Stack,
    Text,
} from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import CustomDatePicker from 'app/views/components/date-picker/date-picker-logic'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import {
    submitForApproval,
} from 'app/state/ducks/loan-increases/thunks'
import FormWrapperWithSubtitle from '../common/form-wrapper-with-subtitle'
import {
    ErrorNotification,
    SuccessNotification,
} from 'app/views/components/notifications/notification'
import { useState } from 'react'
import { FacilityParams } from 'app/models/facility-params'
import { useDispatch } from 'react-redux'
import { LoanIncreaseParams } from 'app/models/loan-increase-params'
import { formatNumberToCurrency } from 'app/utils/util-functions'


type Props = {
    form: UseFormReturnType<any, (values: any) => any>
    close: () => void
    facility: FacilityParams | undefined
    loan: LoanParams
    increase: LoanIncreaseParams | undefined
    createDraftIncrease: () => Promise<any>
}

export default function IncreaseFormPresentation({
    form,
    close,
    facility,
    loan,
    increase,
    createDraftIncrease,
}: Props) {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)

    const onSubmit = async () => {
        setIsLoading(true)
        const result = await createDraftIncrease()
        setIsLoading(false)
        if (result) close()
    }

    const saveAndSubmit = async () => {
        setIsLoading(true)
        const response: any = await createDraftIncrease()
        try {
            if (!response) {
                return
            }
            const result: any = await dispatch(
                submitForApproval(response.payload.data)
            )
            if (result.success) {
                SuccessNotification({
                    title: 'Successful Increase Submitted',
                    message: 'loan increase subbmitted succesfuly',
                })
            } else {
                ErrorNotification({
                    title: 'Loan Increase created but not submitted.',
                    message: result.payload ?? 'Could not submit Loan Increase',
                })
            }
        } finally {
            close()
            setIsLoading(false)
        }
    }

    return (
        <FormWrapperWithSubtitle
            title={
                (increase ? 'Edit' : 'Create') +
                ' Increase'
            }
            subtitle={
                'Existing Loan Amount: ' + formatNumberToCurrency(Number(loan?.amount ?? 0.0) ?? 0, loan?.currency ?? 'USD')
            }
        >
            <div className="content">
                <form>
                    <div className="create-new">
                        <Stack spacing="xl">
                            {increase && increase?.status !== 'Draft' ? (
                                <Text className="topFormErrorText">
                                    Loan Increase has already been submitted
                                </Text>
                            ) : null}


                            <Group noWrap>
                                <CustomDatePicker
                                    required
                                    label={'Effective Date'}
                                    date={form.values.effectiveDate}
                                    setDate={(value: any) => {
                                        form.setFieldValue('effectiveDate', value)
                                    }}

                                    holidayCalendars={facility?.holidayCalendar ?? []}
                                />

                                <NumberInput
                                    required
                                    w="100%"
                                    precision={2}
                                    label="Increase Amount "
                                    hideControls
                                    parser={value =>
                                        value ? value.replace(/\$\s?|(,*)/g, '') : ''
                                    }
                                    formatter={value =>
                                        !Number.isNaN(parseFloat(value ?? ''))
                                            ? value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                : ''
                                    }
                                {...form.getInputProps('increaseAmount')}
                                />
                            </Group>

                            <Group noWrap>
                                <PrimaryButton
                                    loading={isLoading}
                                    onClick={() => onSubmit()}
                                    disabled={!form.isValid()}
                                    text="Save and Exit"
                                    w="100%"
                                />
                                <PrimaryButton
                                    loading={isLoading}
                                    onClick={() => saveAndSubmit()}
                                    disabled={!form.isValid()}
                                    text="Create and Submit"
                                    w="100%"
                                />
                            </Group>
                        </Stack>
                    </div>
                </form>
            </div>
        </FormWrapperWithSubtitle>
    )
}