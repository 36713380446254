import * as types from './types'
import { createReducer } from 'app/state/utils'

const initialState: never[] = []

const utilizationReducer = createReducer(initialState)({
  [types.GET_UTILIZATION]: (state: any, payload: any) => {
    return { ...state, utilization: payload.payload }
  },
})

export default utilizationReducer
