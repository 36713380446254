import { Button } from '@mantine/core'
import React, { useState } from 'react'
import { CSVLink } from "react-csv";


type Props = {
  exportObj?: object[]
  csvName?: string
  style?: any
}

const ExportToCsv: React.FC<Props> = ({
  exportObj,
  csvName,
  style,
}) => {
  const [data, setData] = useState<any>([])

  // If CSV is empty fix this method, fix race condition with setData
  const formatCSVData = () => {
    if (!exportObj || exportObj.length < 1) {
      return null
    }

    const exportKeys = Object.keys(exportObj[0])
    const exportValues = exportObj.map((item: any) => {
      const values = Object.values(item)

      return values.map((value: any) => {
        if (typeof value === 'string' && (
          value.startsWith('=') ||
          value.startsWith('+') ||
          value.startsWith('-') ||
          value.startsWith('@')
        )) {
          return `'${value}`
        }
        return value
      })
    })

    setData([
      exportKeys,
      ...exportValues
    ]);
  }
  return (
    <Button
      style={style}
    >
      <CSVLink
        style={{ color: style.color || 'black' }}
        filename={csvName ?? 'report.csv'}
        data={data}
        onClick={() => formatCSVData()}
      >
        Export to CSV
      </CSVLink>
    </Button>
  )
}

export default ExportToCsv
