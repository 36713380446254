import { Menu, Modal } from '@mantine/core'
import PrimaryDropDown from 'app/views/components/DropDown/DropDown'
import { useDispatch, useSelector } from 'react-redux'
import { LoanRolloverParams } from 'app/models/loan-rollover-params'
import {
  approveLoanRollover,
  deleteLoanRollover,
  rejectLoanRollover,
  submitLoanRollover,
} from './rollover-daml-actions'
import { useState } from 'react'
import { getAllApprovedLoans } from 'app/state/ducks/loans/selectors'
import RolloverFormLogic from './rollover-form-logic'
import IonIcon from '@reacticons/ionicons'
import { LoanParams } from 'app/models/loan-params'

type Props = {
  row: LoanRolloverParams
}

export default function LoanRolloverActions({ row }: Props) {
  const allLoans = useSelector(getAllApprovedLoans)
  const [loan, setLoan] = useState<LoanParams>()
  const [openNewRollover, setOpenNewRollover] = useState(false)
  const dispatch = useDispatch()

  function openRollover() {
    setLoan(allLoans.find(l => l.id === row.loanId.id))
    setOpenNewRollover(true)
  }


  return (
    <div>
      <Modal
        size="50%"
        className="modal-body modal-body-template fit-content-modal"
        opened={openNewRollover}
        onClose={() => setOpenNewRollover(false)}
      >
        {loan ? <RolloverFormLogic loan={loan} close={() => setOpenNewRollover(false)} /> : null}
      </Modal>
      <Menu shadow="md" width={200} position="bottom" withinPortal>
        <Menu.Target>
          <div style={{ width: 'fit-content' }}>
            <PrimaryDropDown>Actions</PrimaryDropDown>
          </div>
        </Menu.Target>

        <Menu.Dropdown>
          {row?.status?.toLowerCase() == 'draft' ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => openRollover()}
            >
              Edit
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'draft' ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => {
                submitLoanRollover(row, dispatch)
              }}
            >
              Submit For Approval
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'submitted' ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => {
                approveLoanRollover(row, dispatch)
              }}
            >
              Approve
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'approved' ? (
            <Menu.Item icon={<IonIcon className="action-ion-icon" name='open-outline' />}>Amendment</Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() != 'approved' ? (
            <>
              <Menu.Divider />
              <Menu.Label>Danger zone</Menu.Label>
            </>
          ) : null}

          {row?.status?.toLowerCase() == 'draft' ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name='trash-outline' />}
              onClick={() => {
                deleteLoanRollover(row, dispatch)
              }}
            >
              Delete
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'submitted' ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => {
                rejectLoanRollover(row, dispatch)
              }}
            >
              Reject
            </Menu.Item>
          ) : null}
        </Menu.Dropdown>
      </Menu>
    </div>
  )
}
