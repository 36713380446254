import * as types from './types'
import { AssignmentParams } from 'app/models/assignment-params'
import { createReducer } from 'app/state/utils'


export interface AssignmentState {
  assignments: AssignmentParams[]
  assignment?: AssignmentParams
  isLoading: boolean
}

const initialState: AssignmentState = {
  assignments: [],
  assignment: undefined,
  isLoading: false,
}

const assignmentsReducer = createReducer(initialState)({
  [types.GET_ASSIGNMENTS]: (state: AssignmentState, payload: any) => {
    return { ...state, assignments: payload.payload }
  },
  [types.GET_ASSIGNMENT]: (state: AssignmentState, payload: any) => {
    return { ...state, assignment: payload.payload }
  },
  [types.ADD_ASSIGNMENTS]: (state: AssignmentState, payload: any) => {
    return {
      ...state,
      assignments: [
        { ...payload.payload, status: 'Draft' },
        ...state.assignments,
      ],
    }
  },
  [types.EDIT_ASSIGNMENTS]: (state: AssignmentState, payload: any) => {
    const index = state.assignments.findIndex(
      (assignment: { id: any }) => assignment.id === payload.payload.id,
    )
    const newArray = [...state.assignments]
    newArray[index] = { ...payload.payload, status: 'Draft' }
    return {
      ...state,
      assignments: newArray,
    }
  },
  [types.SUBMIT_ASSIGNMENT]: (state: AssignmentState, payload: any) => {
    if (payload.payload.error) {
      return state
    }
    const index = state.assignments.findIndex(
      (assignment: { id: any }) => assignment.id === payload.payload.id,
    )
    const newArray = [...state.assignments]
    newArray[index] = { ...newArray[index], status: 'Submitted' }
    if (state?.assignment?.id === payload.payload.id) {
      return {
        ...state,
        assignment: { ...state.assignment, status: 'Submitted' },
        assignments: newArray,
      }
    }
    return {
      ...state,
      assignments: newArray,
    }
  },
  [types.REJECT_ASSIGNMENT]: (state: AssignmentState, payload: any) => {
    if (payload.payload.error) {
      return state
    }
    const index = state.assignments.findIndex(
      (assignment: { id: any }) => assignment.id === payload.payload.id,
    )
    const newArray = [...state.assignments]
    newArray[index] = { ...newArray[index], status: 'Draft' }
    if (state?.assignment?.id === payload?.payload?.id) {
      return {
        ...state,
        assignment: { ...state.assignment, status: 'Draft' },
        assignments: newArray,
      }
    }
    return {
      ...state,
      assignments: newArray,
    }
  },
  [types.APPROVE_ASSIGNMENT]: (state: AssignmentState, payload: any) => {
    const index = state.assignments.findIndex(
      (assignment: { id: any }) => assignment.id === payload.payload.id,
    )
    const newArray = [...state.assignments]
    newArray[index] = { ...newArray[index], status: 'Approved' }
    if (state?.assignment?.id === payload.payload.id) {
      return {
        ...state,
        assignment: { ...state.assignment, status: 'Approved' },
        assignments: newArray,
      }
    }
    return {
      ...state,
      assignments: newArray,
    }
  },
  [types.DELETE_ASSIGNMENT]: (state: AssignmentState, payload: any) => {
    if (!payload.payload || payload.payload.length === 0) {
      return state
    }
    const deletedIds = payload.payload
      .filter((payload: any) => !payload.error)
      .map((payload: any) => payload.id)
    return {
      ...state,
      assignments: state.assignments.filter(
        (assignment: any) => !deletedIds.includes(assignment.id),
      ),
      dealAmendment:
        state.assignment && deletedIds.includes(state.assignment.id)
          ? null
          : state.assignment,
    }
  },
  [types.START_LOADING_ASSIGNMENTS]: (state: AssignmentState) => {
    return { ...state, isLoading: true }
  },
  [types.STOP_LOADING_ASSIGNMENTS]: (state: AssignmentState) => {
    return { ...state, isLoading: false }
  },
})

export default assignmentsReducer
