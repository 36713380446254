import { LoanRolloverParams } from 'app/models/loan-rollover-params'
import {
  submitForApproval,
  rejectSelectedLoanRollover,
  approveSelectedLoanRollover,
  deleteSelectedLoanRollover,
  loadAllLoanRollovers,
} from 'app/state/ducks/loan-rollovers/thunks'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'
import { Dispatch } from 'redux'

export async function submitLoanRollover(
  loanRollover: LoanRolloverParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(submitForApproval(loanRollover))
  if (response.success) {
    SuccessNotification({
      title: 'Successful Submission',
      message: 'Loan Rollover has been Submitted for Approval',
    })
  } else {
    ErrorNotification({
      title: 'Submission Failed',
      message: response.payload ?? 'Could not submit Loan Rollover',
    })
  }
  dispatch(loadAllLoanRollovers())
  return
}

export async function rejectLoanRollover(
  loanRollover: LoanRolloverParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(rejectSelectedLoanRollover(loanRollover))
  if (response.success) {
    SuccessNotification({
      title: 'Successful Rejection',
      message: 'Loan Rollover has been moved back to a Draft',
    })
  } else {
    ErrorNotification({
      title: 'Rejection Failed',
      message: response.payload ?? 'Could not reject Loan Rollover',
    })
  }
  dispatch(loadAllLoanRollovers())
  return
}

export async function approveLoanRollover(
  loanRollover: LoanRolloverParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(
    approveSelectedLoanRollover(loanRollover)
  )
  if (response.success) {
    SuccessNotification({
      title: 'Successful Approval',
      message: 'Loan Rollover has been Approved',
    })
  } else {
    ErrorNotification({
      title: 'Approval Failed',
      message: response.payload ?? 'Could not approve Loan Rollover',
    })
  }
  dispatch(loadAllLoanRollovers())
  return
}

export async function deleteLoanRollover(
  loanRollover: LoanRolloverParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(deleteSelectedLoanRollover(loanRollover))
  if (response.payload.error) {
    ErrorNotification({
      title: 'Delete Failed',
      message: response.payload.error.message,
    })
  } else {
    SuccessNotification({
      title: 'Successful Deletion',
      message: 'Loan Rollover has been Deleted',
    })
  }
  dispatch(loadAllLoanRollovers())
  return
}
