import { Menu } from '@mantine/core'
import PrimaryDropDown from 'app/views/components/DropDown/DropDown'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { LoanParams } from 'app/models/loan-params'
import { loadAllApprovedLoans } from 'app/state/ducks/loans/thunks'
import { getAllApprovedLoans } from 'app/state/ducks/loans/selectors'
import { getFacilities } from 'app/state/ducks/facilities/selectors'
import { loadAllFacilities } from 'app/state/ducks/facilities/thunks'
import { useEffect } from 'react'
import IonIcon from '@reacticons/ionicons'

type Props = {
  row: LoanParams
}

export default function AddRateSettingActions({ row }: Props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(loadAllApprovedLoans())
    dispatch(loadAllFacilities())
  }, [])

  const allLoans = useSelector(getAllApprovedLoans)
  const allFacilities = useSelector(getFacilities)

  const findFacilityByLoanId = (loanId: string) => {
    const currentLoan = allLoans.find(loan => loan.id && loan.id === loanId)
    const currentFacility = currentLoan?.facilityId
      ? allFacilities.find(
        facility => facility.id && facility.id === currentLoan.facilityId.id
      )
      : null
    return currentFacility
  }

  function navigateToFacility(row: LoanParams) {
    const currentFacility = findFacilityByLoanId(row?.id ?? '')
    if (currentFacility) {
      navigate(
        '/dealmanagement/facilities/' +
        currentFacility.id +
        '/' +
        currentFacility.status +
        '?tab=loans'
      )
    }
    return
  }

  return (
    <div>
      <Menu shadow="md" width={200} position="bottom" withinPortal>
        <Menu.Target>
          <div style={{ width: 'fit-content' }}>
            <PrimaryDropDown>Actions</PrimaryDropDown>
          </div>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
            onClick={() => {
              navigateToFacility(row)
            }}
          >
            Add Rates
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  )
}
