import { MRT_ColumnDef } from 'mantine-react-table'
import { Avatar, Text, Tooltip } from '@mantine/core'
import { ReactNode } from 'react'
import { CleanedFundData } from 'app/models/lenders-position-params'
import getInitials from 'app/views/components/functions/getInitials'

function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}

export function getColumnDefs(getTotalAmount: () => string): MRT_ColumnDef<CleanedFundData>[] {
  return [
    {
      size: 50,
      accessorKey: 'Lender',
      header: 'Lender',
      Header: <TableHead header={'Lender'} />,
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
      Footer: () => (<div style={{ fontSize: '1.7vh', fontWeight: 'bold', color: 'black' }}>Total</div>),
    },

    {
      size: 50,
      accessorKey: 'Fund Manager / Parent',
      header: 'Fund Manager / Parent',
      Header: <TableHead header={'Fund Manager / Parent'} />,
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },
    {
      size: 50,
      accessorKey: 'Allocation',
      header: 'Allocation',
      Header: <TableHead header={'Allocation'} />,
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
      Footer: () => <div style={{ fontSize: '1.7vh', fontWeight: 'bold', color: 'black' }}>{getTotalAmount()}</div>
    },
    {
      size: 50,
      accessorKey: 'MEI',
      header: 'MEI',
      Header: <TableHead header={'MEI'} />,
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },
    {
      size: 50,
      accessorKey: 'Percentage',
      header: 'Percentage',
      Header: <TableHead header={'Pro-rata Share'} />,
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },
    {
      size: 50,
      accessorKey: 'Wire Instructions',
      header: 'Wire Instructions',
      Header: <TableHead header={'Wire Instructions'} />,
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },
    {
      size: 50,
      accessorFn: row => row['Admin Contact'],
      accessorKey: 'Admin Contact',
      header: 'Admin Contact',
      Header: <TableHead header={'Admin Contact'} />,
      Cell: ({ cell }) => {
        return (
          <Tooltip label={cell.getValue<string>()}>
            <Avatar radius="xl" color="cyan" size="lg">
              {getInitials(cell.getValue<string>() as string)}
            </Avatar>
          </Tooltip>
        )
      },
    },
    {
      size: 50,
      accessorFn: row => row['Credit Contact'],
      accessorKey: 'Credit Contact',
      header: 'Credit Contact',
      Header: <TableHead header={'Credit Contact'} />,
      Cell: ({ cell }) => {
        return (
          <Tooltip label={cell.getValue<string>()}>
            <Avatar radius="xl" color="cyan" size="lg">
              {getInitials(cell.getValue<string>() as string)}
            </Avatar>
          </Tooltip>
        )
      },
    },
  ]
}
