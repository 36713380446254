import { Key, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFacility } from 'app/state/ducks/facilities/selectors'
import TextInput from 'app/views/components/inputs/TextInput'
import { Group, Stack, Grid, Table } from '@mantine/core'
import { amortizationsStatus, frequencyOptions } from 'app/models/dropdown-options'
import { setOneFacility } from 'app/state/ducks/facilities/thunks'
import { getFacilityAmendments } from 'app/state/ducks/facility-amendments/selectors'
import { getLoan, getLoanHistory } from 'app/state/ducks/loans/selectors'
import { calculatePikScheduler, formatNumberToCurrency, stringToDate } from 'app/utils/util-functions'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'

enum Tab {
  current = 'Current',
  history = 'History'
}

interface Props {
  facilityId?: string
  tab: string
}

type PikSchedulerData = {
  pikDate: string,
  status: string
}

export default function PIKSchedulerDetails({
  facilityId,
  tab
}: Props) {
  const dispatch = useDispatch()
  const facilityAmendments = useSelector(getFacilityAmendments)
  const facility = useSelector(getFacility)
  const loan = useSelector(getLoan)
  const loanHistory = useSelector(getLoanHistory)
  const bussinessDate = useSelector(getBusinessDate)
  const [currentRows, setCurrentRows] = useState<any>([])
  const [historyRows, setHistoryRows] = useState<any>([])
  const [pikScheduler, setPikScheduler] = useState<PikSchedulerData[]>([])

  useEffect(() => {
    if (facilityAmendments && facilityAmendments.length > 0) {
      const amendment = facilityAmendments.find(
        (amendment: any) => amendment.id === facilityId
      )
      if (amendment) {
        dispatch(setOneFacility(amendment))
      }
    }
  }, [facilityAmendments, facilityId])

  useEffect(() => {
    const pikScheduler = calculatePikScheduler(
      facility?.pikScheduler?.pikFrequency,
      stringToDate(loan?.nextPIKDate ?? ''),
      stringToDate(loan?.endDate ?? ''),
    );
    setPikScheduler(pikScheduler.map((scheduler: any) => ({
      ...scheduler,
      status: bussinessDate >= stringToDate(scheduler.pikDate) ? 'Past Due' : 'Active'
    })));
  }, [loan]);

  useEffect(() => {
    setCurrentRows(
      pikScheduler
        .map((scheduler: PikSchedulerData, index: Key | null | undefined) => {
          return (
            <tr key={index}>
              <td colSpan={1}>{scheduler?.pikDate}</td>
              <td colSpan={1}>{amortizationsStatus.find(({ value }) => value == scheduler?.status)?.label ?? ''}</td>
              <td colSpan={1}>
              </td>
            </tr>
          )
        })
    )
  }, [pikScheduler])

  useEffect(() => {
    setHistoryRows(
      loanHistory.filter((history) => history.actionType === 'PIK' || history.actionType === 'RolloverWithPik').map((history: any, index: Key | null | undefined) => {
        return (
          <tr key={index}>
            <td colSpan={1}>{history.effectiveDate}</td>
            <td colSpan={1}>
              {formatNumberToCurrency(Number(history?.accrualInterestPaid) ?? 0, loan?.currency ?? 'USD')}
            </td>
          </tr>
        )
      }))
  }, [loanHistory])

  return (
    <Stack className="data-header-part">
      <div style={{ fontSize: 'large', fontWeight: '500' }}>
        PIK Details
      </div>{' '}
      <>
        <Group noWrap w="100%">
          <Grid grow>
            {/* Frequency */}
            <Grid.Col span={3}>
              <TextInput
                w="100%"
                label="Frequency"
                placeholder="Empty"
                value={
                  frequencyOptions.find(
                    option => option.value === facility?.pikScheduler?.pikFrequency
                  )?.label ?? ''
                }
                disabled
              />
            </Grid.Col>
            {/* First PIK Date */}
            <Grid.Col span={3}>
              <TextInput
                w="100%"
                label="First PIK Date"
                placeholder="Empty"
                value={facility?.pikScheduler?.firstPikDate as string}
                disabled
              />
            </Grid.Col>
          </Grid>
        </Group>
      </>
      <div style={{ overflowX: 'auto', width: '100%' }}>
        <Table highlightOnHover fontSize="xl">
          <thead>
            <tr>
              <th>PIK Date</th>

              {tab !== Tab.history ? <th>Status</th> : (
                <>
                  <th>PIK Amount</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>{tab === Tab.current ? currentRows : historyRows}</tbody>
        </Table>
      </div>
    </Stack>
  )
}