import { MRT_ColumnDef } from 'mantine-react-table'
import { Button, Text } from '@mantine/core'
import { ReactNode } from 'react'

export type CleanECFDecisionParams = {
    'Lender': string,
    'Payment Amount': string,
    'Percentage': string,
    'Expected Amount': string,
    'Decision': boolean,
    'Action': () => void


}

function TableHead({ header }: { header: string }) {
    return (
        <Text
            style={{
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '1.5em',
            }}
        >
            {header}
        </Text>
    )
}

function TableRowText({ text }: { text: ReactNode }) {
    return (
        <Text
            style={{
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '1.5em',
            }}
        >
            {text}
        </Text>
    )
}

export function getColumnDefs(): MRT_ColumnDef<CleanECFDecisionParams>[] {

    return [
        {
            size: 50,
            accessorKey: 'Lender',
            header: 'Lender',
            Header: <TableHead header={'Lender'} />,
            filterVariant: 'range',
            Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
        },
        {
            size: 50,
            accessorKey: 'Payment Amount',
            header: 'Payment Amount',
            Header: <TableHead header={'Payment Amount'} />,
            filterVariant: 'range',
            Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
        },
        {
            size: 50,
            accessorKey: 'Percentage',
            header: 'Percentage',
            Header: <TableHead header={'Percentage'} />,
            filterVariant: 'range',
            Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
        },
        {
            size: 50,
            accessorKey: 'Expected Amount',
            header: 'Expected Amount',
            Header: <TableHead header={'Expected Amount'} />,
            filterVariant: 'range',
            Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
        },
        {
            size: 50,
            accessorKey: 'Decision',
            header: 'Decision',
            Header: <TableHead header={'Decision'} />,
            filterVariant: 'range',
            Cell: ({ cell, row }) => (
                <Button
                    variant="outline"
                    onClick={() => row.original.Action()}
                >
                    {cell.getValue() ? (
                        <Text color="green" fz="1em">
                            Accept
                        </Text>
                    ) : (
                        <Text color="red">Decline</Text>
                    )}
                </Button>
            ),
        },
    ]
}