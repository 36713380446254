import { Accordion, Grid, Text } from '@mantine/core'
import { LetterOfCreditParams } from 'app/models/letter-of-credit'
import NestedAccordion from 'app/views/components/Accordion/nested-accordion'
import OneLetterOfCreditAccordion from './one-letter-of-credit-accordion'

interface Props {
  letterOfCredits: LetterOfCreditParams[]
  setValue: React.Dispatch<React.SetStateAction<string[]>>
  value: string[]
}

export default function FacilityLetterOfCredits({ letterOfCredits }: Props) {
  return (
    <Accordion
      w="100%"
      bg="#32439A"
      multiple
      variant="contained"
      defaultValue={['letterOfCredits']}
      styles={{
        control: {
          fontFamily: 'Plus Jakarta Sans',
          '&[data-active]': {
            backgroundColor: '#32439A',
          },
          '&:hover': {
            backgroundColor: '#32439A',
          },
        },
        chevron: {
          color: 'white',
        },
      }}
    >
      {letterOfCredits && (
        <Accordion.Item value="letterOfCredits">
          <Accordion.Control>
            <Text c="white" fw={600} fz="lg">
              Letters of Credit Within Facility ({letterOfCredits?.length})
            </Text>
          </Accordion.Control>

          <Accordion.Panel>
            <div
              style={{
                paddingRight: '3.75rem',
                paddingLeft: 'calc(1rem + 0.25rem)',
              }}
            >
              <Grid
                className="accordion-table-head"
                columns={31}
                pb="5px"
                pt="5px"
              >
                <Grid.Col span={6} style={{ textAlign: 'center' }}>
                  <Text>Effective Date</Text>
                </Grid.Col>
                <Grid.Col span={6} style={{ textAlign: 'center' }}>
                  <Text>Expiration Date</Text>
                </Grid.Col>
                <Grid.Col span={6} style={{ textAlign: 'center' }}>
                  <Text>Amount</Text>
                </Grid.Col>
                <Grid.Col span={5} style={{ textAlign: 'center' }}>
                  <Text>Status</Text>
                </Grid.Col>
                <Grid.Col span={5} style={{ textAlign: 'center' }}>
                  <Text>Action</Text>
                </Grid.Col>
              </Grid>
            </div>
            <NestedAccordion items={letterOfCredits}>
              {item => <OneLetterOfCreditAccordion item={item} />}
            </NestedAccordion>
          </Accordion.Panel>
        </Accordion.Item>
      )}
    </Accordion>
  )
}
