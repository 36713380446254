import { Accordion, Radio, Stack } from '@mantine/core'
import { ContactParams } from 'app/models/contact-params'
import { updateLendersPosition } from 'app/state/ducks/lenders-positions/thunks'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ContactTable from './contact-table'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'

interface Props {
  lendersPosition: any
  contacts: ContactParams[]
  changeTabToWireInstructions: any
}

export default function ContactsAccordion({
  lendersPosition,
  contacts,
  changeTabToWireInstructions,
}: Props) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const [admin, setAdmin] = useState<{ id: string; admin: string } | null>(
    lendersPosition?.adminContactId ?? null
  )
  const [credit, setCredit] = useState<{ id: string; admin: string } | null>(
    lendersPosition?.creditContactId ?? null
  )

  const changeAdminContact = (contactId: string) => {
    const selectedContact = contacts.find(contact => contact.id == contactId)
    setAdmin(
      selectedContact
        ? { id: selectedContact.id ?? '', admin: selectedContact.maker ?? '' }
        : null
    )
  }

  useEffect(() => {
    setAdmin(lendersPosition?.adminContactId ?? null)
    setCredit(lendersPosition?.creditContactId ?? null)
  }, [lendersPosition, contacts])

  const changeCreditContact = (contactId: string) => {
    const selectedContact = contacts.find(contact => contact.id == contactId)
    setCredit(
      selectedContact
        ? { id: selectedContact.id ?? '', admin: selectedContact.maker ?? '' }
        : null
    )
  }

  const saveContacts = async () => {
    setLoading(true)
    lendersPosition.adminContactId = admin
    lendersPosition.creditContactId = credit
    const response: any = await dispatch(updateLendersPosition(lendersPosition))
    setLoading(false)
    if (!response.success) {
      ErrorNotification({
        title: 'Lender Position Updated Failed',
        message: response.payload ?? 'Check your input and try again',
      })
      return
    }
    SuccessNotification({
      title: 'Successful Lender Position Updated',
      message: 'You can also set wire instructions',
    })
    changeTabToWireInstructions()
  }

  return (
    <>
      <div style={{ flexGrow: 1 }}>
        <Accordion
          multiple
          defaultValue={['adminContacts', 'creditContacts']}
          variant="contained"
          styles={{
            control: {
              fontFamily: 'Plus Jakarta Sans',
              '&[data-active]': {
                backgroundColor: '#A4CAFE',
              },
            },
          }}
        >
          <Accordion.Item value="adminContacts">
            <Accordion.Control
              bg="#A4CAFE"
              color="black"
              className="accordionLight"
            >
              Admin Contacts
            </Accordion.Control>

            <Accordion.Panel>
              <Stack>
                <Radio.Group
                  value={admin?.id ?? ''}
                  onChange={changeAdminContact}
                >
                  <ContactTable
                    contacts={contacts?.filter(
                      contact => contact.contactType == 'Admin'
                    )}
                  ></ContactTable>
                </Radio.Group>
              </Stack>
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="creditContacts">
            <Accordion.Control
              bg="#A4CAFE"
              color="black"
              className="accordionLight"
            >
              Credit Contacts
            </Accordion.Control>

            <Accordion.Panel>
              <Stack>
                <Radio.Group
                  value={credit?.id ?? ''}
                  onChange={changeCreditContact}
                >
                  <ContactTable
                    contacts={contacts?.filter(
                      contact => contact.contactType == 'Credit'
                    )}
                  />
                </Radio.Group>
              </Stack>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </div>
      <div style={{ textAlign: 'right', padding: '16px' }}>
        <PrimaryButton
          text="Save"
          loading={loading}
          onClick={() => {
            saveContacts()
          }}
        />
      </div>
    </>
  )
}
