import { Group, NumberInput, ScrollArea, Select, Stack } from '@mantine/core'
import CustomDatePicker from '../date-picker/date-picker-logic'
import { useForm, isNotEmpty } from '@mantine/form'
import { frequencyOptions } from 'app/models/dropdown-options'
import { getColumnDefs } from './amortization-schedule-colum-def'
import React, { useEffect, useImperativeHandle, useState } from 'react'
import {
  AmortizationScheduleParams,
  ChangeAmortizationSchedulerData,
} from 'app/models/amortitation-schedule'
import PrimaryButton from '../buttons/PrimaryButton'
import { formatDateToUTC, formatNumberToCurrency, stringToDate } from 'app/utils/util-functions'
import { useDispatch, useSelector } from 'react-redux'
import {
  ErrorNotification,
  SuccessNotification,
} from '../notifications/notification'
import { getFacilitySchedulerAmortization } from 'app/state/ducks/amortization/selectors'
import { changeAllSchedulerAmortizationForFacility } from 'app/state/ducks/amortization/thunks'
import { changeAmortizationForFacilityAmendments } from 'app/state/ducks/facility-amendments/thunks'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import FormWrapper from '../Form/FormWrapper'
import MainTable from '../Table/MainTable'


type Props = {
  deal: any
  facility: any
  setFacility: any
  amendment?: string | null | undefined
}

export interface ChildAmortizationScheduleRef {
  handleClick: () => void
}
const AmortizationSchedule: React.ForwardRefRenderFunction<
  ChildAmortizationScheduleRef,
  Props
> = ({ facility, deal, setFacility, amendment }, ref) => {
  const businessDate = useSelector(getBusinessDate)

  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const maturityDate: Date = facility?.maturity
    ? stringToDate(facility?.maturity)
    : businessDate
  const facilityCurrency = facility?.baseCurrency
    ? facility.baseCurrency
    : 'USD'
  const [facilityAmortizationSchedule, setFacilityAmortizationSchedule] = useState<AmortizationScheduleParams>();
  const amortizationSchedule = useSelector(getFacilitySchedulerAmortization);

  useEffect(() => {
    if (facility &&
      facility.amendmentAmortization) {
      setFacilityAmortizationSchedule(facility.amendmentAmortization);
    } else {
      setFacilityAmortizationSchedule(amortizationSchedule);
    }
  }, [amortizationSchedule, facility])

  const handleClick = async () => {
    const date = formatDateToUTC(form.values.firstPaymentDate)
    let response: any
    const transformedPositions = form.getTransformedValues()

    if (amendment) {
      response = await dispatch(changeAmortizationForFacilityAmendments({ ...transformedPositions as ChangeAmortizationSchedulerData, firstPaymentDate: date },
        { admin: facility.accountManagementAdmin, id: facility.id }));
    } else {
      response = await dispatch(
        changeAllSchedulerAmortizationForFacility({ ...transformedPositions as ChangeAmortizationSchedulerData, firstPaymentDate: date }, {
          id: facility.id,
          admin: facility.accountManagementAdmin,
        })
      )
    }
    if (response?.success === false) {
      ErrorNotification({
        title: ' Facility Action Failed',
        message: response?.payload || 'Check Lender Allocations and try again',
      })
      return
    }

    if (
      Object.keys(response.payload).length !== 0 ||
      response?.success === true
    ) {
      setFacility(response.payload)
      SuccessNotification({
        title: 'Successful Facility Creation',
        message:
          'You can add Lender Allocations, Interest Rate Options, Fees and Amortization Schedules',
      })
    }
  }

  // Expose the handleClick method to the parent component
  useImperativeHandle(ref, () => ({
    handleClick,
  }))


  const onCalculate = async () => {
    try {
      setLoading(true);
      const date = form.values.firstPaymentDate;
      const transformedPositions = form.getTransformedValues() as ChangeAmortizationSchedulerData;

      let response: any
      let newFacility: any
      if (amendment) {
        response = await dispatch(changeAmortizationForFacilityAmendments({
          ...transformedPositions,
          firstPaymentDate: formatDateToUTC(date)
        }, {
          id: facility.id,
          admin: facility.accountManagementAdmin
        }));
        if (response.success) {
          newFacility = response.payload;
        }
      } else {
        response = await dispatch(changeAllSchedulerAmortizationForFacility({
          ...transformedPositions,
          firstPaymentDate: formatDateToUTC(date)
        }, {
          id: facility.id,
          admin: facility.accountManagementAdmin
        }));
        if (response.success) {
          newFacility = { ...facility, amortizationScheduleId: { id: response.payload.id, admin: response.payload.agencyAdmin } };
        }
      }

      if (!response.success) {
        ErrorNotification({
          title: 'Facility Action Failed',
          message: response?.payload as string || 'Check Lender Allocations and try again',
        });
        return
      }

      setFacility(newFacility);

      SuccessNotification({
        title: 'Successful Facility Creation',
        message: 'You can add Lender Allocations, Interest Rate Options, Fees and Amortization Schedules'
      });

    } catch (error) {
      console.error('Error in onCalculate:', error);
    } finally {
      setLoading(false);
    }
  };

  const calculateAmount = async (value: number) => {
    const result = (Number(facility.amount) * value) / 100
    form.setFieldValue('amount', result)
  }

  const calculatePercentage = async (value: number) => {
    const result = ((1.0 * value) / Number(facility.amount)) * 100
    form.setFieldValue('percentage', result)
  }

  const form = useForm({
    initialValues: {
      frequency: facilityAmortizationSchedule?.frequency ?? '',
      amount: Number(facilityAmortizationSchedule?.amount) ?? 0,
      firstPaymentDate:
        facilityAmortizationSchedule?.firstPaymentDate &&
          facilityAmortizationSchedule?.firstPaymentDate != ''
          ? stringToDate(facilityAmortizationSchedule?.firstPaymentDate)
          : businessDate,
      percentage: facilityAmortizationSchedule?.percentage
        ? Number(facilityAmortizationSchedule?.percentage)
        : 0,
    },

    validate: {
      percentage: isNotEmpty('Percentage cannot be empty'),
      frequency: isNotEmpty('Deal Name cannot be empty'),
      amount: value => (value > 0 ? null : 'Invalid amount'),
      firstPaymentDate: value =>
        isNotEmpty('Payment date cannot be empty') && value <= maturityDate
          ? null
          : 'Must Be Before Maturity Date',
    },
    transformValues: values => {
      return {
        ...values,
        firstPaymentDate: formatDateToUTC(values.firstPaymentDate),
      }
    },
    validateInputOnBlur: true,
    validateInputOnChange: ['percentage', 'amount'],
  })

  useEffect(() => {
    form.setValues({
      ...facilityAmortizationSchedule,
      amount: Number(facilityAmortizationSchedule?.amount) ?? 0,
      percentage: Number(facilityAmortizationSchedule?.percentage) ?? 0,
      firstPaymentDate: facilityAmortizationSchedule?.firstPaymentDate ? stringToDate(facilityAmortizationSchedule?.firstPaymentDate) : new Date(),
    });
  }, [facilityAmortizationSchedule]);


  function checkForBullet(e: string | null) {
    if (e === 'Bullet') {
      form.values.percentage = 100
      form.values.amount = Number(facility.amount)
      form.setFieldValue('firstPaymentDate', maturityDate)
    }
  }

  return (
    <FormWrapper title={''}>
      <Stack>
        <Group noWrap w="100%">
          <NumberInput
            w="100%"
            withAsterisk
            pb={5}
            label="Payment amount"
            id="amount"
            placeholder="Enter Payment Amount"
            parser={value => value.replace(/[^0-9.]/g, '')}
            formatter={value =>
              !Number.isNaN(parseFloat(value))
                ? formatNumberToCurrency((Number(parseFloat(value.replace(/\B(?<!\d*)(?=(\d{3})+(?!\d))/g, ',')))), (facility?.baseCurrency ?? 'USD'))
                : ''
            }
            {...form.getInputProps('amount')}
            onChange={e => {
              form.getInputProps('amount').onChange(e)
              calculatePercentage(Number(e))
            }}
            disabled={form.values.frequency === 'Bullet'}
          />
        </Group>
        <Group noWrap w="100%">
          <NumberInput
            w="100%"
            pb={5}
            precision={5}
            label="Percentage "
            placeholder="Enter percentage"
            parser={value => (value ? value.replace(/%\s?|\$\s?|(,*)/g, '') : '')}
            formatter={value =>
              !Number.isNaN(parseFloat(value ?? '')) ? value + ' %' : '%'
            }
            {...form.getInputProps('percentage')}
            onChange={e => {
              form.getInputProps('percentage').onChange(e)
              calculateAmount(Number(e))
            }}
            disabled={form.values.frequency === 'Bullet'}
          />
          <Select
            w="100%"
            searchable
            withAsterisk
            label="Frequency"
            placeholder="Select Frequency"
            data={[...frequencyOptions]}
            {...form.getInputProps('frequency')}
            onChange={e => {
              form.getInputProps('frequency').onChange(e)
              checkForBullet(e)
            }}
          />
        </Group>
        <Group noWrap w="100%">
          <CustomDatePicker
            w="100%"
            label={'First Payment Date'}
            date={form.values.firstPaymentDate}
            setDate={(value: any) =>
              form.setFieldValue('firstPaymentDate', value)
            }
            disabled={form.values.frequency === 'Bullet'}
            holidayCalendars={
              facility?.holidayCalendar ?? deal?.holidayCalendar ?? []
            }
          />
        </Group>
        <Group noWrap w="100%" mt="20px" mb="20px">
          <PrimaryButton
            onClick={() => onCalculate()}
            loading={loading}
            style={{ width: '100%' }}
            text="Calculate"
          />
        </Group>
        <Group noWrap w="100%" mt="20px" mb="20px">
          <ScrollArea h={600} type="always">
            <MainTable
              tableName="Amortization Schedule Table"
              withPagination={false}
              enableColumnOrdering={false}
              enableGrouping={false}
              enableColumnFilters={false}
              enableColumnActions={false}
              enableFilters={false}
              enableSorting={false}
              enableFullScreenToggle={false}
              enableTopToolbar={false}
              headerBackgroundColor='#F0EEEE'
              columnDefs={getColumnDefs(facilityCurrency)}
              data={facilityAmortizationSchedule?.scheduler ?? []}
              setRow={() => { }}
            />
          </ScrollArea>
        </Group>
      </Stack>
    </FormWrapper>
  )
}

export default React.forwardRef(AmortizationSchedule)
