export const GET_LOAN_ROLLOVERS = 'loan_rollovers/GET_LOAN_ROLLOVERS'
export const ADD_LOAN_ROLLOVER = 'loan_rollovers/ADD_LOAN_ROLLOVER'
export const EDIT_LOAN_ROLLOVER = 'loan_rollovers/EDIT_LOAN_ROLLOVER'
export const SUBMIT_LOAN_ROLLOVER = 'loan_rollovers/SUBMIT_LOAN_ROLLOVER'
export const APPROVE_LOAN_ROLLOVER = 'loan_rollovers/APPROVE_LOAN_ROLLOVER'
export const REJECT_LOAN_ROLLOVER = 'loan_rollovers/REJECT_LOAN_ROLLOVER'
export const GET_APPROVED_LOAN_ROLLOVERS =
  'loan_rollovers/GET_APPROVED_LOAN_ROLLOVERS'
export const DELETE_LOAN_ROLLOVER = 'loan_rollovers/DELETE_LOAN_ROLLOVER'
export const START_LOADING_LOAN_ROLLOVER = 'loan_rollovers/START_LOADING_LOAN_ROLLOVER'
export const STOP_LOADING_LOAN_ROLLOVER = 'loan_rollovers/STOP_LOADING_LOAN_ROLLOVER'
