import * as types from './types'
import { createReducer } from 'app/state/utils'

const initialState: never[] = []

const notificationsReducer = createReducer(initialState)({
  [types.GET_NOTIFICATIONS]: (state: any, payload: any) => {
    return { ...state, notifications: payload.payload }
  },
})

export default notificationsReducer
