import { Badge, Text } from "@mantine/core";
import { NoticeParams } from 'app/models/notices-params';
import { PaymentParams } from "app/models/payments-params";
import { ReactNode } from "react";

interface Props {
  status: string;
  payment?: PaymentParams
  notice?: NoticeParams
}


export enum statusPillState {
  'PendingRelease' = 'Pending Release',
  'PendingSubmit' = 'Pending Submit',
  'Released' = 'Released',
  'Settled' = 'Settled',
  'Canceled' = 'Canceled',
  'Rejected' = 'Rejected',
  'Draft' = 'Draft',
  'Submitted' = 'Submitted',
  'Approved' = 'Approved',
}


function PillText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}


export const StatusPill = ({ status, payment = undefined, notice = undefined }: Props) => {
  let state = status
  if (state === 'Queued' && payment !== undefined) {
    state = !payment.submitted ? 'Pending Submit' : 'Pending Release'
  }

  if (state === 'Queued' && notice !== undefined) {
    state = !notice.status ? 'Pending Submit' : 'Pending Release'
  }

  let styles = {}
  if (state === statusPillState.PendingRelease) {
    styles = {
      backgroundColor: '#96D2FA',
      color: '#32439A',
    }
  }

  if (state === statusPillState.Released) {
    styles = {
      backgroundColor: '#FB5607',
    }
  }
  if (state === statusPillState.PendingSubmit) {
    styles = {
      backgroundColor: '#32439A',
    }
  }
  if (state === statusPillState.Rejected) {
    styles = {
      backgroundColor: '#b83737',
    }
  }
  if (state === 'SettledPayment') {
    state = statusPillState.Settled
    styles = {
      backgroundColor: '#37b861 ',
    }
  }
  if (state === 'SettledPayment') {
    state = statusPillState.Settled
    styles = {
      backgroundColor: '#37b861 ',
    }
  }
  if (state === 'CanceledPayment') {
    state = statusPillState.Canceled
    styles = {
      backgroundColor: '#b83737 ',
    }
  }

  if (state === statusPillState.Draft) {
    styles = {
      backgroundColor: '#FF5A1F',
    }
  }

  if (state === statusPillState.Submitted) {
    styles = {
      backgroundColor: '#E8006E',
    }
  }
  if (state === statusPillState.Submitted) {
    styles = {
      backgroundColor: '#7E3AF2',
    }
  }
  if (state === statusPillState.Approved) {
    styles = {
      backgroundColor: '#32439A',
    }
  }

  return (
    <Badge
      styles={{
        root: {
          fontFamily: 'Plus Jakarta Sans',
          color: 'white',
          borderRadius: '4px',
          padding: '1.5em',
          ...styles,
        },
      }}
    >
      <PillText text={state} />
    </Badge>
  )

}