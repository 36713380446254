import { Stack, Text } from '@mantine/core'
import MainDrawer from 'app/views/components/Drawer/MainDrawer'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { LendersPosition } from 'app/models/lenders-position-params'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { EntityParams } from 'app/models/entity-params'
import HistoryAccordion from '../deals-management/lender-allocations/history-accordion'

interface Props {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  lendersPosition?: LendersPosition
}

export default function PositionDrawer({
  open,
  setOpen,
  lendersPosition,
}: Props) {
  const entities = useSelector(getEntities)
  const [entity, setEntity] = useState<EntityParams | undefined>(undefined)

  useEffect(() => {
    if (!lendersPosition || !entities) {
      return
    }
    setEntity(entities.find(entity => entity.id == lendersPosition.lender.id))
  }, [lendersPosition, entities])

  return (
    <>
      <MainDrawer opened={open} setOpen={setOpen} titleText={entity?.entityName ?? ''}>
        <Stack w='100%' className='sectionRemoveGap'>
          <Text className='sectionDetailBlueHeader'>History</Text>
          <HistoryAccordion lendersPosition={lendersPosition} />
        </Stack >
      </MainDrawer>
    </>
  )
}
