import IonIcon from '@reacticons/ionicons'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'
import { PageLayout } from 'app/views/components/layout/page-layout'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFacility } from 'app/state/ducks/facilities/selectors'
import {
  Accordion,
  Anchor,
  Breadcrumbs,
  Group,
  SegmentedControl,
  Stack,
  Text,
  Modal,
  Menu
} from '@mantine/core'
import StatusPill from '../../pages/deals-management/common/pill-status'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { AttachmentsAccordion } from '../Accordion/attachments-accordion/attachments-accordion'
import { NotesAccordion } from '../Accordion/notes-accordion'
import { ContractType } from 'app/models/common-types'
import {
  loadOneFacility,
} from 'app/state/ducks/facilities/thunks'
import { UpdatedFacilitiesScreen } from 'app/views/pages/deals-management/facilities/updated-facilities-screen'
import { getDeals } from 'app/state/ducks/deals/selectors'
import { DealParams } from 'app/models/deal-params'
import { loadAllDeals } from 'app/state/ducks/deals/thunks'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import { loadContacts } from 'app/state/ducks/contacts/thunks'
import { loadWireInstructions } from 'app/state/ducks/wire-instructions/thunks'
import {
  loadSchedulerAmortizationByFacility,
  recalculateAmortizationSchedule
} from 'app/state/ducks/amortization/thunks'
import { loadAllFacilityAmendments } from 'app/state/ducks/facility-amendments/thunks'
import {
  ErrorNotification,
  SuccessNotification
} from '../notifications/notification'
import PrimaryDropDown from '../DropDown/DropDown'
import AmortizationScheduleDetails from './ amortization-schedule-details'
import { loadNoteByContract } from 'app/state/ducks/notes/thunks'

enum Tab {
  current = 'Current',
  history = 'History'
}

export default function AmortizationSchedulePage() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()
  const currentTab =
    searchParams.get('tab') === 'History' ? Tab.history : Tab.current

  const [tab, setTab] = useState<string>(currentTab)

  const { facilityId, status } = useParams()
  const [openEdit, setOpenEdit] = useState(false)
  const [deal, setDeal] = useState<DealParams | undefined>(undefined)

  const [isCalculationInSync, setIsCalculationInSync] = useState<boolean>(false)

  const deals = useSelector(getDeals)
  const facility = useSelector(getFacility)

  useEffect(() => {
    dispatch(loadEntities())
    dispatch(loadContacts())
    dispatch(loadWireInstructions())
    dispatch(loadAllDeals())
    dispatch(loadAllFacilityAmendments())
  }, [])

  useEffect(() => {
    if (!facilityId) {
      return
    }
    dispatch(loadNoteByContract(facilityId, ContractType.Facilities))
    dispatch(loadOneFacility(facilityId ?? '', status ?? ''))
  }, [facilityId, status])

  useEffect(() => {
    dispatch(loadSchedulerAmortizationByFacility(facilityId ?? ''))
  }, [facilityId])

  const recalculate = async () => {
    const result = (await dispatch(
      recalculateAmortizationSchedule({
        id: facilityId ?? '',
        admin: facility?.accountManagementAdmin ?? ''
      })
    )) as any
    if (result.success) {
      setIsCalculationInSync(true)
      SuccessNotification({
        title: 'Successful Amortization Recalculate',
        message:
          'Amortization schedule is automaticly recalculated and now in sync with facility amount'
      })
      dispatch(loadSchedulerAmortizationByFacility(facilityId ?? ''))
      dispatch(loadAllFacilityAmendments())
    } else {
      ErrorNotification({
        title: 'Amortization Recalculate Failed',
        message:
          (result?.payload as string) || 'Amortization recalculate failed'
      })
    }
  }

  useEffect(() => {
    if (!facility || !deals) {
      return
    }
    const selectedDeal = deals.find(
      (deal: DealParams) => deal.id === facility.dealId.id
    )
    if (!selectedDeal) {
      return
    }
    setDeal(selectedDeal)
  }, [facility, deals])

  const items = [
    { title: 'Deal Management', action: () => navigate('/dealmanagement') },
    {
      title: 'Facilities',
      action: () => navigate('/dealmanagement/facilities')
    },
    {
      title: facility?.name ?? '',
      action: () =>
        navigate(
          `/dealmanagement/facilities/${facility?.id}/${facility?.status}`
        )
    }
  ].map((item, index) => (
    <Anchor
      onClick={() => item.action()}
      key={index}
      color="#374151"
      fw={500}
      fz="14px"
    >
      {item.title}
    </Anchor>
  ))
  return (
    <PageLayout>
      <Breadcrumbs
        separator={<IonIcon name="chevron-forward-outline" />}
        mt="xs"
      >
        {items}
      </Breadcrumbs>
      <Group position="apart" align="center">
        <PageTitle pageTitle="Amortization Schedule" />
        <Group noWrap>
          <Menu shadow="md" width={200} position="bottom" withinPortal>
            <Menu.Target>
              <div style={{ width: 'fit-content' }}>
                <PrimaryDropDown>Actions</PrimaryDropDown>
              </div>
            </Menu.Target>

            <Menu.Dropdown>
              {facility?.status?.toLowerCase() !== 'submitted' ? (
                <Menu.Item
                  icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
                  onClick={() => setOpenEdit(true)}
                >
                  Edit Amortization Schedule
                </Menu.Item>
              ) : null}
              {facility?.status?.toLowerCase() !== 'submitted' &&
                !isCalculationInSync ? (
                <Menu.Item icon={<IonIcon className="action-ion-icon" name='settings-outline' />} onClick={recalculate}>
                  Recalculate
                </Menu.Item>
              ) : null}
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Group>

      <Group h="100%">
        {
          <StatusPill
            state={facility?.status ?? 'Draft'}
            additionalState={facility?.amendmentType ?? ''}
          />
        }
        <Text>Show:</Text>
        <SegmentedControl
          value={tab}
          onChange={setTab}
          size="lg"
          data={[
            { value: Tab.current, label: 'Current' },
            { value: Tab.history, label: 'History' }
          ]}
          styles={{
            controlActive: {
              backgroundColor: '#A4CAFE'
            }
          }}
        />
      </Group>
      <Group noWrap position="apart" align="flex-start" h="100vh">

        <AmortizationScheduleDetails
          facilityId={facilityId}
          isCalculationInSync={isCalculationInSync}
          setIsCalculationInSync={setIsCalculationInSync}
          tab={tab}
        />

        <Stack className="accordions-header-part">
          <Stack h="100%" bg="#F9F8F8" w="100%" p="md">
            <Accordion
              multiple
              defaultValue={['pdfs', 'notes']}
              variant="contained"
              bg="white"
              styles={{
                control: {
                  fontFamily: 'Plus Jakarta Sans',
                  '&[data-active]': {
                    backgroundColor: '#A4CAFE'
                  }
                }
              }}
            >
              <AttachmentsAccordion
                id={facilityId ?? ''}
                type={ContractType.Facilities}
              />
              <NotesAccordion
                contractId={facilityId ?? ''}
                contractAdmin={facility?.accountManagementAdmin ?? ''}
                type={ContractType.Facilities}
              />
            </Accordion>
          </Stack>
        </Stack>
      </Group>
      {openEdit && facility?.status !== 'Submitted' && (
        <Modal
          className="modal-body modal-body-template fit-content-modal"
          opened={openEdit}
          onClose={() => setOpenEdit(false)}
          centered={true}
          size="77%"
        >
          <UpdatedFacilitiesScreen
            deal={deal}
            amendment={
              facility?.amendmentType || facility?.status === 'Approved'
                ? 'amend'
                : null
            }
            facility={facility}
            onClose={() => setOpenEdit(false)}
            step={6}
          />
        </Modal>
      )}
    </PageLayout>
  )
}
