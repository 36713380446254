import {
    Group,
    Stack,
    Title,
} from '@mantine/core'
import { EntityParams } from 'app/models/entity-params'
import { useParams } from 'react-router-dom'
import Pill, { PillColor } from 'app/views/components/Table/PillUpdated'


interface props {
    entity: EntityParams
}

enum EntityState {
    Active,
    InActive,
}

const entityStatePillColor = new Map<EntityState, PillColor>()
entityStatePillColor.set(EntityState.Active, PillColor.GREEN)
entityStatePillColor.set(EntityState.InActive, PillColor.GREY)

export default function EntityHeader({ entity }: props) {
    const { status } = useParams()
    const entityState: EntityState =
        status === 'Active' ? EntityState.Active : EntityState.InActive
    return (
        <Stack className="detailsHeader">
            <Group noWrap>
                <Title className='detailsTitle'>{entity?.entityName ?? ''}</Title>
                <Pill
                    color={entityStatePillColor.get(entityState) ?? PillColor.GREY}
                    text={status as string}
                />
            </Group>
        </Stack>
    )
}