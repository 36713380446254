import { Divider, ScrollArea, Stack, Group } from '@mantine/core'
import React, { useEffect, useImperativeHandle, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isNotEmpty, useForm } from '@mantine/form'
import Select from '../inputs/Select'
import CustomDatePicker from '../date-picker/date-picker-logic'
import NumberInput from '../inputs/NumericalInput'
import {
  dayBasisOptions,
  feeTypeOptions,
  frequencyOptions,
} from 'app/models/dropdown-options'
import { stringToDate, formatDateToUTC, formatNumberToCurrency } from 'app/utils/util-functions'
import { getColumnDefs } from './facility-fees-colum-def'
import { changeAllFeesForFacilityAmendments } from 'app/state/ducks/facility-amendments/thunks'
import { changeAllFeesForFacility } from 'app/state/ducks/fees/thunks'
import { getFacilityFees } from 'app/state/ducks/fees/selectors'
import { Fees } from 'app/models/fees-params'
import {
  ErrorNotification,
  SuccessNotification,
} from '../notifications/notification'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import FormWrapper from '../Form/FormWrapper'
import IonIcon from '@reacticons/ionicons'
import MainTable from '../Table/MainTable'

type Props = {
  facility: any
  setFacility: any
  amendment?: string | null | undefined
}

export interface ChildManageFeesRef {
  handleClick: () => void
}

const ManageFees: React.ForwardRefRenderFunction<ChildManageFeesRef, Props> = (
  { facility, setFacility, amendment },
  ref
) => {
  const dispatch = useDispatch()
  const [facilityFees, setFacilityFees] = useState<Fees[]>([]);
  const fees = useSelector(getFacilityFees);
  const businessDate = useSelector(getBusinessDate)
  const noBulletTypes = ["Facility", "Commitment", "Utilization", "LetterOfCredit"];
  const onlyBulletTypes = [
    'Arrangement',
    'Upfront',
    'Amendment',
    'Syndication',
    'AdHoc',
  ]


  useEffect(() => {
    if (facility &&
      facility.amendmentFees &&
      facility.amendmentFees.length > 0) {
      setFacilityFees(facility.amendmentFees);
    } else {
      setFacilityFees(fees);
    }
  }, [fees, facility])



  const handleClick = async () => {
    const checkIfFeeTypes = form
      .getTransformedValues()
      .fees.map(
        (fee) => fee.feeType == '' || fee.feeType == null
      )
    let response: any
    const transformedPositions = form.getTransformedValues().fees
    if (checkIfFeeTypes.includes(false)) {
      if (form.validate().hasErrors === true) {
        throw Error
      }
      if (amendment) {
        response = await dispatch(changeAllFeesForFacilityAmendments(transformedPositions,
          { admin: facility.accountManagementAdmin, id: facility.id }));
        setFacility(response.payload)
      } else {
        response = await dispatch(
          changeAllFeesForFacility(transformedPositions, {
            id: facility.id,
            admin: facility.accountManagementAdmin,
          })
        )
      }
    } else if (facility.accrualFee?.length > 0) {
      if (amendment) {
        response = dispatch(changeAllFeesForFacilityAmendments([],
          { admin: facility.accountManagementAdmin, id: facility.id }));
        setFacility(response.payload)
      } else {
        response = await dispatch(
          changeAllFeesForFacility([], {
            id: facility.id,
            admin: facility.accountManagementAdmin,
          })
        )

      }
    }
    if (response?.success === false) {
      ErrorNotification({
        title: ' Facility Action Failed',
        message: response?.payload || 'Check Lender Allocations and try again',
      })
      return
    }
    if (
      !response ||
      Object.keys(response.payload).length !== 0 ||
      response?.success === true
    ) {
      setFacility({
        ...facility,
        accrualFee: form.getTransformedValues().fees,
      })
      SuccessNotification({
        title: 'Successful Facility Creation',
        message:
          'You can add Lender Allocations, Interest Rate Options, Fees and Amortization Schedules',
      })
    }
  }

  // Expose the handleClick method to the parent component
  useImperativeHandle(ref, () => ({
    handleClick,
  }))


  const form = useForm({
    initialValues: {
      fees:
        facilityFees?.length > 0
          ? facilityFees.map((fee: Fees) => ({
            ...fee,
            firstPaymentDate: stringToDate(fee.firstPaymentDate),
            feeRate: Number(fee.feeRate),
            oneTimeFeeAmount: fee.oneTimeFeeAmount === null ? 0 : Number(fee.oneTimeFeeAmount),
          }))
          : [
            {
              feeRate: 0,
              feeType: '',
              firstPaymentDate: businessDate,
              dayBasis: '',
              frequency: '',
              oneTimeFeeAmount: 0,
            },
          ],
    },
    transformValues: values => ({
      ...values,
      fees: values.fees.map((a) => {
        return {
          dayBasis: a.dayBasis === '' ? null : a.dayBasis,
          feeType: a.feeType,
          frequency: a.frequency,
          feeRate: Number(a.feeRate),
          firstPaymentDate: formatDateToUTC(a.firstPaymentDate),
          oneTimeFeeAmount: a.oneTimeFeeAmount === 0 ? null : Number(a.oneTimeFeeAmount),

        }
      }),
    }),
    validate: {
      fees: {
        feeType: isNotEmpty(' Choose a fee'),
        feeRate: value => (value > 0 ? null : 'Invalid Rate'),
        frequency: isNotEmpty('Choose a frequency'),
      },
    },
  })

  const changeFeeRate = async (value: number, index: number) => {
    form.setFieldValue(`fees.${index}.feeRate`, value)

    if (!onlyBulletTypes.includes(form.values.fees[index].feeType)) {
      return
    }
    const result = (Number(facility.amount) * value) / 100
    form.setFieldValue(`fees.${index}.oneTimeFeeAmount`, result)
  }

  const changeOneTimeFeeAmount = async (value: number, index: number) => {
    form.setFieldValue(`fees.${index}.oneTimeFeeAmount`, value)

    if (!onlyBulletTypes.includes(form.values.fees[index].feeType)) {
      return
    }
    const result = ((1.0 * value) / Number(facility.amount)) * 100
    form.setFieldValue(`fees.${index}.feeRate`, result)
  }

  const updateType = (value: any, index: number) => {
    form.setFieldValue(`fees.${index}.feeType`, value)
    if (onlyBulletTypes.includes(value)) {
      form.setFieldValue(`fees.${index}.frequency`, 'Bullet')
      form.setFieldValue(`fees.${index}.dayBasis`, '')
      const result = (Number(facility.amount) * form.values.fees[index].feeRate) / 100
      form.setFieldValue(`fees.${index}.oneTimeFeeAmount`, result)

    }
    if (noBulletTypes.includes(value) && form.values.fees[index].frequency === 'Bullet') {
      form.setFieldValue(`fees.${index}.frequency`, '')
      form.setFieldValue(`fees.${index}.oneTimeFeeAmount`, 0)
    }
  }


  useEffect(() => {
    form.setValues({
      fees:
        facilityFees?.length > 0
          ? facilityFees.map((fee: Fees) => ({
            ...fee,
            firstPaymentDate: stringToDate(fee.firstPaymentDate),
            feeRate: Number(fee.feeRate),
            oneTimeFeeAmount: fee.oneTimeFeeAmount === null ? 0 : Number(fee.oneTimeFeeAmount),
          }))
          : [
            {
              feeRate: 0,
              feeType: '',
              firstPaymentDate: businessDate,
              dayBasis: '',
              frequency: '',
              oneTimeFeeAmount: 0,
            },
          ],
    })
  }, [facilityFees])


  const selectedOptions = form.values.fees?.map(
    (item: { feeType: string }) => item.feeType
  )
  const fields = form.values.fees?.map(
    (item: { feeType: string }, index: any) => {
      const selectedOption = item.feeType
      const updatedOptions = feeTypeOptions.map(option => {
        return {
          ...option,
          disabled:
            selectedOptions.includes(option.value) &&
            option.value !== selectedOption,
        }
      })

      return (
        <FormWrapper title={''}>
          <Stack w="100%" key={index}>
            <Stack>
              <Group noWrap w="100%">
                <Select
                  w="100%"
                  searchable
                  withAsterisk={form.values.fees[index].feeType ? true : false}
                  label="Fee"
                  data={updatedOptions}
                  placeholder="Select a fee and define start date and frequency"
                  {...form.getInputProps(`fees.${index}.feeType`)}
                  onChange={(value: any) => updateType(value, index)}
                />
                <NumberInput
                  w="100%"
                  withAsterisk={form.values.fees[index].feeType ? true : false}
                  precision={5}
                  defaultValue={0.0}
                  min={0.0}
                  pb={5}
                  label="Fee Rate"
                  placeholder="Insert rate"
                  parser={value =>
                    value ? value.replace(/\$\s?|(,*)/g, '') : ''
                  }
                  {...form.getInputProps(`fees.${index}.feeRate`)}
                  onChange={(value: any) => changeFeeRate(value, index)}

                />
                <Group noWrap w="100%">
                  {onlyBulletTypes.includes(form.values.fees[index].feeType) &&
                    <NumberInput
                      w="100%"
                      withAsterisk={form.values.fees[index].oneTimeFeeAmount ? true : false}
                      precision={5}
                      defaultValue={0.0}
                      min={0.0}
                      pb={5}
                      label="Fee Amount"
                      placeholder="Insert amount"
                      parser={value => value.replace(/[^0-9.]/g, '')}
                      formatter={value =>
                        !Number.isNaN(parseFloat(value))
                          ? formatNumberToCurrency((Number(parseFloat(value.replace(/\B(?<!\d*)(?=(\d{3})+(?!\d))/g, ',')))), (facility?.baseCurrency ?? 'USD'))
                          : ''
                      }
                      {...form.getInputProps(`fees.${index}.oneTimeFeeAmount`)}
                      onChange={(value: any) => changeOneTimeFeeAmount(value, index)}
                    />
                  }
                </Group>

              </Group>
              <Group noWrap w="100%">
                <Select
                  w="100%"
                  searchable
                  disabled={onlyBulletTypes.includes(form.values.fees[index].feeType)}
                  withAsterisk={form.values.fees[index].feeType !== '' && !onlyBulletTypes.includes(form.values.fees[index].feeType)}
                  label="Day Basis"
                  data={dayBasisOptions}
                  placeholder="Select day basis"
                  {...form.getInputProps(`fees.${index}.dayBasis`)}
                />

                <Select
                  w="100%"
                  searchable
                  withAsterisk={form.values.fees[index].feeType ? true : false}
                  label='Frequency'
                  placeholder="Select frequency"
                  readOnly={onlyBulletTypes.includes(form.values.fees[index].feeType)}
                  data={noBulletTypes.includes(form.values.fees[index].feeType) ? frequencyOptions.filter(option => option.value !== 'Bullet') : onlyBulletTypes.includes(form.values.fees[index].feeType) ? frequencyOptions.filter(option => option.value === 'Bullet') : frequencyOptions}
                  {...form.getInputProps(`fees.${index}.frequency`)}
                />
                <CustomDatePicker
                  w="100%"
                  required={form.values.fees[index].feeType ? true : false}
                  label={'First Payment Date'}
                  date={
                    form.values.fees[index].firstPaymentDate ?? businessDate
                  }
                  setDate={(value: any) =>
                    form.setFieldValue(
                      `fees.${index}.firstPaymentDate`,
                      value
                    )
                  }
                  holidayCalendars={facility?.holidayCalendar ?? []}
                />
              </Group>
            </Stack>
            <Stack>
              <Group position="center" noWrap w="100%" mt="10px">
                <IonIcon name="trash-outline" className="click-ion-icon" style={{ color: "red" }}
                  onClick={() => form.removeListItem('fees', index)} />
                <span>
                  Remove
                </span>
              </Group>
            </Stack>
            <Divider my="sm" />
          </Stack>
        </FormWrapper>
      )
    }
  )

  return (
    <Stack>
      <Stack>{fields}</Stack>
      <Stack>
        <Stack style={{ marginTop: '5px' }}>
          <Group noWrap w="100%">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IonIcon name="add-circle-outline" className="click-ion-icon"
                onClick={() =>
                  form.insertListItem('fees', {
                    feeRate: 0,
                    feeType: '',
                    firstPaymentDate: businessDate,
                    dayBasis: '',
                    frequency: '',
                  })
                } />
              <span
                style={{
                  marginLeft: '8px',
                  fontFamily: 'Plus Jakarta Sans',
                  fontWeight: 'bold',
                  fontSize: '1em',
                }}
              >
                Add Fees
              </span>
            </div>
          </Group>
        </Stack>
        <Group>
          <ScrollArea h={400} type="always">
            <MainTable
              tableName='Facility Fees Table'
              withPagination={false}
              enableColumnOrdering={false}
              enableGrouping={false}
              enableColumnFilters={false}
              enableColumnActions={false}
              enableFilters={false}
              enableSorting={false}
              enableFullScreenToggle={false}
              enableTopToolbar={false}
              headerBackgroundColor='#F0EEEE'
              columnDefs={getColumnDefs()}
              data={form.getTransformedValues().fees}
              setRow={() => { }}
            />
          </ScrollArea>
        </Group>
      </Stack>
    </Stack>
  )
}
export default React.forwardRef(ManageFees)
