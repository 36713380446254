import { LoanParams } from 'app/models/loan-params';
import { Form } from './interfaces';
import { ContractPeriod, IroValues } from 'app/models/facility-params';
import { InterestRateParams } from 'app/models/interest-rate';
import { calculateTermInterests } from './calculate-term-interest';

export const changeContractPeriod = (
    form: Form,
    iro: IroValues | undefined,
    loan: LoanParams | undefined,
    dailyRates: InterestRateParams[],
    event: string | null,
    setContractPeriod: (contractPeriod: ContractPeriod) => void,
    date: Date
): void => {
    form.setFieldValue('contractPeriod', event)
    if (!event || !iro) {
        return;
    }
    const contractWitCass = iro.contractPeriod.find(cp => cp.contractPeriod === event);
    if (!contractWitCass) {
        form.setFieldError('contractPeriod', 'Not found Index rate option for given contract period');
        return;
    }

    setContractPeriod(contractWitCass);
    form.setFieldValue('casValue', Number(contractWitCass.cas));
    calculateTermInterests(form, loan, dailyRates, iro, contractWitCass, date);
};