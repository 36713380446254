import * as types from './types'
import { DealParams } from 'app/models/deal-params'
import { createReducer } from 'app/state/utils'

export interface DealAmendmentState {
  dealAmendments: DealParams[]
  dealAmendment: DealParams | null
  isLoading: boolean
}
const initialState: DealAmendmentState = {
  dealAmendments: [],
  dealAmendment: null,
  isLoading: false,
}

const dealAmendmentsReducer = createReducer(initialState)({
  [types.GET_DEAL_AMENDMENTS]: (state: DealAmendmentState, payload: any) => {
    return { ...state, dealAmendments: payload.payload }
  },
  [types.GET_DEAL_AMENDMENT]: (state: DealAmendmentState, payload: any) => {
    return { ...state, dealAmendment: payload.payload }
  },
  [types.ADD_DEAL_AMENDMENTS]: (state: DealAmendmentState, payload: any) => {
    return {
      ...state,
      dealAmendments: [
        { ...payload.payload, status: 'Draft' },
        ...state.dealAmendments,
      ],
    }
  },
  [types.EDIT_DEAL_AMENDMENTS]: (state: DealAmendmentState, payload: any) => {
    const index = state.dealAmendments.findIndex(
      (dealAmendment: { id: any }) => dealAmendment.id === payload.payload.id,
    )
    const newArray = [...state.dealAmendments]
    newArray[index] = { ...payload.payload, status: 'Draft' }
    return {
      ...state,
      dealAmendments: newArray,
    }
  },
  [types.SUBMIT_DEAL_AMENDMENT]: (state: DealAmendmentState, payload: any) => {
    if (payload.payload.error) {
      return state
    }
    const index = state.dealAmendments.findIndex(
      (dealAmendment: { id: any }) => dealAmendment.id === payload.payload.id,
    )
    const newArray = [...state.dealAmendments]
    newArray[index] = { ...newArray[index], status: 'Submitted' }
    if (state?.dealAmendment?.id === payload.payload.id) {
      return {
        ...state,
        dealAmendment: { ...state.dealAmendment, status: 'Submitted' },
        dealAmendments: newArray,
      }
    }
    return {
      ...state,
      dealAmendments: newArray,
    }
  },
  [types.REJECT_DEAL_AMENDMENT]: (state: DealAmendmentState, payload: any) => {
    if (payload.payload.error) {
      return state
    }
    const index = state.dealAmendments.findIndex(
      (dealAmendment: { id: any }) => dealAmendment.id === payload.payload.id,
    )
    const newArray = [...state.dealAmendments]
    newArray[index] = { ...newArray[index], status: 'Draft' }
    if (state?.dealAmendment?.id === payload?.payload?.id) {
      return {
        ...state,
        dealAmendment: { ...state.dealAmendment, status: 'Draft' },
        dealAmendments: newArray,
      }
    }
    return {
      ...state,
      dealAmendments: newArray,
    }
  },
  [types.APPROVE_DEAL_AMENDMENT]: (state: DealAmendmentState, payload: any) => {
    if (payload.payload.error) {
      return state
    }
    const approvedId = payload.payload.id

    return {
      ...state,
      dealAmendments: state.dealAmendments.filter(
        (dealAmendment: any) => dealAmendment.id !== approvedId,
      ),
      dealAmendment:
        state.dealAmendment && state.dealAmendment.id === approvedId
          ? null
          : state.dealAmendment,
    }
  },
  [types.DELETE_DEAL_AMENDMENT]: (state: DealAmendmentState, payload: any) => {
    if (!payload.payload || payload.payload.length === 0) {
      return state
    }
    const deletedIds = payload.payload
      .filter((payload: any) => !payload.error)
      .map((payload: any) => payload.id)
    return {
      ...state,
      dealAmendments: state.dealAmendments.filter(
        (dealAmendment: any) => !deletedIds.includes(dealAmendment.id),
      ),
      dealAmendment:
        state.dealAmendment && deletedIds.includes(state.dealAmendment.id)
          ? null
          : state.dealAmendment,
    }
  },
  [types.START_LOADING_DEAL_AMENDMENTS]: (state: DealAmendmentState) => {
    return { ...state, isLoading: true }
  },
  [types.STOP_LOADING_DEAL_AMENDMENTS]: (state: DealAmendmentState) => {
    return { ...state, isLoading: false }
  },
})

export default dealAmendmentsReducer
