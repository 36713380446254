import { Button, ButtonProps, Text } from '@mantine/core'
import { ReactNode } from 'react'

type Props = ButtonProps & {
  text?: string
  onClick?: (e?: any) => void
  children?: ReactNode
}

function PrimaryInput({ text, onClick, children, ...props }: Props) {
  return (
    <Button
      bg="#32439A"
      w="152.81px"
      h="33px"
      style={{ borderRadius: '5px' }}
      onClick={onClick}
      styles={{
        root: {
          '&:hover': {
            background: '#32439A',
          },
        },
      }}
      {...props}
    >
      <Text
        color="#262626"
        fz="14px"
        fw={600}
        style={{
          fontFamily: 'Plus Jakarta Sans',
          color: 'white',
          lineHeight: '21px'
        }}
      >
        {text ?? children}
      </Text>
    </Button>
  )
}
export default PrimaryInput