import { FeePaymentParams } from 'app/models/fee-payment-params'
import {
  submitDraftFeePayment,
  rejectSubmittedFeePayment,
  approveSubmittedFeePayment,
  deleteSelectedFeePayment,
  loadFeePayments,
} from 'app/state/ducks/fee-payment/thunks'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'
import { Dispatch } from 'redux'

export async function submitFeePayment(
  feePayment: FeePaymentParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(submitDraftFeePayment(feePayment?.id ?? ''))
  if (response.success) {
    SuccessNotification({
      title: 'Successful Submission',
      message: 'Fee Payment has been Submitted for Approval',
    })
  } else {
    ErrorNotification({
      title: 'Submission Failed',
      message: response.payload ?? 'Fee Payment Submission Failed',
    })
  }
  dispatch(loadFeePayments())
  return
}

export async function rejectFeePayment(
  feePayment: FeePaymentParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(
    rejectSubmittedFeePayment((feePayment?.id ?? ''))
  )
  if (response.success) {
    SuccessNotification({
      title: 'Successful Rejection',
      message: 'Fee Payment has been moved back to a Draft',
    })
  } else {
    ErrorNotification({
      title: 'Rejection Failed',
      message: response.payload ?? 'Fee Payment Rejection Failed',
    })
  }
  dispatch(loadFeePayments())
  return
}

export async function approveFeePayment(
  feePayment: FeePaymentParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(
    approveSubmittedFeePayment((feePayment?.id ?? ''))
  )
  if (response.success) {
    SuccessNotification({
      title: 'Successful Approval',
      message: 'Fee Payment has been Approved',
    })
  } else {
    ErrorNotification({
      title: 'Approval Failed',
      message: response.payload ?? 'Fee Payment Approval Failed',
    })
  }
  dispatch(loadFeePayments())
  return
}

export async function deleteFeePayment(
  feePayment: FeePaymentParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(
    deleteSelectedFeePayment(feePayment)
  )
  if (response.payload.error) {
    ErrorNotification({
      title: 'Delete Failed',
      message: response.payload.error.message,
    })
  } else {
    SuccessNotification({
      title: 'Successful Deletion',
      message: 'Fee Payment has been Deleted',
    })
  }
  dispatch(loadFeePayments())
  return
}
