import React, { useState } from 'react'
import { Grid, Text, Badge } from '@mantine/core'
import IonIcon from '@reacticons/ionicons'

interface Step {
  label: string
  content: React.ReactNode
}

interface VerticalStepperProps {
  steps: Step[]
  disabledSteps: number[]
  step?: number
}

const VerticalStepper: React.FC<VerticalStepperProps> = ({
  steps,
  disabledSteps = [],
  step,
}) => {
  const [activeStep, setActiveStep] = useState(step ? step : 0)
  const [completedSteps, setCompletedSteps] = useState<number[]>([])

  const handleStepClick = (index: number) => {
    if (!disabledSteps.includes(index)) {
      setActiveStep(index)
    }
  }

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep(step => step + 1)
      setCompletedSteps(prevCompletedSteps => [
        ...prevCompletedSteps,
        activeStep,
      ])
    }
  }

  const handlePrevious = () => {
    if (activeStep > 0) {
      setActiveStep(step => step - 1)
      setCompletedSteps(prevCompletedSteps => prevCompletedSteps.slice(0, -1))
    }
  }

  const isStepDisabled = (index: number) => {
    return disabledSteps.includes(index)
  }

  return (
    <>
      <Grid gutter="lg">
        <Grid.Col span={3} className="stepper-col">
          {steps.map((step, index) =>
            completedSteps.includes(index) ? (
              <div
                key={index}
                className="stepper"
                style={{
                  marginBottom: '10px',
                  cursor: 'pointer',
                  background: activeStep === index ? '#D5F3E7' : '#D5F3E7',
                  opacity: isStepDisabled(index) ? 0.5 : 1,
                }}
                onClick={() => handleStepClick(index)}
              >
                {step.label.indexOf('i.') >= 0 ||
                  step.label.indexOf('v.') >= 0 ? (
                  <Text
                    style={{
                      position: 'relative',
                      fontSize: 14,
                      color: '#046C4E',
                      paddingLeft: 15,
                    }}
                  >
                    {step.label}
                    {completedSteps.includes(index) ? (
                      <Badge
                        variant="light"
                        color="#D5F3E7"
                        style={{
                          backgroundColor: '#D5F3E7',
                          position: 'absolute',
                          top: '50%',
                          right: '-10px',
                          transform: 'translateY(-50%)',
                        }}
                      >
                        <IonIcon name="checkmark-outline" style={{ color: '#046C4E', width: 20, height: 20 }} />
                      </Badge>
                    ) : (
                      activeStep === index && (
                        <Badge
                          variant="light"
                          color="#D5F3E7"
                          style={{
                            backgroundColor: '#D5F3E7',
                            position: 'absolute',
                            top: '50%',
                            right: '-10px',
                            transform: 'translateY(-50%)',
                          }}
                        >
                          <IonIcon name="arrow-forward-outline" style={{ color: '#1A56DB', width: 20, height: 20 }} />
                        </Badge>
                      )
                    )}
                  </Text>
                ) : step.label.indexOf('i.') >= 0 ||
                  step.label.indexOf('v.') >= 0 ? (
                  <Text
                    style={{
                      position: 'relative',
                      fontSize: 14,
                      color: '#046C4E',
                      paddingLeft: 15,
                    }}
                  >
                    {step.label}
                    {completedSteps.includes(index) ? (
                      <Badge
                        variant="light"
                        color="#D5F3E7"
                        style={{
                          backgroundColor: '#D5F3E7',
                          position: 'absolute',
                          top: '50%',
                          right: '-10px',
                          transform: 'translateY(-50%)',
                        }}
                      >
                        <IonIcon name="checkmark-outline" style={{ color: '#046C4E', width: 20, height: 20 }} />
                      </Badge>
                    ) : (
                      activeStep === index && (
                        <Badge
                          variant="light"
                          color="#D5F3E7"
                          style={{
                            backgroundColor: '#D5F3E7',
                            position: 'absolute',
                            top: '50%',
                            right: '-10px',
                            transform: 'translateY(-50%)',
                          }}
                        >
                          <IonIcon name="arrow-forward-outline" style={{ color: '#1A56DB', width: 20, height: 20 }} />
                        </Badge>
                      )
                    )}
                  </Text>
                ) : (
                  <Text
                    style={{
                      position: 'relative',
                      fontSize: 14,
                      color: '#046C4E',
                    }}
                  >
                    {step.label}
                    {completedSteps.includes(index) ? (
                      <Badge
                        variant="light"
                        color="#D5F3E7"
                        style={{
                          backgroundColor: '#D5F3E7',
                          position: 'absolute',
                          top: '50%',
                          right: '-10px',
                          transform: 'translateY(-50%)',
                        }}
                      >
                        <IonIcon name="checkmark-outline" style={{ color: '#046C4E', width: 20, height: 20 }} />
                      </Badge>
                    ) : (
                      activeStep === index && (
                        <Badge
                          variant="light"
                          color="#D5F3E7"
                          style={{
                            backgroundColor: '#D5F3E7',
                            position: 'absolute',
                            top: '50%',
                            right: '-10px',
                            transform: 'translateY(-50%)',
                          }}
                        >
                          <IonIcon name="arrow-forward-outline" style={{ color: '#1A56DB', width: 20, height: 20 }} />
                        </Badge>
                      )
                    )}
                  </Text>
                )}
              </div>
            ) : (
              <div
                key={index}
                className="stepper"
                style={{
                  marginBottom: '10px',
                  cursor: 'pointer',
                  backgroundColor: activeStep === index ? '#EBF5FF' : '#F9FAFB',
                  opacity: isStepDisabled(index) ? 0.5 : 1,
                }}
                onClick={() => handleStepClick(index)}
              >
                {step.label.indexOf('i.') >= 0 ||
                  step.label.indexOf('v.') >= 0 ? (
                  <Text
                    style={{
                      position: 'relative',
                      fontSize: 14,
                      color: activeStep === index ? '#1A56DB' : '#1F2A37',
                      paddingLeft: 15,
                    }}
                  >
                    {step.label}
                    {completedSteps.includes(index) ? (
                      <Badge
                        variant="light"
                        color="#84E1BC"
                        style={{
                          position: 'absolute',
                          top: '50%',
                          right: '-10px',
                          transform: 'translateY(-50%)',
                        }}
                      >
                        <IonIcon name="checkmark-outline" style={{ color: '#046C4E', width: 20, height: 20 }} />
                      </Badge>
                    ) : (
                      activeStep === index && (
                        <Badge
                          variant="light"
                          color="#EBF5FF"
                          style={{
                            position: 'absolute',
                            top: '50%',
                            right: '-10px',
                            transform: 'translateY(-50%)',
                          }}
                        >
                          <IonIcon name="arrow-forward-outline" style={{ color: '#1A56DB', width: 20, height: 20 }} />
                        </Badge>
                      )
                    )}
                  </Text>
                ) : (
                  <Text
                    style={{
                      position: 'relative',
                      fontSize: 14,
                      color: activeStep === index ? '#1A56DB' : '#1F2A37',
                    }}
                  >
                    {step.label}
                    {completedSteps.includes(index) ? (
                      <Badge
                        variant="light"
                        color="#84E1BC"
                        style={{
                          position: 'absolute',
                          top: '50%',
                          right: '-10px',
                          transform: 'translateY(-50%)',
                        }}
                      >
                        <IonIcon name="checkmark-outline" style={{ color: '#046C4E', width: 20, height: 20 }} />
                      </Badge>
                    ) : (
                      activeStep === index && (
                        <Badge
                          variant="light"
                          color="#EBF5FF"
                          style={{
                            position: 'absolute',
                            top: '50%',
                            right: '-10px',
                            transform: 'translateY(-50%)',
                          }}
                        >
                          <IonIcon name="arrow-forward-outline" style={{ color: '#1A56DB', width: 20, height: 20 }} />
                        </Badge>
                      )
                    )}
                  </Text>
                )}
              </div>
            )
          )}
        </Grid.Col>
        <Grid.Col span={9}>
          <div className="content">
            {steps.map((step, index) => (
              <div
                key={index}
                style={{ display: activeStep === index ? 'block' : 'none' }}
              >
                {React.cloneElement(step.content as React.ReactElement<any>, {
                  onNext: handleNext,
                  onPrevious: handlePrevious,
                })}
              </div>
            ))}
          </div>
        </Grid.Col>
      </Grid>
    </>
  )
}

export default VerticalStepper
