import { Menu } from '@mantine/core'
import PrimaryDropDown from 'app/views/components/DropDown/DropDown'
import { useDispatch } from 'react-redux'
import { PikParams } from 'app/models/pik-params'
import {
  approvePIK,
  deletePIK,
  rejectPIK,
  submitPIK,
} from './pik-daml-actions'
import IonIcon from '@reacticons/ionicons'

type Props = {
  row: PikParams
}

export default function PIKActions({ row }: Props) {
  const dispatch = useDispatch()
  return (
    <div>
      <Menu shadow="md" width={200} position="bottom" withinPortal>
        <Menu.Target>
          <div style={{ width: 'fit-content' }}>
            <PrimaryDropDown>Actions</PrimaryDropDown>
          </div>
        </Menu.Target>

        <Menu.Dropdown>
          {row?.status?.toLowerCase() == 'draft' ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => {
                submitPIK(row, dispatch)
              }}
            >
              Submit For Approval
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'submitted' ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => {
                approvePIK(row, dispatch)
              }}
            >
              Approve
            </Menu.Item>
          ) : null}


          {row?.status?.toLowerCase() != 'approved' ? (
            <>
              <Menu.Divider />
              <Menu.Label>Danger zone</Menu.Label>
            </>
          ) : null}

          {row?.status?.toLowerCase() == 'draft' ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name='trash-outline' />}
              onClick={() => {
                deletePIK(row, dispatch)
              }}
            >
              Delete
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'submitted' ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => {
                rejectPIK(row, dispatch)
              }}
            >
              Reject
            </Menu.Item>
          ) : null}
        </Menu.Dropdown>
      </Menu>
    </div>
  )
}
