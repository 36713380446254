import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import {
  getLoanIncreases,
  addLoanIncrease,
  editLoanIncrease,
  submitLoanIncrease,
  approveLoanIncrease,
  rejectLoanIncrease,
  deleteLoanIncrease,
  stopLoadingLoanIncrease,
  startLoadingLoanIncrease,
} from './actions'
import { LoanIncreaseParams } from 'app/models/loan-increase-params'

export const saveLoanIncrease =
  (savedLoanIncrease: LoanIncreaseParams, loanIncrease?: any) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    if (savedLoanIncrease.amount == '') savedLoanIncrease.amount = 0.0
    const updated_savedLoanIncrease: LoanIncreaseParams = {
      ...savedLoanIncrease,
    }
    if (loanIncrease) {
      return client
        .put(
          `${apiServerUrl}/api/draft-loan-increases`,
          updated_savedLoanIncrease,
        )
        .then(response => {
          const payload = dispatch(editLoanIncrease(response))
          return { success: true, payload: payload.payload }
        })
        .catch(e => {
          return { success: false, payload: e.response.data.error }
        })
    }

    return client
      .post(
        `${apiServerUrl}/api/draft-loan-increases`,
        updated_savedLoanIncrease,
      )
      .then(response => {
        const payload = dispatch(addLoanIncrease(response))
        return { success: true, payload: payload.payload }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const loadApprovedLoanIncreases = async (loanId: string | undefined) => {
  const apiServerUrl = config.backendUrl
  if (loanId) {
    return await client
      .get(`${apiServerUrl}/api/approved-loan-increases/loan?id=${loanId}`)
      .then(response => {
        return response.data
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  } else {
    return await client
      .get(`${apiServerUrl}/api/approved-loan-increases`)
      .then(response => {
        console.log('response', response)
        return response.data
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }
}

export const loadDraftLoanIncreases = async (loanId: string | undefined) => {
  const apiServerUrl = config.backendUrl
  if (loanId) {
    return await client
      .get(`${apiServerUrl}/api/draft-loan-increases/loan?id=${loanId}`)
      .then(response => {
        return response.data
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  } else {
    return await client
      .get(`${apiServerUrl}/api/draft-loan-increases`)
      .then(response => {
        return response.data
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }
}

export const loadSubmittedLoanIncreases = async (
  loanId: string | undefined,
) => {
  const apiServerUrl = config.backendUrl
  if (loanId) {
    return await client
      .get(`${apiServerUrl}/api/submitted-loan-increases/loan?id=${loanId}`)
      .then(response => {
        return response.data
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  } else {
    return await client
      .get(`${apiServerUrl}/api/submitted-loan-increases`)
      .then(response => {
        return response.data
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }
}

export const submitForApproval =
  (loanIncrease: LoanIncreaseParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/draft-loan-increases/submit`, {
        id: loanIncrease.id,
      })
      .then(response => {
        const payload = dispatch(submitLoanIncrease(response.data))
        return { success: true, payload: payload.payload }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const approveSelectedLoanIncrease =
  (loanIncrease: LoanIncreaseParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/submitted-loan-increases/approve`, {
        id: loanIncrease.id,
      })
      .then(response => {
        const payload = dispatch(approveLoanIncrease(response.data))
        return { success: true, payload: payload.payload }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const rejectSelectedLoanIncrease =
  (loanIncrease: LoanIncreaseParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/submitted-loan-increases/reject`, {
        id: loanIncrease.id,
      })
      .then(response => {
        const payload = dispatch(rejectLoanIncrease(response.data))
        return { success: true, payload: payload.payload }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const deleteSelectedLoanIncrease =
  (loanIncrease: LoanIncreaseParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .delete(
        `${apiServerUrl}/api/draft-loan-increases?ids[]=${loanIncrease.id}`,
      )
      .then(() => {
        return dispatch(deleteLoanIncrease(loanIncrease))
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const loadAllLoanIncreases =
  (loanId?: string | undefined) => async (dispatch: Dispatch) => {
    try {
      dispatch(startLoadingLoanIncrease())
    let allLoanIncreases: LoanIncreaseParams[] = []

    const draftLoanIncreasesResponse = await loadDraftLoanIncreases(loanId)
    const submittedLoanIncreasesResponse =
      await loadSubmittedLoanIncreases(loanId)

    const draftLoanIncreases: LoanIncreaseParams[] = Array.isArray(
      draftLoanIncreasesResponse,
    )
      ? draftLoanIncreasesResponse.map((loanIncrease: LoanIncreaseParams) => ({
          ...loanIncrease,
          status: 'Draft',
        }))
      : []

    const subLoanIncreases: LoanIncreaseParams[] = Array.isArray(
      submittedLoanIncreasesResponse,
    )
      ? submittedLoanIncreasesResponse.map(
          (a: { loanIncrease: LoanIncreaseParams }) => ({
            ...a.loanIncrease,
            status: 'Submitted',
          }),
        )
      : []

    allLoanIncreases = draftLoanIncreases.concat(subLoanIncreases)

    return dispatch(getLoanIncreases(allLoanIncreases))
      } finally {
        dispatch(stopLoadingLoanIncrease())
      }
  }
