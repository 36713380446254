import { useState } from 'react';
import { Menu } from '@mantine/core';
import { JobParams } from 'app/models/job-params';
import { changeSchedule } from './job-daml-actions';
import { useDispatch } from 'react-redux';
import JobButton from './job-button';
import { jobSchedule } from 'app/models/dropdown-options'

type Props = {
  row: JobParams
}

export default function JobManagementScheduleAction({
  row,
}: Props) {
  const dispatch = useDispatch()
  const initialScheduleLabel = jobSchedule.find(option => option.value === row.schedule)?.label || row.schedule;
  const [selectedSchedule, setSelectedSchedule] = useState(initialScheduleLabel);

  const handleScheduleClick = (value: string, label: string) => {
    if (value !== row.schedule) {
      changeSchedule({ ...row, schedule: value }, dispatch);
      setSelectedSchedule(label);
    }
  };

  return (
    <div>
      <Menu shadow="md" width={200} position="bottom" withinPortal>
        <Menu.Target>
          <div style={{ minWidth: 120 }}>
            <JobButton minWidth={120}>{selectedSchedule}</JobButton>
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          {jobSchedule.map(({ label, value }) => (
            <Menu.Item
              key={value}
              onClick={() => handleScheduleClick(value, label)}
              style={{
                backgroundColor: selectedSchedule === label ? '#32439A' : 'transparent',
                color: selectedSchedule === label ? '#fff' : 'inherit',
              }}
            >
              {label}
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
    </div>
  );
}
