import isomorphicFetch from 'isomorphic-fetch'

export default (url: any, method: string, body: any) => {
  const options = {
    method,
    headers: requestHeaders(),
    body: method !== 'GET' ? JSON.stringify(body) : null,
  }

  return isomorphicFetch(url, options).then(
    (res: { status: any; json: () => any }) =>
      parseStatus(res.status, res.json())
  )
}

function parseStatus(status: number, res: Promise<any>) {
  return new Promise((resolve, reject) => {
    if (status >= 200 && status < 300) {
      res.then((response: unknown) => resolve(response))
    } else {
      res.then((response: any) => reject({ status, response }))
    }
  })
}

function requestHeaders() {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
}
