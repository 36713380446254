export const GET_ASSIGNMENTS = 'entities/GET_ASSIGNMENTS'
export const GET_ASSIGNMENT = 'entities/GET_ASSIGNMENT'
export const ADD_ASSIGNMENTS = 'entities/ADD_ASSIGNMENTS'
export const EDIT_ASSIGNMENTS = 'entities/EDIT_ASSIGNMENTS'
export const SUBMIT_ASSIGNMENT = 'entities/SUBMIT_ASSIGNMENT'
export const REJECT_ASSIGNMENT = 'entities/REJECT_ASSIGNMENT'
export const APPROVE_ASSIGNMENT = 'entities/APPROVE_ASSIGNMENT'
export const DELETE_ASSIGNMENT = 'entities/DELETE_ASSIGNMENT'
export const START_LOADING_ASSIGNMENTS = 'entities/START_LOADING_ASSIGNMENTS'
export const STOP_LOADING_ASSIGNMENTS = 'entities/STOP_LOADING_ASSIGNMENTS'
