import {
  EntityProfileOptions,
  EntityTypeOptions,
  kycTypeOptions,
  countries,
  states,
  taxFormOptions,
  entityReferenceOptions,
  taxFormStatusOptions,
  institutionFinancialTypeOptions,
  kycStatusOptions,
} from 'app/models/dropdown-options'
import TextInput from 'app/views/components/inputs/TextInput'
import { Group, MultiSelect, Select, Stack, Divider } from '@mantine/core'
import FormWrapper from 'app/views/components/Form/FormWrapper'
import { UseFormReturnType, useForm } from '@mantine/form'
import {
  EntityParams,
  entityTypeWithoutFundManager
} from 'app/models/entity-params'
import CustomDatePicker from 'app/views/components/date-picker/date-picker-logic'
import { useState } from 'react'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import { saveEntity } from 'app/state/ducks/entities/thunks'
import { useDispatch } from 'react-redux'
import config from 'app/config/config'
import { formatDateToUTC, isAlphanumeric, isNumeric, stringToDate } from 'app/utils/util-functions'
import { useNavigate } from 'react-router-dom'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'

type EntitiesFormProps = {
  entity?: EntityParams
  closeModal: () => void
  isStepper?: boolean
  onHandleSubmit?: any
  withRedirection?: boolean
}

const GIN_REGEX = /^[A-NP-Z0-9]{6}\.[A-NP-Z0-9]{5}\.(?:LE|SL|ME|BR|SP|SF|SD|SS|SB)\.\d{3}$/

export default function EntitiesForm({
  entity,
  closeModal,
  isStepper,
  onHandleSubmit,
  withRedirection,
}: EntitiesFormProps) {
  const [isLoading, setIsLoading] = useState(false)

  if (entity?.ukTreatyPassportExpiryDate)
    stringToDate(entity.ukTreatyPassportExpiryDate)
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const form = useForm({
    initialValues: {
      id: entity?.id ?? '',
      entityName: entity?.entityName ?? '',
      entityType: entity?.entityType ?? null,
      entityProfile: entity?.entityProfile ?? [],
      entityReference: entity?.entityReference ?? null,
      address: entity?.address ?? null,
      address2: entity?.address2 ?? null,
      city: entity?.city ?? null,
      state: entity?.state ?? null,
      zipCode: entity?.zipCode ?? null,
      country: entity?.country ?? null,
      kycType: entity?.kycType ?? '',
      kycStatus: entity?.kycStatus ?? '',
      institutionType: entity?.institutionType ?? '',
      countryOfIncorporation: entity?.countryOfIncorporation ?? null,
      countryOfTaxResidence: entity?.countryOfTaxResidence ?? null,
      taxFormStatus: entity?.taxFormStatus ?? null,
      usTaxFormType: entity?.usTaxFormType ?? null,
      fundManager: entity?.fundManager ?? null,
      fundManagerMei: entity?.fundManagerMei ?? null,
      parent: entity?.parent ?? null,
      parentMei: entity?.parentMei ?? null,
      giin: entity?.giin ?? null,
      crn: entity?.crn ?? null,
      naicCode: entity?.naicCode ?? null,
      internalId: entity?.internalId ?? null,
      legalEntityId: entity?.legalEntityId ?? null,
      mei: entity?.mei ?? null,
      ein: entity?.ein ?? null,
      cei: entity?.cei ?? null,
      ukTreatyPassportNumber: entity?.ukTreatyPassportNumber ?? null,
      status: entity?.status ?? '',
      ukTreatyPassportExpiryDate: entity?.ukTreatyPassportExpiryDate
        ? stringToDate(entity?.ukTreatyPassportExpiryDate)
        : null,
      entityParty: entity?.entityParty ?? null,
      hasError: entity?.hasError ?? false,
      ErrorMessage: entity?.ErrorMessage ?? '',
      hasSuccessfulEntitySave: entity?.hasSuccessfulEntitySave ?? false,
      customer_token: entity?.customer_token ?? config.company.toUpperCase(),

    },
    validate: {
      // Required fields for draft entity
      entityName: value => (!value.trim() ? 'Required' : null),
      kycStatus: value => (!value.trim() ? 'Required' : null),
      // Fields not required for draft entity, but validation rules are there if data is entered
      entityType: value =>
        value && (value === '' || value === null || value === undefined)
          ? null
          : null,
      fundManagerMei: (value, values) => {
        if (values.entityType === '' || values.entityType === null) {
          // If entityType is blank, don't validate fundManagerMei
          return null;
        }
        // If entityType is not in the list, apply validation
        return !entityTypeWithoutFundManager.includes(values.entityType)
          ? value && value.length !== 10
            ? '10 characters'
            : null
          : null;
      },
      parentMei: (value, values) => {
        if (values.entityType === '' || values.entityType === null) {
          // If entityType is blank, don't validate parentMei
          return null;
        }
        // If entityType is not in the list, apply validation
        return !entityTypeWithoutFundManager.includes(values.entityType)
          ? value && value.length !== 10
            ? '10 characters'
            : null
          : null;
      },
      legalEntityId: value =>
        value && value !== '' && value.length !== 20 ? '20 characters' : null,
      giin: value =>
        value && !GIN_REGEX.test(value) ? 'GIIN must be in XXXXXX.XXXXX.XX.XXX format. (example: "AZ9123.AXCV0.LE.123")' : null,
      crn: value =>
        value && value !== '' && (value.length !== 8 || !isAlphanumeric(value)) ? '8 Alphanumeric Characters' : null,
      ein: value =>
        value && value !== '' && (value.length !== 9 || !isNumeric(value)) ? '9 Numeric Characters' : null,
      cei: value =>
        value && value !== '' && value.length !== 10 ? '10 characters' : null,
      naicCode: value =>
        value && value != '' && ((value.length < 2 || value.length > 6) || !isNumeric(value))
          ? 'Between 2 and 6 numeric characters'
          : null,
      mei: value =>
        value && (value == '' || value.length !== 10) ? 'MEI Should Be 10 Characters' : null,
      zipCode: value =>
        value && value !== '' && (!isAlphanumeric(value)) ? 'ZipCode must be Alphanumeric' : null,
    },
    transformValues: values => ({
      ...values,
      ukTreatyPassportExpiryDate:
        values.ukTreatyPassportExpiryDate === null
          ? null
          : formatDateToUTC(values.ukTreatyPassportExpiryDate),
    }),
    validateInputOnBlur: true,
  })

  async function onSubmit(values: any) {
    try {
      setIsLoading(true)
      const entityparams: EntityParams = {
        ...values,
        ukTreatyPassportExpiryDate: values.ukTreatyPassportExpiryDate
          ? formatDateToUTC(values.ukTreatyPassportExpiryDate)
          : null,
        hasError: false,
        hasSuccessfulEntitySave: false,
        ErrorMessage: '',
        customer_token: config.company.toUpperCase(),
        kycType: values.kycType === '' ? null : values.kycType,
        kycStatus: values.kycStatus,
        fundManagerMei: (values.fundManagerMei =
          entityTypeWithoutFundManager.includes(values.entityType)
            ? null
            : values.fundManagerMei),
        fundManager: (values.fundManager =
          entityTypeWithoutFundManager.includes(values.entityType)
            ? null
            : values.fundManager),
        parentMei: (values.parentMei = entityTypeWithoutFundManager.includes(
          values.entityType
        )
          ? values.parentMei
          : null),
        parent: (values.fundManager = entityTypeWithoutFundManager.includes(
          values.entityType
        )
          ? values.parent
          : null),
        institutionType:
          values.institutionType === '' ? null : values.institutionType,
        ukTreatyPassportNumber:
          values.ukTreatyPassportNumber === ''
            ? null
            : values.ukTreatyPassportNumber,
      }
      const response: any = await dispatch(saveEntity(entityparams, entity))

      if (response.success) {
        SuccessNotification({
          title: 'Successful Save Entity',
          message: 'You created or edited an entity',
        })
        if (withRedirection) {
          closeModal()
          navigate(
            `/entitymanagement/entity/${response?.payload?.data?.id}/Inactive`
          )
        }
        return response.payload
      } else {
        ErrorNotification({
          title: 'Entity save failed',
          message: response.payload ?? 'Check data and try again',
        })
      }
      return undefined
    } catch {
      ErrorNotification({
        title: 'Entity save failed',
        message: 'Check data and try again',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleEntityProfileChange = (
    selectedValue: string[],
    form: UseFormReturnType<
      EntityParams,
      (values: EntityParams) => EntityParams
    >
  ) => {
    form.setFieldValue('entityProfile', selectedValue)
    const isAdminAgentWeOperateOrLenderWe = selectedValue.every(
      (item: string) => item === 'AdminAgentWeOperate' || item === 'LenderWe'
    )

    form.setFieldValue(
      'kycType',
      selectedValue.includes('BorrowerLending') ||
        selectedValue.includes('LenderCounterParty')
        ? 'Full'
        : isAdminAgentWeOperateOrLenderWe
          ? ''
          : 'Lite'
    )
  }

  async function submitForm(event: any) {
    event.preventDefault()
    if (!form.validate().hasErrors) {
      const entity = await onSubmit(form.getTransformedValues())
      onHandleSubmit(entity ?? form.values)
    } else {
      ErrorNotification({
        title: 'Form Validation Error',
        message: 'Please fill in required fields',
      });
    }
  }

  async function submitAndExit() {
    if (!form.validate().hasErrors) {
      withRedirection = true
      const entity = await onSubmit(form.getTransformedValues())
      onHandleSubmit(entity ?? form.values)
    } else {
      ErrorNotification({
        title: 'Form Validation Error',
        message: 'Please fill in required fields',
      });
    }
  }

  return (
    <FormWrapper title={`${entity ? 'Edit' : 'Add a New'} Entity`}>
      <div className="content">
        <form onSubmit={submitForm}>
          <div className="create-new">
            <Stack w="100%">
              <span className="form-subtitle">
                The following fields are required for entity creation
              </span>

              <Group noWrap w="100%">
                <TextInput
                  w="100%"
                  withAsterisk
                  label="Entity Name"
                  name="entityName"
                  id="name"
                  placeholder="Enter Entity Name"
                  {...form.getInputProps('entityName')}
                />
                <TextInput
                  w="100%"
                  label="MEI (Market Entity Identifier)"
                  name="mei"
                  id="mei"
                  placeholder="Enter MEI"
                  minLength={10}
                  maxLength={10}
                  {...form.getInputProps('mei')}
                  onChange={(e) => form.setFieldValue('mei', e.target.value.toUpperCase())}
                />
                <Select
                  searchable
                  clearable
                  w="100%"
                  label="Institution Type"
                  placeholder="Select Institution Type"
                  data={institutionFinancialTypeOptions}
                  {...form.getInputProps('institutionType')}
                />
              </Group>

              <Group noWrap w="100%">
                <MultiSelect
                  w="100%"
                  label="Entity Profile"
                  placeholder="Select entity profile"
                  searchable
                  data={EntityProfileOptions ?? []}
                  value={form.values.entityProfile}
                  onChange={(e: any) => handleEntityProfileChange(e, form as any)}
                />
                <Select
                  error
                  w="100%"
                  label="Entity Type"
                  placeholder="Select contact type"
                  searchable
                  data={EntityTypeOptions}
                  {...form.getInputProps('entityType')}
                />
                <Select
                  w="100%"
                  label="KYC Type"
                  placeholder="Enter KYC Type"
                  searchable
                  data={kycTypeOptions}
                  {...form.getInputProps('kycType')}
                />
              </Group>

              <Group noWrap w="100%">
                <TextInput
                  w="100%"
                  label="Address"
                  type="text"
                  name="address"
                  id="address"
                  placeholder="Enter address"
                  {...form.getInputProps('address')}
                />
                <TextInput
                  w="100%"
                  label="Address Line 2"
                  type="text"
                  name="address2"
                  id="address2"
                  placeholder="Apt, Suite, etc. - Optional"
                  {...form.getInputProps('address2')}
                />
              </Group>

              <Group noWrap w="100%">
                <Select
                  w="100%"
                  label="Country/Region"
                  name="country"
                  id="country"
                  placeholder="Select country/region"
                  searchable
                  data={countries}
                  {...form.getInputProps('country')}
                />
                <TextInput
                  w="100%"
                  label="City"
                  type="text"
                  name="city"
                  id="city"
                  placeholder="Enter city"
                  {...form.getInputProps('city')}
                />
              </Group>

              <Group noWrap w="100%">
                {form.values.country === 'US' ? (
                  <Select
                    w="100%"
                    label="State"
                    name="state"
                    id="state"
                    placeholder="Select state"
                    searchable
                    data={states}
                    {...form.getInputProps('state')}
                  />
                ) : (
                  <TextInput
                    w="100%"
                    label="State/Region"
                    type="text"
                    name="state"
                    id="state"
                    placeholder="Enter state/region"
                    {...form.getInputProps('state')}
                  />
                )}
                <TextInput
                  w="100%"
                  label="ZIP/Postal Code"
                  type="text"
                  name="zipCode"
                  id="zipCode"
                  placeholder="Enter ZIP/Postal Code"
                  {...form.getInputProps('zipCode')}
                />
              </Group>

              <span className="form-subtitle-large">
                Identifiers
              </span>

              <Divider my={0} />

              {form.values.entityType && (
                <Group noWrap w="100%">
                  {!entityTypeWithoutFundManager.includes(
                    form.values.entityType
                  ) && (
                      <>
                        <TextInput
                          w="100%"
                          label="Fund Manager"
                          name="fundManager"
                          id="fundManager"
                          placeholder="Enter fund manager"
                          {...form.getInputProps('fundManager')}
                        />
                        <TextInput
                          w="100%"
                          label="Fund Manager MEI"
                          name="fundManagermei"
                          id="fundManagerMei"
                          placeholder="Fund manager MEI"
                          minLength={10}
                          maxLength={10}
                          {...form.getInputProps('fundManagerMei')}
                          onChange={(e) => form.setFieldValue('fundManagerMei', e.target.value.toUpperCase())}
                        />
                      </>
                    )}

                  {entityTypeWithoutFundManager.includes(
                    form.values.entityType
                  ) && (
                      <>
                        <TextInput
                          w="100%"
                          label="Parent"
                          name="lenderParent"
                          id="lenderParent"
                          placeholder="Name of legal parent if different from entity"
                          {...form.getInputProps('parent')}
                        />
                        <TextInput
                          w="100%"
                          label="Parent MEI"
                          name="parentMei"
                          id="parentMei"
                          maxLength={10}
                          placeholder="Parent MEI"
                          {...form.getInputProps('parentMei')}
                          onChange={(e) => form.setFieldValue('parentMei', e.target.value.toUpperCase())}
                        />
                      </>
                    )}
                </Group>
              )}
              <Group noWrap w="100%">
                <TextInput
                  w="100%"
                  label="Internal Entity ID"
                  name="internalId"
                  id="internalId"
                  placeholder="Enter Internal Entity ID"
                  {...form.getInputProps('internalId')}
                />
                <TextInput
                  w="100%"
                  label="LEI"
                  name="legalEntityId"
                  id="legalEntityId"
                  placeholder="Enter LEI unique 20 digit code number"
                  maxLength={20}
                  {...form.getInputProps('legalEntityId')}
                  onChange={(e) => form.setFieldValue('legalEntityId', e.target.value.toUpperCase())}
                />
                <TextInput
                  w="100%"
                  label="CEI"
                  name="CEI"
                  id="cei"
                  placeholder="CUSIP Entity Identifier 10 character"
                  maxLength={10}
                  {...form.getInputProps('cei')}
                  onChange={(e) => form.setFieldValue('cei', e.target.value.toUpperCase())}
                />
              </Group>

              <Group noWrap w="100%">
                <TextInput
                  w="100%"
                  label="NAIC Code"
                  name="naicCode"
                  id="naicCode"
                  placeholder="Enter NAIC Code"
                  maxLength={6}
                  {...form.getInputProps('naicCode')}
                  onChange={(e) => { form.setFieldValue('naicCode', e.target.value.toUpperCase()) }}
                />
                <TextInput
                  w="100%"
                  label="CRN"
                  name="crn"
                  id="crn"
                  minLength={8}
                  maxLength={8}
                  placeholder="UK Company Registration Number"
                  {...form.getInputProps('crn')}
                  onChange={(e) => form.setFieldValue('crn', e.target.value.toUpperCase())}
                />
                <TextInput
                  w="100%"
                  label="EIN"
                  name="ein"
                  id="ein"
                  placeholder="Enter EIN"
                  {...form.getInputProps('ein')}
                  onChange={(e) => form.setFieldValue('ein', e.target.value.toUpperCase())}
                  maxLength={9}
                />
              </Group>

              <Group noWrap w="100%">
                <TextInput
                  w="100%"
                  label="GIIN (Global Intermediary Identification Number)"
                  name="giin"
                  id="giin"
                  placeholder="e. g. XXXXXX.XXXXX.XX.XXX"
                  {...form.getInputProps('giin')}
                  onChange={(e) => form.setFieldValue('giin', e.target.value.toUpperCase())}
                  maxLength={19}
                />
              </Group>

              <span className="form-subtitle-large">
                Tax/KYC
              </span>

              <Divider my={0} />

              <Group noWrap w="100%">
                <Select
                  w="100%"
                  label="Entity Reference"
                  placeholder="Select entity reference"
                  searchable={true}
                  data={entityReferenceOptions}
                  {...form.getInputProps('entityReference')}
                />
                <Select
                  w="100%"
                  label="US Tax Form Type"
                  placeholder="Select tax form type"
                  searchable={true}
                  data={taxFormOptions}
                  {...form.getInputProps('usTaxFormType')}
                />
                <Select
                  w="100%"
                  label="Tax Form Status"
                  placeholder="Select tax form status"
                  searchable={true}
                  data={taxFormStatusOptions}
                  {...form.getInputProps('taxFormStatus')}
                />
              </Group>

              <Group noWrap w="100%">
                <Select
                  w="100%"
                  withAsterisk
                  required
                  label="KYC Status"
                  placeholder="Enter KYC status"
                  searchable={true}
                  data={kycStatusOptions}
                  {...form.getInputProps('kycStatus')}
                />
                <Select
                  w="100%"
                  label="Country of Incorporation"
                  placeholder="Select country"
                  searchable
                  data={countries}
                  {...form.getInputProps('countryOfIncorporation')}
                />
                <Select
                  w="100%"
                  label="Country of Tax Residence"
                  placeholder="Select country"
                  searchable
                  data={countries}
                  {...form.getInputProps('countryOfTaxResidence')}
                />
              </Group>
              {form.values.countryOfTaxResidence === 'GB' ?
                <Group noWrap w="100%">
                  <TextInput
                    w="100%"
                    label="UK Treaty Passport #"
                    name="ukTreatyPassportNumber"
                    id="ukTreatyPassportNumber"
                    placeholder="e. g. 3/M365185/DTTP"
                    {...form.getInputProps('ukTreatyPassportNumber')}
                  />
                  <CustomDatePicker
                    w="100%"
                    name={'ukTreatyPassportExpiryDate'}
                    label={'UK Treaty Passport Expiry Date'}
                    required={
                      form.values.ukTreatyPassportNumber !== null &&
                      form.values.ukTreatyPassportNumber.length > 0
                    }
                    date={form.values.ukTreatyPassportExpiryDate as Date}
                    setDate={value => form.setFieldValue('ukTreatyPassportExpiryDate', value)}
                    holidayCalendars={[]}
                  />
                </Group>
                : null}

              <div
                style={{
                  marginTop: '1rem',
                  display: 'flex',
                  gap: '.5rem',
                  justifyContent: 'flex-end',
                }}
              >
                {isStepper ? (
                  <>
                    <Group noWrap w="100%">
                      <PrimaryButton
                        disabled={!form.isValid()}
                        className="form-button"
                        loading={isLoading}
                        type="submit"
                        w="100%"
                      >
                        Save and Proceed
                      </PrimaryButton>
                      <PrimaryButton
                        disabled={!form.isValid()}
                        className="form-button"
                        loading={isLoading}
                        onClick={() => submitAndExit()}
                        w="100%"
                      >
                        Save and Exit
                      </PrimaryButton>
                    </Group>
                  </>
                ) : (
                  <>
                    <PrimaryButton
                      className="form-button"
                      w="100%"
                      disabled={!form.isValid()}
                      loading={isLoading}
                      type="submit"
                      text="Save"
                    />
                    <PrimaryButton
                      className="form-button"
                      w="100%"
                      onClick={() => form.reset()}
                      text="Clear"
                    />
                  </>
                )}
              </div>
            </Stack>
          </div>
        </form>
      </div>
    </FormWrapper>
  )
}
