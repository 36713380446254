import {
  Button,
  createStyles,
  Divider,
  Group,
  Menu,
} from '@mantine/core'
import { useAuth0 } from '@auth0/auth0-react'
import IonIcon from '@reacticons/ionicons'
import CurrentDate from './CurrentDate'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { loadBusinessDate } from 'app/state/ducks/business-date/thunks'
import HeaderStyles from './HeaderStyles'

export const UserHeader: React.FC = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0()

  const { classes } = createStyles(theme => HeaderStyles(theme))()


  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadBusinessDate())
  }, [])

  const handleLoginBtn = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: '/mywork',
      },
    })
  }

  const handleSignupBtn = () => {
    window.location.href = '/signup'
  }

  const handleLogoutBtn = () => {
    logout({ logoutParams: { returnTo: window.location.origin } })
  }

  return (
    <Group spacing="10px" position="center" p='0px 30px' align='center' className={classes.hiddenMobile}>
      {!isAuthenticated && (
        <Button
          style={{ height: '35px', backgroundColor: '#32439A', color: '#fff' }}
          variant="default"
          onClick={() => handleLoginBtn()}
        >
          Log in
        </Button>
      )}
      {!isAuthenticated && (
        <Button
          style={{ height: '35px', backgroundColor: '#32439A' }}
          onClick={() => handleSignupBtn()}
        >
          Sign up
        </Button>
      )}
      {isAuthenticated && (
        <>
          <CurrentDate />
          <div style={{ height: '100%', padding: '0px 12px' }} >
            <Divider orientation='vertical' color='#000000' h='30px' />
          </div>
          <IonIcon size='large' name='notifications-circle-outline' style={{ fontSize: '14px', color: '#45526C' }} />
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <div>
                <IonIcon size="large" name="person-circle-outline" style={{ fontSize: '14px', color: '#45526C', cursor: 'pointer', padding: '0px 5px' }} />
              </div>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item>
                <Button
                  style={{
                    backgroundColor: 'transparent',
                    color: '#32439A',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleLogoutBtn()}
                >
                  Log out
                </Button>
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </>
      )}
    </Group>

  )
}
