import { PikParams } from 'app/models/pik-params'
import {
  submitDraftPik,
  rejectSubmittedPik,
  approveSubmittedPik,
  deleteSelectedPik,
  loadPaymentInKinds,
  savePik,
} from 'app/state/ducks/payment-in-kind/thunks'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'
import { Dispatch } from 'redux'

export async function submitPIK(
  pik: PikParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(submitDraftPik(pik.id))
  if (response.success) {
    SuccessNotification({
      title: 'Successful Submission',
      message: 'PIK has been Submitted for Approval',
    })
  } else {
    ErrorNotification({
      title: 'Submission Failed',
      message: response.payload ?? 'Could not submit PIK',
    })
  }
  dispatch(loadPaymentInKinds(pik.loanId.id))
  return
}

export async function rejectPIK(
  pik: PikParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(rejectSubmittedPik(pik.id))
  if (response.success) {
    SuccessNotification({
      title: 'Successful Rejection',
      message: 'PIK has been moved back to a Draft',
    })
  } else {
    ErrorNotification({
      title: 'Rejection Failed',
      message: response.payload ?? 'Could not reject PIK',
    })
  }
  return
}

export async function approvePIK(
  pik: PikParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(
    approveSubmittedPik(pik.id)
  )
  if (response.success) {
    SuccessNotification({
      title: 'Successful Approval',
      message: 'PIK has been Approved',
    })
  } else {
    ErrorNotification({
      title: 'Approval Failed',
      message: response.payload ?? 'Could not approve PIK',
    })
  }
  return
}

export async function deletePIK(
  pik: PikParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(deleteSelectedPik(pik))
  if (response.payload.error) {
    ErrorNotification({
      title: 'Delete Failed',
      message: response.payload.error.message,
    })
  } else {
    SuccessNotification({
      title: 'Successful Deletion',
      message: 'PIK has been Deleted',
    })
  }
  return
}

export async function createDraftPIK(
  values: PikParams,
  dispatch: Dispatch<any>,
  pik?: PikParams,
) {
  const response: any = await dispatch(savePik(values, pik))
  if (!response.success) {
    ErrorNotification({
      title: `PIK ${pik? 'Edit' :'Creation'} failed`,
      message: response.payload,
    })
  } else {
    SuccessNotification({
      title: `Successful ${pik? 'Edit' :'Creation'} PIK`,
      message: `PIK has been ${pik? 'Edited' :'Created'}`,
    })
    return response.payload
  }
}
