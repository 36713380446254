export default function getInitials(name?: string): string {
  if (!name) {
    return ''
  }
  return name
    .split(' ')
    .map((name: string, i: number) => (i < 2 ? name.at(0) : ''))
    .join('')
    .toUpperCase()
}
