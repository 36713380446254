import { PageLayout } from 'app/views/components/layout/page-layout'
import TradeOneHeader from './trade-one-header'

export default function SingleTradeAssignmentPage() {
  return (
    <PageLayout>
      <TradeOneHeader />
    </PageLayout>
  )
}
