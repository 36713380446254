import { useState } from 'react'
import PrimaryInput from 'app/views/components/buttons/PrimaryInput'

function CreateActivityButton() {
    const [, setOpen] = useState(false)

    return (
        <>
            <PrimaryInput onClick={() => setOpen(true)} text="Create Activity" />
        </>
    )
}

export default CreateActivityButton
