import { LetterOfCreditParams } from 'app/models/letter-of-credit'
import {
  approveSelectedLetterOfCredit,
  deleteSelectedLetterOfCredit,
  rejectSelectedLetterOfCredit,
  submitForApproval,
} from 'app/state/ducks/letters-of-credits/thunks'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'
import { Dispatch } from 'redux'

export async function submitLetterOfCredit(
  loan: LetterOfCreditParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(submitForApproval(loan))
  if (response.success) {
    SuccessNotification({
      title: 'Successful Submission',
      message: 'Letter of Credit has been Submitted for Approval',
    })
  } else {
    ErrorNotification({
      title: 'Submission Failed',
      message: response.payload ?? 'Letter of Credit could not be submitted',
    })
  }
  return
}

export async function rejectLetterOfCredit(
  loan: LetterOfCreditParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(rejectSelectedLetterOfCredit(loan))
  if (response.success) {
    SuccessNotification({
      title: 'Successful Rejection',
      message: 'Letter of Credit has been moved back to a Draft',
    })
  } else {
    ErrorNotification({
      title: 'Rejection Failed',
      message: response.payload ?? 'Letter of Credit could not be rejected',
    })
  }
  return
}

export async function approveLetterOfCredit(
  letterOfCredit: LetterOfCreditParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(
    approveSelectedLetterOfCredit(letterOfCredit)
  )
  if (response.success) {
    SuccessNotification({
      title: 'Successful Approval',
      message: 'Letter of Credit has been Approved',
    })
  } else {
    ErrorNotification({
      title: 'Approval Failed',
      message: response.payload ?? 'Please check the fields and try again',
    })
  }
  return
}

export async function deleteLetterOfCredit(
  loan: LetterOfCreditParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(deleteSelectedLetterOfCredit(loan))
  if (response.payload.error) {
    ErrorNotification({
      title: 'Delete Failed',
      message: response.payload.error.message,
    })
  } else {
    SuccessNotification({
      title: 'Successful Deletion',
      message: 'Letter of Credit has been Deleted',
    })
  }
  return
}
