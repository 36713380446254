import { useCallback } from 'react'
import { Assignment } from '../params'
import { LendersPosition } from 'app/models/lenders-position-params'
import { UseListStateHandlers } from '@mantine/hooks'

type UseAdjustLenderPositionAddProps = {
  updatedLendersPositions: LendersPosition[]
  updatedLendersPositionsHandler: UseListStateHandlers<LendersPosition>
  createNewLenderPosition: (trade: any, id: string) => LendersPosition
}

const useAdjustLenderPositionAdd = ({
  updatedLendersPositions,
  updatedLendersPositionsHandler,
  createNewLenderPosition,
}: UseAdjustLenderPositionAddProps) => {
  const adjustLenderPositionAdd = useCallback(
    (assignment: Assignment) => {
      const { from, to, amount } = assignment
      const existingLenderIndex = updatedLendersPositions.findIndex(
        lender => lender.lender.id === to
      )

      if (existingLenderIndex !== -1) {
        updatedLendersPositionsHandler.setState(
          updatedLendersPositions.map(lender => {
            if (lender.lender.id === from) {
              return {
                ...lender,
                amount: (Number(lender.amount) - amount).toString(),
              }
            }
            if (lender.lender.id === to) {
              return {
                ...lender,
                amount: (Number(lender.amount) + amount).toString(),
              }
            }
            return lender
          })
        )
      } else {
        const newLender = createNewLenderPosition(assignment, to)
        updatedLendersPositions.push(newLender)
        updatedLendersPositionsHandler.setState(
          updatedLendersPositions.map(lender => {
            if (lender.lender.id === from) {
              return {
                ...lender,
                amount: (Number(lender.amount) - amount).toString(),
              }
            }
            return lender
          })
        )
      }
    },
    [
      updatedLendersPositions,
      updatedLendersPositionsHandler,
      createNewLenderPosition,
    ]
  )

  return adjustLenderPositionAdd
}

export default useAdjustLenderPositionAdd
