import { Grid } from '@mantine/core'
import React, { useImperativeHandle } from 'react'
import { saveDeal } from 'app/state/ducks/deals/thunks'
import { useDispatch } from 'react-redux'
import { isNotEmpty, useForm } from '@mantine/form'
import { Group, Box } from '@mantine/core'
import {
  DealParams,
  DraftDealParams,
  DealPricingGrid
} from 'app/models/deal-params'
import { saveDealAmendment } from 'app/state/ducks/deal-amendments/thunks'
import { ErrorNotification, SuccessNotification } from '../notifications/notification'
import AddGridButton from './add-grid-button'
import { PricingGridEntry } from './pricing-grid-entry'


type Props = {
  deal: any
  setDeal: React.Dispatch<React.SetStateAction<any>>
  setAmendment: React.Dispatch<React.SetStateAction<any>>
  amendment?: string | null | undefined
  dealAmendment?: DraftDealParams | DealParams
  setDisabledSteps?: React.Dispatch<React.SetStateAction<number[]>>
}

export interface ChildDealManagePricingGridRef {
  handleClick: () => void
}

const DealPricingGridComponent: React.ForwardRefRenderFunction<
  ChildDealManagePricingGridRef,
  Props
> = ({ deal, setDeal, setAmendment, amendment, dealAmendment }, ref) => {
  const dispatch = useDispatch()

  deal = dealAmendment ? dealAmendment : deal
  const handleClick = async () => {
    const updatedForm: DealPricingGrid[] = []
    form.values.pricingGrid.forEach((pricingGrid: DealPricingGrid) => {
      if (pricingGrid.pricingGridType) {
        updatedForm.push(pricingGrid)
      }
    })
    form.values.pricingGrid = updatedForm
    const checkIfPricingGridTypes = form
      .getTransformedValues()
      .pricingGrid.map(
        (pricingGrid: any) => pricingGrid.pricingGridType == '' || pricingGrid.pricingGridType == null
      )
    let dealSubmission
    let submit = false
    let response: any
    if (checkIfPricingGridTypes.includes(false)) {
      if (form.validate().hasErrors === true) {
        throw Error
      }
      submit = true
      const dealValues = {
        ...deal,
        pricingGrid: form.getTransformedValues().pricingGrid,
      }
      if (amendment) {
        const dealAmendmentId = dealAmendment?.id ? (dealAmendment?.id) : null
        dealSubmission = saveDealAmendment(dealValues, dealAmendmentId)
      } else {
        dealSubmission = saveDeal(dealValues, deal.id)
      }
    } else if (deal.fees.length > 0) {
      submit = true
      const dealValues = {
        ...deal,
        pricingGrid: [],
      }
      if (amendment) {
        submit = true
        const dealAmendmentId = dealAmendment?.id ? (dealAmendment?.id) : null
        dealSubmission = saveDealAmendment(dealValues, dealAmendmentId)
      } else {
        submit = true
        dealSubmission = saveDeal(dealValues, deal.id)
      }
    }

    if (submit) {
      response = await dispatch(dealSubmission)
      if (response.success) {
        const submitDealValues = {
          ...deal,
          pricingGrid: form.getTransformedValues().pricingGrid,
        }
        if (amendment) {
          setAmendment(submitDealValues)
        } else {
          setDeal(submitDealValues)
        }
        SuccessNotification({
          title: 'Successful Deal Creation',
          message: 'You can now add Pricing Grids to the deal.',
        })
      } else {
        ErrorNotification({
          title: 'Failed Deal Creation',
          message: response.payload ?? 'Check deal inputs and try again',
        })
      }
    }
  }

  // Expose the handleClick method to the parent component
  useImperativeHandle(ref, () => ({
    handleClick,
  }))

  const form = useForm({
    initialValues: {
      pricingGrid:
        deal?.pricingGrid?.length > 0
          ? deal?.pricingGrid.map((pricingGrid: DealPricingGrid) => ({
            ...pricingGrid,
            levels: pricingGrid.levels.map((level: string) => (level ? level : "")),
          }
          ))
          : [
            {
              pricingGridType: '',
              marginOrFee: '',
              levels: [''],
            },
          ],
    },
    transformValues(values) {
      return {
        pricingGrid: values.pricingGrid.map((pricingGrid: DealPricingGrid) => {
          const levels = pricingGrid.levels.length === 1 && pricingGrid.levels[0] === '' ? [] : pricingGrid.levels
          return {
            ...pricingGrid,
            levels: levels,
          }
        }),
      }
    },
    validate: {
      pricingGrid: {
        pricingGridType: isNotEmpty(' Choose a Pricing Grid Type'),
        marginOrFee: isNotEmpty(' Choose Margin, Fee or Both'),
      },
    },
  })


  const selectedOptions = form.values.pricingGrid.map((grid: { pricingGridType: string }) => grid.pricingGridType).filter((type: string) => type);

  return (
    <Box>
      {form.values.pricingGrid.map((_: DealPricingGrid, index: number) => (
        <><PricingGridEntry
          key={index}
          index={index}
          form={form}
          selectedOptions={selectedOptions} // Pass down the selected options
        /><Grid.Col span={12}>
            <Group
              style={{
                backgroundColor: '#D1D5DB',
                color: '#D1D5DB',
                height: '5px',
                marginTop: '15px',
              }}
            ></Group>
          </Grid.Col></>

      ))}
      <AddGridButton
        onClick={() => form.insertListItem('pricingGrid', {
          pricingGridType: '',
          marginOrFee: '',
          levels: [''],
        })}
        pricingGrid={form.values.pricingGrid.length} //
      />
    </Box >
  )
}
export default React.forwardRef(DealPricingGridComponent)