import React, { useEffect, useState } from 'react'
import {
  Checkbox,
  Grid,
  Group,
  Menu,
  ScrollArea,
  TextInput,
} from '@mantine/core'
import File_Card from '../../Cards/file-card'
import { useSelector } from 'react-redux'
import { getDocumentsForType } from 'app/state/ducks/documents/selectors'
// import PrimaryButton from '../../buttons/PrimaryButton'
import {
  DealDocumentTags,
  EntityDocumentTags,
} from 'app/models/dropdown-options'
import { useListState } from '@mantine/hooks'
import { ContractType } from 'app/models/common-types'
import IonIcon from '@reacticons/ionicons'

interface Props {
  type: ContractType
  openRef: React.RefObject<() => void>
}

export function DropzoneAttachments({ type }: Props): JSX.Element {
  const documents = useSelector(getDocumentsForType(type))

  const posibleTags =
    type == ContractType.Entities ? EntityDocumentTags : DealDocumentTags
  const initCheckboxItem = posibleTags
    .map(option => ({ label: option.label, key: option.value, checked: false }))
    .concat([{ key: '', label: 'Untagged', checked: false }])

  const [search, setSearch] = useState('')
  const [checkBoxItems, checkBoxItemHandler] = useListState(initCheckboxItem)
  const [filteredDocuments, setFilteredDocuments] = useState(documents)

  const allUnchecked = checkBoxItems.every(item => !item.checked)

  useEffect(() => {
    setFilteredDocuments(documents)
    checkBoxItemHandler.setState(() => initCheckboxItem)
    setSearch('')
  }, [documents])

  const onCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    checkBoxItems[index].checked = event.currentTarget.checked
    checkBoxItemHandler.setState(checkBoxItems)
    filterByType()
  }

  const showAll = () => {
    checkBoxItemHandler.setState(initCheckboxItem)
    setFilteredDocuments(documents)
    setSearch('')
  }

  const filterByType = () => {
    const checkedTagValue = checkBoxItems
      .filter(item => item.checked)
      .map(item => item.key)
    if (checkedTagValue.length == 0) {
      return setFilteredDocuments(documents)
    }
    setFilteredDocuments(
      (documents ?? []).filter(document => checkedTagValue.includes(document.tag ?? ''))
    )
  }

  const items = checkBoxItems.map((value, index) => (
    <Checkbox
      mt="xs"
      label={value.label}
      key={value.key}
      checked={value.checked}
      onChange={event => onCheckboxChange(event, index)}
    />
  ))

  return (
    <>
      <Group noWrap w='100%' mb="10px" mt="5px" onClick={e => e.stopPropagation()}>
        <TextInput
          w="100%"
          rightSection={<IonIcon size='large' name='search-outline' aria-disabled />}
          size="lg"
          value={search}
          onChange={e => {
            setSearch(e.target.value)
          }}
        />
        <Menu>
          <Menu.Target>
            <div>
              <IonIcon size="large" name={allUnchecked ? "funnel-outline" : "funnel"} style={{ color: "primary-blue" }} />
            </div>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item onClick={showAll} closeMenuOnClick={false}>
              Show All
            </Menu.Item>
            {items}
          </Menu.Dropdown>
        </Menu>
        {/* commented out because we have massive security concerns */}
        {/* <PrimaryButton
          text="Add Attachment"
          onClick={() => openRef.current && openRef.current()}
        /> */}
      </Group>
      <ScrollArea h={280} w="100%">
        <Grid columns={2}>
          {filteredDocuments &&
            filteredDocuments
              .filter(document =>
                document?.name
                  ?.toLowerCase()
                  .includes(search.trim().toLocaleLowerCase())
              )
              .map((file, index) => <File_Card key={index} file={file} />)}
        </Grid>
      </ScrollArea>
    </>
  )
}