import { LoanParams } from 'app/models/loan-params'
import {
    Group,
    NumberInput,
    Select,
    SelectItem,
    Stack,
    Text,
} from '@mantine/core'
import CustomDatePicker from 'app/views/components/date-picker/date-picker-logic'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import {
    currencyOptions,
    dayBasisOptions,
    roundingOptions,
} from 'app/models/dropdown-options'
import FormWrapperWithSubtitle from '../common/form-wrapper-with-subtitle'
import {
    ErrorNotification,
    SuccessNotification,
} from 'app/views/components/notifications/notification'
import { useState } from 'react'
import { FacilityParams } from 'app/models/facility-params'
import { useDispatch } from 'react-redux'
import { LoanConversionParams } from 'app/models/loan-conversion-params'
import {
    Option,
} from 'app/models/dropdown-options'
import { formatNumberToCurrency, roundTo } from 'app/utils/util-functions'
import { submitForApproval } from 'app/state/ducks/loan-conversions/thunks'


type Props = {
    form: any
    close: any
    facility: FacilityParams | undefined
    loan: LoanParams
    conversion: LoanConversionParams | undefined
    facilityindexOptions: Option[]
    createDraftConversion: () => Promise<any>
    onInterestBaseRateChange: (value: number | '') => void
    changeContractPeriod: (event: string | null) => void
    changeIndexOption: (event: string | null) => void
    changeStartDate: (event: any) => void
    facilityContractPeriodOptions: SelectItem[]
    disableEndDate: boolean
}

export default function ConversionFormPresentation({
    form,
    close,
    facility,
    loan,
    conversion,
    facilityindexOptions,
    facilityContractPeriodOptions,
    disableEndDate,
    createDraftConversion,
    onInterestBaseRateChange,
    changeContractPeriod,
    changeIndexOption,
    changeStartDate,

}: Props) {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)

    const onSubmit = async () => {
        setIsLoading(true)
        const result = await createDraftConversion()
        setIsLoading(false)
        if (result.success) {
            close()
        }
    }

    const saveAndSubmit = async () => {
        setIsLoading(true)
        const response: any = await createDraftConversion()
        try {
            if (!response) {
                return
            }
            const result: any = await dispatch(
                submitForApproval(response.payload.data)
            )
            if (result.success) {
                SuccessNotification({
                    title: 'Successful Conversion Submitted',
                    message: 'loan conversion submitted successfully',
                })
            } else {
                ErrorNotification({
                    title: 'Conversion created but not submitted.',
                    message: result.payload ?? 'Could not submit Loan Conversion',
                })
            }
        } finally {
            close()
            setIsLoading(false)
        }
    }

    return (
        <FormWrapperWithSubtitle
            title={
                (conversion ? 'Edit' : 'Create') +
                ' Conversion' +
                (facility?.pikOption !== 'No_PIK' ? ' w/PIK' : '')
            }
            subtitle={
                'Existing Loan Amount: ' + formatNumberToCurrency(Number(loan?.amount ?? 0.0) ?? 0, loan?.currency ?? 'USD')
            }
        >
            <div className="content">
                <form onSubmit={form.onSubmit(() => onSubmit())}>
                    <div className="create-new">
                        <Stack spacing="xl">
                            {conversion && conversion?.status !== 'Draft' ? (
                                <Text className="topFormErrorText">
                                    Conversion has already been submitted
                                </Text>
                            ) : null}
                            <Group noWrap>
                                <Select
                                    searchable
                                    withAsterisk
                                    label="New Index Rate"
                                    placeholder="Select Index Rate"
                                    w="100%"
                                    data={facilityindexOptions}
                                    {...form.getInputProps('indexOption')}
                                    onChange={e => {
                                        changeIndexOption(e)
                                    }}
                                />
                                <Select
                                    searchable
                                    withAsterisk
                                    readOnly
                                    data={currencyOptions}
                                    label="Select Currency"
                                    placeholder="Currency"
                                    w="100%"
                                    {...form.getInputProps('currency')}
                                />

                                <NumberInput
                                    withAsterisk
                                    precision={2}
                                    w="100%"
                                    label="Amount"
                                    id="amount"
                                    placeholder="Enter Amount"
                                    parser={value =>
                                        value ? value.replace(/\$\s?|(,*)/g, '') : ''
                                    }
                                    formatter={value =>
                                        !Number.isNaN(parseFloat(value ?? ''))
                                            ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                            : ''
                                    }
                                    {...form.getInputProps('amount')}
                                />
                            </Group>

                            <Stack>
                                {!disableEndDate && (
                                    <Select
                                        disabled={disableEndDate}
                                        searchable
                                        withAsterisk
                                        label="Contract Period"
                                        placeholder="Select Contract Period"
                                        w="100%"
                                        data={facilityContractPeriodOptions}
                                        {...form.getInputProps('contractPeriod')}
                                        onChange={e => {
                                            changeContractPeriod(e)
                                        }}
                                    />
                                )}
                                <Group noWrap>
                                    <CustomDatePicker
                                        required
                                        label={'Conversion Start Date'}
                                        date={form.values.startDate}
                                        setDate={(value: any) => {
                                            form.setFieldValue('startDate', value)
                                            changeStartDate(value)
                                        }}
                                        holidayCalendars={facility?.holidayCalendar ?? []}
                                    />

                                    <CustomDatePicker
                                        label={'Maturity Date'}
                                        disabled={disableEndDate}
                                        date={form.values.endDate}
                                        setDate={(value: any) =>
                                            form.setFieldValue('endDate', value)
                                        }
                                        holidayCalendars={facility?.holidayCalendar ?? []}
                                    />

                                    <Select
                                        searchable
                                        readOnly
                                        data={dayBasisOptions}
                                        label="Day basis"
                                        w="100%"
                                        {...form.getInputProps('dayBasis')}
                                    />
                                </Group>
                                {(form.isTouched('startDate') || form.isTouched('maturity')) &&
                                    form.values.maturity &&
                                    new Date(form.values.startDate) >=
                                    new Date(form.values.maturity) ? (
                                    <div className="topFormErrorText">
                                        Start Date Must Be Before Maturity Date
                                    </div>
                                ) : null}
                            </Stack>

                            <Group noWrap>
                                <NumberInput
                                    w="100%"
                                    precision={5}
                                    label="Interest Rate "
                                    parser={value =>
                                        value ? value.replace(/%\s?|\$\s?|(,*)/g, '') : ''
                                    }
                                    formatter={value =>
                                        !Number.isNaN(parseFloat(value ?? '')) ? value + ' %' : '%'
                                    }
                                    {...form.getInputProps('interestBaseRate')}
                                    onChange={onInterestBaseRateChange}
                                />

                                <NumberInput
                                    readOnly
                                    w="100%"
                                    precision={5}
                                    label="Interest Rate with Rounding"
                                    parser={value =>
                                        value ? value.replace(/%\s?|\$\s?|(,*)/g, '') : ''
                                    }
                                    formatter={value =>
                                        !Number.isNaN(parseFloat(value ?? '')) ? value + ' %' : '%'
                                    }
                                    {...form.getInputProps('interestBaseRateWithRounding')}
                                />

                                <Select
                                    searchable
                                    data={roundingOptions}
                                    readOnly
                                    label="Interest Base Rate Rounding"
                                    w="100%"
                                    {...form.getInputProps('rounding')}
                                />
                            </Group>

                            <Group noWrap>
                                <NumberInput
                                    w="100%"
                                    readOnly
                                    precision={5}
                                    label="Margin"
                                    parser={value =>
                                        value ? value.replace(/%\s?|\$\s?|(,*)/g, '') : ''
                                    }
                                    formatter={value =>
                                        !Number.isNaN(parseFloat(value ?? '')) ? value + ' %' : '%'
                                    }
                                    {...form.getInputProps('margin')}
                                />

                                {form.values.casValue !== undefined && form.values.casValue !== 0 && (
                                    <NumberInput
                                        readOnly
                                        w="100%"
                                        precision={5}
                                        label="CAS Rate"
                                        parser={value =>
                                            value ? value.replace(/%\s?|\$\s?|(,*)/g, '') : ''
                                        }
                                        formatter={value =>
                                            !Number.isNaN(parseFloat(value ?? '')) ? roundTo(Number(value ?? ''), form?.values?.rounding ?? 'NoRounding') + ' %' : '%'
                                        }
                                        {...form.getInputProps('casValue')}
                                    />
                                )}

                                <NumberInput
                                    w="100%"
                                    precision={5}
                                    label="All In Rate"
                                    parser={value =>
                                        value ? value.replace(/%\s?|\$\s?|(,*)/g, '') : ''
                                    }
                                    formatter={value =>
                                        !Number.isNaN(parseFloat(value ?? '')) ? roundTo(Number(value ?? ''), form?.values?.rounding ?? 'NoRounding') + ' %' : '%'
                                    }
                                    {...form.getInputProps('allInRate')}
                                />
                            </Group>
                            <Group noWrap>
                                <PrimaryButton
                                    loading={isLoading}
                                    type="submit"
                                    disabled={!form.isValid()}
                                    text="Save"
                                    w="100%"
                                />
                                <PrimaryButton
                                    loading={isLoading}
                                    onClick={() => saveAndSubmit()}
                                    disabled={!form.isValid()}
                                    text="Create and Submit"
                                    w="100%"
                                />
                            </Group>
                        </Stack>
                    </div>
                </form>
            </div>
        </FormWrapperWithSubtitle>
    )
}