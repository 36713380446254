import PrimaryInput from 'app/views/components/buttons/PrimaryInput'
import { Group } from '@mantine/core'

export default function CreatePaymentButton() {
    return (
        <>
            <Group>
                <PrimaryInput
                    text='Create Payment'
                    onClick={() => console.log(true)}
                />
            </Group>
        </>
    )
}