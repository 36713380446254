import { ContactParams } from 'app/models/contact-params'
import { ContactsState } from './reducer'

export const getContacts = (state: {
  contacts: { contacts: ContactParams[] }
}) => {
  return state.contacts.contacts
}

export const getContact = (state: { contacts: { contact: ContactParams } }) => {
  return state.contacts.contact
}

export const getContactsLoader = (state: { contacts: ContactsState }) => {
  return state.contacts.isLoading
}
