import React, { useEffect, useRef, useState } from 'react'
import { Accordion, Flex, useMantineTheme } from '@mantine/core'
import {
  Dropzone,
  DropzoneProps,
  MIME_TYPES,
  MS_EXCEL_MIME_TYPE,
  MS_WORD_MIME_TYPE,
  PDF_MIME_TYPE,
} from '@mantine/dropzone'
import { useDispatch, useSelector } from 'react-redux'
import {
  loadByContract,
  // uploadDocumentToS3,
} from 'app/state/ducks/documents/thunks'
import { getDocumentsForType } from 'app/state/ducks/documents/selectors'
import { DropzoneAttachments } from './dropzone-attachment'
import { ContractType } from 'app/models/common-types'
import IonIcon from '@reacticons/ionicons'

interface Props {
  id: string
  type: ContractType
}

export function AttachmentsAccordion({
  id,
  type,
  ...props
}: Props & Partial<DropzoneProps>): JSX.Element {
  const EXPECTED_MIME_TYPES: string[] = ([MIME_TYPES.csv] as string[]).concat(
    PDF_MIME_TYPE,
    MS_WORD_MIME_TYPE,
    MS_EXCEL_MIME_TYPE
  )
  const documents = useSelector(getDocumentsForType(type))
  const theme = useMantineTheme()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const openRef = useRef<() => void>(null)

  const handleDrop = async (acceptedFiles: File[]) => {
    try {
      console.log('acceptedFiles', acceptedFiles)
      setLoading(true)
      // commented out because we have massive security concerns
      // await Promise.all(
      //   acceptedFiles.map(file => dispatch(uploadDocumentToS3(file, id, type)))
      // )
    } catch (error) {
      console.error('Error uploading files:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id !== '')
      dispatch(loadByContract(id, type))
  }, [id, type])

  return (
    <Accordion.Item value="pdfs">
      <Accordion.Control fw={600} fz="14px" p="lg">
        Attachments ({documents?.length ?? 0})
      </Accordion.Control>
      <Accordion.Panel>
        <Dropzone
          openRef={openRef}
          activateOnClick={false}
          onClick={e => e.stopPropagation()}
          loading={loading}
          onDrop={files => {
            handleDrop(files)
          }}
          onReject={files => console.log('rejected files', files)}
          maxSize={10 * 1024 ** 2}
          accept={EXPECTED_MIME_TYPES}
          {...props}
        >
          <Dropzone.Accept>
            <Flex direction="row" justify="center" align="center">
              <IonIcon name="cloud-upload-outline"
                style={{ color: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6], height: "3.2rem", width: "3.2rem" }}
              />
            </Flex>
          </Dropzone.Accept>
          <Dropzone.Reject>
            <Flex direction="row" justify="center" align="center">
              <IonIcon name="close-outline"
                style={{ color: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6], height: "3.2rem", width: "3.2rem" }}
              />
            </Flex>
          </Dropzone.Reject>
          <Dropzone.Idle>
            <DropzoneAttachments type={type} openRef={openRef} />
          </Dropzone.Idle>
        </Dropzone>
      </Accordion.Panel>
    </Accordion.Item>
  )
}
