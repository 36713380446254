import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import {
  getFeesByFacility,
  editFees,
  changeFeesForFacility,
  getScheduler,
  getAllFees,
  startLoadingAllFees,
  stopLoadingAllFees,
} from './actions'
import { ChangeFeeData, Fees } from 'app/models/fees-params'
import { StableKey } from 'app/models/common-types'

export const updateFees =
  (savedFees: Fees, fee: boolean) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl

    if (!fee) {
      return null
    }
    return client
      .put(`${apiServerUrl}/api/fees`, savedFees)
      .then(response => {
        return dispatch(editFees({ ...response.data }))
      })
      .catch(error => {
        console.log(error)
      })
  }

export const changeAllFeesForFacility =
  (fees: ChangeFeeData[], facilityId: StableKey) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/fees/facility`, {
        feesData: fees.map(fee => ({
          feeType: fee.feeType,
          feeRate: fee.feeRate,
          frequency: fee.frequency,
          dayBasis: fee.dayBasis,
          firstPaymentDate: fee.firstPaymentDate,
          oneTimeFeeAmount: fee.oneTimeFeeAmount,
          id: fee.id,
        })),
        facilityId: facilityId,
      })
      .then((response: any) => {
        dispatch(changeFeesForFacility(response.data))
        return { payload: response.data, success: true }
      })
      .catch(e => {
        return { payload: e.response.data.error, success: false }
      })
  }

export const loadFeesByFacility =
  (id: string) => async (dispatch: Dispatch) => {
    if (!id) {
      return []
    }
    const apiServerUrl = config.backendUrl
    return client
      .get(`${apiServerUrl}/api/fees/facility?id=${id}`)
      .then(fees => {
        return dispatch(
          getFeesByFacility(
            fees.data.map((lp: any) => ({ ...lp, status: 'Approved' })),
          ),
        )
      })
      .catch(e => {
        console.log(e)
        return []
      })
  }

export const loadAllFees = () => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  try {
    dispatch(startLoadingAllFees())
  return await client
    .get(`${apiServerUrl}/api/fees`)
    .then(response => {
      return dispatch(getAllFees(response.data))
    })
    .catch(e => {
      console.log(e)
      return []
    })
  } finally {
    dispatch(stopLoadingAllFees())
  }
}

export const calculateFeeScheduler =
  (id: string, facilityId: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .get(
        `${apiServerUrl}/api/fees/scheduler?id=${id}&facilityId=${facilityId}`,
      )
      .then(response => {
        dispatch(getScheduler(response.data))
        return { payload: response.data, success: true }
      })
      .catch(e => {
        console.log(e)
        dispatch(getScheduler([]))
        return { payload: e.response.data.error, success: false }
      })
  }

export const getNextFeeAmount = (
  feeId: string,
  facilityId: string,
  effectiveDate: string,
) => {
  const apiServerUrl = config.backendUrl
  return client
    .get(
      `${apiServerUrl}/api/fees/next-fee-amount?id=${feeId}&facilityId=${facilityId}&effectiveDate=${effectiveDate}`,
    )
    .then(response => {
      console.log(response)
      return { payload: response.data, success: true }
    })
    .catch(e => {
      console.log(e)
      return { payload: e.response.data.error, success: false }
    })
}

export const getNextFeeAmountBulk = (fees: Fees[]) => {
  const apiServerUrl = config.backendUrl
  return client
    .post(`${apiServerUrl}/api/fees/next-fee-amount-bulk`, {
      fees: fees.map(fee => ({
        feeType: fee.feeType,
        feeRate: fee.feeRate,
        frequency: fee.frequency,
        dayBasis: fee.dayBasis,
        firstPaymentDate: fee.firstPaymentDate,
        id: fee.id,
        facilityId: fee.facilityId,
      })),
    })
    .then((response: any) => {
      return { payload: response.data, success: true }
    })
    .catch(e => {
      return { payload: e.response.data.error, success: false }
    })
}
