import { Button } from '@mantine/core'
import { ReactNode, forwardRef } from 'react'
import IonIcon from '@reacticons/ionicons'

interface Props {
    children?: ReactNode
    minWidth?: number;
    img?: boolean
    onClick?: () => void;
}


const JobButton = forwardRef<HTMLButtonElement, Props>(
    ({ children, minWidth, img = true, ...others }: Props, ref) => (
        < Button
            ref={ref}
            style={{
                fontFamily: 'Plus Jakarta Sans',
                color: '#fff',
                backgroundColor: '#32439A',
                borderRadius: 4,
                height: 34,
                fontSize: 14,
                minWidth: minWidth ? minWidth : 75,
            }}
            {...others}
        >
            {children}
            {img && <IonIcon name='chevron-down-outline' style={{ marginLeft: '10px' }} />} { }
        </Button >
    )
)

export default JobButton
