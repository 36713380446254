import * as types from './types'

export const uploadDocument = (payload: any) => ({
  type: types.UPLOAD_DOCUMENT,
  payload,
})

export const getDocumentByContract = (payload: any) => ({
  type: types.GET_DOCUMENT_BY_CONTRACT,
  payload,
})
export const editDocumentTag = (payload: any) => ({
  type: types.EDIT_DOCUMENT_TAG,
  payload,
})

export const removeDocument = (payload: any) => ({
  type: types.REMOVE_DOCUMENT,
  payload,
})
