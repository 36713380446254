import * as types from './types'
import { createReducer } from 'app/state/utils'

const initialState: never[] = []

const jobsReducer = createReducer(initialState)({
  [types.GET_JOBS]: (state: any, payload: any) => {
    return { ...state, jobs: payload.payload }
  },
  [types.GET_JOB]: (state: any, payload: any) => {
    return { ...state, job: payload.payload }
  },
  [types.ADD_JOBS]: (state: any, payload: any) => {
    return {
      ...state,
      jobs: [payload.payload, ...state.jobs],
    }
  },
  [types.EDIT_JOBS]: (state: any, payload: any) => {
    const index = state.jobs.findIndex(
      (job: { id: any }) => job.id === payload.payload.id
    )
    const newArray = [...state.jobs]
    newArray[index] = { ...payload.payload }
    return {
      ...state,
      jobs: newArray,
      job: { ...payload.payload },
    }
  },
  [types.DELETE_JOBS]: (state: any, payload: any) => {
    const filteredJobs = state.jobs.filter((job: any) => {
      return job.id !== payload.payload[0].id
    })

    return {
      ...state,
      jobs: filteredJobs,
    }
  },
})

export default jobsReducer
