import React, { useState } from 'react';
import { useForm } from '@mantine/form';
import { useDispatch } from 'react-redux';
import { registerUser } from 'app/state/ducks/signup/thunks';
import { useAuth0 } from '@auth0/auth0-react';
import { Modal as SimpleModal } from 'app/views/components/modals/modal-small';
import config from 'app/config/config';

const SignupForm = () => {
  const dispatch = useDispatch();
  const [signupSuccess, setSignUpSuccess] = useState(false);
  const [signupFail, setSignUpFail] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { loginWithRedirect } = useAuth0();

  const passwordRules =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

  const form = useForm({
    initialValues: {
      email: '',
      username: '',
      password: '',
    },

    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value) ? null : 'Please enter a valid email',
      username: (value) => {
        if (value.length < 5) return 'Username must have a minimum of 5 characters';
        if (value.length > 15) return 'Username must have a maximum of 15 characters';
        return null;
      },
      password: (value) => {
        if (value.length < 8) return 'Password must be at least 8 characters long';
        if (!passwordRules.test(value)) {
          return 'Password must be a combination of lower case, upper case, numbers and special characters (ex: !@#$%^&*)';
        }
        return null;
      },
    },
  });

  const onSubmit = async (values: typeof form.values) => {
    setIsSubmitting(true);
    try {
      const register_params = {
        email: values.email,
        username: values.username,
        password: values.password,
        hasError: false,
        hasSuccessfulRegistration: false,
        ErrorMessage: '',
        customer_token: config.company.toUpperCase(),
      };
      await dispatch(registerUser(register_params));
      setSignUpSuccess(true);
      setSignUpFail(false);
      form.reset();
    } catch {
      setSignUpFail(true);
      setSignUpSuccess(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: '/profile',
      },
    });
  };

  const RegistrationErrorModal: React.FC<{ modal: typeof SimpleModal }> = ({
    modal,
  }) => {
    return (
      <modal.Frame
        open={signupSuccess || signupFail}
        onClose={() => {
          setSignUpSuccess(false);
          setSignUpFail(false);
        }}
      >
        {signupSuccess ? (
          <modal.Head>User Registration Successful</modal.Head>
        ) : (
          <modal.Head>Oops! Something went wrong</modal.Head>
        )}
        <modal.Body>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
            }}
          >
            {signupSuccess ? (
              <p>
                User Registration was successful. Please check your email to
                verify user.
              </p>
            ) : (
              <p>
                A user with this username and/or email exists. Please try again
                with an alternate username and/or email.
              </p>
            )}
          </div>
        </modal.Body>
      </modal.Frame>
    );
  };

  const basicInputClass =
    'form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none';
  const errorClass =
    'form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-red-700 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none';
  const buttonDisabledClass = {
    color: '#636c82',
    fontWeight: 'bold',
    backgroundImage:
      'linear-gradient(90deg, hsla(163, 79%, 76%, 1) 0%, hsla(165, 79%, 48%, 1) 100%)',
    opacity: 0.35,
    fontSize: '12px',
  };
  const buttonEnabledClass = { fontSize: '12px' };

  return (
    <div>
      <form onSubmit={form.onSubmit((values) => onSubmit(values))} autoComplete="off">
        <h2 className="mb-4">Create your account</h2>
        <div className="mb-4">
          <input
            type="text"
            className={form.errors.username ? errorClass : basicInputClass}
            id="username"
            name="username"
            placeholder="Username"
            value={form.values.username}
            onChange={(event) => form.setFieldValue('username', event.currentTarget.value)}
            onBlur={() => form.validateField('username')}
          />
          {form.errors.username && (
            <p style={{ color: '#ff0000', fontSize: 14, opacity: 1 }}>
              {form.errors.username}
            </p>
          )}
        </div>

        <div style={{ marginBottom: '1rem' }}>
          <input
            type="email"
            id="email"
            name="email"
            className={form.errors.email ? errorClass : basicInputClass}
            placeholder="Email"
            value={form.values.email}
            onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
            onBlur={() => form.validateField('email')}
          />
          {form.errors.email && (
            <p style={{ color: '#ff0000', fontSize: 14, opacity: 1 }}>
              {form.errors.email}
            </p>
          )}
        </div>

        <div style={{ marginBottom: '1rem' }}>
          <input
            type="password"
            className={form.errors.password ? errorClass : basicInputClass}
            id="password"
            name="password"
            placeholder="Password"
            value={form.values.password}
            onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
            onBlur={() => form.validateField('password')}
          />
          {form.errors.password && (
            <p style={{ color: '#ff0000', fontSize: 14, opacity: 1 }}>
              {form.errors.password}
            </p>
          )}
        </div>

        <div
          style={{
            textAlign: 'center',
            paddingTop: '0.25rem',
            marginBottom: '3rem',
            paddingBottom: '0.25rem',
          }}
        >
          <button
            type="submit"
            className="registerBtn"
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            style={isSubmitting ? buttonDisabledClass : buttonEnabledClass}
            disabled={isSubmitting}
          >
            Register
          </button>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingBottom: '1.5rem',
          }}
        >
          <h2 style={{ marginBottom: 0, marginRight: '0.5rem' }}>
            Already have an account?
          </h2>
          <button
            className="loginBtn"
            onClick={handleLogin}
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
          >
            Log In
          </button>
        </div>
      </form>
      <RegistrationErrorModal modal={SimpleModal} />
    </div>
  );
};

export default SignupForm;
