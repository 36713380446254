import { Menu } from '@mantine/core'
import PrimaryDropDown from 'app/views/components/DropDown/DropDown'
import { useDispatch } from 'react-redux'
import {
  approveAssignment,
  deleteAssignment,
  rejectAssignment,
  submitAssignment,
} from './trade-closing-daml-actions'
import { useNavigate } from 'react-router-dom'
import IonIcon from '@reacticons/ionicons'

interface actionParam {
  row: any
}

export default function TradeClosingActions({ row }: actionParam) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  function editTradeClosing(tradeId: string, status: string) {
    navigate(`/tradeclosing/assignment/` + status + `/` + tradeId + ``)
  }

  return (
    <div>
      <Menu shadow="md" width={200} position="bottom" withinPortal>
        <Menu.Target>
          <div style={{ width: 'fit-content' }}>
            <PrimaryDropDown>Actions</PrimaryDropDown>
          </div>
        </Menu.Target>

        <Menu.Dropdown>
          {row?.status?.toLowerCase() == 'draft' ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
              onClick={() => editTradeClosing(row.id, row.status)}
            >
              Edit
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'draft' ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => {
                submitAssignment(row, dispatch)
              }}
            >
              Submit For Approval
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'submitted' ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => {
                approveAssignment(row, dispatch)
              }}
            >
              Approve
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() != 'approved' ? (
            <>
              <Menu.Divider />
              <Menu.Label>Danger zone</Menu.Label>
            </>
          ) : null}

          {row?.status?.toLowerCase() == 'draft' ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name='trash-outline' />}
              onClick={() => {
                deleteAssignment(row, dispatch)
              }}
            >
              Delete
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'submitted' ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => {
                rejectAssignment(row, dispatch)
              }}
            >
              Reject
            </Menu.Item>
          ) : null}
        </Menu.Dropdown>
      </Menu>
    </div>
  )
}
