import {
  Group,
  Stack,
  TextInput,
  Select,
  NumberInput,
  SelectItem,
  Text,
} from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { DealParams } from 'app/models/deal-params'
import {
  Option,
  currencyOptions,
  dayBasisOptions,
  roundingOptions,
  rateTypeOptions,
  strBooleanOptions,
} from 'app/models/dropdown-options'
import {
  ContractPeriod,
  FacilityParams,
  IroValues,
} from 'app/models/facility-params'
import CustomDatePicker from 'app/views/components/date-picker/date-picker-logic'
import { forwardRef, useEffect, useState, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getWireInstructions } from 'app/state/ducks/wire-instructions/selectors'
import {
  roundTo, stringToDate
} from 'app/utils/util-functions'
import { DraftLoanParams, LoanParams } from 'app/models/loan-params'
import { saveLoan } from 'app/state/ducks/loans/thunks'
import cleanUUID from 'app/views/components/functions/cleanUUID'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { getDeals } from 'app/state/ducks/deals/selectors'
import FormWrapper from 'app/views/components/Form/FormWrapper'
import axios from 'axios'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'
import { getIndexRateOptions } from 'app/state/ducks/index-rate-options/selectors'
import { getInterestRatess } from 'app/state/ducks/interest-rates/selectors'
import { loadInterestByIndex } from 'app/state/ducks/interest-rates/thunks'
import { getLoansByFacility } from 'app/state/ducks/loans/selectors'
import { getLetterOfCredits } from 'app/state/ducks/letters-of-credits/selectors'
import { resetInterest } from './utilities/reset-interest';
import { calculateTermInterests } from './utilities/calculate-term-interest'
import { calculateNonTermInterests } from './utilities/calculate-non-term-interest'
import { changeContractPeriod } from './utilities/change-contract-period'

export interface Props {
  form: UseFormReturnType<any, (values: any) => any>
  facility?: FacilityParams
  loan?: LoanParams
  setSaveButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>
  setFacility?: React.Dispatch<React.SetStateAction<any>>
  setLoan: React.Dispatch<React.SetStateAction<any>>
}

export interface ChildLoanRef {
  handleClick: () => void
}

const UpdatedLoanModal: React.ForwardRefRenderFunction<ChildLoanRef, Props> = ({
  form,
  loan,
  facility,
  setSaveButtonDisabled,
  setLoan,
}, ref) => {
  const dispatch = useDispatch()
  const entities = useSelector(getEntities)
  const deals = useSelector(getDeals)
  const wireInstructionList = useSelector(getWireInstructions)
  const allIndexes = useSelector(getIndexRateOptions)
  const dailyRates = useSelector(getInterestRatess)
  const { data: loans } = useSelector(getLoansByFacility)
  const lettersOfCredits = useSelector(getLetterOfCredits)
  const loansAmount = loans.filter(loan => loan.status == 'Approved').map(loan => loan.amount).reduce((a, b) => Number(a) + Number(b), 0)
  const locsAmount = lettersOfCredits.filter(loc => loc.status == 'Approved').map(loc => loc.amount).reduce((a, b) => Number(a) + Number(b), 0)
  const totalLoanLocsAmount = Number(loansAmount) + Number(locsAmount)
  const [deal, setDeal] = useState<DealParams>()
  const [iro, setIro] = useState<IroValues>()
  const [contractPeriod, setContractPeriod] = useState<ContractPeriod>()
  const [entityOptions, setEntityOptions] = useState<Option[]>([])
  const [availableCurrecncies, setAvailableCurrecncies] = useState<Option[]>([])
  const [facilityContractPeriodOptions, setFacilityContractPeriodOptions] =

    useState<SelectItem[]>([])
  const [wireInstructionsOption, setWireInstructionOptions] = useState<
    Option[]
  >([])
  const [, setIsLoading] = useState(false)
  const [facilityindexOptions, setFacilityIndexOptions] = useState<Option[]>([])
  const [disableEndDate, setDisableEndDate] = useState(false)

  useEffect(() => {
    if (!allIndexes || allIndexes.length === 0 || !facility) {
      return
    }

    const facilityIndexes = [...new Set(facility?.iroValues.map(iro => { return allIndexes.find(option => option.id === iro.indexOption.id) }))]

    setFacilityIndexOptions(
      facilityIndexes.map(indexOption => ({
        value: indexOption?.id ?? '',
        label: indexOption?.indexOption ?? '',
      })) ?? []
    )
    const selectedIndex = allIndexes.find(
      index => index.id === form.values.indexOption
    )
    if (selectedIndex) {
      dispatch(loadInterestByIndex(selectedIndex))
    }
  }, [allIndexes, facility])

  // set entity options
  useEffect(() => {
    if (!facility || !entities) {
      return
    }
    setEntityOptions(
      facility.borrowers
        ? facility.borrowers.map(borrower => {
          const entity = entities.find(entity => entity.id === borrower.id)
          return {
            label: entity?.entityName ?? '',
            value: borrower.id,
          }
        })
        : []
    )

    if (!facility.isSublimit) {
      return
    }

    const swingline = facility.subLimits.find(
      sublimit => sublimit.subLimitsType === 'SwingLine'
    )

    if (!swingline) {
      return
    }
  }, [entities, loan, facility])

  // set Deal
  useEffect(() => {
    if (!deals) {
      return
    }
    setDeal(deals.find(deal => deal.id === facility?.dealId.id))
  }, [deals, loan])

  // set Available Currencies
  useEffect(() => {
    if (!facility) {
      return
    }
    setAvailableCurrecncies(
      currencyOptions.filter(option =>
        facility.currencies.includes(option.value)
      )
    )
  }, [facility])

  // set WireInstrutionOption on edit
  useEffect(() => {
    if (!loan) {
      return
    }
    setWireInstructionOptions(
      wireInstructionList
        .filter(
          wireInstruction =>
            cleanUUID(wireInstruction.entity.id) === form.values.borrower
        )
        .map(wireInstruction => ({
          label: `${wireInstruction.currency}_${wireInstruction.beneficiaryAccountNumber}`,
          value: wireInstruction.id ?? '',
        }))
    )
  }, [wireInstructionList, loan])

  // set contract period on edit
  useEffect(() => {
    if (!loan || !facility) {
      return
    }
    const indexRateOptions = facility.iroValues.find(
      iro =>
        iro.indexOption.id === form.values.indexOption &&
        form.values.currency === iro.currency
    )

    setIro(indexRateOptions)
    if (!indexRateOptions) {
      return
    }

    const contractPeriods = indexRateOptions.contractPeriod.map(
      cp => cp.contractPeriod
    )
    const filteredContractPeriodOptions = rateTypeOptions.filter(option =>
      contractPeriods.includes(option.value)
    )
    setFacilityContractPeriodOptions(filteredContractPeriodOptions)

    const selectedContractPeriod = indexRateOptions.contractPeriod.find(
      cp => cp.contractPeriod === form.values.contractPeriod
    )
    setContractPeriod(selectedContractPeriod)
  }, [facility, loan])

  const handleClick = async () => {
    await onSubmit()
  }

  useImperativeHandle(ref, () => ({
    handleClick,
  }))

  // set interest on edit
  useEffect(() => {
    if (!dailyRates || loan) {
      return
    }
    if (allIndexes.find(index => index.id === form.values.indexOption)?.indexType !== 'TermIndex') {
      calculateNonTermInterests(form, loan, dailyRates, allIndexes, iro, form.values?.startDate);
    }
  }, [dailyRates])

  useEffect(() => {
    setSaveButtonDisabled(!form.isValid())
  }, [form.isValid()])

  useEffect(() => {
    if (!allIndexes || allIndexes.length === 0 || !loan) {
      return
    }

    const selectedIndex = allIndexes.find(
      index => index.id === loan.indexOption.id
    )
    if (selectedIndex?.indexType === 'TermIndex') {
      setDisableEndDate(false)
    } else {
      setDisableEndDate(true)
    }


  }, [allIndexes, loan])

  const changeIndexOption = (event: string | null) => {
    if (!event) {
      return
    }
    const selectedIndex = allIndexes.find(index => index.id === event)
    if (selectedIndex?.indexType === 'TermIndex') {
      setDisableEndDate(false)
    } else {
      setDisableEndDate(true)
    }

    if (selectedIndex) {
      dispatch(loadInterestByIndex(selectedIndex))
    }
    form.setFieldValue('indexOption', event)
    form.setFieldValue('casValue', 0)
    form.setFieldValue('allInRate', '')
    form.setFieldValue('contractPeriod', '')
    setContractPeriod(undefined)
    const filteredIros =
      facility?.iroValues.find(
        iro => iro.indexOption.id === event && form.values.currency === iro.currency
      ) ?? undefined
    if (!filteredIros) {
      form.setFieldValue('rounding', '')
      form.setFieldValue('margin', 0)
      form.setFieldValue('dayBasis', '')
      resetInterest(form, loan)
      return
    }
    form.setFieldValue('rounding', filteredIros.rounding)
    form.setFieldValue('margin', Number(filteredIros.margin))
    form.setFieldValue('dayBasis', filteredIros.dayBasis)
    setIro(filteredIros)

    const contractPeriods = filteredIros.contractPeriod.map(
      cp => cp.contractPeriod
    )
    const filteredContractPeriodOptions = rateTypeOptions.filter(option =>
      contractPeriods.includes(option.value)
    )
    if (selectedIndex?.indexType !== 'TermIndex') {
      form.setFieldValue('contractPeriod', null)
      form.setFieldValue('endDate', stringToDate((facility?.maturity ?? '')))
    } else {
      resetInterest(form, loan)
      setFacilityContractPeriodOptions(filteredContractPeriodOptions)
    }
  }

  const handleBorrowerChange = (event: string | null) => {
    if (!event) {
      return
    }
    form.setFieldValue('borrower', event)
    setWireInstructionOptions(
      wireInstructionList
        .filter(
          wireInstruction => cleanUUID(wireInstruction.entity.id) === event
        )
        .map(wireInstruction => ({
          label: `${wireInstruction.currency}_${wireInstruction.beneficiaryAccountNumber}`,
          value: wireInstruction.id ?? '',
        }))
    )
  }

  const createDraftLoan = async () => {
    try {
      const loanParams: DraftLoanParams = form.getTransformedValues()
      const response: any = await dispatch(saveLoan(loanParams, loan))
      if (response.success) {
        SuccessNotification({
          title: 'Successful Loan Created',
          message: 'Successful Loan Created',
        })
        setLoan(response.payload.data)
      } else {
        ErrorNotification({
          title: 'Failed to Create Loan',
          message: response.payload ?? 'Please check inputs or try again later',
        })
      }
      return response
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status == 400) {
        const data: any = e.response.data
        ErrorNotification({
          title: 'Failed to Create Loan',
          message: data?.error ?? 'Please check inputs',
        })
      } else {
        ErrorNotification({
          title: 'Failed to Create Loan',
          message: 'Please check inputs or try again later',
        })
      }
      return null
    }
  }

  const onSubmit = async () => {
    setIsLoading(true)
    await createDraftLoan()
    setIsLoading(false)
  }


  function onInterestBaseRateChange(value: number | ''): void {
    form.setFieldValue('interestBaseRate', value)
    if (iro) {
      const interestWithRounding = Number(
        roundTo(Number(value), iro.rounding)
      )
      form.setFieldValue('interestBaseRateWithRounding', Number(interestWithRounding))
    }
  }

  useEffect(() => {
    form.setFieldValue(
      'allInRate',
      Number(form.values.interestBaseRate || 0) +
      Number(form.values.margin || 0) +
      Number(form.values.casValue ?? 0)
    )
  }, [form.values.casValue, form.values.interestBaseRate, form.values.margin])

  return (
    <FormWrapper title={`${loan ? 'Edit' : 'Create'} Loan`}>
      <Stack>
        <Group noWrap>
          <TextInput
            readOnly
            label="Deal"
            w="100%"
            value={deal?.dealName ?? deal?.id ?? ''}
          ></TextInput>
          <TextInput
            readOnly
            label="Facility"
            w="100%"
            value={facility?.name ?? facility?.id ?? ''}
          ></TextInput>
        </Group>

        <Group noWrap>
          <CustomDatePicker
            label={'Start Date'}
            date={form.values.startDate}
            required
            setDate={(value: any) => {
              form.setFieldValue('startDate', value)
              if (allIndexes.find(index => index.id === form.values.indexOption)?.indexType !== 'TermIndex') {
                calculateNonTermInterests(form, loan, dailyRates, allIndexes, iro, value);
              } else {
                calculateTermInterests(form, loan, dailyRates, iro, contractPeriod, value);
              }
            }}
            holidayCalendars={deal?.holidayCalendar ?? []}
          />
          <CustomDatePicker
            disabled={disableEndDate}
            label={'Maturity Date'}
            date={form.values.endDate}
            setDate={(value: any) => form.setFieldValue('endDate', value)}
            holidayCalendars={deal?.holidayCalendar ?? []}
          />
        </Group>
        {facility && form.values.endDate && form.values.endDate > stringToDate(facility?.maturity) ?
          <Group noWrap>
            <Text w="100%"></Text>
            <Text w="100%" className='topFormErrorText'>Must be before Facility Maturity</Text>
          </Group>
          : null}
        <Group noWrap>
          <Select
            searchable
            clearable
            withAsterisk
            data={availableCurrecncies}
            label="Select currency"
            placeholder="Currency"
            w="100%"
            {...form.getInputProps('currency')}
          />
          <NumberInput
            withAsterisk
            w="100%"
            label="Amount"
            id="amount"
            placeholder="Enter Amount"
            parser={value =>
              value ? value.replace(/\$\s?|(,*)/g, '') : ''
            }
            formatter={value =>
              !Number.isNaN(parseFloat(value ?? ''))
                ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                : ''
            }
            {...form.getInputProps('amount')}
          />
        </Group>
        {facility && form.values.amount && Number(form.values.amount) <= facility?.amount && Number(form.values.amount) + totalLoanLocsAmount > facility?.amount ?
          <Group noWrap>
            <Text w="100%"></Text>
            <Text w="100%" className='warningText'>Warning: The creation of this Loan will exceed the Facility Availability</Text>
          </Group>
          : null}

        <Group noWrap>
          <Select
            searchable
            data={entityOptions ?? []}
            label="Borrower"
            w="100%"
            placeholder="Select borrower"
            onChange={event => {
              handleBorrowerChange(event)
            }}
            value={form.values.borrower}
          />
          <Select
            searchable
            data={wireInstructionsOption}
            label="Borrower Wire Instructions"
            placeholder="Select Wire Instructions"
            w="100%"
            {...form.getInputProps('borrowerPaymentInstructions')}
          />
        </Group>

        <Group noWrap>
          <Select
            clearable
            searchable
            w="100%"
            placeholder="Is it SwingLine"
            label="Swing Line (Y/N)"
            disabled={!facility?.isSublimit || form?.values?.isNonProRata === 'true' ? true : false}
            data={strBooleanOptions}
            {...form.getInputProps('isSwingLine')}
          />
          <Select
            clearable
            searchable
            w="100%"
            placeholder="Is it NonProRata"
            label="Non-Prorata (Y/N)"
            disabled={form?.values?.isSwingLine === 'true' ? true : false}
            data={strBooleanOptions}
            {...form.getInputProps('isNonProRata')}
          />
        </Group>

        <Group noWrap>
          <Select
            searchable
            label="Index Rate"
            placeholder="Select Index Rate"
            w="100%"
            data={[...facilityindexOptions]}
            {...form.getInputProps('indexOption')}
            onChange={e => {
              changeIndexOption(e)
            }}
          />
          <Select
            searchable
            disabled={form.values.indexOption.length === 0 || allIndexes.find(index => index.id === form.values.indexOption)?.indexType !== 'TermIndex'}
            label="Contract Period"
            placeholder="Select Contract Period"
            w="100%"
            data={facilityContractPeriodOptions}
            {...form.getInputProps('contractPeriod')}
            onChange={e => {
              changeContractPeriod(form, iro, loan, dailyRates, e, setContractPeriod, form.values?.startDate);
            }}
          />
        </Group>

        <Group noWrap>
          <Select
            searchable
            data={roundingOptions}
            readOnly
            label="Interest Base Rate Rounding"
            w="100%"
            {...form.getInputProps('rounding')}
          />
          <Select
            searchable
            readOnly
            data={dayBasisOptions}
            label="Day basis"
            w="100%"
            {...form.getInputProps('dayBasis')}
          />
        </Group>

        <Group noWrap>
          <NumberInput
            w="100%"
            precision={5}
            label="Interest Rate "
            parser={value =>
              value ? value.replace(/%\s?|\$\s?|(,*)/g, '') : ''
            }
            formatter={value =>
              !Number.isNaN(parseFloat(value ?? '')) ? value + ' %' : '%'
            }
            {...form.getInputProps('interestBaseRate')}
            onChange={onInterestBaseRateChange}
          />
          <NumberInput
            readOnly
            w="100%"
            precision={5}
            label="Interest Base Rate with Rounding"
            parser={value =>
              value ? value.replace(/%\s?|\$\s?|(,*)/g, '') : ''
            }
            formatter={value =>
              !Number.isNaN(parseFloat(value ?? '')) ? value + ' %' : '%'
            }
            {...form.getInputProps('interestBaseRateWithRounding')}
          />
        </Group>

        <Group noWrap>
          <NumberInput
            w="100%"
            precision={5}
            label="Margin"
            parser={value =>
              value ? value.replace(/%\s?|\$\s?|(,*)/g, '') : ''
            }
            formatter={value =>
              !Number.isNaN(parseFloat(value ?? '')) ? value + ' %' : '%'
            }
            {...form.getInputProps('margin')}
          />
          {(form.values.indexOption && allIndexes.find(index => index.id === form.values.indexOption)?.indexType === 'TermIndex') && (
            <NumberInput
              readOnly
              w="100%"
              precision={5}
              label="CAS Rate"
              placeholder='Select a Contract Period'
              parser={value =>
                value ? value.replace(/%\s?|\$\s?|(,*)/g, '') : ''
              }
              formatter={value =>
                !Number.isNaN(parseFloat(value ?? '')) && form.values.contractPeriod ? roundTo(Number(value ?? ''), form?.values?.rounding ?? 'NoRounding') + ' %' : ''
              }
              {...form.getInputProps('casValue')}
            />
          )}
          <NumberInput
            readOnly
            disabled={iro?.capFloorFlag && (Number(iro?.iroCap) < form?.values?.allInRate || Number(iro?.iroFloor) > form?.values?.allInRate)}
            w="100%"
            precision={5}
            label="All In Rate"
            parser={value =>
              value ? value.replace(/%\s?|\$\s?|(,*)/g, '') : ''
            }
            formatter={value =>
              !Number.isNaN(parseFloat(value ?? '')) ? roundTo(Number(value ?? ''), form?.values?.rounding ?? 'NoRounding') + ' %' : '%'
            }
            {...form.getInputProps('allInRate')}
          />
        </Group>


        {iro?.capFloorFlag && <Group noWrap>
          <NumberInput
            readOnly
            disabled={Number(iro?.iroCap) > form?.values?.allInRate}
            w="100%"
            precision={5}
            label="Cap All-In-Rate"
            parser={value =>
              value ? value.replace(/%\s?|\$\s?|(,*)/g, '') : ''
            }
            formatter={value =>
              !Number.isNaN(parseFloat(value ?? '')) ? value + ' %' : '%'
            }
            value={Number(iro?.iroCap) ?? 0.00000}
          />
          <NumberInput
            readOnly
            disabled={Number(iro?.iroFloor) < form?.values?.allInRate}
            w="100%"
            precision={5}
            label="Floor All-In-Rate"
            parser={value =>
              value ? value.replace(/%\s?|\$\s?|(,*)/g, '') : ''
            }
            formatter={value =>
              !Number.isNaN(parseFloat(value ?? '')) ? value + ' %' : '%'
            }
            value={Number(iro?.iroFloor) ?? 0.00000}
          />
        </Group>}
      </Stack>
    </FormWrapper >
  )
}

export default forwardRef(UpdatedLoanModal)
