import {
    Anchor,
    Avatar,
    Group,
    Stack,
    Text,
    Tooltip,
} from '@mantine/core'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { DetailSection } from 'app/views/components/detailSections/detail-section'
import { DealParams } from 'app/models/deal-params'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import cleanUUID from 'app/views/components/functions/cleanUUID'
import { loadOneDeal, setOneDeal } from 'app/state/ducks/deals/thunks'
import { getDeal, getDeals } from 'app/state/ducks/deals/selectors'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { loadContacts } from 'app/state/ducks/contacts/thunks'
import { getContacts } from 'app/state/ducks/contacts/selectors'
import { getDealAmendments } from 'app/state/ducks/deal-amendments/selectors'
import { getCalendars } from 'app/state/ducks/holiday-calendars/selectors'
import { loadCalendars } from 'app/state/ducks/holiday-calendars/thunks'
import { formatNumberToCurrency } from 'app/utils/util-functions'
import getInitials from 'app/views/components/functions/getInitials'

export default function DealDetailsInfo() {
    const { dealId, status } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const entityIdToName = new Map<string, string>()
    const deal: DealParams | undefined = useSelector(getDeal)
    const entities = useSelector(getEntities)
    const contacts = useSelector(getContacts)
    const dealAmendments: DealParams[] = useSelector(getDealAmendments)
    const allDeals: DealParams[] = useSelector(getDeals)
    const holidayCalendars = useSelector(getCalendars)
    const dealFee = deal?.fees ?? []

    useEffect(() => {
        if (!dealId) {
            return
        }

        dispatch(loadEntities())
        dispatch(loadContacts())
        dispatch(loadCalendars())

        if (allDeals) {
            const selectedDeal = allDeals.find(deal => deal.id === dealId)
            if (selectedDeal) {
                dispatch(setOneDeal(selectedDeal))
            } else {
                dispatch(loadOneDeal(dealId, status ?? 'Draft'))
            }
        }
        if (dealAmendments) {
            const amended_deal = dealAmendments.find(
                amendment => amendment.id === dealId
            )
            if (amended_deal) {
                amended_deal.status = amended_deal.status + ' Amendment'
                dispatch(setOneDeal(amended_deal))
            }
        }
    }, [dealId])

    useEffect(() => {
        if (Array.isArray(entities)) {
            for (const entity of entities) {
                if (entity.id) {
                    entityIdToName.set(cleanUUID(entity.id), entity.entityName)
                }
            }
        }
    }, [entities])

    function dealCalendars() {
        if (holidayCalendars && deal?.holidayCalendar && holidayCalendars.length > 0 && deal?.holidayCalendar.length > 0) {
            const dealCals = deal?.holidayCalendar.map((calendar: any) => {
                return holidayCalendars.find(hc => hc.id === calendar.id)?.name
            })
            return dealCals.join(', ')
        }
        return ('')
    }

    const dealDetailsObjectArray = [
        { label: 'Deal Assignee', value: 'N/A' },
        { label: 'Start Date', value: deal?.startDate },
        { label: 'Deal ID', value: deal?.id },
        { label: 'Holiday Calendar', value: dealCalendars() },
        {
            label: <Text className='sectionItemTitleClickable' onClick={() =>
                navigate(`/dealmanagement/deal/${deal?.id}/borrowers`, {
                    state: { deal, entities },
                })
            }>Borrowers</Text>, value: (
                <Group
                    onClick={() =>
                        navigate(`/dealmanagement/deal/${deal?.id}/borrowers`, {
                            state: { deal, entities },
                        })
                    }
                >
                    <Tooltip.Group openDelay={300} closeDelay={100}>
                        <Avatar.Group spacing="sm">
                            {deal?.borrowers?.map((borrower, i) => {
                                const entityName =
                                    entities?.find(
                                        entity => entity?.id === cleanUUID(borrower?.id)
                                    )?.entityName ?? ''
                                return (
                                    <Anchor key={'deal-borrowers' + i}>
                                        <Tooltip label={entityName} withArrow>
                                            <Avatar color="violet" radius="xl">
                                                {getInitials(entityName)}
                                            </Avatar>
                                        </Tooltip>
                                    </Anchor>
                                )
                            })}
                        </Avatar.Group>
                    </Tooltip.Group>
                </Group>
            )
        },
        { label: 'End Date', value: deal?.maturity },
        { label: 'Deal CUSIP', value: deal?.cusip },
        { label: 'Deal ISIN', value: deal?.isin },
        { label: 'Deal BBG ID', value: deal?.bbgId },
        {
            label: <Text className='sectionItemTitleClickable' onClick={() =>
                navigate(`/dealmanagement/deal/${deal?.id}/${deal?.status}/pricing-grid`)
            }>Pricing Grid</Text>,
            value:
                <Anchor
                    onClick={() =>
                        navigate(`/dealmanagement/deal/${deal?.id}/${deal?.status}/pricing-grid`)
                    }
                >
                    <Group noWrap>
                        <Text className='sectionItemClickabe'>{deal?.isPricingGrid.toString()}</Text>
                    </Group>
                </Anchor>
        },
        { label: 'Total Amount', value: formatNumberToCurrency(Number(deal?.amount) ?? 0, deal?.currency ?? 'USD') },
        { label: 'Utilized Amount', value: formatNumberToCurrency(Number(deal?.utilizedAmount) ?? 0, deal?.currency ?? 'USD') },
        {
            label: <Text className='sectionItemTitleClickable' onClick={() =>
                navigate(`/dealmanagement/deal/${deal?.id}/guarantors`, {
                    state: { deal, entities },
                })
            }>Guarantors</Text>,
            value: (
                <Group
                    noWrap
                    onClick={() =>
                        navigate(`/dealmanagement/deal/${deal?.id}/guarantors`, {
                            state: { deal, entities },
                        })
                    }
                >
                    <Tooltip.Group openDelay={300} closeDelay={100}>
                        <Avatar.Group spacing="sm">
                            {deal?.guarantors?.map((guarantor, index) => {
                                const entityName =
                                    entities?.find(
                                        entity => entity?.id === cleanUUID(guarantor?.id)
                                    )?.entityName ?? ''
                                return (
                                    <Anchor key={'facility-accordion-lender-' + index}>
                                        <Tooltip label={entityName} withArrow>
                                            <Avatar size="md" color="teal" radius="xl">
                                                {getInitials(entityName)}
                                            </Avatar>
                                        </Tooltip>
                                    </Anchor>
                                )
                            })}
                        </Avatar.Group>
                    </Tooltip.Group>
                </Group>
            ),
        },
        {
            label: 'Fees', value: <Anchor
                onClick={() =>
                    navigate(`/dealmanagement/deal/${deal?.id}/${deal?.status}/fees/${dealFee[0]?.feeType}`)
                }
            >
                <Group noWrap>
                    <Text className='sectionItemClickabe'>{dealFee.length.toString() ?? '0'}</Text>
                </Group>
            </Anchor>
        },
        { label: 'Currency', value: deal?.currency },
        { label: 'Maturity Date', value: deal?.maturity },
        {
            label: 'Ops Admin', value: (
                <Tooltip
                    label={
                        contacts && deal
                            ? contacts.find(
                                contact => contact.id === cleanUUID(deal?.opsAdmin?.id)
                            )?.name ?? ''
                            : ''
                    }
                >
                    <Anchor>
                        <Avatar size="md" color="cyan" radius="xl">
                            {getInitials(
                                contacts && deal
                                    ? contacts.find(
                                        contact =>
                                            contact.id === cleanUUID(deal?.opsAdmin?.id)
                                    )?.name ?? ''
                                    : ''
                            )}
                        </Avatar>
                    </Anchor>
                </Tooltip>
            ),
        },
        {
            label: 'Administrative Agent', value: (
                <Tooltip
                    label={
                        entities && deal
                            ? entities.find(
                                entity => entity.id === cleanUUID(deal?.adminEntity?.id)
                            )?.entityName ?? ''
                            : ''
                    }
                >
                    <Anchor>
                        <Avatar size="md" color="cyan" radius="xl">
                            {getInitials(
                                entities && deal
                                    ? entities.find(
                                        entity =>
                                            entity.id === cleanUUID(deal?.adminEntity?.id)
                                    )?.entityName ?? ''
                                    : ''
                            )}
                        </Avatar>
                    </Anchor>
                </Tooltip>
            ),
        },
        { label: 'Deal Ref ID', value: deal?.id },
        { label: "CP's Met", value: deal?.precedentDate },
        { label: 'Currencies', value: deal?.currency },
        {
            label: 'Administrative Agent MEI', value: entities && deal
                ? entities.find(
                    entity =>
                        entity.id === cleanUUID(deal?.adminEntity?.id)
                )?.mei ?? ''
                : ''
        },
    ]

    return (
        <Stack w='100%' className='sectionRemoveGap sectionBody'>
            <Stack w='100%' className='sectionDetailBody noHeader'>
                <DetailSection detailObjArr={dealDetailsObjectArray} columnCount={4} />
            </Stack>
        </Stack>
    )
}