import { Group, Stack } from '@mantine/core'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'

function DealManagementHeaderSection() {
  return (
    <>
      <Stack>
        <Group position="apart">
          <PageTitle pageTitle='Deal Management' />
        </Group>
      </Stack>
    </>
  )
}

export default DealManagementHeaderSection
