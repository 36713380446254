import { Stack, Button, Table, Text } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getDealHistory } from 'app/state/ducks/deals/selectors'
import { loadDealHistory } from 'app/state/ducks/deals/thunks'
import moment from 'moment'
import ExportToCsv from 'app/views/components/ExportToCsv/ExportToCsv'
import { formatNumberToCurrency } from 'app/utils/util-functions'

type Props = {
  close: any
  dealId: string
}

export default function HistoryForm({ close, dealId }: Props) {
  const dealHistory = useSelector(getDealHistory)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadDealHistory(dealId))
  }, [dealId])

  const dealArrayObjects = function () {
    const exportObjVals = dealHistory.map((item) => {
      const exportObj: any = {}
      exportObj['Type'] = item.actionType
      exportObj['Start Date'] = item.deal.startDate
      exportObj['End Date'] = item.deal.maturity
      exportObj['Amount'] = formatNumberToCurrency(Number(item.deal.amount), item.deal?.currency ?? 'USD') 
      exportObj['Days'] = moment(item.deal.maturity).diff(
        moment(item.deal.startDate),
        'days'
      )
      exportObj['History Date'] = item.actionDate.toString()
      exportObj['History Time'] = moment(item.actionTime).format('hh:mm A')
      return exportObj
    })
    return exportObjVals
  }

  return (
    <Stack w="100%">
      <div
        style={{
          backgroundColor: '#A4CAFE',
          width: '100%',
          padding: '10px',
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold',
        }}
      >
        Deal History
      </div>
      <div style={{ backgroundColor: '#E5E7EB', border: '1px solid #D1D5DB' }}>
        <Table>
          <thead>
            <tr>
              <th style={{ width: '9%' }}>
                <Text>Type</Text>
              </th>
              <th style={{ width: '9%' }}>
                <Text>Start Date</Text>
              </th>
              <th style={{ width: '9%' }}>
                <Text>End Date</Text>
              </th>
              <th style={{ width: '10%' }}>
                <Text>Amount</Text>
              </th>
              <th style={{ width: '9%' }}>
                <Text>Days</Text>
              </th>
              <th style={{ width: '9%' }}>
                <Text>History Date</Text>
              </th>
              <th style={{ width: '9%' }}>
                <Text>History Time</Text>
              </th>
            </tr>
          </thead>
          <tbody style={{ backgroundColor: '#E5E7EB' }}>
            {dealArrayObjects() && dealArrayObjects().length > 0 &&
              dealArrayObjects().map((item, index) => (
                <tr
                  key={index}
                  style={{ border: '1px solid #D1D5DB', height: '3vw' }}
                >
                  <td>
                    <Text>
                      {item['Type']}
                    </Text>
                  </td>
                  <td>
                    <Text>{item['Start Date']}</Text>
                  </td>
                  <td>
                    <Text>{item['End Date']}</Text>
                  </td>
                  <td>
                    <Text>
                      {item['Amount']}
                    </Text>
                  </td>
                  <td>
                    <Text>
                      {item['Days']}
                    </Text>
                  </td>
                  <td>
                    <Text>{item['History Date']}</Text>
                  </td>
                  <td>
                    <Text>{item['History Time']}</Text>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '70px',
        }}
      >
        <ExportToCsv csvName='Deal History' exportObj={dealArrayObjects()} style={{
          width: '130px',
          backgroundColor: '#A4CAFE',
          color: 'black',
          fontWeight: 'bold',
          borderRadius: '8px',
        }} />

        <Button
          onClick={close}
          style={{
            width: '130px',
            backgroundColor: '#32439A',
            color: 'white',
            fontWeight: 'bold',
            borderRadius: '8px',
          }}
        >
          Exit
        </Button>
      </div>
    </Stack>
  )
}
