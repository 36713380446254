import { MRT_ColumnDef } from 'mantine-react-table'
import { Text, Avatar, Group } from '@mantine/core'
import { ReactNode } from 'react'
import getInitials from 'app/views/components/functions/getInitials'

function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}

export const userManagementCols: MRT_ColumnDef<any>[] = [
  {
    accessorKey: 'fullName',
    header: 'Full Name',
    Header: <TableHead header={'Full Name'} />,
    size: 150,
    Cell: ({ cell }) => (
      <>
        <Group>
          <Avatar radius="xl" color="cyan" size="2.5em">
            {getInitials(cell.getValue<string>())}
          </Avatar>
          <span style={{ fontSize: '1.5em' }}>{cell.getValue<string>()}</span>
        </Group>
      </>
    ),
  },
  {
    size: 100,
    accessorKey: 'username',
    header: 'Username',
    Header: <TableHead header={'Username'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 100,
    accessorKey: 'userProfile',
    header: 'User Profile',
    Header: <TableHead header={'User Profile'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 100,
    accessorKey: 'teamName',
    header: 'Team Name',
    Header: <TableHead header={'Team Name'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 100,
    accessorKey: 'userType',
    header: 'User Type',
    Header: <TableHead header={'User Type'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 100,
    accessorKey: 'email',
    header: 'Email',
    Header: <TableHead header={'Email'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 100,
    accessorKey: 'activityDetails',
    header: 'Activity Details',
    Header: <TableHead header={'Activity Details'} />,
    Cell: ({ cell }) => (
      <div style={{ fontSize: '1.25em' }}>
        <b>Login count: </b>{' '}
        {cell.getValue<{ activityCount: number }>()?.activityCount}
        <br></br>
        <b>Last: </b> {cell.getValue<{ activityLast: string }>()?.activityLast}
      </div>
    ),
  },
]
