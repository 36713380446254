import { Flex, Grid, NumberInput } from '@mantine/core'
import React, { useEffect, useImperativeHandle, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isNotEmpty, useForm } from '@mantine/form'
import { Group, Box } from '@mantine/core'
import Select from '../inputs/Select'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { EntityParams } from 'app/models/entity-params'
import { getFacilityLendersPositionsWithLoading, getLoanLendersPositionsWithLoading } from 'app/state/ducks/lenders-positions/selectors'
import { changeAllLendersForLoan, getAmount, loadLendersPositionsByLoan } from 'app/state/ducks/lenders-positions/thunks'
import { StableKey } from 'app/models/common-types'
import {
    ErrorNotification,
    SuccessNotification,
} from '../notifications/notification'
import { getFacility } from 'app/state/ducks/facilities/selectors'
import { LoanParams } from 'app/models/loan-params'
import { formatNumberToCurrency, stringToDate } from 'app/utils/util-functions'
import IonIcon from '@reacticons/ionicons'

type Props = {
    loan: LoanParams
}

interface ILendersArray {
    label: string
    value: string
    disabled: boolean
}

export interface ChildManageLendersRef {
    handleClick: () => void
}

const defaultValue = {
    id: null,
    percentage: '',
    amount: 0,
    lenderName: '',
    lenders: { id: '' },
    creditContactId: null,
    adminContactId: null,
    wireInstructions: [],
}

const ManageLoanLenders: React.ForwardRefRenderFunction<
    ChildManageLendersRef,
    Props
> = ({ loan }, ref) => {
    const dispatch = useDispatch()
    const facility = useSelector(getFacility)
    const { data: facilityLendersPosition } = useSelector(getFacilityLendersPositionsWithLoading)
    const entities: EntityParams[] = useSelector(getEntities)
    const { data: lendersPositions, isLoading: isLoadingLenders } = useSelector(
        getLoanLendersPositionsWithLoading
    )
    const [disableLenderPositions, setDisableLenderPositions] = useState(true);

    useEffect(() => {
        dispatch(loadLendersPositionsByLoan(loan?.id ?? ''))
    }, [])

    useEffect(() => {
        if (loan?.isNonProRata || loan?.isSwingLine) {
            setDisableLenderPositions(false)
        }
    }, [loan])

    lendersPositions?.forEach(lp => {
        if (lp.proRatas) {
            lp.amount = getAmount(lp, stringToDate(loan?.startDate ?? '')) + ''
        }
    })

    const [lendersArray, setLendersArray] = useState<ILendersArray[]>([])
    const handleClick = async () => {
        const transformedPositions = form.getTransformedValues().lendersPosition
        if (form.validate().hasErrors === true) {
            throw Error
        } else {
            if (loan?.isNonProRata || loan?.isSwingLine) {
                try {
                    await dispatch(
                        changeAllLendersForLoan(transformedPositions, {
                            id: loan.id || '',
                            admin: loan.agencyAdmin,
                        })
                    )
                    SuccessNotification({
                        title: 'Successful Loan Creation',
                        message:
                            'You have successfully created a loan with the selected lender position',
                    })
                } catch {
                    ErrorNotification({
                        title: ' Loan Creation Failed',
                        message: 'Check Lender Allocations and try again',
                    })
                }
            } else {
                SuccessNotification({
                    title: 'Successful Loan Creation',
                    message:
                        'Lender Positions are set to ProRata.',
                })
            }
        }
    }

    // Expose the handleClick method to the parent component
    useImperativeHandle(ref, () => ({
        handleClick,
    }))

    React.useEffect(() => {
        const lendersPosition =
            lendersPositions?.length > 0
                ? lendersPositions.map(
                    (lp: { lender: StableKey; amount: string; id?: string }) => ({
                        lenders: lp.lender,
                        amount: Number(lp.amount),
                        id: lp.id,
                    })
                )
                : [{ ...defaultValue }]
        const subtotal =
            lendersPositions.length > 0
                ? lendersPositions.reduce(
                    (accumulator: number, lp: { amount: string }) =>
                        accumulator + Number(lp.amount),
                    0
                )
                : 0
        form.setFieldValue('lendersPosition', lendersPosition)
        form.setFieldValue('subtotal', subtotal)
    }, [lendersPositions])

    React.useEffect(() => {
        if (loan?.isNonProRata) {
            const lenderallocations = facilityLendersPosition?.map(lenderPosition => {
                const lender = entities.find(entity => entity.id === lenderPosition.lender.id)
                return { value: lenderPosition.lender.id, label: lender?.entityName ?? '', disabled: false }
            })
            setLendersArray(lenderallocations)
            return
        }
        if (loan?.isSwingLine) {
            const lenderallocations = facility?.subLimits.find(subLimit => subLimit.subLimitsType === 'SwingLine')?.lenders.map((lenders: any) => {
                const entity = entities.find(entity => entity.id === lenders.lender.id)
                return { value: lenders.lender.id ?? '', label: entity?.entityName ?? '', disabled: false }
            }) ?? []
            setLendersArray(lenderallocations)
            return
        }
        const lenderallocations =
            entities && entities.length > 0
                ? entities
                    .filter(entity =>
                        entity.entityProfile.some(profile =>
                            [
                                'LenderAgency',
                                'LenderCounterParty',
                                'LenderCounterSub',
                                'LenderCreditBenficiary',
                            ].includes(profile)
                        )
                    )
                    .map(entity => ({
                        value: entity.id || '',
                        label: entity.entityName,
                        disabled: false,
                    }))
                : []
        setLendersArray(lenderallocations)
    }, [loan, lendersPositions])

    const form = useForm({
        initialValues: {
            lendersPosition:
                lendersPositions?.length > 0
                    ? lendersPositions.map(
                        (lp: { lender: StableKey; amount: string; id?: string }) => ({
                            lenders: lp.lender,
                            amount: Number(lp.amount),
                            id: lp.id,
                        })
                    )
                    : [{ ...defaultValue }],
            subtotal:
                lendersPositions.length > 0
                    ? lendersPositions.reduce(
                        (accumulator: number, lp: { amount: string }) =>
                            accumulator + Number(lp.amount),
                        0
                    )
                    : 0,
        },

        transformValues: values => ({
            ...values,
            lendersPosition: values.lendersPosition.map(
                (a: { lenders: { id: string }; amount: number }) => {
                    return {
                        ...a,
                        lender: {
                            id: a.lenders.id,
                            admin:
                                entities.find(entity => entity.id === a.lenders.id)?.maker ??
                                '',
                        },
                        amount: a.amount.toString(),
                    }
                }
            ),
        }),

        validate: {
            lendersPosition: {
                amount: value => (value >= 0 ? null : 'Invalid amount'),
                lenders: { id: isNotEmpty(' Choose a lender') },
            },
        },
    })

    form.values.subtotal = form.values?.lendersPosition
        .map(lp => (lp.amount ? lp.amount : 0))
        .reduce(
            (accumulator: number, currentValue: number) => accumulator + currentValue,
            0
        )
    const loanAmount = Number(loan?.amount)
    const selectedOptions = form.values.lendersPosition?.map(
        (item: { lenders: { id: string } }) => item.lenders.id
    )
    const fields = form.values.lendersPosition?.map(
        (
            item: { lenders: { id: string }; newField?: boolean | null },
            index: number
        ) => {
            const selectedOption = item.lenders.id
            const updatedOptions = lendersArray.map(option => {
                return {
                    ...option,
                    disabled:
                        selectedOptions.includes(option.value) &&
                        option.value !== selectedOption,
                }
            })

            return (
                <Group grow key={index}>
                    <Flex direction={'column'}>
                        <Grid
                            grow
                            style={{
                                height: '25%',
                                paddingBottom: '10px',
                            }}
                        >
                            <Grid.Col span={5}>
                                <Select
                                    searchable
                                    withAsterisk
                                    label="Lender"
                                    data={updatedOptions}
                                    placeholder="Select a Lender"
                                    disabled={
                                        (disableLenderPositions) && !item.newField
                                            ? true
                                            : false
                                    }
                                    {...form.getInputProps(`lendersPosition.${index}.lenders.id`)}
                                />
                            </Grid.Col>
                            <Grid.Col span={5}>
                                <NumberInput
                                    withAsterisk
                                    label="Amount"
                                    precision={2}
                                    placeholder="Enter amount"
                                    disabled={disableLenderPositions}
                                    min={0}
                                    defaultValue={0}
                                    parser={value => value.replace(/[^0-9.]/g, '')}
                                    formatter={value =>
                                        !Number.isNaN(parseFloat(value))
                                            ? formatNumberToCurrency(
                                                Number(
                                                    parseFloat(
                                                        value.replace(
                                                            /\B(?<!\d*)(?=(\d{3})+(?!\d))/g,
                                                            ','
                                                        )
                                                    )
                                                ),
                                                loan?.currency ?? 'USD'
                                            )
                                            : '$ 0'
                                    }
                                    {...form.getInputProps(`lendersPosition.${index}.amount`)}
                                    sx={{
                                        label: {
                                            fontFamily: 'Plus Jakarta Sans',
                                            color: '#111928',
                                            fontSize: '1em',
                                        },
                                        input: {
                                            backgroundColor: '#F9FAFB',
                                            height: '3em',
                                            borderRadius: '0.7em',
                                            color: '#6B7280',
                                            borderWidth: '1px',
                                            borderColor: '#D1D5DB',
                                            fontSize: '1em',
                                            fontFamily: 'Plus Jakarta Sans',
                                        },
                                    }}
                                />
                            </Grid.Col>
                            {disableLenderPositions ? (
                                <Grid.Col
                                    span={2}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                ></Grid.Col>
                            ) : (
                                <Grid.Col
                                    span={2}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <span
                                        style={{
                                            fontFamily: 'Plus Jakarta Sans',
                                            color: '#111928',
                                            fontSize: '12px',
                                            fontWeight: 'bold',
                                            paddingTop: '5px',
                                        }}
                                    >
                                        Remove
                                    </span>
                                    <IonIcon name="trash-outline" className="click-ion-icon" style={{ color: "red" }}
                                        onClick={() =>
                                            form.removeListItem('lendersPosition', index)
                                        } />
                                </Grid.Col>
                            )}
                        </Grid>
                    </Flex>
                </Group>
            )
        }
    )

    return !isLoadingLenders ?
        (
            <Box>
                {disableLenderPositions ? (
                    <Grid.Col span={12} className="topFormErrorText">
                        Lender Positions cannot be changed for this loan.
                    </Grid.Col>
                ) : null}
                {fields}
                <Group>
                    {disableLenderPositions ? (
                        <Grid.Col span={5}></Grid.Col>
                    ) : (
                        <Grid.Col span={5}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <IonIcon name="add-circle-outline" className="click-ion-icon"
                                    onClick={() =>
                                        form.insertListItem('lendersPosition', {
                                            ...defaultValue,
                                            newField: true,
                                        })
                                    }
                                />
                                <span
                                    style={{
                                        marginLeft: '8px',
                                        fontFamily: 'Plus Jakarta Sans',
                                        fontWeight: 'bold',
                                        fontSize: '1em',
                                    }}
                                >
                                    Add lenders and amounts
                                </span>
                            </div>
                        </Grid.Col>
                    )}
                    <Grid.Col span={5}>
                        <NumberInput
                            label="Subtotal"
                            placeholder="Allocation Total"
                            min={0}
                            defaultValue={0}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            disabled
                            precision={2}
                            formatter={value =>
                                !Number.isNaN(parseFloat(value))
                                    ? formatNumberToCurrency((Number(value) ?? 0), (loan?.currency ?? 'USD'))
                                    : loan?.currency ?? 'USD'
                            }
                            {...form.getInputProps(`subtotal`)}
                            sx={{
                                label: {
                                    fontFamily: 'Plus Jakarta Sans',
                                    color: '#111928',
                                    fontSize: '1em',
                                    marginLeft: '-10px',
                                    width: '102%',
                                },
                                input: {
                                    backgroundColor: '#F9FAFB',
                                    height: '3em',
                                    borderRadius: '0.7em',
                                    color: '#6B7280',
                                    borderWidth: '1px',
                                    borderColor: '#D1D5DB',
                                    fontSize: '1em',
                                    fontFamily: 'Plus Jakarta Sans',
                                    marginLeft: '-10px',
                                    width: '102%',
                                },
                            }}
                        />
                    </Grid.Col>
                </Group>
                {Math.abs(form.values.subtotal - loanAmount) > .01 ? (
                    <Group>
                        <Grid.Col span={10} style={{ textAlign: 'right', marginLeft: '8px' }}>
                            <span style={{ color: '#dc4d01' }}>
                                Total Lender Allocations should not exceed Loan Amount.
                            </span>
                        </Grid.Col>
                    </Group>
                ) : null}
            </Box>
        ) : <Box></Box>
}
export default React.forwardRef(ManageLoanLenders)
