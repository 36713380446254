import React from 'react'
import { PageLayout } from 'app/views/components/layout/page-layout'
import SignupForm from './form'

const Signup = () => {
  return (
    <PageLayout>
      <div className="signupform">
        <SignupForm />
      </div>
    </PageLayout>
  )
}

export default Signup
