import React from 'react';
import { Menu, Radio } from '@mantine/core';
import IonIcon from '@reacticons/ionicons';

// Define props interface
interface FilterMenuProps {
    selected: string;
    onSelect: (value: string) => void;
}
const FilterMenu: React.FC<FilterMenuProps> = ({ selected, onSelect }) => {
    return (
        <Menu closeOnItemClick={false}>
            <Menu.Target>
                <IonIcon style={{ height: '20px', width: '20px' }} name='funnel-outline' />
            </Menu.Target>
            <Menu.Dropdown>
                <Radio.Group value={selected} onChange={onSelect} >
                    <Menu.Item onClick={() => onSelect('All')}>
                        <Radio value="All" label="All" />
                    </Menu.Item>
                    <Menu.Item onClick={() => onSelect('Success')}>
                        <Radio value="Success" label="Success" />
                    </Menu.Item>
                    <Menu.Item onClick={() => onSelect('Failure')}>
                        <Radio value="Failure" label="Failure" />
                    </Menu.Item>
                </Radio.Group>
            </Menu.Dropdown>
        </Menu>
    );
};

export default FilterMenu;