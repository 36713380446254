import { PrincipalPaymentParams } from 'app/models/principal-payments-params'
import * as types from './types'
import { createReducer } from 'app/state/utils'

export interface PrincipalPaymentState {
  principalPayments: PrincipalPaymentParams[]
  isLoading: boolean
}

const initialState: PrincipalPaymentState = {
  principalPayments: [],
  isLoading: false,
}

const principalPaymentsReducer = createReducer(initialState)({
  [types.GET_PRINCIPAL_PAYMENTS]: (state: any, payload: any) => {
    return { ...state, principalPayments: payload.payload }
  },

  [types.EDIT_PRINCIPAL_PAYMENT]: (state: any, payload: any) => {
    const index = state.principalPayments.findIndex(
      (principalPayment: { id: any }) =>
        principalPayment.id === payload.payload.data.id
    )
    const newArray = [...state.principalPayments]
    newArray[index] = { ...payload.payload.data, status: 'Draft' }
    return {
      ...state,
      principalPayments: newArray,
    }
  },

  [types.ADD_PRINCIPAL_PAYMENT]: (state: any, payload: any) => {
    return {
      ...state,
      principalPayments: [
        { ...payload.payload.data, status: 'Draft' },
        ...state.principalPayments,
      ],
    }
  },

  [types.SUBMIT_PRINCIPAL_PAYMENT]: (state: any, payload: any) => {
    const obj = state.principalPayments.find(
      (principalPayments: { id: any }) =>
        principalPayments.id == payload.payload.id
    )
    obj.status = 'Submitted'
    return {
      ...state,
      principalPayments: [...state.principalPayments],
    }
  },

  [types.APPROVE_PRINCIPAL_PAYMENT]: (state: any, payload: any) => {
    const obj = state.principalPayments.find(
      (principalPayment: { id: any }) =>
        principalPayment.id == payload.payload.id
    )
    obj.status = 'Approved'

    return {
      ...state,
      principalPayments: [...state.principalPayments],
    }
  },

  [types.REJECT_PRINCIPAL_PAYMENT]: (state: any, payload: any) => {
    const obj = state.principalPayments.find(
      (principalPayment: { id: any }) =>
        principalPayment.id == payload.payload.id
    )
    obj.status = 'Draft'
    return {
      ...state,
      principalPayments: [...state.principalPayments],
    }
  },

  [types.DELETE_PRINCIPAL_PAYMENT]: (state: any, payload: any) => {
    const index = state.principalPayments.findIndex(
      (principalPayment: { id: any }) =>
        principalPayment.id == payload.payload.id
    )
    if (index >= 0) {
      state.principalPayments.splice(index, 1)
    }
    return {
      ...state,
      principalPayments: [...state.principalPayments],
    }
  },
  [types.START_LOADING_PRINCIPAL_PAYMENT]: (state: any) => {
    return { ...state, isLoading: true }
  },
  [types.STOP_LOADING_PRINCIPAL_PAYMENT]: (state: any) => {
    return { ...state, isLoading: false }
  },
})

export default principalPaymentsReducer
