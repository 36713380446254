import { useCallback } from 'react'
import { Assignment } from '../params'
import { LendersPosition } from 'app/models/lenders-position-params'

type UseCheckAmountProps = {
  updatedLendersPositions: LendersPosition[]
  assignmentsList: Assignment[]
  selectedRow: number | null
}

export const useCheckAmount = ({
  updatedLendersPositions,
  assignmentsList,
  selectedRow,
}: UseCheckAmountProps) => {
  const checkAmountBiggerEdit = useCallback(
    (assignment: Assignment) => {
      return updatedLendersPositions.some(lender => {
        if (lender.lender.id === assignment.from) {
          if (assignmentsList[selectedRow ?? 0].from === assignment.from) {
            return (
              Number(lender.amount) +
                Number(assignmentsList[selectedRow ?? 0].amount) <
              assignment.amount
            )
          } else {
            return Number(lender.amount) < assignment.amount
          }
        }
        return false
      })
    },
    [updatedLendersPositions, assignmentsList, selectedRow]
  )

  const checkAmountBiggerAdd = useCallback(
    (assignment: Assignment) => {
      return updatedLendersPositions.some(lender => {
        if (lender.lender.id === assignment.from) {
          return Number(lender.amount) < assignment.amount
        }
        return false
      })
    },
    [updatedLendersPositions]
  )

  return {
    checkAmountBiggerEdit,
    checkAmountBiggerAdd,
  }
}
