import * as types from './types'
import { createReducer } from 'app/state/utils'

const initialState: never[] = []

const usersReducer = createReducer(initialState)({
  [types.SET_USERS]: (state: any, payload: any) => {
    return { ...state, users: payload }
  },
})

export default usersReducer
