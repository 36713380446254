import { Menu, Radio, Text, Group } from '@mantine/core'
import { useEffect, useState } from 'react'
import { Document, getDocumentTagOptions } from 'app/models/document-params'
import { useDispatch } from 'react-redux'
import {
  changeTag,
  deleteDocument,
  getDocumentFromS3,
} from 'app/state/ducks/documents/thunks'
import IonIcon from '@reacticons/ionicons'

interface Props {
  file: Document
}

export default function FileActions({ file }: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedTag, setSelectedTag] = useState(file.tag)
  const dispatch = useDispatch()
  const posibleTags = getDocumentTagOptions(file.contractType)

  useEffect(() => {
    if (selectedTag == file.tag) {
      return
    }
    dispatch(changeTag(file.id, selectedTag))
  }, [selectedTag])

  function deleteDoc(id: string) {
    dispatch(deleteDocument(id))
  }

  async function downloadDocument(doc: Document) {
    const { url } = await getDocumentFromS3(doc.id, doc.mimeType)

    if (url) {
      const blob = await fetch(url).then(response => response.blob())
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = doc.name
      link.style.display = 'none'

      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(link.href)
    } else {
      console.error('URL not found')
    }
  }

  return (
    <Menu
      shadow="md"
      width={250}
      position="bottom"
      onChange={setIsOpen}
    >
      <Menu.Target>
        <Group sx={{ position: 'relative'}}>
        <IonIcon name='ellipsis-horizontal-outline' style={{ height: '18px', width: '18px' }} onClick={() => setIsOpen(!isOpen)} />
        </Group>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          onClick={() => {
            downloadDocument(file)
          }}
        >
          Download Attachment
        </Menu.Item>
        {posibleTags.length > 0 && (
          <Menu.Item closeMenuOnClick={false}>
            <Menu
              closeOnItemClick={false}
              shadow="md"
              width={200}
              position="left"
              arrowPosition="center"
            >
              <Menu.Target>
                <Text style={{ fontSize: 10 }}>Tag Attachment</Text>
              </Menu.Target>
              <Menu.Dropdown>
                <Radio.Group
                  value={selectedTag ?? undefined}
                  onChange={value => setSelectedTag(value)}
                >
                  {posibleTags.map((tag, index) => (
                    <Menu.Item
                      key={index}
                      onClick={() => setSelectedTag(tag.value)}
                    >
                      <Radio value={tag.value} label={tag.label} />
                    </Menu.Item>
                  ))}
                </Radio.Group>
              </Menu.Dropdown>
            </Menu>
          </Menu.Item>
        )}
        <Menu.Item
          color="red"
          onClick={() => {
            deleteDoc(file.id)
          }}
        >
          Delete Attachment
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}
