import { PaymentState } from './reducer'

export const getPayments = (state: {
  payments: PaymentState
}) => state.payments.payments

export const getLockedPayments = (state: {
  payments: PaymentState
}) => state.payments.lockedPayments

export const getSenders = (state: {
  payments: PaymentState
}) => state.payments.senders

export const getReceivers = (state: {
  payments: PaymentState
}) => state.payments.receivers

export const getActivePaymentLoader = (state: {
  payments: PaymentState
}) => state.payments.isLoadingActivePayments

export const getLockedPaymentLoader = (state: {
  payments: PaymentState
}) => state.payments.isLoadingLockedPayments
