import { Menu, Modal } from '@mantine/core'
import { AmortizationScheduleParams, SchedulerList } from 'app/models/amortitation-schedule'
import PrimaryDropDown from 'app/views/components/DropDown/DropDown'
import { changeStatus, deleteRow } from './amortization-row-daml-actions'
import { useDispatch } from 'react-redux'
import EditAmortizationRowForm from './amortization-row-edit-form'
import { FacilityParams } from 'app/models/facility-params'
import { useState } from 'react'
import IonIcon from '@reacticons/ionicons'

type Props = {
    row: SchedulerList
    amortization: AmortizationScheduleParams
    facility?: FacilityParams
    canBePaid: boolean
    canBePastDue: boolean
}

export default function AmortizationRowActions({ row, amortization, canBePastDue, canBePaid, facility }: Props) {

    const dispatch = useDispatch()

    const [openEditRow, setOpenEditRow] = useState(false)

    return (
        <>
            <Menu shadow="md" width={200} position="bottom" withinPortal>
                <Menu.Target>
                    <div style={{ width: 'fit-content' }}>
                        <PrimaryDropDown>Actions</PrimaryDropDown>
                    </div>
                </Menu.Target>

                <Menu.Dropdown>
                    {canBePastDue && <Menu.Item
                        icon={<IonIcon className="action-ion-icon" name='clipboard-outline' />}
                        onClick={() => changeStatus(amortization, row, 'Past_Due', dispatch)}
                    >
                        Past Due
                    </Menu.Item>
                    }
                    {canBePaid && <Menu.Item
                        icon={<IonIcon className="action-ion-icon" name='clipboard-outline' />}
                        onClick={() =>
                            changeStatus(amortization, row, 'Paid', dispatch)
                        }
                    >
                        Paid
                    </Menu.Item>
                    }

                    <Menu.Item
                        icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
                        onClick={() => setOpenEditRow(true)}
                    >
                        Edit
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Label>Danger zone</Menu.Label>
                    <Menu.Item
                        color="red"
                        icon={<IonIcon className="action-ion-icon" name='trash-outline' />}
                        onClick={() => deleteRow(amortization, row, dispatch)}
                    >
                        Delete
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
            {openEditRow && facility && (
                <Modal
                    size="xl"
                    className="modal-body create-new-form fit-content-modal"
                    opened={openEditRow}
                    onClose={() => setOpenEditRow(false)}
                >

                    <EditAmortizationRowForm row={row} facility={facility} close={() => setOpenEditRow(false)} />


                </Modal>
            )}
        </>
    )
}
