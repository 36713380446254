import React, { useEffect, useRef } from 'react';

const gradientClass = {
  backgroundColor: '#7faedf',
  border: '2px solid #ddd',
};

interface MyModalProps {
  children?: React.ReactNode;
  closeOnClickOutside?: boolean;
  closeOnEsc?: boolean;
  onClose: () => void;
  open?: boolean;
}

const Frame: React.FC<MyModalProps> = ({
  children,
  closeOnClickOutside = true,
  closeOnEsc = true,
  onClose,
  open = true,
}) => {
  useEffect(() => {
    const onKeyPress = (e: KeyboardEvent) => {
      if (closeOnEsc && open && e.key === 'Escape') onClose();
    };

    window.addEventListener('keydown', onKeyPress);
    return () => window.removeEventListener('keydown', onKeyPress);
  }, [closeOnEsc, onClose, open]);

  const container = useRef<HTMLDivElement>(null);
  const onOverlayClick = (e: React.MouseEvent) => {
    if (!container.current?.contains(e.target as Node)) onClose();
  };

  return (
    <div
      style={{
        position: 'fixed',
        inset: 0,
        zIndex: 10,
        padding: '2rem',
        color: 'white',
        backgroundColor: 'rgba(75, 85, 99, 0.9)',
        display: open ? 'block' : 'none',
      }}
      onClick={closeOnClickOutside ? onOverlayClick : undefined}
    >
      <div
        style={{
          position: 'relative',
          width: '100%',
          maxWidth: '24rem',
          margin: '0 auto',
          marginTop: '8rem',
        }}
        ref={container}
      >
        <button
          style={{
            position: 'absolute',
            top: '-0.5rem',
            right: '-0.5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            height: '2rem',
            width: '2rem',
            cursor: 'pointer',
            boxShadow:
              '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
            ...gradientClass,
          }}
          onClick={() => onClose()}
          title="Close Popup"
        >
          <span
            style={{
              fontSize: '1.5rem',
              lineHeight: '1.75rem',
              userSelect: 'none',
              color: '#000',
            }}
          >
            &times;
          </span>
        </button>
        <div
          style={{
            overflow: 'hidden',
            backgroundColor: '#1f2937',
            borderRadius: '0.5rem',
            boxShadow:
              '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

interface MyHeadProps {
  children?: React.ReactNode;
}

const Head: React.FC<MyHeadProps> = ({ children }) => (
  <div
    style={{
      display: 'block',
      padding: '1rem',
      backgroundColor: '#7faedf',
      border: '2px solid #ddd',
    }}
  >
    <h1
      style={{
        color: '#fff',
        fontSize: '1.125rem',
      }}
    >
      {children}
    </h1>
  </div>
);

interface MyBodyProps {
  children?: React.ReactNode;
}

const Body: React.FC<MyBodyProps> = ({ children }) => (
  <div
    style={{
      padding: '1rem',
      backgroundColor: '#fff',
      color: '#636c82',
    }}
  >
    {children}
  </div>
);

export const Modal = { Frame, Head, Body };
