import React, { useState, useEffect, useImperativeHandle } from 'react'
import { Group, Stack, Divider, } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from '@mantine/form'
import { saveFacility } from 'app/state/ducks/facilities/thunks'
import { saveFacilityAmendment } from 'app/state/ducks/facility-amendments/thunks'
import {
  FacilityLevelData,
  FacilityParams, FacilityPricingGrid,
} from 'app/models/facility-params'
import { getFacilityAmendment } from 'app/state/ducks/facility-amendments/selectors'
import {
  ErrorNotification,
  SuccessNotification,
} from '../notifications/notification'
import { getDeals } from 'app/state/ducks/deals/selectors'
import { DealParams } from 'app/models/deal-params'
import PricingGridTypeSelector from './pricing-grid-component'
import LevelDetails from './level-details'
import AddGridButton from './add-grid-button'
import RemoveGridButton from './remove-grid-button'
import { formatDateToUTC } from 'app/utils/util-functions'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'

type Props = {
  facility: any
  setFacility: React.Dispatch<React.SetStateAction<any>>
  amendment?: string | null | undefined
}

export interface ChildManageFacilityPricingGridRef {
  handleClick: () => void
}

const facilityPricingGrid: React.ForwardRefRenderFunction<ChildManageFacilityPricingGridRef, Props> = (
  { facility, setFacility, amendment },
  ref
) => {
  const facilityAmendment: FacilityParams = useSelector(getFacilityAmendment)
  const allDeals = useSelector(getDeals)
  const businessDate = useSelector(getBusinessDate)
  const dispatch = useDispatch()
  const [deal, setDeal] = useState<DealParams>()


  useEffect(() => {
    setDeal(allDeals?.find(deal => deal?.id === facility?.dealId?.id))
  }, [facility?.dealId, allDeals])


  const handleClick = async () => {
    if (form.validate().hasErrors === true) {
      throw Error
    } else {
      let response: any
      if (amendment) {
        response = await dispatch(
          saveFacilityAmendment(
            {
              ...facility,
              amendmentDate: facility.amendmentDate ?? formatDateToUTC(businessDate),
              amendmentAmortization: facility.amendmentAmortization ?? null,
              amendmentFees: facility.amendmentFees ?? [],
              amendmentLenders: facility.amendmentLenders ?? [],
              facilityPricingGrid: form.getTransformedValues().pricingGrid,
            },
            facilityAmendment
          )
        )
      } else {
        response = await dispatch(
          saveFacility(
            {
              ...facility,
              facilityPricingGrid: form.getTransformedValues().pricingGrid,
            },
            facility.id
          )
        )
      }
      if (response?.success === false) {
        ErrorNotification({
          title: ' Facility Action Failed',
          message:
            response?.payload || 'Check Lender Allocations and try again',
        })
        return
      }
      if (
        Object.keys(response?.payload).length !== 0 ||
        response?.success === true
      ) {
        setFacility({
          ...facility,
          facilityPricingGrid: form.getTransformedValues().pricingGrid,
        })
        SuccessNotification({
          title: 'Successful Facility Creation',
          message:
            'You can add Lender Allocations, Interest Rate Options, Fees and Amortization Schedules',
        })
      }
    }
  }

  useImperativeHandle(ref, () => ({
    handleClick,
  }))

  const form = useForm({
    initialValues: {
      pricingGrid: facility?.facilityPricingGrid?.length > 0
        ? facility.facilityPricingGrid.map((pricingGrid: { levels: FacilityLevelData[] }) => ({
          ...pricingGrid,
          levels: pricingGrid.levels.map(level => ({
            ...level,
            margin: Number(level.margin),
            fee: Number(level.fee)
          }))
        }))
        : [
          {
            pricingGridType: '',
          },
        ],
    },
  })

  const changeGridType = (value: string, index: number) => {
    const findDealPricingGrid = deal?.pricingGrid.find((grid) => grid.pricingGridType === value)
    const levels = findDealPricingGrid?.levels.map((level) => {
      return { level: level, margin: 0.0, fee: 0.0, currentLevel: false }
    })
    form.setFieldValue(`pricingGrid.${index}`, { pricingGridType: value, levels })
  }


  const fields = form.values.pricingGrid?.map((pricingGrid: FacilityPricingGrid, pricingGridIndex: number) => {
    const handleLevelChange = (idx: number) => {
      const updatedLevels = pricingGrid.levels.map((level: FacilityLevelData, index: number) => ({
        ...level,
        currentLevel: index === idx
      }));
      form.setFieldValue(`pricingGrid.${pricingGridIndex}.levels`, updatedLevels);
    };


    return (
      <Stack key={pricingGridIndex}>
        <Group noWrap w="100%">
          <PricingGridTypeSelector
            form={form}
            pricingGridIndex={pricingGridIndex}
            deal={deal}
            changeGridType={changeGridType}
          />
        </Group>
        {pricingGrid?.levels?.map((level, idx) => (
          <Group noWrap w="100%">
            <LevelDetails
              key={idx}
              form={form}
              pricingGridIndex={pricingGridIndex}
              idx={idx}
              level={level}
              deal={deal}
              handleLevelChange={handleLevelChange}
            />
          </Group>
        ))}
        <Group noWrap w="100%" position='center'>
          <RemoveGridButton
            onRemove={() => form.removeListItem('pricingGrid', pricingGridIndex)}
          />
        </Group>

        <Divider my="sm" />
      </Stack>
    )
  })

  return (
    <Stack>
      {fields}
      <AddGridButton
        onClick={() => form.insertListItem('pricingGrid', {
          pricingGridType: '',
          levels: []
        })}
        disabled={form.values.pricingGrid.length >= (deal?.pricingGrid?.length ?? 0)}
      />
    </Stack>
  )
}
export default React.forwardRef(facilityPricingGrid)