import { WireInstructionParams } from 'app/models/wire-instruction-params'
import { WireInstructionState } from './reducer'  

export const getWireInstructions = (state: {
  wireInstructions: { wireInstructions: WireInstructionParams[] }
}) => {
  return state.wireInstructions.wireInstructions
}

export const getWireInstruction = (state: {
  wireInstructions: { wireInstruction: WireInstructionParams }
}) => {
  return state.wireInstructions.wireInstruction
}

export const getWireInstructionsLoader = (state: { wireInstructions: WireInstructionState }) => {
  return state.wireInstructions.isLoading
}

