import { useEffect, useState } from 'react'
import { Group, Stack, Text, Tooltip } from '@mantine/core'
import { ReactComponent as PDF_ICON } from 'app/styles/pdf-icon.svg'
import { ReactComponent as DOC_ICON } from 'app/styles/doc-icon.svg'
import { Document } from 'app/models/document-params'
import { ReactComponent as XLS_ICON } from 'app/styles/xls-icon.svg'
import {
  DealDocumentTags,
  EntityDocumentTags,
} from 'app/models/dropdown-options'
import FileActions from './file-actions'
import { useDispatch } from 'react-redux'
import { getDocumentFromS3 } from 'app/state/ducks/documents/thunks'
import { ContractType } from 'app/models/common-types'

interface Props {
  file: Document
}

export default function File_Card({ file }: Props) {
  const posibleTags =
    file.contractType == ContractType.Entities
      ? EntityDocumentTags
      : DealDocumentTags
  const [fileUrl, setFileUrl] = useState<string>('')
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDocumentFromS3(file.id, file.mimeType)
        setFileUrl(response?.url)
      } catch (error) {
        console.error('Error fetching presigned URL', error)
      }
    }

    fetchData()
  }, [dispatch, file.name])

  function openFile(): void {
    window.open(fileUrl, '_blank')
  }

  return (
    <>
      <Tooltip label={file.name ?? ''}>
        <Group
          px="md"
          m="1.5%"
          py="sm"
          w="47%"
          noWrap
          bg="white"
          sx={{
            border: '1px solid #6B7280',
            borderRadius: '6px',
          }}
        >
          <Group w="25%" onClick={openFile}>
            {['PDF', 'UnknownType'].includes(file.mimeType) && <PDF_ICON />}
            {['DOC', 'DOCX'].includes(file.mimeType) && <DOC_ICON />}
            {['CSV', 'XLS', 'XLSX'].includes(file.mimeType) && <XLS_ICON />}
          </Group>
          <Stack
            justify="center"
            h="100%"
            spacing={0}
            w="65%"
            onClick={openFile}
          >
            <Text p={0} truncate>
              {file.name}
            </Text>
            <Text color="#6B7280" p={0}>
              {file.mimeType} +{' '}
              {posibleTags.find(tag => tag.value == file.tag)?.label ??
                'Untagged'}
            </Text>
          </Stack>
          <FileActions file={file} />
        </Group>
      </Tooltip>
    </>
  )
}
