import {
    Group,
    Stack,
} from '@mantine/core'
import CreateDealButton from './deals-management-buttons/create-deal-btn'
import SegmentControlUpdated from 'app/views/components/segments/segment-control-updated'
import DealManagmentPage from '../deals/deals-management'
import FacilitiesContentPage from '../facilities/facilities-content-page'
import CreateFacilityButton from './deals-management-buttons/create-facility-btn'
import HistoryToggleButton from 'app/views/components/buttons/HistoryToggleButton'


interface props {
    tab: string
}

export function DealDrillPage({ tab }: props) {
    const tabs: { [key in props['tab']]: { route: string; label: string; active: boolean; pageRender: JSX.Element; createButton: JSX.Element; historyLink: string; } } = {
        deals: { route: `/dealmanagement`, label: 'DEALS', active: tab === 'deals' ? true : false, pageRender: <DealManagmentPage />, createButton: <CreateDealButton />, historyLink: '/dealmanagement/terminated' },
        facilities: { route: `/dealmanagement/facilities`, label: 'FACILITIES', active: tab === 'facilities' ? true : false, pageRender: <FacilitiesContentPage />, createButton: <CreateFacilityButton />, historyLink: '/dealmanagement/facilities/terminated' },
    }
    return (
        <Stack w="100%">
            <Group w="100%" position="apart" className='tabsGapsActivity'>
                <Group position="right">
                    <SegmentControlUpdated tabs={tabs} />
                </Group>
                <Group noWrap>
                    <HistoryToggleButton navLink={tabs[tab].historyLink} />
                    {tabs[tab].createButton}
                </Group>
            </Group>
            {tabs[tab].pageRender}
        </Stack>
    )
}