import { MRT_ColumnDef } from 'mantine-react-table'
import { Text } from '@mantine/core'
import { ReactNode } from 'react'
import { JobParams } from 'app/models/job-params'

export type TableData = JobParams & {
  setJobLogs: any
}

function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}

export const jobsComponentCols: MRT_ColumnDef<TableData>[] = [
  {
    accessorKey: 'Job',
    header: 'Job',
    Header: <TableHead header={'Job #'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    accessorKey: 'Log',
    header: 'Log',
    Header: <TableHead header={'Log #'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    accessorKey: 'executionDate',
    header: 'Execution Date',
    Header: <TableHead header={'Execution Date'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    accessorKey: 'schedule',
    header: 'Schedule',
    Header: <TableHead header={'Schedule'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    accessorKey: 'view',
    header: 'View',
    Header: <TableHead header={'View'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  }
]
