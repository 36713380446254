import { Accordion, Group, Stack } from '@mantine/core'
import { ContractType } from 'app/models/common-types'
import { AttachmentsAccordion } from '../Accordion/attachments-accordion/attachments-accordion-full'
import { NotesAccordion } from '../Accordion/notes-accordion-full'

type Props = {
  itemId: string,
  type: ContractType
  itemAdmin: string
}

export default function NotesAndAttachment({ itemId, itemAdmin, type }: Props) {
  return (
    <Accordion
      defaultValue={['pdfs', 'notes']}
      multiple
      variant="contained"
      bg="white"
      w="100%"
      h="200px"
      styles={{
        control: {
          fontFamily: 'Plus Jakarta Sans',
          fontSize: '24px', // Set font size here
          fontWeight: 600,  // Set font weight here
          '&[data-active]': {
            backgroundColor: '#95A4FD',
          },
          '&:hover': {
            backgroundColor: '#95A4FD',
          },
        },
      }}
    >
      <Group w="100%" noWrap align="flex-start">
        {/* Attachments Accordion */}
        <Stack w="50%">
          <AttachmentsAccordion id={itemId} type={type} />
        </Stack>

        {/* Notes Accordion */}
        <Stack w="50%">
          <NotesAccordion contractId={itemId} contractAdmin={itemAdmin} type={type} fullSize={false} />
        </Stack>
      </Group>
    </Accordion>
  )
}