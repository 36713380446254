import { fetch } from 'app/state/utils'

const baseUrl =
  typeof document === 'undefined' ? 'http://localhost:7777/api' : '/api'

const apiService =
  () =>
  (next: (arg0: any) => any) =>
  (action: {
    meta: { async?: any; path?: any; method?: any; body?: any }
    type: any
  }) => {
    const result = next(action)
    if (!action.meta || !action.meta.async) {
      return result
    }

    const { path, method = 'GET', body } = action.meta

    if (!path) {
      throw new Error(`'path' not specified for async action ${action.type}`)
    }

    const url = `${baseUrl}${path}`

    return fetch(url, method, body).then(
      (res: any) => handleResponse(res, action, next),
      (err: any) => handleErrors(err, action, next)
    )
  }

export default apiService

function handleErrors(
  err: any,
  action: { type: any; meta: any },
  next: (arg0: { type: string; payload: any; meta: any }) => void
) {
  next({
    type: `${action.type}_FAILED`,
    payload: err,
    meta: action.meta,
  })

  return Promise.reject(err)
}

function handleResponse(
  res: any,
  action: { type: any; meta: any },
  next: (arg0: { type: string; payload: any; meta: any }) => void
) {
  next({
    type: `${action.type}_COMPLETED`,
    payload: res,
    meta: action.meta,
  })

  return res
}
