import { AmortizationScheduleParams, SchedulerList } from "app/models/amortitation-schedule"
import { Dispatch } from "react"
import { ErrorNotification, SuccessNotification } from "../notifications/notification"
import { changeStatusForAmortizationRow, removeAmortizationRow } from "app/state/ducks/amortization/thunks"
import { loadAllFacilityAmendments } from "app/state/ducks/facility-amendments/thunks"

export async function changeStatus(
    amortization: AmortizationScheduleParams,
    amortizationRow: SchedulerList,
    status: string,
    dispatch: Dispatch<any>,
) {
    const response: any = await dispatch(changeStatusForAmortizationRow(amortization.facilityId, amortizationRow.paymentDate, status))
    if (response.success) {
        SuccessNotification({
            title: 'Successful Update Status',
            message: 'Row has been Submitted for Updated',
        })
    } else {
        ErrorNotification({
            title: 'Status Update Failed',
            message: response.payload || 'Submission Failed',
        })
        return false
    }
    dispatch(loadAllFacilityAmendments())
    return true
}


export async function deleteRow(
    amortization: AmortizationScheduleParams,
    amortizationRow: SchedulerList,
    dispatch: Dispatch<any>,
) {
    const response: any = await dispatch(removeAmortizationRow(amortization.facilityId, amortizationRow.paymentDate))
    if (response.success) {
        SuccessNotification({
            title: 'Successful Row Deleted',
            message: 'Row has been Deleted',
        })
    } else {
        ErrorNotification({
            title: 'Delete Row Failed',
            message: response.payload || 'Submission Failed',
        })
        return false
    }
    dispatch(loadAllFacilityAmendments())
    return true
}