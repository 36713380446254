import { useForm } from '@mantine/form'
import { useDispatch, useSelector } from 'react-redux'
import { getFacility } from 'app/state/ducks/facilities/selectors'
import { useEffect } from 'react'
import { LoanParams } from 'app/models/loan-params'
import {
} from 'app/models/dropdown-options'
import {
  formatDateToUTC,
  stringToDate
} from 'app/utils/util-functions'
import {
  loadAllLoanIncreases,
  saveLoanIncrease,
} from 'app/state/ducks/loan-increases/thunks'
import { getLoanIncreases } from 'app/state/ducks/loan-increases/selectors'
import { LoanIncreaseParams } from 'app/models/loan-increase-params'
import config from 'app/config/config'
import axios from 'axios'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import IncreaseFormPresentation from './increase-form-presentation'

type Props = {
  close: () => void
  loan: LoanParams
}

export default function IncreaseFormLogic({ close, loan }: Props) {
  const dispatch = useDispatch()

  const facility = useSelector(getFacility)
  const loanIncrease = useSelector(getLoanIncreases)
  const businessDate = useSelector(getBusinessDate)


  const increase = loanIncrease.find(li => li?.loanId?.id === loan?.id) ?? undefined

  const form = useForm({
    initialValues: {
      id: increase?.id ?? undefined,
      loanId: { admin: loan.agencyAdmin, id: loan.id ?? '' },
      increaseAmount: 0.0,
      effectiveDate: stringToDate(increase?.effectiveDate || ''),
      status: increase?.status ?? 'Draft',
      hasError: false,
      ErrorMessage: '',
      approveRate: false,
      hasSuccessfulEntitySave: false,
      customer_token: '',
    },
    transformValues: values => {
      return {
        ...values,
      }
    },
    validate: {
      increaseAmount: (value: number) => {
        return value > 0 ? null : 'Invalid amount'
      },
      effectiveDate: (value: Date) => {
        return value ? null : 'Effective Date is required'
      },
      status: (value: string) => {
        return value === 'Draft' ? null : 'Has already been submitted'
      }
    },
  })

  
  useEffect(() => {
    if (increase) {
      const formIncrease = {
        ...increase,
        increaseAmount: Number(increase.amount),
        effectiveDate: stringToDate(increase.effectiveDate),
      }
      form.setValues(formIncrease)
    } else {
      const cleanForm = {
        id: undefined,
        loanId: { admin: loan.agencyAdmin, id: loan.id ?? '' },
        increaseAmount: 0.0,
        effectiveDate: businessDate,
        status: 'Draft',
      }
      form.setValues(cleanForm)
    }
  }, [increase, businessDate])

  const createDraftIncrease = async () => {
    try {
     
      const loanIncreaseVals: LoanIncreaseParams = {
        ...form.values,
        amount: form.values.increaseAmount,
        effectiveDate: formatDateToUTC(form.values.effectiveDate),
        hasError: false,
        hasSuccessfulEntitySave: false,
        ErrorMessage: '',
        customer_token: config.company.toUpperCase(),
      }
      const response: any = await dispatch(
        saveLoanIncrease(loanIncreaseVals, increase)
      )
      if (response.success) {
        SuccessNotification({
          title: 'Successfully Saved Draft Loan Increase',
          message: 'You created or edited a Loan Increase',
        })
        dispatch(loadAllLoanIncreases(loan?.id))
      } else {
        ErrorNotification({
          title: 'Failed to Create Loan Increase',
          message: response.payload ?? 'Please check inputs',
        })
      }
      return response
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status == 400) {
        const data = error.response.data
        ErrorNotification({
          title: 'Failed to Create Loan Increase',
          message: data?.error ?? 'Please check inputs',
        })
        return null
      } else {
        ErrorNotification({
          title: 'Failed to Create Loan Increase',
          message: 'Please check inputs',
        })
        return null
      }
    } finally {
      form.reset()
    }
  }


  return (
    <IncreaseFormPresentation close={close} form={form} facility={facility} loan={loan} increase={increase} createDraftIncrease={createDraftIncrease} />
  )
}