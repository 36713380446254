import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import {
  submitNotice,
  approveNotice,
  rejectNotice,
  getNotice,
  getAllLockedNotices,
  getAllActiveNotices,
  startLoadingActiveNotices,
  stopLoadingActiveNotices,
  startLoadingLockedNotices,
  stopLoadingLockedNotices,
} from 'app/state/ducks/notices/actions'
import { NoticeParams } from 'app/models/notices-params'

export const loadOneNotice =
  (noticeId: string, status: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    const statusLower = status.toLowerCase()
    return client
      .get(`${apiServerUrl}/api/${statusLower}-notices/one?id=${noticeId}`)
      .then(notice => {
        if (statusLower === 'submitted') {
          return dispatch(getNotice({ ...notice.data.notice, status: status }))
        }
        return dispatch(getNotice({ ...notice.data, status: status }))
      }).catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }


export const preview =
  async (noticeId: string, status: string) => {
    const apiServerUrl = config.backendUrl
    const statusLower = status.toLowerCase()
    return client
      .get(`${apiServerUrl}/api/${statusLower}-notices/preview?id=${noticeId}`)
      .then(notice => {
        return { success: true, payload: notice.data }
      }).catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const loadAllDraftNotices = async () => {
  const apiServerUrl = config.backendUrl
  try {
    const response = await client.get(`${apiServerUrl}/api/draft-notices`)
    return response.data
  } catch (e) {
    console.error(e)
    return []
  }
}

export const loadAllApprovedNotices = async () => {
  const apiServerUrl = config.backendUrl
  try {
    const response = await client.get(`${apiServerUrl}/api/approved-notices`)
    return response.data
  } catch (e) {
    console.error(e)
    return []
  }
}

export const loadAllSubmittedNotices = async () => {
  const apiServerUrl = config.backendUrl
  try {
    const response = await client.get(`${apiServerUrl}/api/submitted-notices`)
    return response.data
  } catch (e) {
    console.error(e)
    return []
  }
}

export const loadActiveNotices = () => async (dispatch: Dispatch) => {
  try {
    dispatch(startLoadingActiveNotices())

    const [allDraftNotices, submittedNotices] = await Promise.all([
      loadAllDraftNotices(),
      loadAllSubmittedNotices()
    ])

    const allSubmittedNotices = submittedNotices.map((n: { notice: any }) => n.notice)

    const allActiveNotices = allDraftNotices
      .map((notice: NoticeParams) => ({ ...notice, status: 'Draft' }))
      .concat(allSubmittedNotices.map((notice: NoticeParams) => ({ ...notice, status: 'Submitted' })))

    return dispatch(getAllActiveNotices(allActiveNotices))
  } catch (e) {
    console.error(e)
    return []
  } finally {
    dispatch(stopLoadingActiveNotices())
  }
}


export const loadLockedNotices = () => async (dispatch: Dispatch) => {
  try {
    dispatch(startLoadingLockedNotices())
    const lockedNotices = await loadAllApprovedNotices()
    const allLockedNotices = lockedNotices.map((notice: NoticeParams) => ({ ...notice, status: 'Approved' }))
    return dispatch(getAllLockedNotices(allLockedNotices))
  } catch (e) {
    console.error(e)
    return []
  } finally {
    dispatch(stopLoadingLockedNotices())
  }
}

export const submitForApproval = (notice: NoticeParams) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .post(`${apiServerUrl}/api/draft-notices/submit`, { id: notice.id })
    .then(response => {
      const payload = dispatch(submitNotice(response.data))
      return { success: true, payload: payload.payload }
    }).catch(e => {
      return { success: false, payload: e.response.data.error }
    })
}

export const approveSelectedNotice =
  (notice: any) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    console.log('approve - notice', notice)
    return client
      .post(`${apiServerUrl}/api/submitted-notices/approve`, { id: notice.id })
      .then(response => {
        dispatch(approveNotice(response.data))
        return { success: true }
      }).catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const rejectSelectedNotice = (notice: NoticeParams) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .post(`${apiServerUrl}/api/submitted-notices/reject`, { id: notice.id })
    .then(response => {
      const payload = dispatch(rejectNotice(response.data))
      return { success: true, payload: payload.payload }
    }).catch(e => {
      return { success: false, payload: e.response.data.error }
    })
}