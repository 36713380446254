import { NoticeParams } from 'app/models/notices-params'
import * as types from './types'
import { createReducer } from 'app/state/utils'

export interface NoticeState {
  lockedNotices: NoticeParams[]
  activeNotices: NoticeParams[]
  notice?: NoticeParams
  isLoadingActiveNotices: boolean,
  isLoadingLockedNotices: boolean,
}
const initialState: NoticeState = {
  lockedNotices: [],
  activeNotices: [],
  notice: undefined,
  isLoadingActiveNotices: false,
  isLoadingLockedNotices: false,
}
const noticesReducer = createReducer(initialState)({
  [types.GET_ALL_ACTIVE_NOTICES]: (state: NoticeState, payload: any) => {
    return { ...state, activeNotices: payload.payload }
  },
  [types.GET_ALL_LOCKED_NOTICES]: (state: NoticeState, payload: any) => {
    return { ...state, lockedNotices: payload.payload }
  },
  [types.GET_NOTICE]: (state: NoticeState, payload: any) => {
    return { ...state, notice: payload.payload }
  },
  [types.SUBMIT_NOTICE]: (state: NoticeState, payload: any) => {
    if (payload.payload.error) {
      return {
        ...state
      }
    }
    const updatedActiveNotices = [...state.activeNotices].map((notice) =>
      notice.id === payload.payload.id ? { ...notice, status: 'Submitted' } : notice
    )
    return {
      ...state,
      activeNotices: updatedActiveNotices,
    }
  },

  [types.APPROVE_NOTICE]: (state: NoticeState, payload: any) => {
    if (payload.payload.error) {
      return {
        ...state,
      }
    }
     
    // Move approved notice from activeNotices to lockedNotices
    const updatedActiveNotices = state.activeNotices.filter(
      notice => notice.id !== payload.payload.id
    )
    const approvedNotice = state.activeNotices.find(
      notice => notice.id === payload.payload.id
    )
    const updatedLockedNotices = approvedNotice
    ? [...state.lockedNotices, { ...approvedNotice, status: 'Approved' }]
    : state.lockedNotices

    
    return {
      ...state,
      activeNotices: updatedActiveNotices,
      lockedNotices: updatedLockedNotices,
    }
  },

  [types.REJECT_NOTICE]: (state: NoticeState, payload: any) => {
    if (payload.payload.error) {
      return {
        ...state,
      }
    }
    const updatedActiveNotices = state.activeNotices.map((notice) =>
      notice.id === payload.payload.id ? { ...notice, status: 'Draft' } : notice
    )
    return {
      ...state,
      activeNotices: updatedActiveNotices,
    }
  },
  [types.START_LOADING_ACTIVE_NOTICES]: (state: NoticeState) => {
    return { ...state, isLoadingActiveNotices: true }
  },
  [types.STOP_LOADING_ACTIVE_NOTICES]: (state: NoticeState) => {
    return { ...state, isLoadingActiveNotices: false }
  },
  [types.START_LOADING_LOCKED_NOTICES]: (state: NoticeState) => {
    return { ...state, isLoadingLockedNotices: true }
  },
  [types.STOP_LOADING_LOCKED_NOTICES]: (state: NoticeState) => {
    return { ...state, isLoadingLockedNotices: false }
  },
})

export default noticesReducer
