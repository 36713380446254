import React from 'react'

const LenderTableHeader: React.FC = () => (
  <thead style={{ backgroundColor: '#001565', minHeight: '40px' }}>
    <tr>
      <th style={{ textAlign: 'center', color: 'white' }}>Lender</th>
      <th style={{ textAlign: 'center', color: 'white' }}>MEI</th>
      <th style={{ textAlign: 'center', color: 'white' }}>Fund Manager</th>
      <th style={{ textAlign: 'center', color: 'white' }}>Allocation</th>
      <th style={{ textAlign: 'center', color: 'white' }}>Wire Instructions</th>
      <th style={{ textAlign: 'center', color: 'white' }}>Contacts</th>
    </tr>
  </thead>
)

export default LenderTableHeader
