import * as types from './types'

export const addNote = (payload: any) => ({
  type: types.ADD_NOTE,
  payload,
})
export const getNoteByContract = (payload: any) => ({
  type: types.GET_NOTE_BY_CONTRACT,
  payload,
})
export const editNote = (payload: any) => ({
  type: types.EDIT_NOTE,
  payload,
})

export const setNull = (payload: any) => ({
  type: types.SET_NULL,
  payload,
})
