import { KpiProps } from 'app/views/components/KPI/KPI';

export const myworkKPIData = (
  data: { activityCount: number; percentageComplete: string; deadlineApproaching: string, totalActivityAmount: string },
): KpiProps[] => {
  if (!data) return [];

  return [
    {
      title: 'Activity Count',
      amount: data.activityCount,
      activeFilter: false,
    },
    {
      title: 'Percentage Complete',
      amount: data.percentageComplete !== 'N/A' ? data.percentageComplete + '%' : 'N/A',
      activeFilter: false,
    },
    {
      title: 'Deadline Approaching',
      amount: data.deadlineApproaching,
      activeFilter: false,
    },
    {
      title: 'Total Activity Amount',
      amount: data.totalActivityAmount,
      activeFilter: false,
    },
  ];
};
