import { MRT_ColumnDef } from 'mantine-react-table'
import { Text } from '@mantine/core'
import { ReactNode } from 'react'
import { LendersPositionWithMapping } from 'app/models/lenders-position-params'



function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}

export const colsEntitiesPositions: MRT_ColumnDef<LendersPositionWithMapping>[] = [
  {
    size: 5,
    accessorKey: 'entityName',
    header: 'Entity Name',
    Header: <TableHead header={'Entity Name'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 5,
    accessorKey: 'entityMei',
    header: 'Entity MEI',
    Header: <TableHead header={'Entity MEI'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 5,
    accessorKey: 'dealName',
    header: 'Deal Name',
    Header: <TableHead header={'Deal Name'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 5,
    accessorKey: 'dealCusip',
    header: 'Deal CUSIP',
    Header: <TableHead header={'Deal CUSIP'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 5,
    accessorKey: 'facilityName',
    header: 'Facility Name',
    Header: <TableHead header={'Facility Name'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 5,
    accessorKey: 'facilityCusip',
    header: 'Facility CUSIP',
    Header: <TableHead header={'Facility CUSIP'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 5,
    accessorKey: 'internalName',
    header: 'Internal Name',
    Header: <TableHead header={'Internal Name'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 5,
    accessorKey: 'agentName',
    header: 'Agent Name',
    Header: <TableHead header={'Agent Name'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 5,
    accessorKey: 'agentMei',
    header: 'Agent MEI',
    Header: <TableHead header={'Agent MEI'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 5,
    accessorKey: 'opsAdmin',
    header: 'Ops Admin',
    Header: <TableHead header={'Ops Admin'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 5,
    accessorKey: 'currency',
    header: 'Currency',
    Header: <TableHead header={'Currency'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 5,
    accessorKey: 'settledDateAmount',
    header: 'SD Position',
    Header: <TableHead header={'SD Position'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 5,
    accessorKey: 'tdDate',
    header: 'TD Position',
    Header: <TableHead header={'TD Position'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
]