import { Group } from '@mantine/core'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'


export default function ServicingHeader() {

  return (
    <Group position="apart">
      <PageTitle pageTitle='Servicing'></PageTitle>
    </Group >
  )
}