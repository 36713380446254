export const GET_FACILITY_AMENDMENTS = 'deal_amendment/GET_FACILITY_AMENDMENTS'
export const GET_FACILITY_AMENDMENT = 'deal_amendment/GET_FACILITY_AMENDMENT'
export const ADD_FACILITY_AMENDMENTS = 'deal_amendment/ADD_FACILITY_AMENDMENTS'
export const EDIT_FACILITY_AMENDMENTS =
  'deal_amendment/EDIT_FACILITY_AMENDMENTS'
export const SUBMIT_FACILITY_AMENDMENT =
  'deal_amendment/SUBMIT_FACILITY_AMENDMENT'
export const REJECT_FACILITY_AMENDMENT =
  'deal_amendment/REJECT_FACILITY_AMENDMENT'
export const APPROVE_FACILITY_AMENDMENT =
  'deal_amendment/APPROVE_FACILITY_AMENDMENT'
export const DELETE_FACILITY_AMENDMENT =
  'deal_amendment/DELETE_FACILITY_AMENDMENT'
export const NULL_FACILITY_AMENDMENT = 'deal_amendment/NULL_FACILITY_AMENDMENT'
export const CHANGE_FACILITY_FEES_AMENDMENT = 'deal_amendment/CHANGE_FACILITY_FEES_AMENDMENT'
export const CHANGE_FACILITY_AMORTIZATION_AMENDMENT = 'deal_amendment/CHANGE_FACILITY_AMORTIZATION_AMENDMENT'
export const START_LOADING_FACILITY_AMENDMENTS = 'deal_amendment/START_LOADING_FACILITY_AMENDMENTS'
export const STOP_LOADING_FACILITY_AMENDMENTS = 'deal_amendment/STOP_LOADING_FACILITY_AMENDMENTS'
