import { Menu } from '@mantine/core'
import PrimaryDropDown from 'app/views/components/DropDown/DropDown'
import { useDispatch, useSelector } from 'react-redux'
import { PrincipalPaymentParams } from 'app/models/principal-payments-params'
import {
  approvePrincipalPayment,
  deletePrincipalPayment,
  rejectPrincipalPayment,
  submitPrincipalPayment,
} from './principal-payment-daml-actions'
import { LoanParams } from 'app/models/loan-params'
import { useEffect, useState } from 'react'
import PrincipalPaymentModal from './principal-payment-modal'
import { getAllApprovedLoans } from 'app/state/ducks/loans/selectors'
import { loadAllApprovedLoans } from 'app/state/ducks/loans/thunks'
import IonIcon from '@reacticons/ionicons'

type Props = {
  row: PrincipalPaymentParams
}

export default function LoanPrincipalPaymentActions({ row }: Props) {
  const allLoans = useSelector(getAllApprovedLoans)
  const [loan, setLoan] = useState<LoanParams>()
  const [openEdit, setOpenEdit] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadAllApprovedLoans())
  }, [])

  function editPrincipal(): any {
    setLoan(allLoans.find(l => l.id === row.loanId.id))
    setOpenEdit(true)
  }

  return (
    allLoans && (
      <div>
        <Menu shadow="md" width={200} position="bottom" withinPortal>
          <Menu.Target>
            <div style={{ width: 'fit-content' }}>
              <PrimaryDropDown>Actions</PrimaryDropDown>
            </div>
          </Menu.Target>

          <Menu.Dropdown>
            {row?.status?.toLowerCase() === 'draft' ? (
              <>
                <Menu.Item
                  icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
                  onClick={() => {
                    submitPrincipalPayment(row, dispatch)
                  }}
                >
                  Submit For Approval
                </Menu.Item>
                <Menu.Item
                  icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
                  onClick={() => editPrincipal()}
                >
                  Edit
                </Menu.Item>
              </>
            ) : null}

            {row?.status?.toLowerCase() === 'submitted' ? (
              <>
                <Menu.Item
                  icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
                  onClick={() => {
                    approvePrincipalPayment(row, dispatch)
                  }}
                >
                  Approve
                </Menu.Item>
                <Menu.Item
                  icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
                  onClick={() => editPrincipal()}
                >
                  Preview
                </Menu.Item>
              </>
            ) : null}

            {row?.status?.toLowerCase() !== 'approved' ? (
              <>
                <Menu.Divider />
                <Menu.Label>Danger zone</Menu.Label>
              </>
            ) : null}

            {row?.status?.toLowerCase() === 'draft' ? (
              <Menu.Item
                color="red"
                icon={<IonIcon className="action-ion-icon" name='trash-outline' />}
                onClick={() => {
                  deletePrincipalPayment(row, dispatch)
                }}
              >
                Delete
              </Menu.Item>
            ) : null}

            {row?.status?.toLowerCase() === 'submitted' ? (
              <Menu.Item
                color="red"
                icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
                onClick={() => {
                  rejectPrincipalPayment(row, dispatch)
                }}
              >
                Reject
              </Menu.Item>
            ) : null}
          </Menu.Dropdown>
        </Menu>
        {loan && (
          <PrincipalPaymentModal
            loan={loan}
            setOpen={setOpenEdit}
            open={openEdit}
          />
        )}
      </div>
    )
  )
}
