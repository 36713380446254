import { Tooltip, Avatar } from '@mantine/core'
import React from 'react'
import getInitials from 'app/views/components/functions/getInitials'
import { LenderRowProps } from '../params'
import { formatNumberToCurrency } from 'app/utils/util-functions'

const LenderRow: React.FC<LenderRowProps> = ({
  lender,
  entities,
  pulledWireInstructions,
  contacts,
  currency,
}) => {
  const lenderId = lender.lender.id
  const entity = entities?.find(item => item.id === lenderId)

  const lenderName = entity?.entityName ?? ''
  const lenderMei = entity?.mei ?? ''

  const lenderWireInstructionIds = lender.wireInstructions?.map(
    instruction => instruction.id
  )
  const filteredWireInstructions = pulledWireInstructions.filter(instruction =>
    lenderWireInstructionIds.includes(instruction.id)
  )
  const currencies = filteredWireInstructions.map(
    instruction => instruction.currency
  )

  const fundManager = entities?.find(item => item.id === lenderId)?.fundManager
  const parent = entities?.find(item => item.id === lenderId)?.parent

  const adminContactId = lender.adminContactId?.id ?? ''
  const creditContactId = lender.creditContactId?.id ?? ''
  const adminContact =
    contacts?.find(item => item.id === adminContactId)?.name ?? ''
  const creditContact =
    contacts?.find(item => item.id === creditContactId)?.name ?? ''

  const formattedAmount = formatNumberToCurrency(Number(lender.amount), currency)

  return (
    <tr key={lenderId}>
      <td>{lenderName}</td>
      <td>{lenderMei}</td>
      <td>
        {fundManager} {parent}
      </td>
      <td style={{ textAlign: 'end' }}>{formattedAmount}</td>
      <td style={{ textAlign: 'center' }}>{currencies.join(', ')}</td>
      <td style={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip label={adminContact} withArrow>
          <Avatar color="violet" radius="xl" style={{ marginTop: '10px' }}>
            {getInitials(adminContact)}
          </Avatar>
        </Tooltip>
        <Tooltip label={creditContact} withArrow>
          <Avatar
            color="violet"
            radius="xl"
            style={{ marginLeft: '5px', marginTop: '10px' }}
          >
            {getInitials(creditContact)}
          </Avatar>
        </Tooltip>
      </td>
    </tr>
  )
}

export default LenderRow
