import { FacilityParams } from 'app/models/facility-params'
import * as types from './types'
import { createReducer } from 'app/state/utils'

export interface FacilityAmendmentState {
  facilityAmendments: FacilityParams[]
  facilityAmendment: FacilityParams | null
  isLoading: boolean
}

const initialState: FacilityAmendmentState = {
  facilityAmendments: [],
  facilityAmendment: null,
  isLoading: false,
}

const facilityAmendmentsReducer = createReducer(initialState)({
  [types.GET_FACILITY_AMENDMENTS]: (state: any, payload: any) => {
    return { ...state, facilityAmendments: payload.payload }
  },
  [types.GET_FACILITY_AMENDMENT]: (state: any, payload: any) => {
    return { ...state, facilityAmendment: payload.payload }
  },
  [types.NULL_FACILITY_AMENDMENT]: (state: any) => {
    return { ...state, facilityAmendment: null }
  },
  [types.CHANGE_FACILITY_FEES_AMENDMENT]: (state: any, payload: any) => {
    const index = state.facilityAmendments.findIndex(
      (facilityAmendment: { id: any }) =>
        facilityAmendment.id === payload.payload.id
    )
    const newArray = [...state.facilityAmendments]

    if(index === -1){
      newArray.push({...payload.payload, status: 'Draft'})
    } else{
      newArray[index] = { ...payload.payload, status: 'Draft' }
    }
    return {
      ...state,
      facilityAmendments: newArray,
      facilityAmendment: { ...payload.payload, status: 'Draft' },
    }
  },
  [types.CHANGE_FACILITY_AMORTIZATION_AMENDMENT]: (state: any, payload: any) => {
    const index = state.facilityAmendments.findIndex(
      (facilityAmendment: { id: any }) =>
        facilityAmendment.id === payload.payload.id
    )
    const newArray = [...state.facilityAmendments]

    if(index === -1){
      newArray.push({...payload.payload, status: 'Draft'})
    } else{
      newArray[index] = { ...payload.payload, status: 'Draft' }
    }
    return {
      ...state,
      facilityAmendments: newArray,
      facilityAmendment: { ...payload.payload, status: 'Draft' },
    }
  },
  [types.ADD_FACILITY_AMENDMENTS]: (state: any, payload: any) => {
    return {
      ...state,
      facilityAmendments: [
        { ...payload.payload, status: 'Draft' },
        ...state.facilityAmendments,
      ],
      facilityAmendment: { ...payload.payload, status: 'Draft' },
    }
  },
  [types.EDIT_FACILITY_AMENDMENTS]: (state: FacilityAmendmentState, payload: any) => {
    const index = state.facilityAmendments.findIndex(
      (facilityAmendment: { id: any }) =>
        facilityAmendment.id === payload.payload.id
    )
    const newArray = [...state.facilityAmendments]
    newArray[index] = { ...payload.payload, status: 'Draft' }
    return {
      ...state,
      facilityAmendments: newArray,
      facilityAmendment: { ...payload.payload, status: 'Draft' },
    }
  },
  [types.SUBMIT_FACILITY_AMENDMENT]: (state: FacilityAmendmentState, payload: any) => {
    if (payload.payload.error) {
      return state
    }
    const index = state.facilityAmendments.findIndex(
      (facilityAmendment: { id: any }) => facilityAmendment.id === payload.payload.id,
    )
    const newArray = [...state.facilityAmendments]
    newArray[index] = { ...newArray[index], status: 'Submitted' }
    if (state?.facilityAmendment?.id === payload.payload.id) {
      return {
        ...state,
        facilityAmendment: { ...state.facilityAmendment, status: 'Submitted' },
        facilityAmendments: newArray,
      }
    }
    return {
      ...state,
      facilityAmendments: newArray,
    }
  },
  [types.REJECT_FACILITY_AMENDMENT]: (state: FacilityAmendmentState, payload: any) => {
    if (payload.payload.error) {
      return state
    }
    const index = state.facilityAmendments.findIndex(
      (facilityAmendment: { id: any }) => facilityAmendment.id === payload.payload.id,
    )
    const newArray = [...state.facilityAmendments]
    newArray[index] = { ...newArray[index], status: 'Draft' }
    if (state?.facilityAmendment?.id === payload?.payload?.id) {
      return {
        ...state,
        facilityAmendment: { ...state.facilityAmendment, status: 'Draft' },
        facilityAmendments: newArray,
      }
    }
    return {
      ...state,
      facilityAmendments: newArray,
    }
  },
  [types.APPROVE_FACILITY_AMENDMENT]: (state: FacilityAmendmentState, payload: any) => {
    const newFacilityAmendment = state.facilityAmendment?.id === payload.payload.id ? null : state.facilityAmendment
    const index = state.facilityAmendments.findIndex(
      (facilityAmendment: { id: any }) =>
        facilityAmendment.id === payload.payload.id
    )
    const newArray = [...state.facilityAmendments]
    newArray.splice(index, 1)
    return {
      ...state,
      facilityAmendments: newArray,
      facilityAmendment: newFacilityAmendment,
    }
  },
  [types.DELETE_FACILITY_AMENDMENT]: (state: FacilityAmendmentState, payload: any) => {
    if (!payload.payload || payload.payload.length === 0) {
      return state
    }
    const deletedIds = payload.payload
      .filter((payload: any) => !payload.error)
      .map((payload: any) => payload.id)
    return {
      ...state,
      facilityAmendments: state.facilityAmendments.filter(
        (facilityAmendment: any) => !deletedIds.includes(facilityAmendment.id),
      ),
      facilityAmendment:
        state.facilityAmendment && deletedIds.includes(state.facilityAmendment.id)
          ? null
          : state.facilityAmendment,
    }
  },
  [types.START_LOADING_FACILITY_AMENDMENTS]: (state: FacilityAmendmentState) => {
    return { ...state, isLoading: true }
  },
  [types.STOP_LOADING_FACILITY_AMENDMENTS]: (state: FacilityAmendmentState) => {
    return { ...state, isLoading: false }
  },
})

export default facilityAmendmentsReducer
