import { MRT_ColumnDef } from 'mantine-react-table'
import { Text } from '@mantine/core'
import { ReactNode } from 'react'

export type CleanInterestPaymentSummaryParams = {
  'Start Date': string,
  'End Date': string,
  'Loan Amount': string,
  'All-In Rate': string,
  'Days': number,
  'Interest Amount': string
}

function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}

export function getColumnDefs(): MRT_ColumnDef<CleanInterestPaymentSummaryParams>[] {

  return [
    {
      size: 50,
      accessorKey: 'Start Date',
      header: 'Start Date',
      Header: <TableHead header={'Start Date'} />,
      mantineFilterTextInputProps: {
        placeholder: 'Date',
      },
      filterFn: 'equals',
      filterVariant: 'date',
      sortingFn: 'datetime',
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,

    },

    {
      size: 50,
      accessorKey: 'End Date',
      header: 'End Date',
      Header: <TableHead header={'End Date'} />,
      mantineFilterTextInputProps: {
        placeholder: 'Date',
      },
      filterFn: 'equals',
      filterVariant: 'date',
      sortingFn: 'datetime',
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,

    },
    {
      size: 50,
      accessorKey: 'Loan Amount',
      header: 'Loan Amount',
      Header: <TableHead header={'Loan Amount'} />,
      filterVariant: 'range',
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },
    {
      size: 50,
      accessorKey: 'All-In Rate',
      header: 'All-In Rate',
      Header: <TableHead header={'All-In Rate'} />,
      filterVariant: 'range',
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },
    {
      size: 50,
      accessorKey: 'Days',
      header: 'Days',
      Header: <TableHead header={'Days'} />,
      filterVariant: 'range',
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },
    {
      size: 50,
      accessorKey: 'Interest Amount',
      header: 'Interest Amount',
      Header: <TableHead header={'Interest Amount'} />,
      filterVariant: 'range',
      Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    },
  ]
}