import { Divider, Stack } from '@mantine/core'
import { ContactParams } from 'app/models/contact-params'
import MainTable from 'app/views/components/Table/MainTable'
import { LenderHistory, ProRataData } from 'app/models/lenders-position-params'
import { getColumnDefs } from './table-columnDefs'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getLoan } from 'app/state/ducks/loans/selectors'
import { getFacility } from 'app/state/ducks/facilities/selectors'
import LenderInterestComponent from './lender-interest-component'
import { LenderTransactionTypeOptions } from 'app/models/dropdown-options'


interface Props {
    lendersPosition: any
    contacts?: ContactParams[]
    changeTabToWireInstructions?: any
}


export default function HistoryAccordion({
    lendersPosition,
}: Props) {
    const { contractType } = useParams()
    const loan = useSelector(getLoan)
    const facility = useSelector(getFacility)
    const currency = contractType === 'loan' ? loan?.currency ?? 'USD' : facility?.baseCurrency ?? 'USD'

    function cleanLenderHistoryData(
        filteredLenderHistory: ProRataData[],
    ): LenderHistory[] {
        return (
            filteredLenderHistory.map((proRata, index) => ({
                effectiveDate: proRata.effectiveDate,
                transactionType: LenderTransactionTypeOptions.find((option) => option.value === proRata.transactionType)?.label ?? "",
                amount: index == 0 ? Number(proRata.amount) : Math.abs(Number(proRata.amount) - Number(filteredLenderHistory[index - 1].amount)),
                newBalance: Number(proRata.amount),
            })) ?? []
        );
    }


    return (
        <Stack w="100%">
            {contractType === 'loans' && (
                <>
                    <LenderInterestComponent
                        lendersPosition={lendersPosition}
                        currency={currency}
                    />
                    <Divider my="xl" size="md" />
                </>
            )}
            <MainTable
                minHeight='0xp'
                tableName='Lender History'
                csvExportEnabled={true}
                columnDefs={getColumnDefs(currency)}
                data={cleanLenderHistoryData(lendersPosition.proRatas)}
                enableTopToolbar={false}
                headerBackgroundColor='#F0EEEE'
                setRow={() => {
                }}
            />
        </Stack>

    )
}